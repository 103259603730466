import { FloatButton } from 'antd';
import IconChat from 'assets/svg/IconChat';
import IconEmail from 'assets/svg/IconEmail';
import IconSupport from 'assets/svg/IconSupport';
import IconSurvey from 'assets/svg/IconSurvey';
import React from 'react';
import styled from 'styled-components';

/**
 * Float button page
 * @returns 
 */
const FloatButtonPage = () => {

    const content = () => {
        return (
            <FloatButtonPageStyled>
                <div className='item'>
                    <IconEmail />
                    <a href="">Đánh giá</a>
                </div>
                <div className='item'>
                    <IconSurvey />
                    <a href="">Khảo sát</a>
                </div>
                <div className='item'>
                    <IconSupport />
                    <a href="">Hỗ trợ</a>
                </div>
            </FloatButtonPageStyled>
        );
    };

    return (
        <>
            <FloatButton icon={<IconChat/>} tooltip={content} />
        </>
    );
};

export default FloatButtonPage;

const FloatButtonPageStyled = styled.div`
border-radius: 6px;
background: var(--Gray-80, #262626);
box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.40), 0px 12px 12px -6px rgba(0, 0, 0, 0.16);
padding: 8px;
display: grid;
gap: 4px;
width: 180px;

.item {
    display: flex;
    gap: 8px;
    align-items: center;
    justify-content: start;
    padding: 10px 8px;
    a {
        color: var(--White, #FFF);
        font-family: Inter;
        font-size: 16px;
        font-weight: 500;
        line-height: 140%;
    }
}
`;