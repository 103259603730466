import { message } from 'antd';
import { IMessageProps } from '../type';
import { translate } from 'utils/moment/getSingleDateFormat';

/**
 * Handle custom info message
 * @param props 
 * @returns 
 */
const CustomInfoMessage = (props: IMessageProps) => {
  const _text = typeof props === 'string' ? props : props?.text;
  return message.info({
    className: 'is-info',
    content: (
      <p>
        <span>{translate('common', 'message.info')}:</span>
        {_text}
      </p>
    ),
  });
};

export default CustomInfoMessage;
