const IconAddCustomer = () => {
    return (
        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle opacity="0.4" cx="7.5" cy="9.49512" r="2.5" fill="#595959"/>
        <circle opacity="0.4" cx="16.5" cy="9.49512" r="2.5" fill="#595959"/>
        <ellipse opacity="0.4" cx="12" cy="16.9951" rx="6" ry="3" fill="#595959"/>
        <path d="M16.4946 18.9825C17.4313 18.4533 17.9999 17.7574 17.9999 16.9951C17.9999 15.8081 16.6212 14.7822 14.6208 14.2957C15.3283 14.104 16.1387 13.9951 16.9999 13.9951C19.7614 13.9951 21.9999 15.1144 21.9999 16.4951C21.9999 17.8758 19.7614 18.9951 16.9999 18.9951C16.8294 18.9951 16.6608 18.9908 16.4946 18.9825Z" fill="#595959"/>
        <path d="M7.50534 18.9825C7.33916 18.9908 7.17058 18.9951 7 18.9951C4.23858 18.9951 2 17.8758 2 16.4951C2 15.1144 4.23858 13.9951 7 13.9951C7.86123 13.9951 8.67161 14.104 9.37908 14.2957C7.3787 14.7822 6 15.8081 6 16.9951C6 17.7574 6.56862 18.4533 7.50534 18.9825Z" fill="#595959"/>
        <circle cx="12" cy="8.99512" r="3" fill="#595959"/>
        </svg>
    );
};

export default IconAddCustomer;