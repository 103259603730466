import fetch from 'services/request';

export interface BaseResponse<T> {
  status: number;
  data: T;
}

const getMerchantPermission = () => {
  return fetch({
    method: 'get',
    url: 'api/v1/auth/:merchant_code/permissions',
  });
};


const apisPackagePermission = {
  getMerchantPermission,
};

export default apisPackagePermission;
