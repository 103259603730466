import { Form, Select, SelectProps } from 'antd';
import { IStaffFormInput } from '.';

type Props = IStaffFormInput & SelectProps;

const StaffFormInputSelect = ({
    label,
    name,
    rules = [
        { required: true },
    ],
    placeholder,
    options,
    disabled,
    ...props
}: Props) => {
    
    return (
        <div className='form-row'>
            <Form.Item
                name={name}
                label={label}
                rules={rules}
                help={disabled ? 'Quản lý chi nhánh không thể thay đổi trường này' : ''}
            >
                <Select
                    options={options}
                    placeholder={placeholder}
                    tabIndex={-1}
                    disabled={disabled}
                    {...props}
                />
          </Form.Item>
        </div> 
    );
};

export default StaffFormInputSelect;