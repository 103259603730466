// import IconDecrease from 'assets/svg/IconDecrease';
// import IconIncrease from 'assets/svg/IconIncrease';
import { RefObject, useEffect, useMemo, useState } from 'react';
// import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { ANONYMOUS_NAME, formatMoney, isBookingTeamMemberRequired } from 'utils/unit';
import { IService } from '../../../types';
import { ModalConfirmRef } from 'components/common/Modal/ModalConfirm';
import classNames from 'classnames';
import PopoverEdit from '../../PopoverEdit';
import quickBookingHooks from 'features/bookings/hooks/useQuickBooking';
import { IServiceQuickBookingItem } from 'features/bookings/services/types/quickBooking';
import { useSearchParams } from 'react-router-dom';
import settingSelectors from 'features/settings/services/selectors';
import bookingSelectors from 'features/bookings/services/selectors';
import { useTranslation } from 'react-i18next';

type Props = {
  service: IService;
  onOpenConfirmModal: any;
  handleEditService: any;
  handleEditQuantityService: any;
  isInProgress?: boolean;
  isEdit?: boolean;
  isBusy?: boolean;
  modalConfirmRef: RefObject<ModalConfirmRef>

};

const DetailItem = (props: Props) => {
  const {
    service,
    onOpenConfirmModal,
    handleEditService,
    // handleEditQuantityService,
    // isInProgress = false,
    isBusy = false,
    // isEdit,
    // modalConfirmRef,
  } = props;
  const [quantity, setQuantity] = useState<number>(1);
  const { t: bookingLang } = useTranslation('booking');
  const [currServices, setCurrServices] = quickBookingHooks.useSelectedServices();
  const [searchParams] = useSearchParams();
  const setting = settingSelectors.getSetting();
  const isCheckIn = searchParams.get('isCheckIn')?.toLocaleLowerCase() === 'true' ?? false;
  const bookStatus = bookingSelectors.quickBooking.getBookingStatus();

  useEffect(() => {
    setQuantity(service?.quantity || 1);
  }, [service]);

  const currEmployeeName = useMemo(() => {
    const fullName = service?.assigned_employee?.employee?.full_name;
    if (fullName && !service?.employee_id) return fullName;
    if (!service?.employee_id) return bookingLang(ANONYMOUS_NAME === 'Any team member' ? 'AnyTeamMember' : '');
    return service?.merchant_employees?.find(
      (employee: any) => employee.id === service?.employee_id
    )?.full_name;
  }, [service]);

  const isAnyTeamMember = useMemo(() => {
    return isBookingTeamMemberRequired(setting, isCheckIn ,bookStatus) && currEmployeeName === bookingLang(ANONYMOUS_NAME === 'Any team member' ? 'AnyTeamMember' : '');
  }, [currEmployeeName]);   

  const handleSplitService = () => {

    setCurrServices([...currServices.map(o => {
      if (o?.id === service?.id && service?.service_variant_id === o.service_variant_id && service?.index === o?.index && (o?.quantity ?? 1) > 1) {
        return {
          ...o,
          quantity: (o?.quantity ?? 0) - 1
        };
      }
      return o;
    }), {
      ...service,
      quantity: 1,
      index: currServices.length - 1,
    } as IServiceQuickBookingItem]);
  };

  // const handleDecrease = () => {
  //   setQuantity(() => quantity - 1);
  //   handleEditQuantityService(service?.id, service.service_variant_id, quantity - 1, service?.index);
  // };

  // const onDecrease = () => {
  //   if (quantity === 1) {
  //     return;
  //   }
  //   if (isInProgress) {
  //     const configs = {
  //       title: bookingLang('modalConfirm.editService.title'),
  //       msg: bookingLang('modalConfirm.editService.message'),
  //       submit: handleDecrease
  //     };
  //     modalConfirmRef.current?.show(configs);
  //     return;
  //   }
  //   handleDecrease();
  // };
  // const handleIncrease = () => {
  //   setQuantity(() => quantity + 1);
  //   handleEditQuantityService(service?.id, service.service_variant_id, quantity + 1, service?.index);
  // };
  // const onIncrease = () => {
  //   if (isInProgress) {
  //     const configs = {
  //       title: bookingLang('modalConfirm.editService.title'),
  //       msg: bookingLang('modalConfirm.editService.message'),
  //       submit: handleIncrease
  //     };
  //     modalConfirmRef.current?.show(configs);
  //     return;
  //   }
  //   handleIncrease();
  // };


  const{t: bookinglang} = useTranslation('booking');
  return (
    <>
      <DetailItemStyled onClick={()=>handleEditService(service?.id, service?.service_variant_id, service?.index)} className={classNames(
        isBusy && 'busy',
      )}>
        <div className='item-info'>
          <p className='item-title'>{service?.name || service?.service_name}</p>
          <p className='item-text'>
            {service?.serviceVariantName ||
              service?.name ||
              service?.service_name}{' '}
            {/* <span>
              {service?.duration_time
                ? formatTimeMinutes(service.duration_time)
                : ''}
            </span>{' '} */}
            {bookinglang('with')} <span className='item-emloyee-name'>{currEmployeeName ? currEmployeeName : ANONYMOUS_NAME}</span>
          </p>
        </div>
        <div>
          <div>
            <div className='quantity-control'>
              {/* <span
                className={`control-btn ${quantity === 1 && 'is-disabled'}`}
                onClick={onDecrease}
              >
                <IconDecrease />
              </span> */}
              <span className='quantity-value'>{quantity}</span>
              {/* <span className='control-btn' onClick={onIncrease}>
                <IconIncrease />
              </span> */}
            </div>
          </div>
          <div className='item-price'>{formatMoney(service.sale_price || service?.regular_price || (service?.service_price ?? 0))}</div>
          <PopoverEdit
            quantity={quantity}
            onOpenConfirmModal={() => onOpenConfirmModal(service?.id, service?.service_variant_id, service?.index)}
            handleEditService={() => handleEditService(service?.id, service?.service_variant_id, service?.index)}
            handleSplitService={() => handleSplitService()}
          />
        </div>
      </DetailItemStyled>
      {isAnyTeamMember && <ErrorTextStyled className='text-error'>{bookingLang('TheServicesMustNotBeAssign')} {bookingLang(ANONYMOUS_NAME === 'Any team member' ? 'AnyTeamMember' : '')}. {bookingLang('PleaseAssignTeamMember')}</ErrorTextStyled>}

    </>
  );
};

export default DetailItem;

const ErrorTextStyled = styled.p`
  margin-top: 1px;
`;

const DetailItemStyled = styled.div`
  &.busy {
    border: 2px solid #FA8C16;
    background-color: #fff7e6;
  }
  display: flex;
  flex-wrap: wrap;
  padding: 12px;
  gap: 24px;
  border: 1px solid var(--color-purple-05);
  border-radius: 6px;
  align-items: center;
  cursor: pointer;
  :hover {
    background-color: var(--color-white-01);
  }
  .item-info {
    flex: 1;
    min-width: 40%;
    & + div {
      display: flex;
      gap: 24px;
      margin-left: auto;
      justify-content: end;
      width: 50%;
      /* justify-content: space-between; */
    }
  }
  .item-price {
    display: flex;
    align-items: center;
  }
  .item-title {
    font-weight: 600;
    font-size: 16px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .item-text {
    font-size: 12px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    .item-emloyee-name {
      color: #4a9d77;
    }
    span {
      font-weight: 600;
    }
  }
  .item-control {
    display: flex;
    gap: 8px;
    align-items: center;
    button {
      display: flex;
      align-items: center;
    }
  }
`;
