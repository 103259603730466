import fetch from 'services/request';

const BASE_URL = 'api/v1/merchants/:merchant_code/product-category/all-category';

const getListPaginateCategory = (params?: any) => {
  return fetch({
    method: 'get',
    url: BASE_URL,
    params: { ...params },
  });
};

const apisProduct = {
  getListPaginateCategory
};

export default apisProduct;