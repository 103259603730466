import { Button, Form, Input, Select, Upload } from 'antd';
import { IconEditSetting, IconInfoPayment, IconUpload } from 'assets/svg';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from '../../pages/index.module.scss';
import img_5422_1 from '../../../../assets/images/setting/IMG_5422_1.png';
import styled from 'styled-components';
interface ITransferModal {
    statusModal?: string;
}
const TransferModal = ({
    statusModal,
}: ITransferModal) => {
    const { t: settingLang } = useTranslation('setting');
    const uploadButton = (
        <div className={styles.upload_overview}>
            <IconUpload />
            <div>
                <p className={styles.modal_upload_title} style={{ marginTop: 8 }}>{settingLang('SelectOrDragAndDropImagesToUpload')}</p>
                <p className={styles.modal_upload_content}>{settingLang('SupportsOneOrMoreImagesAs')}</p>
            </div>
        </div>
    );
    const options = [
        {
            'id': 1,
            'value': 'Vietcombank'
        },
        {
            'id': 2,
            'value': 'ACBbank'
        }
    ];
    return (
        <>
            <h2 className={styles.modal_title}>{settingLang('Transfer')}</h2>
            <MomoModalStyled>
                <Form
                    className='modal-form'
                    // onFinish={handleSubmit}
                    autoComplete='off'
                    // form={form}
                    layout='vertical'
                    initialValues={{
                        locations: null,
                    }}
                >
                    <div className={styles.modal_info}>
                        <IconInfoPayment />
                        <p className={styles.modal_info_p}>{settingLang('PleaseAttachAQRCodeImageWithNameAndPhoneMumberAttached')}.</p>
                    </div>

                    <div>
                        <Form.Item label={settingLang('IssuingBank')+'(*)'} name='locations' rules={[
                            { required: true, message: settingLang('PleaseSelectIssuingBank') || '' }
                        ]} >
                            <Select
                                placeholder={settingLang('SelectBank')}
                                defaultValue={options.find(option => option.id === 1)}
                                options={options}
                            />
                        </Form.Item>
                        <Form.Item
                            label={settingLang('AccountNumber')+'(*)'}
                            name='name'
                            rules={[{ required: true, message: settingLang('PleaseAccountNumber') || '' }]}
                        >
                            <Input placeholder={settingLang('EnterAccountNumber') || ''} />
                        </Form.Item>
                        <Form.Item
                            label={settingLang('FullName')+'(*)'}
                            name='name'
                            rules={[{ required: true, message: settingLang('PleaseEnterName') || '' }]}
                        >
                            <Input placeholder={settingLang('EnterTheAccountHolderIsFirstAndLastName') || ''} />
                        </Form.Item>
                        <div>
                            <p className={styles.modal_upload_title}>{settingLang('BankQR')}</p>
                            {statusModal === 'Add' ?
                                <Form.Item >
                                    <Upload>
                                        {uploadButton}
                                    </Upload>
                                </Form.Item>
                                :
                                <div className={styles.modal_upload_img_container}>
                                    <div>
                                        <img src={img_5422_1} alt="" />
                                    </div>
                                    <div className={styles.modal_upload_edit}>
                                        <IconEditSetting />
                                        <p className={styles.modal_upload_edit_p}>{settingLang('Edit')}</p>
                                    </div>
                                </div>}
                        </div>
                    </div>

                    {statusModal === 'Add'
                        ?
                        <Button className='common-btn'>{settingLang('Confirm')}</Button>
                        :
                        <Button className='common-btn' style={{ opacity: '50%' }}>{settingLang('Confirm')}</Button>
                    }
                </Form>
            </MomoModalStyled>
        </>
    );
};

export default TransferModal;

const MomoModalStyled = styled.div`
.ant-upload {
    width: 100%;
    text-align: center;
}
.ant-form {
    gap: 24px;
}
.common-btn {
    width: 100%;
}
`;