import { Datepicker, DatepickerEvent } from '@meinefinsternis/react-horizontal-date-picker';
import { useEffect } from 'react';
import { vi } from 'date-fns/locale';
import styled from 'styled-components';
import moment from 'moment';
import 'dayjs/locale/vi';
import dayjs from 'dayjs';

const daysFormat = [
  {
    origin: 'Mo',
    replace: 'Mon',
  },
  {
    origin: 'Tu',
    replace: 'Tue',
  },
  {
    origin: 'We',
    replace: 'Wed',
  },
  {
    origin: 'Th',
    replace: 'Thu',
  },
  {
    origin: 'Fr',
    replace: 'Fri',
  },
  {
    origin: 'Sa',
    replace: 'Sat',
  },
  {
    origin: 'Su',
    replace: 'Sun',
  },
];

interface HorizontalDateProps {
  value: Date | null,
  handleChange: (date: DatepickerEvent) => void,
  disabledDates?: Date[]
  endDate?: Date;
}

/**
 * Handle horizontal date
 * @param value, handleChange, disabledDates, endDate 
 * @returns 
 */
const HorizontalDate = ({ value, handleChange, disabledDates, endDate }: HorizontalDateProps) => {

  const lang = 'vi';
  dayjs.locale(lang === 'vi' ? 'vi' : 'en');

  useEffect(() => {
    const kqButtons = Array.from(
      document.getElementsByClassName('Kq')
    );

    for (const kq of kqButtons) {
      kq.setAttribute('type', 'button');
    }


    const labelDayOfWeek = Array.from(
      document.getElementsByClassName('Lx')
    );

    for (const kq of labelDayOfWeek) {
      const value = kq.innerHTML;
      kq.innerHTML = daysFormat.find(day => day.origin.toLocaleLowerCase() === value?.toLocaleLowerCase())?.replace ?? value;
    }

    const dates = Array.from(
      document.getElementsByClassName('_3n')
    );

    for (const date of dates) {

      const value = date.querySelector('._1g')?.innerHTML;

      if (moment().get('date').toString() === value) {
        date.classList.add('current');
      }
    }

  }, [value]);

  return (
    <DatepickerStyled>
      {/* <ConfigProvider locale={locale}> */}
        <Datepicker
          disabledDates={disabledDates}
          locale={vi}
          onChange={handleChange}
          startValue={value}
          endValue={value}
          startDate={new Date()}
          endDate={endDate}
        />
      {/* </ConfigProvider>  */}
    </DatepickerStyled>
  );
};

export default HorizontalDate;

const DatepickerStyled = styled.div`
  .dM {
    position: relative;
    .Kq {
      position: absolute;
      right: 0px;
      top: 0px;
    }

    .WP {
      z-index: 200;

      &:after {
        background: transparent;
        right: 0px;
      }
    }

    .Ez {
      right: 36px;
      z-index: 100;
      &:after {
        background: transparent;
        left: -12px;
      }
    }
  }

  .dr {
    ._2E .Lx {
      color: #000;
    }

    ._2E {
      color: #000;
    }

    ._3n[aria-disabled=true] {
      ._1g {
        text-decoration: line-through;
        border: none;
        background-color: transparent;
      }
    }
    ._3n {
      margin-right: 12px;
    }

    ._1g {
      border: 1px solid #E2E5FF;
      background-color: #E2E5FF;
      padding: 20px;
      border-radius: 100%;
    }

    .current {
      ._1g {
        border: 1px solid #363565;
        background-color: #fff;
        padding: 20px;
        border-radius: 100%;
      }
    }

    .FC {
      background-color: transparent;
      ._1g {
        background-color: #363565;
        color: #fff;
      }

      & .Lx {
        color: #000;
      }
    }
  }
`;