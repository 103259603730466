import { PackagePermissionsType } from '../services/types/permission';
// import { PackageFeatures } from '../services/types/reducer';

// const dataPermissionsEnterise = {
// 	package_name: 'ENTERPRISE',
// 	package_expiration: '2025-09-25',
// 	package_features: {
// 		customers: [
// 			'add_customer',
// 			'view_customer',
// 			'edit_customer',
// 			'delete_customer',
// 			'import_customer',
// 			'export_customer'
// 		],
// 		employees: [
// 			'add_employee',
// 			'view_employee',
// 			'edit_employee',
// 			'delete_employee',
// 			'import_employee',
// 			'export_employee'
// 		],
// 		sales: [
// 			'manage_sale'
// 		],
// 		books: [
// 			'view_gird',
// 			'view_calendar',
// 			'view_list',
// 			'create_book',
// 			'edit_book',
// 			'delete_book',
// 			'duplicate_book',
// 			'no_show_book',
// 			'allow_check_team_member'
// 		],
// 		services: [
// 			'add_service',
// 			'view_service',
// 			'edit_service',
// 			'delete_service',
// 			'import_service',
// 			'export_service'
// 		],
// 		service_categories: [
// 			'add_category',
// 			'view_category',
// 			'edit_category',
// 			'delete_category'
// 		],
// 		vouchers: [
// 			'add_voucher',
// 			'view_voucher',
// 			'edit_voucher',
// 			'delete_voucher',
// 			'send_mail_voucher_purchased'
// 		],
// 		promotions: [
// 			'add_promotion',
// 			'view_promotion',
// 			'edit_promotion',
// 			'delete_promotion'
// 		],
// 		programs: [
// 			'add_program',
// 			'view_program',
// 			'edit_program',
// 			'delete_program'
// 		],
// 		rewards: [
// 			'add_reward',
// 			'view_reward',
// 			'edit_reward',
// 			'delete_reward'
// 		],
// 		loyaltysettings: [
// 			'manage_setting_loyal'
// 		],
// 		analytics: [
// 			'view_analytic',
// 			'export_analytic'
// 		],
// 		locations: [
// 			'add_location',
// 			'view_location',
// 			'edit_location',
// 			'delete_location'
// 		],
// 		workinghours: [
// 			'view_working_hour',
// 			'checkin_working_hour',
// 			'checkout_working_hour',
// 			'add_shift',
// 			'edit_shift'
// 		],
// 		settings: [
// 			'manage_genaral_setting',
// 			'manage_booking_setting',
// 			'manage_closed_periods_setting',
// 			'manage_tyro_setting',
// 			'manage_booking_link_setting',
// 			'manage_cancellation_reason_setting'
// 		],
// 		paymentmethods: [
// 			'cash_payment',
// 			'card_payment',
// 			'voucher_payment',
// 			'mix_payment',
// 			'discount_fee',
// 			'extra_fee'
// 		],
// 		checkinforms: [
// 			'checkin_qr_code'
// 		],
// 		bookingforms: [
// 			'booking_online'
// 		],
// 		invoices: [
// 			'view_invoice',
// 			'refund_invoice',
// 			'send_invoice'
// 		]
// 	},
// 	package_extras: {
// 		limit_location: null,
// 		limit_staff: null,
// 		limit_customer: null
// 	}
// };

// const dataPermissionAdvance = {
// 	package_name: 'ADVANCED',
// 	package_expiration: '2025-09-25',
// 	package_features: {
// 		customers: [
// 			'add_customer',
// 			'view_customer',
// 			'edit_customer',
// 			'delete_customer',
// 			'import_customer',
// 			'export_customer'
// 		],
// 		employees: [
// 			'add_employee',
// 			'view_employee',
// 			'edit_employee',
// 			'delete_employee',
// 			'import_employee',
// 			'export_employee'
// 		],
// 		sales: [
// 			'manage_sale'
// 		],
// 		books: [
// 			'view_gird',
// 			'view_calendar',
// 			'view_list',
// 			'create_book',
// 			'edit_book',
// 			'delete_book',
// 			'duplicate_book',
// 			'no_show_book',
// 			'allow_check_team_member'
// 		],
// 		services: [
// 			'add_service',
// 			'view_service',
// 			'edit_service',
// 			'delete_service',
// 			'import_service',
// 			'export_service'
// 		],
// 		service_categories: [
// 			'add_category',
// 			'view_category',
// 			'edit_category',
// 			'delete_category'
// 		],
// 		vouchers: [
// 			'add_voucher',
// 			'view_voucher',
// 			'edit_voucher',
// 			'delete_voucher',
// 			'send_mail_voucher_purchased'
// 		],
// 		promotions: [
// 			'add_promotion',
// 			'view_promotion',
// 			'edit_promotion',
// 			'delete_promotion'
// 		],
// 		programs: [
// 			'add_program',
// 			'view_program',
// 			'edit_program',
// 			'delete_program'
// 		],
// 		rewards: [
// 			'add_reward',
// 			'view_reward',
// 			'edit_reward',
// 			'delete_reward'
// 		],
// 		loyaltysettings: [
// 			'manage_setting_loyal'
// 		],
// 		analytics: [
// 			'view_analytic',
// 			'export_analytic'
// 		],
// 		locations: [
// 			'add_location',
// 			'view_location',
// 			'edit_location',
// 			'delete_location'
// 		],
// 		workinghours: [
// 			'view_working_hour',
// 			'checkin_working_hour',
// 			'checkout_working_hour',
// 			'add_shift',
// 			'edit_shift'
// 		],
// 		settings: [
// 			'manage_genaral_setting',
// 			'manage_booking_setting',
// 			'manage_closed_periods_setting',
// 			'manage_tyro_setting',
// 			'manage_booking_link_setting',
// 			'manage_cancellation_reason_setting'
// 		],
// 		paymentmethods: [
// 			'cash_payment',
// 			'card_payment',
// 			'voucher_payment',
// 			'mix_payment',
// 			'discount_fee',
// 			'extra_fee'
// 		],
// 		checkinforms: [
// 			'checkin_qr_code'
// 		],
// 		bookingforms: [
// 			'booking_online'
// 		],
// 		invoices: [
// 			'view_invoice',
// 			'refund_invoice',
// 			'send_invoice'
// 		]
// 	},
// 	package_extras: {
// 		limit_location: null,
// 		limit_staff: null,
// 		limit_customer: null
// 	}
// };

// const dataPermissionBasic = {
// 	package_name: 'STARTER',
// 	package_expiration: '2025-09-25',
// 	package_features: {
// 		customers: [
// 			'add_customer',
// 			'view_customer',
// 			'edit_customer',
// 			'delete_customer',
// 			'import_customer',
// 			'export_customer'
// 		],
// 		employees: [
// 			'add_employee',
// 			'view_employee',
// 			'edit_employee',
// 			'delete_employee',
// 			'import_employee',
// 			'export_employee'
// 		],
// 		sales: [
// 			'manage_sale'
// 		],
// 		books: [
// 			'view_gird',
// 			'view_calendar',
// 			'view_list',
// 			'create_book',
// 			'edit_book',
// 			'delete_book',
// 			'duplicate_book',
// 			'no_show_book',
// 			'allow_check_team_member'
// 		],
// 		services: [
// 			'add_service',
// 			'view_service',
// 			'edit_service',
// 			'delete_service',
// 			'import_service',
// 			'export_service'
// 		],
// 		service_categories: [
// 			'add_category',
// 			'view_category',
// 			'edit_category',
// 			'delete_category'
// 		],
// 		vouchers: [
// 			'add_voucher',
// 			'view_voucher',
// 			'edit_voucher',
// 			'delete_voucher',
// 			'send_mail_voucher_purchased'
// 		],
// 		promotions: [
// 			'add_promotion',
// 			'view_promotion',
// 			'edit_promotion',
// 			'delete_promotion'
// 		],
// 		programs: [
// 			'add_program',
// 			'view_program',
// 			'edit_program',
// 			'delete_program'
// 		],
// 		rewards: [
// 			'add_reward',
// 			'view_reward',
// 			'edit_reward',
// 			'delete_reward'
// 		],
// 		loyaltysettings: [
// 			'manage_setting_loyal'
// 		],
// 		analytics: [
// 			'view_analytic',
// 			'export_analytic'
// 		],
// 		locations: [
// 			'add_location',
// 			'view_location',
// 			'edit_location',
// 			'delete_location'
// 		],
// 		workinghours: [
// 			'view_working_hour',
// 			'checkin_working_hour',
// 			'checkout_working_hour',
// 			'add_shift',
// 			'edit_shift'
// 		],
// 		settings: [
// 			'manage_genaral_setting',
// 			'manage_booking_setting',
// 			'manage_closed_periods_setting',
// 			'manage_tyro_setting',
// 			'manage_booking_link_setting',
// 			'manage_cancellation_reason_setting'
// 		],
// 		paymentmethods: [
// 			'cash_payment',
// 			'card_payment',
// 			'voucher_payment',
// 			'mix_payment',
// 			'discount_fee',
// 			'extra_fee'
// 		],
// 		checkinforms: [
// 			'checkin_qr_code'
// 		],
// 		bookingforms: [
// 			'booking_online'
// 		],
// 		invoices: [
// 			'view_invoice',
// 			'refund_invoice',
// 			'send_invoice'
// 		]
// 	},
// 	package_extras: {
// 		limit_location: null,
// 		limit_staff: null,
// 		limit_customer: null
// 	}
// };

export const useCheckPermission = (packageFeature: string[], permission: PackagePermissionsType) => {	
	//@ts-ignore
	return packageFeature?.includes(permission);
};