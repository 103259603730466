const IconWarning = () => {
    return (
        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="Huge-icon/interface/outline/warning">
                <circle id="Ellipse 1113" cx="12" cy="17.5" r="1" transform="rotate(-180 12 17.5)" fill="#DF1212" />
                <path id="Vector" d="M12 14.5L12 7.5M22 12.5C22 18.0228 17.5228 22.5 12 22.5C6.47715 22.5 2 18.0228 2 12.5C2 6.97715 6.47715 2.5 12 2.5C17.5228 2.5 22 6.97715 22 12.5Z" stroke="#DF1212" />
            </g>
        </svg>

    );
};

export default IconWarning;