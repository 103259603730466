import React, { memo, useLayoutEffect, useRef, useState } from 'react';

/**
 * Breaking time overlay
 * @param style 
 * @returns 
 */
function BreakingTimeOverlay({ }: { style?: React.CSSProperties }) {
  const [count, setCount] = useState(0);
  const ref = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    if (ref.current?.clientWidth) {
      // const width = ref.current?.clientWidth;
      
      setCount(Math.round((1140 / 16) + 5));
    }
  });

  return (
    <div ref={ref} className='breakingTimeOverlay'>
      <svg fill="none" xmlns="http://www.w3.org/2000/svg" style={{ width: '100%', height: '100%', display: 'flex' }}>
        <g clipPath="url(#clip0_6901_178720)">
          {Array.from(Array(count).keys()).map(o => <line key={o} x1={0.353553 + 16 * o} y1={0.353553 * 2} x2={-95.6464 + 16 * o} y2={96.3535 * 2} stroke="#C3C2E0" />)}
        </g>
      </svg>

    </div >
  );
}

export default memo(BreakingTimeOverlay);
