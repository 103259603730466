import { Button, Form, Input } from 'antd';
// import { IconRight } from 'assets/svg';
import IconPlusCircle from 'assets/svg/IconPlusCircle';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from '../../pages/index.module.scss';
import TextArea from 'antd/es/input/TextArea';
import styled from 'styled-components';

const InformationTab = () => {
    const { t: productsLang } = useTranslation('products');
    const [phoneFields, setPhoneFields] = useState(1);

    const handleAddPhone = () => {
        setPhoneFields((prevFields) => prevFields + 1);
    };

    const phoneInputs = [];
    for (let i = 0; i < phoneFields; i++) {
        phoneInputs.push(
                <Input placeholder={productsLang('EnterPhone') || ''} />
        );
    }
    return (
        <InformationTabStyled>
            <div className={styles.container_infotmation}>
                <div className={styles.Information_item}>
                    <p className={styles.title_overview}>{productsLang('GeneralInformation')}</p>
                    <Form.Item label={productsLang('Supplier') + ' (*)'}
                        rules={[
                            { required: true, message: productsLang('PleaseEnterSupplier') || '' }
                        ]}>
                        <Input placeholder={productsLang('EnterSupplier') || ''} />
                    </Form.Item>
                    <Form.Item label={productsLang('Description')}>
                        <TextArea
                            placeholder={productsLang('ExampleProductNotes') || ''}
                            rows={4}
                        />
                    </Form.Item>
                </div>
                <div className={styles.Information_item}>
                    <p className={styles.title_overview}>{productsLang('Contact')}</p>
                    <Form.Item label={productsLang('ContactName') + ' (*)'}
                        rules={[
                            { required: true, message: productsLang('PleaseEnterContactName') || '' }
                        ]}>
                        <Input placeholder={productsLang('EnterContactName') || ''} />
                    </Form.Item>
                    <Form.Item label={productsLang('Phone')+' (*)'}
                        rules={[
                            { required: true, message: productsLang('PleaseEnterPhone') || '' }
                        ]}>
                        {phoneInputs}
                    </Form.Item>
                    <Button onClick={handleAddPhone}><IconPlusCircle/> {productsLang('AddPhone')}</Button>
                    <Form.Item label={productsLang('Email')}
                        rules={[
                            { required: true, message: productsLang('PleaseEnterEmail') || '' }
                        ]}>
                        <Input placeholder={productsLang('EnterEmail') || ''} />
                    </Form.Item>
                    <Form.Item label={productsLang('Address')}
                        rules={[
                            { required: true, message: productsLang('PleaseEnterAddress') || '' }
                        ]}>
                        <Input placeholder={productsLang('EnterAddress') || ''} />
                        <p className={styles.example_address}>{productsLang('ExampleAddress')}</p>
                    </Form.Item>
                    <Form.Item label={productsLang('Website')}
                        // rules={[
                        //     { required: true, message: productsLang('PleaseEnterWebsite') || '' }
                        // ]}
                        >
                        <Input placeholder={productsLang('EnterWebsite') || ''} />
                    </Form.Item>
                </div>
            </div>
        </InformationTabStyled>
    );
};

export default InformationTab;
const InformationTabStyled = styled.div`
.ant-row{
    display: contents;
}
.ant-btn-default {
    margin-top: 12px;
    width: 100%;
    border: 0px;
    font-size: 18px;
    font-weight: 500;
    line-height: 140%;
    color: #1B9AAA;
    display: flex;
    gap: 8px;
}
.ant-form-item-label > label {
    font-weight: 600;
    font-size: 16px;
}
.ant-form-item .ant-form-item-control-input-content {
    display: grid;
    gap: 8px;
}
`;