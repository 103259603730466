import React from 'react';

const IconAddSetting = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
            <path d="M10 4.16602V15.8327" stroke="#1B9AAA" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M4.1665 10H15.8332" stroke="#1B9AAA" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    );
};

export default IconAddSetting;