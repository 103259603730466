import { AxiosResponse } from 'axios';
import message from 'components/common/Message';
import vouchersApis from 'features/vouchers/services/apis';
import { first, get, set } from 'lodash';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import uiActions from 'services/UI/actions';
import { setLoading } from 'services/UI/sagas';
import { ISagaFunc } from 'services/actionConfigs';
import actions from './actions';
import apisCheckout from './apis';
import { PATH_LOADING } from './constants';
import purchasedVouchersMockup from './mocks/purchasedVouchers';
import { getDetailPure, getIdPure } from './selectors';
import { IBookingDetailResData } from './types/bookingDetail';
import { IApiCheckoutByCardBody, IApiCheckoutByCashBody, IApiCheckoutByVoucherBody, IApiCheckoutWithMixinBody } from './types/checkout';
import { IApiUpdateCusInfoBody } from './types/customer';
import { IServiceConfigsResData } from './types/service';
import { ICustomerDetailResData } from 'features/customers/services/types/cutomer';
import apisCustomer from 'features/customers/services/apis';

const getVouchersConfig = function* () {
  try {
    const res: AxiosResponse<{ data: IServiceConfigsResData }> = yield call(vouchersApis.getListVouchers, { view: 'sale', per_page: 99999, status: 'active' });
    if (res?.data?.data) {
      yield put(actions.getVouchersConfig.success(res.data.data));
    }
  } catch (error) {
    yield put(actions.getVouchersConfig.fail({}));
  }
};
const getServiceConfigs = function* () {
  yield setLoading(PATH_LOADING.getServiceConfigs, true);
  try {
    const res: AxiosResponse<{ data: IServiceConfigsResData }> = yield call(apisCheckout.getServiceConfigs);
    yield put(actions.getVouchersConfig.fetch());
    if (res?.data?.data) {
      const resData = res.data.data;
      yield put(actions.getServiceConfigs.success(resData));
    }
  } catch (error) {
    yield put(actions.getServiceConfigs.fail({}));
  } finally {
    yield setLoading(PATH_LOADING.getServiceConfigs, false);
  }
};

const getVoucherOfCustomer: ISagaFunc<string> = function* ({ payload }) {
  yield setLoading(PATH_LOADING.getVoucherOfCustomer, true);

  const customerCode = payload;

  try {
    const res: AxiosResponse<{ data: IServiceConfigsResData }> = yield call(apisCheckout.getVoucherOfCustomer, customerCode);
    
    if (res?.data?.data) {
      // FOR TEST ************************************************************
      const resData = res?.data?.data;      
      yield put(actions.getVoucherOfCustomer.success(resData));
    }
  } catch (error) {
    yield put(actions.getVoucherOfCustomer.fail({}));
  } finally {
    yield setLoading(PATH_LOADING.getVoucherOfCustomer, false);
  }
};

const getBookingDetail: ISagaFunc<string> = function* ({ payload }) {
  yield put(uiActions.setLoadingPage(true));
  const id = payload;
  try {
    const res: AxiosResponse<{ data: IBookingDetailResData }> = yield call(apisCheckout.getBookingDetail, id);
    if (res?.data?.data) {

      // FOR TEST ************************************************************
      set(res.data.data, 'customer', {
        ...res.data.data?.customer ?? {},
        reward_info: res.data.data?.customer?.reward_info || {
          current_point: res.data.data?.customer?.current_point,
          point_left_to_earn_a_reward: 0,
        },
        vouchers: purchasedVouchersMockup,
      });
      yield put(actions.getBookingDetail.success(res.data.data));
    } else {
      message.error('An error occurred. Please try ');
    }
  } catch (error) {
    const errors = Object.values(get(error, 'response.data.errors', {}));
    const msg = get(first(errors), 0, '');
    message.error(msg);
    yield put(actions.getBookingDetail.fail({}));
  } finally {
    yield put(uiActions.setLoadingPage(false));
  }
};

const updateCusInfo: ISagaFunc<{ id: string, data: IApiUpdateCusInfoBody }> = function* ({ payload }) {
  const { id, data } = payload;
  if (!id) return;
  yield setLoading(PATH_LOADING.updateCusInfo, true);
  try {
    const res: AxiosResponse<{ data: unknown }> = yield call(apisCheckout.updateCusInfo, id, data);
    if (res.data.data) {
      yield put(actions.updateCusInfo.success({}));
    }
  } catch (error) {
    yield put(actions.updateCusInfo.fail({}));
  } finally {
    yield setLoading(PATH_LOADING.updateCusInfo, false);
  }
};

const checkoutByCash: ISagaFunc<IApiCheckoutByCashBody> = function* ({ payload }) {
  const id = yield select(getIdPure);
  if (!id) return;

  yield setLoading(PATH_LOADING.checkout, true);
  try {
    yield call(apisCheckout.apiCheckoutByCash, id, payload);
    yield put(actions.checkoutByCash.success({}));
  } catch (error) {
    yield put(actions.checkoutByCash.fail({}));
  } finally {
    yield setLoading(PATH_LOADING.checkout, false);
  }
};

const getRewardOfCustomer: ISagaFunc<any> = function* ({ payload }) {
  // const id = yield select(getIdPure);

  const { id } = payload;

  yield setLoading(PATH_LOADING.getRewardOfCustomer, true);
  try {
    const pointEnough = yield call(apisCheckout.getRewardOfCustomer, id, { view: 'point_enough' });
    const pointLeft = yield call(apisCheckout.getRewardOfCustomer, id, { view: 'point_left' });

    yield put(actions.getRewardOfCustomer.success({ pointLeft: pointLeft?.data?.data, pointEnough: pointEnough?.data?.data }));
  } catch (error) {
    yield put(actions.getRewardOfCustomer.fail({}));
  } finally {
    yield setLoading(PATH_LOADING.getRewardOfCustomer, false);
  }
};

const checkoutByCard: ISagaFunc<IApiCheckoutByCardBody> = function* ({ payload }) {
  const id = yield select(getIdPure);
  if (!id) return;

  yield setLoading(PATH_LOADING.checkout, true);
  try {
    yield call(apisCheckout.apiCheckoutByCard, id, payload);
    yield put(actions.checkoutByCard.success({}));
  } catch (error) {
    yield put(actions.checkoutByCard.fail({}));
  } finally {
    yield setLoading(PATH_LOADING.checkout, false);
  }
};

const checkoutByVoucher: ISagaFunc<IApiCheckoutByVoucherBody> = function* ({ payload }) {
  const id = yield select(getIdPure);
  if (!id) return;

  yield setLoading(PATH_LOADING.checkout, true);
  try {
    yield call(apisCheckout.apiCheckoutByVoucher, id, payload);
    yield put(actions.checkoutByVoucher.success({}));
  } catch (error) {
    yield put(actions.checkoutByVoucher.fail({}));
  } finally {
    yield setLoading(PATH_LOADING.checkout, false);
  }
};

const checkoutWithMixin: ISagaFunc<IApiCheckoutWithMixinBody> = function* ({ payload }) {
  const id = yield select(getIdPure);
  if (!id) return;

  yield setLoading(PATH_LOADING.checkout, true);
  try {
    yield call(apisCheckout.apiCheckoutWithMixin, id, payload);
    yield put(actions.checkoutWithMixin.success({}));
  } catch (error) {
    yield put(actions.checkoutWithMixin.fail({}));
  } finally {
    yield setLoading(PATH_LOADING.checkout, false);
  }
};

const reviewPointCustomerWillReceive: ISagaFunc<number> = function* ({ payload }) {
  try {
    const detail = yield select(getDetailPure);
    const merchant_location_id = detail?.merchant_location_id;
    if (merchant_location_id === null || merchant_location_id === undefined) return;

    const res = yield call(apisCheckout.reviewPointCustomerWillReceive, payload, merchant_location_id);
    if (res.data.data) {
      yield put(actions.reviewPointCustomerWillReceive.success(res.data.data));
    }
  } catch (error) {
    yield put(actions.reviewPointCustomerWillReceive.fail({}));
  }
};

const setCheckoutBookingId: ISagaFunc<string> = function* ({ payload }) {
  const id = payload;
  yield put(actions.getBookingDetail.fetch(id));
  yield put(actions.getServiceConfigs.fetch());
};

const getTotalPointCustomer: ISagaFunc<{ is_walkin_in: boolean, customer_code: string }> = function* ({ payload }) {
  const param = payload;
  try {
    const res: AxiosResponse<{ data: ICustomerDetailResData }> = yield call(apisCustomer.getCustomerDetail, param);
    if (res?.data?.data) {
      const customerDetail = res.data.data;
      yield put(actions.getTotalPointCustomer.success(customerDetail.loyalty_point));
    } else {
      throw 'fail';
    }
  } catch (error) {
  }
};

export default function* checkoutServiceSaga() {
  yield takeLatest(actions.getBookingDetail.fetch, getBookingDetail);
  yield takeLatest(actions.getServiceConfigs.fetch, getServiceConfigs);
  yield takeLatest(actions.getVouchersConfig.fetch, getVouchersConfig);
  yield takeLatest(actions.updateCusInfo.fetch, updateCusInfo);
  yield takeLatest(actions.checkoutByCash.fetch, checkoutByCash);
  yield takeLatest(actions.checkoutByCard.fetch, checkoutByCard);
  yield takeLatest(actions.checkoutByVoucher.fetch, checkoutByVoucher);
  yield takeLatest(actions.checkoutWithMixin.fetch, checkoutWithMixin);
  yield takeLatest(actions.setCheckoutBookingId, setCheckoutBookingId);
  yield takeLatest(actions.getRewardOfCustomer.fetch, getRewardOfCustomer);
  yield takeLatest(actions.getVoucherOfCustomer.fetch, getVoucherOfCustomer);
  yield takeLatest(actions.reviewPointCustomerWillReceive.fetch, reviewPointCustomerWillReceive);
  yield takeLatest(actions.getTotalPointCustomer.fetch, getTotalPointCustomer);
}
