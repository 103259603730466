import React from 'react';

const IconLogoMomoSetting = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">
            <rect width="48" height="48" rx="8" fill="#A50064" />
            <path d="M34.9471 22.2376C39.4288 22.2376 43.1023 18.6376 43.1023 14.1559C43.1023 9.67424 39.4288 6.07422 34.9471 6.07422C30.4655 6.07422 26.792 9.67424 26.792 14.1559C26.792 18.6376 30.4655 22.2376 34.9471 22.2376ZM34.9471 10.6293C36.8574 10.6293 38.4002 12.1722 38.4002 14.0824C38.4002 15.9926 36.8574 17.5355 34.9471 17.5355C33.0369 17.5355 31.4941 15.9926 31.4941 14.0824C31.4941 12.1722 33.0369 10.6293 34.9471 10.6293Z" fill="white" />
            <path d="M34.9471 25.7637C30.4655 25.7637 26.792 29.3637 26.792 33.8454C26.792 38.327 30.4655 41.927 34.9471 41.927C39.4288 41.927 43.1023 38.327 43.1023 33.8454C43.1023 29.3637 39.4288 25.7637 34.9471 25.7637ZM34.9471 37.2984C33.0369 37.2984 31.4941 35.7556 31.4941 33.8454C31.4941 31.9351 33.0369 30.3923 34.9471 30.3923C36.8574 30.3923 38.4002 31.9351 38.4002 33.8454C38.4002 35.7556 36.8574 37.2984 34.9471 37.2984Z" fill="white" />
            <path d="M18.3429 25.7637C16.947 25.7637 15.698 26.2045 14.6694 27.0127C13.6409 26.278 12.3919 25.7637 10.9959 25.7637C7.61633 25.7637 4.89795 28.4821 4.89795 31.8617V42.0005H9.60002V31.7882C9.60002 31.0535 10.1878 30.3923 10.9959 30.3923C11.8041 30.3923 12.3919 30.98 12.3919 31.7882V42.0005H17.0205V31.7882C17.0205 31.0535 17.6082 30.3923 18.4164 30.3923C19.1511 30.3923 19.8123 30.98 19.8123 31.7882V42.0005H24.4409V31.7882C24.4409 28.4821 21.7225 25.7637 18.3429 25.7637Z" fill="white" />
            <path d="M18.3429 6C16.947 6 15.698 6.44082 14.6694 7.24899C13.6409 6.51429 12.3919 6 10.9959 6C7.61633 6 4.89795 8.71838 4.89795 12.098V22.2368H9.60002V12.0245C9.60002 11.2898 10.1878 10.6286 10.9959 10.6286C11.8041 10.6286 12.3919 11.2164 12.3919 12.0245V22.2368H17.0205V12.0245C17.0205 11.2898 17.6082 10.6286 18.4164 10.6286C19.1511 10.6286 19.8123 11.2164 19.8123 12.0245V22.2368H24.5144V12.098C24.4409 8.71838 21.7225 6 18.3429 6Z" fill="white" />
        </svg>
    );
};

export default IconLogoMomoSetting;