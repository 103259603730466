import MultipleSelect, { IOptionMulti } from 'components/common/MultipleSelect';
import bookingActions from 'features/bookings/services/actions';
import apisBooking from 'features/bookings/services/apis';
import bookingSelectors from 'features/bookings/services/selectors';
import { IApiBookingParams } from 'features/bookings/services/types/booking';
import { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from 'store/hooks';

/**
 * Render status picker
 * @returns 
 */
const StatusPicker = () => {
  const dispatch = useAppDispatch();
  const lstBookingStatus = bookingSelectors.getLstBookingStatus();
  const value = bookingSelectors.getCalendarParamValue('book_status') as IApiBookingParams['book_status'];
  const { t: bookingLang } = useTranslation('booking');
  const options: IOptionMulti[] = useMemo(() => {
    return lstBookingStatus.map(item => {
      return ({
        value: item.value,
        title: bookingLang(item.label === 'In progress' ? 'InProgress' : item.label === 'No show' ? 'NoShow' : item.label),
      });
    });
  }, [lstBookingStatus]);


  const onChange = async (val: (string | number)[]) => {
    dispatch(bookingActions.setCalendarBookingParams({
      book_status: val,
    }));
    await apisBooking.storeParamOptionCalendar({action_behavior: 'booking_status_filter', action_behavior_value: val});
  };
 
  return (
    <div className='d-flex'>
      <MultipleSelect
        onChange={onChange}
        value={value ?? []}
        options={options}
        isWhiteInput
        maxTagPlaceholder={bookingLang('Status')|| ''}
        placeholder={bookingLang('SelectStatus') || ''}
      />
    </div>
  );
};

export default memo(StatusPicker);
