import { Form, Popover } from 'antd';
import useFormInstance from 'antd/es/form/hooks/useFormInstance';
import serviceSelectors from 'features/services/services/selectors';
import { useEffect, useState } from 'react';
import styled from 'styled-components';

interface IColor {
  id: number;
  color: string;
}

type Props = {
  useSelect?: boolean;
  defaultValue?: string;
};

/**
 * Picker color
 * @param useSelect, defaultValue 
 * @returns 
 */
const PickerColor = ({ useSelect, defaultValue }: Props) => {
  const [selectedColor, setSelectedColor] = useState<IColor>();
  const colorOptions = serviceSelectors.getColorCategory();

  useEffect(() => {
    if (defaultValue !== undefined) {
      setSelectedColor(colorOptions?.find((o: IColor) => o?.color?.toString() === defaultValue?.toString()));
    }
  }, [defaultValue]);

  const [open, setOpen] = useState(false);
  const form = useFormInstance();

  useEffect(() => {
    setSelectedColor(colorOptions.length > 0 && colorOptions[0]);
  }, [colorOptions]);

  useEffect(() => {
    form.setFieldValue('color', selectedColor?.id);
  }, [selectedColor]);

  /**
   * styles
   */
  const styles = {
    color: {
      background: selectedColor?.color ?? (colorOptions.length > 0 && colorOptions),
    },
  };

  /**
   * handle color click
   * @param o 
   */
  const handleColorClick = (o: IColor) => {
    setSelectedColor(o);
    setOpen(false);
  };

  /**
   * render color options
   * @returns 
   */
  const renderColorOptions = () => {
    return (
      <Form.Item name={'color'}>
        <ColorPickerStyled>
          <div className="color-options">{
            colorOptions.map((o: IColor, index: number) => (
              <div
                key={index}
                className={`color-option ${selectedColor?.id === o?.id ? 'selected' : ''}`}
                onClick={() => handleColorClick(o)}
              >
                <div className='color' style={{ backgroundColor: o?.color }}></div>
              </div>
            ))
          }</div>
        </ColorPickerStyled>
      </Form.Item>
    );
  };

  // const toggleColorPicker = () => {
  //     setIsOpen(!isOpen);
  // };

  return (
    // <div style={{ marginLeft: '50px', clear: 'both', whiteSpace: 'nowrap' }}>
    <Popover
      open={open}
      onOpenChange={setOpen}
      content={renderColorOptions}
      trigger={'click'}
      placement={useSelect ? 'bottomLeft' : 'bottomRight'}
      arrow={false}
    >
      {useSelect ?
        <InputColor selectedColor={selectedColor} onClick={() => setOpen(true)} /> :
        <>
          <div className="picker-color" onClick={() => setOpen(true)}>
            <div>
              <div style={styles.color} className="color" />
            </div>
          </div>
        </>}
    </Popover>
    // </div>
  );
};

export default PickerColor;

/**
 * Input color
 * @param selectedColor, onClick 
 * @returns 
 */
const InputColor = ({ selectedColor, onClick }: { selectedColor: IColor | null | undefined, onClick: () => void }) => {
  return <InputColorStyled type='button' onClick={onClick}>
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <circle cx="12" cy="12" r="12" fill={selectedColor?.color} />
    </svg>
    <div className='arrow'>
      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
        <path d="M16.6665 6.66634L10.7069 12.6259C10.3164 13.0164 9.68326 13.0164 9.29273 12.6259L3.33317 6.66634" stroke="#121219" strokeWidth="1.5" strokeLinecap="round" />
      </svg>
    </div>
  </InputColorStyled>;
};
const InputColorStyled = styled.button`
  width:100%;
  border-radius: 6px;
  // background: #F0F2F7;
  display: flex;
  height: 64px;
  padding: 20px 16px;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border: 1px solid #CCC;
  .color {
    width: 24px;
    height: 24px;
  }
  .arrow {
    display: flex;
    width: 32px;
    height: 32px;
    padding: 4px;
    justify-content: center;
    align-items: center;
  }
`;

const ColorPickerStyled = styled.div`

    // background-color: var(--color-white);
    // right: 2px;
    // z-index: 100;
    // position: absolute;
    // top: 70px;

  .selected-color {
    width: 30px;
    height: 30px;
    border: 1px solid #ccc;
    border-radius: 24px;
  }
  
  .toggle-icon {
    transition: transform 0.3s;
  }
  
  .rotate {
    transform: rotate(90deg);
  }
  
  .color-options {
    display: flex;
    flex-wrap: wrap;
    -webkit-box-pack: start;
    justify-content: flex-start;
    // transition: all 0.3s ease 0s;
    max-width: 230px;
    // padding: 18px 10px;
    // box-shadow: rgba(0, 0, 0, 0.16) 0px 12px 12px -6px, rgba(0, 0, 0, 0.4) 0px 0px 1px;
    // border-radius: 10px;
    // margin-top: 8px;
  }
  
  .color-option {
    width: 30px;
    height: 30px;
    cursor: pointer;
    border-radius: 24px;
    margin: 0 8px 8px 8px;
    .color {
      width: 100%;
      height: 100%;
      border-radius: 100%;
    }
  }
  
  .color-option.selected {
    padding: 1px;
    border: 1px solid #000;
  }
`;
