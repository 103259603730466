import { Checkbox, Form } from 'antd';
import IconDecrease from 'assets/svg/IconDecrease';
import IconIncrease from 'assets/svg/IconIncrease';
import bookingSelectors from 'features/bookings/services/selectors';
import { IServiceItem } from 'features/bookings/services/types/service';
import { StaffPublic } from 'features/users/services/types/teamMember';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { ANONYMOUS_NAME, formatMoney, formatTimeMinutes } from 'utils/unit';
export type IFuncGetIsCheck = (o: { service_id: number, service_variant_id?: number }) => boolean;
interface Props extends React.HTMLAttributes<HTMLDivElement> {
  dataService: any;
  data: IServiceItem;
  onServiceChange: (data: IServiceItem, dataService: any) => void;
  updateQuantity: (id: IServiceItem['id'], service_variant_id: IServiceItem['service_variant_id'], quantity: number) => void;
  selectedList?: IServiceItem[];
  employeeList?: StaffPublic[]
  isTimeRoster: boolean;
}

const ServiceItem: React.FC<Props> = ({ dataService, data, className, onServiceChange, updateQuantity, selectedList, employeeList, isTimeRoster }) => {
  if (data.subServices && data.subServices?.length > 0) {
    return <ServiceGroup isTimeRoster={isTimeRoster} dataService data={data} onServiceChange={onServiceChange} updateQuantity={updateQuantity} selectedList={selectedList} employeeList={employeeList} />;
  }
  const {t: dateLang} = useTranslation('date');
  const {t: bookingLang} = useTranslation('booking');
  const selectedItem = selectedList?.find(o => o.id === data.id && o.service_variant_id === data.service_variant_id);
  const teamMemberTimeRosterSelected = bookingSelectors.bookingOnline.getTeamMemberSelected();
  const isChecked = !!selectedItem;

  const employeeName = useMemo(() => {
    return selectedItem ? (!selectedItem?.employee_id ? bookingLang(ANONYMOUS_NAME === 'Any team member' ? 'AnyTeamMember' : '') : employeeList?.find((o: any) => o.id === Number(selectedItem?.employee_id))?.full_name) : '';
  }, [selectedItem]);
  const quantity = selectedItem?.quantity ?? 1;

  const increase: React.MouseEventHandler<HTMLSpanElement> = (e) => {
    e.stopPropagation();
    updateQuantity(data.id, data.service_variant_id, quantity + 1);
  };

  const decrease: React.MouseEventHandler<HTMLSpanElement> = (e) => {
    e.stopPropagation();
    if (quantity === 1) return;
    updateQuantity(data.id, data.service_variant_id, quantity - 1);
  };

  

  return (
    <ServiceItemStyled className={className} onClick={() => onServiceChange(data, dataService)}>
      <td className='service_content'>
        <div className='group_name'>
          <div className='checkbox'>
            <Form.Item name='service'>
              <Checkbox checked={isChecked} />
            </Form.Item>
          </div>
          <div className='flex-spacing' >
            <div className='service-table'>
              <p className={'service_name service-name-ellipsis'}>{dataService.name} {dataService.name ? ' - ' : ''} {data.name}</p>
              <p className='service_time'>
                {(data.duration ? formatTimeMinutes(data.duration, dateLang) : '') + (!isTimeRoster ? ` ${bookingLang('with')} ${employeeName}` : ( teamMemberTimeRosterSelected.length === 1 && isChecked ? ` ${bookingLang('with')} ${ teamMemberTimeRosterSelected?.[0].full_name }` : '')) + ' - ' + formatMoney(data?.price ?? 0)}
              </p>
            </div>
            <div className={`quantity-control ${isChecked ? 'checked' : 'none-checked'}`} onClick={e => e.stopPropagation()}>
              <span className={'control-btn'} onClick={decrease}>
                <IconDecrease />
              </span>
              <span className='quantity-value'>{selectedItem?.quantity}</span>
              <span className='control-btn' onClick={increase}>
                <IconIncrease />
              </span>
            </div>
          </div>
        </div>
      </td>
      {/* <td className='service_amount'>
        <p>{formatMoney(data?.price ?? 0)}</p>
      </td> */}
    </ServiceItemStyled>
  );
};

export default ServiceItem;

const ServiceGroup: React.FC<Props> = ({ data,
  // className,
  onServiceChange, updateQuantity, selectedList, employeeList, isTimeRoster }) => {
  const [open,] = useState(true);
  // const toggle = () => setOpen(!open);
  if (data?.subServices && data.subServices?.length === 0) return null;

  return (
    <React.Fragment>
      {/* <ServiceItemStyled className={`have_child ${className}`} onClick={toggle}>
        <td className='service_content'>
          <div className='group_name'>
            <button type='button' className='checkbox'>
              {open ? <IconUp /> : <IconDown />}
            </button>
            <div>
              <p className='service_name'>{data.name}</p>
            </div>
          </div>
        </td>
        <td className='service_amount'>
        </td>
      </ServiceItemStyled> */}
      {open &&
        data?.subServices?.map((item) => (
          <ServiceItem
            isTimeRoster={isTimeRoster}
            className='is_child'
            key={item.id + '-' + item.service_variant_id}
            data={item}
            dataService={data}
            onServiceChange={onServiceChange}
            updateQuantity={updateQuantity}
            selectedList={selectedList}
            employeeList={employeeList}
          />
        ))}
    </React.Fragment>
  );
};

// const IconDown = () => {
//   return (
//     <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
//       <path d="M4.26562 6.94238L8.26563 10.9424L12.2656 6.94238" stroke="#363565" strokeLinecap="round" strokeLinejoin="round" />
//     </svg>

//   );
// };

// const IconUp = () => {
//   return (
//     <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
//       <path d="M12.2656 10.9424L8.26563 6.94238L4.26562 10.9424" stroke="#363565" strokeLinecap="round" strokeLinejoin="round" />
//     </svg>
//   );
// };

const viewportWidth = window.innerWidth;

const ServiceItemStyled = styled.tr`

  &:hover {
    cursor: pointer;
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
  }
  &:not(.have_child):hover {
    cursor: pointer;
    background: #F0F2F7;
  }
  .service-table {
    @media only screen and (max-width: 767.98px) {
      /* width: 125px; */
    }
  }
  .control-btn {
    @media only screen and (max-width: 767.98px) {
      display: none;
    }
  }
  .flex-spacing {
    display:flex;
    flex: 1;
    gap: 20px;
    justify-content: space-between;
    @media only screen and (max-width: 767.98px) {
      gap: 20px;
    }
  }
  .quantity-control {
    &.none-checked {
      display:none;
    }
    &.checked {
      display:flex;
    }
  }
  &.is_child {
    /* background-color: var(--color-white-01); */
    padding-left: 10px;
    /* .group_name {
      padding-left: 28px;
    } */
    &:hover {
      background-color:#e1e2e6;
    }
  }
  button {
    background: transparent;
    outline: none;
    border: none;
  }
  .service_table_item {
  @media only screen and (max-width: 767.98px) {
    width: 150px;
  }
}
.control-btn {
  display: none;
}
  td {
    vertical-align: middle;
    padding: 10px 0px;
    color: var(--color-primary);
  }

  .service_content {
    padding-left: 8px;
    width: 100%;
    .checkbox {
      margin-right: 16px;
    }
    .group_name {
      display: flex;
      align-items: center;
      @media only screen and (max-width: 767.98px) {
        /* width: 150px */
      }
    }
    .service_name {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 140%;
      color: #363565;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .service-name-ellipsis {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: ${viewportWidth * 0.23}px;
      display: block;
      @media only screen and (max-width: 767.98px) {
        width: ${viewportWidth * 0.55}px;
      }
      @media only screen and (min-width: 768px) and (max-width: 1180.98px) {
        width: ${viewportWidth * 0.38}px;
      }
    }
    .service_time {
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 140%;
      color: #363565;
    }
  }
  .service_amount {
    padding-right: 8px;
    font-weight: 600;
    font-size: 16px;
    line-height: 140%;
    text-align: right;
    white-space: nowrap;
    .label {
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 140%;
      color: #363565;
    }
  }
`;
