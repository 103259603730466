import { Form, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { IFormAddCustomerData } from 'features/customers/widgets/AddNewCustomer/CustomersForm';
import UserFormFirstStep from './FirstStep';
import UserFormSecondStep from './SecondStep';
import { useAppDispatch } from 'store/hooks';
import userActions from 'features/users/services/actions';
import UserFormFouthStep from './FouthStep';
import { useTranslation } from 'react-i18next';
import { get, set } from 'lodash';

const DEFAULT_STEP = 1;
const validateMessages = {
  required: '${label} is required!',
  types: {
    email: '${label} is not a valid email!',
    number: '${label} is not a valid number!',
  },
  number: {
    range: '${label} must be between ${min} and ${max}',
  },
};

export interface IRole {
  id: number,
  name: string
}

export interface IFormAddStaffData {
  name?: string;
  phone?: string;
  email?: string;
  accessLevel?: any;
  pinNumber?: string;
  title?: string;
  location?: any;
  isReceiveBook?: boolean;
  services: string[],
  workingTime?: any,
  is_manager?: boolean,
  password?: string,
  role?: number,
}

export type IFuncCancel = () => void;
export type IFuncSubmit = (data: IFormAddCustomerData) => Promise<boolean>;

type Props = {
  onCancel?: () => void;
  onSubmit?: IFuncSubmit;
  formCustom?: any;
  errors?: any;
  isEdit?: boolean;
  defaultData?: IFormAddStaffData;
};
const ModalUserForm: React.FC<Props> = ({
  onCancel = () => undefined,
  onSubmit = () => undefined,
  formCustom,
  errors = {},
  isEdit,
  defaultData,
}) => {
  const [step, setStep] = useState(DEFAULT_STEP);
  const [form] = Form.useForm();
  const [selected, setSelected] = useState<string[]>([]);
  const dispatch = useAppDispatch();
  const{t: userLang} = useTranslation('user');

  useEffect(() => {
    form.setFieldValue('services', [...selected]);
  }, [selected]);

  useEffect(() => {
    if (defaultData) {
      setSelected(defaultData.services);
      form.setFieldsValue(defaultData);
      dispatch(userActions.setLocationSelectedId(defaultData?.location?.value));
    }
  }, [defaultData]);  

  useEffect(() => {

    if (Object.keys(errors).some((o: string) => ['full_name', 'phone', 'pin_number'].includes(o))) {
      setStep(1);
      const valid = Object.keys(errors).map(o => ({
        name: o,
        errors: [errors[o]]
      }));
      form.setFields(valid);
    }
    
    
  }, [errors]);

  const handleCancel = () => {
    onCancel();
    setStep(DEFAULT_STEP);
    form.resetFields();
  };

  const checkValid = async () => {
    try {
      await form.validateFields([
        'name',
        'phone',
        'pinNumber',
        'location',
        'password'
      ]);

      // if (isEdit) {
      //   if (defaultData?.pinNumber !== form.getFieldValue('pinNumber'))
      //     await userApis.checkPinNumberExist({
      //       pin_number: form.getFieldValue('pinNumber')
      //     });
      // } else {
      //   await userApis.checkPinNumberExist({
      //     pin_number: form.getFieldValue('pinNumber')
      //   });
      // }

      return true;
    } catch (error: any) {
      const response = get<Record<string, string[]>>(
        error,
        'response.data.errors',
        {}
      );
      const errors = Object.entries(response);
      const errorsObj = {};
      errors.forEach(([key, value]) => {
        set(errorsObj, [key], value?.[0]);
      });

      errors.map(([, value]) => {
        form.setFields([
          {
            name: 'pinNumber',
            errors: [get(value, [0], '')]
          }
        ]);

      });
      return false;
    }
  };

  const nextStep  = async (e: any) => {
    e.preventDefault();
    const isFormValid = await checkValid();
    if (!isFormValid) return;
    setStep((currPage) => currPage + 1);
  };

  const prevStep = () => {
    setStep((currPage) => currPage - 1);
  };

  const _onSubmit = () => {
    (formCustom ?? form).submit();
  };
  
  const [loading, setLoading] = useState(false);
  const handleSubmit = async (values: any) => {
    setLoading(true);
    try {
      const success = await onSubmit(values);
      if (success) {
        (formCustom ?? form).resetFields();
        setSelected([]);
        setStep(1);
      }
    } catch (error) {
      setStep(1);      
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div className="modal-title">
        <div className='button-close' onClick={handleCancel}>
          <CloseIcon />
        </div>
        <p>{!isEdit ? userLang('AddNewUser') : userLang('EditUser') }</p>
        <div className='button'>
          {step !== 1 && <button
            className='common-btn is-white'
            onClick={prevStep}
          >
            {userLang('Back')}
          </button>}
          <button
            className='common-btn'
            onClick={step !== 3 ? nextStep : _onSubmit}
          >
            {userLang('Continue')}
          </button>
        </div>
      </div>
      <Form
        form={form}
        layout="vertical"
        onFinish={handleSubmit}
        initialValues={defaultData}
        validateMessages={validateMessages}
      >
        
        <Spin spinning={loading}>
          <div className='form-container' style={step !== 1 ? { display: 'none' } : undefined}>
            <UserFormFirstStep defaultData={defaultData} isEdit={isEdit} errors={errors} />
          </div>
          <div className='form-container' style={step !== 2 ? { display: 'none' } : undefined}>
            <UserFormSecondStep 
              // onSubmit={_onSubmit} 
              setSelected={setSelected}
              selected={selected} 
              isManager={defaultData?.is_manager}
            />
          </div>
          <div className='form-container'  style={step !== 3 ? { display: 'none' } : undefined}>
            <UserFormFouthStep onPrevStep={prevStep} onSubmit={_onSubmit} isEdit={false}/>
          </div>
        </Spin>
      </Form>
    </>
  );
};

export default ModalUserForm;

const CloseIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M16.2431 7.75739L7.75781 16.2427M16.2431 16.2426L7.75781 7.75734" stroke="#141414" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
};
