const IconUserMobileAnalytics = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="33"
      height="32"
      viewBox="0 0 33 32"
      fill="none"
    >
      <g clipPath="url(#clip0_1813_174416)">
        <path
          d="M16.5 32C25.3366 32 32.5 24.8366 32.5 16C32.5 7.16344 25.3366 0 16.5 0C7.66344 0 0.5 7.16344 0.5 16C0.5 24.8366 7.66344 32 16.5 32Z"
          fill="#FF2147"
        />
        <path
          d="M16.5001 15.2788C18.7172 15.2788 20.5145 13.4815 20.5145 11.2644C20.5145 9.0473 18.7172 7.25 16.5001 7.25C14.283 7.25 12.4857 9.0473 12.4857 11.2644C12.4857 13.4815 14.283 15.2788 16.5001 15.2788Z"
          fill="white"
        />
        <path
          d="M12.5738 16.8838H20.4263C20.8847 16.8838 21.3386 16.9741 21.7622 17.1496C22.1857 17.325 22.5705 17.5822 22.8945 17.9064C23.2186 18.2306 23.4757 18.6155 23.651 19.0391C23.8263 19.4627 23.9165 19.9166 23.9163 20.375V23.0625C23.9163 23.5101 23.7385 23.9393 23.422 24.2558C23.1056 24.5722 22.6764 24.75 22.2288 24.75H10.7738C10.3263 24.75 9.89703 24.5722 9.58056 24.2558C9.26409 23.9393 9.0863 23.5101 9.0863 23.0625V20.375C9.08597 19.4496 9.45318 18.562 10.1072 17.9073C10.7612 17.2526 11.6484 16.8845 12.5738 16.8838Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_1813_174416">
          <rect
            width="32"
            height="32"
            fill="white"
            transform="translate(0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default IconUserMobileAnalytics;
