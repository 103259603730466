import { IBookingItemResData } from 'features/bookings/services/types/booking';
import { useState } from 'react';
import { useAppDispatch } from 'store/hooks';
import { IDndDataCalendar } from './RowCalendar';
import moment from 'moment';
import { BREAK_TIME_MINUTES, TIME_START_FORMAT_RESPONSE } from 'features/bookings/services/constants';
import apisBooking from 'features/bookings/services/apis';
import message from 'components/common/Message';
import bookingActions from 'features/bookings/services/actions';
import { get } from 'lodash';
import { translate } from 'utils/moment/getSingleDateFormat';

/**
 * hook use update date view
 * @returns 
 */
export const useUpdateDateView = (): [boolean, ((book: IBookingItemResData, overDnD: IDndDataCalendar, pin_number?: string) => Promise<void>)] => {
  const dispatch = useAppDispatch();
  const [loadingUpdate, setLoadingUpdate] = useState(false);

  const updateBooking = async (book: IBookingItemResData, overDnD: IDndDataCalendar, pin_number?: string) => {
    const timeStart = moment(overDnD.rowTime);

    const bookId = book.id?.toString();
    if (!bookId) return;

    const _time_start = timeStart.clone();
    const book_assignment_services = book.book_assignment_services.map((o) => {
      const result = {
        service_id: o?.service_id,
        time_start: _time_start.format(TIME_START_FORMAT_RESPONSE),
        duration_time: o?.duration_time,
        quantity: o?.quantity,
        employee_id: o?.assigned_employee?.employee?.id ?? null,
        sale_price: o?.service_price,
        service_variant_id: o?.service_variant_id ?? null,
      };
      const breakTimeMinutes = BREAK_TIME_MINUTES;
      const quantity = (o.quantity ?? 0);
      const prevServiceMinutes = ((o.duration_time ?? 0) * quantity) + (quantity - 1) * breakTimeMinutes;
      _time_start.add(breakTimeMinutes + prevServiceMinutes, 'minute');
      return result;
    });

    const payload = {
      book_start: timeStart.format(TIME_START_FORMAT_RESPONSE),
      customer_id: book?.customer?.id,
      customer_code: book?.customer?.customer_code,
      note: book?.appointment_note || book?.noted_client,
      book_assignment_services,
      is_walkin_in: !!book?.customer?.is_walkin_in,
      action_update: 'drag_drop',
      pin_number
    };
    setLoadingUpdate(true);
    try {
      const response = await apisBooking.updateBooking(bookId || '', payload);
      if (response?.data.error) {
        message.error(response?.data.error.message);
      } else {
        message.success(translate('common', 'message.BookEditSuccessfully'));
        dispatch(bookingActions.getBookings.fetch({}));
      }
    } catch (error: any) {
      const response = get<Record<string, string[]>>(
        error,
        'response.data.errors',
        {}
      );

      const errors = Object.entries(response);
      errors.map(([, value]) => {
        message.error(value[0]);
      });

    } finally {
      setLoadingUpdate(false);
    }
  };

  return ([loadingUpdate, updateBooking]);
};

export default useUpdateDateView;
