import { Form, Spin } from 'antd';
// import SwitchInput from 'components/common/Input/SwitchInput';
import { IOptionMulti } from 'components/common/MultipleSelect';
import { debounce, get, set } from 'lodash';
import { useEffect, useMemo, useRef, useState } from 'react';
import voucherSelectors from '../services/selectors';
import { IApiVoucherCreateBodyData } from '../services/types/api';
import styled from 'styled-components';
import vouchersApis from '../services/apis';
import settingSelectors from 'features/settings/services/selectors';
import { formatMoney } from 'utils/unit';
import { useTranslation } from 'react-i18next';
import { getFormatShortDateAndTime } from 'utils/moment/getSingleDateShortFormat';
// import NumberValidations from 'utils/validations/number';


export type IVoucherFormValue = {
  voucherCode: string;
  voucherName: string;
  value: number;
  retailPrice: number;
  validityPeriod: string;
  terms: string;
  restrictService: string[];
  enable?: boolean,
  redeemed?: number;
  remaining?: number;
  customerName?: string;
  expiryDate?: string;
  targetQuantity?: number;
  unlimited?: boolean;
  invoice?: any;
  total?: any;
  location?: any;
};

export type VoucherFormProps = {
  isEdit: boolean;
  viewOnly?: boolean;
  onCancel?: () => void;
  onSubmit?: (payload: IApiVoucherCreateBodyData) => void;
  formData?: IVoucherFormValue;
  errors?: any;
  updateVoucherStatus?: (checked: boolean) => Promise<boolean>;
  loading?: boolean;
  voucherLang?: any;
};

const DetailVoucher = ({voucherLang, isEdit, errors = {}, formData, onSubmit = () => undefined, loading }: VoucherFormProps) => {
  const setting = settingSelectors.getSetting();
  // const [, setIsUnlimited] = useState<boolean>(false);

  const [form] = Form.useForm();
  const [selected, setSelected] = useState<(string | number)[]>([]);
  const [, setValuePrice] = useState<number>();
  const servicesStore = voucherSelectors.getServices();
  const [, setEnable] = useState(false);
  const [, setLoadingVoucherCode] = useState(false);
  const params = voucherSelectors.getLstVoucherParams();
  const { t: dateLang } = useTranslation('date');

  const generateVoucherCode = debounce(async () => {
    if (isEdit) return;
    setLoadingVoucherCode(true);
    try {
      const res = await vouchersApis.generateVoucherCode();
      const voucher_code = get(res, 'data.data.voucher_code', '');
      if (voucher_code)
        form.setFieldValue('voucherCode', voucher_code);
    } catch (error) { } finally {
      setLoadingVoucherCode(false);
    }
  }, 100);

  useEffect(() => {
    if (isEdit) return;
    generateVoucherCode();
  }, []);

  const services = useMemo(() => {
    const result: IOptionMulti[] = [];
    servicesStore?.filter(name => name.enable_discount === 1 && name.enable_voucher_sale === 1)?.forEach((service) => {
      if (service?.service_variants?.length > 0) {
        service?.service_variants.forEach(serviceVariant => {
          result.push({
            value: service?.id + '-' + serviceVariant.id,
            title: service.name + ' - ' + serviceVariant.name,
            // value: serviceVariant.id,
            // title: serviceVariant.name,
          });
        });
      } else {
        result.push({
          value: service.id + '',
          title: service.name,
        });
      }
    });
    return result;
  }, [servicesStore]);


  // const services = useMemo(() => {
  //   const result: IOptionMulti[] = [];
  //   servicesStore?.filter(name => name.enable_discount === 1 && name.enable_voucher_sale === 1)?.forEach((service) => {
  //     result.push({
  //       value: service.id + '',
  //       title: service.name,
  //     });
  //   });
  //   return result;
  // }, [servicesStore]);

  const allServices = useMemo(() => {
    return services.map(o => o.value);
  }, [servicesStore]);

  useEffect(() => {
    if (isEdit) return;
    if (services.length > 0) {
      setSelected([...allServices]);
    }
  }, [services]);

  useEffect(() => {
    if (!isEdit) {
      const terms_conditions_voucher = setting?.booking?.terms_conditions;
      form.setFieldValue('terms', terms_conditions_voucher);
      return;
    }

    form.setFieldsValue(formData);
    setEnable(!!formData?.enable);
    setSelected(formData?.restrictService ?? []);
    setValuePrice(formData?.value || undefined);
  }, [formData]);

  useEffect(() => {
    form.setFieldValue('restrictService', selected);
  }, [selected]);

  const getPayload = (values: IVoucherFormValue) => {
    const result: IApiVoucherCreateBodyData = {
      name: values.voucherName,
      retail_price: values.retailPrice,
      validity_period_key: values.validityPeriod,
      value: values.value,
      services: [],
      is_enable: params.view === 'purchased' ? values.enable : undefined,
      status: !!values.enable,
      description: values.terms,
      voucher_code: values.voucherCode ?? '',
      target_quantity: values?.unlimited ? undefined : (values.targetQuantity ?? 1),
      unlimited: true
    };

    if (isEdit) {
      set(result, 'value_key', values.value);
    }

    const restrict_services: { id: number, service_variant_ids: number[] }[] = [];
    values.restrictService.forEach(o => {
      if (!o) return;
      const item = o?.split('-');
      const serviceId = get(item, 0, '');
      const serviceVariant = get(item, 1, '');
      const parent = restrict_services.find(o => o.id?.toString() === serviceId?.toString());
      if (!parent) {
        restrict_services.push({
          id: +serviceId,
          service_variant_ids: serviceVariant ? [+serviceVariant] : [],
        });
      } else {
        if (serviceVariant) parent.service_variant_ids.push(+serviceVariant);
      }
    });
    result.services = restrict_services;

    return result;
  };

  const handleSubmit = (values: IVoucherFormValue) => {
    const payload = getPayload(values);
    if (!payload) return;
    onSubmit(payload);
  };

  const errorsText = useMemo(() => ({
    voucherCode: get(errors, 'voucher_code', ''),
    voucherName: get(errors, 'name', ''),
    retailPrice: get(errors, 'retail_price', ''),
    validityPeriod: get(errors, 'validity_period_key', ''),
    value: get(errors, 'value', ''),
    restrictService: get(errors, 'services', ''),
    enable: get(errors, 'status', ''),
    terms: get(errors, 'description', ''),
  }), [errors]);

  const myFocus = useRef<any>();

  useEffect(() => {
    myFocus?.current?.input?.focus();
  }, []);
  const [randomString, setRandomString] = useState('');
  useEffect(() => {
    if (!randomString) {
      const length = 8;
      const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      let result = '';
      for (let i = 0; i < length; i++) {
        const randomIndex = Math.floor(Math.random() * characters.length);
        result += characters.charAt(randomIndex);
      }
      setRandomString(result);
    }

  }, [randomString]);

  const numberService =
    selected.length === services.length
      ? 'Tất cả dịch vụ'
      : `${selected.length} dịch vụ`;

  const { t: bookingLang } = useTranslation('booking');

  return (
    <DetailVoucherStyled>
      <Spin spinning={services.length === 0 || !!loading}>
        <Form
          className='modal-form'
          autoComplete='off'
          form={form}
          layout='vertical'
          onFinish={handleSubmit}
          initialValues={{
            enable: formData?.enable ?? true,
            validityPeriod: 'years_3',
            targetQuantity: 1, errorsText
          }}
        >
          <div className='label-container'>
            <p className='title'>{voucherLang('Invoice')} #{formData?.voucherCode}</p>
            <ul>
              <li>
                <p>{voucherLang('DateCreated')}</p>
                <p className='text-data'>{getFormatShortDateAndTime(formData?.invoice?.created_at, dateLang)}</p>
              </li>
              {/* <li>
                <p>Ngày thanh toán</p>
                <p className='text-data'>Ngày thanh toán</p>
              </li> */}
              <li>
                <p>{voucherLang('Location')}</p>
                <p className='text-data'>{formData?.location?.name}</p>
              </li>
              <li>
                <p>{voucherLang('Customer')}</p>
                <p className='text-data'>{formData?.customerName}</p>
              </li>
            </ul>
          </div>

          <div className='label-container'>
            <p className='title'>{voucherLang('VoucherInformation')}</p>
            <ul>
              <li>
                <p>{voucherLang('VoucherCode')}</p>
                <p className='text-data'>{formData?.voucherCode}</p>
              </li>
              <li>
                <p>{voucherLang('Name')}</p>
                <p className='text-data'>{formData?.voucherName}</p>
              </li>
              <li>
                <p>{voucherLang('ExpiryDate')}</p>
                <p className='text-data'>{formData?.expiryDate}</p>
              </li>
              <li>
                <p>{voucherLang('Apply')}</p>
                <p className='text-data'>{numberService}</p>
              </li>
              <li>
                <p>{voucherLang('Value')} / {voucherLang('Used')}</p>
                <p className='text-data'>{formatMoney(formData?.value ?? 0)} / {formatMoney(formData?.redeemed ?? 0)}</p>
              </li>
              <li>
                <p>{voucherLang('Remaining')}</p>
                <p className='text-data'>{formatMoney(formData?.remaining ?? 0)}</p>
              </li>
            </ul>
          </div>

          <div className='label-container'>
            <p className='title'>{voucherLang('BillingInformation')}</p>
            <ul>
              <li>
                <p>{voucherLang('Subtotal')}</p>
                <p className='text-data'>{formatMoney(formData?.total)}</p>
              </li>
              {/* <li>
                <p>Giảm giá</p>
                <p className='text-data'>{formatMoney(-1000000)}</p>
              </li> */}
              <li>
                <p>{voucherLang('Total')}</p>
                <p className='text-total'>{formatMoney(formData?.total)}</p>
              </li>
              <li>
                <p>{voucherLang('Payments')}</p>
                <p className='text-data'>{bookingLang(formData?.invoice?.payment_methods)}</p>
              </li>
              <li>
                <p className='text-note'>(*) {voucherLang('Included10%VAT')}</p>
                <p></p>
              </li>
            </ul>
          </div>
        </Form>
      </Spin>
    </DetailVoucherStyled>
  );
};

export default DetailVoucher;

const DetailVoucherStyled = styled.div`
margin-top: 30px;
justify-content: center;
display: flex;

.ant-spin-nested-loading {
    border: 1px solid transparent !important;
}

.label-container {
  display: grid;
  gap: 24px;
  .title {
    color: var(--Gray-95, #141414);
    font-family: Inter;
    font-size: 18px;
    font-weight: 600;
    line-height: 140%;
  }
  ul {
    display: grid;
    gap: 24px;
    padding-bottom: 24px;
    li {
      display: flex;
      justify-content: space-between;
      align-items: center;
      p {
        color: var(--Gray-95, #141414);
        font-family: Inter;
        font-size: 16px;
        font-weight: 400;
        line-height: 140%;
      }
      .text-note {
        color: var(--Gray-50, #8C8C8C);
        font-variant-numeric: slashed-zero;
        font-family: Inter;
        font-size: 14px;
        font-style: italic;
        font-weight: 400;
        line-height: 140%;
      }
      .text-data {
        color: var(--Gray-95, #141414);
        text-align: right;
        font-family: Inter;
        font-size: 16px;
        font-weight: 600;
        line-height: 140%;
      }
      .text-total {
        color: var(--Blue-Munsell-Main, #1B9AAA);
        text-align: right;
        font-family: Inter;
        font-size: 24px;
        font-weight: 600;
        line-height: 140%;
      }
    }
  }
}

.ant-spin-nested-loading {
  width: 600px;
  border-radius: 12px;
  border: 1px solid var(--Gray-50, #8C8C8C);
  padding: 24px;
}

.dislay-grid {
  @media only screen and (max-width: 767.98px) {
    display: grid;
  }
}

.code-voucher {
  text-decoration: transparent;
}

.form-submit-wrapper {
  bottom: -40px;
}
 .ant-input-disabled {
    background-color:#F0F2F7;
    color: #7980BC;
  }
  .ant-input-number-disabled {
    .ant-input-number-input {
      color: #7980BC;
      background-color:#F0F2F7;
    }
  }
  .ant-input-number-group .ant-input-number-group-addon:first-child {
    background-color:#F0F2F7;
    color: #7980BC;
  }
  .ant-select-disabled {
    &.ant-select {
      .ant-select-selector {
        background-color:#F0F2F7;
        color: #7980BC;
      }
    }
  }
  .ant-select-multiple.ant-select-disabled.ant-select{
    .ant-select-selector {
      background:#F0F2F7;
      background-color:#F0F2F7 !important;
      color: #7980BC !important;
    }
  }
  .ant-select-multiple.ant-select-lg .ant-select-selection-item {
    .ant-select-selection-item-content {
      // color: #7980BC !important;
    }
  }
  .ant-select-single.ant-select-show-arrow .ant-select-selection-item, .ant-picker .ant-picker-input > input {
    color: #7980BC !important;
  }
`;
