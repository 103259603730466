import { Empty, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import ModalConfirm, { ModalConfirmRef } from 'components/common/Modal/ModalConfirm';
import { useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useAppDispatch } from 'store/hooks';
import voucherActions from '../services/actions';
import { VOUCHER_VIEW } from '../services/constants';
import voucherSelectors from '../services/selectors';
import { IVoucherItemResData } from '../services/types/voucher';
import EditVoucher from '../widgets/EditVoucher';
import ViewInfoVoucher from '../widgets/ViewInfoVoucher';
// import { useSetLoadingPage } from 'services/UI/LoadingPage';
import { getFormatShortDateNoTime } from 'utils/moment/getSingleDateShortFormat';
import { formatMoney } from 'utils/unit';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import IconNameVoucher from 'assets/svg/IconNameVoucher';

type Props = {
  currView?: string;
};

const VouchersTable = (props: Props) => {
  const { currView } = props;

  const confirmRef = useRef<ModalConfirmRef>(null);
  const lstData = voucherSelectors.getLstVouchers();
  const pagination = voucherSelectors.getLstVoucherPagination();

  const loading = voucherSelectors.getLoadingLstVouchers();
  const loadingActions = voucherSelectors.getLoadingVoucherActions();
  // const setPageLoading = useSetLoadingPage();
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const [openEditVoucherModal, setOpenEditVoucherModal] = useState<boolean>(false);
  const [openViewVoucherModal, setOpenViewVoucherModal] = useState<boolean>(false);

  const { t: vouchersLang } = useTranslation('vouchers');

  // const sendMail = async (voucher: IVoucherItemResData) => {
  //   setPageLoading(true);
  //   try {
  //     const res = await vouchersApis.sendMail(voucher?.voucher_code, voucher?.customer?.customer_code?.replace('#', ''));
  //     const msg = get(res, 'data.message');
  //     if (msg) {
  //       message.success(msg);
  //       dispatch(voucherActions.setVoucherListParams.fetch({}));
  //       return true;
  //     } else {
  //       const errorMsg = get(res, 'data.error.message');
  //       if (errorMsg) {
  //         message.error(errorMsg);
  //         return false;
  //       } else throw 'fail';
  //     }
  //   } catch (error) {
  //     const errors = Object.values(get(error, 'response.data.errors', {}));
  //     if (errors.length > 0) {
  //       errors.map((valueArr) => {
  //         const msg = get(valueArr, [0], '');
  //         message.error(msg);
  //       });
  //       return false;
  //     }
  //     message.error('An error occurred. Please try again');
  //     return false;
  //   } finally {
  //     setPageLoading(false);
  //   }
  // };  

  const confirm = (id: string) => {
    confirmRef.current?.show({
      title: vouchersLang('AreYouSure'),
      msg: vouchersLang('AreYouSureRemoveThisVouchers'),
      icon: 'delete',
      submit: async () => {
        dispatch(voucherActions.deleteVoucher(id?.toString()));
      }
    });
  };

  const getVoucherDetail = (voucherCode: string, type: string) => {
    dispatch(voucherActions.getVoucherDetail.fetch({ voucherCode, type }));
  };
  const { t: voucherLang } = useTranslation('vouchers');
  const { t: dateLang } = useTranslation('date');

  const checkExpiration = (expiryDate: string) => {
    const expiryDateObj = new Date(expiryDate);
    const currentDate = new Date();
    const threeDaysBefore = new Date();
    const twoDaysBefore = new Date();
    const oneDaysBefore = new Date();
    threeDaysBefore.setDate(currentDate.getDate() + 3);
    twoDaysBefore.setDate(currentDate.getDate() + 2);
    oneDaysBefore.setDate(currentDate.getDate() + 1);

    if (currentDate <= expiryDateObj && expiryDateObj <= threeDaysBefore) {
      return { isExpiringSoon: true, text: '3 ngày' };
    } else if (currentDate <= expiryDateObj && expiryDateObj <= twoDaysBefore) {
      return { isExpiringSoon: true, text: '2 ngày' };
    } else if (currentDate <= expiryDateObj && expiryDateObj <= oneDaysBefore) {
      return { isExpiringSoon: true, text: '1 ngày' };
    } else if (expiryDateObj < currentDate) {
      return { isExpiringSoon: true, text: 'Hết hạn' };
    } else {
      return { isExpiringSoon: false, text: '' };
    }
  };

  const purchasedColumns: ColumnsType<IVoucherItemResData> = [
    {
      title: voucherLang('VoucherName'),
      dataIndex: 'name',
      key: 'name',
      fixed: 'left',
      render: (value: any, record: any) => (
        <NameVoucherStyled>
          <IconNameVoucher />
          <div className='name-voucher-container'>
            <p onClick={() => {
              getVoucherDetail(record?.voucher_code, 'purchased');
              setOpenViewVoucherModal(true);
            }}
              className='name'>{value}</p>
            <p className='voucher'>{voucherLang('Code')}: {record.voucher_code}</p>
          </div>
        </NameVoucherStyled>
      ),
    },
    {
      title: voucherLang('CustomerName'),
      dataIndex: 'customer',
      key: 'customer',
      className: 'nowrap',
      render: (value: any, record: any) => {
        return (
          <NameVoucherStyled>
            <div className='name-voucher-container'>
              <p
                className='name'
                onClick={() => {
                  getVoucherDetail(record?.voucher_code, 'purchased');
                  setOpenViewVoucherModal(true);
                }}>
                {value?.name}
              </p>
            </div>
          </NameVoucherStyled>
        );
      },
    },
    {
      title: voucherLang('ExpiryDate'),
      dataIndex: 'validity_period_label',
      key: 'validity_period_label',
      className: 'nowrap',
      render: (status: string, record: IVoucherItemResData) => {
        const { isExpiringSoon, text } = checkExpiration(record.expiry_date);
        return (
          <ValidityPeriodLabelStyled>
            <p>{getFormatShortDateNoTime(record.expiry_date, dateLang, 'DD-MM-YYYY')}</p>
            {
              text ?
                <p style={{ color: isExpiringSoon ? 'red' : 'black' }}>{`(${text})`}</p>
                : ''
            }
          </ValidityPeriodLabelStyled>
        );
      }
    },
    {
      title: `${voucherLang('Value')} / ${voucherLang('Redeemed')}`,
      dataIndex: 'value',
      key: 'value',
      // render: (value) => (value ? formatMoney(+value) : formatMoney(0)),
      render: (value: any, record: any) => {
        return (
          <div>{formatMoney(+value) + ' / ' + formatMoney(+record?.redeemed_value)}</div>
        );
      },
    },
    // {
    //   title: voucherLang('Redeemed'),
    //   dataIndex: 'redeemed_value',
    //   key: 'redeemed_value',
    //   render: (value) => (value ? formatMoney(+value) : formatMoney(0)),
    // },
    {
      title: voucherLang('Remaining'),
      dataIndex: 'remaining',
      key: 'remaining',
      render: (value) => (value ? formatMoney(+value) : formatMoney(0)),
    },

    {
      title: voucherLang('Status'),
      dataIndex: 'status',
      key: 'status',
      render: (status: string) => {
        const stt = status === 'deactive' ? 'Disable' : status;

        return (
          <StatusStyled>
            <div className={`common-status voucher ${stt}`}>{voucherLang(stt)}</div>
          </StatusStyled>
        );
      },
    },
    {
      title: voucherLang('Actions'),
      dataIndex: 'actions',
      key: 'actions',
      // render: (text: string, record: IVoucherItemResData) => (
      //   <ul className={'common-action'}>
      //     {/* <ViewInfoVoucher data={record} /> */}
      //     <li
      //       className='action-info'
      //       onClick={() => {
      //         getVoucherDetail(record?.voucher_code, 'purchased');
      //         setOpenViewVoucherModal(true);
      //       }}
      //     />
      //     <li className='action-send' onClick={() => sendMail(record)}></li>
      //   </ul>
      // ),
      render: (text: string, record: IVoucherItemResData) => (
        <ul className={'common-action'}>
          {/* <EditVoucher data={record} /> */}
          <li
            className='action-edit'
            onClick={() => {
              getVoucherDetail(record?.voucher_code, 'purchased');
              setOpenViewVoucherModal(true);
            }}
          />

          <li
            className='action-remove'
            onClick={() => confirm(record?.id?.toString())}
          ></li>
        </ul>
      ),
    },
  ];


  const saleColumns: ColumnsType<IVoucherItemResData> = [
    {
      title: voucherLang('VoucherName'),
      dataIndex: 'name',
      key: 'name',
      fixed: 'left',
      render: (text, record) => (
        <NameVoucherStyled>
          <IconNameVoucher />
          <div className='name-voucher-container'>
            <p onClick={() => {
              getVoucherDetail(record?.voucher_code, 'sale');
              setOpenEditVoucherModal(true);
            }} className='name'>{text}</p>
            {/* <p className='voucher'>{voucherLang('Code')}: {record.voucher_code}</p> */}
          </div>
        </NameVoucherStyled>
      ),
    },
    {
      title: voucherLang('Apply'),
      dataIndex: 'services',
      key: 'services',
      render: (text) => (
        <>
          {
            text === 1 ? '1' + text : `${text.length} dịch vụ`
          }
        </>
      ),
    },
    {
      title: voucherLang('RetailPrice') + ' / ' + voucherLang('Value'),
      dataIndex: 'combined_column',
      key: 'combined_column',
      render: (_, record) => (
        <RetailPriceStyled>
          {record.value ? formatMoney(+record.value) : 0}
          {' / '}
          {record.retail_price ? formatMoney(+record.retail_price) : 0}
        </RetailPriceStyled>
      ),
    },
    {
      title: voucherLang('Quantity'),
      dataIndex: 'target_quantity',
      key: 'target_quantity',
    },
    {
      title: voucherLang('Status'),
      dataIndex: 'status',
      key: 'status',
      render: (status: string) => {
        const stt = status === 'deactive' ? 'Disable' : status;
        return (
          <div className={`common-status voucher ${status}`}>{voucherLang(stt)}</div>
        );
      },
    },
    {
      title: voucherLang('Actions'),
      dataIndex: 'actions',
      key: 'actions',
      render: (text: string, record: IVoucherItemResData) => (
        <ul className={'common-action'}>
          {/* <EditVoucher data={record} /> */}
          <li
            className='action-edit'
            onClick={() => {
              getVoucherDetail(record?.voucher_code, 'sale');
              setOpenEditVoucherModal(true);
            }}
          />

          <li
            className='action-remove'
            onClick={() => confirm(record?.id?.toString())}
          ></li>
        </ul>
      ),
    },
  ];

  return (
    <>
      <Table
        className={'custom-table'}
        dataSource={lstData}
        loading={loading || loadingActions}
        rowKey={(re) => re.id + re.voucher_code}
        columns={currView === VOUCHER_VIEW.PURCHASED ? purchasedColumns : saleColumns}
        scroll={{
          x: 900,
        }}
        pagination={{
          total: pagination?.total_record,
          defaultCurrent: Number(searchParams.get('page') ?? 1),
          defaultPageSize: Number(searchParams.get('per_page') ?? 10),
          onChange: (pageChange, sizeChange) => {
            const params: any = {
              page: pageChange == 0 ? 1 : pageChange,
              per_page: sizeChange,
            };
            setSearchParams(params);

            dispatch(voucherActions.setVoucherListParams.fetch({
              page: params.page,
              per_page: params.per_page,
            }));
          },
          showSizeChanger: false,
          // showTotal(total) {
          //   return `Total ${total} items`;
          // },
        }}
        locale={{
          emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={voucherLang('NoVoucher')} />,
        }}
      />
      <ModalConfirm ref={confirmRef} />

      {openEditVoucherModal ?
        <EditVoucher
          open={openEditVoucherModal}
          handleClose={() => setOpenEditVoucherModal(false)}
        /> : null
      }

      {openViewVoucherModal ?
        <ViewInfoVoucher
          open={openViewVoucherModal}
          handleClose={() => setOpenViewVoucherModal(false)}
        /> : null
      }
    </>
  );
};

export default VouchersTable;

const RetailPriceStyled = styled.div`
white-space: nowrap;
  @media only screen and (max-width: 767.98px) {
    white-space: normal;
  }
`;

const StatusStyled = styled.div`
.active {
  color: #1BB250;
  font-weight: 500;
  background: #F4FDF7;
}
.redeemed {
  color: #8C8C8C;
  font-weight: 500;
  background: #F5F5F5;
}
.invalid {
  color: #DF1212;
  font-weight: 500;
  background: #FDF3F3;
}
`;

const ValidityPeriodLabelStyled = styled.div`
display: flex;
justify-content: center;
align-items: center;
flex-wrap: wrap;
`;

const NameVoucherStyled = styled.div`
display: flex;
gap: 8px;
align-items: center;
justify-content: start;
.name-voucher-container {
  display: grid;
  gap: 4px;
  justify-content: start;
  align-items: center;
  .name {
    overflow: hidden;
    color: var(--Blue-Munsell-Main, #1B9AAA);
    text-overflow: ellipsis;
    white-space: nowrap;
    font-family: Inter;
    font-size: 16px;
    font-weight: 500;
    line-height: 140%;
    text-decoration-line: underline;
    cursor: pointer;
  }
  .voucher {
    overflow: hidden;
    color: var(--Gray-50, #8C8C8C);
    text-overflow: ellipsis;
    white-space: nowrap;
    font-family: Inter;
    font-size: 12px;
    font-weight: 400;
    line-height: 140%;
  }
}
`;