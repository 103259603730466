import uiServiceReducer from 'services/UI/reducer';
import invoiceServiceReducer from 'features/invoices/services/reducers';
import authServiceReducer from 'features/auth/services/reducers';
import customerServiceReducer from 'features/customers/services/reducers';
import bookingServiceReducer from 'features/bookings/services/reducers';
import userServiceReducer from 'features/users/services/reducers';
import checkoutServiceReducer from 'features/checkout/services/reducers';
import serviceServiceReducer from 'features/services/services/reducers';
import locationServiceReducer from 'features/locations/services/reducers';
import settingServiceReducer from 'features/settings/services/reducers';
import voucherServiceReducer from 'features/vouchers/services/reducers';
import loyalServiceReducer from 'features/loyaltyProgram/services/reducers';
import analyticServiceReducer from 'features/analytics/services/reducers';
import salesServiceReducer from 'features/sales/services/reducers';
import promotionServiceReducer from 'features/promotions/services/reducers';
import reviewServiceReducer from 'features/reviews/services/reducers';
import workingHourServiceReducer from 'features/workingHour/services/reducers';

import notificationServiceReducer from 'components/layout/Header/service/reducers';
import timeScheduleServiceReducer from 'features/timeSchedule/services/reducers';
import packagePermissionReducer from 'features/packagePermission/services/reducers';

import productServiceReducer from 'features/Products/services/reducers';

const rootReducer = {
  ui: uiServiceReducer,
  invoice: invoiceServiceReducer,
  auth: authServiceReducer,
  customer: customerServiceReducer,
  booking: bookingServiceReducer,
  user: userServiceReducer,
  checkout: checkoutServiceReducer,
  service: serviceServiceReducer,
  location: locationServiceReducer,
  setting: settingServiceReducer,
  voucher: voucherServiceReducer,
  loyal: loyalServiceReducer,
  analytic: analyticServiceReducer,
  sales: salesServiceReducer,
  promotion: promotionServiceReducer,
  review: reviewServiceReducer,
  workingHour: workingHourServiceReducer,
  timeSchedule: timeScheduleServiceReducer,
  notification: notificationServiceReducer,
  package: packagePermissionReducer,
  product: productServiceReducer
};

export default rootReducer;
