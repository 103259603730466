import { Empty, Input, Modal } from 'antd';
import PurchasedItem from 'features/checkout/components/VouchersTabs/PurchasedItem';
import checkoutSelectors from 'features/checkout/services/selectors';
import { IVoucherDataItem } from 'features/checkout/services/types/voucher';
import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import VoucherFormView, { VoucherFormViewProps } from './VoucherFormView';
import useSearchDebounce from 'hooks/useSearchDebounce';
import { useTranslation } from 'react-i18next';

/**
 * Purchased tab
 * @returns 
 */
const PurchasedTab = () => {
  const [currVoucherView, setCurrVoucherView] = React.useState<IVoucherDataItem | null>(null);
  const purchasedVouchersStore = checkoutSelectors.getVoucherOfCustomer() as IVoucherDataItem[];
  const [searchText, setSearchText] = useState('');
  const [text, onDebounce] = useSearchDebounce(async (value: string) => {
    const textSearch = value.toLowerCase();
    setSearchText(textSearch);
  }, purchasedVouchersStore);

  const purchasedVouchers = useMemo(() => purchasedVouchersStore.filter(o =>
    o.name?.toLocaleLowerCase().includes(searchText?.toLocaleLowerCase()) ||
    o.voucher_code?.toLocaleLowerCase().includes(searchText?.toLocaleLowerCase())
  ), [purchasedVouchersStore, searchText]);
    const {t: bookingLang} = useTranslation('booking');
    const {t: salesLang} = useTranslation('sales');
  return (
    <>
      <div className='common-input-search'>
        <Input
          type='text'
          value={text}
          placeholder={bookingLang('SearchVoucherCodeOrVoucherName')||''}
          onChange={onDebounce}
        ></Input>
      </div>
      <PurchasedTabStyled>
        {purchasedVouchers?.length === 0 ? <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={salesLang('NoVoucher')} /> :
          purchasedVouchers.map(o => (
            <PurchasedItem
              key={o.id}
              data={o}
              onView={() => setCurrVoucherView(o)}
            />
          ))
        }
        <ModalPurchasedView visible={!!currVoucherView} data={currVoucherView} viewOnly={true} onCancel={() => setCurrVoucherView(null)} />
      </PurchasedTabStyled>
    </>
  );
};

export default PurchasedTab;
interface ModalPurchasedViewProps extends VoucherFormViewProps {
  visible?: boolean;
  viewOnly?: boolean;

}
export const ModalPurchasedView = ({ visible, viewOnly ,...rest }: ModalPurchasedViewProps) => {
  const {t: voucherLang} = useTranslation('vouchers');
  return (
    <Modal
      maskClosable={false}
      open={visible}
      onCancel={rest.onCancel}
      okButtonProps={{ style: { display: 'none' } }}
      width={574}
      footer={null}
      forceRender
      centered
    >
      <h2 className='modal-title'>{voucherLang('VoucherInformation')}</h2>
      <VoucherFormView {...rest} viewOnly={viewOnly} />
    </Modal>
  );
};

const PurchasedTabStyled = styled.div`
  gap:12px;
  display: flex;
  flex-direction: column;
  max-height:40vh;
  overflow: scroll;
`;