import { Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import ModalConfirm, { ModalConfirmRef } from 'components/common/Modal/ModalConfirm';
import { useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useAppDispatch } from 'store/hooks';
import voucherActions from '../services/actions';
import { VOUCHER_VIEW } from '../services/constants';
import voucherSelectors from '../services/selectors';
import { IVoucherItemResData } from '../services/types/voucher';
import EditVoucher from '../widgets/EditVoucher';
import ViewInfoVoucher from '../widgets/ViewInfoVoucher';
import vouchersApis from '../services/apis';
import { get } from 'lodash';
import message from 'components/common/Message';
import { useSetLoadingPage } from 'services/UI/LoadingPage';
import { getFormatShortDate } from 'utils/moment/getSingleDateShortFormat';
import { formatMoney } from 'utils/unit';

type Props = {
  currView?: string;
};

const VouchersTable = (props: Props) => {
  const { currView } = props;

  const confirmRef = useRef<ModalConfirmRef>(null);
  const lstData = voucherSelectors.getLstVouchers();
  const pagination = voucherSelectors.getLstVoucherPagination();

  const loading = voucherSelectors.getLoadingLstVouchers();
  const loadingActions = voucherSelectors.getLoadingVoucherActions();
  const setPageLoading = useSetLoadingPage();
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();  
  const [openEditVoucherModal, setOpenEditVoucherModal] = useState<boolean>(false);
  const [openViewVoucherModal, setOpenViewVoucherModal] = useState<boolean>(false);

  const sendMail = async (voucher: IVoucherItemResData) => {
    setPageLoading(true);
    try {
      const res = await vouchersApis.sendMail(voucher?.voucher_code, voucher?.customer?.customer_code?.replace('#', ''));
      const msg = get(res, 'data.message');
      if (msg) {
        message.success(msg);
        dispatch(voucherActions.setVoucherListParams.fetch({}));
        return true;
      } else {
        const errorMsg = get(res, 'data.error.message');
        if (errorMsg) {
          message.error(errorMsg);
          return false;
        } else throw 'fail';
      }
    } catch (error) {
      const errors = Object.values(get(error, 'response.data.errors', {}));
      if (errors.length > 0) {
        errors.map((valueArr) => {
          const msg = get(valueArr, [0], '');
          message.error(msg);
        });
        return false;
      }
      message.error('An error occurred. Please try again');
      return false;
    } finally {
      setPageLoading(false);
    }
  };

  const confirm = (id: string) => {
    confirmRef.current?.show({
      title: 'Are you sure',
      icon: 'delete',
      msg: 'Are you sure remove this vouchers?',
      submit: async () => {
        dispatch(voucherActions.deleteVoucher(id?.toString()));
      }
    });
  };

  const getVoucherDetail = (voucherCode: string, type: string) => {
    dispatch(voucherActions.getVoucherDetail.fetch({voucherCode, type}));
  };

  const purchasedColumns: ColumnsType<IVoucherItemResData> = [
    // {
    //   title: 'Customer Name',
    //   dataIndex: 'customer',
    //   key: 'customer',
    //   fixed: 'left',
    //   render: (customer: ICustomerItemResData) => (
    //     <div>{customer?.name ?? ''}</div>
    //   ),
    // },
    {
      title: 'Code',
      dataIndex: 'voucher_code',
      key: 'voucher_code',
      fixed: 'left',
      className: 'nowrap',
    },
    {
      title: 'Value',
      dataIndex: 'value',
      key: 'value',
      render: (value) => (value ? formatMoney(+value) : formatMoney(0)),
    },
    {
      title: 'Redeemed',
      dataIndex: 'redeemed_value',
      key: 'redeemed_value',
      render: (value) => (value ? formatMoney(+value) : formatMoney(0)),
    },
    {
      title: 'Remaining',
      dataIndex: 'remaining',
      key: 'remaining',
      render: (value) => (value ? formatMoney(+value) : formatMoney(0)),
    },
    {
      title: 'Expiry date',
      dataIndex: 'validity_period_label',
      key: 'validity_period_label',
      className: 'nowrap',
      render: (status: string, record: IVoucherItemResData) =>
        getFormatShortDate(record.expiry_date, 'YYYY-MM-DD HH:mm:ss'),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (status: string) => {
        // const statusPurchase = record.is_enable ? 'active' : 'deactive';
        return (
          <div className={`common-status voucher ${status}`}>{status}</div>
        );
      },
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
      render: (text: string, record: IVoucherItemResData) => (
        <ul className={'common-action'}>
          {/* <ViewInfoVoucher data={record} /> */}
          <li
            className='action-info'
            onClick={() => {
              getVoucherDetail(record?.voucher_code, 'purchased');
              setOpenViewVoucherModal(true);
            }}
          />
          <li className='action-send' onClick={() => sendMail(record)}></li>
        </ul>
      ),
    },
  ];

  const saleColumns: ColumnsType<IVoucherItemResData> = [
    {
      title: 'Voucher Name',
      dataIndex: 'name',
      key: 'name',
      fixed: 'left',
    },
    {
      title: 'Value',
      dataIndex: 'value',
      key: 'value',
      render: (value) => (value ? formatMoney(+value) : 0),
    },
    {
      title: 'Retail Price',
      dataIndex: 'retail_price',
      key: 'retail_price',
      render: (value) => (value ? formatMoney(+value) : 0),
    },
    // {
    //   title: 'Voucher Sold',
    //   dataIndex: 'voucher_sold',
    //   key: 'voucher_sold',
    // },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (status: string) => {
        // const stt = status === 'deactive' ? 'Disable': status;
        return (
          <div className={`common-status voucher ${status}`}>{status}</div>
        );
      },
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
      render: (text: string, record: IVoucherItemResData) => (
        <ul className={'common-action'}>
          {/* <EditVoucher data={record} /> */}
          <li
            className='action-edit'
            onClick={() => {
              getVoucherDetail(record?.voucher_code, 'sale');
              setOpenEditVoucherModal(true);
            }}
          />

          <li
            className='action-remove'
            onClick={() => confirm(record?.id?.toString())}
          ></li>
        </ul>
      ),
    },
  ];

  return (
    <>
      <Table
        className={'custom-table'}
        dataSource={lstData}
        loading={loading || loadingActions}
        rowKey={(re) => re.id + re.voucher_code}
        columns={currView === VOUCHER_VIEW.PURCHASED ? purchasedColumns : saleColumns}
        scroll={{
          x: 900,
        }}
        pagination={{
          total: pagination?.total_record,
          defaultCurrent: Number(searchParams.get('page') ?? 1),
          defaultPageSize: Number(searchParams.get('per_page') ?? 10),
          onChange: (pageChange, sizeChange) => {
            const params: any = {
              page: pageChange == 0 ? 1 : pageChange,
              per_page: sizeChange,
            };
            setSearchParams(params);

            dispatch(voucherActions.setVoucherListParams.fetch({
              page: params.page,
              per_page: params.per_page,
            }));
          },
          showSizeChanger: true,
          showTotal(total) {
            return `Total ${total} items`;
          },
        }}
      />
      <ModalConfirm ref={confirmRef} />

      {openEditVoucherModal ? 
        <EditVoucher 
          open={openEditVoucherModal} 
          handleClose={()=>setOpenEditVoucherModal(false)} 
        /> : null
      }

      {openViewVoucherModal ? 
        <ViewInfoVoucher 
          open={openViewVoucherModal} 
          handleClose={()=>setOpenViewVoucherModal(false)} 
        /> : null
      }
    </>
  );
};

export default VouchersTable;
