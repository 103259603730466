const IconCalendar = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path opacity="0.4" d="M3 7.51318C3 5.30404 4.79086 3.51318 7 3.51318H17C19.2091 3.51318 21 5.30404 21 7.51318V9.01318V18.0132C21 20.2223 19.2091 22.0132 17 22.0132H7C4.79086 22.0132 3 20.2223 3 18.0132V9.01318V7.51318Z" fill="#595959"/>
      <path d="M17 3.51318H7C4.79086 3.51318 3 5.30404 3 7.51318V9.01318H21V7.51318C21 5.30404 19.2091 3.51318 17 3.51318Z" fill="#595959"/>
      <path opacity="0.4" d="M8 1.26318C8.41421 1.26318 8.75 1.59897 8.75 2.01318V5.01318C8.75 5.4274 8.41421 5.76318 8 5.76318C7.58579 5.76318 7.25 5.4274 7.25 5.01318V2.01318C7.25 1.59897 7.58579 1.26318 8 1.26318ZM16 1.26318C16.4142 1.26318 16.75 1.59897 16.75 2.01318V5.01318C16.75 5.4274 16.4142 5.76318 16 5.76318C15.5858 5.76318 15.25 5.4274 15.25 5.01318V2.01318C15.25 1.59897 15.5858 1.26318 16 1.26318Z" fill="#595959"/>
    </svg>
  );
};

export default IconCalendar;
