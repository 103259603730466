import { Form, InputNumber, Select, Spin } from 'antd';
import { TopUpType } from 'features/settings/services/types/topUp';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { TOPUP_OPTION } from 'features/settings/services/constants';
import PaymentMethodInput, { PaymentMethodInputRef } from './PaymentMethodInput';
import { Stripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import settingSelectors from 'features/settings/services/selectors';
import { getSelectedLanguage } from 'utils/unit';

interface TopUpFormProps {
  topUpType: TopUpType;
  loading?: boolean;
  stripe: Stripe | null;
}

/* eslint-disable-next-line */
const TopUpForm = forwardRef<PaymentMethodInputRef, TopUpFormProps>(({ topUpType, loading, stripe }, ref) => {
  const { t: topUpLang } = useTranslation('top_up');
  const form = Form.useFormInstance();
  const paymentFormRef = useRef<any>();
  const merchantBalance = settingSelectors.getMerchantStripeBalance();
  const topupInitData = settingSelectors.getTopUpInitData();
  const [topUpValue, setTopUpValue] = useState<number>(form.getFieldValue('topUp'));

  useImperativeHandle(ref, () => ({
    submitStripeAndElement: () => paymentFormRef?.current?.submitStripeAndElement(),
  }));

  const handleChangeTopUp = (val: any) => {
    setTopUpValue(val);
  };

  return (
    <Spin spinning={loading}>
      <TopUpFormStyled>
        <div className='box-wrap'>
          <p className='box-title'>
            {topUpLang(
              topUpType === TopUpType.AUTOMATIC
                ? 'automaticTopUpType.text'
                : 'manuallyChargeType.text'
            )}
          </p>
          <p className='box-desc'>{topUpLang('automaticTopUpType.formDesc')}</p>
          {topUpType === TopUpType.MANUALLY ? (
            <div className='current-balance'>
              <p className='current-balance-text'>Current balance</p>
              <p className='current-balance-amount'>${merchantBalance || 0}</p>
            </div>
          ) : null}
          <div>
            <Form.Item
              label={topUpLang('topUpForm.formLabel.topUp')}
              name='topUp'
              rules={[
                {
                  required: true,
                  message: 'Please select top up',
                },
              ]}
            >
              <Select options={TOPUP_OPTION} onChange={handleChangeTopUp}/>
            </Form.Item>
            <div className='form-help-text'>
              {topUpLang('topUpForm.formHelp.topUp', { amount: Math.floor(topUpValue / topupInitData?.fee_sms) })}
            </div>
          </div>
          <div>
            {topUpType === TopUpType.AUTOMATIC ? (
              <div>
                <Form.Item
                  label={topUpLang('topUpForm.formLabel.autoChargeWhen')}
                  name='autoChargeWhen'
                  rules={[
                    {
                      required: true,
                      message: topUpLang('PleaseEnterAutoChargeNumber')||''
                    },
                    {
                      validator(_, value) {
                        const topupValue = form.getFieldValue('topUp');
                        if (value && !/^\d+$/.test(value)) {
                          return Promise.reject(
                            new Error('Wrong number format')
                          );
                        }

                        if (Number(value) > Number(topupValue)) {
                          return Promise.reject(
                            new Error(
                              'The number of the Auto charge when field cannot bigger Topup'
                            )
                          );
                        }
                        return Promise.resolve();
                      },
                    },
                  ]}
                  dependencies={['topUp']}
                >
                  <InputNumber
                    addonAfter={getSelectedLanguage() === 'vi' ? 'đ' : '$'}
                    inputMode='numeric'
                    placeholder={
                      topUpLang('topUpForm.formPlaceholder.autoChargeWhen') ||
                      ''
                    }
                    type='number'
                  />
                </Form.Item>
                <div className='form-help-text'>
                  {topUpLang('topUpForm.formHelp.autoChargeWhen')}
                </div>
              </div>
            ) : (
              // <div>
              //   <Form.Item
              //     label={topUpLang('topUpForm.formLabel.notifyYourWhen')}
              //     name='notifyYourWhen'
              //     rules={[
              //       {
              //         required: true,
              //       },
              //       {
              //         validator(_, value) {
              //           const topupValue = form.getFieldValue('topUp');

              //           if (value && !/^\d+$/.test(value)) {
              //             return Promise.reject(
              //               new Error('Wrong number format')
              //             );
              //           }

              //           if (Number(value) > Number(topupValue)) {
              //             return Promise.reject(
              //               new Error(
              //                 'The number of the Notify your when field cannot bigger Topup'
              //               )
              //             );
              //           }
              //           return Promise.resolve();
              //         },
              //       },
              //     ]}
              //     dependencies={['topUp']}
              //   >
              //     <InputNumber
              //       addonBefore='$'
              //       inputMode='numeric'
              //       placeholder={
              //         topUpLang('topUpForm.formPlaceholder.notifyYourWhen') ||
              //         ''
              //       }
              //     />
              //   </Form.Item>
              //   <div className='form-help-text'>
              //     {topUpLang('topUpForm.formHelp.notifyYourWhen')}
              //   </div>
              // </div>
              null
            )}
          </div>
        </div>
        <Elements stripe={stripe}>
          <PaymentMethodInput ref={paymentFormRef}/>
        </Elements>
      </TopUpFormStyled>
    </Spin>
  );
});

const TopUpFormStyled = styled.div`
  .box-title {
    color: #363565;
    font-size: 24px;
    font-weight: 600;
  }

  .box-desc {
    color: #7980bc;
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 24px;
  }

  .box-wrap {
    margin-top: 24px;
    border-radius: 10px;
    border: 1px solid #c3c2e0;
    padding: 16px;
  }

  .current-balance {
    margin-bottom: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .current-balance-text,
    .current-balance-amount {
      color: #363565;
      font-size: 16px;
      font-weight: 400;
    }
  }

  .abnNumber,
  .cardNumber {
    border: unset;
    box-shadow: none;

    input {
      border-radius: 6px !important;
    }
  }

  .form-help-text {
    color: #7980bc;
    font-size: 14px;
    font-weight: 400;
    line-height: 140%;
    margin-top: -20px;
    margin-bottom: 24px;
  }

  .card-expired-picker {
    background: #f0f2f6 !important;
  }

  @media (max-width: 1180px) {
    .box-wrap {
      max-width: 648px;
      width: 100%;
    }
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .card-number-wrap {
    position: relative;

    .card-number-icon {
      position: absolute;
      right: 16px;
      top: 50%;
      transform: translate(0, -50%);
    }
  }
`;

export default TopUpForm;
