import fetch from 'services/request';
import { IApiListVoucherParams, IApiVoucherCreateBodyData } from './types/api';

const BASE_URL = 'api/v1/merchants/:merchant_code/vouchers';

const getListVouchers = (params: IApiListVoucherParams) => {
  return fetch({
    method: 'get',
    url: BASE_URL,
    params,
  });
};

const generateVoucherCode = () => {
  return fetch({
    method: 'get',
    url: BASE_URL + '/generate/voucher-code',
  });
};
const sendMail = (voucher_code: string, customer_code: string) => {
  return fetch({
    method: 'get',
    url: BASE_URL + `/${voucher_code}/customer/${customer_code}/send-mail`,
  });
};
const addVoucher = (body: IApiVoucherCreateBodyData) => {
  return fetch({
    method: 'post',
    url: BASE_URL + '/create',
    body
  });
};
const editVoucher = (id: string, body: IApiVoucherCreateBodyData) => {
  return fetch({
    method: 'put',
    url: BASE_URL + '/update/' + id,
    body
  });
};
const updateStatus = (voucher_code: string, is_enable: boolean) => {
  return fetch({
    method: 'put',
    url: BASE_URL + '/update-voucher-enable',
    body: { voucher_code, is_enable }
  });
};
const deleteVoucher = (id: string) => {
  return fetch({
    method: 'delete',
    url: BASE_URL + '/delete/' + id,
  });
};

const getListOptsPeriod = () => {
  return fetch({
    method: 'get',
    url: 'api/v1/voucher/list-option-period'
  });
};

const getAllService = () => {
  return fetch({
    method: 'get',
    url: 'api/v1/merchants/:merchant_code/service',
    params: {
      per_page: Number.MAX_SAFE_INTEGER,
    }
  });
};

const checkVoucherCodValid = (voucher_code: string, customer_code: string) => {
  return fetch({
    method: 'get',
    url: `api/v1/merchants/:merchant_code/vouchers/${voucher_code?.replace('#', '')}/customer/${customer_code?.replace('#', '')}`,
  });
};

const getSingleVoucher = (voucher_code: string, view?: string) => {
  return fetch({
    method: 'get',
    url: `api/v1/merchants/:merchant_code/vouchers/single/${voucher_code}`,
    params: {
      view: view || 'sale'
    }
  });
};

// const sendVoucherEmail = (voucher_code, customer_email  ) => {
//   return fetch({
//     method: 'get',
//     url: `api/v1/merchants/:merchant_code/vouchers/${voucher_code?.replace('#', '')}/customer/${customer_code?.replace('#', '')}`,
//   });
// }

const vouchersApis = {
  getListVouchers,
  getListOptsPeriod,
  getAllService,
  generateVoucherCode,
  addVoucher,
  editVoucher,
  deleteVoucher,
  updateStatus,
  sendMail,
  checkVoucherCodValid,
  getSingleVoucher
};

export default vouchersApis;
