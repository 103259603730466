import { Spin, Table } from 'antd';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import serviceActions from '../../../services/services/actions';
import serviceSelectors from '../../../services/services/selectors';
import { useEffect } from 'react';

const BrandTable = ({ columns }: any) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const params = serviceSelectors.getParams();

    useEffect(() => {
        // @ts-ignore
        setSearchParams(params ?? {});
    }, [params]);

    const dispatch = useDispatch();

    const data = serviceSelectors.getPaginateListServices();
    const loading = serviceSelectors.loadingStaffPaginateList();

    const dataSource: any = [
        {
            'id_product': 'ABC123',
            'name': 'Sửa rửa mặt',
            'status': 'Action',
            'quantity': 10,
        },
        {
            'id_product': 'DEF456',
            'name': 'Kem dưỡng',
            'status': 'Deactive',
            'quantity': 20,
        }
    ];

    return (
        <>
            <Spin spinning={loading}>
                <Table
                    className={'custom-table'}
                    dataSource={dataSource}
                    columns={columns}
                    scroll={{
                        x: 500,
                    }}
                    pagination={{
                        total: data?.pagination?.total_record,
                        defaultCurrent: Number(searchParams.get('page') ?? 1),
                        // defaultPageSize: Number(searchParams.get('per_page') ?? 10),
                        defaultPageSize: 6,
                        current: +(params.page ?? ''),
                        onChange: (pageChange, sizeChange) => {
                            const params: any = {
                                page: pageChange == 0 ? 1 : pageChange,
                                per_page: sizeChange,
                                keyword: searchParams.get('keyword')
                            };
                            dispatch(serviceActions.setServiceListParams(params));
                        },
                        showSizeChanger: false,
                        // showTotal(total) {
                        //   return `Total ${total} items`;
                        // },
                    }}
                />
            </Spin>
        </>
    );
};

export default BrandTable;