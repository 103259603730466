import { Button, Form, Modal, message } from 'antd';
import React, { useRef, useState } from 'react';
import TypeViewPicker from '../filter/TypeViewPicker';
import TimeScheduleDateView from '../filter/TimeScheduleDateView';
import styled from 'styled-components';
import StatisticalCard from './StatisticalCard';
import ChartVisitRate from './ChartVisitRate';
import { useTranslation } from 'react-i18next';
import EditCustomerForm, { IFuncSubmit } from '../widgets/EditCustomer/CustomersForm';
import { AddNewCustomerFormRef, IFormAddCustomerData, IFuncCancel } from '../widgets/AddNewCustomer/CustomersForm';
import { get, set } from 'lodash';
import { AxiosResponse } from 'axios';
import apisCustomer from '../services/apis';
import { IApiUpdateCustomerBody } from '../services/types/cutomer';
import storage from 'utils/sessionStorage';
import customerSelectors from '../services/selectors';

/**
 * Statistical tab
 * @returns 
 */
const StatisticalTab = () => {
  const [valueTypeView,] = useState<string>();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [errors, setErrors] = useState<Record<string, string> | null>(null);
  const detail = customerSelectors.getCustomerDetail();
  const formRef = useRef<AddNewCustomerFormRef>(null);

  /**
   * Show modal
   */
  const showModal = () => {
    setIsModalOpen(true);
  };

  /**
   * Handle cancel
   */
  const handleCancel: IFuncCancel = () => {
    setIsModalOpen(false);
    setErrors(null);
  };

  /**
   * Get payload
   * @param data 
   * @returns 
   */
  const getPayload = (data: IFormAddCustomerData) => {
    const merchant_id = storage.merchantId.get() ?? '';
    const name = detail?.name ?? '';
    const phone_number = detail?.phone_number ?? '';
    const email = data?.email ?? '';
    const date_of_birth = data.birthDate ? data.birthDate.format('YYYY-MM-DD') : null;
    const total_visited = parseInt(data?.numberVisits ?? 0);
    const total_cancelled = parseInt(data?.cancellations ?? 0);
    const total_no_show = parseInt(data?.noShows ?? 0);
    const loyalty_point = parseInt(data?.loyaltyPoints ?? 0);
    const important_client_info = data?.importantClientInfo ?? null;
    const gender = data?.gender ?? null;
    const amount_spent = data?.amountSpent ?? 0;
    const payload: IApiUpdateCustomerBody = {
      merchant_id,
      name,
      phone_number,
      email,
      gender,
      date_of_birth,
      total_visited,
      total_cancelled,
      total_no_show,
      loyalty_point,
      amount_spent,
      important_client_info,
    };
    return payload;
  };

  /**
   * Handle submit
   * @param data 
   * @returns 
   */
  const handleSubmit: IFuncSubmit = async (data) => {
    // Call the handleCancel() function before performing any other actions
    handleCancel();
    // Create payload from input data
    const payload = getPayload(data);
    try {
      // Send payload to the server to update customer information
      const rest: AxiosResponse<{ message: string }> = await apisCustomer.updateCustomerInfo(detail?.id.toString() ?? '', payload);
      const msg = get(rest, 'data.message', '');
      // Display a success message from the server if any
      if (msg) {
        message.success(msg);
        handleCancel();// Close or delete any open views
        // fetchDetailData();
        return true; // Returns true if successful
      }
      return false; // Returns false if there is no notification from the server
    } catch (error: any) {
      // Handle errors from the server
      const errors = Object.entries(get(error, 'response.data.errors', {}));
      const errorsData = {};
      // Display and handle errors from the server
      if (errors.length > 0) {
        errors.map(([key, text]) => {
          const msg = get(text, [0]);
          message.error(msg);
          set(errorsData, [key], msg);
        });
        // If there is a specific error, perform some action such as returning to a previous step in the process
        if (errors.some(([key]) => {
          if (['phone_number', 'name', 'email', 'date_of_birth'].includes(key)) return true;
          return false;
        })) {
          formRef.current?.prevStep();
        }
        // Put the error into state to display on the interface
        setErrors(errorsData);
      }
      else {
        // Display a general error message if there is no specific error from the server
        message.error('An error occurred. Please try again');
      }
      return false; // Return false when there is an error from the server
    }
  };

  return (
    <StatisticalTabStyled>
      <div className="cards-wrapper">
        <div className="common-card">
          <div className='menu'>
            <i className='developing'>Đang phát triển</i>
            <div className='analytics-time'>
              <Button className='btn-analytics' onClick={() => showModal()}>Chỉnh sửa</Button>
              <TypeViewPicker value={valueTypeView} />
              <TimeScheduleDateView />
            </div>
          </div>
          <div className='analytics-customer'>
            <StatisticalCard />
            <div className={'analytics-card'}>
              <div className={'analytics-label'}>
                <h1>Tỷ lệ ghé thăm</h1>
                <p>Tỷ lệ này được so sánh với chu kỳ trước</p>
              </div>
              <div style={{ maxWidth: '100%' }}>
                <ChartVisitRate />
              </div>
            </div>
          </div>
        </div>
      </div>
      {isModalOpen &&
        <EditCustomerModal
          visible
          errors={errors}
          handleCancel={handleCancel}
          handleSubmit={handleSubmit}
        />}
    </StatisticalTabStyled>
  );
};

export default StatisticalTab;

const StatisticalTabStyled = styled.div`
.menu {
  display: flex;
  justify-content: space-between;
  @media only screen and (max-width: 767.98px) {
    display: grid;
  }
  .developing {
      color: #141414;
      font-size: 32px;
      font-weight: 600;
  }
  .analytics-time {
    display: flex;
    gap: 8px;
    justify-content: end;
    align-items: center;
    @media only screen and (max-width: 767.98px) {
      flex-wrap: wrap;
      justify-content: center;
    }
    .btn-analytics {
      padding: 0px 20px;
      background: #F3FAFB;
      color: #1B9AAA;
      text-align: center;
      font-family: Inter;
      font-size: 18px;
      font-weight: 500;
      line-height: 140%;
      border: 0px solid transparent;
    }
  }
}
.common-card {
  display: grid;
  gap: 16px;
}

.analytics-customer {
  display: grid;
  gap: 16px;
}

.analytics-card {
  padding: 24px;
  display: grid;
  gap: 24px;
  border-radius: 16px;
  border: 1px solid #D9D9D9;
  @media only screen and (max-width: 767.98px) {
    overflow: auto;
  }
  .analytics-label {
    display: grid;
    gap: 8px;
    h1 {
      color: #141414;
      font-family: Inter;
      font-size: 20px;
      font-weight: 700;
      line-height: 140%;
    }
    p {
      color: #8C8C8C;
      font-family: Inter;
      font-size: 14px;
      font-weight: 400;
      line-height: 140%;
    }
  }
}
`;

type EditCustomerModalProps = {
  visible?: boolean;
  handleCancel?: () => void;
  handleSubmit?: IFuncSubmit;
  errors?: Record<string, string> | null;
};
const EditCustomerModal = ({ visible, handleCancel, handleSubmit, errors }: EditCustomerModalProps) => {
  const { t: customerLang } = useTranslation('customer');
  return (
    <Modal
      maskClosable={false}
      open={visible}
      onCancel={handleCancel}
      okButtonProps={{ style: { display: 'none' } }}
      width={574}
      footer={null}
      forceRender
      centered
    >
      <h2 className="modal-title">
        <p>{customerLang('Edit')}</p>
      </h2>
      <Form
        data-form-testing='form-customer-statistical'

        name="basic"
        className="modal-form"
        onFinish={handleSubmit}
        autoComplete="off"
        initialValues={{
          phoneNumberCode: '+92',
        }}
        layout="vertical"
      >
        <EditCustomerForm
          // ref={formRef}
          errors={errors}
          onCancel={handleCancel}
          onSubmit={handleSubmit}
        />
      </Form>
    </Modal>
  );
};