import { Image, Spin } from 'antd';
import IconProgram from 'assets/icons/ico_programs.svg';
import ModalConfirm, { ModalConfirmRef } from 'components/common/Modal/ModalConfirm';
import { useRef } from 'react';
import styles from '../pages/index.module.scss';
import loyalSelectors from '../services/selectors';
import ProgramEdit from '../widgets/Program/Edit';
import ProgramRemove from '../widgets/Program/Remove';
import ProgramSwitchStatus from '../widgets/Program/SwitchStatus';
import { useTranslation } from 'react-i18next';
import Can from 'features/packagePermission/components/Can';
import { ModulePermission, ProgramPermission } from 'features/packagePermission/services/types/permission';

const ProgramsTable = () => {
  const confirmRef = useRef<ModalConfirmRef>(null);
  const loadingListLoyalProgram = loyalSelectors.loadingListLoyalProgram();
  const listLoyalProgram = loyalSelectors.getListLoyalProgram();
  const {t: loyaltyProgramLang} = useTranslation('loyaltyProgram');
  return (
    <Spin spinning={loadingListLoyalProgram}>
      <div className='card-body'>
        <ul className={styles.programs_list}>
          {listLoyalProgram?.map((o, index) => (
            <li key={index}>
              <div>
                <Image preview={false} src={IconProgram} alt='icon-program'></Image>
              </div>
              <div className={styles.item_heading}>
                <p className={styles.item_title}>
                  {o.name}
                </p>
                {/* <p className={styles.item_title}>
                  {o.earn_point === 'amount_spent' ? `${loyaltyProgramLang('Spend')} ${formatMoney(o.amount_spent)} ${loyaltyProgramLang('OrMoreInOneVisit')}` : `${loyaltyProgramLang('Visit')} ${loyaltyProgramLang(o.name_location.toLocaleLowerCase() === 'all locations' ? 'AllLocation' : o.name_location.toLocaleLowerCase())}`}
                </p> */}
                <p className={styles.item_point}>{o.point} {loyaltyProgramLang('point')}{loyaltyProgramLang('s')}</p>
              </div>
              <Can module={ModulePermission.PROGRAMS} permission={ProgramPermission.EDIT_PROGRAM}>
                <ProgramSwitchStatus data={o} confirmRef={confirmRef} />
              </Can>
              <ul className={'common-action'}>
                <Can module={ModulePermission.PROGRAMS} permission={ProgramPermission.EDIT_PROGRAM}>
                  <ProgramEdit data={o} />
                </Can>
                <Can module={ModulePermission.PROGRAMS} permission={ProgramPermission.DELETE_PROGRAM}>
                  <ProgramRemove data={o} confirmRef={confirmRef} />
                </Can>
              </ul>
            </li>
          ))}
        </ul>
      </div>
      <ModalConfirm ref={confirmRef} />
    </Spin>
  );
};

export default ProgramsTable;
