const IconCopy = () => {
  return (
    <svg
      width="14"
      height="15"
      viewBox="0 0 14 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="copy">
        <path
          id="Vector 1"
          d="M8.61111 14L2.5 14C1.39543 14 0.499998 13.1046 0.499999 12L0.5 5.88892C0.5 4.78435 1.39543 3.88892 2.5 3.88892L8.61111 3.88892C9.71568 3.88892 10.6111 4.78435 10.6111 5.88892L10.6111 12C10.6111 13.1046 9.71568 14 8.61111 14Z"
          stroke="#1B9AAA"
          strokeLinecap="round"
        />
        <path
          id="Vector 2"
          d="M3.38889 3.88889V3C3.38889 1.89543 4.28432 1 5.38889 1H11.5C12.6046 1 13.5 1.89543 13.5 3V9.11111C13.5 10.2157 12.6046 11.1111 11.5 11.1111H10.6111"
          stroke="#1B9AAA"
          strokeLinecap="round"
        />
      </g>
    </svg>
  );
};

export default IconCopy;
