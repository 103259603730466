import bookingSelectors from 'features/bookings/services/selectors';
import { IServiceQuickBookingItem } from 'features/bookings/services/types/quickBooking';
import { findIndex, get } from 'lodash';
import { useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import ServiceItem, { IFuncGetIsCheck, ServiceGroup } from './ServiceItem';
import ListCategory from 'features/bookings/components/BookingForm/QuickBooking/ListCategory';
import checkoutSelectors from 'features/checkout/services/selectors';
import InputSearchServices from 'features/bookings/components/BookingForm/QuickBooking/InputSearchServices';
import { Empty, Spin } from 'antd';
import settingSelectors from 'features/settings/services/selectors';
import ModalConfirm, { ModalConfirmRef } from 'components/common/Modal/ModalConfirm';
import { PATH } from 'constants/path.enum';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

type Props = {
  handleChooseService?: any;
  currChooseServices?: any;
  isFavorite?: boolean;
};

/**
 * Services selection
 * @param handleChooseService , isFavorite
 * @returns 
 */
const ServicesSelection = ({
  handleChooseService,
  isFavorite,
}: Props) => {

  const dataConfigs = bookingSelectors.quickBooking.getDataConfigs();
  const selected = checkoutSelectors.getSelectedServices();
  const loadingConfigs = bookingSelectors.quickBooking.getLoadingDataConfigs();
  const categoryFavorite = useRef<string[]>([]);
  const detail = checkoutSelectors.getBookingDetail();
  const setting = settingSelectors.getSetting();
  const modalConfirmRef = useRef<ModalConfirmRef>(null);
  const navigate = useNavigate();
  const listCategoryRef = useRef<HTMLDivElement>(null);

  const services = useMemo(() => {
    if (!dataConfigs?.services) return [];
    const service_data = dataConfigs?.services?.filter(o => {
      if (!o.merchant_location_id || o.merchant_location_id?.length === 0) return true;
      return !!o.merchant_location_id?.find(d => d?.toString() === detail?.merchant_location_id?.toString());
    }).map(o => {

      const data = {
        ...o,
        merchant_employees: o.merchant_employees.filter(o => {
          if (!o.id) return true;
          return o.merchant_location_id?.toString() === detail?.merchant_location_id?.toString();
        })
      };
      //@ts-ignore
      if (!(setting?.booking?.is_team_member_booking && !o.merchant_employees.some(o => o.is_receive_book === 1 && o.id !== '' && o.merchant_location_id.toString() === detail?.merchant_location_id?.toString()))) {
        return {
          ...data,
          is_active: false
        };
      } else {

        return {
          ...data,
          is_active: true,
        };
      }

    });
    if (isFavorite) {
      const services: IServiceQuickBookingItem[] = [];
      service_data.forEach(o => {
        if (o.is_favourite === 1) {
          categoryFavorite.current.push(o.category.id?.toString());
          services.push(o);
        }
      });
      return services;
    }
    return service_data;
  }, [dataConfigs?.services, isFavorite, detail]);
  const{t: bookingLang} = useTranslation('booking');
  const categories = useMemo(() => {
    if (services.length === 0) return [];
    return [
      {
        id: 'general',
        color: '#2f54eb',
        name: 'All',
        total_sale: Number.MAX_SAFE_INTEGER
      },
    ]
    .concat(services.filter(o => o.is_favourite === 1).length > 0 ? [
      {
        id: 'favorite',
        color: '#4A9D77',
        name: bookingLang('Favorites'),
        total_sale: Number.MAX_SAFE_INTEGER
      }
    ] : [])
    .concat(dataConfigs?.categories.filter(o => services.find(e => e.category.id === o.id)) as []);
  }, [services]);

  const [search, setSearch] = useState('');
  const [category, setCategory] = useState<string>(() => {
    if (!isFavorite) return 'general';
    return get(categories, [0, 'id'])?.toString() ?? '';
  });

  const _services = useMemo(() => {
    const _search = search.toLowerCase();
    return services.filter(o =>
      category === 'general' ? true
        : category === 'favorite' ? o.is_favourite === 1
          : String(o.category.id) === category
    ).filter(o => {
      if (o?.name?.toLowerCase().includes(_search)) {
        return true;
      }
      return !!o?.service_variants?.some(s => s?.name?.toLowerCase().includes(_search));
    });
  }, [services, category, search, selected]);

  /**
   * Handle input change
   * @param value 
   */
  const handleInputChange = (value: string) => {
    setSearch(value);
  };

  /**
   * Handle service change
   * @param isChecked 
   * @param serviceItem 
   */
  const handleServiceChange = (
    isChecked: boolean,
    serviceItem: IServiceQuickBookingItem
  ) => {
    handleChooseService(serviceItem, isChecked);
  };

  /**
   * Get is checked
   * @param service_variant_id, service_id 
   * @returns 
   */
  const getIsChecked: IFuncGetIsCheck = ({ service_variant_id, service_id }) => {
    const currentIdx = findIndex(selected, (o) => o.service_variant_id !== null ? o.id === service_id && o.service_variant_id === service_variant_id : o.id === service_id);
    return currentIdx !== -1;
  };

  const {t: formLang} = useTranslation('form');
  const titleConfirmValue: string | undefined = formLang('GoToService') || undefined;
  
  const handleCategoryChange = (value: string) => {
    if (value === category) {
      if (listCategoryRef.current) {
        listCategoryRef.current.scrollTo(0, 0); 
      }
      return setCategory('general');
    }
    setCategory(value);
  };

  return (
    <>
      <Spin spinning={loadingConfigs}>
        <InputSearchServices handleChange={handleInputChange} isGray={true} />
        {_services.length === 0 ?
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={bookingLang('NoService')} />
          : <>
            <ListCategory
              ref={listCategoryRef}
              data={categories}
              onChange={handleCategoryChange}
              value={category}
              disableScroll
            />
            <ServicesSelectionStyled>
              <table>
                <tbody>
                  {_services?.map((service) => {
                    if (
                      service.service_variants &&
                      service.service_variants?.length > 0
                    ) {
                      return (
                        <ServiceGroup
                          isActive={service.is_active}
                          key={service.id}
                          service={service}
                          getIsChecked={getIsChecked}
                          onServiceChange={(isChecked, serviceItem) => {
                            service.is_active ? modalConfirmRef.current?.show({
                              title: formLang('NoTeamMember'),
                              msg: formLang('CurrentlyThereIsNoTeamMemberEnabledToTheServicePageToAssignAnotherTeamMember'),
                              submit: () => navigate(PATH.services),
                            }) : handleServiceChange(isChecked, serviceItem);
                          }}

                        />
                      );
                    }
                    return (
                      <ServiceItem
                        isActive={service.is_active}
                        key={service.id}
                        service={service}
                        getIsChecked={getIsChecked}
                        onServiceChange={(isChecked, serviceItem) => {
                          service.is_active ? modalConfirmRef.current?.show({
                            title: formLang('NoTeamMember'),
                            msg: formLang('CurrentlyThereIsNoTeamMemberEnabledToTheServicePageToAssignAnotherTeamMember'),
                            submit: () => navigate(PATH.services),
                          }) : handleServiceChange(isChecked, serviceItem);
                        }}
                      />
                    );
                  })}
                </tbody>
              </table>
            </ServicesSelectionStyled>

          </>}
      </Spin>
      <ModalConfirm titleConfirm={titleConfirmValue}ref={modalConfirmRef} />


    </>
  );
};

export default ServicesSelection;

const ServicesSelectionStyled = styled.div`
  @media (max-width: 992.98px) {
    max-height: 230px;
  }
  max-height: 300px;
  overflow-y: scroll;
  table {
    width: 100%;
  }
  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #f5f5f5;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #d9d9d9;
  }

  @media (max-width: 767.98px) {
    min-height: 300px;
  }
`;

