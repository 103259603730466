import React from 'react';
import styled from 'styled-components';
import { formatMoney } from 'utils/unit';

const data = {
    booking: {
        totalBooking: 20,
        statisticalBooking: 14,
        success: 20,
        cancel: 0,
        noShow: 0
    },
    expenditure: {
        total: 20000000,
        statisticalExpenditure: -14,
        service: 5000000,
        product: 2000000,
        treatment: 13000000
    },
    spendingAverage: {
        total: 20000000,
        statistical: 14,
        averageService: 500000,
        averageProduct: 2000000,
        averageTreatment: 13000000
    },
    customerBehavior: {
        total: 0,
        statistical: 0,
        LastTime: '50 Ngày',
        regularDays: 'Chủ nhật',
        regularAppointmentHours: '17:00 - 19:00'
    }
};

/**
 * Statistical card
 * @returns 
 */
const StatisticalCard = () => {
    return (
        <StatisticalCardStyled>
            <div className='statistical-card-container'>
                <div className='statistical-card'>
                    <p className='title'>Tổng lượt đặt hẹn</p>
                    <p className='price'>{data.booking.totalBooking}</p>
                    <p className={`statistical ${data.booking.statisticalBooking > 0 ? 'colorTrendUp' : data.booking.statisticalBooking < 0 ? 'colorTrendDown' : 'colorTrendMinus'}`}>
                        {data.booking.statisticalBooking > 0 ? <IconTrendUp /> : data.booking.statisticalBooking < 0 ? <IconTrendDown /> : <IconTrendMinus />}
                        {data.booking.statisticalBooking}% với tháng trước
                    </p>
                </div>
                <div className='statistical-card-content'>
                    <div className='card-content'>
                        <p className='title'>Hoàn thành</p> <p className='total'>{data.booking.success} (100%)</p>
                    </div>
                    <div className='card-content'>
                        <p className='title'>Huỷ</p><p className='total'>{data.booking.cancel} (0%)</p>
                    </div>
                    <div className='card-content'>
                        <p className='title'>Vắng mặt</p><p className='total'>{data.booking.noShow} (0%)</p>
                    </div>
                </div>
            </div>
            <div className='statistical-card-container'>
                <div className='statistical-card'>
                    <p className='title'>Tổng chi (VND)</p>
                    <p className='price'>{formatMoney(data.expenditure.total)}</p>
                    <p className={`statistical ${data.expenditure.statisticalExpenditure > 0 ? 'colorTrendUp' : data.expenditure.statisticalExpenditure < 0 ? 'colorTrendDown' : 'colorTrendMinus'}`}>
                        {data.expenditure.statisticalExpenditure > 0 ? <IconTrendUp /> : data.expenditure.statisticalExpenditure < 0 ? <IconTrendDown /> : <IconTrendMinus />}
                        {data.expenditure.statisticalExpenditure}% với tháng trước</p>
                </div>
                <div className='statistical-card-content'>
                    <div className='card-content'>
                        <p className='title'>Dịch vụ</p> <p className='total'>{formatMoney(data.expenditure.service)} (100%)</p>
                    </div>
                    <div className='card-content'>
                        <p className='title'>Sản phẩm</p><p className='total'>{formatMoney(data.expenditure.product)} (0%)</p>
                    </div>
                    <div className='card-content'>
                        <p className='title'>Liệu trình</p><p className='total'>{formatMoney(data.expenditure.treatment)} (0%)</p>
                    </div>
                </div>
            </div>
            <div className='statistical-card-container'>
                <div className='statistical-card'>
                    <p className='title'>Trung bình chi (VND)</p>
                    <p className='price'>{data.spendingAverage.total}</p>
                    <p className={`statistical ${data.spendingAverage.statistical > 0 ? 'colorTrendUp' : data.spendingAverage.statistical < 0 ? 'colorTrendDown' : 'colorTrendMinus'}`}>
                        {data.spendingAverage.statistical > 0 ? <IconTrendUp /> : data.spendingAverage.statistical < 0 ? <IconTrendDown /> : <IconTrendMinus />}
                        {data.spendingAverage.statistical}% với tháng trước</p>
                </div>
                <div className='statistical-card-content'>
                    <div className='card-content'>
                        <p className='title'>TB Dịch vụ</p> <p className='total'>{formatMoney(data.spendingAverage.averageService)} (100%)</p>
                    </div>
                    <div className='card-content'>
                        <p className='title'>TB Sản phẩm</p><p className='total'>{formatMoney(data.spendingAverage.averageProduct)} (0%)</p>
                    </div>
                    <div className='card-content'>
                        <p className='title'>TB Liệu trình</p><p className='total'>{formatMoney(data.spendingAverage.averageTreatment)} (0%)</p>
                    </div>
                </div>
            </div>
            <div className='statistical-card-container'>
                <div className='statistical-card'>
                    <p className='title'>Hành vi khách hàng</p>
                    <p className='price'>{data.customerBehavior.total}</p>
                    <p className={`statistical ${data.customerBehavior.statistical > 0 ? 'colorTrendUp' : data.customerBehavior.statistical < 0 ? 'colorTrendDown' : 'colorTrendMinus'}`}>
                        {data.customerBehavior.statistical > 0 ? <IconTrendUp /> : data.customerBehavior.statistical < 0 ? <IconTrendDown /> : <IconTrendMinus />}
                        {data.customerBehavior.statistical}% với tháng trước</p>
                </div>
                <div className='statistical-card-content'>
                    <div className='card-content'>
                        <p>Lần gần nhất</p> <p className='total'>{data.customerBehavior.LastTime}</p>
                    </div>
                    <div className='card-content'>
                        <p>Ngày thường xuyên</p><p className='total'>{data.customerBehavior.regularDays}</p>
                    </div>
                    <div className='card-content'>
                        <p>Giờ hẹn thường xuyên</p><p className='total'>{data.customerBehavior.regularAppointmentHours}</p>
                    </div>
                </div>
            </div>
        </StatisticalCardStyled>
    );
};

export default StatisticalCard;

const StatisticalCardStyled = styled.div`
display: flex;
flex-wrap: wrap;
gap: 16px;
justify-content: start;
/* @media only screen and (max-width: 767.98px) {
    display: grid;
} */
.statistical-card-container {
    display: grid;
    gap: 16px;
    padding: 24px;
    border-radius: 12px;
    border: 1px solid #D9D9D9;
    width: 24%;
    @media only screen and (max-width: 767.98px) {
        width: 100%;
    }
    .statistical-card {
        display: grid;
        gap: 2px;
        align-items: center;
        justify-content: start;
        .title {
            color: #141414;
            font-family: Inter;
            font-size: 12px;
            font-weight: 400;
            line-height: 140%;
        }
        .price {
            color: #141414;
            font-family: Inter;
            font-size: 24px;
            font-weight: 600;
            line-height: 140%;
        }
        .statistical {
            color: #595959;
            font-family: Inter;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 140%;
            display: flex;
            justify-content: start;
            align-items: center;
        }
        .colorTrendUp {
            color: #22DF64;
            fill: #22DF64;
        }
        .colorTrendDown {
            color: #DF1212;
            fill: #DF1212;
        }
        .colorTrendMinus {
            color: #595959;
            fill: #595959;
        }
    }
    .statistical-card-content {
        display: grid;
        gap: 8px;
        align-items: center;
        justify-content: start;
        .card-content {
            display: flex;
            gap: 8px;
            justify-content: start;
            align-items: center;
            .title {
                color: #8C8C8C;
                font-family: Inter;
                font-size: 14px;
                font-weight: 400;
                line-height: 140%;
            }
            .total {
                color: #141414;
                font-family: Inter;
                font-size: 14px;
                font-weight: 600;
                line-height: 140%;
            }
        }
    }
}
`;

export const IconTrendUp = () => {
    return (
        <svg
            width='16'
            height='16'
            viewBox='0 0 16 16'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                d='M3.6129 11.7903L3.70711 11.7071L6 9.415L7.29289 10.7071C7.65338 11.0676 8.22061 11.0953 8.6129 10.7903L8.70711 10.7071L11.5 7.915L12.2929 8.70711C12.8955 9.30968 13.9072 8.92767 13.994 8.11363L14 8L13.9983 4.94062L13.9893 4.85305L13.9643 4.73416L13.9401 4.65833L13.9063 4.57678L13.854 4.47929L13.7871 4.38298L13.7071 4.29286C13.6717 4.2575 13.6343 4.22531 13.5953 4.19633L13.4841 4.12467L13.3713 4.07123L13.266 4.03585L13.1485 4.01102L13.0892 4.00397L13 4H10C9.14783 4 8.70257 4.98551 9.21677 5.62253L9.29289 5.70711L10.085 6.5L8 8.585L6.70711 7.29289C6.34662 6.93241 5.77939 6.90468 5.3871 7.2097L5.29289 7.29289L2.29289 10.2929C1.90237 10.6834 1.90237 11.3166 2.29289 11.7071C2.65338 12.0676 3.22061 12.0953 3.6129 11.7903Z'
                fill='#22DF64'
            />
        </svg>
    );
};

export const IconTrendDown = () => {
    return (
        <svg width="10" height="7" viewBox="0 0 10 7" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="chart-down-1">
                <path id="Vector" d="M1 1L3.58925 3.91291C3.78815 4.13667 4.13777 4.13667 4.33666 3.91291L5.36702 2.75375C5.56591 2.53 5.91553 2.52999 6.11443 2.75375L9 6M9 6H6.6M9 6V3.5" stroke="#DF1212" strokeLinecap="round" strokeLinejoin="round" />
            </g>
        </svg>
    );
};

export const IconTrendMinus = () => {
    return (
        <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="minus">
                <path id="Vector 4" d="M9.5 6.5L2.5 6.5" stroke="#595959" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            </g>
        </svg>
    );
};
