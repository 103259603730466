import { Modal } from 'antd';
import React from 'react';
import MomoModal from './MomoModal';
import ZaloPayModal from './ZaloPayModal';
import TransferModal from './TransferModal';
interface IFormModal {
    isModalOpen?: boolean;
    handleCancel?: () => void;
    modalName?: string;
    statusModal?: string;
}
const FormModal = ({
    isModalOpen,
    handleCancel,
    modalName,
    statusModal
}: IFormModal) => {
    return (
        <Modal
            maskClosable={false}
            open={isModalOpen}
            onCancel={handleCancel}
            okButtonProps={{ style: { display: 'none' } }}
            width={574}
            footer={null}
            forceRender
            centered
        >
            {
                modalName === 'Momo' ? <MomoModal statusModal={statusModal} /> 
                : modalName === 'ZaloPay'? <ZaloPayModal statusModal={statusModal} /> 
                : <TransferModal statusModal={statusModal} />
            }
        </Modal>
    );
};

export default FormModal;