import { Navigate, Outlet } from 'react-router-dom';
import { useCheckPermission } from '../hooks/useCheckPermission';
import { ModulePermission, PackagePermissionsType } from '../services/types/permission';
import packagePermissionSelector from '../services/selectors';
import { useMemo, useState } from 'react';
// import { Modal } from 'antd';


interface IRequireAuth {
  module: ModulePermission,
  permission: PackagePermissionsType;
}

const RequireAuth = ({ module, permission }: IRequireAuth) => {

  const permissionFeatures = packagePermissionSelector.getPermissionFeature(module);
  const permissionAll = packagePermissionSelector.getPermissionFeatureAll();
  const [, setOpen] = useState<boolean>(false);

  const isAllow = useMemo(() => {
    
    const allow = !permissionAll ? true : useCheckPermission(permissionFeatures, permission);

    setOpen(!allow);
    return !!allow;
  }, [permissionAll]);

  const content = (
    isAllow ? <Outlet /> : 
      <Navigate to={'/403'}/>
    // <div style={{ opacity: 0.1 }}>
    //   <Modal
    //     maskClosable={false}
    //     open={open}
    //     onCancel={() => setOpen(false)}
    //     okButtonProps={{ style: { display: 'none' } }}
    //     closeIcon={false}
    //     width={574}
    //     footer={null}
    //     centered
    //     forceRender
    //   >
    //     <div className='modal-delete-confirm'>
    //       <h2 className='modal-title'>{'You are not authorized to access this content.'}</h2>
    //       <p className='modal-text'>{'Your current package does not have access to this content. To use this feature, please upgrade your package.'}</p>
    //     </div>
    //   </Modal>
    // </div>
  );

  return content;
};

export default RequireAuth;