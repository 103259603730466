import { Checkbox } from 'antd';
import styled from 'styled-components';

const RoleItem = ({toDetail, roleName, description, checked, onChangeRole}: {toDetail: () => void, roleName: string, description: string, checked: boolean; onChangeRole: (checked: boolean) => void}) => {
  return (
    <RoleItemStyled>
      <div className="content">
        <Checkbox onChange={(e) => onChangeRole(e.target.checked)} checked={checked}/>
        <div className="content-text">
          <h3>{roleName}</h3>
          <p>{description}</p>
        </div>
      </div>
      <div onClick={toDetail} className='button'>
        <EyeButton/>
      </div>
    </RoleItemStyled>
  );
};

export default RoleItem;

const RoleItemStyled = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  .content {
    width: 100%;
    display: flex;
    gap: 24px;
  }
  .content-text {
    display: flex;
    flex-direction: column;
    gap: 8px;
    h3 {
      text-overflow: ellipsis;
      white-space: nowrap;
      font-size: 16px;
      font-weight: 600;
    }

    p {
      color: #8C8C8C;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-size: 12px;
      font-weight: 400;
    }
    
  }
  .button {
    cursor: pointer;
  }
`;

const EyeButton = () => {
  return (
    <svg width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect y="0.5" width="32" height="32" rx="6" fill="#FAFAFA" />
      <path d="M24.3327 16.4998C23.4993 13.9998 19.7493 10.6665 15.9993 10.6665C12.2493 10.6665 8.49935 13.9998 7.66602 16.4998" stroke="#121219" strokeWidth="1.5" strokeLinecap="round" />
      <path d="M7.66536 16.4997C8.4987 18.9997 12.2487 22.333 15.9987 22.333C19.7487 22.333 23.4987 18.9997 24.332 16.4997" stroke="#121219" strokeWidth="1.5" strokeLinecap="round" />
      <path d="M18.2874 16.4557C18.2874 17.734 17.2645 18.7912 15.9989 18.7912C14.7332 18.7912 13.7031 17.734 13.7031 16.4557C13.7031 15.1773 14.7332 14.2079 15.9989 14.2079C17.2645 14.2079 18.2874 15.1773 18.2874 16.4557Z" stroke="#141414" strokeWidth="1.5" />
    </svg>
  );
};