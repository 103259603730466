import PromotionInput, { IPromotionInputRef } from 'features/checkout/components/PromotionCode/PromotionInput';
import { IFuncPromotionAccept, IFuncSetErrorBoundary, IFuncSoftOnChangeText } from 'features/checkout/services/types/promotion';
import React, { useMemo, useRef, useState } from 'react';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
import salesActions from '../services/actions';
import salesSelectors from '../services/selectors';
import { Checkbox } from 'antd';
import { useTranslation } from 'react-i18next';
// eslint-disable-next-line @typescript-eslint/ban-types
type IPromotionCodeProps = {};
const PromotionCode: React.FC<IPromotionCodeProps> = () => {
  const promotionInputRef = useRef<IPromotionInputRef>(null);
  const dispatch = useAppDispatch();
  const merchant_location_id = salesSelectors.getMerchantLocationId();
  const selected = salesSelectors.getSelectedServices();
  const [isPromotion, setIsPromotion] = useState<boolean>(false);

  const amount = salesSelectors.getOriginTotalPrice();
  const voucherValue = salesSelectors.getVoucherValue() ?? 0;
  const promotionValue = salesSelectors.getPromotionValue();

  
  const isDisable = useMemo(() => {
    return amount - voucherValue === 0;
  }, [amount, voucherValue]);


  const isDisableCheck = useMemo(() => {
    if(!promotionValue?.promotionManualValue) return false;

    if(promotionValue.promotionManualValue > 0) return true;

  }, [promotionValue.promotionManualValue]);

  const errorBoundary = salesSelectors.getPromotionErrorBoundary();

  const onSoftOnChangeText: IFuncSoftOnChangeText = (val) => dispatch(salesActions.setSoftPromotionCode(val));

  const setErrorBoundary: IFuncSetErrorBoundary = (val) => dispatch(salesActions.setPromotionErrorBoundary(val));

  const onPromotionAccept: IFuncPromotionAccept = (val) => dispatch(salesActions.setPromotionInfo(val));  
  const {t: bookinglang} = useTranslation('booking');
  return (
    <ComponentCusStyled isDisable={isDisable}>
      <div className='form-row'>
        <Checkbox disabled={isDisableCheck} checked={isPromotion} onChange={(value) => {
          setIsPromotion(! isPromotion);

          if(!value.target.checked) {
            dispatch(salesActions.setSoftPromotionCode(null));
          }
          }} />
        <p className='form-label is-center'>
          {bookinglang('ApplyPromotion')}
        </p>
      </div>
      {isPromotion && <PromotionInput
        ref={promotionInputRef}
        location_id={merchant_location_id}
        onPromotionAccept={onPromotionAccept}
        errorBoundary={errorBoundary}
        softOnChangeText={onSoftOnChangeText}
        setErrorBoundary={setErrorBoundary}
        selectedServices={selected}
        handleUnCheck={()=>setIsPromotion(false)}
      />}
    </ComponentCusStyled>
  );
};

export default PromotionCode;
const ComponentCusStyled = styled.div<{isDisable: boolean}>`
  .form-row {
    margin-top: 24px;
  }

  opacity: ${props => props.isDisable ? 0.5 : 1};
  pointer-events: ${props => props.isDisable ? 'none' : undefined}
`;
