import { Select } from 'antd';
import InputSearchText from 'components/common/Input/InputSearchDebounce';
import { useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useAppDispatch } from 'store/hooks';
import VouchersTable from '../components/VouchersTable';
import voucherActions from '../services/actions';
import { VOUCHER_STATUS, VOUCHER_VIEW } from '../services/constants';
import voucherSelectors from '../services/selectors';
import AddVoucher from '../widgets/AddVoucher';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const VouchersPage = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [currView, setCurrView] = useState(searchParams.get('view') || VOUCHER_VIEW.SALE);
  const [currStatus, setCurrStatus] = useState(searchParams.get('status') || VOUCHER_STATUS.ALL);
  const dispatch = useAppDispatch();
  const paramsStore = voucherSelectors.getLstVoucherParams();
  const fetchFirstStatus = useRef<boolean>(false);
  const searchTime = useRef<boolean>(false);

  const { t: voucherLang } = useTranslation('vouchers');

  const voucherStatusOpts = [
    {
      label: voucherLang('AllStatus'),
      value: VOUCHER_STATUS.ALL,
    },
    {
      label: voucherLang('Active'),
      value: VOUCHER_STATUS.ACTIVE,
    },
    {
      label: voucherLang('Disable'),
      value: VOUCHER_STATUS.DISABLE,
    },
    {
      label: voucherLang('Invalid'),
      value: VOUCHER_STATUS.INVALID,
    },
    {
      label: voucherLang('Redeemed'),
      value: VOUCHER_STATUS.REDEEMED,
    },
    // {
    //   label: 'Sold',
    //   value: VOUCHER_STATUS.SOLD,
    // },

  ];

  const VoucherEnableOpts = [
    {
      label: voucherLang('AllStatus'),
      value: VOUCHER_STATUS.ALL,
    },
    {
      label: voucherLang('Active'),
      value: VOUCHER_STATUS.ACTIVE
    },
    {
      label: voucherLang('Deactive'),
      value: VOUCHER_STATUS.DEACTIVE
    }
  ];

  const voucherViewOpts = [
    {
      value: VOUCHER_VIEW.PURCHASED,
      label: voucherLang('Purchased'),
    },
    {
      value: VOUCHER_VIEW.SALE,
      label: voucherLang('ForSales'),
    },
  ];

  useEffect(() => {
    // @ts-ignore
    setSearchParams(paramsStore);
  }, [paramsStore]);

  // useEffect(() => {
  //   setCurrStatus(VOUCHER_STATUS.ALL);
  //   dispatch(voucherActions.setVoucherListParams.fetch({
  //     status: undefined
  //   }));
  // }, [currView]);

  useEffect(() => {
    if (!fetchFirstStatus.current) {
      const params = {
        page: Number(searchParams.get('page') ?? 1),
        per_page: Number(searchParams.get('per_page') ?? 10),
        status: currStatus !== VOUCHER_STATUS.ALL ? currStatus : undefined,
        view: currView,
        keyword: searchParams.get('keyword') ?? undefined,
      };

      if (params.status === undefined || params.status === null) {
        delete params.status;
      }
      if (params.keyword === undefined || params.keyword === null) {
        delete params.keyword;
      }

      dispatch(voucherActions.setVoucherListParams.fetch(params));
      dispatch(voucherActions.getDataConfigs());

      fetchFirstStatus.current = true;
    }
  }, []);

  const handleSelectViewChange = (e: string) => {
    setCurrView(e);
    setCurrStatus(VOUCHER_STATUS.ALL);
    dispatch(voucherActions.setVoucherListParams.fetch({
      view: e,
      page: 1,
      status: undefined
    }));
  };
  const handleSelectStatusChange = (e: string) => {
    setCurrStatus(e);
    dispatch(voucherActions.setVoucherListParams.fetch({
      status: e !== VOUCHER_STATUS.ALL ? e : undefined
    }));
  };
  const handleSearchVoucher = (e: string) => {
    if (searchTime.current) {
      dispatch(voucherActions.setVoucherListParams.fetch({
        keyword: e || undefined,
        page: 1,
      }));
    }
    if (!searchTime.current && !e?.length) {
      searchTime.current = true;
    }
  };

  return (
    <>
      <div className={'private-filter'}>
        <InputSearchText value={paramsStore.keyword} onSearchText={handleSearchVoucher} />
        <VouchersPageStyled>
          <div className='common-select'>
            <Select
              defaultValue={'purchased'}
              value={currView}
              onChange={handleSelectViewChange}
              options={voucherViewOpts}
            />
          </div>
          <div className='common-select'>
            <Select
              value={currStatus}
              onChange={handleSelectStatusChange}
              options={currView !== VOUCHER_VIEW.SALE ? voucherStatusOpts : VoucherEnableOpts}
            />
          </div>
        </VouchersPageStyled>
        <AddVoucher />
      </div>
      <VouchersTable currView={currView} />
    </>
  );
};

export default VouchersPage;
const VouchersPageStyled = styled.div`
display: flex;
gap: 8px;
`;