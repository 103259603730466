import { Checkbox, ConfigProvider, DatePicker, Form, Input, Modal, Select } from 'antd';
import { useEffect, useRef, useState } from 'react';
import { AddNewCustomerFormRef, IFormAddCustomerData, IFuncCancel, IFuncSubmit } from './CustomersForm';
import { AxiosResponse } from 'axios';
import message from 'components/common/Message';
import customerActions from 'features/customers/services/actions';
import apisCustomer from 'features/customers/services/apis';
import { IApiCreateNewCustomerBody } from 'features/customers/services/types/cutomer';
import { get, set } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from 'store/hooks';
import storage from 'utils/sessionStorage';
import IconClose from 'assets/svg/IconClose';
import styled from 'styled-components';
import TextArea from 'antd/es/input/TextArea';
import FormRow from 'components/common/Form/FormRow';
import PhoneNumberInput from 'components/common/PhoneNumberInput';
import 'dayjs/locale/vi';
import viVN from 'antd/lib/locale/vi_VN';
import enEL from 'antd/lib/locale/en_US';
import dayjs from 'dayjs';

/**
 * Add new customer
 * @returns 
 */
const AddNewCustomer = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const formRef = useRef<AddNewCustomerFormRef>(null);
  const { t: customerLang } = useTranslation('customer');

  const dispatch = useAppDispatch();
  const [errors, setErrors] = useState<Record<string, string> | null>(null);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel: IFuncCancel = () => {
    setIsModalOpen(false);
    setErrors(null);
  };
  const getPayload = (data: IFormAddCustomerData) => {
    const merchant_id = storage.merchantId.get() ?? '';
    const name = data?.name;
    const phone_number = data?.phoneNumber ?? '';
    const email = data?.email ?? '';
    const date_of_birth = data.birthDate ? data.birthDate.format('YYYY-MM-DD') : null;
    const total_visited = parseInt(data?.numberVisits ?? 0);
    const total_cancelled = parseInt(data?.cancellations ?? 0);
    const total_no_show = parseInt(data?.noShows ?? 0);
    const loyalty_point = parseInt(data?.loyaltyPoints ?? 0);
    const important_client_info = data?.importantClientInfo ?? null;
    const gender = data?.gender ?? null;
    const amount_spent = data?.amountSpent ?? 0;
    const payload: IApiCreateNewCustomerBody = {
      merchant_id,
      name,
      phone_number,
      email,
      gender,
      date_of_birth,
      total_visited,
      total_cancelled,
      total_no_show,
      loyalty_point,
      amount_spent,
      notify_market: 0,
      notification_customer: 1,
      important_client_info,
    };
    return payload;
  };

  /**
   * Handle submit
   * @param data 
   * @returns 
   */
  const handleSubmit: IFuncSubmit = async (data) => {
    // handleCancel();
    // Extract payload from data
    const payload = getPayload(data);
    try {
      // Send request to create a new customer
      const rest: AxiosResponse<{ message: string }> = await apisCustomer.createNewCustomer(payload);
      // Extract message from the response
      const msg = get(rest, 'data.message', '');
      if (msg) {
        // If success message is available:
        // - Call handleCancel to reset form fields and close modal
        // - Display success message
        // - Dispatch action to update state indicating successful customer creation
        handleCancel();
        message.success(msg);
        dispatch(customerActions.createCustomer.success({}));
        return true; // Indicate successful submission
      }
      return false; // Indicate submission failed if no success message is available
    } catch (error: any) {
      const errors = Object.entries(get(error, 'response.data.errors', {}));
      const errorsData = {};
      if (errors.length > 0) {
        // If there are errors in the response data:
        // - Map through errors and extract error messages for each field
        // - Update errorsData with the extracted error messages
        errors.map(([key, text]) => {
          const msg = get(text, [0]);
          // message.error(msg);
          set(errorsData, [key], msg);
        });
        if (errors.some(([key]) => {
          if (['phone_number', 'name', 'email', 'date_of_birth'].includes(key)) return true;
          // If errors include specific fields (phone_number, name, email, date_of_birth):
          // - Move back to the previous step in the form (if applicable)
          return false;
        })) {
          formRef.current?.prevStep();
        }
        // Update errors state with the extracted error messages
        setErrors(errorsData);
      }
      else {
        // If no specific errors are provided, display a generic error message
        message.error('An error occurred. Please try again');
      }
      return false; // Indicate submission failed
    }
  };

  return (
    <>
      <button onClick={showModal} className={'common-btn'}>
        {customerLang('New.AddBtn')}
      </button>
      {isModalOpen &&
        <AddCustomer
          visible
          errors={errors}
          handleCancel={handleCancel}
          handleSubmit={handleSubmit}
        />}
    </>
  );
};

type AddCustomerProps = {
  visible?: boolean;
  handleCancel?: () => void;
  handleSubmit?: IFuncSubmit;
  errors?: Record<string, string> | null;
};
const AddCustomer = ({ visible, handleSubmit, handleCancel, errors }: AddCustomerProps) => {
  const { t: customerLang } = useTranslation('customer');
  const { t: voucherLang } = useTranslation('vouchers');
  const { t: formLang } = useTranslation('form');
  const { t: bookingLang } = useTranslation('booking');
  const [form] = Form.useForm();
  const { Option } = Select;
  const _onSubmit = () => form.submit();
  const [errorsText, setErrorsText] = useState<any>();
  const validateMessages = {
    required: '${label} is required!',
    types: {
      email: '${label} is not a valid email!',
      number: '${label} is not a valid number!',
    },
    number: {
      range: '${label} must be between ${min} and ${max}',
    },
  };

  useEffect(() => {
    const _errorsText = ({
      name: get(errors, 'name', ''),
      phone_number: get(errors, 'phone_number', ''),
      email: get(errors, 'email', ''),
      date_of_birth: get(errors, 'date_of_birth', ''),
      important_client_info: get(errors, 'important_client_info', ''),
    });
    setErrorsText(_errorsText);
  }, [errors]);

  const onChangeKey = (key: string) => () => {
    if (!errorsText) return;
    if (errorsText[key] === undefined) return;
    const newErrorsText = { ...errorsText };
    delete newErrorsText[key];
    setErrorsText(newErrorsText);
  };

  const lang = 'vi';
  dayjs.locale(lang === 'vi' ? 'vi' : 'en');
  const locale = lang === 'vi' ? viVN : enEL;

  const onChange = () => undefined;

  return (
    <Modal
      maskClosable={false}
      open={visible}
      onCancel={handleCancel}
      okButtonProps={{ style: { display: 'none' } }}
      width={1200}
      footer={null}
      forceRender
      centered
      closable={false}
    >
      <Form
        data-form-testing='form-customer-statistical-add'
        form={form}
        layout="vertical"
        initialValues={{
          phoneNumberCode: '+92',
          phoneNumber: null,
          email: null,
          birthDate: null,
          numberVisits: 0,
          cancellations: 0,
          noShows: 0,
          loyaltyPoints: 0,
          amountSpent: 0,
          contact: null,
          importantClientInfo: null,
          gender: 0,
        }}
        onKeyUp={(e) => {
          if (e.key === 'Enter') {
            _onSubmit();
          }
        }}
        onFinish={handleSubmit}
        validateMessages={validateMessages}
        scrollToFirstError
      >
        <AddCustomerStyled>
          <div className='icon-close' onClick={handleCancel}>
            <IconClose />
          </div>
          <h2 className='modal-title'>{customerLang('New.ModalTitle')}</h2>
          <button type='submit' className='common-btn' >
            {voucherLang('Xác nhận')}
          </button>
        </AddCustomerStyled>
        <CustomersFormFirstStepStyled>
          <div className='left'>
            <div className='profile-customer'>
              <div className='title'>
                <h4>Thông tin khách hàng</h4>
              </div>
              <Form.Item
                label={customerLang('Họ và tên (*)')}
                name="name"
                validateStatus={errorsText?.name ? 'error' : undefined}
                help={errorsText?.name ? errorsText?.name : undefined}
                rules={[
                  { required: true, message: customerLang('PleaseInputYourName') || '' },
                  { min: 2, message: bookingLang('NameMustBeAtLeast2Characters') || '' },
                  { max: 30, message: bookingLang('NameMustBeAtMost30Characters') || '' }
                ]}
              >
                <Input autoFocus placeholder={formLang('EnterCustomerNamePlaceholder') || ''} onChange={onChangeKey('name')} />
              </Form.Item>

              <Form.Item label={customerLang('PhoneNumber') + ' (*)'} name="phoneNumber"
                validateStatus={errorsText?.phone_number ? 'error' : undefined}
                help={errorsText?.phone_number ? errorsText?.phone_number : undefined}
                rules={[
                  { required: true, message: formLang('PhoneNumberIsRequired') || '' },
                  { pattern: /^0+[0-9]{9}$/, message: formLang('ThePhoneNumberIs04AndHas10Digits') || '' }
                ]}
              >
                <PhoneNumberInput placeholder={'0_________'} onChange={onChangeKey('phone_number')} />
              </Form.Item>

              <div className='form-row'>
                <Form.Item label={
                  <p>
                    {customerLang('Email')}
                  </p>
                } name="email"
                  rules={[
                    { type: 'email', message: customerLang('IsNotAValidEmail') || '' },
                  ]}
                >
                  <Input placeholder={customerLang('EnterCustomerEmail') || ''} onChange={onChangeKey('email')} />
                </Form.Item>
              </div>
              <div className='gender-birthDate'>
                <FormRow label={customerLang('Gender')} name='gender'>
                  <Select>
                    <Option value={0}>{customerLang('Female')}</Option>
                    <Option value={1}>{customerLang('Male')}</Option>
                    <Option value={2}>{customerLang('NonBinary')}</Option>
                  </Select>
                </FormRow>
                <div className="form-row">
                  <ConfigProvider locale={locale}>
                    <Form.Item
                      name="birthDate"
                      label={
                        <p className='BirthDate-title'>
                          {customerLang('BirthDate')}
                        </p>
                      }
                    >
                      <DatePicker onChange={onChange} placeholder='DD-MM-YYYY' clearIcon={false} />
                    </Form.Item>
                  </ConfigProvider>
                </div>
              </div>
              <Form.Item
                label={customerLang('Địa chỉ')}
                name="address"
                help={'Ví dụ: 223 Nguyễn Xí, phường 13, quận Bình Thạnh, HCMC.'}
              >
                <Input placeholder='Nhập địa chỉ' />
              </Form.Item>
            </div>
            <div className='note-customer'>
              <div className='title'>
                <h4>Ghi chú khách hàng</h4>
                {/* <p>Thông tin này sẽ chỉ hiển thị cho bạn hoặc người có quyền truy cập vào hệ thống.</p> */}
              </div>
              <div className='note-container'>
                <Form.Item
                  label={
                    <p>
                      Chi tiết
                      <span>({customerLang('Optional')})</span>
                    </p>
                  }
                  name='note'
                  help={formLang('ThisNoteWillBeDisplayedOnAllBookings')}
                >
                  <TextArea
                    rows={5}
                    placeholder={formLang('EgCustomerAllergicToFragrance') || ''}
                  />
                </Form.Item>
                {/* <Form.Item valuePropName={'checked'} name='isUpdateEmail'>
                  <Checkbox className='check-show-booking'>{'Hiển thị cho mọi lịch hẹn'}</Checkbox>
                </Form.Item> */}
              </div>
            </div>
          </div>
          <div className='right'>
            <div className='right-content'>
              <div className='title-right'>
                <h4>Cài đặt thông báo</h4>
                <p>Cài đặt cập nhật thông báo cho khách hàng về các lịch hẹn, sản phẩm và chương trình khuyến mãi.</p>
              </div>
              <ul>
                <li className='notification-booking-service'>
                  <div className={'setting-notification'}>
                    <p>{customerLang('Thông tin lịch hẹn và sản phẩm')}</p>
                    <Form.Item valuePropName={'checked'} name='1'>
                      <Checkbox className='check-show-booking'>{'Gửi qua tin nhắn SMS'}</Checkbox>
                    </Form.Item>
                    <Form.Item valuePropName={'checked'} name='2'>
                      <Checkbox style={{ marginBottom: '24px' }} className='check-show-booking'>{'Gửi qua tin nhắn Zalo'}</Checkbox>
                    </Form.Item>
                  </div>
                </li>
                <li className='maketings'>
                  <div className={'setting-notification'}>
                    <p>{customerLang('Thông tin chương trình marketings')}</p>
                    <Form.Item valuePropName={'checked'} name='3'>
                      <Checkbox className='check-show-booking'>{'Gửi qua tin nhắn SMS'}</Checkbox>
                    </Form.Item>
                    <Form.Item valuePropName={'checked'} name='4'>
                      <Checkbox className='check-show-booking'>{'Gửi qua tin nhắn Zalo'}</Checkbox>
                    </Form.Item>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </CustomersFormFirstStepStyled>
      </Form>

      {/* </Form> */}
    </Modal>
  );
};

export default AddNewCustomer;

const CustomersFormFirstStepStyled = styled.div`
display: flex;
gap: 24px;
margin-top: 24px;
padding: 0 50px;
@media only screen and (max-width: 767.98px) {
  display: grid;
  padding: 0 8px;
}
.left {
  flex: 1;
  display: grid;
  gap: 24px;
  .profile-customer {
    border-radius: 12px;
    border: 1px solid var(--Gray-30, #D9D9D9);
    padding: 24px;
    display: grid;
    gap: 24px;
    .title {
      color: var(--Gray-95, #141414);
      font-family: Inter;
      font-size: 20px;
      font-weight: 700;
      line-height: 140%;
    }
    .gender-birthDate {
      display: flex;
      gap: 12px;
      @media only screen and (max-width: 767.98px) {
        display: grid;
      }
    }
  }
  .note-customer {
    border-radius: 12px;
    border: 1px solid var(--Gray-30, #D9D9D9);
    padding: 24px;
    display: grid;
    gap: 24px;
    .title {
      display: grid;
      gap: 8px;
      h4 {
        color: var(--Gray-95, #141414);
        font-family: Inter;
        font-size: 20px;
        font-weight: 700;
        line-height: 140%;
      }
      p {
        color: var(--Gray-60, #595959);
        font-family: Inter;
        font-size: 12px;
        font-weight: 400;
        line-height: 140%;
      }
    }
    .note-container {
      display: grid;
      gap: 16px;
    }
  }
}

.ant-form-item-control {
  margin-bottom: 0 !important;
}

.right {
  flex: 1;
  .right-content {
    border-radius: 12px;
    border: 1px solid var(--Gray-30, #D9D9D9);
    padding: 24px;
    display: grid;
    gap: 24px;

    .title-right {
    display: grid;
    gap: 8px;
    h4 {
      color: var(--Gray-95, #141414);
      font-family: Inter;
      font-size: 20px;
      font-weight: 700;
      line-height: 140%;
    }
    p {
      color: var(--Gray-60, #595959);
      font-family: Inter;
      font-size: 12px;
      font-weight: 400;
      line-height: 140%;
    }
  }
  ul {
    .notification-booking-service {
      margin-bottom: 24px;
      border-bottom: 1px solid #F0F0F0;
      .setting-notification {
      p {
        color: var(--Gray-95, #141414);
        font-family: Inter;
        font-size: 16px;
        font-weight: 600;
        line-height: 140%;
      }
      span {
        color: var(--Gray-95, #141414);
        font-family: Inter;
        font-size: 16px;
        font-weight: 500;
        line-height: 140%;
      }
    }
    
    }
    .maketings {
      .setting-notification {
        p {
          color: var(--Gray-95, #141414);
          font-family: Inter;
          font-size: 16px;
          font-weight: 600;
          line-height: 140%;
        }
        span {
          color: var(--Gray-95, #141414);
          font-family: Inter;
          font-size: 16px;
          font-weight: 500;
          line-height: 140%;
        }
      }
    }
  }
  }
}
`;


const AddCustomerStyled = styled.div`
display: flex;
justify-content: space-between;
align-items: center;
border-bottom: 1px solid #1B9AAA;
position: sticky;
background: white;
z-index: 99999;
top: -50px;
padding-top: 10px;
.icon-close {
  cursor: pointer;
  width: 20%;
}
.modal-title {
  width: 60%;
  margin-bottom: 0px;
  text-align: center;
}
button {
  width: 20%;
  min-width: 0px;
}
`;
