import React, { useEffect } from 'react';
import styled from 'styled-components';
import { LabelStyled } from './BasicContent';
import bookingSelectors from 'features/bookings/services/selectors';
import locationSelectors from 'features/locations/services/selectors';
import { useDispatch } from 'react-redux';
import locationActions from 'features/locations/services/actions';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';



const Location: React.FC<{isCheckin?: boolean}> = ({isCheckin}) => {

  const dispatch = useDispatch();
  const {t: bookingLang} = useTranslation('booking');
  const {merchant_code, location_id} = useParams();

  useEffect(() => {
    
    isCheckin && dispatch(locationActions.getLocationDetailPublic.fetch({merchant_code, location_id}));
  },[]);

  const location = isCheckin ? locationSelectors.getLocationDetailPublic() : bookingSelectors.bookingOnline.getSelectedLocation();
  return (
    <LocationStyled>
      <LabelStyled>{bookingLang('Location')}</LabelStyled>
      <p className='text'>{location?.name}</p>
      <p className='text'>{location?.address}</p>
    </LocationStyled>
  );
};

export default Location;

const LocationStyled = styled.div`
 p.text {
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  color: var(--color-primary);
 }
`;
