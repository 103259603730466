import checkoutSelectors from 'features/checkout/services/selectors';
import promotionSelectors from 'features/promotions/services/selectors';
import settingSelectors from 'features/settings/services/selectors';
import { useMemo } from 'react';

/**
 * Use checkout payment
 * @returns 
 */
export const useCheckoutPayment = () => {

  const holiday = settingSelectors.getHolidayInfoToday();
  const bookingSetting = settingSelectors.getSetting()?.booking;
  const cardSurchargeValue = bookingSetting?.value_card_surchange;
  const isCardSurcharge = bookingSetting?.is_card_surchange;
  const cardValue = checkoutSelectors.getPurchaseCurrentCard();
  const selectedServices = checkoutSelectors.getSelectedServices();
  const promotionAuto = promotionSelectors.getPromotionAuto();
  const setting = settingSelectors.getSetting();
  const promotionValue = checkoutSelectors.getPromotionValue();
  const promotionInfo = checkoutSelectors.getPromotionInfo();
  // const promotionCode = checkoutSelectors.getSoftPromotionCode();

  const promotionPayment = useMemo(() => {

    if(!setting?.booking?.both_promotion_types_allow) {
      return {
        promotion_code: promotionValue.promotionManualValue > promotionValue.promotionAutoValue ? promotionInfo?.code ?? null : undefined,
        promotion_auto_offer:  promotionValue.promotionAutoValue > promotionValue.promotionManualValue ? promotionAuto?.id : undefined, // The promotion auto's id is not the value.
      };
    } else {
      return {
        promotion_code: (promotionInfo?.code || null),
        promotion_auto_offer: promotionAuto?.id, // The promotion auto's id is not the value.
      };
    }
  }, [promotionValue, promotionInfo]);

  return ({
    holiday_surcharge_id: selectedServices.length > 0 ? holiday?.id : undefined,
    card_surcharge_percent: isCardSurcharge && cardValue > 0 ? cardSurchargeValue : undefined,
    // promotion_type: promotionInfo?.promotion_type,
    // promotion_retention_code:  promotionCode ,
    ...promotionPayment 

  });
};