import { Checkbox, Form, Switch } from 'antd';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { uniq } from 'lodash';
import { Dispatch, SetStateAction, useEffect, useMemo } from 'react';
import styled from 'styled-components';

export interface IPermissionItem {
  id: number;
  display_name: string;
  name: string;
}

const PermissionGroup = ({label, options, values, onChange}: {label: string, options: IPermissionItem[], values: string[], onChange: Dispatch<SetStateAction<string[]>>}) => {    

  const form = Form.useFormInstance<any>();

  const onChangePermission = (checked: boolean, name: string) => {

    if(checked) return onChange((prev: string[]) => [...prev, name]);
    
    onChange((prev) => {
      const result = prev.filter(val => val !== name);
      return result;
    });
  };

  useEffect(() => {
    options.forEach((opt) => {
      
      form.setFieldValue(opt.name, values.some(val => opt.name === val));
    });
  }, [values]);

  const onChangeAll = (e: CheckboxChangeEvent) => {
    if(e.target.checked) return onChange(prev => uniq(prev.concat(options.map(opt => opt.name))));

    onChange(values.filter(val => !options.some(opt => opt.name === val)));
  };

  const isCheckedAll = useMemo(() => {
    const valChecked = values.filter(val => options.some(opt => opt.name === val));

    return valChecked.length === options.length;
  }, [values]);

  

  return (
    <PermissionGroupStyled>
      <div className="permission-group-header">
        <h3>{label}</h3>
        <Checkbox onChange={onChangeAll} checked={isCheckedAll}>Chọn tất cả</Checkbox>
      </div>
      <div className='list-permission'>
        {options.map((option, index) => (
          <div key={index} className='form-row switch-check'>
            <Form.Item key={index} noStyle name={`${option.name}`} valuePropName='checked'>
              <Switch key={index} onChange={(checked) => onChangePermission(checked, option.name)} defaultChecked={values.some(val => option.name === val)} />
            </Form.Item>
             <p className='form-label'>{option.display_name}</p>
           </div>
        ))}
      </div>
      {/* <button onClick={() => form.su}></button> */}
    </PermissionGroupStyled>
  ); 
};

export default PermissionGroup;

const PermissionGroupStyled = styled.div`
  .permission-group-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;
    h3 {
      font-size: 16px;
      font-weight: 700;
      text-transform: capitalize;
    }
  }
  .list-permission {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
`;