import { Form, InputNumber, Modal } from 'antd';
import FormRow from 'components/common/Form/FormRow';
import settingSelectors from 'features/settings/services/selectors';
import { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

type IFormValues = {
  _one: number;
  _two: number;
  _three: number;
  _four: number;
}

type Props = {
  visible?: boolean;
  onCancel?: () => void;
  onSubmit?: () => void;
};

const Contain = ({ onCancel = () => undefined, visible = false, onSubmit = () => undefined }: Props) => {
  const [form] = Form.useForm();
  const currentPin = settingSelectors.getPin();

  const _one = useRef<HTMLInputElement>(null);
  const _two = useRef<HTMLInputElement>(null);
  const _three = useRef<HTMLInputElement>(null);
  const _four = useRef<HTMLInputElement>(null);
  const [errorPin, setErrorPin] = useState('');

  const _onCancel = () => {
    onCancel();
  };

  const handleSubmit = (values: IFormValues) => {
    setErrorPin('');
    const pinValue = String(values['_one'] ?? '') + String(values['_two'] ?? '') + String(values['_three'] ?? '') + String(values['_four'] ?? '');
    if (!pinValue || pinValue.length !== 4) {
      setErrorPin('Please enter digits PIN!');
      return;
    }
    if (currentPin !== pinValue) {
      setErrorPin('Your pin not correct, please try again!');
      return;
    }
    onCancel();
    onSubmit();
  };

  return (
    <Modal
      maskClosable={false}
      open={visible}
      onCancel={_onCancel}
      forceRender
      centered
      className='modal-cancel-booking'
    >
      <VerifyPinStyled>
        <h2 className='modal-title'>
          <p>{'Enter PIN'}</p>
        </h2>
        <Form
          form={form}
          className='modal-form'
          layout='vertical'
          onFinish={handleSubmit}
          onValuesChange={(changedValues: any, values) => {
            if (changedValues['_one'] !== undefined && changedValues['_one'] && values['_two'] === undefined) {
              _two.current?.focus();
            }
            if (changedValues['_two'] !== undefined && changedValues['_two'] && values['_three'] === undefined) {
              _three.current?.focus();
            }
            if (changedValues['_three'] !== undefined && changedValues['_three'] && values['_four'] === undefined) {
              _four.current?.focus();
            }
          }}
        >
          <div className='PIN_form'>
            <p>Please enter a 4 digits PIN to confirm this action</p>
            <Form.Item extra={errorPin}>
              <div className="PIN_group">
                <FormRow shouldUpdate noStyle name="_one">
                  <InputNumber
                    className='PIN_input'
                    ref={_one}
                    maxLength={1}
                    minLength={1}
                    autoFocus
                    onChange={e => {
                      if (e === null || e === undefined) return;
                      form?.setFieldValue('_two', undefined);
                      setTimeout(() => _two.current?.focus(), 1);
                    }}
                    onFocus={() => {
                      setTimeout(() => _one.current?.setSelectionRange(0, 2), 1);
                    }}
                  />
                </FormRow>
                <FormRow shouldUpdate noStyle name="_two">
                  <InputNumber
                    className='PIN_input'
                    ref={_two}
                    maxLength={1}
                    minLength={1}
                    onChange={e => {
                      if (e === null || e === undefined) return;
                      form?.setFieldValue('_three', undefined);
                      setTimeout(() => _three.current?.focus(), 1);
                    }}
                    onFocus={() => {
                      setTimeout(() => _two.current?.setSelectionRange(0, 2), 1);
                    }}
                    onKeyDown={e => {
                      if (e.key === 'Backspace') {
                        setTimeout(() => { _one.current?.focus(); }, 1);
                      }
                    }}
                  />
                </FormRow>
                <FormRow shouldUpdate noStyle name="_three">
                  <InputNumber
                    className='PIN_input'
                    ref={_three}
                    maxLength={1}
                    minLength={1}
                    onChange={e => {
                      if (e === null || e === undefined) return;
                      form?.setFieldValue('_four', undefined);
                      setTimeout(() => _four.current?.focus(), 1);
                    }}
                    onFocus={() => {
                      setTimeout(() => _three.current?.setSelectionRange(0, 2), 1);
                    }}
                    onKeyDown={e => {
                      if (e.key === 'Backspace') {
                        setTimeout(() => { _two.current?.focus(); }, 1);
                      }
                    }}
                  />
                </FormRow>
                <FormRow shouldUpdate noStyle name="_four">
                  <InputNumber
                    className='PIN_input'
                    ref={_four}
                    accept='/^[0-9]*$/'
                    maxLength={1}
                    minLength={1}
                    onFocus={() => {
                      setTimeout(() => _four.current?.setSelectionRange(0, 2), 1);
                    }}
                    onKeyDown={e => {
                      if (e.key === 'Backspace') {
                        setTimeout(() => { _three.current?.focus(); }, 1);
                      }
                    }}
                  />
                </FormRow>
              </div>
            </Form.Item>
            <p>Don’t remember the number? <Link to='/private/settings' className='label-highlight'><b>Reset PIN</b></Link></p>
            <div className='form-row form-submit-wrapper form-submit-floating'>
              <button className='common-btn is-white' onClick={_onCancel}>
                Back
              </button>
              <button className='common-btn' type='submit'>
                Confirm
              </button>
            </div>
          </div>
        </Form>
      </VerifyPinStyled>
    </Modal>
  );
};

const VerifyPin = (props: Props) => {
  if (!props.visible) return null;

  return <Contain visible onSubmit={props.onSubmit} onCancel={props.onCancel} />;
};

export default VerifyPin;

const VerifyPinStyled = styled.div`
  .ant-form-item-extra {
    color:#ff4d4f;
  }
  .hide {
    display:none;
  }
  .form-confirm {
    text-align: center;
  }
  .PIN_form {
    text-align: center;
    .label-highlight {
      color:#4A9D77;
    }
  }
  .PIN_group {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    width:100%;
    margin-top: 10%;
    margin-bottom: 5%;
  }
  .PIN_input {
    flex:1;
    background: none;
    border: unset;
    border-radius: 0;
    border-bottom: 14px solid #C3C2DE;
    input {
      padding: 0;
      font-style: normal;
      font-weight: 600;
      font-size: 40px;
      line-height: 140%;
      text-align: center;
      color: #363565;
      background: none;
    }
    &.ant-input-number {
      width: 40px;
    }
  }
`;