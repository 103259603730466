export const NAME_REDUCER = 'Setting';

export const PREFIX_ACTIONS = 'SETTING_FEATURE_';

export const PATH_LOADING = {
  getSetting: `loading.${NAME_REDUCER}.getSetting`,
  getSettingBookingForm: `loading.${NAME_REDUCER}.getSettingBookingForm`,
  getOpionBooking: `loading.${NAME_REDUCER}.getOpionBooking`,
  getListClosedDate: `loading.${NAME_REDUCER}.getListClosedDate`,
  getNumberOfNotification: `loading.${NAME_REDUCER}.getNumberOfNotification`,
  getListCancellationReason: `loading.${NAME_REDUCER}.getListCancellationReason`,
  getListServiceCharge: `loading.${NAME_REDUCER}.getListServiceCharge`,
  getDetailServiceCharge: `loading.${NAME_REDUCER}.getDetailServiceCharge`,
  getShowServiceCharge: `loading.${NAME_REDUCER}.getShowServiceCharge`,
  getDetailServiceChage: `loading.${NAME_REDUCER}.getDetailServiceChage`,
  getTopUpHistory: `loading.${NAME_REDUCER}.getTopUpHistory`,
  getTopUpSMSHistory: `loading.${NAME_REDUCER}.getTopUpSMSHistory`,
  getMerchantStripeBalance: `loading.${NAME_REDUCER}.getMerchantStripeBalance`,
  getTopUpInitData: `loading.${NAME_REDUCER}.getTopUpInitData`,
};

export const TOPUP_OPTION = [
  {
    value: 30,
    label: '$30.00',
  },
  {
    value: 50,
    label: '$50.00',
  },
  {
    value: 100,
    label: '$100.00',
  },
];
