import { Checkbox, Form } from 'antd';
import bookingSelectors from 'features/bookings/services/selectors';
import { IServiceQuickBookingItem } from 'features/bookings/services/types/quickBooking';
import { useMemo, useState } from 'react';
import styled from 'styled-components';
import InputSearchServices from '../InputSearchServices';
import ListCategory, { IServiceCategoryItem } from '../ListCategory';
import ServiceItem, { IFuncGetIsCheck, ServiceGroup } from './ServiceItem';
import { uniqBy } from 'lodash';
import { useTranslation } from 'react-i18next';

type Props = {
  selected?: string[];
  setSelected?: (selected: string[]) => void;
  disabledSelect?: boolean
};

const ServicesSelection = ({
  selected = [],
  setSelected = () => undefined,
  disabledSelect
}: Props) => {

  const form = Form.useFormInstance();

  const locationId = form.getFieldValue('location');
  const dataConfigs = bookingSelectors.quickBooking.getDataConfigs();

  const serviceOption = useMemo(() => {

    return dataConfigs?.services.filter(o => {
      return o.merchant_location_id?.find(e => e === Number(locationId));
    });
  }, [locationId, dataConfigs?.services]);


  const {t: locationLang} = useTranslation('location');
  const services = useMemo(() => serviceOption ?? [], [serviceOption]);

  const categories = useMemo(() => {
    const result: IServiceCategoryItem[] = [];
    result.push({
      id: 'general',
      color: '#4A9D77',
      name: locationLang('General'),
      total_sale: Number.MAX_SAFE_INTEGER
    });
    dataConfigs?.categories?.filter(o => o.location_ids?.find(e => e === Number(locationId))).forEach(o => {
      result.push(o);
    });
    return result;
  }, [dataConfigs?.categories, locationId]);

  const [search, setSearch] = useState('');
  const [category, setCategory] = useState<string>('general');
  const {t: userLang} = useTranslation('user');

  const _services = useMemo(() => {
    const _search = search.toLowerCase();
    return services.filter(o =>
      category === 'general' ? true : String(o.category.id) === category
    ).filter(o => {
      if (o?.name?.toLowerCase().includes(_search)) {
        return true;
      }
      return !!o?.service_variants?.some(s => s.name?.toLowerCase().includes(_search));
    });
  }, [services, category, search]);

  const allServices = useMemo(() => {
    const result: string[] = [];

    _services?.forEach((service) => {
      if (service?.service_variants?.length > 0) {
        service?.service_variants.forEach(serviceVariant => {
          result.push(service?.id + '-' + serviceVariant.id);
        });
      } else {
        result.push(service?.id?.toString());
      }
    });
    return result;
  }, [_services]);


  const handleInputChange = (value: string) => {
    setSearch(value);
  };
  const handleServiceChange = (
    _: boolean,
    serviceItem: IServiceQuickBookingItem
  ) => {
    const id = serviceItem.service_variant_id ? serviceItem.id + '-' + serviceItem.service_variant_id : serviceItem.id.toString();

    const idx = selected.indexOf(id);
    if (idx !== -1) {
      setSelected(selected.filter(o => o !== id));
    } else {
      const temp = [...selected];
      temp.push(id);
      setSelected(temp);  
      
    }
  };

  if (services.length === 0) return null;

  const getIsChecked: IFuncGetIsCheck = ({ service_variant_id, service_id }) => {
    const id = service_variant_id ? service_id + '-' + service_variant_id : service_id;

    const currentIdx = selected.indexOf(id.toString());
    return currentIdx !== -1;
  };
  
  return (
    <>
      <InputSearchServices handleChange={handleInputChange} isGray={true} />
      <ListCategory
        data={categories}
        onChange={setCategory}
        value={category}
        disableScroll
      />
      {allServices.filter(service => selected.find(selected => service === selected))?.length === allServices.length ?
        <CusCheckBox checked 
          disabled={disabledSelect}
          onClick={() => setSelected( selected.filter(service => !allServices.includes(service)))} 
          title={userLang('UnselectAllServices')||''} />
        : <CusCheckBox checked={false} onClick={() => setSelected(uniqBy([...selected ,...allServices], (data) => data))} title={userLang('SelectAllServices')||''} />
      }
      <ServicesSelectionStyled>
        <table>
          <tbody>
            {_services?.map((service) => {
              if (
                service.service_variants &&
                service.service_variants?.length > 0
              ) {
                return (
                  <ServiceGroup
                    key={service.id}
                    service={service}
                    getIsChecked={getIsChecked}
                    onServiceChange={handleServiceChange}
                    disabled={disabledSelect}
                  />
                );
              }
              return (
                <ServiceItem
                  key={service.id}
                  service={service}
                  getIsChecked={getIsChecked}
                  onServiceChange={handleServiceChange}
                  disabled={disabledSelect}
                />
              );
            })}
          </tbody>
        </table>
      </ServicesSelectionStyled>
    </>
  );
};

export default ServicesSelection;
type CusCheckBoxProps = {
  checked?: boolean;
  title?: string;
  onClick?: () => void;
  disabled?: boolean
};
const CusCheckBox = ({ title = '', checked, onClick, disabled }: CusCheckBoxProps) => {
  return (
    <CusCheckBoxStyled className='form-row' onClick={onClick}>
      <Checkbox checked={checked} disabled={disabled}/>
      <p className='form-label is-center'>
        {title}
      </p>
    </CusCheckBoxStyled>
  );
};
const CusCheckBoxStyled = styled.div`
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
  &:hover {
    cursor: pointer;
    background: #F0F2F7;
  }
`;

const ServicesSelectionStyled = styled.div`
  max-height: 300px;
  overflow-y: scroll;
  table {
    width: 100%;
  }
  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #f5f5f5;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #d9d9d9;
  }

  @media (max-width: 767.98px) {
    min-height: 300px;
  }
`;

