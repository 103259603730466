import React from 'react';
import styles from './index.module.scss';
interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  label?: string,
  children?: React.ReactElement;
}

/**
 * Handle primary button
 * @param label, children, ...props
 * @returns 
 */
const PrimaryButton: React.FC<Props> = ({ label = '', children = null, ...props }) => {
  return (
    <button
      {...props}
      className={`${styles.container} ${props.className}`}
    >
      {children ? children : <span className={styles.label}>{label}</span>}
    </button>
  );
};

export default PrimaryButton;
