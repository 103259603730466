const IconPersonnel = () => {
    return (
        <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path opacity="0.4" d="M22.5078 12.0132C22.5078 15.0266 21.175 17.7286 19.0666 19.562C17.3109 21.0888 15.0173 22.0132 12.5078 22.0132C9.99833 22.0132 7.70475 21.0888 5.94898 19.562C3.84066 17.7286 2.50781 15.0266 2.50781 12.0132C2.50781 6.49034 6.98496 2.01318 12.5078 2.01318C18.0307 2.01318 22.5078 6.49034 22.5078 12.0132Z" fill="#595959"/>
            <path d="M19.0669 19.562C18.0735 16.905 15.5117 15.0132 12.5081 15.0132C9.50443 15.0132 6.94265 16.905 5.94922 19.562C7.70499 21.0888 9.99857 22.0132 12.5081 22.0132C15.0175 22.0132 17.3111 21.0888 19.0669 19.562Z" fill="#595959"/>
            <circle cx="3" cy="3" r="3" transform="matrix(1 0 0 -1 9.50781 13.0132)" fill="#595959"/>
        </svg>        
    );
  };
  
  export default IconPersonnel;
  