import { createAsyncAction } from 'services/actionConfigs';
import { PREFIX_ACTIONS } from './constants';

const getListPaginateCategory = createAsyncAction<any>(PREFIX_ACTIONS + 'GET_LIST_PAGINATE_CATEGORY');


const productActions = { 
    getListPaginateCategory,
};

export default productActions;