import FormBlock from 'features/services/components/ServicesForm/FormBlock';
import React from 'react';
import styled from 'styled-components';

type Props = {
  label: string;
  defaultShow?: boolean;
  children?: React.ReactElement;
};

/**
 * Handle form toggle block
 * @param props 
 * @returns 
 */
const FormToggleBlock = (props: Props) => {
  const { label, children } = props;
  // const [isShow, setIsShow] = useState<boolean>(defaultShow);

  // const handleClick = () => {
  //   setIsShow(!isShow);
  // };

  return (
    <>
      <FormToggleBlockStyled>
        <div
          // onClick={handleClick}
          className={`form-label is-big block-title ${true && 'is-active'}`}
        >
          {label}
        </div>
      </FormToggleBlockStyled>
      {true && children}
    </>
  );
};

export default FormToggleBlock;

const FormToggleBlockStyled = styled(FormBlock)`
  .block-title {
    width: 100%;
    margin-bottom: 40px;
    cursor: pointer;
    position: relative;
    line-height: 1.4;
    &.is-active::after {
      transform: rotate(-90deg);
    }
    &::after {
      content: '';
      width: 10px;
      height: 16px;
      transform: rotate(90deg);
      background: var(--icon-gt) center/contain no-repeat;
      right: 25px;
      position: absolute;
      right: 12px;
      top: 5px;
    }
  }
`;
