import { Checkbox, ConfigProvider, Form, Input, Modal, Spin } from 'antd';
import { AxiosResponse } from 'axios';
import DatePicker from 'components/common/DatePickerInput';
import FormRow from 'components/common/Form/FormRow';
import message from 'components/common/Message';
import PhoneNumberInput from 'components/common/PhoneNumberInput';
import dayjs from 'utils/dayjs';
import customerActions from 'features/customers/services/actions';
import apisCustomer from 'features/customers/services/apis';
import { IApiUpdateCustomerBody } from 'features/customers/services/types/cutomer';
import { get, set } from 'lodash';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import storage from 'utils/sessionStorage';
// import NumberValidations from 'utils/validations/number';
import { useTranslation } from 'react-i18next';
const { TextArea } = Input;
import 'dayjs/locale/vi';
import viVN from 'antd/lib/locale/vi_VN';
import enEL from 'antd/lib/locale/en_US';
import IconClose from 'assets/svg/IconClose';
import styled from 'styled-components';
type Props = {
  isOpenModal: boolean;
  handleCancel: () => void;
  customer: any;
  setCurrCustomer: any;
  viewOnly?: boolean;
};


const DetailCustomerModal = (props: Props) => {
  const { isOpenModal, handleCancel, customer,
    setCurrCustomer,
    // viewOnly,
  } = props;
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [errors, setErrors] = useState<any>({});
  const [isLoading, setLoading] = useState(false);

  const getPayload = (data: any) => {
    const merchant_id = storage.merchantId.get() ?? '';
    const name = data?.name;
    const phone_number = data?.phone_number ?? '';
    const email = data?.email ?? '';
    const gender = data?.gender ?? null;
    const date_of_birth = data?.birthDate?.format('YYYY-MM-DD') ?? undefined;
    const payload: IApiUpdateCustomerBody = {
      merchant_id,
      name,
      phone_number,
      email,
      gender,
      date_of_birth,
      is_walkin_in: customer.is_walkin_in,
      important_client_info: data.importantClientInfo ?? '',
    };
    return payload;
  };

  const handleFinish = async (values: any) => {
    setLoading(true);
    const payload = getPayload(values);
    try {
      const rest: AxiosResponse<{ message: string }> =
        await apisCustomer.updateCustomerInfo(customer.id, payload);

      if (rest.data) {
        message.success(rest.data.message);
        dispatch(customerActions.getListCustomers.fetch());
        setCurrCustomer({ ...customer, ...values, important_client_info: payload.important_client_info, date_of_birth: payload.date_of_birth });

        handleCancel();
      }
    } catch (error: any) {
      const textError = get(error, 'response.data.error.message', '');
      if (textError) {
        message.error(textError);
      }

      const response = get<Record<string, string[]>>(
        error,
        'response.data.errors',
        {}
      );

      const errors = Object.entries(response);
      const errorsObj = {};
      errors.forEach(([key, value]) => {
        set(errorsObj, [key], value?.[0]);
      });
      setErrors(errorsObj);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isOpenModal) {
      form.setFieldsValue({
        name: customer?.name,
        email: customer?.email,
        birthDate:
          (customer?.date_of_birth && dayjs(customer?.date_of_birth)) ||
          (customer?.birthday && dayjs(customer?.birthday)) ||
          null,
        phone_number: customer?.phone_number,
        importantClientInfo: customer?.important_client_info || null,
      });
    }
  }, [isOpenModal]);


  const errorsText = useMemo(() => {
    return ({
      name: get(errors, 'name', ''),
      phone_number: get(errors, 'phone_number', ''),
      email: get(errors, 'email', ''),
      date_of_birth: get(errors, 'date_of_birth', ''),
      important_client_info: get(errors, 'important_client_info', ''),
    });
  }, [errors]);

  const myFocus = useRef<any>();

  useEffect(() => {
    myFocus?.current?.input?.focus();
  }, []);

  const lang = 'vi';
  dayjs.locale(lang === 'vi' ? 'vi' : 'en');
  const locale = lang === 'vi' ? viVN : enEL;

  const { t: bookingLang } = useTranslation('booking');
  const { t: formLang } = useTranslation('form');
  const { t: voucherLang } = useTranslation('vouchers');
  const { t: customerLang } = useTranslation('customer');
  return (
    <Modal
      maskClosable={false}
      open={isOpenModal}
      okButtonProps={{ style: { display: 'none' } }}
      onCancel={handleCancel}
      width={1200}
      footer={null}
      forceRender
      centered
    >

      <Spin spinning={isLoading}>
        <Form
          data-form-testing='form-quick-booking-customer'
          name='basic'
          className='modal-form'
          autoComplete='off'
          onFinish={handleFinish}
          form={form}
          // disabled={viewOnly}
          initialValues={{
            name: customer?.name,
            phone_number: customer?.phone_number,
            email: customer?.email,
            birthDate: customer?.date_of_birth ? dayjs(customer?.date_of_birth) : null,
            importantClientInfo: customer?.important_client_info || null,
            gender: customer?.gender || 0
          }}
          layout='vertical'
        >
          <AddCustomerStyled>
            <div className='icon-close' onClick={handleCancel}>
              <IconClose />
            </div>
            <h2 className='modal-title'>{bookingLang('CustomerDetails')}</h2>
            <button type='submit' className='common-btn' >
              {voucherLang('Xác nhận')}
            </button>
          </AddCustomerStyled>
          <CustomersFormFirstStepStyled>
            <div className='left'>
              <div className='profile-customer'>
                <div className='title'>
                  <h4>Thông tin khách hàng</h4>
                </div>
                <Form.Item name='name' label={bookingLang('Name')}
                  rules={[
                    { required: true, message: bookingLang('NameIsRequired') || '' },
                    { min: 2, message: bookingLang('NameMustBeAtLeast2Characters') || '' },
                    { max: 30, message: bookingLang('NameMustBeAtMost30Characters') || '' }
                  ]}
                  validateStatus={errorsText.name ? 'error' : undefined}
                  help={errorsText.name ? errorsText.name : undefined}
                >
                  <Input ref={myFocus} />
                </Form.Item>
                <Form.Item name='phone_number' label={bookingLang('PhoneNumber')}
                  rules={[
                    { required: true },
                    // NumberValidations.PHONE
                    { pattern: /^0+[0-9]{9}$/, message: formLang('ThePhoneNumberIs04AndHas10Digits') || '' }
                  ]}
                  validateStatus={errorsText.phone_number ? 'error' : undefined}
                  help={errorsText.phone_number ? errorsText.phone_number : undefined}
                >
                  <PhoneNumberInput placeholder={'0_________'} />
                </Form.Item>
                <FormRow optional name='email' label='Email' rules={[{ type: 'email', message: 'is not a valid email!' },]}
                  validateStatus={errorsText.email ? 'error' : undefined}
                  help={errorsText.email ? errorsText.email : undefined}
                >
                  <Input />

                </FormRow>
                {/* <FormRow label='Gender' name='gender' optional>
                  <Radio.Group>
                    <Radio value={0}>Female</Radio>
                    <Radio value={1}>Male</Radio>
                    <Radio value={2}>Non-binary</Radio>
                  </Radio.Group>
                </FormRow> */}
                <ConfigProvider locale={locale}>
                  <Form.Item
                    name='birthDate'
                    label={
                      <p>
                        {bookingLang('BirthDate')}<span>({bookingLang('Optional')})</span>
                      </p>
                    }
                    validateStatus={errorsText.date_of_birth ? 'error' : undefined}
                    help={errorsText.date_of_birth ? errorsText.date_of_birth : undefined}
                  >
                    <DatePicker clearIcon={false} />
                  </Form.Item>
                </ConfigProvider>
              </div>
              <div className='note-customer'>
                <div className='title'>
                  <h4>Ghi chú khách hàng</h4>
                  {/* <p>Thông tin này sẽ chỉ hiển thị cho bạn hoặc người có quyền truy cập vào hệ thống.</p> */}
                </div>
                <Form.Item
                  className='important-note'
                  label={
                    <p>
                      {bookingLang('AppointmentNotes')}
                      <span>({bookingLang('Optional')})</span>
                    </p>
                  }
                  name='importantClientInfo'
                  extra={bookingLang('ThisNoteWillBeDisplayedOnAllBookings')}
                  validateStatus={errorsText.important_client_info ? 'error' : undefined}
                  help={errorsText.important_client_info ? errorsText.important_client_info : undefined}
                >
                  <TextArea
                    rows={5}
                    placeholder={bookingLang('IncludeCommentsForYourBookings') || ''}
                  />
                </Form.Item>
              </div>

              {/* <div className='form-row form-submit-wrapper'>
                <button className='common-btn is-white' onClick={handleCancel}>
                  {bookingLang('Cancel')}
                </button> */}
              {/* {!viewOnly && <button className='common-btn' type='submit'>
                  Save
                </button>
                } */}
              {/* <button className='common-btn' type='submit'>
                  {bookingLang('Save')}
                </button> */}
            </div>
            <div className='right'>
              <div className='right-content'>
                <div className='title-right'>
                  <h4>Cài đặt thông báo</h4>
                  <p>Cài đặt cập nhật thông báo cho khách hàng về các lịch hẹn, sản phẩm và chương trình khuyến mãi.</p>
                </div>
                <ul>
                  <li className='notification-booking-service'>
                    <div className={'setting-notification'}>
                      <p>{customerLang('Thông tin lịch hẹn và sản phẩm')}</p>
                      <Form.Item valuePropName={'checked'} name='1'>
                        <Checkbox className='check-show-booking'>{'Gửi qua tin nhắn SMS'}</Checkbox>
                      </Form.Item>
                      <Form.Item valuePropName={'checked'} name='2'>
                        <Checkbox style={{ marginBottom: '24px' }} className='check-show-booking'>{'Gửi qua tin nhắn Zalo'}</Checkbox>
                      </Form.Item>
                    </div>
                  </li>
                  <li className='maketings'>
                    <div className={'setting-notification'}>
                      <p>{customerLang('Thông tin chương trình marketings')}</p>
                      <Form.Item valuePropName={'checked'} name='3'>
                        <Checkbox className='check-show-booking'>{'Gửi qua tin nhắn SMS'}</Checkbox>
                      </Form.Item>
                      <Form.Item valuePropName={'checked'} name='4'>
                        <Checkbox className='check-show-booking'>{'Gửi qua tin nhắn Zalo'}</Checkbox>
                      </Form.Item>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </CustomersFormFirstStepStyled>
        </Form>
      </Spin>
    </Modal>
  );
};

export default DetailCustomerModal;

const AddCustomerStyled = styled.div`
display: flex;
justify-content: space-between;
align-items: center;
border-bottom: 1px solid #1B9AAA;
position: sticky;
background: white;
z-index: 99999;
top: -50px;
padding-top: 10px;
.icon-close {
  cursor: pointer;
  width: 20%;
}
.modal-title {
  width: 60%;
  margin-bottom: 0px;
  text-align: center;
}
button {
  width: 20%;
  min-width: 0px;
}
`;

const CustomersFormFirstStepStyled = styled.div`
display: flex;
gap: 24px;
margin-top: 24px;
padding: 0 50px;
@media only screen and (max-width: 767.98px) {
  padding: 0 8px;
  display: grid;
}
.left {
  flex: 1;
  display: grid;
  gap: 24px;
  .profile-customer {
    border-radius: 12px;
    border: 1px solid var(--Gray-30, #D9D9D9);
    padding: 24px;
    display: grid;
    gap: 24px;
    .title {
      color: var(--Gray-95, #141414);
      font-family: Inter;
      font-size: 20px;
      font-weight: 700;
      line-height: 140%;
    }
    .gender-birthDate {
      display: flex;
      gap: 12px;
      @media only screen and (max-width: 767.98px) {
        display: grid;
      }
    }
  }
  .note-customer {
    border-radius: 12px;
    border: 1px solid var(--Gray-30, #D9D9D9);
    padding: 24px;
    display: grid;
    gap: 24px;
    .title {
      display: grid;
      gap: 8px;
      h4 {
        color: var(--Gray-95, #141414);
        font-family: Inter;
        font-size: 20px;
        font-weight: 700;
        line-height: 140%;
      }
      p {
        color: var(--Gray-60, #595959);
        font-family: Inter;
        font-size: 12px;
        font-weight: 400;
        line-height: 140%;
      }
    }
    .note-container {
      display: grid;
      gap: 16px;
    }
  }
}

.ant-form-item-control {
  margin-bottom: 0 !important;
}

.right {
  flex: 1;
  .right-content {
    border-radius: 12px;
    border: 1px solid var(--Gray-30, #D9D9D9);
    padding: 24px;
    display: grid;
    gap: 24px;

    .title-right {
    display: grid;
    gap: 8px;
    h4 {
      color: var(--Gray-95, #141414);
      font-family: Inter;
      font-size: 20px;
      font-weight: 700;
      line-height: 140%;
    }
    p {
      color: var(--Gray-60, #595959);
      font-family: Inter;
      font-size: 12px;
      font-weight: 400;
      line-height: 140%;
    }
  }
  ul {
    .notification-booking-service {
      margin-bottom: 24px;
      border-bottom: 1px solid #F0F0F0;
      .setting-notification {
      p {
        color: var(--Gray-95, #141414);
        font-family: Inter;
        font-size: 16px;
        font-weight: 600;
        line-height: 140%;
      }
      span {
        color: var(--Gray-95, #141414);
        font-family: Inter;
        font-size: 16px;
        font-weight: 500;
        line-height: 140%;
      }
    }
    
    }
    .maketings {
      .setting-notification {
        p {
          color: var(--Gray-95, #141414);
          font-family: Inter;
          font-size: 16px;
          font-weight: 600;
          line-height: 140%;
        }
        span {
          color: var(--Gray-95, #141414);
          font-family: Inter;
          font-size: 16px;
          font-weight: 500;
          line-height: 140%;
        }
      }
    }
  }
  }
  }
`;
