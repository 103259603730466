import { Switch } from 'antd';
import message from 'components/common/Message';
import { ModalConfirmRef } from 'components/common/Modal/ModalConfirm';
import loyalActions from 'features/loyaltyProgram/services/actions';
import apisLoyal from 'features/loyaltyProgram/services/apis';
import { IProgramItemResData } from 'features/loyaltyProgram/services/types/program';
import { get } from 'lodash';
import React, { RefObject } from 'react';
import { useTranslation } from 'react-i18next';
import { useSetLoadingPage } from 'services/UI/LoadingPage';
import { useAppDispatch } from 'store/hooks';
type Props = {
  data: IProgramItemResData;
  confirmRef?: RefObject<ModalConfirmRef>;
};
const ProgramSwitchStatus = ({ data, confirmRef }: Props) => {
  const dispatch = useAppDispatch();
  const setPageLoading = useSetLoadingPage();

  const {t: loyaltyProgramLang} = useTranslation('loyaltyProgram');

  const handleUpdateStatus = async () => {
    if (!data) return;
    setPageLoading(true);
    try {
      const res = await apisLoyal.updateStatusProgram(data?.id?.toString(), !data.status);
      dispatch(loyalActions.getListLoyalProgram.fetch({}));
      const msg = get(res, 'data.message', '');
      if (msg) {
        message.success(msg);
      }
    } catch (error) {
      const errors = Object.entries(get(error, 'response.data.errors', {}));
      if (errors.length > 0) {
        errors.map(([, valueArr]) => {
          message.error(get(valueArr, [0], ''));
        });
        return;
      }
      message.error('An error occurred. Please try again');
    } finally {
      setPageLoading(false);
    }
  };
  const onClick = () => {
    if (!confirmRef) return;
    confirmRef.current?.show({
      title: loyaltyProgramLang('AreYouSure'),
      msg: `${loyaltyProgramLang('AreYouSure')} ${loyaltyProgramLang(!data.status ? 'enable' : 'disable')} ${loyaltyProgramLang('thisProgram')}?`,
      submit: handleUpdateStatus,
    });
  };
  return (
    <>
      <Switch onClick={onClick} checked={!!data.status} />
    </>
  );
};

export default ProgramSwitchStatus;
