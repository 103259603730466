import { Button, Tabs } from 'antd';
import reviewActions from 'features/reviews/services/actions';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
// import AppointmentsTab from '../components/AppointmentsTab';
// import GeneralTab from '../components/GeneralTab';
// import ReviewsTab from '../components/ReviewsTab';
// import customerActions from '../services/actions';
// import customerSelectors from '../services/selectors';
import InformationTab from './InformationTab';
import ProductTab from './ProductTab';
import styles from '../../pages/index.module.scss';
import customerActions from 'features/customers/services/actions';
import customerSelectors from 'features/customers/services/selectors';
import { IconRight } from 'assets/svg';
import styled from 'styled-components';

const SupplierEditPage = () => {
    const onChange = () => undefined;
    const { customer_code = '', } = useParams();
    const [params] = useSearchParams();
    const navigate = useNavigate();

    const dispatch = useDispatch();
    const { t: productlang } = useTranslation('products');

    const fetchDetailData = () => {
        const payload = {
            is_walkin_in: !!Number(params.get('is_walkin_in') ?? ''),
            customer_code: customer_code || '',
        };
        dispatch(customerActions.setCustomerCodeDetail(payload));
    };

    useEffect(() => {
        fetchDetailData();
    }, []);
    const customer = customerSelectors.getCustomerDetail();

    const onClickBack = () => {
        navigate(-1);
    };

    useEffect(() => {
        dispatch(reviewActions.getListReview.fetch({
            per_page: 9999,
            keyword: customer?.customer_code.replace('#', '') ?? '',
        }));
    }, [customer]);

    const items = [
        {
            key: '1',
            label: productlang('Information'),
            children: <InformationTab />,
        },
        {
            key: '2',
            label: productlang('Product'),
            children: <ProductTab />,
        },
    ];
    return (
        <SupplierEditStyled>
            <div className={styles.supplier_detail_page}>
                <Tabs defaultActiveKey="1" items={items} onChange={onChange} />
                <div className={styles.btn_edit_supplier}>
                    <button className={styles.icon_heading} onClick={onClickBack}><IconRight /></button>
                    <Button className={styles.confirm_heading}>{productlang('Edit')}</Button>
                </div>
            </div>
        </SupplierEditStyled>
    );
};

export default SupplierEditPage;
const SupplierEditStyled = styled.div`
.ant-tabs {
    width: 100%
}
`;