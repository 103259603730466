import checkoutSelectors from 'features/checkout/services/selectors';
import checkoutActions from 'features/checkout/services/actions';

import BlockPayment from 'features/sales/components/BlockPayment';
import checkoutHook from 'features/checkout/hook/checkoutHook';

/**
 * Block payment checkout
 * @returns 
 */
const BlockPaymentCheckout = () => {


  //@ts-ignore
  return <BlockPayment action={checkoutActions} selector={checkoutSelectors} hook={checkoutHook}/>;
};

export default BlockPaymentCheckout;