import { RuleObject } from 'antd/es/form';
import { formatMoney } from 'utils/unit';

const ONLY_NUMBER = { pattern: /^[0-9]*$/, message: 'is not a valid number!' };
const PHONE = { pattern: /^0+[0-9]{9}$/, message: 'ThePhoneNumberIs04AndHas10Digits' };
const CURRENCY = {
  validator(rule: RuleObject, value: any, callback: (error?: string | undefined) => void) {
    if(! value) {
      callback(undefined);
      return;
    }
    const valueSplit = value.toString().split('.');
    if(! (valueSplit.length === 1 || valueSplit.length === 2 && valueSplit[1].length <= 2)) callback('The amount contains a decimal number with no more than 2 digits');
    else callback(undefined);
  },
};

/**
 * Handle max
 * @param valueMax 
 * @returns 
 */
const max = (valueMax: number) => {
  return {
    validator(rule: RuleObject, value: any, callback: (error?: string | undefined) => void) {
      if(! value) {
        callback(undefined);
        return;
      }
      if( value > valueMax) callback('The value must not exceed ' + formatMoney(valueMax));
      else callback(undefined);
    },
  };
};

const NumberValidations = {
  ONLY_NUMBER,
  PHONE,
  CURRENCY,
  max
};
export default NumberValidations;
