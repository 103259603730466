import { Avatar, List, Modal } from 'antd';
import bookingSelectors from 'features/bookings/services/selectors';
import { IActivity } from 'features/bookings/services/types/reducer';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { getFormatShortDate } from 'utils/moment/getSingleDateShortFormat';

const Activity = () => {

  const [isOenActivity, setOpenActivity] = useState<boolean>(false);

  const onOpen = () => {
    setOpenActivity(true);
  };

  const onClose = () => {
    setOpenActivity(false);
  };

  const data: IActivity[] = bookingSelectors.getListActivity();

  const { t: dateLang } = useTranslation('date');
  const { t: bookingLang } = useTranslation('booking');

  return (
    <>
      <ActivityStyled className='form-row'>
        <div className='label'>
          <ActivityIcon />
          {bookingLang('Activity')}
        </div>
        <div>
          <button onClick={onOpen} className='button' type='button' >{bookingLang('ShowMore')}</button>
        </div>
      </ActivityStyled>
      <Modal
        open={isOenActivity}
        onCancel={onClose}
        okButtonProps={{ style: { display: 'none' } }}
        footer={null}
        style={{ height: '100%' }}
        title={bookingLang('Activity')}>

        <List
          style={{ overflow: 'auto' }}
          itemLayout="horizontal"
          dataSource={data}
          renderItem={(item) => (
            <List.Item>
              <List.Item.Meta
                avatar={<Avatar style={{ background: 'linear-gradient(315deg, #6587ff 0%, #c883ff 100%', textTransform: 'uppercase' }} className='avatar'>{item?.creator ? item?.creator[0] : ''}</Avatar>}
                title={
                  <div>
                    <TitleStyled>
                      <p>{item.title}</p>
                      <p className='created-at'>{getFormatShortDate(item.created_at, dateLang)}</p>
                    </TitleStyled>
                  </div>
                }
                description={<div dangerouslySetInnerHTML={{ __html: item.content }}></div>}
              />
            </List.Item>
          )}
        />
        <div className='form-row form-submit-wrapper'>
          <button type='button' className='common-btn is-white' onClick={onClose}>
            {bookingLang('Close')}
          </button>
        </div>
      </Modal>
    </>
  );
};

export default Activity;

const ActivityStyled = styled.div`
  justify-content: space-between;
  margin-bottom: 24px !important;
  .label {
    *:first-child {
      margin-right: 2px;
    }
    display: flex;
    align-items: center;
    font-size: 18px;
    font-weight: 600;
    line-height: 140%;
  }

  .button {
    border-radius: 8px;
    padding: 8px 16px;
    background-color: #F3F3F3;
  }
`;

const TitleStyled = styled.div`
display: flex;
justify-content: space-between;
.created-at {
  color: #949BDA;
  font-size: 12px;
  line-height: 16.8px;
  font-weight: 500;
}
`;

const ActivityIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="#363565" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M12 6V12L16 14" stroke="#363565" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
  </svg>

);