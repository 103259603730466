import { ConfigProvider, DatePicker } from 'antd';
import dayjs from 'utils/dayjs';
import React from 'react';
import styled from 'styled-components';
import 'dayjs/locale/vi';
import viVN from 'antd/lib/locale/vi_VN';
import enEL from 'antd/lib/locale/en_US';
type IDateRangePickerProps = {
  start_date?: number,
  end_date?: number,
  date?: number,
  onChange: (values: any) => void
};
/**
 * Handle date range picker
 * @param start_date, end_date, date, onChange 
 * @returns 
 */
const DateRangePicker: React.FC<IDateRangePickerProps> = ({ start_date, end_date, date, onChange }) => {
  
  const lang = 'vi';
  dayjs.locale(lang === 'vi' ? 'vi' : 'en');
  const locale = lang === 'vi' ? viVN : enEL;
  
  return (
    <DateRangePickerStyled>
      <div className="common-select">
        <ConfigProvider locale={locale}>
          <DatePicker.RangePicker
            onChange={onChange}
            allowClear={false}
            format="DD-MM-YYYY"
            suffixIcon={false}
            value={[dayjs((start_date ?? date ?? 0) * 1000), dayjs((end_date ?? date ?? 0) * 1000)]}
            inputReadOnly
          />
        </ConfigProvider>
      </div>
    </DateRangePickerStyled>
  );
};

export default DateRangePicker;
const DateRangePickerStyled = styled.div``;
