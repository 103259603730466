const IconLocation = () => {
  return (
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path opacity="0.4" d="M20.5078 18.0132V7.01318H4.50781V18.0132C4.50781 20.2223 6.29867 22.0132 8.50781 22.0132H16.5078C18.717 22.0132 20.5078 20.2223 20.5078 18.0132Z" fill="#595959"/>
      <path d="M17.2766 2.01318H7.73907C5.92172 2.01318 4.28745 3.25794 3.61251 5.15623L2.85452 7.28806C2.62508 7.93338 2.46647 8.62392 2.63383 9.28806C3.03017 10.8609 4.31605 12.0132 5.84115 12.0132C7.6821 12.0132 9.17448 10.3343 9.17448 8.26318C9.17448 10.3343 10.6669 12.0132 12.5078 12.0132C14.3488 12.0132 15.8411 10.3343 15.8411 8.26318C15.8411 10.3343 17.3335 12.0132 19.1745 12.0132C20.6996 12.0132 21.9855 10.8609 22.3818 9.28806C22.5492 8.62392 22.3905 7.93338 22.1611 7.28806L21.4031 5.15623C20.7282 3.25794 19.0939 2.01318 17.2766 2.01318Z" fill="#595959"/>
      <path d="M9.50781 19.0132C9.50781 17.3563 10.851 16.0132 12.5078 16.0132V16.0132C14.1647 16.0132 15.5078 17.3563 15.5078 19.0132V22.0132H9.50781V19.0132Z" fill="#595959"/>
    </svg>
  );
};

export default IconLocation;
