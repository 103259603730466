import { Empty, Table } from 'antd';
import analyticSelectors from '../services/selectors';
import { useTranslation } from 'react-i18next';

/**
 * Working table
 * @returns 
 */
const WorkingTable = () => {

  const workingHour = analyticSelectors.getWorkingHours();
  const { t: analyticLang } = useTranslation('analytic');
  const { t: userLang } = useTranslation('user');
  const { t: invoiceLang } = useTranslation('invoice');
  const dataSource = workingHour;
  const { t: dateLang } = useTranslation('date');

  /**
   * Columns table
   */
  const columns = [
    {
      title: 'STT',
      width: '5%',
      render: (text: string, record: any, index: number) => {
        return (
          <p>
            {index + 1}
          </p>
        );
      },
    },
    {
      title: analyticLang('Staff'),
      dataIndex: 'full_name',
      key: 'full_name',
      width: '35%',
    },
    {
      title: analyticLang('Position'),
      dataIndex: 'access_level',
      key: 'access_level',
      width: '35%',
      render: (text: string) => {
        return (
          <p>
            {userLang(text)}
          </p>
        );
      },
    },
    {
      title: (
        <p>
          {analyticLang('TotalHours')} <span className='optional-text'>({analyticLang('hrs')})</span>
        </p>
      ),
      dataIndex: 'total_hours',
      key: 'total_hours',
      render: (text: string) => {
        const units = text.split(' ');
        const unit1 = units[1];
        const unit2 = units[3];

        const translatedUnit1 = dateLang(unit1) || unit1;
        const translatedUnit2 = dateLang(unit2) || unit2;
        
        const translatedText = `${units[0]} ${translatedUnit1} ${translatedUnit2 === undefined ? '' : units[2]} ${translatedUnit2 === undefined ? '' : translatedUnit2}`;

        return (
          <p>
            {translatedText}
          </p>
        );
      },
    },
  ];

  return (
    <>
      <Table
        className={'custom-table'}
        dataSource={dataSource}
        columns={columns}
        scroll={{
          x: 700,
        }}
        pagination={false}
        locale={{
          emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={invoiceLang('NoData')} />,
        }}
      />
    </>
  );
};

export default WorkingTable;
