import React from 'react';

function ImageDeleteConfirm() {
  return (
    // <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
    //   <g clipPath="url(#clip0_4412_179869)">
    //     <path d="M6.71973 0V80H34.9655L44.0208 37.2592L37.2089 0H6.71973Z" fill="#EBF3FE" />
    //     <path d="M67.7068 40.9805V16.0144L57.2076 10.5614L51.684 0H37.209V70.8309L67.7068 40.9805Z" fill="#D0E4FC" />
    //     <path d="M67.7068 16.0144H51.684V0L67.7068 16.0144Z" fill="#EBF3FE" />
    //     <path d="M34.8826 46.8389V80.0003H51.2948L52.4883 64.2601L51.2948 46.8389H34.8826Z" fill="#4F5F73" />
    //     <path d="M51.2948 46.8389H67.7068V80.0003H51.2948V46.8389Z" fill="#3D4959" />
    //     <path d="M43.1945 28.9424V36.4177C43.1945 36.9943 42.7254 37.4635 42.1487 37.4635L40.5359 40.2661V42.151H42.1487C45.3101 42.151 47.882 39.5789 47.882 36.4177V33.6299H51.2948L54.6787 31.3533L51.2948 28.9424H43.1945Z" fill="#4F5F73" />
    //     <path d="M60.4535 37.4635C59.8768 37.4635 59.4076 36.9943 59.4076 36.4177V28.9424H51.2948V33.6299H54.7201V36.4177C54.7201 39.5789 57.2921 42.151 60.4535 42.151H62.0664V40.7543L60.4535 37.4635Z" fill="#3D4959" />
    //     <path d="M29.3217 37.4639V48.4423H51.2948L55.6009 42.8817L51.2948 37.4639H29.3217Z" fill="#3D4959" />
    //     <path d="M51.2948 37.4639H73.2806V48.4423H51.2948V37.4639Z" fill="#222842" />
    //     <path d="M44.0326 54.3457H48.7201V73.8474H44.0326V54.3457Z" fill="#3D4959" />
    //     <path d="M53.882 54.3457H58.5695V73.8474H53.882V54.3457Z" fill="#222842" />
    //   </g>
    //   <defs>
    //     <clipPath id="clip0_4412_179869">
    //       <rect width="80" height="80" fill="white" />
    //     </clipPath>
    //   </defs>
    // </svg>

    <svg width="140" height="140" viewBox="0 0 140 140" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M51.2114 59.6581V58.6212L33.2376 47.4092C31.2734 46.184 28.6878 46.7829 27.4625 48.7472L20.6562 59.6581H51.2114Z" fill="#78E75A" />
      <path d="M77.1777 63.063H29.5215L50.0929 42.4915C51.8915 40.6929 54.8077 40.6929 56.6063 42.4915L77.1777 63.063Z" fill="#57B3FE" />
      <path d="M99.7332 64.3445H50.584L73.5605 41.368C75.5694 39.3591 78.8265 39.3591 80.8354 41.368L99.7332 64.3445Z" fill="#F45170" />
      <path d="M92.0996 135.881H26.5754C23.1886 135.881 20.3879 133.243 20.1854 129.862L16.0073 60.1062C15.8326 57.1886 18.1516 54.7266 21.0744 54.7266H97.6006C100.523 54.7266 102.842 57.1886 102.668 60.1062L98.4896 129.862C98.2871 133.243 95.4864 135.881 92.0996 135.881Z" fill="#C9CEE2" />
      <path d="M39.5789 125.386C37.882 125.386 36.4414 124.083 36.2997 122.361L31.8432 68.0995C31.6945 66.287 33.0436 64.6956 34.8571 64.5469C36.6535 64.4091 38.261 65.7462 38.4098 67.5607L42.8662 121.822C43.015 123.634 41.6659 125.226 39.8523 125.375C39.7609 125.382 39.6694 125.386 39.5789 125.386Z" fill="#9EA9C9" />
      <path d="M59.3373 125.385C57.5177 125.385 56.043 123.91 56.043 122.091V67.8295C56.043 66.0099 57.5177 64.5352 59.3373 64.5352C61.1569 64.5352 62.6316 66.0099 62.6316 67.8295V122.091C62.6316 123.91 61.1569 125.385 59.3373 125.385Z" fill="#9EA9C9" />
      <path d="M79.0973 125.385C79.0068 125.385 78.9154 125.381 78.8239 125.374C77.0104 125.225 75.6613 123.634 75.81 121.822L80.2665 67.5603C80.4153 65.7458 81.9986 64.4118 83.8191 64.5465C85.6327 64.6953 86.9818 66.2866 86.833 68.0991L82.3766 122.36C82.2348 124.082 80.7942 125.385 79.0973 125.385Z" fill="#9EA9C9" />
      <path d="M30.2527 33.7939L42.9144 39.7318C43.297 39.9112 43.7497 39.7101 43.8732 39.306L48.0768 25.5431C48.2583 24.9489 47.6278 24.4337 47.0818 24.73L44.1375 26.327C43.8057 26.507 43.3963 26.3943 43.2078 26.0673C41.2592 22.687 30.5576 6.33814 12.3464 19.2947C11.7016 19.7535 12.1653 20.7568 12.9261 20.5391C18.5255 18.9363 28.4339 18.2003 34.3068 30.0138C34.485 30.3722 34.3196 30.8193 33.9509 30.9749L30.2775 32.5256C29.7237 32.7594 29.7085 33.5386 30.2527 33.7939Z" fill="#FFB961" />
      <path d="M120.366 62.281L50.5195 12.8588C48.3498 11.3236 47.8355 8.32016 49.3707 6.15045C50.9059 3.9807 53.9094 3.46633 56.0791 5.0016L125.925 54.4238C128.095 55.959 128.609 58.9624 127.074 61.1321C125.539 63.3019 122.535 63.8163 120.366 62.281Z" fill="#4C5C75" />
      <path d="M103.436 38.5102L78.5684 20.9144L80.2032 18.6039C81.9614 16.1191 85.4011 15.53 87.8859 17.2882L103.755 28.5169C106.24 30.2751 106.829 33.7147 105.071 36.1996L103.436 38.5102Z" fill="#C9CEE2" />
      <path d="M93.4111 68.3616C93.3018 68.3616 93.2122 68.2758 93.2067 68.1666C93.1089 66.2136 91.5426 64.6472 89.5895 64.5494C89.4803 64.544 89.3945 64.4544 89.3945 64.3451C89.3945 64.2358 89.4803 64.1463 89.5894 64.1407C91.5425 64.0421 93.1085 62.4761 93.2071 60.523C93.2127 60.4139 93.3022 60.3281 93.4115 60.3281C93.5208 60.3281 93.6104 60.4139 93.6158 60.5231C93.7136 62.4761 95.28 64.0425 97.2331 64.1403C97.3422 64.1458 97.428 64.2354 97.428 64.3446C97.428 64.4539 97.3423 64.5435 97.2332 64.549C95.28 64.6476 93.7141 66.2136 93.6154 68.1667C93.6099 68.2758 93.5203 68.3616 93.4111 68.3616Z" fill="white" />
    </svg>

  );
}

export default ImageDeleteConfirm;
