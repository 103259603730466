import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import LocationsTable from '../components/LocationsTable';
import locationActions from '../services/actions';
import { useAppDispatch } from 'store/hooks';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Can from 'features/packagePermission/components/Can';
import { LocationPermission, ModulePermission } from 'features/packagePermission/services/types/permission';
import packagePermissionSelector from 'features/packagePermission/services/selectors';
import locationSelectors from '../services/selectors';

/**
 * Locations page v2 
 * @returns 
 */
const LocationsPage = () => {

  const dispatch = useAppDispatch();

  const [searchParams] = useSearchParams();
  const locationLimit = packagePermissionSelector.getPermissionLimit('limit_location');
  const locationList = locationSelectors.getPaginateListLocations().data;

  useEffect(() => {
    dispatch(
      locationActions.getListPaginateLocations.fetch({
        page: Number(searchParams.get('page') ?? 1),
        per_page: Number(searchParams.get('per_page') ?? 10),
      })
    );
  }, [searchParams]);
  
  const { t: Locationlang } = useTranslation('location');
  return (
    <>
      <div className={'private-filter'}>
        <Can module={ModulePermission.LOCATIONS} permission={LocationPermission.ADD_LOCATION} limitAllow={{
          limit: locationLimit,
          size: locationList?.length
        }}>
          <a href="locations/add-new" className={'common-btn'}>
            {Locationlang('btn.AddNew')}
          </a>
        </Can>
      </div>
      <LocationsTableStyled>
        <LocationsTable />
        {/* <div className='premium-conatiner'>
          <div className='premium-conatiner-text'>
            <div className='text-container'>
              <p className='text'>Bạn có nhu cầu thêm địa điểm?</p>
              <p className='premium-text'>Thử ngay phiên bản <span>Premium</span> của MindHana!</p>
            </div>
            <div className='btn-container'>
              <button className='common-btn is-white'>Chi tiết</button>
              <Button className={'common-btn'}>Nâng cấp</Button>
            </div>
          </div>
          <IconPremium />
        </div> */}
      </LocationsTableStyled>
    </>
  );
};

export default LocationsPage;

const LocationsTableStyled = styled.div`
// display: grid;
gap: 24px;
@media only screen and (max-width: 767.98px) {
  display: block;
}
.premium-conatiner {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: var(--color-white);
  padding: 24px;
  border-radius: 16px;
  @media only screen and (max-width: 767.98px) {
    display: grid;
    gap: 24px;
    margin-top: 24px;
  }
  .premium-conatiner-text {
    display: grid;
    gap: 24px;
    .text-container {
      display: grid;
      gap: 8px;
      .text {
        color: #141414;
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 140%;
      }
      .premium-text {
        color: #141414;
        font-family: Inter;
        font-size: 24px;
        font-weight: 500;
        line-height: 39.813px;
        span {
          color: #FF6F00;
          font-weight: 700;
        }
      }
    }
  }
  .btn-container {
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 8px;
    .common-btn {
      margin-bottom: 0;
      @media only screen and (max-width: 767.98px) {
        min-width: auto;
      }
    }
  }
}
`;
