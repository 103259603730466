const IconHeadingTimeMobile = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M2 6.00001V12C2 13.4728 3.19391 14.6667 4.66667 14.6667H11.3333C12.8061 14.6667 14 13.4728 14 12V6.00001M2 6.00001V5.00001C2 3.52725 3.19391 2.33334 4.66667 2.33334H11.3333C12.8061 2.33334 14 3.52725 14 5.00001V6.00001M2 6.00001H14M10.6667 1.33334V3.33334M5.33333 1.33334V3.33334"
        stroke="#595959"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default IconHeadingTimeMobile;
