import { Form, Input, InputNumber, Radio, Switch } from 'antd';
import { useForm } from 'antd/es/form/Form';
import MultipleSelect, { IOptionMulti } from 'components/common/MultipleSelect';
import { CHOOSE_CATEGORY_REWARD } from 'features/loyaltyProgram/services/constants';
import loyalSelectors from 'features/loyaltyProgram/services/selectors';
import { IApiRewardReq, IRewardFormValues } from 'features/loyaltyProgram/services/types/reward';
import { get } from 'lodash';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { getSelectedLanguage } from 'utils/unit';
export type RewardFormProps = {
  isEdit?: boolean;
  formData?: IRewardFormValues;
  onSubmit?: (payload: IApiRewardReq) => void;
  onCancel?: () => void;
};
const RewardForm = ({ isEdit = false, formData, onSubmit = () => undefined, onCancel = () => undefined }: RewardFormProps) => {
  const [form] = useForm();
  const [category, setCategory] = useState<string>(CHOOSE_CATEGORY_REWARD.FREE_SERVICE);
  const { t: loyaltyProgramLang } = useTranslation('loyaltyProgram');
  const servicesStore = loyalSelectors.getListServices();
  const [selected, setSelected] = useState<(string | number)[]>([]);
  const [price, setPrice] = useState<number>();
  const [percent, setPercent] = useState<number>();

  const services = useMemo(() => {
    const result: IOptionMulti[] = [];
    servicesStore?.forEach((service) => {
      if (service?.service_variants?.length > 0) {
        service?.service_variants.forEach(serviceVariant => {
          result.push({
            value: service?.id + '-' + serviceVariant.id,
            title: service.name + ' - ' + serviceVariant.name,
          });
        });
      } else {
        result.push({
          value: service.id + '',
          title: service.name,
        });
      }
    });
    return result;
  }, [servicesStore]);

  const allServices = useMemo(() => {
    return services.map(o => o.value);
  }, [servicesStore]);

  useEffect(() => {
    if (isEdit) return;
    if (services.length > 0) {
      setSelected([...allServices]);
    }
  }, [services]);

  useEffect(() => {
    form.setFieldValue('services', selected);
  }, [selected]);

  useEffect(() => {
    if (!isEdit) return;
    form.setFieldsValue(formData);
    if (formData?.category) setCategory(formData.category);
    if (formData?.category == CHOOSE_CATEGORY_REWARD.FREE_SERVICE) {
      if (formData?.services?.length === 0) {
        form.setFieldValue('services', allServices);
      }
    } else {
      setPrice(formData?.price || undefined);
      setPercent(formData?.percent || undefined);
    }
  }, [formData]);

  const getPayload = (values: IRewardFormValues) => {
    const free_services: IApiRewardReq['free_services'] = [];
    values.services?.forEach(s => {
      if (!s) return;
      const arr = s.split('-');
      const serviceParentId = get(arr, [0], '')?.toString();
      const serviceExtraId = get(arr, [1], '')?.toString();

      const parent = free_services.find(o => o.id?.toString() === serviceParentId);
      if (parent) {
        parent.extend_services.push(+serviceExtraId);
      } else {
        free_services.push({
          id: +serviceParentId,
          extend_services: [+serviceExtraId]
        });
      }
    });

    const result: IApiRewardReq = {
      category: values.category,
      name: values.rewardName,
      amount_reward: values.price || null,
      value_reward: values.percent || null,
      free_services,
      points_to_earn_reward: values.pointsToEarnReward || null,
      reset_point: values.resetPoint ? 1 : 0,
    };
    return result;
  };

  const _onSubmit = (values: IRewardFormValues) => {
    const payload = getPayload(values);
    onSubmit(payload);
  };

  const checkValid = () => {
    try {
      form.validateFields();
      return true;
    } catch (error) {
      return false;
    }
  };
  const submit: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    e.preventDefault();
    const isValid = checkValid();
    if (!isValid) return;
    form.submit();
  };

  useEffect(() => {
    form.validateFields(['priceOrPercent']);
  }, [price, percent]);

  const myFocus = useRef<any>();

  useEffect(() => {
    myFocus?.current?.input?.focus();
  }, []);

  const [priceOrPercentValidationStatus, setPriceOrPercentValidationStatus] = useState<'success' | 'error' | undefined>(undefined);

  return (
    <FormStyled>
      <Form
        form={form}
        name='basic'
        className='modal-form'
        onFinish={_onSubmit}
        autoComplete='off'
        layout='vertical'
        initialValues={{
          category: category,
        }}
      >
        <>
          <div className='form-row'>
            <Form.Item label={loyaltyProgramLang('RewardName')} name='rewardName' rules={[{ required: true, message: loyaltyProgramLang('PleaseEnterRewardName') || '' }]} >
              <Input ref={myFocus} placeholder={loyaltyProgramLang('EnterName') || ''} />
            </Form.Item>
          </div>
          <div className='form-row'>
            <Form.Item label={loyaltyProgramLang('ChooseCategory')} name='category' rules={[{ required: true, message: loyaltyProgramLang('PleaseEnterChooseCategory') || '' }]}>
              <Radio.Group onChange={e => setCategory(e.target.value)} value={category}>
                <Radio value={CHOOSE_CATEGORY_REWARD.FREE_SERVICE}>{loyaltyProgramLang('FreeService')}</Radio>
                <Radio value={CHOOSE_CATEGORY_REWARD.REWARD}>{loyaltyProgramLang('reward')}</Radio>
              </Radio.Group>
            </Form.Item>
          </div>

          <>
            <div className='form-row'>
              <Form.Item
                label={loyaltyProgramLang('PointsToEarnReward')}
                name='pointsToEarnReward'
                rules={[
                  { required: true, message: loyaltyProgramLang('PleaseEnterPointsToEarnReward') || '' }
                ]}
              >
                <InputNumber
                  min={1}
                  addonAfter={loyaltyProgramLang('Points')}
                  placeholder={loyaltyProgramLang('NumberOfPoints') || ''}
                  type="number"
                />
              </Form.Item>
            </div>



            {category === CHOOSE_CATEGORY_REWARD.FREE_SERVICE ? (
              <div className='form-row items-end'>
                <Form.Item label={loyaltyProgramLang('ChooseServices')} name='services' rules={[{ required: true, message: loyaltyProgramLang('PleaseEnterChooseServices') || '' }]}>
                  <MultipleSelect
                    onChange={setSelected}
                    value={selected}
                    options={services}
                    maxTagPlaceholder={loyaltyProgramLang('services') || ''}
                    placeholder={loyaltyProgramLang('SelectServices') || ''}
                  />
                </Form.Item>
              </div>
            ) : (
              <>
                <div className='form-row groupPrice items-end'>
                  <Form.Item
                    className={priceOrPercentValidationStatus}
                    label={loyaltyProgramLang('Amount')} name='price'>
                    <InputNumber
                      addonAfter={getSelectedLanguage() === 'vi' ? 'đ' : '$'}
                      onChange={e => setPrice(e as number)}
                      placeholder={loyaltyProgramLang('EnterPricePlaceholder') || ''}
                      type="number"
                    />
                  </Form.Item>
                  <p className='center-text'>{loyaltyProgramLang('or')}</p>
                  <Form.Item
                    className={priceOrPercentValidationStatus}
                    label={loyaltyProgramLang('Value')} name='percent'>
                    <InputNumber
                      addonAfter='%'
                      onChange={e => setPercent(e as number)}
                      placeholder={loyaltyProgramLang('EnterPercentPlaceholder') || ''}
                      max={100}
                      min={0.1}
                      type="number"
                    />
                  </Form.Item>
                </div>
                <Form.Item className='messageError' name={'priceOrPercent'} rules={[
                  {
                    validateTrigger: ['price', 'percent'],
                    validator() {
                      if (!price && !percent || (!!price && !!percent)) {
                        setPriceOrPercentValidationStatus('error');
                        return Promise.reject(new Error(loyaltyProgramLang('PleaseFillInValueRewardOrAmountRewardAField') || ''));
                      }
                      return Promise.resolve();
                    },
                  }
                ]} />
              </>
            )}
          </>
          <div className='form-row'>
            <p className='form-label'>{loyaltyProgramLang('EnableResetPoint')}</p>
            <Form.Item noStyle name='resetPoint' valuePropName='checked'>
              <Switch />
            </Form.Item>
          </div>

        </>

        <div className='form-submit-wrapper'>
          <div onClick={onCancel} className='common-btn is-white'>
            {loyaltyProgramLang('Cancel')}
          </div>
          <button onClick={submit} className='common-btn'>
            {loyaltyProgramLang('Save')}
          </button>
        </div>
      </Form>
    </FormStyled>
  );
};

export default RewardForm;

const FormStyled = styled.div`
.error {
  .ant-input-number-group-wrapper {
    border: 1px solid #ff4d4f !important;
    border-radius: 8px !important;
  }
}
.ant-input-number-group > .ant-input-number:last-child, .ant-input-number-group .ant-input-number-group-addon:last-child {
  min-width: 64px;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: var(--color-primary);
  /* background: var(--color-white-01); */
  border: none;
  position: relative;
  padding:0px 25px;
  border: 1px solid #CCC;
  border-left: 0px;
}
  .ant-form-item{
    &.messageError {
      .ant-form-item-control-input {
        display:none;
      }
    }
  }
  .form-row {
    &.groupPrice {
      margin-bottom:0;
      .center-text {
        margin:0;
        margin-bottom:18px;
        @media only screen and (max-width: 767.98px) {
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
      .ant-col{
        &.ant-form-item-control {
          margin-bottom:0;
        }
      }
      @media only screen and (max-width: 767.98px) {
        display: grid;
      }
    }
  }
`;