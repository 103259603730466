import React from 'react';
import avatar from '../../../assets/images/auth/auth_avatar.jpg';
import styled from 'styled-components';

/**
 * Analyze customer item
 * @param data 
 * @returns 
 */
const AnalyzeCustomerItem = (data: any) => {

    return (
        <AnalyzeCustomerItemStyled>
            <div className='image-fullname-container'>
                <img className='image-avatar' src={avatar} alt="avatar" />
                <p className='full-name'>{data.fullName}</p>
            </div>
            <ul className='content-container'>
                <li>Lịch hẹn cuối cùng vào 76 ngày trước Chỉ mới đặt 1 lịch hẹn</li>
                <li>Chỉ mới đặt 1 lịch hẹn</li>
            </ul>
        </AnalyzeCustomerItemStyled>
    );
};

export default AnalyzeCustomerItem;

const AnalyzeCustomerItemStyled = styled.div`
display: grid;
gap: 16px;
.image-fullname-container {
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 16px;

    .image-avatar {
        width: 32px;
        height: 32px;
    }

    .full-name {
        color: #1B9AAA;
        font-size: 18px;
        font-weight: 500;
        line-height: 140%;
    }
}

.content-container {
    list-style-type: disc;
    margin-left: 30px;
    li {
        color: #141414;
        font-size: 16px;
        font-weight: 400;
        line-height: 140%;
        align-self: stretch;
    }
}
`;