import fetch from 'services/request';
import { IApiStaffCheckIn, IApiStaffPublic, IApiTeamMembersParams } from './types/teamMember';
const getTeamMembers = (params: IApiTeamMembersParams) => {
  return fetch({
    method: 'get',
    url: 'api/v1/merchants/staffs',
    params,
  });
};

const BASE_URL = 'api/v1/merchants/:merchant_code/staffs';

const getListPaginateStaffs = (params?: any) => {
  return fetch({
    method: 'get',
    url: BASE_URL,
    params: { ...params },
  });
};

const checkPinNumberExist = (params?: any) => {
  return fetch({
    method: 'get',
    url: `${BASE_URL}/check-pin-number`,
    params: { ...params },
  });
};

const getShowCreateStaffs = (params?: any) => {
  return fetch({
    method: 'get',
    url: `${BASE_URL}/create`,
    params: { ...params },
  });
};

const createStaffs = (body: any) => {
  return fetch({
    method: 'post',
    url: `${BASE_URL}/store`,
    body: { ...body },
  });
};

const updateStaffs = (id: string, body: any) => {
  return fetch({
    method: 'put',
    url: `${BASE_URL}/update/${id}`,
    body: { ...body },
  });
};

const deleteStaffs = (id: string) => {
  return fetch({
    method: 'delete',
    url: `${BASE_URL}/delete/${id}`,
  });
};

const getProfileStaffDetail = (params?: any) => {
  return fetch({
    method: 'get',
    url: 'api/v1/auth/profile',
    params: { ...params },
  });
};

const staffCheckIn = (params?: IApiStaffCheckIn) => {
  return fetch({
    method: 'get',
    url: `api/v1/merchants/${params?.merchant_code}/staffs/checkin`,
    params: { ...params },
  });
};

const getStaffPublic = (params?: IApiStaffPublic) => {
  return fetch({
    method: 'post',
    url: `api/v1/merchants/${params?.merchant_code}/locations/employees`,
    body: { ...params },
  });
};


const getSearchLocation = (merchant_location_id: any) => {
  return fetch({
    method: 'get',
    url: `api/v1/merchants/:merchant_code/staffs?page=1&per_page=100&merchant_location_id=${merchant_location_id}`,
  });
};

// api here
const userApis = {
  getTeamMembers,
  getListPaginateStaffs,
  getShowCreateStaffs,
  createStaffs,
  updateStaffs,
  deleteStaffs,
  getProfileStaffDetail,
  checkPinNumberExist,
  staffCheckIn,
  getStaffPublic,
  getSearchLocation
};
export default userApis;
