import { IModalFeeFormPayload, feeType } from 'features/checkout/services/types/fee';
import formatNumber from 'utils/formatNumber';

export const getValueFeeLabel = (data: IModalFeeFormPayload) => {
  if (data.type === 'percent') return formatNumber(data.value) + '%';
  return formatNumber(data.value) + 'đ';
};

export const getCalcValueFeeLabel = (type: feeType) => {
  switch (type) {
    case 'discount':
      return '-';
    case 'extra': return '+';
    default:
      return null;
  }
};

export const getTitleModal = (type: feeType, t: any) => {
  switch (type) {
    case 'extra':
      return t('AddServiceCharge');
    case 'discount':
      return t('AddDiscount');
    default:
      return null;
  }
};

export const getTitleInput = (type: feeType, t: any) => {
  switch (type) {
    case 'extra':
      return t('NameExtraCharge');
    case 'discount':
      return t('Name');
    default:
      return null;
  }
};

export const getNameInitialValue = (type: feeType, t: any) => {
  switch (type) {
    case 'extra':
      return t('ExtraService');
    case 'discount':
      return t('DiscountService');
    default:
      return null;
  }
};

export const getAddLabel = (type: feeType, t: any) => {
  switch (type) {
    case 'extra':
      return t('AddExtra');
    case 'discount':
      return t('AddDiscount');
    default:
      return null;
  }
};