import salesSelectors from 'features/sales/services/selectors';
import settingSelectors from 'features/settings/services/selectors';
import { useTranslation } from 'react-i18next';
import { formatMoney } from 'utils/unit';

const PromotionRow = () => {
  const {t: saleLang} = useTranslation('sales');
  const result = salesSelectors.getPromotionValue();
  const setting = settingSelectors.getSetting();
  
  const getOriginTotalPriceExceptPromotion = salesSelectors.getOriginTotalPriceExceptPromotion();

  if (!setting?.booking?.both_promotion_types_allow) {
    const promotionValue = result.promotionManualValue > result.promotionAutoValue ? result.promotionManualValue : result.promotionAutoValue;
    return (
    <>
      {
      promotionValue ? <p className='extra-price-row'>
        {saleLang('Promotion')}
        <span>- {formatMoney(promotionValue ?? 0)}</span>
      </p> : <></>
      }
    </>
    );
  }

  return (
    <>
      {result.promotionManualValue > 0 && <p className='extra-price-row'>
        {saleLang('Promotion')}
        <span>- {formatMoney(result.promotionManualValue ?? 0)}</span>
      </p>}
      {result.promotionAutoValue > 0 && getOriginTotalPriceExceptPromotion > 0 && <p className='extra-price-row'>
        {saleLang('Promotion')} ({saleLang('Auto')})
        <span>- {formatMoney(result.promotionAutoValue ?? 0)}</span>
      </p>}
    </>
  );
};

export default PromotionRow;
