import _debounce from 'lodash/debounce';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
type Props = {
  value?: string;
  onSearchText?: (val: string) => void;
};
/**
 * Handle input search text
 * @param value , onSearchText
 * @returns 
 */
const InputSearchText = ({ value = '', onSearchText = () => undefined }: Props) => {
  const [text, setText] = useState(value);
  const { t: formLang } = useTranslation('form');
  const onChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setText(e.target.value ?? '');
  };

  const debounce = useCallback(_debounce((text) => {
    onSearchText(text);
  }, 500), [onSearchText]);

  useEffect(() => {
    debounce(text);
  }, [text]);

  return (
    <div className='common-input-search'>
      <input
        placeholder={formLang('SearchPlaceholder') || ''}
        type='text'
        value={text}
        onChange={onChange}
      />
    </div>
  );
};

export default InputSearchText;