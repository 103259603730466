import { TimeScheduleRepeatType } from '../types/timeSchedule';

export const PREFIX_ACTIONS = 'TIME_SCHEDULE_FEATURE_';

export const NAME_REDUCER = 'timeSchedule';

export const PATH_LOADING = {
  getListTimeSchedules: `loading.${NAME_REDUCER}.getListTimeSchedules`,
  getDetailTimeSchedules: `loading.${NAME_REDUCER}.getDetailTimeSchedules`,
  getListTimeScheduleOnline: `loading.${NAME_REDUCER}.getListTimeScheduleOnline`,
  getLocationDetail: `loading.${NAME_REDUCER}.getLocationDetail`,
};

export const TIME_OFF_REPEAT_OPTS = [
  {
    value: TimeScheduleRepeatType.DAILY,
    label: 'Daily',
  },
  {
    value: TimeScheduleRepeatType.WEEKLY,
    label: 'Weekly',
  },
  {
    value: TimeScheduleRepeatType.MONTHLY,
    label: 'Monthly',
  },
];