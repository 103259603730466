import React, { useState } from 'react';
import ProgramModal from './Modal';
import { ProgramFormProps } from './Form';
import { useSetLoadingPage } from 'services/UI/LoadingPage';
import apisLoyal from 'features/loyaltyProgram/services/apis';
import { useAppDispatch } from 'store/hooks';
import loyalActions from 'features/loyaltyProgram/services/actions';
import { get } from 'lodash';
import message from 'components/common/Message';
import { useTranslation } from 'react-i18next';

const ProgramAdd = () => {
  const [isOpen, setIsOpen] = useState(false);
  const showModal = () => setIsOpen(true);
  const closeModal = () => setIsOpen(false);
  const {t: loyaltyProgramLang} = useTranslation('loyaltyProgram');
  const dispatch = useAppDispatch();

  const setPageLoading = useSetLoadingPage();

  const handleSubmit: ProgramFormProps['onSubmit'] = async (payload) => {
    setPageLoading(true);
    try {
      const res = await apisLoyal.createProgram(payload);
      dispatch(loyalActions.getListLoyalProgram.fetch({}));
      const msg = get(res, 'data.message', '');
      if (msg) {
        closeModal();
        message.success(msg);
      } else {
        throw 'fail';
      }
    } catch (error: any) {
      const errorText = get(error, 'response.data.error.message', '');
      if (errorText) {
        message.error(errorText);
        return;
      }
      const errors = Object.entries(get(error, 'response.data.errors', {}));
      if (errors.length > 0) {
        errors.map(([, valueArr]) => {
          message.error(get(valueArr, [0], ''));
        });
        return;
      }
      message.error(loyaltyProgramLang('AnErrorOccurredPleaseTryAgain')||'');
    } finally {
      setPageLoading(false);
    }
  };
  return (
    <>
      <button onClick={showModal} className={'common-btn'}>
        {loyaltyProgramLang('AddNew')}
      </button>
      <ProgramModal visible={isOpen} onCancel={closeModal} onSubmit={handleSubmit} />
    </>
  );
};

export default ProgramAdd;
