export const analyzeCustomerData = [
    {
        id: 1,
        fullName: 'Tran Van A',

    },
    {
        id: 2,
        fullName: 'Tran Van B',

    },
    {
        id: 3,
        fullName: 'Tran Van C',

    },
    {
        id: 4,
        fullName: 'Tran Van D',

    },
];

export const recentAppointmentsData = [
    {
        id: 1,
        CodeBooking: 'C24680',
        FullName: 'Tran Van A',
        price: 100000

    },
    {
        id: 2,
        CodeBooking: 'C24680',
        FullName: 'Tran Van B',
        price: 100000

    },
    {
        id: 3,
        CodeBooking: 'C24680',
        FullName: 'Tran Van C',
        price: 100000

    },
    {
        id: 4,
        CodeBooking: 'C24680',
        FullName: 'Tran Van D',
        price: 100000

    },
];