import { Modal } from 'antd';
import message from 'components/common/Message';
import { get, set } from 'lodash';
import { ReactNode, useState } from 'react';
import { useSetLoadingPage } from 'services/UI/LoadingPage';
import { useAppDispatch } from 'store/hooks';
import voucherActions from '../services/actions';
import vouchersApis from '../services/apis';
import { VOUCHER_VIEW } from '../services/constants';
import VoucherForm, { VoucherFormProps } from './VoucherForm';
import { useTranslation } from 'react-i18next';
type IAddVoucherProps = {
  customButton?: ReactNode;
  onSuccess?: () => void;
  className?: string;
};
function AddVoucher({ customButton, onSuccess, className }: IAddVoucherProps) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { t: voucherLang } = useTranslation('vouchers');
  const showModal = () => setIsModalOpen(true);
  const hideModal = () => {
    setErrors({});
    setIsModalOpen(false);
  };

  const dispatch = useAppDispatch();
  const setPageLoading = useSetLoadingPage();
  const [errors, setErrors] = useState<any>({});

  const handleSubmit: VoucherFormProps['onSubmit'] = async (payload) => {
    setErrors({});
    setPageLoading(true);

    try {
      const res = await vouchersApis.addVoucher(payload);
      const msg = get(res, 'data.data.message');
      if (msg) {
        message.success(msg);
        if (onSuccess) {
          onSuccess();
        } else {
          dispatch(voucherActions.setVoucherListParams.fetch({
            page: 1,
            per_page: 10,
            view: VOUCHER_VIEW.SALE,
            keyword: undefined,
          }));
        }
        hideModal();
      } else {
        const errorMsg = get(res, 'data.error.message');
        if (errorMsg) message.error(errorMsg);
        else throw 'fail';
      }

    } catch (error) {
      const errors = Object.entries(get(error, 'response.data.errors', {}));

      if (errors.length > 0) {
        const _errors = {};
        errors.map(([key, valueArr]) => {
          const msg = get(valueArr, [0], '');
          message.error(msg);
          set(_errors, [key], msg);
        });
        setErrors(_errors);
        return;
      }

      message.error('An error occurred. Please try again');
    } finally {
      setPageLoading(false);
    }
  };

  return (
    <>
      {customButton ?
        <div className={className} onClick={showModal}> {customButton}</div>
        : <button onClick={showModal} className={`common-btn ${className}`}>
          {voucherLang('AddNew')}
        </button>
      }
      <ModalForm errors={errors} visible={isModalOpen} onCancel={hideModal} onsubmit={handleSubmit} />
    </>
  );
}

export default AddVoucher;

type ModalFormProps = {
  visible?: boolean;
  onCancel?: () => void;
  onsubmit?: VoucherFormProps['onSubmit'],
  errors?: any;
};
const ModalForm = ({ visible, errors, onCancel = () => undefined, onsubmit = () => undefined }: ModalFormProps) => {
  if (!visible) return null;
  return (
    <Modal
      open={visible}
      onCancel={onCancel}
      maskClosable={false}
      okButtonProps={{ style: { display: 'none' } }}
      width={1800}
      footer={null}
      forceRender
      centered
      closable={false}
    >
      <VoucherForm errors={errors} isEdit={false} onCancel={onCancel} onSubmit={onsubmit} />
    </Modal>
  );
};


