import React, { useMemo } from 'react';
import styled from 'styled-components';
import RewardItem from './Item';
import checkoutSelectors from 'features/checkout/services/selectors';
import { IRewardItemResData } from 'features/loyaltyProgram/services/types/reward';
import { useAppDispatch } from 'store/hooks';
import checkoutActions from 'features/checkout/services/actions';

/**
 * Reward list
 * @returns 
 */
const RewardList = () => {
  const dispatch = useAppDispatch();
  const selectedReward = checkoutSelectors.getSelectedReward();
  const customer = checkoutSelectors.getCustomerInfo();
  const selectedService = checkoutSelectors.getSelectedServices();
  const amount = checkoutSelectors.getOriginTotalPrice();
  const voucherValue = checkoutSelectors.getVoucherValue() ?? 0;

  
  const isDisable = useMemo(() => {
    return amount - voucherValue === 0;
  }, [amount, voucherValue]);

  const rewards = checkoutSelectors.getRewardOfCustomer();  

  const myRewards = useMemo(() => rewards.filter((o: any) => {
    if(o.category !== 'free_service') return true;
    for(const service of selectedService) {
      const haveServiceReward = o.free_service.find((item: any) => {
        return service.service_variant_id === null ? item.id === service.id : item.id === service.id && item.extend_services.find((i: number) => i === service.service_variant_id);
      });

      if(haveServiceReward)
        return true;
    }
  }), [rewards]);  

  /**
   * On choose reward
   * @param o 
   * @returns 
   */
  const onChooseReward = (o: IRewardItemResData) => {
    if (selectedReward?.id !== o.id) {
      dispatch(checkoutActions.updateCusInfo.success({
        reward_info: {
          point_left_to_earn_a_reward: (customer?.reward_info?.current_point ?? 0) <  o?.points_to_earn_reward ? o?.points_to_earn_reward  -  (customer?.reward_info?.current_point ?? 0) : 0,
          current_point: customer?.reward_info?.current_point
        }
      }));
      return dispatch(checkoutActions.setSelectedReward(o));
    }
    dispatch(checkoutActions.setSelectedReward(null));
    dispatch(checkoutActions.updateCusInfo.success({
      reward_info: {
        point_left_to_earn_a_reward: 0,
        current_point: customer?.reward_info?.current_point
      }
    }));
  };



  return (
    <RewardListStyled>
      {myRewards.map((o: any) => (<RewardItem
        disabled={isDisable}
        key={o.id}
        data={o}
        active={o.id === selectedReward?.id}
        onClick={() => onChooseReward(o)}
      />))}
    </RewardListStyled>
  );
};

export default RewardList;

const RewardListStyled = styled.div`
  width: 100%;
  flex-wrap: wrap;
  display: flex;
  margin-bottom: 8px;
  gap:12px;
`;