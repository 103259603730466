import React from 'react';
import PromotionFilter from '../widgets/Filter';
import PromotionsTable from '../components/PromotionsTable';

const PromotionsPage = () => {
  return (
    <>
      <PromotionFilter />
      <PromotionsTable />
    </>
  );
};

export default PromotionsPage;