import { VIEW } from 'constants/view.enum';
import bookingSelectors from 'features/bookings/services/selectors';
import { memo, useMemo } from 'react';
import styled from 'styled-components';
import CalendarFilter from './CalendarFilter';
import GridFilter from './GridFilter';
import ListFilter from './ListFilter';

const BookingFilter = () => {
  const currentView = bookingSelectors.getBookingLayout();

  const FilterConfigs = useMemo(() => {
    switch (currentView) {
      case VIEW.GRID:
        return <GridFilter />;
      case VIEW.LIST:
        return (
          <ListFilter />
        );
      case VIEW.CALENDAR:
        return <CalendarFilter />;

      default:
        return <></>;
    }
  }, [currentView]);

  return <FilterStyled>{FilterConfigs}</FilterStyled>;
};

export default memo(BookingFilter);

const FilterStyled = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 8px;
  margin-bottom: 12px;
  line-height: 1;
  @media only screen and (max-width: 767.98px) {
    gap: 0;
  }
  .ant-form-item {
    display: flex;
    gap: 8px;
  }
  .block-left {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 10px;
  }
  .block-right {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    margin-left: auto;
    gap: 10px;
    @media only screen and (max-width: 767.98px) {
      flex: 1;
    }
    & > .d-flex {
      gap: 10px;
      margin-left: auto;
    }
  }
  .filter-btn {
    line-height: 1;
  }
  .item-full {
    width: 100%;
    max-width: 100%;
    * {
      width: 100%;
      @media only screen and (max-width: 767.98px) {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: auto;
        margin: 0px 5px;
      }
    }
    .ant-picker.is-normal {
      height: 64px;
    }
  }
`;
