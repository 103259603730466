import { Select } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import invoiceSelectors from '../services/selectors';
import { useAppDispatch } from 'store/hooks';
import invoiceActions from '../services/actions';
import { useTranslation } from 'react-i18next';

const Category = () => {
  const dispatch = useAppDispatch();
  const categories = invoiceSelectors.getCategories();
  const params = invoiceSelectors.getInvoiceAllParam();
  const { t: invoiceLang } = useTranslation('invoice');

  const [translatedAllCategories, setTranslatedAllCategories] = useState(invoiceLang('AllCategories'));

  useEffect(() => {
    const storedLanguage = localStorage.getItem('selectedLanguage');
    const translatedLabel = storedLanguage ? invoiceLang('AllCategories') : 'AllLocations';
    const initialSelectedLocation = { value: storedLanguage || 'all', label: translatedLabel };
    setSelectedCategories(initialSelectedLocation);
    setTranslatedAllCategories(invoiceLang('AllCategories'));
  }, [invoiceLang]);
  const [selectedCategories, setSelectedCategories] = useState<any>({ value: 'all', label: translatedAllCategories });
  const _categories = useMemo(() => {
    const translatedLabel = translatedAllCategories;
    const result = categories.map(o => ({ value: o.id.toString(), label: o.name }));
    result.unshift({
      value: 'all',
      label: translatedLabel || '',
    });
    return result;
  }, [categories, translatedAllCategories]);

  // const category_id = invoiceSelectors.getInvoiceParam('category_id') as string ?? 'all';

  const setCategory = (val: string) => {
    const selectedCategory = _categories.find((loc: { value: any; }) => loc.value === val);
    setSelectedCategories(selectedCategory);
    dispatch(invoiceActions.setInvoiceParams({
      ...params,
      category_id: val !== 'all' ? +val : undefined
    }));
  };

  return (
    <div className="common-select">
      <Select
        value={selectedCategories}
        options={_categories}
        onChange={setCategory}
      />
    </div>
  );
};

export default Category;