import { Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { useSearchParams } from 'react-router-dom';
import { useAppDispatch } from 'store/hooks';
import { formatMoney, tagStatusColor } from 'utils/unit';
import bookingActions from '../services/actions';
import bookingSelectors from '../services/selectors';
import { IBookingItemResData } from '../services/types/booking';
import PopoverEdit from './BookingCalendar/Table/PopoverEdit';
import UIPopover from './BookingCalendar/Table/UIBookingItem/Popover';
import useOpenPopover from './BookingCalendar/Table/useOpenPopover';
// import DateViewPicker from './BookingFilter/widgets/TableList/DateViewPicker';
import moment from 'moment';
import getSingleDateShortFormat from 'utils/moment/getSingleDateShortFormat';
import { useEffect } from 'react';
import DateViewPicker from './BookingFilter/widgets/DateViewPicker';
import { useTranslation } from 'react-i18next';

const BookingsTable = () => {
  const pagination = bookingSelectors.getListTablePagination();
  const loading = bookingSelectors.loadingBookings();
  const page = bookingSelectors.getLstTableParamValue('page');
  const per_page = bookingSelectors.getLstTableParamValue('per_page');
  // const statusOptions = bookingSelectors.getLstBookingStatus(); 
  // const teamMembers = bookingSelectors.getTeamMembers();

 
  const dispatch = useAppDispatch();

  // useEffect(() => {    
  //   dispatch(bookingActions.setListTableBookingParams({ 
  //     page: 1, 
  //     per_page: 10 }));
  // }, []);

  const listBookings = bookingSelectors.getLstTableBookings();
  const statusOptions = bookingSelectors.getLstBookingStatus();
  const employeeOption = bookingSelectors.getTeamMembers();
  const status = bookingSelectors.getCalendarParamValue('book_status');
  const employee_id = bookingSelectors.getCalendarParamValue('employee_id');


  useEffect(() => {
      const status_filter = ( status as any[]);
      const status_applied = status_filter?.length > 0 ? status_filter : statusOptions.map(o => o.value);
      // dispatch(bookingActions.getParamOptionCalendar.fetch());
      dispatch(bookingActions.setListTableBookingParams({
        book_status: status_applied
      }));
    
  }, [employee_id, status, statusOptions, employeeOption]);

  const {t: dateLang} = useTranslation('date');
  const {t: bookingLang} = useTranslation('booking');

  const columns: ColumnsType<IBookingItemResData> = [
    // {
    //   title: 'Booking ID',
    //   dataIndex: 'id',
    //   key: 'id',
    //   fixed: 'left',
    //   className: 'nowrap',
    // },
    {
      title: bookingLang('CustomerName'),
      dataIndex: 'customer',
      key: 'customer',
      fixed: 'left',
      render: (customer: any) => customer?.name,
    },
    {
      title: bookingLang('PhoneNumber'),
      dataIndex: 'customer',
      key: 'customer',
      render: (customer: any) => customer?.phone_number,
    },
    {
      title: bookingLang('BookingDate'),
      dataIndex: 'time_start',
      key: 'time_start',
      className: 'nowrap',
      render: (time: any) => {
        const currentDateBooking = moment(time, 'YYYY-MM-DD HH:mm:ss');
        return <span>
          {getSingleDateShortFormat(currentDateBooking, dateLang)} &bull; {currentDateBooking.format(currentDateBooking.format('HH:mm'))}
        </span>;
      },
    },
    {
      title: bookingLang('Total'),
      dataIndex: 'total_price',
      key: 'total_price',
      render: (price: number) => `${formatMoney(+price)} `,
    },
    {
      title: bookingLang('Status'),
      dataIndex: 'book_status',
      key: 'book_status',
      render: (status: string) => {
        return (
          <div className='booking-status'>
            <span
              style={{
                backgroundColor: `${tagStatusColor(status.toLowerCase())}`,
              }}
            ></span>
            {bookingLang(status.replace('_', ' '))}
          </div>
        );
      },
    },
    {
      title: bookingLang('Actions'),
      render: (o) => (
        <ul className={'common-action'}>
          <ViewItem data={o} />
          <PopoverEdit bookId={o.id} bookStatus={o.book_status} data={o} />
        </ul>
      ),
    },
  ];
  const [, setSearchParams] = useSearchParams();  
  
  useEffect(() => {
    setSearchParams({
      page: page as any,
      per_page: per_page as any
    });
  }, [page, per_page]);

  return (
    <div className={'booking-grid-block'}>
      <div className='block-heading'>
        <div className='heading-date'>
          <DateViewPicker isNoSelect />
        </div>
      </div>
      <Table
        className={'custom-table'}
        dataSource={listBookings}
        columns={columns}
        loading={loading}
        scroll={{
          x: 900,
        }}
        pagination={{
          total: pagination?.total_record,
          defaultCurrent: page as any ?? 1,
          defaultPageSize: per_page as any ?? 10,
          current: page as any ?? 1,
          onChange: (pageChange, sizeChange) => {
            const params: any = {
              page: pageChange == 0 ? 1 : pageChange,
              per_page: sizeChange,
            };
            dispatch(bookingActions.setListTableBookingParams({
              page: params.page,
              per_page: params.per_page,
            }));
          },
          showSizeChanger: false,
          // showTotal(total) {
          //   return `Total ${total} items`;
          // },
        }}
      />
    </div>
  );
};

export default BookingsTable;


const ViewItem = ({ data }: { data: IBookingItemResData }) => {
  const [open, setOpen] = useOpenPopover();
  return (
    <UIPopover open={open} setOpen={setOpen} data={data} removeWrapStyle>
      <button>
        <li className='action-view'></li>
      </button>
    </UIPopover>
  );
};