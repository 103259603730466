import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';


i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    lng: 'vi',
    react: { 
      useSuspense: false
    },
    interpolation: {
      escapeValue: false,
    },
    returnObjects: true
  });

export default i18n;
