const IconChat = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="16"
      viewBox="0 0 18 16"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.33317 0.5H8.28555C10.9817 0.5 13.2417 2.40612 13.843 4.97028C13.9455 5.40756 13.9998 5.86397 13.9998 6.33333C13.9998 9.55499 11.4415 12.1667 8.28555 12.1667H4.80118H2.57127C1.51929 12.1667 0.666504 11.2961 0.666504 10.2222V7.30555C0.666504 3.54695 3.65127 0.5 7.33317 0.5ZM8.52364 13.8333H5.71495C6.74559 14.8643 8.15714 15.5 9.71412 15.5H13.1985H15.4284C16.4804 15.5 17.3332 14.6294 17.3332 13.5555V10.6389C17.3332 8.91155 16.7028 7.33452 15.664 6.13446C15.6657 6.20054 15.6665 6.26683 15.6665 6.33332C15.6665 10.4755 12.4685 13.8333 8.52364 13.8333Z"
        fill="white"
      />
    </svg>
  );
};

export default IconChat;
