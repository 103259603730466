const IconSurvey = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <mask id="path-1-inside-1_4480_43599" fill="white">
        <path d="M10.0002 12.291H5.8335C5.49183 12.291 5.2085 12.0077 5.2085 11.666C5.2085 11.3243 5.49183 11.041 5.8335 11.041H10.0002C10.3418 11.041 10.6252 11.3243 10.6252 11.666C10.6252 12.0077 10.3418 12.291 10.0002 12.291Z" />
      </mask>
      <path
        d="M10.0002 12.291H5.8335C5.49183 12.291 5.2085 12.0077 5.2085 11.666C5.2085 11.3243 5.49183 11.041 5.8335 11.041H10.0002C10.3418 11.041 10.6252 11.3243 10.6252 11.666C10.6252 12.0077 10.3418 12.291 10.0002 12.291Z"
        fill="white"
      />
      <path
        d="M10.0002 10.791H5.8335V13.791H10.0002V10.791ZM5.8335 10.791C6.32026 10.791 6.7085 11.1793 6.7085 11.666H3.7085C3.7085 12.8361 4.6634 13.791 5.8335 13.791V10.791ZM6.7085 11.666C6.7085 12.1528 6.32026 12.541 5.8335 12.541V9.54102C4.6634 9.54102 3.7085 10.4959 3.7085 11.666H6.7085ZM5.8335 12.541H10.0002V9.54102H5.8335V12.541ZM10.0002 12.541C9.5134 12.541 9.12516 12.1528 9.12516 11.666H12.1252C12.1252 10.4959 11.1703 9.54102 10.0002 9.54102V12.541ZM9.12516 11.666C9.12516 11.1793 9.5134 10.791 10.0002 10.791V13.791C11.1703 13.791 12.1252 12.8361 12.1252 11.666H9.12516Z"
        fill="white"
        mask="url(#path-1-inside-1_4480_43599)"
      />
      <mask id="path-3-inside-2_4480_43599" fill="white">
        <path d="M5.83337 5.59167C5.67503 5.59167 5.5167 5.53333 5.3917 5.40833L2.2667 2.28333C2.02503 2.04167 2.02503 1.64167 2.2667 1.4C2.50837 1.15833 2.90837 1.15833 3.15003 1.4L6.27503 4.525C6.5167 4.76667 6.5167 5.16667 6.27503 5.40833C6.15003 5.525 5.9917 5.59167 5.83337 5.59167Z" />
      </mask>
      <path
        d="M5.83337 5.59167C5.67503 5.59167 5.5167 5.53333 5.3917 5.40833L2.2667 2.28333C2.02503 2.04167 2.02503 1.64167 2.2667 1.4C2.50837 1.15833 2.90837 1.15833 3.15003 1.4L6.27503 4.525C6.5167 4.76667 6.5167 5.16667 6.27503 5.40833C6.15003 5.525 5.9917 5.59167 5.83337 5.59167Z"
        fill="white"
      />
      <path
        d="M5.3917 5.40833L6.45236 4.34767L5.3917 5.40833ZM2.2667 2.28333L1.20604 3.34399L2.2667 2.28333ZM3.15003 1.4L2.08937 2.46066L2.08937 2.46066L3.15003 1.4ZM6.27503 4.525L7.33569 3.46434L6.27503 4.525ZM6.27503 5.40833L7.29851 6.50492L7.31741 6.48728L7.33569 6.46899L6.27503 5.40833ZM5.83337 4.09167C6.05139 4.09167 6.28123 4.17655 6.45236 4.34767L4.33104 6.46899C4.75217 6.89012 5.29867 7.09167 5.83337 7.09167V4.09167ZM6.45236 4.34767L3.32736 1.22267L1.20604 3.34399L4.33104 6.46899L6.45236 4.34767ZM3.32736 1.22267C3.67148 1.56679 3.67148 2.11654 3.32736 2.46066L1.20604 0.33934C0.378586 1.16679 0.378586 2.51654 1.20604 3.34399L3.32736 1.22267ZM3.32736 2.46066C2.98324 2.80478 2.43349 2.80478 2.08937 2.46066L4.21069 0.33934C3.38324 -0.488113 2.03349 -0.488113 1.20604 0.33934L3.32736 2.46066ZM2.08937 2.46066L5.21437 5.58566L7.33569 3.46434L4.21069 0.33934L2.08937 2.46066ZM5.21437 5.58566C4.87025 5.24154 4.87025 4.69179 5.21437 4.34767L7.33569 6.46899C8.16315 5.64154 8.16315 4.29179 7.33569 3.46434L5.21437 5.58566ZM5.25156 4.31175C5.37319 4.19822 5.57123 4.09167 5.83337 4.09167V7.09167C6.41216 7.09167 6.92687 6.85178 7.29851 6.50492L5.25156 4.31175Z"
        fill="white"
        mask="url(#path-3-inside-2_4480_43599)"
      />
      <path
        d="M5.3585 1.4332L5.7115 1.78621L5.3585 1.4332ZM6.24183 2.31654L5.88883 1.96353L6.24183 2.31654Z"
        fill="white"
        stroke="white"
        strokeWidth="1.5"
      />
      <path
        d="M12.5002 8.95898H5.8335C5.49183 8.95898 5.2085 8.67565 5.2085 8.33398C5.2085 7.99232 5.49183 7.70898 5.8335 7.70898H12.5002C12.8418 7.70898 13.1252 7.99232 13.1252 8.33398C13.1252 8.67565 12.8418 8.95898 12.5002 8.95898Z"
        fill="white"
      />
      <path
        d="M17.5002 13.9577C17.1585 13.9577 16.8752 13.6743 16.8752 13.3327V6.65768C16.8752 3.13268 15.6752 2.41602 13.3002 2.29102H8.3335C7.99183 2.29102 7.7085 2.00768 7.7085 1.66602C7.7085 1.32435 7.99183 1.04102 8.3335 1.04102H13.3335C16.7502 1.22435 18.1252 2.84935 18.1252 6.65768V13.3327C18.1252 13.6743 17.8418 13.9577 17.5002 13.9577Z"
        fill="white"
      />
      <path
        d="M12.5 18.9578H7.5C2.81667 18.9578 1.875 16.7995 1.875 13.3161V7.50781C1.875 7.16615 2.15833 6.88281 2.5 6.88281C2.84167 6.88281 3.125 7.16615 3.125 7.50781V13.3161C3.125 16.4161 3.73333 17.7078 7.5 17.7078H12.5C12.8417 17.7078 13.125 17.9911 13.125 18.3328C13.125 18.6745 12.8417 18.9578 12.5 18.9578Z"
        fill="white"
      />
      <path
        d="M12.5 18.959C12.4167 18.959 12.3417 18.9423 12.2583 18.909C12.025 18.809 11.875 18.584 11.875 18.334V15.834C11.875 13.8173 12.9833 12.709 15 12.709H17.5C17.75 12.709 17.9833 12.859 18.075 13.0923C18.1667 13.3257 18.1167 13.5923 17.9417 13.7757L12.9417 18.7757C12.825 18.8923 12.6583 18.959 12.5 18.959ZM15 13.959C13.6833 13.959 13.125 14.5173 13.125 15.834V16.8257L15.9917 13.959H15Z"
        fill="white"
      />
    </svg>
  );
};

export default IconSurvey;
