import { Button, Form, Upload } from 'antd';
import { IconEditSetting, IconInfoPayment, IconUpload } from 'assets/svg';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from '../../pages/index.module.scss';
import img_5422_1 from '../../../../assets/images/setting/IMG_5422_1.png';
import styled from 'styled-components';
interface IZaloPayModal {
    statusModal?: string;
}

const ZaloPayModal = ({
    statusModal,
}: IZaloPayModal) => {
    const { t: settingLang } = useTranslation('setting');
    const uploadButton = (
        <div className={styles.upload_overview}>
            <IconUpload />
            <div>
                <p className={styles.modal_upload_title} style={{ marginTop: 8 }}>{settingLang('SelectOrDragAndDropImagesToUpload')}</p>
                <p className={styles.modal_upload_content}>{settingLang('SupportsOneOrMoreImagesAs')}</p>
            </div>
        </div>
    );
    return (
        <>
            <h2 className={styles.modal_title}>Zalopay</h2>
            <MomoModalStyled>
                <Form
                    className='modal-form'
                    // onFinish={handleSubmit}
                    autoComplete='off'
                    // form={form}
                    layout='vertical'
                    initialValues={{
                        locations: null,
                    }}
                >
                    <div className={styles.modal_info}>
                        <IconInfoPayment />
                        <p className={styles.modal_info_p}>{settingLang('PleaseAttachAQRCodeImageWithNameAndPhoneMumberAttached')}.</p>
                    </div>
                    {statusModal === 'Add' ?
                        <>
                            <div>
                                <p className={styles.modal_upload_title}>{settingLang('BankQR')}</p>
                                <Form.Item >
                                    <Upload>
                                        {uploadButton}
                                    </Upload>
                                </Form.Item>
                            </div>
                            <Button className='common-btn'>{settingLang('Confirm')}</Button>
                        </>
                        :
                        <>
                            <div className={styles.modal_upload_img_container}>
                                <div>
                                    <img src={img_5422_1} alt="" />
                                </div>
                                <div className={styles.modal_upload_edit}>
                                    <IconEditSetting />
                                    <p className={styles.modal_upload_edit_p}>{settingLang('Edit')}</p>
                                </div>
                            </div>
                            <Button className='common-btn' style={{ opacity: '50%' }}>{settingLang('Confirm')}</Button>
                        </>}
                </Form>
            </MomoModalStyled>
        </>
    );
};

export default ZaloPayModal;
const MomoModalStyled = styled.div`
.ant-upload {
    width: 100%;
    text-align: center;
}
.ant-form {
    gap: 24px;
}
.common-btn {
    width: 100%;
}
`;