import IconReward from 'assets/svg/IconReward';
import { IconAvatarWalkIn } from 'features/bookings/components/BookingForm/QuickBooking/CustomerDetail';
import Can from 'features/packagePermission/components/Can';
import { ModulePermission, RewardPermission } from 'features/packagePermission/services/types/permission';
import salesSelectors from 'features/sales/services/selectors';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { translate } from 'utils/moment/getSingleDateFormat';
import { CUSTOMER_WALKIN_INDEX, pluralizeWord } from 'utils/unit';

const CustumerDetail = ({ step }: { step: number }) => {
    const currCustomer = salesSelectors.getCustomer();
    const total_point_program_will_earn = salesSelectors.getTotalPointWillEarn();
    // const total_point_of_customer = currCustomer?.loyalty_point ?? 0;
    const total_point_of_customer = currCustomer?.current_point ?? 0;
    const name = currCustomer?.name ?? '';


    const Rewards = () => {

        const totalLoyaltyAfterCheckout = () => {
            return Number(total_point_of_customer) + Number(total_point_program_will_earn);
        };

        if (step === 3) {
            const totalPoint = totalLoyaltyAfterCheckout();
            return totalPoint > 0 ? <><IconReward /> <span>{bookingLang('Have')} {totalPoint} {pluralizeWord(bookingLang('LoyaltyPoint'), totalPoint)}</span></> : <></>;

        }

        return null;
    };
    const { t: bookingLang } = useTranslation('booking');
    return (
        <>
            <CustumerDetailStyled>
                <div className="form-row">
                    <p className="form-label">{bookingLang('CustomerDetails')}</p>
                </div>
            </CustumerDetailStyled>
            <div className='current-customer'>
                {Number(currCustomer?.is_walkin_in ?? 0) !== CUSTOMER_WALKIN_INDEX ? <p className='customer-avatar common-avatar'>
                    {name && Array.from(Number(currCustomer?.is_walkin_in) !== CUSTOMER_WALKIN_INDEX ? name : translate('common', 'WalkInCustomer'))[0]}
                </p> : <IconAvatarWalkIn />}
                <div className='customer-detail'>
                    <p className={`detail-name ${(currCustomer?.is_walkin_in ?? 0) !== CUSTOMER_WALKIN_INDEX ? 'customer-link' : 'customer-link-disable'}`} style={{ textTransform: 'capitalize' }}>{currCustomer?.is_walkin_in !== CUSTOMER_WALKIN_INDEX ? name : translate('common', 'WalkInCustomer')}</p>
                    {(currCustomer?.is_walkin_in ?? 0) !== CUSTOMER_WALKIN_INDEX ?
                        <>
                            {/* <p>{bookingLang('CustomerID')}: <span>{currCustomer?.customer_code ?? ''}</span></p> */}
                            <Can module={ModulePermission.REWARDS} permission={RewardPermission.VIEW_REWARD}>
                                <div className='reward'> <Rewards /></div>
                            </Can>
                            
                        </> : <></>}
                </div>
            </div>
        </>
    );
};

export default CustumerDetail;

const CustumerDetailStyled = styled.div`
  .form-row {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    .form-label {
      font-weight: 600;
      font-size: 18px;
      color: '#363565';
    }
  }
  .customer-link-disable {
    pointer-events: none;
  }

`;