import { CopyOutlined } from '@ant-design/icons';
import { Button, Form, Input, QRCode, Spin, Switch } from 'antd';
import { AxiosResponse } from 'axios';
import message from 'components/common/Message';
import { get } from 'lodash';
import { useDispatch } from 'react-redux';
import storage from 'utils/sessionStorage';
import settingActions from '../services/actions';
import settingApis from '../services/apis';
import settingSelectors from '../services/selectors';
import FormTabSetting from './FormTabSetting';
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import Can from 'features/packagePermission/components/Can';
import { BookingFormPermission, ModulePermission } from 'features/packagePermission/services/types/permission';
import { translate } from 'utils/moment/getSingleDateFormat';
// import TextArea from 'antd/es/input/TextArea';

interface ICustomerLink {
  fullDomain: string;
  checkinQR: boolean;
  selectServive: boolean;
  online_promotionText: string;
  online_headerText: string;
  online_descriptionText: string;
  checkIn_promotionText: string;
  checkIn_headerText: string;
  checkIn_descriptionText: string;
  viewBirthday?: boolean;
  urlView?: string;
  isPromotion?: boolean;
  enable_timeroster_management?: any;
  featured_category: boolean;
}

const CustomerLinksTab = () => {

  const dispatch = useDispatch();
  
  useEffect(() => {
    dispatch(settingActions.getSetting.fetch());
    dispatch(settingActions.getOptionBooking.fetch());
    dispatch(settingActions.getListClosedDate.fetch());
  }, []);

  const getSetting = settingSelectors.getSetting();
  const loading = settingSelectors.loadSetting();
  const [form] = Form.useForm();
  const location = useLocation();



  useEffect(() => {
    getInitValues();
  }, [getSetting]);

  const getInitValues = () => {
    const data = getSetting?.customer_link;
    
    const merchantCode: string = storage.merchantCode.get();
    const merchantName: string = storage.merchantName.get();

    const initData = {
      fullDomain: `${window.location.href.replace(location?.pathname, '')}/${merchantName.replaceAll(' ', '-')}/booking-form/${merchantCode}`.toLowerCase(),
      checkinQR: data?.checkin_qr,
      selectServive: data?.customer_services,
      online_promotionText: getSetting?.booking_online_text?.promotion_text,
      online_headerText: getSetting?.booking_online_text?.header_text,
      online_descriptionText: getSetting?.booking_online_text?.description_text,
      checkIn_promotionText: getSetting?.checkin_text?.promotion_text,
      checkIn_headerText: getSetting?.checkin_text?.header_text,
      checkIn_descriptionText: getSetting?.checkin_text?.description_text,
      viewBirthday: !!getSetting?.booking?.view_birthday,
      urlView: getSetting?.review?.url_review,
      isPromotion: data?.is_promotion,
      enable_timeroster_management: getSetting?.notification_setting?.enable_timeroster_management,
      featured_category: getSetting?.booking?.featured_category
    };
    
    form.setFieldsValue(initData);
    return initData;
  };

  const getPayload = (values: ICustomerLink) => {
    return {
      checkin_qr: values?.checkinQR,
      customer_services: values?.selectServive,
      promotion_text_booking: values?.online_promotionText,
      herder_text_booking: values?.online_headerText,
      description_text_booking: values?.online_descriptionText,
      promotion_text_checkin: values?.checkIn_promotionText,
      herder_text_checkin: values?.checkIn_headerText,
      description_text_checkin: values?.checkIn_descriptionText,
      view_birthday: !!values?.viewBirthday,
      url_review: values?.urlView,
      is_promotion: values?.isPromotion,
      enable_timeroster_management: values?.enable_timeroster_management,
      featured_category: values?.featured_category
    };
  };
  const handleSubmit = async (values: ICustomerLink) => {

    const payload = getPayload(values);
    try {
      const rest: AxiosResponse<{ message: string }> = await settingApis.updateCustomerLink(payload);

      const msg = get(rest, 'data.message', '');
      if (msg) {
        message.success(msg);
        dispatch(settingActions.getSetting.fetch());
        form.setFieldsValue(getInitValues());
        return true;
      }
      return false;
    } catch (error: any) {
      const response = get<Record<string, string[]>>(
        error,
        'response.data.errors',
        {}
      );

      const errors = Object.values(response);
      errors.map((value) => {
        message.error(get(value, [0], ''));
      });

      return false;
    }
  };

  const checkValid = async () => {
    try {
      await form.validateFields([
        // 'phoneNumber',
        // 'name',
        // 'email',
        // 'service',
        // 'bookingDate',
        // 'bookingTime',
      ]);
      return true;
    } catch (error) {
      return false;
    }
  };

  const copyTextToClipboard = async (text: string) => {
    if ('clipboard' in navigator) {
      return await navigator.clipboard.writeText(text);
    } else {
      return await document.execCommand('copy', true, text);
    }
  };

  const handleCopy = async (text: string) => {
    
    try {

        await copyTextToClipboard(text);
        message.success(translate('common', 'message.Copied'));
    } catch (error) { }
  };

  const {t: settingLang} = useTranslation('setting');

  return (
    <CustomerLinksTabStyled>
      <Spin spinning={loading}>
        <FormTabSetting
          form={form}
          handleSubmit={handleSubmit}
          checkValid={checkValid} 
          initialValues={getInitValues()}
        >
          <div className='cards-wrapper'>
            <div className='cards-col'>
              <div className='common-card'>
                <div className='card-title'>
                  <p>{settingLang('BookingOnline')}</p>
                </div>
                <div className='card-body'>
                  <FullDomainStyled>
                    <Can module={ModulePermission.BOOKING_FORMS} permission={BookingFormPermission.BOOKING_ONLINE}>
                      <Form.Item name='fullDomain' label={settingLang('BookingOnline')}>
                        <Input className='is-blur' id="myInput" disabled suffix={<Button onClick={() => handleCopy(getInitValues().fullDomain)} icon={<CopyOutlined />} />}></Input>
                      </Form.Item>
                      <p className='form-note'>
                        {settingLang('LinkWhichCustomersCanUseToMakeOnlineBookings')}
                      </p>
                      <QRCode size={150} value={getInitValues().fullDomain} />
                    </Can>


                    <Form.Item name='urlView' label={settingLang('GoogleReviewURL')}>
                      <Input placeholder={settingLang('EnterGoogleReviewUrl') || ''} className='is-blur' id="myInput" suffix={<Button onClick={() => handleCopy(form.getFieldValue('urlView'))} icon={<CopyOutlined />} />}></Input>
                    </Form.Item>
                  </FullDomainStyled>
                  <FullDomainStyled>
                    <div className='form-row'>
                      <Form.Item noStyle name='checkinQR' />
                      <div>
                        <div className='d-flex' style={{ gap: '10px' }}>
                          <div className='form-row'>
                            <p className='form-label'>{settingLang('PromotionRedemption') || ''}</p>
                            <Form.Item valuePropName='checked' initialValue={ form?.getFieldValue('isPromotion') } name={'isPromotion'} noStyle>
                              <Switch data-testing='promotion-redemption' defaultChecked={ form?.getFieldValue('isPromotion') } />
                            </Form.Item>
                          </div>
                        </div>
                        <p className='form-note'>
                          {settingLang('AllowRedeemPromotionOnTheCheckOutScreen')}
                        </p>
                      </div>
                    </div>
                  </FullDomainStyled>
                  <Form.Item label={settingLang('PromotionText')} name='online_promotionText'>
                    <Input placeholder={settingLang('EnterPromotionText')||''} />
                  </Form.Item>
                  <Form.Item label={settingLang('HeaderText')} name='online_headerText'>
                    <Input placeholder={settingLang('EnterHeaderText')||''} />
                  </Form.Item>
                  <Form.Item label={settingLang('AddDescription')} name='online_descriptionText'>
                    <Input placeholder={settingLang('EnterDescriptionText')||''} />
                  </Form.Item>
  
                  <div className='form-row'>
                    <Form.Item noStyle name='checkinQR' />
                    <div>
                      <div className='d-flex' style={{ gap: '10px' }}>
                        <div className='form-row'>
                          <p className='form-label'>{settingLang('FeaturedCategory') || ''}</p>
                          <Form.Item valuePropName='checked' initialValue={ form?.getFieldValue('featured_category') } name={'featured_category'} noStyle>
                            <Switch data-testing='online-featured-category' defaultChecked={ form?.getFieldValue('featured_category') } />
                          </Form.Item>
                        </div>
                      </div>
                      <p className='form-note'>
                        {settingLang('DisplayYourCategoryAtTheTopOfYourOnlineBookingMenuOfServices')}
                      </p>
                    </div>
                  </div>
                  <div className='card-body'>
                    <div className='form-row'>
                      <p className='form-label'>{settingLang('EnableTimeRoster')||''}</p>
                      <Form.Item valuePropName='checked' initialValue={ form?.getFieldValue('enable_timeroster_management') } name={'enable_timeroster_management'} noStyle>
                        <Switch data-testing='online-enable-time-roster' defaultChecked={ form?.getFieldValue('enable_timeroster_management') } />
                      </Form.Item>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='cards-col'>
              <div className='common-card'>
                <h2 className='card-title'>{settingLang('QRCheckIn')}</h2>
                <div className='card-body'>
                  <div className='form-row'>
                    <Form.Item noStyle name='checkinQR' />
                    <div>
                      <div className='d-flex' style={{ gap: '10px' }}>
                      <div className='form-row'>
                        <p className='form-label'>{settingLang('ServiceSelection') || ''}</p>
                        <Form.Item valuePropName='checked' initialValue={ form?.getFieldValue('selectServive') } name={'selectServive'} noStyle>
                          <Switch data-testing='qr-service-selection' defaultChecked={ form?.getFieldValue('selectServive') } />
                        </Form.Item>
                      </div>
                      </div>
                      <p className='form-note'>
                        {settingLang('AllowCustomersToSelectServicesInTheCheckInLink')}
                      </p>
                    </div>
                  </div>
                  <div className='form-row'>
                    <Form.Item noStyle name='checkinQR' />
                    <div>
                      <div className='d-flex' style={{ gap: '10px' }}>
                        <div className='form-row'>
                          <p className='form-label'>{settingLang('FeaturedCategory')||''}</p>
                          <Form.Item valuePropName='checked' initialValue={ form?.getFieldValue('featured_category') } name={'featured_category'} noStyle>
                            <Switch data-testing='qr-featured-category' defaultChecked={ form?.getFieldValue('featured_category') } />
                          </Form.Item>
                        </div>
                      </div>
                      <p className='form-note'>
                        {settingLang('DisplayYourCategoryAtTheTopOfYourQRCheckInMenuOfServices')}
                      </p>
                    </div>
                  </div>
                  <Form.Item label={settingLang('PromotionText')} name='checkIn_promotionText'>
                    <Input placeholder={settingLang('EnterPromotionText')||''} />
                  </Form.Item>
                  <Form.Item label={settingLang('HeaderText')} name='checkIn_headerText'>
                    <Input placeholder={settingLang('EnterHeaderText')||''} />
                  </Form.Item>
                  <Form.Item label={settingLang('AddDescription')} name='checkIn_descriptionText'>
                    <Input placeholder={settingLang('EnterDescriptionText')||''} />
                  </Form.Item>
                  <p className='form-note'>
                    {settingLang('YouCanSetUpTheDescriptionDisplayedOnTheTopPageOfTheCheckInLink')}
                  </p>
                </div>
              </div>
              <div className='common-card'>
                <h2 className='card-title'>{settingLang('Configs')}</h2>
                <div className='card-body'>
                  <SwitchInputStyled>
                  <div className='form-row'>
                    <p className='form-label'>{settingLang('EnableShowInputBirthday')||''}</p>
                    <Form.Item valuePropName='checked' initialValue={ form?.getFieldValue('viewBirthday') } name={'viewBirthday'} noStyle>
                      <Switch data-testing='config-view-birthday' defaultChecked={ form?.getFieldValue('viewBirthday') } />
                    </Form.Item>
                  </div>
                    <p className='form-note'>
                      {settingLang('AllowEnteringInputDateOfBirthOnTheCheckinAndBookingFormPage')}
                    </p>
                  </SwitchInputStyled>
                </div>

              </div>

            </div>
          </div>
        </FormTabSetting>
      </Spin>
    </CustomerLinksTabStyled>
  );
};

export default CustomerLinksTab;

const SwitchInputStyled = styled.div`
.form-label {
  @media only screen and (max-width: 767.98px) {
    white-space: normal;
  }
}
`;

const FullDomainStyled = styled.div`
  border-bottom: 1px solid #F0F2F7;
  padding: 0 0 24px;
  margin: 0 0 24px;
  display: grid;
  gap: 24px;
  .ant-form-item-control {
    margin: 0 !important;
  }
  .ant-form-item-control-input-content {
    display: grid;
    gap: 8px;
  }
`;

const CustomerLinksTabStyled = styled.div`
margin-top: 100px;
@media only screen and (max-width: 767.98px) {
  margin-top: 0;
}
`;

