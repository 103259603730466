import { translate } from './getSingleDateFormat';

/**
 * Handle get hours
 * @param hours 
 * @returns 
 */
const getHours = (hours: number) => {

  if (hours !== 0) {
    if (hours === 1) return hours + ' '+ translate('date', 'hr');
    return hours + ' ' + translate('date', 'hrs');
  }
  return '';
};

/**
 * Handle get minutes
 * @param minutes 
 * @returns 
 */
const getMinutes = (minutes: number) => {
  if (minutes !== 0) {
    if (minutes === 1) return minutes + ' '+ translate('date', 'min');
    return minutes + ' '+ translate('date', 'mins');
  }
  return '';
};

/**
 * Handle format Time Minutes
 * @param totalMinutes 
 * @returns 
 */
export const formatTimeMinutes = (totalMinutes: number) => {
  const hours = Math.floor(totalMinutes / 60);

  const minutes = totalMinutes % 60;
  
  return `${getHours(hours)} ${getMinutes(minutes)}`;
};

/**
 * Handle generate List Durations
 * @param limitHour 
 * @returns 
 */
const generateListDurations = (limitHour?: number) => {

  const maximumMinutes = (limitHour ?? 6) * 60;
  const result = [];
  let number = 0;
  do {
    if (number >= 120) {
      number += 15;
    } else {
      number += 5;
    }
    result.push({ value: number, label: formatTimeMinutes(number) });
  } while (number < maximumMinutes);
  return result;
};
export default generateListDurations;