import React from 'react';
import { Checkbox } from 'antd';

/**
 * List services
 * @returns 
 */
const ListServices = () => {

  const dataList = [
    {
      id: '1',
      name: 'WAXING - Feet, Massage - Hand and Feet...',
      price: '24đ',
    },
    {
      id: '2',
      name: 'WAXING - Feet',
      price: '24đ',
    },
    {
      id: '3',
      name: 'WAXING - Feet',
      price: '24đ',
    },
    {
      id: '4',
      name: 'WAXING - Feet',
      price: '24đ',
    },
  ];

  return (
    <div className="form-select-wrapper">
      <ul className="select-list">
        {dataList.map((item) => {
          const { id, name } = item;
          return (
            <li key={id}>
              <Checkbox key={id}>{name}</Checkbox>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default ListServices;
