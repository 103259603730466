import { Modal } from 'antd';
import { useRef, useState } from 'react';
import AddNewCustomerForm, { AddNewCustomerFormRef, IFormAddCustomerData, IFuncCancel, IFuncSubmit } from './CustomersForm';
// import authSelectors from 'features/auth/services/selectors';
import { AxiosResponse } from 'axios';
import message from 'components/common/Message';
import customerActions from 'features/customers/services/actions';
import apisCustomer from 'features/customers/services/apis';
import { IApiCreateNewCustomerBody } from 'features/customers/services/types/cutomer';
import { get, set } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from 'store/hooks';
import storage from 'utils/sessionStorage';
const AddNewCustomer = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const formRef = useRef<AddNewCustomerFormRef>(null);
  const { t: customerLang } = useTranslation('customer');

  const dispatch = useAppDispatch();
  const [errors, setErrors] = useState<Record<string, string> | null>(null);
  // const merchantInfo = authSelectors.getMerchantInfo();

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel: IFuncCancel = () => {
    setIsModalOpen(false);
    setErrors(null);
  };
  const getPayload = (data: IFormAddCustomerData) => {
    const merchant_id = storage.merchantId.get() ?? '';
    const name = data?.name;
    const phone_number = data?.phoneNumber ?? '';
    const email = data?.email ?? '';
    const date_of_birth = data.birthDate ? data.birthDate.format('YYYY-MM-DD') : null;
    const total_visited = parseInt(data?.numberVisits ?? 0);
    const total_cancelled = parseInt(data?.cancellations ?? 0);
    const total_no_show = parseInt(data?.noShows ?? 0);
    const loyalty_point = parseInt(data?.loyaltyPoints ?? 0);
    const important_client_info = data?.importantClientInfo ?? null;
    const gender = data?.gender ?? null;
    const amount_spent = data?.amountSpent ?? 0;
    const payload: IApiCreateNewCustomerBody = {
      merchant_id,
      name,
      phone_number,
      email,
      gender,
      date_of_birth,
      total_visited,
      total_cancelled,
      total_no_show,
      loyalty_point,
      amount_spent,
      notify_market: 0,
      notification_customer: 1,
      important_client_info,
    };
    return payload;
  };
  const handleSubmit: IFuncSubmit = async (data) => {
    handleCancel();
    const payload = getPayload(data);
    try {
      const rest: AxiosResponse<{ message: string }> = await apisCustomer.createNewCustomer(payload);
      const msg = get(rest, 'data.message', '');
      if (msg) {
        message.success(msg);
        handleCancel();
        dispatch(customerActions.createCustomer.success({}));
        return true;
      }
      return false;
    } catch (error: any) {
      const errors = Object.entries(get(error, 'response.data.errors', {}));
      const errorsData = {};
      if (errors.length > 0) {
        errors.map(([key, text]) => {
          const msg = get(text, [0]);
          message.error(msg);
          set(errorsData, [key], msg);
        });
        if (errors.some(([key]) => {
          if (['phone_number', 'name', 'email', 'date_of_birth'].includes(key)) return true;
          return false;
        })) {
          formRef.current?.prevStep();
        }
        setErrors(errorsData);
      }
      else {
        message.error('An error occurred. Please try again');
      }
      return false;
    }
  };

  return (
    <>
      <button onClick={showModal} className={'common-btn'}>
        {customerLang('New.AddBtn')}
      </button>
      {isModalOpen &&
        <AddCustomer
          visible
          errors={errors}
          handleCancel={handleCancel}
          handleSubmit={handleSubmit}
        />}
    </>
  );
};

type AddCustomerProps = {
  visible?: boolean;
  handleCancel?: () => void;
  handleSubmit?: IFuncSubmit;
  errors?: Record<string, string> | null;
};
const AddCustomer = ({ visible, handleCancel, handleSubmit, errors }: AddCustomerProps) => {
  const { t: customerLang } = useTranslation('customer');
  return (
    <Modal
      maskClosable={false}
      open={visible}
      onCancel={handleCancel}
      okButtonProps={{ style: { display: 'none' } }}
      width={574}
      footer={null}
      forceRender
      centered
      closable={false}
    >
      <h2 className="modal-title">
        <p>{customerLang('New.ModalTitle')}</p>
      </h2>
      {/* <Form
        name="basic"
        className="modal-form"
        onFinish={handleSubmit}
        autoComplete="off"
        initialValues={{
          phoneNumberCode: '+92',
        }}
        layout="vertical"
      > */}
        <AddNewCustomerForm
          // ref={formRef}
          errors={errors}
          onCancel={handleCancel}
          onSubmit={handleSubmit}
        />
      {/* </Form> */}
    </Modal>
  );
};

export default AddNewCustomer;
