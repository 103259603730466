import React, { useEffect, useState } from 'react';
import { useLocation, useResolvedPath } from 'react-router-dom';
import styles from '../index.module.scss';
import styled from 'styled-components';
type INavItem = {
  text: string;
  path: string;
  icon: JSX.Element;
  icon_right?: JSX.Element;
  childrens: {
    text: string;
    path: string;
    // icon: JSX.Element;
  }[];
};
type Props = {
  data: INavItem;
  isNavShow: boolean;
};

/**
 * Nav item
 * @param data, isNavShow 
 * @returns 
 */
const NavItem = ({ data, isNavShow }: Props) => {
  const [isActive, setIsActive] = useState(false);
  const [isParentActive, setIsParentActive] = useState(false);
  const { text, icon, childrens, icon_right } = data;
  const pathname = useResolvedPath('');
  const location = useLocation();

  useEffect(() => {
    if (!isNavShow) {
      setIsActive(false);
    }
  }, [isNavShow]);

  /**
   * active submenu
   */
  const activeSubmenu = () => {
    const root = location.pathname.split('/');
    const path = data.path.split('/');

    if (root?.[2] === path?.[2]) setIsActive(true);

  };

  useEffect(() => {
    const isActive = childrens.some((children) => {
      return window.location.pathname.includes(children?.path);
    });

    setIsParentActive(isActive);
    activeSubmenu();
  }, [pathname, childrens]);

  useEffect(() => {
    setIsActive(false);
    activeSubmenu();
    
  }, [location]);

  const activeStyle = {
    background: '#1B9AAA',
    color: '#ffffff',
    opacity: '1',
  };

  return (
    <span
      onClick={() => setIsActive(!isActive)}
      className={`${isActive || isParentActive ? styles.nav_item_active : undefined} ${isParentActive ? styles.nav_item_parent_active : undefined
        }`}
      style={isParentActive ? activeStyle : undefined}
    >
      <NavItemStyled>
        <div className='menu_text'>
          {icon}
          <span>{text}</span>
          <div className={`${isParentActive || isActive ? 'active' : ''} icon_right`}>
            {icon_right}
          </div>
        </div>

      </NavItemStyled>
    </span>
  );
};

export default NavItem;


const NavItemStyled = styled.div`
.menu_text {
  display: flex;
}
.icon_right {
  position: absolute;
  right: 0;
  margin-top: 5px; 
  transition: all 0.3s ease;
}

.active { 
  transform: rotate(90deg);
}
`;