import { AxiosResponse } from 'axios';
import message from 'components/common/Message';
import ModalConfirm, {
  ModalConfirmRef,
} from 'components/common/Modal/ModalConfirm';
import { IMerchantEmployee } from 'features/bookings/components/BookingForm/types';
import { get } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { useSetLoadingPage } from 'services/UI/LoadingPage';
import BrandForm, {
  IFuncHandleSubmit,
  IPayloadReturnType,
} from '../Components/BrandFrom';
import serviceActions from '../../services/services/actions';
import apisService from '../../services/services/apis';
import BrandTable from '../Components/BrandFrom/BrandTable';
import styles from './index.module.scss';
interface IServiceVariant {
  name?: string;
  price?: number;
  duration?: number;
}

export type IServiceReponse = {
  category: {
    id: number;
    name: string;
    color: string;
  };
  enable_discount?: boolean;
  enable_voucher_sale?: boolean;
  enable_online_booking?: boolean;
  enable?: boolean;
  description?: string;
  id: number;
  merchant_location_id: number[];
  merchant_employees: IMerchantEmployee[];
  regular_price: number;
  sale_price: number;
  service_variants: IServiceVariant[];
  name?: string;
  service_name?: string;
  service_price?: number;
  duration_time?: number;
  is_favourite?: boolean;

  assigned_employee: {
    employee: {
      id: number;
      full_name: string;
      email: string;
      phone: string;
      merchant_location_id: number;
    };
    time_start: string;
    duration: string;
    time_end: string;
    status: string;
  };

  checked?: boolean;
  time_start?: string;
  employee_id?: number;
  quantity?: number;
  serviceVariantName?: string;
  serviceVariantPrice?: number;
  service_id?: string;
};

const BrandPage = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [serviceId, setServiceId] = useState<number>();
  const [currService, setCurrService] = useState<any>();
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const modalConfirmRef = useRef<ModalConfirmRef>(null);
  const { t: bookingLang } = useTranslation('booking');
  const { t: serviceLang } = useTranslation('service');
  const setPageLoading = useSetLoadingPage();
  const [titleModel, setTitleModel] = useState('add');

  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      serviceActions.getListPaginateServices.fetch({
        page: Number(searchParams.get('page') ?? 1),
        per_page: Number(searchParams.get('per_page') ?? 10),
        keyword: searchParams.get('keyword') ?? ''
      })
    );
    const page: any = {
      page: searchParams.get('page') ?? 1,
      per_page: searchParams.get('per_page') ?? 10,
      keyword: searchParams.get('keyword') || undefined,
    };

    dispatch(serviceActions.setServiceListParams(page));

    dispatch(serviceActions.getShowCreateService.fetch());
  }, []);

  const onAddItem = () => {
    setTitleModel('add');
    setIsEdit(false);
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setCurrService(null);
  };

  const handleSubmitUpdate = async (payload: IPayloadReturnType) => {
    handleCancel();
    try {
      const rest: AxiosResponse<{ message: string }> =
        await apisService.updateServices(serviceId, payload);
      const msg = get(rest, 'data.message', '');
      if (msg) {
        message.success(msg);
        dispatch(
          serviceActions.setServiceListParams({
            page: Number(searchParams.get('page') ?? 1),
            per_page: Number(searchParams.get('per_page') ?? 10),
            keyword: searchParams.get('keyword') ?? ''
          })
        );
        return true;
      } else {
        const msgError = get(rest, 'data.error.message', '');
        if (msgError) message.error(msgError);
        return false;
      }
    } catch (error: any) {
      const response = get<Record<string, string[]>>(
        error,
        'response.data.errors',
        {}
      );
      const errors = Object.values(response);
      errors.map((value) => {
        message.error(get(value, [0], ''));
      });
      return false;
    }
  };

  const handleSubmitStore = async (payload: IPayloadReturnType) => {

    try {
      const rest: AxiosResponse<{ data: { message: string } }> =
        await apisService.storeServices(payload);
      const msg = get(rest, 'data.data.message', '');
      if (msg) {
        message.success(msg);
        dispatch(
          serviceActions.setServiceListParams({
            page: 1,
            per_page: 10,
          })
        );
        handleCancel();
        return true;
      }
      const msgError = get(rest, 'data.error.message', '');
      if (msgError) message.error(msgError);

      return false;
    } catch (error: any) {
      const response = get<Record<string, string[]>>(
        error,
        'response.data.errors',
        {}
      );

      const errors = Object.values(response);
      errors.map((value) => {
        message.error(get(value, [0], ''));
      });
      return false;
    }
  };

  const handleSubmit: IFuncHandleSubmit = async (payload) => {
    setPageLoading(true);
    try {
      return isEdit
        ? await handleSubmitUpdate(payload)
        : await handleSubmitStore(payload);
    } catch (error) {
    } finally {
      setPageLoading(false);
      dispatch(serviceActions.getShowCreateService.fetch());
    }
  };

  const handleModalUpdate = (record: IServiceReponse) => {
    // const fieldsValue = {
    //   service_name: record?.name,
    //   price: record?.sale_price,
    //   duration: record?.duration_time,
    //   category: record?.category?.id,
    //   isEnableDiscounts: record?.enable_discount,
    //   isEnableService: record?.enable,
    //   isEnableBookingLink: record?.enable_online_booking,
    //   isEnableVoucherSales: record?.enable_voucher_sale,
    //   serviceDescription: record?.description,
    //   availableLocation: record?.merchant_location_id,
    //   locationTemp: record?.merchant_location_id,
    //   serviceVariants: record?.service_variants,
    //   teamMember: record?.merchant_employees?.map((o) => o.id),
    // };
    setTitleModel('edit');
    // setCurrService(fieldsValue);
    setServiceId(record?.id);
    setIsEdit(true);
    setIsModalOpen(true);
  };

  const onClickRemoveItem = (record: IServiceReponse) => {
    modalConfirmRef.current?.show({
      title: bookingLang('modalConfirm.deleteBrand.title'),
      msg: bookingLang('modalConfirm.deleteBrand.message'),
      submit: async () => {
        try {
          const res = await apisService.deleteService(record.id);
          const msg = get(res, 'data.message', '');
          if (msg) {
            message.success(msg);
            dispatch(serviceActions.getListPaginateServices.fetch({}));
          } else {
            const msgError = get(res, 'data.error.message');
            if (msgError) message.error(msgError);
          }
        } catch (error) {
          message.error('An error occurred. Please try again');
        }
      },
      icon: 'delete',
    });
  };

  const columns = [
    {
      title: serviceLang('BrandName'),
      dataIndex: 'name',
      key: 'name',
      fixed: 'left',
      render: (text: any, record: IServiceReponse) => {
        return (
          <div className={styles.titleContainer} onClick={() => handleModalUpdate(record)}>
            {text}
          </div>
        );
      }
    },
    {
      title: serviceLang('TheNumberOfProducts'),
      dataIndex: 'quantity',
      key: 'quantity',
      render: (text: any) => {
        return (
          <div>
            {text}
          </div>
        );
      },
    },
    {
      title: serviceLang('Actions'),
      dataIndex: 'actions',
      key: 'actions',
      render: (text: string, record: IServiceReponse) => (
        <ul className={'common-action'}>
          <li
            className='action-edit'
            onClick={() => handleModalUpdate(record)}
          ></li>
          <li
            className='action-remove'
            onClick={() => onClickRemoveItem(record)}
          ></li>
        </ul>
      ),
    },
  ];

  return (
    <>
      <div className={'private-filter'}>
        <button onClick={onAddItem} className={'common-btn'}>
          {serviceLang('btn.AddNew')}
        </button>
      </div>
      <BrandTable columns={columns} />

      {isModalOpen && (
        <BrandForm
          formMode={titleModel}
          handleCancel={handleCancel}
          handleSubmit={handleSubmit}
          isModalOpen={isModalOpen}
          currService={currService}
        />
      )}

      <ModalConfirm ref={modalConfirmRef} />
    </>
  );
};

export default BrandPage;