const IconAnalytic = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
    >
      <circle opacity="0.4" cx="9" cy="11.0127" r="9" fill="#595959" />
      <path
        d="M19.8025 8.02545C19.0104 4.09687 15.9158 1.00224 11.9872 0.210138C10.9045 -0.00817861 10 0.908115 10 2.01268V8.01268C10 9.11725 10.8954 10.0127 12 10.0127H18C19.1046 10.0127 20.0209 9.10823 19.8025 8.02545Z"
        fill="#595959"
      />
    </svg>
  );
};

export default IconAnalytic;
