import { Column } from '@ant-design/plots';
import { isArray } from 'lodash';
import moment from 'moment';
import { useMemo } from 'react';
import analyticSelectors from '../services/selectors';
import { useTranslation } from 'react-i18next';

/**
 * Chart sales
 * @returns 
 */
const ChartSales = () => {
  const {t: analyticsLang} = useTranslation('analytics');
  const {t: dateLang} = useTranslation('date');
  const saleFigures = analyticSelectors.getSaleFigures();
  
  const _data = useMemo(() => {
    if (!saleFigures) return [];
    if (!isArray(saleFigures)) {
      const result: {
        xField: string;
        type: string;
        value: number;
      }[] = [];
      // @ts-ignore
      Object.entries(saleFigures).forEach(([key, values]: [string, { type: string, total_price: number }[]]) => {
        values?.forEach(o => {
          
          result.push({
            xField: dateLang(key),
            type: o.type,
            value: o.total_price,
          });
        });
      });
      return result;
    }
    
    const result: {
      xField: string;
      type: string;
      value: number;
    }[] = [];
    saleFigures.forEach((arr: { type: string, total_price: number, date_book: string }[]) => {
      arr?.forEach(o => {
        result.push({
          xField: moment(o.date_book, 'YYYY-MM-DD').format('DD.MM'),
          type: analyticsLang(o.type),
          value: Number(o.total_price?.toFixed(2)),
        });
      });
    });

    return result;
  }, [saleFigures]);

  return (
    <>
      <Column
        data={_data}
        xField='xField'
        yField='value'
        seriesField='type'
        isGroup
        columnStyle={{ radius: [20, 20, 0, 0] }}
      />
    </>
  );
};

export default ChartSales;
