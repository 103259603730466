import React, { useEffect, useRef } from 'react';
import lottie from 'lottie-web';
import animationData from '../../../../src/assets/json/92460-checkmark-animation.json';
import { Button } from 'antd';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
type Props = {
  email: string;
  ToLogin: () => void;
};

/**
 * Sign up success
 * @param ToLogin 
 * @returns 
 */
const SignUpSuccess = ({
  // email,
  ToLogin = () => undefined,
}: Props) => {
  const { t: formLang } = useTranslation('form');

  /**
   * Checkmark icon
   * @returns 
   */
  const CheckmarkIcon = () => {
    const containerRef = useRef(null);

    useEffect(() => {
      if (containerRef.current) {
        const anim = lottie.loadAnimation({
          container: containerRef.current,
          renderer: 'svg',
          loop: true,
          autoplay: true,
          animationData: animationData,
        });

        return () => anim.destroy();
      }
    }, []);

    return <div ref={containerRef}></div>;
  };

  return (
    <FormSignUpSuccessStyled>
      <form>
        <div className='CheckmarkIcon'>
          <CheckmarkIcon />
        </div>
        <p className='title'>{formLang('SignUp.SignUpSuccess')}!</p>
        <div className='contentContainer'>
          <span className='content'>{formLang('SignUp.PleaseVisitYourZaloInboxAndClickOnTheVerificationLinkToCompleteTheRegistrationProcess')}</span>
        </div>
        <div className='btnContainer'>
          <Button className='btnCancel' onClick={ToLogin}>{formLang('OnTheHomepage')}</Button>
        </div>
      </form>
    </FormSignUpSuccessStyled>
  );
};

export default SignUpSuccess;
const FormSignUpSuccessStyled = styled.div`
.CheckmarkIcon{
  width: 100%;
  height: 200px;
  display: flex;
  justify-content: center;
}
.title {
  font-size: 28px;
  font-weight: 600;
  line-height: 39px;
  margin: 80px 0 24px;
}
.content {
  font-size: 16px;
  font-weight: 400;
  line-height: 22.4px;
}
.contentContainer {
  width: 502px;
  display: inline-grid;
  margin: 0 0 24px;
  max-width: 100%;
  @media only screen and (max-width: 767.98px) {
    width: 100%;
  }
  .emailContent {
    font-size: 16px;
    font-weight: 700;
    line-height: 22.4px;
  }
}
.btnContainer {
  width: 520px;
  display: inline-flex;
  max-width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  @media only screen and (max-width: 767.98px) {
    width: 100%;
  }
  .btnCancel {
    margin-right: 6px;
    width: 200px;
    border: 1px solid #1B9AAA;
    border-radius: 10px;
    font-family: Inter;
    font-size: 18px;
    font-weight: 600;
    line-height: 25px;
    color: #1B9AAA;
    :hover {
      color: #ffffff;
      background-color: #1B9AAA;
    }
  }
  .checkEmail {
    margin-left: 6px;
    width: 100%;
    border: 1px solid #1B9AAA;
    border-radius: 10px;
    background-color: #1B9AAA;
    color: #FFFFFF;
    font-size: 18px;
    font-weight: 600;
    :hover {
      color: #1B9AAA;
      background-color: #FFFFFF;
      path {
        stroke: #1B9AAA
      }
    }
  }
  svg {
    margin-right: 8px;
  }
}
`;