import IconCustomerCircle from 'assets/svg/IconCustomerCircle';
import IconDiscountCircle from 'assets/svg/IconDiscountCircle';
import IconStarCircle from 'assets/svg/IconStarCircle';
import IconVoucherCircle from 'assets/svg/IconVoucherCircle';
import ChartSales from '../components/ChartSales';
import ChartServices from '../components/ChartServices';
import SalesTable from '../components/SalesTable';
import WorkingTable from '../components/WorkingTable';
import styles from './index.module.scss';
import { Select, message } from 'antd';
import { useState } from 'react';
import analyticActions from '../services/actions';
import { useDispatch } from 'react-redux';
import analyticSelectors from '../services/selectors';
import moment from 'moment';
import downloadFile from 'utils/downloadFile';
import { first, get, last } from 'lodash';
import apisAnalytic from '../services/apis';
import { useSetLoadingPage } from 'services/UI/LoadingPage';
import { Dayjs } from 'dayjs';
import { useRangeDateTime } from 'components/common/GroupFilter/hook/useRangeDate';
import GroupFilterDate from 'components/common/GroupFilter';
import { ISummary } from '../services/types/api';
import { formatMoney } from 'utils/unit';
import { TIME_START_FORMAT_RESPONSE } from 'features/bookings/services/constants';
import { useTranslation } from 'react-i18next';
import RecentAppointments from '../components/RecentAppointments';
import { DATE } from '../types';
import { Link, useSearchParams } from 'react-router-dom';
import AnalyzeCustomerItem from '../components/AnalyzeCustomerItem';
import { analyzeCustomerData } from '../data';
import styled from 'styled-components';
import IconCalenderMobile from 'assets/svg/IconCalenderMobile';
import IconPickList from 'assets/svg/IconPickList';
import IconUserMobileAnalytics from 'assets/svg/IconUserMobileAnalytics';
import { AnalyticPermission, ModulePermission } from 'features/packagePermission/services/types/permission';
import Can from 'features/packagePermission/components/Can';

type MyDivProps = React.HTMLProps<HTMLDivElement> & {
  style?: {
    '--p': number;
  };
};

export interface IOptionAnalyticItem {
  value: 'month' | 'week' | 'year' | 'month_3';
  label: string;
}

/**
 * Analytics page
 * @returns 
 */
const AnalyticsPage = () => {

  const dispatch = useDispatch();
  const setLoadingPage = useSetLoadingPage();
  const [optionDate, setOptionDate] = useState<{ dateStart: string, dateEnd: string }>(() => {
    const dateStart = moment().format('YYYY-MM-DD 00:00:00');
    const dateEnd = moment().format('YYYY-MM-DD 23:59:59');


    dispatch(analyticActions.getAnalytics.fetch({
      start_date: dateStart,
      end_date: dateEnd,
    }));

    return { dateStart: dateStart, dateEnd: dateEnd };
  }
  );

  const [searchParams,] = useSearchParams();
  const [currDate, setCurrDate] = useState(searchParams.get('status') || DATE.WEEK);
  const ddateViewOpts = [
    {
      value: DATE.WEEK,
      label: 'Tuần',
    },
    {
      value: DATE.MONTH,
      label: 'Tháng',
    },
    {
      value: DATE.YEAR,
      label: 'Năm',
    },
  ];

  /**
   * handle select date change
   * @param e 
   */
  const handleSelectDateChange = (e: string) => {
    setCurrDate(e);
    // dispatch(voucherActions.setVoucherListParams.fetch({
    //   status: e !== VOUCHER_STATUS.ALL ? e : undefined
    // }));
  };

  const getRangeDate = useRangeDateTime;

  // const options: IOptionAnalyticItem[] = [
  //   {
  //     value: 'month',
  //     label: 'This month'
  //   },
  //   {
  //     value: 'week',
  //     label: 'This week'
  //   },
  //   {
  //     value: 'year',
  //     label: 'This year'
  //   } 
  // ];


  /**
   * On change option picker
   * @param val 
   */
  const onChangeOptionPicker = (val: string) => {
    const [unit, amount] = val.split('_');
    const [dateStart, dateEnd] = getRangeDate(unit, Number(amount));

    setOptionDate({ dateStart, dateEnd });
    dispatch(analyticActions.getAnalytics.fetch({
      start_date: moment(dateStart).format('YYYY-MM-DD 00:00:00'),
      end_date: moment(dateEnd).format('YYYY-MM-DD 23:59:59'),
    }));
  };

  /**
   * On change date range picker
   */
  const onChangeDateRangePicker = (values: Dayjs[]) => {
    const [start, end] = getRangeDate('day', moment().unix());
    const dateStart = first(values)?.format(TIME_START_FORMAT_RESPONSE) ?? start;
    const dateEnd = last(values)?.format(TIME_START_FORMAT_RESPONSE) ?? end;
    setOptionDate({ dateStart, dateEnd });
    dispatch(analyticActions.getAnalytics.fetch({
      start_date: moment(dateStart).format('YYYY-MM-DD 00:00:00'),
      end_date: moment(dateEnd).format('YYYY-MM-DD 23:59:59'),
    }));
  };

  const cancelledBooking = analyticSelectors.bookingCancel();
  const numberOfBooking = analyticSelectors.booking();
  const getNewCustomer = analyticSelectors.getNewCustomer();
  // const getVoucher = analyticSelectors.getVoucher();
  const revenueService = analyticSelectors.getRevenueService() ?? [];
  // const promotion = analyticSelectors.getPromotion();
  const totalDiscount = analyticSelectors.getTotalDiscount();
  const getAverageSpendCustomer = analyticSelectors.getAverageSpendCustomer();
  const totalSale = analyticSelectors.getTotalSale();


  const cancelledProps: MyDivProps = {
    style: {
      '--p': cancelledBooking.booking_cancel * 100 / cancelledBooking.total,
    },
  };

  const bookingProps: MyDivProps = {
    style: {
      '--p': numberOfBooking.booking_offline * 100 / (numberOfBooking.booking_online + numberOfBooking.booking_offline),
    },
  };

  /**
   * On click export
   * @returns 
   */
  const onClickExport = async () => {
    setLoadingPage(true);

    try {
      const res = await apisAnalytic.exportAnalytic({
        start_date: optionDate.dateStart,
        end_date: optionDate.dateEnd
      });
      const file_export_url = get(res, ['data', 'data', 'file_export'], '');
      if (!file_export_url) return;
      downloadFile(file_export_url);
    } catch (error) {
      const errorText = get(error, 'response.data.error.message', '');
      if (errorText) {
        message.error(errorText);
        return;
      }
      const errors = Object.entries(get(error, 'response.data.errors', {}));
      if (errors.length > 0) {
        errors.map(([, valueArr]) => {
          message.error(get(valueArr, [0], ''));
        });
        return;
      }
      message.error('An error occurred. Please try again');
    } finally {
      setLoadingPage(false);
    }
  };

  /**
   * Data card summary
   */
  const dataCardSummary = [
    {
      ...totalSale,
      icon: <IconCustomerCircle />,
      money: true
    },
    {
      ...getNewCustomer,
      icon: <IconDiscountCircle />,

    },
    {
      ...getAverageSpendCustomer,
      icon: <IconVoucherCircle />,
      money: true
    },
    {
      ...totalDiscount,
      icon: <IconStarCircle />,
      money: true
    }
  ];
  const { t: analyticLang } = useTranslation('analytic');

  /**
   * Card summary
   * @param values 
   * @returns 
   */
  const CardSummary = (values: ISummary) => {
    
    return (
      <li className={`${styles.overview_item}`}>
        <div className={styles.card_info}>
          <p className={styles.info_title}>
            {values.money ? formatMoney(values.total_amount) : values.total_amount}
            {Number(values.percent) !== 0 ?
              <span className={`common-growth ${values.percent < 0 ? 'is-reduce' : values.percent === 0 ? 'no-reduce' : ''}`}>
                {values.percent} %
              </span> : null
            }
          </p>
          <p className={styles.info_text}>{values.content === 'Total Sales' ? analyticLang('TotalSales') : values.content === 'Total Customers' ? analyticLang('TotalCustomers') : values.content === 'Avg Customer Spent' ? analyticLang('AvgCustomerSpent') : analyticLang('TotalDiscount')}</p>
        </div>
        {/* <div>
          {values.icon}
        </div> */}
      </li>
    );
  };
  const queryAddBooking = localStorage.getItem('query-add-booking');

  return (
    <>
      <div className={'private-filter'}>
        {/* <button className='common-btn-export'>Export</button> */}
        <Can module={ModulePermission.ANALYTICS} permission={AnalyticPermission.EXPORT_ANALYTIC}>
          <button className='common-btn-export' onClick={onClickExport}>
            <span>{analyticLang('Export')}</span>
          </button>
        </Can>
        <Select options={[]}/>
        <GroupFilterDate onChangeOptionPicker={onChangeOptionPicker} onChangeDateRangePicker={onChangeDateRangePicker} optionDate={{
          dateEnd: moment(optionDate.dateEnd).unix(),
          dateStart: moment(optionDate.dateStart).unix()
        }} />
        {/* <div className='common-select'>
          <OptionPicker onChange={onChangeOptionPicker} />
        </div>
        <div className='common-select'>
          <DateRangePicker onChange={onChangeDateRangePicker} start_date={optionDate.dateStart} end_date={optionDate.dateEnd}/>
        </div> */}
      </div>
      <section className={styles.section_analytics}>
        <div className={styles.analytics_left}>
          <div className={styles.left_inner}>
            <ul className={styles.analytics_overview}>
              {dataCardSummary.map((o, index) => (
                <CardSummary key={index} {...o} />
              ))}
            </ul>
            <div className={`${styles.analytics_card}`}>
              <div className={styles.analytics_label}>{analyticLang('SalesFigures')}</div>
              <div style={{ maxWidth: '100%' }}>
                <ChartSales />
              </div>
            </div>
            <div className={`${styles.analytics_card}`}>
              <div className={styles.analytics_label}>{analyticLang('CategoryOfSales')}</div>
              <SalesTable />
            </div>
            <div className={`${styles.analytics_card}`}>
              <div className={styles.analytics_label}>{analyticLang('WorkingHours')}</div>
              <WorkingTable />
            </div>
            <div className={`${styles.analytics_card}`}>
              <div className={`${styles.analytics_label} ${styles.recent_appointments}`}>
                <p>Lịch hẹn gần đây</p>
                <Select
                  defaultValue={'purchased'}
                  value={currDate}
                  onChange={handleSelectDateChange}
                  options={ddateViewOpts}
                />
              </div>
              <RecentAppointments />
            </div>
          </div>
        </div>
        <div className={styles.analytics_right}>
          <ShortcutsStyled>
            <div className='title'>
              Phím tắt
            </div>
            <div className='short-item-container'>
              <Link to={`/private/bookings/quick-booking?${queryAddBooking}`}>
                <IconCalenderMobile />
                <p>Đặt lịch hẹn</p>
              </Link>
              <Link to={'/'}>
                <IconPickList />
                <p>Thêm đơn hàng</p>
              </Link>
              <Link to={'/'}>
                <IconUserMobileAnalytics />
                <p>Thêm nhân viên</p>
              </Link>
            </div>
          </ShortcutsStyled>
          <div
            className={`${styles.analytics_services} ${styles.analytics_card}`} >
            <div className={styles.analytics_label}>{analyticLang('Services')}</div>
            <ChartServices revenueServices={revenueService} />
          </div>
          <div
            className={`${styles.analytics_bookings} ${styles.analytics_card}`}
          >
            <div className={styles.analytics_label}>{analyticLang('NumberOfBookings')}</div>
            <div className='pie-wrapper'>
              <div className='pie animate' {...bookingProps}></div>
              <div className='pie-content'>
                <span>{analyticLang('Total')}</span>
                <p>{(numberOfBooking?.booking_online ?? 0) + (numberOfBooking?.booking_offline ?? 0)}</p>
              </div>
            </div>
            <ul className={styles.pie_detail}>
              <li>
                <span>{analyticLang('OnlineBooking')}</span>
                <span>{numberOfBooking?.booking_online ?? 0}</span>
              </li>
              <li>
                <span>{analyticLang('WalkInCustomer')}</span>
                <span>{numberOfBooking?.booking_offline ?? 0}</span>
              </li>
            </ul>
          </div>
          <div
            className={`${styles.analytics_cancel} ${styles.analytics_card}`}
          >
            <div className={styles.analytics_label}>{analyticLang('CancelledBookings')}</div>
            <div className='pie-wrapper is-red'>
              <div className='pie animate' {...cancelledProps}></div>
              <div className='pie-content'>
                <span>{analyticLang('Total')}</span>
                <p>{(cancelledBooking?.booking_cancel ?? 0) + (cancelledBooking?.booking_no_show ?? 0) + (cancelledBooking?.booking_refund ?? 0)}</p>
              </div>
            </div>
            <ul className={`${styles.pie_detail} ${styles.is_red}`}>
              <li>
                <span>{analyticLang('Cancel&Refund')}</span>
                <span>{(cancelledBooking?.booking_cancel ?? 0) + (cancelledBooking?.booking_refund ?? 0)}</span>
              </li>
              <li>
                <span>{analyticLang('NoShow')}</span>
                <span>{cancelledBooking?.booking_no_show ?? 0}</span>
              </li>
            </ul>
          </div>
          <div 
            className={`${styles.analytics_cancel} ${styles.analytics_card}`}
          >
            <div className={styles.analytics_label}>
              <p>Phân tích hành vi khách hàng</p>
              <p className={styles.analytics_label_content}><IconInfor />Các khách hàng đã lâu chưa trở lại</p>
            </div>
            {analyzeCustomerData.map((o, index) => (
              <div key={index} className={`${styles.analyze_customer_item} ${index !== analyzeCustomerData.length - 1 ? styles.border_bottom : ''}`}>
                <AnalyzeCustomerItem {...o} />
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default AnalyticsPage;

const ShortcutsStyled = styled.div`
  background: #FFF;
  padding: 16px;
  display: grid;
  gap: 24px;
  border-radius: 16px;

  .title {
    color: var(--Gray-95, #141414);
  font-family: Inter;
  font-size: 20px;
  font-weight: 700;
  line-height: 140%;
  }

  .short-item-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    a {
      display: grid;
      gap: 12px;
      align-items: center;
      justify-content: center;
      justify-items: center;
    }
  }
`;

export const IconInfor = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
      <path opacity="0.4" d="M17.3333 9.21956C17.3333 13.8219 13.6023 17.5529 8.99996 17.5529C4.39759 17.5529 0.666626 13.8219 0.666626 9.21956C0.666626 4.61719 4.39759 0.88623 8.99996 0.88623C13.6023 0.88623 17.3333 4.61719 17.3333 9.21956Z" fill="#FF6F00" />
      <path fillRule="evenodd" clipRule="evenodd" d="M9 5.26123C9.34518 5.26123 9.625 5.54105 9.625 5.88623V6.71956C9.625 7.06474 9.34518 7.34456 9 7.34456C8.65482 7.34456 8.375 7.06474 8.375 6.71956V5.88623C8.375 5.54105 8.65482 5.26123 9 5.26123ZM9 8.1779C9.34518 8.1779 9.625 8.45772 9.625 8.8029V12.5529C9.625 12.8981 9.34518 13.1779 9 13.1779C8.65482 13.1779 8.375 12.8981 8.375 12.5529V8.8029C8.375 8.45772 8.65482 8.1779 9 8.1779Z" fill="#FF6F00" />
    </svg>
  );
};