import React from 'react';

const IconInfo = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="Icon/Info">
                <g id="Group 219">
                    <path id="Vector" d="M12.3535 21.6797C17.8764 21.6797 22.3535 17.2025 22.3535 11.6797C22.3535 6.15684 17.8764 1.67969 12.3535 1.67969C6.83067 1.67969 2.35352 6.15684 2.35352 11.6797C2.35352 17.2025 6.83067 21.6797 12.3535 21.6797Z" stroke="#FF6F00"/>
                    <path id="Vector_2" d="M12.3535 15.6797V11.6797" stroke="#FF6F00" />
                    <path id="Vector_3" d="M12.3535 7.67969H12.3635" stroke="#FF6F00"/>
                </g>
            </g>
        </svg>

    );
};

export default IconInfo;