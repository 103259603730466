import { Select } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import userSelectors from '../services/selectors';
import { useDispatch } from 'react-redux';
import userActions from '../services/actions';
import { useTranslation } from 'react-i18next';

export type IFilterLocation = {
    value?: any;
    label?: any;
};

const LocationsFilter = () => {
    const getShowCreateStaffs = userSelectors.getShowCreateStaffs();
    const locationOptions = getShowCreateStaffs?.merchant_locations;
    const params = userSelectors.getParams();
    const { t: userLang } = useTranslation('user');
    const dispatch = useDispatch();
    useEffect(() => {
        if (locationOptions) {
            return;
        }
    }, [locationOptions]);


    const [translatedAllLocations, setTranslatedAllLocations] = useState(userLang('AllLocations'));

    useEffect(() => {
        const storedLanguage = localStorage.getItem('selectedLanguage');
        const translatedLabel = storedLanguage ? userLang('AllLocations') : 'AllLocations';
        const initialSelectedLocation = { value: storedLanguage || 'all', label: translatedLabel };
        setSelectedLocation(initialSelectedLocation);
        setTranslatedAllLocations(userLang('AllLocations'));
    }, [userLang]);

    const location = useMemo(() => {
        if (!locationOptions) return [];
        const translatedLabel = translatedAllLocations;
        const result = locationOptions?.map((o: { value: string; label: string }) => ({ value: o.value, label: o.label }));
        result?.unshift({
            value: 'all',
            label: translatedLabel,
        });
        return result;
    }, [locationOptions, translatedAllLocations]);
    const [selectedLocation, setSelectedLocation] = useState<IFilterLocation>({ value: 'all', label: translatedAllLocations });

    const handleLocationChange = (value: any) => {
        const selectedLocation = location.find((loc: { value: any; }) => loc.value === value);
        setSelectedLocation(selectedLocation);
        if (selectedLocation) {

            // userApis.getSearchLocation(selectedLocation.value);
            dispatch(userActions.getListPaginateStaffs.fetch({
                ...params,
                merchant_location_id: selectedLocation.value === 'all' ? '' : selectedLocation.value,
            }));

        }
    };

    return (
        <div className="common-select">
            <Select
                value={selectedLocation}
                options={location}
                onChange={handleLocationChange}
            />
        </div>
    );
};

export default LocationsFilter;