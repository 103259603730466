import InputSearchText from 'components/common/Input/InputSearchDebounce';
import TimeScheduleTable from '../components/TimeScheduleTable';
import { useEffect, useRef, useState } from 'react';
import TimeOffModal from '../widgets/TimeOffModal';
import timeScheduleSelectors from '../services/selectors';
import { useAppDispatch } from 'store/hooks';
import timeScheduleActions from '../services/actions';
import TimeScheduleDateView from '../widgets/TableList/TimeScheduleDateView';
import LocationPicker from '../widgets/TableList/LocationPicker';
import TeamMemberSelect from '../widgets/TableList/TeamMemberSelect';
import { useSetLoadingPage } from 'services/UI/LoadingPage';
import apisTimeSchedule from '../services/api';
import { IApiAddDateOff, IApiTimeScheduleListReq } from '../types/api';
import { message } from 'antd';
import { TimeOffModalType } from '../types/timeSchedule';
import styled from 'styled-components';
import { displayError } from '../utils/displayError';
import moment from 'moment';
import getArrayDatesOfWeek from 'utils/moment/getDatesOfWeek';
import { useTranslation } from 'react-i18next';

const TimeSchedulePage = () => {
  const [isTimeOffModalOpen, setIsTimeOffModalOpen] = useState(false);
  const params = timeScheduleSelectors.getParams();
  const dispatch = useAppDispatch();
  const searchTime = useRef<number>(0);
  const setLoadingPage = useSetLoadingPage();
  const locationValue = (timeScheduleSelectors.getTimeScheduleParamValue(
    'merchant_location_id'
  ) ?? 'All') as IApiTimeScheduleListReq['merchant_location_id'];

  useEffect(() => {
    const currentDate = moment();

    if (currentDate.isoWeekday() === 7) {
      currentDate.subtract(1, 'day');
    }
    const _daysOfWeek = getArrayDatesOfWeek(currentDate.format());
    dispatch(timeScheduleActions.setCalendarDaysOfWeek(_daysOfWeek));
    
    dispatch(timeScheduleActions.getInit.fetch());
  }, []);

  useEffect(() => {
    if (locationValue) {
      dispatch(timeScheduleActions.getLocationDetail.fetch(locationValue.toString()));
    }
  }, [locationValue]);

  const handleSearchTimeSchedule = (value: string) => {
    if (searchTime.current === 1) {
      const _params = {
        ...params,
        keyword: value || undefined,
        page: 1,
      };
      dispatch(timeScheduleActions.setListTimeScheduleParams(_params));
    }

    if (!searchTime.current && !value?.length) {
      searchTime.current = 1;
    }
  };

  const handleOpenTimeOffModal = () => {
    setIsTimeOffModalOpen(true);
  };

  const handleCloseTimeOffModal = () => {
    setIsTimeOffModalOpen(false);
  };

  const handleAddTimeOff = async (payload: IApiAddDateOff) => {
    setLoadingPage(true);
    try {
      const rest = await apisTimeSchedule.createDayOff(payload);
      const msg = rest?.data?.message;

      if (msg) {
        handleCloseTimeOffModal();
        dispatch(
          timeScheduleActions.setListTimeScheduleParams({
            page: 1,
          })
        );
        message.success(msg);
        setLoadingPage(false);
        return true;
      } else {
        const msgError = rest?.data?.error?.message;
        if (msgError) message.error(msgError);
        else throw 'fail';
        setLoadingPage(false);
        return false;
      }
    } catch (error: any) {
      const err = displayError(error);
      message.error(err);
      setLoadingPage(false);
      return false;
    }
  };
  const {t: formLang} = useTranslation('form');
  return (
    <TimeSchedulePageStyled>
      <div className={'private-filter'}>
        <InputSearchText
          value={params?.keyword ?? ''}
          onSearchText={handleSearchTimeSchedule}
        />
        <div className='common-select'>
          <LocationPicker />
        </div>
        <div className='common-select'>
          <TeamMemberSelect />
        </div>
        <div className='heading-date'>
          <TimeScheduleDateView />
        </div>
        <button className={'common-btn'} onClick={handleOpenTimeOffModal}>
          {formLang('AddDayOff')}
        </button>
      </div>
      <TimeScheduleTable handleAddTimeOff={handleAddTimeOff} />
      {isTimeOffModalOpen ? (
        <TimeOffModal
          handleClose={handleCloseTimeOffModal}
          handleSubmit={handleAddTimeOff}
          isModalOpen={isTimeOffModalOpen}
          modalType={TimeOffModalType.CREATE}
        />
      ) : null}
    </TimeSchedulePageStyled>
  );
};

const TimeSchedulePageStyled = styled.div`
  @media screen and (max-width: 643px) {
    .heading-date {
      width: 100%;
      .ant-space {
        width: 100%;
        .ant-space-item {
          width: 100%;
          .ant-picker {
            width: 100%;
            .ant-picker-input {
              display: flex;
              justify-content: center;
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 413px) {
    .common-select {
      width: 100%;

      .ant-select {
        width: 100%;
      }
    }
  }
`;

export default TimeSchedulePage;
