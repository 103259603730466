import { Button, Checkbox, Input, Switch } from 'antd';
import { IconRight } from 'assets/svg';
import { PATH } from 'constants/path.enum';
import React, { useEffect, useState } from 'react';
import { NavLink, useParams } from 'react-router-dom';
import styled from 'styled-components';

const RolesDetail = () => {
  const { name } = useParams();

  // Booking checkbox
  const [isCheckedAll, setIsCheckedAll] = useState(false);
  const [isCheckedbooking, setIsCheckedBooking] = useState(false);
  const [isCheckedPaymenBooking, setIsCheckedPaymenBooking] = useState(false);
  const [isCheckedEditBooking, setIsCheckedEditbooking] = useState(false);
  const [isCheckedRefundBooking, setIsCheckedRefundbooking] = useState(false);

  const handleCheckboxBookingChange = () => {
    const newState = !isCheckedAll;
    setIsCheckedAll(newState);
    setIsCheckedBooking(newState);
    setIsCheckedPaymenBooking(newState);
    setIsCheckedEditbooking(newState);
    setIsCheckedRefundbooking(newState);
  };
  const handleCheckedBookingChange = () => {
    setIsCheckedBooking(!isCheckedbooking);
    updateCheckboxState();
  };
  const handleCheckedPaymenBookingChange = () => {
    setIsCheckedPaymenBooking(!isCheckedPaymenBooking);
    updateCheckboxState();
  };
  const handleCheckedEditBookingChange = () => {
    setIsCheckedEditbooking(!isCheckedEditBooking);
    updateCheckboxState();
  };
  const handleCheckedRefundBookingChange = () => {
    setIsCheckedRefundbooking(!isCheckedRefundBooking);
    updateCheckboxState();
  };
  const updateCheckboxState = () => {
    setIsCheckedAll(
      isCheckedbooking && isCheckedPaymenBooking && isCheckedEditBooking && isCheckedRefundBooking
    );
  };

  useEffect(() => {
    updateCheckboxState();
  }, [isCheckedbooking, isCheckedPaymenBooking, isCheckedEditBooking, isCheckedRefundBooking]);

  // Endow checkbox
  const [isEndowAll, setIsEndowAll] = useState(false);
  const [isAddVoucher, setIsAddVoucher] = useState(false);
  const [isEditDeleteVoucher, setIsEditDeleteVoucher] = useState(false);
  const [isBuyCodeVoucher, setIsBuyCodeVoucher] = useState(false);
  const [isAddPromotions, setIsAddPromotions] = useState(false);
  const [isEditDeletePromotions, setIsEditDeletePromotions] = useState(false);

  const handleCheckboxEndowAllChange = () => {
    const newState = !isEndowAll;
    setIsEndowAll(newState);
    setIsAddVoucher(newState);
    setIsEditDeleteVoucher(newState);
    setIsBuyCodeVoucher(newState);
    setIsAddPromotions(newState);
    setIsEditDeletePromotions(newState);
  };
  const handleCheckedAddVoucherChange = () => {
    setIsAddVoucher(!isAddVoucher);
    updateCheckboxEndowAllState();
  };
  const handleCheckedEditDeleteVoucherChange = () => {
    setIsEditDeleteVoucher(!isEditDeleteVoucher);
    updateCheckboxEndowAllState();
  };
  const handleCheckedBuyCodeVoucherChange = () => {
    setIsBuyCodeVoucher(!isBuyCodeVoucher);
    updateCheckboxEndowAllState();
  };
  const handleCheckedAddPromotionsChange = () => {
    setIsAddPromotions(!isAddPromotions);
    updateCheckboxEndowAllState();
  };
  const handleCheckedEditDeletePromotions = () => {
    setIsEditDeletePromotions(!isEditDeletePromotions);
    updateCheckboxEndowAllState();
  };
  const updateCheckboxEndowAllState = () => {
    setIsEndowAll(
      isAddVoucher && isEditDeleteVoucher && isBuyCodeVoucher && isAddPromotions && isEditDeletePromotions
    );
  };

  useEffect(() => {
    updateCheckboxEndowAllState();
  }, [isAddVoucher, isEditDeleteVoucher, isBuyCodeVoucher, isAddPromotions, isEditDeletePromotions]);

  // Paymen checkbox
  const [isPaymenAll, setIsPaymenAll] = useState(false);
  const [isEditPaymen, setIsEditPaymen] = useState(false);
  const [isViewRevenue, setIsViewRevenue] = useState(false);

  const handleCheckboxPaymenAllChange = () => {
    const newState = !isPaymenAll;
    setIsPaymenAll(newState);
    setIsEditPaymen(newState);
    setIsViewRevenue(newState);
  };
  const handleCheckedEditPaymenChange = () => {
    setIsEditPaymen(!isEditPaymen);
    updateCheckboxPaymenAllState();
  };
  const handleCheckedViewRevenueChange = () => {
    setIsViewRevenue(!isViewRevenue);
    updateCheckboxPaymenAllState();
  };
  const updateCheckboxPaymenAllState = () => {
    setIsPaymenAll(
      isEditPaymen && isViewRevenue
    );
  };

  useEffect(() => {
    updateCheckboxPaymenAllState();
  }, [isEditPaymen, isViewRevenue]);

  // Customer Checkbox
  const [isCustomerAll, setIsCustomerAll] = useState(false);
  const [isViewCustomer, setIsViewCustomer] = useState(false);
  const [isEditCustomer, setIsEditEditCustomer] = useState(false);
  const [isDeleteCustomer, setIsDeleteCustomer] = useState(false);
  const [isEditPoint, setIsEditPoint] = useState(false);
  const [isChangeCustomer, setIsChangeCustomer] = useState(false);

  const handleCheckboxCustomerChange = () => {
    const newState = !isCustomerAll;
    setIsCustomerAll(newState);
    setIsViewCustomer(newState);
    setIsEditEditCustomer(newState);
    setIsDeleteCustomer(newState);
    setIsEditPoint(newState);
    setIsChangeCustomer(newState);
  };
  const handleCheckedViewCustomerChange = () => {
    setIsViewCustomer(!isViewCustomer);
    updateCheckboxCustomerState();
  };
  const handleCheckedEditCustomerChange = () => {
    setIsEditEditCustomer(!isEditCustomer);
    updateCheckboxCustomerState();
  };
  const handleCheckedDeleteCustomerChange = () => {
    setIsDeleteCustomer(!isDeleteCustomer);
    updateCheckboxCustomerState();
  };
  const handleCheckedEditPointChange = () => {
    setIsEditPoint(!isEditPoint);
    updateCheckboxCustomerState();
  };
  const handleCheckedChangeCustomerChange = () => {
    setIsChangeCustomer(!isChangeCustomer);
    updateCheckboxCustomerState();
  };
  const updateCheckboxCustomerState = () => {
    setIsCustomerAll(
      isViewCustomer && isEditCustomer && isDeleteCustomer && isEditPoint && isChangeCustomer
    );
  };

  useEffect(() => {
    updateCheckboxCustomerState();
  }, [isViewCustomer, isEditCustomer, isDeleteCustomer, isEditPoint, isChangeCustomer]);

  // Service checkbox
  const [isServiceAll, setIsServiceAll] = useState(false);
  const [isViewService, setIsViewService] = useState(false);
  const [isEditService, setIsEditEditService] = useState(false);
  const [isViewSchedule, setIsViewSchedule] = useState(false);
  const [isUpdateSchedule, setIsUpdateSchedule] = useState(false);
  const [isEditSchedule, setIsEditSchedule] = useState(false);

  const handleCheckboxServiceChange = () => {
    const newState = !isServiceAll;
    setIsServiceAll(newState);
    setIsViewService(newState);
    setIsEditEditService(newState);
    setIsViewSchedule(newState);
    setIsUpdateSchedule(newState);
    setIsEditSchedule(newState);
  };
  const handleCheckedViewServiceChange = () => {
    setIsViewService(!isViewService);
    updateCheckboxServiceState();
  };
  const handleCheckedEditServiceChange = () => {
    setIsEditEditService(!isEditService);
    updateCheckboxServiceState();
  };
  const handleCheckedViewScheduleChange = () => {
    setIsViewSchedule(!isViewSchedule);
    updateCheckboxServiceState();
  };
  const handleCheckedUpdateScheduleChange = () => {
    setIsUpdateSchedule(!isUpdateSchedule);
    updateCheckboxServiceState();
  };
  const handleCheckedEditScheduleChange = () => {
    setIsEditSchedule(!isEditSchedule);
    updateCheckboxServiceState();
  };
  const updateCheckboxServiceState = () => {
    setIsServiceAll(
      isViewService && isEditService && isViewSchedule && isUpdateSchedule && isEditSchedule
    );
  };

  useEffect(() => {
    updateCheckboxServiceState();
  }, [isViewService, isEditService, isViewSchedule, isUpdateSchedule, isEditSchedule]);

  // Personnel Checkbox
  const [isPersonnelAll, setIsPersonnelAll] = useState(false);
  const [isViewWorkSchedule, setIsViewWorkSchedule] = useState(false);
  const [isWorkScheduleManagement, setIsWorkScheduleManagement] = useState(false);
  const [isViewVacationSchedule, setIsViewVacationSchedule] = useState(false);
  const [isBrowseVacationSchedule, setIsBrowseVacationSchedule] = useState(false);

  const handleCheckboxPersonnelChange = () => {
    const newState = !isPersonnelAll;
    setIsPersonnelAll(newState);
    setIsViewWorkSchedule(newState);
    setIsWorkScheduleManagement(newState);
    setIsViewVacationSchedule(newState);
    setIsBrowseVacationSchedule(newState);
  };
  const handleCheckedWorkScheduleChange = () => {
    setIsViewWorkSchedule(!isViewWorkSchedule);
    updateCheckboxPersonnelState();
  };
  const handleCheckedWorkScheduleManagementChange = () => {
    setIsWorkScheduleManagement(!isWorkScheduleManagement);
    updateCheckboxPersonnelState();
  };
  const handleCheckedViewVacationScheduleChange = () => {
    setIsViewVacationSchedule(!isViewVacationSchedule);
    updateCheckboxPersonnelState();
  };
  const handleCheckedBrowseVacationScheduleChange = () => {
    setIsBrowseVacationSchedule(!isBrowseVacationSchedule);
    updateCheckboxPersonnelState();
  };
  const updateCheckboxPersonnelState = () => {
    setIsPersonnelAll(
      isViewWorkSchedule && isWorkScheduleManagement && isViewVacationSchedule && isBrowseVacationSchedule
    );
  };

  useEffect(() => {
    updateCheckboxPersonnelState();
  }, [isViewWorkSchedule, isWorkScheduleManagement, isViewVacationSchedule, isBrowseVacationSchedule]);

  return (
    <FormRolesNewEditStyled>
      <>
        <div className='rolesDetail'>
          <div className='btnContainer'>
            <NavLink
              to={PATH.RolesAndDecentralization}
              className={'iconRight'}
            >
              <IconRight />
            </NavLink>
            {name === 'new' ? <Button className='btnConfirm'>Xác nhận</Button> : ''}
          </div>
          <form className='formRoloes'>
            <div className='rolesContainer'>
              <p className='titleRoles'>Các phân quyền</p>
              <span className='contentRoles'>Admin là vai trò quan trọng trong hệ thống MindHana, nên vui lòng không cung cấp tài khoản Admin cho bất kì ai khác.</span>
              <div className='rolesInput'>
                <p className='title'>Vai trò (*)</p>
                <Input className='inputName' placeholder='Nhập vai trò' type='text' defaultValue={name === 'new' ? '' : name} />
              </div>
              <div className='contentContainer'>
                <div className='content'>
                  <span className='titleCheckbox'>Lịch hẹn</span>
                  <div>
                    <Checkbox checked={isCheckedAll} onChange={handleCheckboxBookingChange} /> <span className='titleSpan'>Chọn tất cả</span>
                  </div>
                </div>
                <div className='content'>
                  <span className='titleSpan'>Tạo lịch hẹn</span>
                  <Switch checked={isCheckedbooking} onChange={handleCheckedBookingChange} />
                </div>
                <div className='content'>
                  <span className='titleSpan'>Thanh toán lịch hẹn</span>
                  <Switch checked={isCheckedPaymenBooking} onChange={handleCheckedPaymenBookingChange} />
                </div>
                <div className='content'>
                  <span className='titleSpan' >Chỉnh/xoá sửa lịch hẹn</span>
                  <Switch checked={isCheckedEditBooking} onChange={handleCheckedEditBookingChange} />
                </div>
                <div className='content'>
                  <span className='titleSpan' >Hoàn tiền lịch hẹn</span>
                  <Switch checked={isCheckedRefundBooking} onChange={handleCheckedRefundBookingChange} />
                </div>
              </div>

              <div className='contentContainer'>
                <div className='content'>
                  <span className='titleCheckbox'>Ưu đãi</span>
                  <div>
                    <Checkbox checked={isEndowAll} onChange={handleCheckboxEndowAllChange} /> <span className='titleSpan'>Chọn tất cả</span>
                  </div>
                </div>
                <div className='content'>
                  <span className='titleSpan'>Tạo mã voucher</span>
                  <Switch checked={isAddVoucher} onChange={handleCheckedAddVoucherChange} />
                </div>
                <div className='content'>
                  <span className='titleSpan'>Chỉnh sửa/xoá mã voucher</span>
                  <Switch checked={isEditDeleteVoucher} onChange={handleCheckedEditDeleteVoucherChange} />
                </div>
                <div className='content'>
                  <span className='titleSpan'>Bán mã voucher</span>
                  <Switch checked={isBuyCodeVoucher} onChange={handleCheckedBuyCodeVoucherChange} />
                </div>
                <div className='content'>
                  <span className='titleSpan'>Tạo chương trình khuyến mãi</span>
                  <Switch checked={isAddPromotions} onChange={handleCheckedAddPromotionsChange} />
                </div>
                <div className='content'>
                  <span className='titleSpan'>Chỉnh sửa/xoá chương trình khuyến mãi</span>
                  <Switch checked={isEditDeletePromotions} onChange={handleCheckedEditDeletePromotions} />
                </div>
              </div>

              <div className='contentContainer'>
                <div className='content'>
                  <span className='titleCheckbox'>Thanh toán</span>
                  <div>
                    <Checkbox checked={isPaymenAll} onChange={handleCheckboxPaymenAllChange} /> <span className='titleSpan'>Chọn tất cả</span>
                  </div>
                </div>
                <div className='content'>
                  <span className='titleSpan'>Chỉnh sửa phương thức thanh toán</span>
                  <Switch checked={isEditPaymen} onChange={handleCheckedEditPaymenChange} />
                </div>
                <div className='content'>
                  <span className='titleSpan'>Xem báo cáo doanh thu</span>
                  <Switch checked={isViewRevenue} onChange={handleCheckedViewRevenueChange} />
                </div>
              </div>

              <div className='contentContainer'>
                <div className='content'>
                  <span className='titleCheckbox'>Khách hàng</span>
                  <div>
                    <Checkbox checked={isCustomerAll} onChange={handleCheckboxCustomerChange} /> <span className='titleSpan'>Chọn tất cả</span>
                  </div>
                </div>
                <div className='content'>
                  <span className='titleSpan'>Xem thông tin khách hàng</span>
                  <Switch checked={isViewCustomer} onChange={handleCheckedViewCustomerChange} />
                </div>
                <div className='content'>
                  <span className='titleSpan'>Chỉnh sửa khách hàng</span>
                  <Switch checked={isEditCustomer} onChange={handleCheckedEditCustomerChange} />
                </div>
                <div className='content'>
                  <span className='titleSpan'>Xoá khách hàng</span>
                  <Switch checked={isDeleteCustomer} onChange={handleCheckedDeleteCustomerChange} />
                </div>
                <div className='content'>
                  <span className='titleSpan'>Chỉnh sửa điểm tích luỹ</span>
                  <Switch checked={isEditPoint} onChange={handleCheckedEditPointChange} />
                </div>
                <div className='content'>
                  <span className='titleSpan'>Chuyển đổi khách tiềm năng sang khách hàng</span>
                  <Switch checked={isChangeCustomer} onChange={handleCheckedChangeCustomerChange} />
                </div>
              </div>

              <div className='contentContainer'>
                <div className='content'>
                  <span className='titleCheckbox'>Dịch vụ</span>
                  <div>
                    <Checkbox checked={isServiceAll} onChange={handleCheckboxServiceChange} /> <span className='titleSpan'>Chọn tất cả</span>
                  </div>
                </div>
                <div className='content'>
                  <span className='titleSpan'>Xem dịch vụ</span>
                  <Switch checked={isViewService} onChange={handleCheckedViewServiceChange} />
                </div>
                <div className='content'>
                  <span className='titleSpan'>Chỉnh sửa/xoá dịch vụ</span>
                  <Switch checked={isEditService} onChange={handleCheckedEditServiceChange} />
                </div>
                <div className='content'>
                  <span className='titleSpan'>Xem liệu trình</span>
                  <Switch checked={isViewSchedule} onChange={handleCheckedViewScheduleChange} />
                </div>
                <div className='content'>
                  <span className='titleSpan'>Cập nhật liệu trình</span>
                  <Switch checked={isUpdateSchedule} onChange={handleCheckedUpdateScheduleChange} />
                </div>
                <div className='content'>
                  <span className='titleSpan'>Chỉnh sửa/xoá liệu trình</span>
                  <Switch checked={isEditSchedule} onChange={handleCheckedEditScheduleChange} />
                </div>
              </div>

              <div className='contentContainer'>
                <div className='content'>
                  <span className='titleCheckbox'>Đội ngũ</span>
                  <div>
                    <Checkbox checked={isPersonnelAll} onChange={handleCheckboxPersonnelChange} /> <span className='titleSpan'>Chọn tất cả</span>
                  </div>
                </div>
                <div className='content'>
                  <span className='titleSpan'>Xem lịch làm việc</span>
                  <Switch checked={isViewWorkSchedule} onChange={handleCheckedWorkScheduleChange} />
                </div>
                <div className='content'>
                  <span className='titleSpan'>Quản lý lịch làm việc</span>
                  <Switch checked={isWorkScheduleManagement} onChange={handleCheckedWorkScheduleManagementChange} />
                </div>
                <div className='content'>
                  <span className='titleSpan'>Xem lịch nghỉ phép</span>
                  <Switch checked={isViewVacationSchedule} onChange={handleCheckedViewVacationScheduleChange} />
                </div>
                <div className='content'>
                  <span className='titleSpan'>Duyệt lịch nghỉ phép</span>
                  <Switch checked={isBrowseVacationSchedule} onChange={handleCheckedBrowseVacationScheduleChange} />
                </div>
              </div>
            </div>
          </form>
        </div>
      </>
    </FormRolesNewEditStyled>
  );
};

export default RolesDetail;
const FormRolesNewEditStyled = styled.div`
.rolesDetail {
    background: #FFFFFF;
    padding: 24px;
    border-radius: 16px;
    max-height: 100%;
}
.btnContainer {
    display: flex;
    justify-content: end;
    align-items: center;
    margin-bottom: 8px;
    .iconRight {
        transform: rotate(180deg);
    }
    .btnConfirm {
        border-radius: 10px;
        border: 1px solid #1B9AAA;
        background: #1B9AAA;
        color: #ffffff;
        font-size: 18px;
        font-weight: 500;
        opacity: 50%;
        margin-left: 8px;
    }
    }
}
.formRoloes {
    display: flex;
    justify-content: center;
    .rolesContainer {
        width: 500px;
        border-radius: 12px;
        padding: 24px;
        border: 1px solid #8C8C8C;
        .titleRoles {
            font-size: 20px;
            font-weight: 700;
            margin-bottom: 8px;
        }
        .contentRoles {
            font-size: 14px;
            color: #8C8C8C;
        }
        .rolesInput {
            margin-top: 24px;
            .title {
                font-size: 16px;
                font-weight: 600;
            }
            .inputName {
                margin-top: 8px;
                height: 48px;
            }
        }
        .contentContainer {
            padding: 25px 0;
            .content {
                display: flex;
                justify-content: space-between;
                margin: 20px 0;
                .titleCheckbox {
                    font-size: 16px;
                    font-weight: 700;
                }
                .titleSpan {
                    font-size: 16px;
                    font-weight: 600;
                }
            }
        }
    }
}
`;