
import IconFilter from 'assets/svg/IconFilter';
import bookingSelectors from 'features/bookings/services/selectors';
import { IApiBookingParams } from 'features/bookings/services/types/booking';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import getAddBookingLink from './getAddBookingLink';
import FilterModal from './widgets/FilterModal';
import SelectLayout from './widgets/SelectLayout';
import TableListFilter from './widgets/TableList';
import useDetachScreen from 'hooks/useDetachScreen';
import IconTimeAndDate from 'assets/svg/IconTimeAndDate';
import { useTranslation } from 'react-i18next';
function ListFilter() {
  const navigate = useNavigate();
  const dateStore = bookingSelectors.getCalendarParamValue('date') as number;
  const merchant_location_id = (bookingSelectors.getCalendarParamValue('merchant_location_id') ?? 'all') as IApiBookingParams['merchant_location_id'];
  const onAdd = () => {
    navigate(getAddBookingLink(dateStore, merchant_location_id));
  };
  

  const { isMobile } = useDetachScreen();
  if (isMobile) return <MobileView onAdd={onAdd} />;

  return (
    <BrowserView onAdd={onAdd} />
  );
}

export default ListFilter;

type IChildViewProps = {
  onAdd?: () => void;
};
const BrowserView = ({ onAdd = () => undefined }: IChildViewProps) => {
  const {t: commonLang} = useTranslation('common');
  return (
    <BrowserViewStyled className='BrowserViewStyled'>
      <div className='block-left'></div>
      <div className='block-right'>
        <div className='d-flex no-wrap'>
          <TableListFilter.InputSearchText />
          <div className='desktop-view'>
            <TableListFilter.LocationPicker />
          </div>
          <div className='desktop-view'>
            <TableListFilter.StatusPicker />
          </div>
          <SelectLayout />
        </div>
        <div className='quick-booking-btn'>
          <button
            className={'common-btn'}
            onClick={onAdd}
          >
            <p>{commonLang('AddBooking')}</p>
          </button>
        </div>
      </div>

    </BrowserViewStyled>
  );
};
const BrowserViewStyled = styled.div`
  width: 100%;
  display: flex;
`;

const MobileView = ({ onAdd = () => undefined }: IChildViewProps) => {
  const [isShowModal, setIsShowModal] = useState<boolean>(false);
  const {t: commonLang} = useTranslation('common');
  return (
    <MobileViewStyled className='MobileViewStyled'>
      <div className='block-right'>
        <div className='d-flex no-wrap'>
          <button
            className='filter-btn tablet-mobile-view'
            onClick={() => setIsShowModal(true)}
          >
            <IconFilter />
          </button>
          <div className='desktop-view'>
            <TableListFilter.LocationPicker />
          </div>
          <div className='desktop-view'>
            <TableListFilter.StatusPicker />
          </div>
          <SelectLayout />
        </div>
        <div className='quick-booking-btn'>
          <button
            className={'common-btn'}
            onClick={onAdd}
          >
            <p>{commonLang('AddBooking')}</p>
            <IconTimeAndDate/>
          </button>
        </div>
      </div>
      <TableListFilter.InputSearchText />
      <FilterModal isShow={isShowModal} onClose={() => setIsShowModal(false)} />
    </MobileViewStyled>
  );
};

const MobileViewStyled = styled.div`
  width:100%;
  display: flex;
  flex-direction: column;
  gap:12px;
  .common-input-search {
    width:100%;
    input {
      width:100%;
    }
  }
`;