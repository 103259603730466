import { Form, Input, Modal, ModalProps, Radio } from 'antd';
import FormRow from 'components/common/Form/FormRow';
import PhoneNumberInput from 'components/common/PhoneNumberInput';
import { ICustomerResData } from 'features/checkout/services/types/bookingDetail';
import { IVoucherForSalesSelectedItem } from 'features/checkout/services/types/voucher';
import { ICustomerItemResData } from 'features/customers/services/types/cutomer';
// import { ICustomerItemResData } from 'features/customers/services/types/cutomer';
import { IVoucherDataItem } from 'features/sales/services/types/voucher';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
// import NumberValidations from 'utils/validations/number';

interface ModalVoucherSaleProps extends ModalProps {
  customer: ICustomerResData | ICustomerItemResData | null,
  handleChooseVoucher: (voucher: IVoucherForSalesSelectedItem) => void,
  data: IVoucherDataItem,
  onClose: () => void
}

enum EVoucherType {
  FOR_THEMSELVES = 'for_themselves',
  FOR_FRIEND = 'for_friend'
}
interface IVoucherSaleForm {
  customer_name: string;
  email: string;
  voucherType: EVoucherType;
  phone_number: string;
}

/**
 * Modal voucher sale
 * @param customer, handleChooseVoucher, data, onClose, ...props 
 * @returns 
 */
const ModalVoucherSale = ({ customer, handleChooseVoucher, data, onClose, ...props }: ModalVoucherSaleProps) => {

  const [form] = Form.useForm();

  const [voucherType, setVoucherType] = useState<EVoucherType>(EVoucherType.FOR_THEMSELVES);

  const { t: salesLang } = useTranslation('sales');
  const {t: formLang} = useTranslation('form');

  /**
   * on submit
   * @param value 
   */
  const onSubmit = (value: IVoucherSaleForm) => {

    const isGift = value.voucherType === EVoucherType.FOR_FRIEND;

    const voucher: IVoucherForSalesSelectedItem = {
      ...data,
      quantity: 1,
      is_gift: isGift,
      receiver_info: isGift ? {
        receiver_email: value?.email,
        receiver_phone: value?.phone_number,
        receiver_name: value?.customer_name
      } : undefined

    };

    handleChooseVoucher(voucher);
    onClose();
  };

  useEffect(() => {
    if (!customer) return;

    if (voucherType === EVoucherType.FOR_THEMSELVES) {
      form.setFieldsValue({
        customer_name: customer?.name,
        phone_number: customer?.phone_number,
        email: customer?.email
      });
      return;
    }

    form.setFieldsValue({
      customer_name: '',
      phone_number: '',
      email: ''
    });
  }, [voucherType, customer]);


  return (
    <Modal
      maskClosable={false}
      okButtonProps={{ style: { display: 'none' } }}
      width={574}
      footer={null}
      centered
      onCancel={onClose}
      forceRender {...props}>
      <Form
        layout='vertical'
        className='modal-form'
        form={form}
        onFinish={onSubmit}
        data-form-testing='form-sale-voucher'
        initialValues={{
          voucherType: EVoucherType.FOR_THEMSELVES
        }}
      >
        <h2 className='modal-title'>{salesLang('VoucherSale')}</h2>
        <Form.Item
          name='voucherType'
        >
          <Radio.Group onChange={(e) => setVoucherType(e.target.value)}>
            <Radio value={EVoucherType.FOR_THEMSELVES}>
              {salesLang('ForThemselves')}
            </Radio>
            <Radio value={EVoucherType.FOR_FRIEND}>
              {salesLang('BuyForFriend')}
            </Radio>

          </Radio.Group>
        </Form.Item>
        {voucherType === EVoucherType.FOR_FRIEND ? <>
          <FormRow
            label={salesLang('CustomerName')}
            name='customer_name'
            rules={[
              { required: true, message: salesLang('PleaseEnterCustomerName') || '' }
            ]}
          >
            <Input placeholder={salesLang('EnterYourName') || ''} />
          </FormRow>
          <FormRow
            label='Email'
            name='email'
            rules={[
              { required: true, message: salesLang('PleaseEnterEmail') || '' },
              { type: 'email', message: salesLang('IsNotAValidEmail') || '' }
            ]}
          >
            <Input placeholder={salesLang('EnterYourEmail') || ''} />
          </FormRow>
          <FormRow name='phone_number' label={salesLang('PhoneNumber')}
            rules={[
              { required: true, message: salesLang('PleaseEnterPhoneNumber') || '' },
              // NumberValidations.PHONE
              { pattern: /^0+[0-9]{9}$/, message: formLang('ThePhoneNumberIs04AndHas10Digits')||'' }
            ]}
          >
            <PhoneNumberInput />
          </FormRow>
        </> : <>
          <FormRow
            label={salesLang('CustomerName')}
            name='customer_name'

          >
            <Input disabled value={customer?.name} />
          </FormRow>
          <FormRow
            label='Email'
            name='email'

          >
            <Input disabled value={customer?.email} placeholder={salesLang('EnterYourEmail') || ''} />
          </FormRow>
          <FormRow name='phone_number' label={salesLang('PhoneNumber')}
          >
            <PhoneNumberInput disabled value={customer?.phone_number} />
          </FormRow>
        </>}
        <div className='form-submit-wrapper'>
          <button
            onClick={onClose}
            type='button'
            className='common-btn is-white'
          >
            {salesLang('Close')}
          </button>
          <button
            // onClick={onSubmit}
            type='submit'
            className='common-btn'
          >
            {salesLang('Confirm')}
          </button>
        </div>
      </Form>
    </Modal>
  );
};

export default ModalVoucherSale;