import MaskedInput from 'antd-mask-input';
 
/**
 * Phone number input
 * @param props 
 * @returns 
 */
const PhoneNumberInput = (props: any) => {
  return (
    <MaskedInput
      mask={'\\0000000000'}
      {...props}
    />
  );
};
export default PhoneNumberInput;

