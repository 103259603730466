import fetch from 'services/request';

const storeUnsubcribedEmail = (body?: any) => {
    return fetch({
        method: 'post',
        url: 'api/unsubscribe',
        body: { ...body },
    });
};

const apiUnsubcribedEmail = {
    storeUnsubcribedEmail,
};

export default apiUnsubcribedEmail;