import { TopUpStatus, TopUpType } from 'features/settings/services/types/topUp';
import { useTranslation } from 'react-i18next';
import TopUpSuccessIcon from 'assets/images/setting/pop-up-success.png';
import TopUpFailedIcon from 'assets/images/setting/pop-up-failed.png';
import TopUpProcessingIcon from 'assets/images/setting/pop-up-processing.png';
import styled from 'styled-components';
import settingSelectors from 'features/settings/services/selectors';

interface TopUpChargeStatusProps {
  type: TopUpStatus;
  amount?: number;
  visible: boolean;
  topUpType: TopUpType | '';
  autoChargeWhen?: number;
}

const TopUpChargeStatus = ({
  type,
  amount,
  visible,
  topUpType,
  autoChargeWhen,
}: TopUpChargeStatusProps) => {
  const { t: topUpLang } = useTranslation('top_up');
  const topupInitData = settingSelectors.getTopUpInitData();

  const topUpChargeTypeInfo = (type: TopUpStatus) => {
    switch (type) {
      case TopUpStatus.FAILED: {
        return {
          title: topUpLang('topUpForm.formResult.failed.text'),
          desc: topUpLang('topUpForm.formResult.failed.desc'),
          image: TopUpFailedIcon,
        };
      }
      case TopUpStatus.SUCCESS: {
        if (topUpType === TopUpType.AUTOMATIC) {
          return {
            title: topUpLang('topUpForm.formResult.success.text'),
            desc: topUpLang('topUpForm.formResult.success.autoDesc', {
              texts: amount ? Math.floor(amount / topupInitData?.fee_sms) : 0,
              amount: amount ?? 0,
              autoChargeAmount: autoChargeWhen ?? 0,
            }),
            image: TopUpSuccessIcon,
          };
        }

        return {
          title: topUpLang('topUpForm.formResult.success.text'),
          desc: topUpLang('topUpForm.formResult.success.manualDesc', {
            amount,
          }),
          image: TopUpSuccessIcon,
        };
      }
      case TopUpStatus.PROCESSING: {
        return {
          title: topUpLang('topUpForm.formResult.processing.text'),
          desc: topUpLang('topUpForm.formResult.processing.desc'),
          image: TopUpProcessingIcon,
        };
      }
      default: {
        return {
          title: '',
          desc: '',
          image: '',
        };
      }
    }
  };

  return visible ? (
    <TopUpChargeStatusStyled>
      <img
        className='status-img'
        src={topUpChargeTypeInfo(type).image}
        alt='top up  charge status icon'
      />
      <p className='status-title'>{topUpChargeTypeInfo(type).title}</p>
      <p className='status-desc'>{topUpChargeTypeInfo(type).desc}</p>
    </TopUpChargeStatusStyled>
  ) : (
    <></>
  );
};

const TopUpChargeStatusStyled = styled.div`
  display: flex;
  padding: 24px;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  align-self: stretch;
  text-align: center;

  p {
    line-height: 140%;
  }

  .status-title {
    color: #363565;
    font-size: 24px;
    font-weight: 600;
  }

  .status-desc {
    color: #363565;
    font-size: 16px;
    font-weight: 400;
  }
`;

export default TopUpChargeStatus;
