const IconEmail = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M3.33317 3.33398H16.6665C17.5832 3.33398 18.3332 4.08398 18.3332 5.00065V15.0007C18.3332 15.9173 17.5832 16.6673 16.6665 16.6673H3.33317C2.4165 16.6673 1.6665 15.9173 1.6665 15.0007V5.00065C1.6665 4.08398 2.4165 3.33398 3.33317 3.33398Z"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.3332 5L9.99984 10.8333L1.6665 5"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default IconEmail;
