import { EBookingStatus } from 'constants/index';
import dayjs, { Dayjs } from 'dayjs';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isBetween from 'dayjs/plugin/isBetween';
import { get } from 'lodash';
import moment, { Moment } from 'moment';
import { BREAK_TIME_MINUTES, HOUR_MINUTE_FORMAT } from 'features/bookings/services/constants';
import { IPromotionAutoRes, IPromotionDetailInput, IPromotionServiceItem } from 'features/promotions/services/types/promotion';
import { IServiceSelectedItem } from 'features/checkout/services/types/service';
import { IServiceItem } from 'features/bookings/services/types/service';
import { IBookAssignmentServiceItemResData, IBookInvoice } from 'features/customers/services/types/appointment';
import { IBookAssignmentServiceResData, IMerchantLocationItemResData } from 'features/bookings/services/types/booking';
import { useTranslation } from 'react-i18next';
dayjs.extend(isSameOrBefore);
dayjs.extend(isBetween);

/**
 * Date Format
 */
export const dateFormat = 'DD.MM.YYYY';

/**
 * Date format placeholder
 */
export const dateFormatPlaceholder = 'DD.MM.YYYY';

/**
 * Time format
 */
export const timeFormat = 'HH:mm';

/**
 * Form optional text
 */
export const formOptionalText = '(Optional)';

/**
 * Value all options
 */
export const valueAllOptions = 99999;

/**
 * HOURS OF DAY
 */
export const HOURS_OF_DAY = 24;

/**
 * MINUTES OF DAY
 */
export const MINUTES_OF_DAY = 60;

/**
 * MINUTE ROUNDED
 */
export const MINUTE_ROUNDED = 5;

/**
 * ANONYMOUS NAME
 */
export const ANONYMOUS_NAME = 'Any team member';

/**
 * CUSTOMER WALKIN INDEX
 */
export const CUSTOMER_WALKIN_INDEX = 2;

/**
 * Days of week
 */
export const daysOfWeek = [
  {
    index: 0,
    label: 'SUN',
    value: 'sunday',
  },
  {
    index: 1,
    label: 'MON',
    value: 'monday',
  },
  {
    index: 2,
    label: 'TUE',
    value: 'tuesday',
  },
  {
    index: 3,
    label: 'WED',
    value: 'wednesday',
  },
  {
    index: 4,
    label: 'THU',
    value: 'thursday',
  },
  {
    index: 5,
    label: 'FRI',
    value: 'friday',
  },
  {
    index: 6,
    label: 'SAT',
    value: 'saturday',
  },
];

/**
 * Handle Promotion Type
 */
export enum EPromotionType {
  PRICE = 'price',
  PERCENT = 'percent'
}

/**
 * Handle hours
 * @param hours 
 * @param dateLang 
 * @returns 
 */
const getHours = (hours: number, dateLang: any) => {
  if (hours !== 0) {
    if (hours === 1) return hours + dateLang('hr');
    return hours + dateLang('hrs');
  }
  return '';
};

/**
 * Handle minutes
 * @param minutes 
 * @param dateLang 
 * @returns 
 */
const getMinutes = (minutes: number, dateLang: any) => {
  if (minutes !== 0) {
    if (minutes === 1) return minutes + dateLang('min');
    return minutes + dateLang('mins');
  }
  return '';
};

/**
 * Handle format Time Minutes
 * @param totalMinutes 
 * @param dateLang 
 * @returns 
 */
export const formatTimeMinutes = (totalMinutes: number, dateLang: any) => {
  const hours = Math.floor(totalMinutes / 60);

  const minutes = totalMinutes % 60;

  return `${getHours(hours, dateLang)} ${getMinutes(minutes, dateLang)}`;
};

/**
 * Handle parse duration minute
 * @param duration_time_text 
 * @returns 
 */
export const parseDurationMinute = (duration_time_text: string) => {
  const arr = duration_time_text.split(':') ?? [];
  const hour = +get(arr, [0], '');
  const minute = +get(arr, [1], '');
  const{t: dateLang} = useTranslation('date');

  const durationMinute = hour * 60 + minute;
  return formatTimeMinutes(durationMinute, dateLang);
};

/**
 * Handle disabled Date Same Or Before
 * @param current 
 * @param from 
 * @returns 
 */
export const disabledDateSameOrBefore = (current: Dayjs, from: Dayjs) => {
  return current?.isSameOrBefore(from?.format('YYYY-MM-DD'));
};

/**
 * Handle disabled date before
 * @param current 
 * @param from 
 * @returns 
 */
export const disabledDateBefore = (current: Dayjs, from: Dayjs) => {
  return current?.isBefore(from?.format('YYYY-MM-DD'));
};

/**
 * Handle format duration time
 * @param duration 
 * @param t 
 * @returns 
 */
export const formatDurationTime = (duration: number, t: any) => {
  const hours = Math.floor(duration / 60);
  const minutes = duration % 60;
  return `${hours === 0 ? '' : hours > 1 ? `${hours} ${t('hrs')}` : `${hours} ${t('hr')}`} ${minutes > 0 ? `${minutes} ${t('mins')}` : ''}`;
};

/**
 * Handle tag status color
 * @param status 
 * @returns 
 */
export const tagStatusColor = (status: string) => {
  switch (status.toLocaleLowerCase()) {
    case 'open':
      return '#363565';
    case 'cancelled':
      return '#F5222D';
    case 'in_progress':
      return '#FAAD14';
    case 'completed':
      return '#4A9D77';
    case 'no_show':
      return '#C3C2E0';
    case 'multiple':
      return '#FADB14';
    case 'active':
      return '#4A9D77';
    case 'disable':
      return '#D0D0D0';
    case 'draft':
      return '#C4C4C4';
    default:
      return;
  }
};

/**
 * Handle round number
 * @param number 
 * @returns 
 */
export const roundNumber = (number: number) => {
  // return Math.round(number * 100) / 100;
  return Math.floor(number * 100) / 100;
};

/**
 * Handle tag status background color
 * @param status 
 * @returns 
 */
export const tagStatusBackgroundColor = (status: string) => {
  switch (status.toLocaleLowerCase()) {
    case 'open':
      return '#F4FFFA';
    case 'cancelled':
      return '#FFF1F0';
    case 'in_progress':
      return '#F4FFFA';
    case 'completed':
      return '#4A9D77';
    case 'no_show':
      return '#F4FFFA';
    case 'multiple':
      return '#F4FFFA';
    case 'active':
      return '#F4FFFA';
    case 'disable':
      return '#F4FFFA';
    case 'draft':
      return '#F4FFFA';
    default:
      return;
  }
};

/**
 * Handle range
 * @param start 
 * @param end 
 * @returns 
 */
const range = (start: number, end: number) => {
  const result = [];
  for (let i = start; i < end; i++) {
    result.push(i);
  }
  return result;
};

/**
 * Handle disabled date time
 * @returns 
 */
export const disabledDateTime = () => ({
  disabledHours: () => [...range(0, 6), ...range(18, 24)],
});

/**
 * Handle status tag background
 * @param status 
 * @returns 
 */
export const statusTagBackground = (status: string) => {
  switch (status.toLocaleLowerCase()) {
    case EBookingStatus.OPEN:
      // return 'is-light-green';
      return 'open';
    case EBookingStatus.IN_PROGRESS:
      return 'is-yellow';
    case EBookingStatus.COMPLETED:
      // return 'is-green';
      return 'completed';
    case EBookingStatus.CANCELLED:
      // return 'is-red';
      return 'cancelled';
    case EBookingStatus.NO_SHOW:
      return 'is-purple';
    case EBookingStatus.DRAFT:
      // return 'is-gray';
      return 'draft';
    default:
      return;
  }
};

/**
 * Handle format number VND
 */
const VND = new Intl.NumberFormat('vi-VN', {
  style: 'currency',
  currency: 'VND',
});

/**
 * Handle format money
 * @param value 
 * @returns 
 */
export const formatMoney = (value: number) => {
  // return `${(value ?? 0)?.toFixed(3)}đ`;
  return `${getSelectedLanguage() === 'vi' ? VND.format(value ?? 0) : '$ ' + value?.toFixed(2)}`;
};

/**
 * Handle format money input
 * @param value 
 * @returns 
 */
export const formatMoneyInput = (value: number) => {
  return `${VND.format(value ?? 0)}`;
};

interface IPluralDict {
  singularWord: 'loyalty point' | 'service'
  pluralWord: string
}

const pluralDict: IPluralDict[] = [
  {
    singularWord: 'loyalty point',
    pluralWord: 'loyalty points'
  },
  {
    singularWord: 'service',
    pluralWord: 'services'
  }
];

/**
 * Handle pluralize word
 * @param singularWord 
 * @param value 
 * @returns 
 */
export const pluralizeWord = (singularWord: IPluralDict['singularWord'], value: number) => {
  return value > 1 ? pluralDict.find(o => o.singularWord === singularWord)?.pluralWord ?? singularWord : singularWord;
};

/**
 * Handle get cash option
 * @param amount 
 * @returns 
 */
export const getCashOption = (amount: number) => {
  const valueRound = amount % 10 === 0 ? amount + 10000 : Math.floor(amount / 10000) * 10000 + 10000;
    
    return [
      {
        value: amount,
        label: formatMoney(amount)
      },
      {
        value: valueRound,
        label: formatMoney(valueRound)
      },
      {
        value: valueRound + 10000,
        label: formatMoney(valueRound + 10000)
      },
    ];
};

/**
 * Handle get unique transaction id
 * @returns 
 */
export const getUniqueTransactionId = () => {
  return `transaction_${moment().format('DDMMYYYY')}`;
};

/**
 * Handle is numeric
 * @param value 
 * @returns 
 */
export function isNumeric(value: any) {
  return /^-?\d+$/.test(value);
}

/**
 * Handle get device id
 * @returns 
 */
export const getDeviceId = () => {
  const fingerprint = [];

  fingerprint.push(navigator.userAgent);
  fingerprint.push(navigator.language);
  fingerprint.push(screen.colorDepth);
  fingerprint.push(new Date().getTimezoneOffset());
  fingerprint.push(typeof sessionStorage !== 'undefined');
  fingerprint.push(typeof localStorage !== 'undefined');
  fingerprint.push(typeof indexedDB !== 'undefined');
  
  const hash = btoa(fingerprint.join(''));  

  return hash;
};

/**
 * Handle get value not lower 0
 * @param value 
 * @returns 
 */
export const getValueNotLower0 = (value: number) => {
  return value > 0 ? value : 0;
};

/**
 * Handle get time round 5 minute
 * @param time 
 * @returns 
 */
export const getTimeRound5Minute = (time: Moment) => {
  const minute = Math.ceil(time.minute() / MINUTE_ROUNDED) * MINUTE_ROUNDED;
  return time.set({
    minute: minute
  });
};

/**
 * Handle is NaN Or Infinity
 * @param variable 
 * @returns 
 */
export function isNaNOrInfinity(variable: any) {
  return !Number.isNaN(variable) || !Number.isFinite(variable);
}

/**
 * checkStatusCanEditBooking
 * @param status EBookingStatus
 * @returns boolean
 */
export const checkStatusCanEditBooking = (status: EBookingStatus) => {
  return status !== EBookingStatus.CANCELLED && status !== EBookingStatus.COMPLETED && status !== EBookingStatus.NO_SHOW;
};


/**
 * Get all dates between two dates
 * @param start Moment
 * @param end Moment
 * @returns Moment[]
 */
export const getDaysBetweenDates = (start: Moment, end: Moment) => {
  const day = start.subtract(1, 'day');
  const dates: Moment[] = [];

  while(day.isBefore(end)) {        
    dates.push(day.add(1, 'day').clone());
  }
  return dates;
};

/**
 * Get all numbers between two numbers
 * @param start number
 * @param end number
 * @returns number[]
 */
export const getNumberBetweenNumbers = (start: number, end: number) => {
  let numStart = start;
  const numbers = [];

  while(numStart < end) {
    numbers.push(numStart ++);
  }

  return numbers;
};


/**
 * Get the timeStart of the next service to be added to the service list based on the timeStart and duration of the services in the list
 * @param services {time_start: string, duration: number, quantity: number}[]
 * @param booking_date string
 * @returns Moment
 */
export const getNextTimeStart = (services: {time_start: string, duration: number, quantity: number}[], booking_date: string) => {
  
  const time  = moment(booking_date);
  services.forEach(o => {
    const duration_time = o.duration ?? 0;
    const quantity = (o.quantity ?? 0);
    const prevServiceMinutes = (duration_time * quantity) + (quantity) * BREAK_TIME_MINUTES;
    time.add(prevServiceMinutes, 'minute');
  });

  return time;
};

/**
 * Convert the following Code to be compatible with the Code used for param requests
 * @param code string
 * @returns string
 */
export const getCodeParamRequest = (code?: string) => {
  return code?.replace('#', '') ?? '';
};

/**
 * checkServiceRestrict is applied
 * @param services IPromotionServiceItem[]
 * @param service IServiceSelectedItem
 * @returns boolean
 */
export const checkServiceRestrict = (services: IPromotionServiceItem[], servicePromotion: IServiceSelectedItem | IServiceItem) => {
  return !services.some(restrict => 
    !servicePromotion.service_variant_id ? servicePromotion.id === restrict.id : 
    servicePromotion.id === restrict.id && restrict.service_variants.find(e => e.id === servicePromotion.service_variant_id));
};

/**
 * get promotion accpet value price or  percent
 * @param promotion IPromotionAutoRes | IPromotionDetailInput
 * @returns number
 */
export const getActivePricePromotion = (service: IServiceSelectedItem, promotion?: IPromotionAutoRes | IPromotionDetailInput | null,) => {
  
  if (!promotion) return 0;

  const value = promotion.value;
  if (promotion?.type === 'percent') {
    return service.price * service.quantity * Number(value) / 100;
  } else if (promotion?.type === 'price') {
    return Number(value);
  } else 
    return 0;
};

/**
 * If the value is greater than the limitValue return limitValue. Otherwise return the value.
 * @param value number
 * @param limitValue number
 * @returns number
 */

export const getValueInLimit = (value: number, limitValue: number) => {
  return limitValue > value ? value : limitValue;
};

/**
 * 
 * @param voucherValue Number
 * @param promotion IPromotionAutoRes | IPromotionDetailInput | null | undefined
 * @returns 
 */
export const getActivePricePromotionVoucher = (voucherValue: number, promotion?: IPromotionAutoRes | IPromotionDetailInput | null,) => {
  
  if (!promotion) return 0;

  const value = promotion.value;
  if (promotion?.type === 'percent') {
    return voucherValue * Number(value) / 100;
  } else if (promotion?.type === 'price') {
    return Number(value);
  } else 
    return 0;
};

/**
 * Check team member must be required in edit booking or checkout
 * @param setting any
 * @param isCheckIn boolean
 * @param status string
 * @returns boolean
 */
export const isBookingTeamMemberRequired = (setting: any, isCheckIn: boolean, status?: string) => {
  return setting?.booking?.is_team_member_booking && ( status === EBookingStatus.IN_PROGRESS || isCheckIn );
};

/**
 * getServicesAndVoucher
 * @param book_assignment_services 
 * @param book_invoice 
 * @returns 
 */
export const getServicesAndVoucher = (book_assignment_services: IBookAssignmentServiceItemResData[] | IBookAssignmentServiceResData[], book_invoice: IBookInvoice) => {
  return [...(book_assignment_services ?? []), ...(book_invoice?.voucher_sales?.map(o => ({
    service_name: 'Voucher',
    quantity: o?.quantity,
    id: -o?.voucher_id,
    service_price: o?.retail_price,
    duration_time: '',
    assigned_employee: {
      employee: {
        full_name: o?.voucher_code
      }
    },
    category_service_color: '',
    category_service_name: '',
    service_id: -o?.voucher_id,
    serivce_variant_name: o?.voucher_name

  } as IBookAssignmentServiceItemResData)) ?? [])];
};

/**
 * get string value of array with duration | exp: [3:00, 3:15, 3:30, 3:45] 
 * @param startTime 
 * @param endTime 
 * @param duration 
 * @returns 
 */

export const getTimeList = (startTime: Moment, endTime: Moment, duration: number) => {
  const roundedUp = Math.round(startTime.subtract(1, 'minute').minute() / 15) * 15;
  
  const timeSchedule = startTime.minute(roundedUp).second(0).clone();
  const times: string[] = [];

  const timeEnd = endTime;

  while(timeSchedule.isBefore(timeEnd)) {
    times.push(timeSchedule.format(HOUR_MINUTE_FORMAT));
    timeSchedule.add(duration, 'minute');
  }

  return times;
};

/**
 * get string value lowest
 * @param arr 
 * @returns 
 */
export const minStringArray = (arr: string[]) => {
  return arr.reduce(function(s1, s2) {
    return s1 < s2 ? s1 : s2;
  }, arr[0]);
};

/**
 * get string value biggest
 * @param arr 
 * @returns 
 */
export const maxStringArray = (arr: string[]) => {
  return arr.reduce(function(s1, s2) {
    return s2 > s1 ? s2 : s1;
  }, arr[0]);
};

export interface IPeriodTime {
  time_end: string, 
  time_start: string
}
/**
 * merge period if have any period duplicate
 * @param arr IPeriodTime[]
 * @param status?: 'lower': when two period duplicate get lower time | 'upper': when two period duplicate get bigger time 
 * returns IPeriodTime[]
 */
export const arrayTimeNotCollapse = (periods: IPeriodTime[], status:'lower' | 'upper' | undefined = 'upper') => {
  const timeResult: IPeriodTime[] = [];
  const periodsSorted = periods.sort((o1, o2) => o1.time_start.localeCompare(o2.time_start));
  periodsSorted?.forEach(scheduleBusy => {
    const timeCollapse = timeResult.find(busy => scheduleBusy.time_start < busy.time_end);
    if(!timeCollapse) return timeResult.push(scheduleBusy);

    timeResult.forEach((busy, index) => {
      if(busy.time_start === timeCollapse?.time_start) {
        timeResult[index].time_start = status === 'upper' ? minStringArray([busy.time_start, scheduleBusy.time_start]) : maxStringArray([busy.time_start, scheduleBusy.time_start]);
        timeResult[index].time_end = status === 'upper' ? maxStringArray([busy.time_end, scheduleBusy.time_end]) : minStringArray([busy.time_end, scheduleBusy.time_end]);
      }
    });

  });

  return timeResult;
};

/**
 * Handle get opening location
 * @param date 
 * @param activeLocation 
 * @returns 
 */
export const getOpeningLocation = (date: Moment, activeLocation?: IMerchantLocationItemResData) => {
  const timming = activeLocation?.time_opening.find(time => time.weekday === daysOfWeek.find(day => day.index === date.day())?.value);
  return timming;
};

/**
 * Handle get selected language
 * @returns 
 */
export const getSelectedLanguage = (): string => {
  const storedLanguage = localStorage.getItem('selectedLanguage');
  const defaultLanguage = 'vi';
  return storedLanguage || defaultLanguage;
};

/**
 * Handle format time minutes v2
 * @param totalMinutes 
 * @returns 
 */
export const formatTimeMinutes_v2 = (totalMinutes: number) => {
  const hours = Math.floor(totalMinutes / 60);

  const minutes = totalMinutes % 60;

  return `${getHours_v2(hours)} ${getMinutes_v2(minutes)}`;
};

/**
 * Handle get hours v2
 * @param hours 
 * @returns 
 */
const getHours_v2 = (hours: number) => {
  if (hours !== 0) {
    if (hours === 1) return hours + 'hr';
    return hours + 'hrs';
  }
  return '';
};

/**
 * Handle get minutes v2
 * @param minutes 
 * @returns 
 */
const getMinutes_v2 = (minutes: number) => {
  if (minutes !== 0) {
    if (minutes === 1) return minutes + 'min';
    return minutes + 'mins';
  }
  return '';
};