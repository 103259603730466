import { Form, InputNumber } from 'antd';
import { useEffect, useRef } from 'react';
import styled from 'styled-components';
import FormRow from '../Form/FormRow';

import { isNumeric } from 'utils/unit';

/**
 * Handle OTP
 * @param errorPin , size
 * @returns 
 */
function OTP({ errorPin = '', size = '' }: { errorPin?: string, size?: 'big' | string }) {
  const form = Form.useFormInstance();
  const _one = useRef<HTMLInputElement>(null);
  const _two = useRef<HTMLInputElement>(null);
  const _three = useRef<HTMLInputElement>(null);
  const _four = useRef<HTMLInputElement>(null);

  const pinNumbers = [
    {
      name: '_one',
      ref: _one
    },
    {
      name: '_two',
      ref: _two
    },
    {
      name: '_three',
      ref: _three
    },
    {
      name: '_four',
      ref: _four
    },
  ];
  
  useEffect(() => {
    const timer = setTimeout(() => {
      if (_one.current) {
        _one.current.focus();
      }
      form.setFieldsValue({
        _one: _one.current?.value,
        _two: _two.current?.value,
        _three: _three.current?.value,
        _four: undefined,
      });
      if (_three.current?.value) {
        _four.current?.focus();
      } else if (_two.current?.value) {
        _three.current?.focus();
      } else if (_one.current?.value) {
        _two.current?.focus();
      }
    }, 400);
    return () => clearTimeout(timer);
  }, [pinNumbers]);
  return (
    <OTPStyled className={`${size}`}>
      <Form.Item
        validateStatus={errorPin ? 'error' : undefined}
        help={errorPin ? errorPin : undefined}
      >
        <div className="PIN_group" >
          {pinNumbers.map(({name, ref}, index, arr) => (
            <FormRow key={index} shouldUpdate noStyle name={name}>
              <InputNumber
                ref={ref}
                type='number'
                maxLength={1}
                minLength={1}
                // autoFocus={index === 0}
                inputMode='numeric'
                className='PIN_input'
                onFocus={() => {
                  form?.setFieldValue(name, undefined);
                }}
        
                onKeyUp={e => {
                  if (e === null || e === undefined) return;

                  if( isNumeric(e.key)) {
                    const item = arr[index + 1] ? arr[index + 1] : undefined;
                    if(item) {
                      if(form.getFieldValue(item.name)) {
                        return;
                      }
                      form?.setFieldValue(item.name, undefined);
                      item.ref.current?.focus();
                    }
                  }
                }}
                onKeyDown={e => {
                  if (e.key === 'Backspace') {                    
                    if( ! form.getFieldValue(name) ) {
                      const item = arr[index - 1] ? arr[index - 1] : undefined;
                      form?.setFieldValue(item?.name ?? '', undefined);
                      setTimeout(() => item?.ref?.current?.focus(), 1);
                    }
                  }
                }}
              />
            </FormRow>
          ))}
        </div>
      </Form.Item>
    </OTPStyled>
  );
}

export default OTP;

const OTPStyled = styled.div`
  .ant-form-item-extra {
    color:#ff4d4f;
  }
  .hide {
    display:none;
  }
  .form-confirm {
    text-align: center;
  }
  .PIN_form {
    text-align: center;
    .label-highlight {
      color:#4A9D77;
    }
  }
  .PIN_group {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    width:100%;
    margin-top: 10%;
    margin-bottom: 5%;
  }
  .PIN_input {
    flex:1;
    background: none;
    border: unset;
    border-radius: 0;
    border-bottom: 3px solid #C3C2DE;
    input {
      padding: 0;
      font-style: normal;
      font-weight: 600;
      font-size: 40px;
      line-height: 140%;
      text-align: center;
      color: #363565;
      background: none;
    }
    &.ant-input-number {
      width: 40px;
    }
  }
  &.big {
    .PIN_input {
      input {
        font-size: 55px;
      }
      &.ant-input-number {
        width: 60px;
      }
    }
  }
`;