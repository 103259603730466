import { Empty, Table } from 'antd';
import { useTranslation } from 'react-i18next';
import { recentAppointmentsData } from '../data';
import styled from 'styled-components';


const RecentAppointments = () => {
  const { t: userLang } = useTranslation('user');
  const { t: invoiceLang } = useTranslation('invoice');

  /**
   * Columns table 
   */
  const columns = [
    {
      title: 'Mã đặt lịch',
      dataIndex: 'CodeBooking',
      key: 'CodeBooking',
      width: '35%',
      render: (text: string) => {
        return (
          <RecentAppointmentsStyled>
            <a className='code-booking'>{userLang(text)}</a>
          </RecentAppointmentsStyled>
        );
      },
    },
    {
      title: 'Khách hàng',
      dataIndex: 'FullName',
      key: 'FullName',
      width: '35%',

    },
    {
      title: 'Giá',
      dataIndex: 'price',
      key: 'price',
    },
  ];

  return (
    <>
      <Table
        className={'custom-table'}
        dataSource={recentAppointmentsData}
        columns={columns}
        // scroll={{
        //   x: 900,
        // }}
        pagination={false}
        locale={{
          emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={invoiceLang('NoData')} />,
        }}
      />
    </>
  );
};

export default RecentAppointments;

const RecentAppointmentsStyled = styled.div`
.code-booking {
  overflow: hidden;
  color: #1B9AAA;
  text-overflow: ellipsis;
  font-size: 16px;
  font-weight: 500;
  line-height: 140%;
  text-decoration-line: underline;
}
`;
