import {
  TreeSelect
} from 'antd';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

export type IOptionMulti = {
  title: string;
  value: string | number;
  isDisabledUnSelect?: boolean;
};
type MultipleSelectProps = {
  value: IOptionMulti['value'][],
  onChange: (value: IOptionMulti['value'][]) => void
  options: IOptionMulti[],
  placeholder?: string;
  maxTagPlaceholder?: string;
  isWhiteInput?: boolean;
  disabled?: boolean;
  showArrow?: boolean;
};

/**
 * Handle multiple select
 * @param isWhiteInput, disabled, onChange , options, value, placeholder, maxTagPlaceholder, showArrow
 * @returns 
 */
const MultipleSelect: React.FC<MultipleSelectProps> = ({ isWhiteInput, disabled, onChange = () => undefined, options = [], value = [], placeholder, maxTagPlaceholder, showArrow }) => {
  const allIds = useMemo(() => options.map(({ value }) => value), [options]);
  const { t: commonLang } = useTranslation('common');

  return (
    <MultipleStyled className={`${isWhiteInput ? 'inputSelect' : ''}`}>
      <TreeSelect
        disabled={disabled}
        allowClear={false}
        placeholder={placeholder ?? 'Select items'}
        treeCheckable
        showArrow={showArrow || !disabled}
        showCheckedStrategy={TreeSelect.SHOW_CHILD}
        dropdownStyle={{ maxHeight: '300px' }}
        onChange={ids => onChange(ids)}
        value={value}
        maxTagCount={1}
        tagRender={(props: any) => {
          return value?.length > 1 ? <></> : <>{props?.label}</>;
        }}
        size={!isWhiteInput ? 'large' : 'middle'}
        maxTagPlaceholder={() => value?.length === options?.length ? commonLang('All') + ' ' + (maxTagPlaceholder ?? 'items') : `${value?.length} ${maxTagPlaceholder ?? 'items'}`}
        treeData={options.length > 0 ?
          disabled ?
            options.map(o => ({ ...o, disabled })) : [
              {
                title:
                  value?.length === options?.length ? (
                    <span
                      onClick={() => onChange([])}
                      style={{
                        display: 'block',
                        color: '#286FBE',
                        cursor: 'pointer',
                      }}
                    >
                      {commonLang('UnselectAll')}
                    </span>
                  ) : (
                    <span
                      onClick={() => onChange(allIds)}
                      style={{
                        display: 'block',
                        color: '#286FBE',
                        cursor: 'pointer'
                      }}
                    >
                      {commonLang('SelectAll')}
                    </span>
                  ),
                value: 'xxx',
                checkable: false,
                disableCheckbox: true,
                disabled,
              },
              ...(options?.map((item) => {
                if (item?.isDisabledUnSelect) {
                  return {
                    ...item,
                    disabled: value?.find(val => Number(val) === Number(item?.value)),
                  };
                }
                return item;
              }) || []),
            ] : []}
      />
    </MultipleStyled>
  );
};
export default MultipleSelect;

const MultipleStyled = styled.div`
      &.inputSelect {
        background:#fff;
        border-radius: 6px;
        height: 42px;
      &:hover{
        cursor: pointer;
      }
      .ant-select {
        height:100%;
        display:flex;
  }
      .ant-select-selector {
        &:hover{
          cursor: pointer;
        }
          display: flex;
          -webkit-box-align: center;
          align-items: center;
          width: 100%;
          min-width: 248px;
          min-width: unset;
          border-radius: 6px;
          font-weight: 500;
          font-size: 14px;
          font-family: var(--font-base);
          padding: 0px 24px !important;
          background-color: #fff  !important;
          border: none !important;
          color: var(--color-primary)  !important;
          box-shadow: none !important;
          min-width: 200px;
          height: 100%;
          background-color: var(--color-white);
          padding: 0px 32px 0px 16px;
          border-radius: 6px;
        }
}

      .ant-select-selection-overflow-item.ant-select-selection-overflow-item-rest {
        width:90%;
}
      .ant-select-selection-item {
        &:hover{
          cursor: pointer;
        }
        width: 100%;
      background: none !important;
      border: none !important;
      padding:0 !important;
}
      .ant-select-selection-overflow-item {
        width: 90%;
      color: var(--color-primary) !important;
      .ant-select-selection-item-content {
        box-shadow: none !important;
        font-size: 14px;
        font-weight: 500;
        font-family: var(--font-base);
        color: var(--color-primary) !important;
  }
}
      .ant-select-selection-overflow-item-suffix {
        display:none !important;
}
      .ant-select-selector {
        display: flex;
      align-items: center;
      width: 100%;
      padding: 0 24px !important;
      min-width: 248px;
      height: 64px;
      /* background-color: var(--color-white-01) !important; */
      border-radius: 6px;
      /* border: none !important; */
      color: var(--color-primary) !important;
      font-weight: 500;
      box-shadow: none !important;
      font-size: 14px;
      font-family: var(--font-base);
}
      `;
