import {
    Button,
    Form,
    Input,
    Modal,
} from 'antd';
import { IOptionMulti } from 'components/common/MultipleSelect';
import SYSTEM_VARIABLES from 'constants/variables';
import { first, get } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import storage from 'utils/sessionStorage';
import serviceSelectors from '../../../services/services/selectors';
import { useTranslation } from 'react-i18next';
import FormBlock from '../../../Products/Components/BrandFrom/FormBlock';
import TextArea from 'antd/lib/input/TextArea';
import styled from 'styled-components';
import styles from '../../pages/index.module.scss';
import IconPlusCircle from 'assets/svg/IconPlusCircle';
import { IconRight } from 'assets/svg';
import { useNavigate } from 'react-router-dom';
export interface IServiceForm {
    service_name?: string;
    category?: number;
    price?: number;
    availableLocation?: number | number[];
    locationTemp: number[];
    newCategory?: string;
    teamMember?: number[];
    teamMemberTemp: number[];
    duration?: number;
    isEnableService?: boolean;
    isEnableBookingLink?: boolean;
    isEnableVoucherSales?: boolean;
    isEnableDiscounts?: boolean;
    isMakeNewCategory?: boolean;
    serviceDescription?: string;
    serviceVariants?: IServiceVariant[];
}

interface IServiceVariant {
    name?: string;
    price?: number;
    duration?: number;
}

type IEmployeeItem = {
    id: string | number;
    title_name: string;
    merchant_location_id: string;
    employee_id: number;
    user: IUser;
};

interface IUser {
    id: number;
    full_name: string;
    email: string;
    phone: string;
}

export type IFuncHandleSubmit = (value: IPayloadReturnType) => void;
interface ServicesFormProps {
    isModalOpen?: boolean;
    handleCancel?: () => void;
    handleSubmit: IFuncHandleSubmit;
    currService?: any;
    formMode?: any
}

const SupplierForm = ({
    isModalOpen,
    handleCancel,
    handleSubmit,
    currService,
}: ServicesFormProps) => {
    const [form] = Form.useForm<IServiceForm>();
    const { t: serviceLang } = useTranslation('service');
    const { t: productLang } = useTranslation('products');
    const dataForm = serviceSelectors.getShowCreateService();
    const navigate = useNavigate();
    const locations: IOptionMulti[] = useMemo(
        () =>
            (dataForm?.locations as { id: string | number; name: string }[])
                ?.filter((o) => o.id != 99999)
                ?.map((o) => ({ value: o.id, title: o.name })) ?? [],
        [dataForm?.locations]
    );
    const [selectedLocations, setSelectedLocations] = useState<any[]>(
        locations.map(({ value }) => value)
    );

    const employees: IOptionMulti[] = useMemo(
        () =>
            (dataForm?.employees as IEmployeeItem[])
                ?.filter((o) => selectedLocations.includes(o.merchant_location_id))
                ?.map((o) => ({ value: o.id, title: o.user.full_name })) ?? [],
        [dataForm?.employees, selectedLocations]
    );
    const [selectedEmployees, setSelectedEmployees] = useState<any[]>(employees);

    useEffect(() => {
        if (!currService) return;
        form.setFieldsValue(currService);
        setSelectedLocations(currService.availableLocation);
        setSelectedEmployees(currService.teamMember);
    }, [currService]);

    useEffect(() => {
        form?.setFieldValue('availableLocation', [...selectedLocations]);
        if (locations.length > 1 && selectedLocations.length === locations.length) {
            form?.setFieldValue('locationTemp', [SYSTEM_VARIABLES.ALL_LOCATIONS]);
        } else {
            form?.setFieldValue('locationTemp', [...selectedLocations]);
        }
    }, [selectedLocations]);

    useEffect(() => {
        form?.setFieldValue('teamMember', [...selectedEmployees]);
        if (employees.length > 1 && selectedEmployees.length === employees.length) {
            form?.setFieldValue('teamMemberTemp', [SYSTEM_VARIABLES.ALL_EMPLOYEES]);
        } else {
            form?.setFieldValue('teamMemberTemp', [...selectedEmployees]);
        }
    }, [selectedEmployees]);

    const checkValid = async () => {
        try {
            await form.validateFields([]);
            return true;
        } catch (error) {
            return false;
        }
    };

    const _onSubmit: React.MouseEventHandler<HTMLButtonElement> = async (e) => {
        e.preventDefault();
        const isFormValid = await checkValid();
        if (!isFormValid) return;
        form.submit();
    };

    const _handleSubmit = (values: IServiceForm) => {
        const payload: IPayloadReturnType = getPayload(values);
        handleSubmit(payload);
    };

    const onClickBack = () => {
        navigate(-1);
    };

    const initialValues = useMemo(() => {
        const result = {
            isMakeNewCategory: false,
            isEnableService: currService?.isEnableService === 0 ? false : true,
            isEnableBookingLink: currService?.isEnableBookingLink === 0 ? false : true,
            isEnableVoucherSales: currService?.isEnableVoucherSales === 0 ? false : true,
            isEnableDiscounts: currService?.isEnableDiscounts === 0 ? false : true,
            isNewCategory: false,
            serviceVariants: [],
        };

        if (locations.length === 0) {
            form.setFieldValue('locationTemp', [SYSTEM_VARIABLES.ALL_LOCATIONS]);
            form.setFieldValue('availableLocation', SYSTEM_VARIABLES.ALL_LOCATIONS);
            form.setFieldValue('teamMemberTemp', [SYSTEM_VARIABLES.ALL_EMPLOYEES]);
            form.setFieldValue('teamMember', SYSTEM_VARIABLES.ALL_EMPLOYEES);
        }

        return result;
    }, [locations]);

    const [phoneFields, setPhoneFields] = useState(1);

    const handleAddPhone = () => {
        setPhoneFields((prevFields) => prevFields + 1);
    };

    const phoneInputs = [];
    for (let i = 0; i < phoneFields; i++) {
        phoneInputs.push(
            <Form.Item
                label={productLang('Phone') + ' (*)'}
                name={`service_name_${i}`}
                key={i}
                rules={[
                    {
                        validator: (_, value) =>
                            value?.trim()
                                ? Promise.resolve()
                                : Promise.reject(new Error(`${productLang('PleaseEnterPhone')}`)),
                    },
                ]}
            >
                <Input placeholder={productLang('EnterPhone') || ''} />
            </Form.Item>
        );
    }

    return (

        <Modal
            maskClosable={true}
            open={isModalOpen}
            onCancel={handleCancel}
            okButtonProps={{ style: { display: 'none' } }}
            width={1320}
            footer={null}
            closeIcon={null}
            forceRender
            closable={false}
        >
            <ListProductPageStyled>
                <div className={styles.header_container}>
                    <div className={styles.icon_close_container}>
                        <span className={styles.icon_close} onClick={handleCancel}>
                            &#10005;
                        </span>
                    </div>
                    <div>
                        <p className={styles.titile_add_new}>{productLang('AddNew')}</p>
                    </div>
                    <div className={styles.btn_header_container}>
                        <Button className='common-btn is-brand' onSubmit={_onSubmit}>
                            {productLang('Confirm')}
                        </Button>
                    </div>
                </div>

                <Form
                    name='basic'
                    className='modal-form'
                    form={form}
                    onFinish={_handleSubmit}
                    autoComplete='off'
                    layout='vertical'
                    initialValues={initialValues}
                >
                    <FormBlock>
                        <div className={styles.general_information}>
                            <p className={styles.setting_overview}>{productLang('GeneralInformation')}</p>
                            <Form.Item
                                label={productLang('Supplier') + ' (*)'}
                                name={'service_name'}
                                rules={[
                                    {
                                        validator: (_, value) =>
                                            value?.trim() ? Promise.resolve() : Promise.reject(new Error(`${productLang('SupplierIsRequired')}`)),
                                    },
                                ]}
                            >
                                <Input placeholder={productLang('EnterSupplier') || ''}></Input>
                            </Form.Item>
                            <Form.Item
                                label={serviceLang('Description')}
                                name={'Description'}
                            >
                                <TextArea
                                    placeholder={productLang('VdSpecializesInProvidingHairProducts') + '...' || ''}
                                    rows={2}></TextArea>
                            </Form.Item>
                        </div>

                        <div className={styles.general_information}>
                            <p className={styles.setting_overview}>{productLang('Contact')}</p>
                            <Form.Item
                                label={productLang('NameContact') + ' (*)'}
                                name={'service_name'}
                                rules={[
                                    {
                                        validator: (_, value) =>
                                            value?.trim() ? Promise.resolve() : Promise.reject(new Error(`${productLang('PleaseEnterTheContactIsFirstAndLastName')}`)),
                                    },
                                ]}
                            >
                                <Input placeholder={productLang('EnterTheContactIsFirstAndLastName') || ''}></Input>
                            </Form.Item>

                            {/* <Form.Item
                                label={productLang('Phone') + '(*)'}
                                name={'service_name'}
                                rules={[
                                    {
                                        validator: (_, value) =>
                                            value?.trim() ? Promise.resolve() : Promise.reject(new Error(`${productLang('PleaseEnterPhone')}`)),
                                    },
                                ]}
                            >
                                <Input placeholder={productLang('EnterPhone') || ''}></Input>
                            </Form.Item> */}
                            {phoneInputs}
                            <Button onClick={handleAddPhone}><IconPlusCircle /> {productLang('AddPhone')}</Button>
                            <Form.Item
                                label={productLang('Email')}
                                name={'service_name'}
                                rules={[
                                    {
                                        validator: (_, value) =>
                                            value?.trim() ? Promise.resolve() : Promise.reject(new Error(`${productLang('PleaseEnterEmail')}`)),
                                    },
                                ]}
                            >
                                <Input placeholder={productLang('EnterEmail') || ''}></Input>
                            </Form.Item>
                            <Form.Item
                                label={productLang('Address')}
                                name={'service_name'}
                                rules={[
                                    {
                                        validator: (_, value) =>
                                            value?.trim() ? Promise.resolve() : Promise.reject(new Error(`${productLang('PleaseEnterAddress')}`)),
                                    },
                                ]}
                            >
                                <Input placeholder={productLang('EnterAddress') || ''}></Input>
                                <p className='example_address'>{productLang('ExampleAddress')}</p>
                            </Form.Item>
                            <Form.Item
                                label={'Website'}
                                name={'service_name'}
                                rules={[
                                    {
                                        validator: (_, value) =>
                                            value?.trim() ? Promise.resolve() : Promise.reject(new Error(`${productLang('PleaseEnterWebsite')}`)),
                                    },
                                ]}
                            >
                                <Input placeholder={'VD www.mindhanaonline.com'}></Input>
                            </Form.Item>
                        </div>
                    </FormBlock>
                    <div className={styles.btn_add_form_supplier}>
                        <div style={{ display: 'flex' }}>
                            <button className={styles.icon_heading} onClick={onClickBack}><IconRight /></button>
                            <Button className='common-btn is-brand'>{productLang('Confirm')}</Button>
                        </div>
                    </div>
                </Form>
            </ListProductPageStyled>
        </Modal>

    );
};

export default SupplierForm;

const getPayload = (values: IServiceForm) => {
    const firstVariant = first(values?.serviceVariants);
    const servicePrice =
        get(firstVariant, 'price', 0) != 0
            ? get(firstVariant, 'price', 0)
            : values.price;
    const serviceDuration =
        get(firstVariant, 'duration', 0) != 0
            ? get(firstVariant, 'duration', 0)
            : values.duration;

    const merchant_id = storage.merchantId.get() ?? '';
    const service_category_id = values?.category;

    const merchant_location_id =
        values?.locationTemp?.length > 0
            ? values?.locationTemp
            : values?.availableLocation;
    const regular_price = servicePrice;
    const sale_price = servicePrice;
    const name = values?.service_name;
    const duration_time = serviceDuration;
    const enable = !!values?.isEnableService;

    const enable_online_booking = !!values?.isEnableBookingLink;
    const enable_voucher_sale = !!values?.isEnableVoucherSales;
    const enable_discount = !!values?.isEnableDiscounts;
    const checkNewCategory = values?.isMakeNewCategory;
    const checkNewExtraService =
        values?.serviceVariants?.length != 0 ? true : false;

    const emoloyee_service_id =
        values.teamMemberTemp.length > 0
            ? values?.teamMemberTemp
            : values?.teamMember;
    const service_variants = values?.serviceVariants?.map(
        (item: IServiceVariant) => ({
            extra_name: item?.name,
            price: item?.price,
            duration: item?.duration,
        })
    );

    const name_category = values?.newCategory;
    const color_id = 1;

    const description = values?.serviceDescription ?? '';

    return {
        merchant_id,
        service_category_id,
        merchant_location_id,
        regular_price,
        sale_price,
        name,
        duration_time,
        enable,
        enable_online_booking,
        enable_voucher_sale,
        enable_discount,
        checkNewCategory,
        checkNewExtraService,
        emoloyee_service_id,
        service_variants,
        name_category,
        color_id,
        description,
    };
};

export type IPayloadReturnType = ReturnType<typeof getPayload>;

const ListProductPageStyled = styled.div`
.ant-modal .ant-modal-content {
    padding: 35px 20px 35px;  
}
.form-block {
    display: grid;
    justify-content: center;
    gap: 24px;
}
.ant-form-item .ant-form-item-label > label {
    font-size: 16px;
    font-weight: 600;
    line-height: 140%;
}
.ant-btn-default {
    margin-top: 12px;
    width: 100%;
    border: 0px;
    font-size: 18px;
    font-weight: 500;
    line-height: 140%;
    color: #1B9AAA;
}
.common-btn.ant-btn-default {
    min-width: 80px;
    color: #FFFFFF;
}
.common-btn.ant-btn-default:hover {
    color: #1B9AAA;
    border: 1px solid #1B9AAA;
}
`;
