import React, { useState } from 'react';
import styles from '../../pages/index.module.scss';
import { useTranslation } from 'react-i18next';
import { Checkbox } from 'antd';
import { IconAddSetting, IconCashSetting, IconEditSetting, IconLogoMomoSetting, IconLogoZLPSetting, IconTransferMoney } from 'assets/svg';
import FormModal from './FormModal';

const PaymentMethodsTab = () => {
    const { t: settingLang } = useTranslation('setting');
    const [isCheckedMomo, setIsCheckedMomo] = useState(false);
    const [isCheckedZaloPay, setIsCheckedZaloPay] = useState(false);
    const [isCheckedTransfer, setIsCheckedTransfer] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isModalName, setModalName] = useState('');
    const [statusModal, setStatusModal] = useState('');
    const handleCheckboxChangeMomo = (e: any) => {
        setIsCheckedMomo(e.target.checked);
    };

    const handleCheckboxChangeZaloPay = (e: any) => {
        setIsCheckedZaloPay(e.target.checked);
    };

    const handleCheckboxChangeTransfer = (e: any) => {
        setIsCheckedTransfer(e.target.checked);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const AddMomoShowModal = () => {
        setIsModalOpen(true);
        setModalName('Momo');
        setStatusModal('Add');
    };

    const EditMomoShowModal = () => {
        setIsModalOpen(true);
        setModalName('Momo');
        setStatusModal('Edit');
    };

    const AddZaloPayShowModal = () => {
        setIsModalOpen(true);
        setModalName('ZaloPay');
        setStatusModal('Add');
    };

    const EditZaloPayShowModal = () => {
        setIsModalOpen(true);
        setModalName('ZaloPay');
        setStatusModal('Edit');
    };

    const AddTransferShowModal = () => {
        setIsModalOpen(true);
        setModalName('AddTransfer');
        setStatusModal('Add');
    };

    const EditTransferShowModal = () => {
        setIsModalOpen(true);
        setModalName('AddTransfer');
        setStatusModal('Edit');
    };

    return (
        <>
            <div className={styles.payment_method_container}>
                <div className={styles.payment_method_table}>
                    <div>
                        <p className={styles.payment_method_title}>{settingLang('PaymentMethods')}</p>
                        <p className={styles.payment_method_content}>{settingLang('SettingPaymentMethods')}</p>
                    </div>
                    <div className={styles.payment_method_item_container}>
                        <div className={styles.payment_method_item}>
                            <Checkbox checked />
                            <div className={styles.payment_method_item_content}>
                                <IconCashSetting />
                                <div>
                                    <p className={styles.payment_method_item_title}>{settingLang('Cash')}</p>
                                    <p className={styles.payment_method_item_p}>{settingLang('DefaultMethod')}</p>
                                </div>
                            </div>
                        </div>
                        <div className={styles.payment_method_item}>
                            <Checkbox checked={isCheckedMomo} onChange={handleCheckboxChangeMomo} />
                            <div className={styles.payment_method_item_content}>
                                <IconLogoMomoSetting />
                                <div>
                                    <p className={styles.payment_method_item_title}>Momo</p>
                                    <p className={styles.payment_method_item_p}>{!isCheckedMomo ? settingLang('NotInstalledYet') : '0964074541'}</p>
                                </div>
                            </div>
                            {!isCheckedMomo ?
                                <div onClick={AddMomoShowModal}>
                                    <IconAddSetting />
                                </div>
                                :
                                <div onClick={EditMomoShowModal}>
                                <IconEditSetting />
                                </div>
                            }
                        </div>
                        <div className={styles.payment_method_item}>
                            <Checkbox checked={isCheckedZaloPay} onChange={handleCheckboxChangeZaloPay} />
                            <div className={styles.payment_method_item_content}>
                                <IconLogoZLPSetting />
                                <div>
                                    <p className={styles.payment_method_item_title}>Zalopay</p>
                                    <p className={styles.payment_method_item_p}>{!isCheckedZaloPay ? settingLang('NotInstalledYet') : '0964074541'}</p>
                                </div>
                            </div>
                            {!isCheckedZaloPay ?
                                <div onClick={AddZaloPayShowModal}>
                                    <IconAddSetting />
                                </div> :
                                <div onClick={EditZaloPayShowModal}>
                                <IconEditSetting />
                                </div>
                            }
                        </div>
                        <div className={styles.payment_method_item}>
                            <Checkbox checked={isCheckedTransfer} onChange={handleCheckboxChangeTransfer} />
                            <div className={styles.payment_method_item_content}>
                                <IconTransferMoney />
                                <div>
                                    <p className={styles.payment_method_item_title}>{settingLang('Transfer')}</p>
                                    <p className={styles.payment_method_item_p}>{!isCheckedTransfer ? settingLang('NotInstalledYet') : '19033774026017'}</p>
                                </div>
                            </div>
                            {!isCheckedTransfer ?
                                <div onClick={AddTransferShowModal}>
                                    <IconAddSetting />
                                </div> :
                                <div onClick={EditTransferShowModal}>
                                    <IconEditSetting />
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
            {isModalOpen && <FormModal
                // isDisableSubmit={isDisableSubmit}
                // formData={formData}
                modalName={isModalName}
                isModalOpen={isModalOpen}
                handleCancel={handleCancel}
                statusModal={statusModal}
            // handleSubmit={isEdit ? handleSubmitEdit : handleSubmit}
            />}
        </>
    );
};

export default PaymentMethodsTab;