import PrimaryButton from 'components/common/Buttons/PrimaryButton';
import React, { Fragment } from 'react';
import styled from 'styled-components';
import Section from '../Section';
import { BookingFormProps } from '../types';
import { DividerStyled } from './BasicContent';
import BookingDetails from './BookingDetails';
import CustomerInfo from './CustomerInfo';
import ImportantNote from './ImportantNote';
import Location from './Location';
import ServiceDetails from './ServiceDetails';
import { useTranslation } from 'react-i18next';
interface Props extends BookingFormProps {
  isCheckIn?: boolean;
}
const Confirm: React.FC<Props> = ({ isCheckIn }) => {
  const {t: bookingLang} = useTranslation('booking');
  return (
    <ConfirmStyled>
      <Section label={bookingLang('YouAreAllSetYourBookingHasBeenConfirmed')||''}>
        <Fragment>
          <CustomerInfo />
          <Location isCheckin={isCheckIn} />
          <DividerStyled />
          <BookingDetails />
          <DividerStyled />
          <ServiceDetails />
          <ImportantNote onlyView />
        </Fragment>
      </Section>
      {!isCheckIn ? <PrimaryButton type='button' label={bookingLang('AddNewBooking')||''} className='primary_button' onClick={() => window?.location?.reload()} /> : null}
    </ConfirmStyled>
  );
};

export default Confirm;

const ConfirmStyled = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 16px;
  width: 100%;
  .primary_button {
    display: flex;
    align-self: center;
    &.outline{
      color: var(--color-primary);
      background-color: transparent;
      
    }
  }
`;