const IconPlusCircle = () => {
  return (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="plus-circle">
        <g id="plus">
          <path id="Vector" d="M22.5 12C22.5 6.47715 18.0228 2 12.5 2C6.97715 2 2.5 6.47715 2.5 12C2.5 17.5228 6.97715 22 12.5 22C18.0228 22 22.5 17.5228 22.5 12Z" stroke="#1B9AAA" />
          <path id="Vector 3" d="M12.5 8V16" stroke="#1B9AAA" />
          <path id="Vector 4" d="M16.5 12L8.5 12" stroke="#1B9AAA"/>
        </g>
      </g>
    </svg>
  );
};

export default IconPlusCircle;
