import { Popover } from 'antd';
import { EBookingStatus } from 'constants/index';
import bookingActions from 'features/bookings/services/actions';
import bookingSelectors from 'features/bookings/services/selectors';
import settingSelectors from 'features/settings/services/selectors';
import { MouseEventHandler, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
import { IconDash, IconNote } from './Icons';
import ModalCancel, { IModalCancelSubmitFunc } from './ModalCancel';
import { useBookingMultiContext } from './UIBookingMulti';
import { BookingItem } from './types';
import useOpenPopover from './useOpenPopover';
import { useTranslation } from 'react-i18next';

type Props = {
  bookId: number | string;
  bookStatus: string;
  data: BookingItem;
  handleOpenPopup?: (status: boolean) => void;
};

enum ENUM_ACTIONS {
  EDIT = 'edit',
  DUPLICATE = 'duplicate',
  CANCEL = 'cancel',
  NO_SHOW = 'no-show',
}

/**
 * Popover edit
 * @param bookId , bookStatus , data, handleOpenPopup
 * @returns 
 */
const PopoverEdit = ({ bookId, bookStatus, data, handleOpenPopup }: Props) => {
  const [open, setOpen] = useOpenPopover();
  const bookingMultiContext = useBookingMultiContext();
  const merchant_location_id = bookingSelectors.getCalendarParamValue('merchant_location_id');
  const dispatch = useAppDispatch();
  const note = data.noted_client || data.appointment_note;
  const { t: bookingLang } = useTranslation('booking');
  const close = () => {
    setOpen(false);
  };

  /**
   * On click duplicate
   */
  const onClickDuplicate = () => {
    dispatch(bookingActions.duplicateBooking(String(bookId)));
    close();
  };

  /**
   * On click no show
   */
  const onClickNoShow = () => {
    dispatch(bookingActions.setBookingNoShow(String(bookId)));
    close();
  };

  // const onClickDeleteBooking = () => {
  //   dispatch(bookingActions.deleteBooking(String(bookId)));
  //   close();
  // };

  /**
   * set popover close
   */
  const setPopoverClose = () => {
    setOpen(false);
    bookingMultiContext?.setPopover(false);
  };

  const disableActions: ENUM_ACTIONS[] = useMemo(() => {
    switch (bookStatus) {
      case EBookingStatus.IN_PROGRESS:
        return [ENUM_ACTIONS.NO_SHOW, ENUM_ACTIONS.CANCEL];
      case EBookingStatus.NO_SHOW:
      case EBookingStatus.COMPLETED:
        return [ENUM_ACTIONS.NO_SHOW, ENUM_ACTIONS.CANCEL, ENUM_ACTIONS.EDIT];
      case EBookingStatus.CANCELLED:
        return [ENUM_ACTIONS.NO_SHOW, ENUM_ACTIONS.CANCEL, ENUM_ACTIONS.EDIT];
      case EBookingStatus.DRAFT:
        return [ENUM_ACTIONS.NO_SHOW, ENUM_ACTIONS.EDIT, ENUM_ACTIONS.DUPLICATE];
      case EBookingStatus.OPEN: {
        // const _timeStart = moment(data.time_start);
        // const beforeTimeStart10mins = _timeStart.clone().subtract(10, 'minute');
        // const afterTimeStart10mins = _timeStart.clone().add(10, 'minute');
        // const currentTime = moment();
        // if (currentTime < beforeTimeStart10mins) {
        //   return [ENUM_ACTIONS.NO_SHOW];
        // }
        // if (currentTime >= beforeTimeStart10mins) {
        //   return [ENUM_ACTIONS.NO_SHOW, ENUM_ACTIONS.CANCEL];
        // }

        // if (currentTime >= afterTimeStart10mins) {
        //   return [ENUM_ACTIONS.CANCEL];
        // }
        return [];
      }
      default:
        return [];
    }
  }, [bookStatus]);

  // if (bookStatus === EBookingStatus.DRAFT) {
  //   return (
  //     <button type='button' onClick={onClickDeleteBooking}>
  //       <IconTrash />
  //     </button>
  //   );
  // }

  // if (bookStatus === EBookingStatus.CANCELLED) {
  //   return (
  //     <button type='button' onClick={onClickDuplicate}>
  //       <IconDuplicate />
  //     </button>
  //   );
  // }

  /**
   * On click button
   * @param e 
   */
  const onClickButton: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.stopPropagation();
    setOpen(true);
    handleOpenPopup?.(true);
  };

  return (
    <>
      <Popover
        arrow={false}
        trigger='click'
        placement='rightTop'
        open={open}
        onOpenChange={setOpen}
        content={
          <ActionsStyled>
            <ul className='more-settings'>
              {!disableActions.includes(ENUM_ACTIONS.EDIT) && (
                <Link to={`/private/bookings/edit-booking?bookId=${bookId}&merchant_location_id=${merchant_location_id}`}>
                  <li className='is-edit' onClick={() => close()}>{bookingLang('Edit')}</li>
                </Link>
              )}
              {!disableActions.includes(ENUM_ACTIONS.DUPLICATE) && (
                <li className='is-double' onClick={onClickDuplicate}>{bookingLang('Duplicate')}</li>
              )}
              {!disableActions.includes(ENUM_ACTIONS.CANCEL) && (
                <ButtonCancel id={bookId} setPopoverClose={setPopoverClose} />
              )}
              {!disableActions.includes(ENUM_ACTIONS.NO_SHOW) && (
                <li className='is-disabled' onClick={onClickNoShow}>{bookingLang('NoShow')}</li>
              )}
            </ul>
          </ActionsStyled >
        }
      >
        {!!note && <IconNote />}
        <button type='button' onClick={onClickButton}>
          <IconDash />
        </button>
      </Popover >

    </>
  );
};

export default PopoverEdit;

type IButtonCancelProps = {
  id: string | number;
  setPopoverClose: (val: boolean) => void;
};

/**
 * Button cancel
 * @param id, setPopoverClose 
 * @returns 
 */
const ButtonCancel = ({ id, setPopoverClose }: IButtonCancelProps) => {
  const dispatch = useAppDispatch();
  const currentPin = settingSelectors.getPin();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { t: bookingLang } = useTranslation('booking');
  const onClickCancel: MouseEventHandler<HTMLLIElement> = () => {
    setIsModalOpen(true);
    setPopoverClose(false);
  };

  /**
   * Handle cancel
   * @returns 
   */
  const handleCancel = () => setIsModalOpen(false);

  /**
   * Handle submit
   * @param values 
   * @param setErrors 
   * @param resetPin 
   * @returns 
   */
  const handleSubmit: IModalCancelSubmitFunc = async (values, setErrors, resetPin) => {
    if (currentPin?.toString() !== values.PIN?.toString()) {
      resetPin();
      setErrors({ pin: bookingLang('YourPinNotCorrectPleaseTryAgain') });
      return;
    }
    setIsModalOpen(false);
    dispatch(bookingActions.cancelBooking({ id: String(id), body: { note: values.note, reason_id: values.reason } }));
  };

  return (
    <>
      <li className='is-cancel' onClick={onClickCancel}>{bookingLang('Cancel')}</li>
      {!!isModalOpen && <ModalCancel visible onCancel={handleCancel} onSubmit={handleSubmit} />}
    </>
  );
};

const ActionsStyled = styled.div`
  .more-settings {
    display: block;
    position: static;
    transform: none;
    padding: 0;
    box-shadow: none;
  }
  button {
    border: none;
    background: transparent;
    display: block;
  }
`;
