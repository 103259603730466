import { createSlice } from '@reduxjs/toolkit';
import actions from './actions';
import { NAME_REDUCER } from './constants';
import { IState } from './types/reducer';
import { IOptPeriodItemResData, IVoucherItemResData, IVoucherListResData } from './types/voucher';
import { ICategoryServiceItemResData, IServiceItemResData } from './types/service';
import authActions from 'features/auth/services/actions';

const initialState: IState = {
  params: {},
  vouchers: [],
  pagination: null,
  periodOpts: [],
  services: [],
  categories: [],
  voucherDetail: null
};

export const Slice = createSlice({
  name: NAME_REDUCER,
  initialState,
  reducers: {},
  // @ts-ignore
  extraReducers: builder => {
    builder
      .addCase(authActions.logout.success, (state) => {
        state.params = {};
        state.vouchers = [];
        state.pagination = null;
        state.periodOpts = [];
        state.services = [];
        state.categories = [];
        state.voucherDetail = null;
      })
      .addCase(actions.getAllServices.success, (state, { payload }) => {
        const resData = payload as IServiceItemResData[];
        const categories: ICategoryServiceItemResData[] = [];
        state.services = resData.map(o => {
          if (!categories.find(c => c.id === o.category.id)) {
            categories.push(o.category);
          }
          return o;
        });
        state.categories = categories;
      })
      .addCase(actions.getLstPeriodOpts.success, (state, { payload }) => {
        const resData = payload as IOptPeriodItemResData[];
        state.periodOpts = resData;
      })
      .addCase(actions.setVoucherListParams.fetch, (state, { payload }) => {
        const params = {
          ...state.params,
          ...payload ?? {},
        };
        if (params.status === undefined || params.status === null) {
          delete params.status;
        }
        if (params.keyword === undefined || params.keyword === null) {
          delete params.keyword;
        }

        state.params = params;
      }).addCase(actions.setVoucherListParams.success, (state, { payload }) => {
        const resData = payload as IVoucherListResData;
        state.vouchers = resData?.data ?? [];
        state.pagination = resData.pagination ?? {};
      })
      .addCase(actions.getVoucherDetail.success, (state, { payload }) => {
        const resData = payload as IVoucherItemResData;
        state.voucherDetail = resData;
      });
  }
});

const voucherServiceReducer = Slice.reducer;
export default voucherServiceReducer;
