const IconLocationCardBooking = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="19"
      viewBox="0 0 16 19"
      fill="none"
    >
      <path
        d="M8.00943 0.854156C11.4194 0.854156 14.5871 3.58482 14.5871 7.02975C14.5871 9.30682 13.8178 10.9167 12.5285 12.7936C11.1368 14.8198 9.37248 16.7313 8.51477 17.622C8.23126 17.9165 7.7683 17.9165 7.48479 17.622C6.62707 16.7313 4.86275 14.8198 3.47101 12.7936C2.18176 10.9167 1.41248 9.30682 1.41248 7.02975C1.41248 3.58482 4.5801 0.854156 7.99005 0.854156"
        stroke="#595959"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M10.47 7.39358C10.47 8.77127 9.36765 9.91063 8.00367 9.91063C6.63969 9.91063 5.52954 8.77127 5.52954 7.39358C5.52954 6.01589 6.63969 4.97114 8.00367 4.97114C9.36765 4.97114 10.47 6.01589 10.47 7.39358Z"
        stroke="#595959"
        strokeWidth="1.5"
      />
    </svg>
  );
};

export default IconLocationCardBooking;
