import { EPaymentMethod } from 'features/checkout/services/types/checkout';
import Card from './Card';
import Cash from './Cash';
import Voucher from './Voucher';
import { IPurchaseProps } from './type';
import Mixin from './Mix';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import checkoutSelectors from 'features/checkout/services/selectors';
import checkoutActions from 'features/checkout/services/actions';
import checkoutHook from 'features/checkout/hook/checkoutHook';

/**
 * Purchase
 * @param props 
 * @returns 
 */
const Purchase = (props: IPurchaseProps) => {
  const voucherValue = checkoutSelectors.getVoucherValue() ?? 0;
  const cashValue = checkoutSelectors.getPurchaseCurrentCash() ?? 0;
  const cardValue = checkoutSelectors.getPurchaseCurrentCard() ?? 0;
  const voucherSelected = checkoutSelectors.getSelectedVoucher();
  const [method] = checkoutHook.usePaymentMethod();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(checkoutActions.setMixPayments([
      {
        data: voucherSelected,
        type: EPaymentMethod.VOUCHER,
        value: voucherValue,
      },
      {
        data: cardValue,
        type: EPaymentMethod.CARD,
        value: cardValue,
      },
      {
        data: cashValue,
        type: EPaymentMethod.CASH,
        value: cashValue,
      },
    ]));
  },[voucherValue, cashValue, cardValue]);

  
  switch (true) {
    case voucherValue === 0 && cashValue === 0 && cardValue > 0:
      return <Card {...props} />;
    case (method === EPaymentMethod.CASH && voucherValue === 0) || voucherValue === 0 && cashValue > 0 && cardValue === 0:
      return <Cash {...props} />;
    case voucherValue > 0 && cashValue === 0 && cardValue === 0:
      return <Voucher {...props} />;
    default:
      return <Mixin {...props} />;   


  // switch (method) {
  //   case EPaymentMethod.CARD:
  //     return <Card {...props} />;
  //   case EPaymentMethod.CASH:
  //     return <Cash {...props} />;
  //   case EPaymentMethod.VOUCHER:
  //     return <Voucher {...props} />;
  //   case EPaymentMethod.MIX:
  //     return <Mixin {...props} />;
  //   default:
      
  }
};

export default Purchase;
