import { Modal, Spin } from 'antd';
import PromotionForm, { IPromotionFormProps } from './Form';
import promotionSelectors from '../services/selectors';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import IconClose from 'assets/svg/IconClose';
import { useState } from 'react';
interface IPromotionModalProps extends IPromotionFormProps {
  visible?: boolean;
}
const PromotionModal = ({ visible, form, ...rest }: IPromotionModalProps) => {
  const { t: formLang } = useTranslation('form');
  const loading = promotionSelectors.getLoadingDetailPromotion();

  const { t: promotionLang } = useTranslation('promotions');

  const checkValid = async () => {
    try {
      await form.validateFields();
      return true;
    } catch (error) {
      return false;
    }
  };


  const submit = async () => {
    if (!(await checkValid())) return;
    form.submit();
  };
  const [isSelectPromotion, setIsSelectPromotion] = useState(true);
  const [isCategoryPromotion, setIsCategoryPromotion] = useState('');

  const handlePromotionSelect = (promotionType: any) => {
    setIsCategoryPromotion(promotionType);
  };

  const handlePage = () => {
    setIsSelectPromotion(false);
  };

  return (
    <ModalStyled>
      <Modal
        maskClosable={false}
        open={visible}
        // onCancel={rest.onClose}
        okButtonProps={{ style: { display: 'none' } }}
        width={1300}
        footer={null}
        forceRender
        centered
        className='promotion-manager-modal'
        closable={false}
      >
        <TitleModalPromotionStyled>
          <div className='icon-close' onClick={rest.onClose}>
            <IconClose />
          </div>
          <h2 className='modal-title'>{rest.isEdit ? formLang('promotion.editPromotionModalTitle') : formLang('promotion.addPromotionModalTitle')}</h2>
          {
            !isSelectPromotion ?
              <button className='common-btn'>
                {promotionLang('Xác nhận')}
              </button>
              :
              <button className='common-btn' onClick={handlePage} >
                {promotionLang('Tiếp tục')}
              </button>
          }
        </TitleModalPromotionStyled>
        <PromotionModalStyled className='form-wrap'>
          <Spin spinning={loading}>
            <div className='form-promotion'>
              <PromotionForm {...rest} submit={submit} form={form} isSelectPromotion={isSelectPromotion} isCategoryPromotion={isCategoryPromotion} handlePromotionSelect={handlePromotionSelect} />
            </div>
          </Spin>
        </PromotionModalStyled>
      </Modal>
    </ModalStyled>
  );
};

const ModalStyled = styled.div`
.ant-modal {
  width: 70% !important;
    @media only screen and (min-width: 1025px){
      max-width: 1148px;
      width: 70%!important;
    }
    @media only screen and (max-width: 767.98px) {
      width: 100%!important;
    }
  }
`;

const TitleModalPromotionStyled = styled.div`
display: flex;
justify-content: space-between;
align-items: center;
border-bottom: 1px solid #1B9AAA;
position: sticky;
background: white;
z-index: 99999;
top: -50px;
padding-top: 10px;
.icon-close {
  cursor: pointer;
  width: 20%;
}
.modal-title {
  width: 60%;
  margin-bottom: 0px;
  text-align: center;
}
button {
  width: 20%;
  min-width: 0px;
}
`;

const PromotionModalStyled = styled.div`
  &.form-wrap {
    justify-content: flex-start;;
  }

  height: 100%;
  display: flex;
  flex-direction: column;
  text-align: left;

  .modal-title {
    margin-bottom: 24px;
    border-bottom: none;
    text-align: left;
  }

  @media (min-width: 1181px) {
    .form-promotion {
      padding-left: 100px;
      padding-right: 100px;
    }
  }

  .ant-form {
    justify-content: center;
    align-items: center;
  }
  
`;

export default PromotionModal;
