import { IconRemove } from 'features/checkout/components/FeeButton';
import checkoutActions from 'features/checkout/services/actions';
import checkoutSelectors from 'features/checkout/services/selectors';
import React from 'react';
import { useAppDispatch } from 'store/hooks';
import { translate } from 'utils/moment/getSingleDateFormat';
import { formatMoney } from 'utils/unit';

/**
 * Voucher result row
 * @param viewOnly 
 * @returns 
 */
const VoucherResultRow = ({ viewOnly }: {viewOnly: boolean}) => {
  const result = checkoutSelectors.getVoucherValue();
  const totalOriginal = checkoutSelectors.getOriginTotalPrice();  
  const dispatch = useAppDispatch();

  /**
   * On remove
   */
  const onRemove = () => {
    dispatch(checkoutActions.setSelectedVoucherUsed(null));
  };

  if (!result) return null;
  // const {t: bookingLang} = useTranslation('booking');
  return result > 0 ? (
    <div className='extra-price-row'>
      <div className='label'>
        {!viewOnly &&
        <button type='button' onClick={onRemove}><IconRemove /></button>}
        {/* {bookingLang('Voucher')} */}
        {translate('booking', 'Voucher')}
      </div>

      <span>- {formatMoney(totalOriginal <= result ? totalOriginal : result ?? 0)}</span>
    </div>
  ) : <></>;
};

export default VoucherResultRow;
