import { ReactNode } from 'react';
import { useCheckPermission } from '../hooks/useCheckPermission';
import { ModulePermission, PackagePermissionsType } from '../services/types/permission';
import packagePermissionSelector from '../services/selectors';

interface ICanProps {
  module?: ModulePermission,
  permission?: PackagePermissionsType;
  children: ReactNode;
  limitAllow?: {limit: number, size: number};
}

const Can = ({ module, permission, children, limitAllow }: ICanProps) => {

  let isAllowLimit = true;

  if(limitAllow && limitAllow?.limit && limitAllow.size) {
    isAllowLimit = limitAllow.size < limitAllow.limit;
  }

  

  if (!module || !permission) return <div>
    {children}
  </div>;


const permissionFeatures = packagePermissionSelector.getPermissionFeature(module);


const isAllow = useCheckPermission(permissionFeatures, permission);

  return isAllow && isAllowLimit ? (
    <div>
      {children}
    </div>
  ) : <></>;
};

export default Can;