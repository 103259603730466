import { Select } from 'antd';
import workingHourActions from 'features/workingHour/services/actions';
import workingHourSelectors from 'features/workingHour/services/selectors';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
// eslint-disable-next-line @typescript-eslint/ban-types
type ILocationPickerProps = {};
const LocationPicker: React.FC<ILocationPickerProps> = () => {
  const dispatch = useAppDispatch();
  const storeLocations = workingHourSelectors.getLocations();
  const { t: workingHourLang } = useTranslation('workingHour');

  const [translatedAllLocations, setTranslatedAllLocations] = useState(workingHourLang('AllLocations'));

  useEffect(() => {
    const storedLanguage = localStorage.getItem('selectedLanguage');
    const translatedLabel = storedLanguage ? workingHourLang('AllLocations') : 'AllLocations';
    const initialSelectedLocation = { value: storedLanguage || 'all', label: translatedLabel };
    setSelectedLocation(initialSelectedLocation);
    setTranslatedAllLocations(workingHourLang('AllLocations'));
  }, [workingHourLang]);

  const locationOpts = useMemo(() => {
    const translatedLabel = translatedAllLocations;
    const lst: { value: number | string, label: string }[] = [{
      value: 'all',
      label: translatedLabel || ''
    }];
    storeLocations.forEach(item => {
      const result = ({
        value: item.id,
        label: item.name,
      });
      lst.push(result);
    });
    return lst;
  }, [storeLocations, translatedAllLocations]);

  const location = (workingHourSelectors.getParamValue('merchant_location_id') ?? 'all') as (string | number);
  const [selectedLocation, setSelectedLocation] = useState<any>(location);

  const onChangeLocation = (val: string | number | undefined) => {
    const selectedLocation = locationOpts.find((loc: { value: any; }) => loc.value === val);
    setSelectedLocation(selectedLocation);
    dispatch(workingHourActions.setParams({
      // @ts-ignore
      merchant_location_id: val !== 'all' ? val : undefined,
      employee_id: undefined,
    }));
  };

  if (storeLocations.length <= 1) return null;

  return (
    <LocationPickerStyled>
      <div className="common-select">
        <Select
          value={selectedLocation}
          options={locationOpts}
          className='is-normal'
          onChange={onChangeLocation}
        />
      </div>
    </LocationPickerStyled>
  );
};

export default LocationPicker;
const LocationPickerStyled = styled.div``;
