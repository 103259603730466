import styled from 'styled-components';
import SettingCard from 'components/common/Cards/SettingCard';
import FrameSetting from 'components/common/FrameSetting';
import RoleItem from '../../components/widgets/RoleItem';
import { Divider } from 'antd';
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import settingActions from 'features/settings/services/actions';
import settingSelectors from 'features/settings/services/selectors';
import { IRole } from 'features/settings/services/types/role';
import PermissionSettingFormPage from './PermissionSettingFormPage';
import userActions from 'features/users/services/actions';
import uiActions from 'services/UI/actions';
import { AxiosResponse } from 'axios';
import settingApis from 'features/settings/services/apis';
import { get } from 'lodash';
import message from 'components/common/Message';
import ModalConfirm, { ModalConfirmRef } from 'components/common/Modal/ModalConfirm';

const PermissionSettingPage = () => {
  const dispatch = useDispatch();

  const listRole = settingSelectors.rolePermission.getListRole();
  const [roleSelected, setRoleSelected] = useState<IRole>();
  const [isAdd, setIsAdd] = useState<boolean>(false);
  const modalConfirmRef = useRef<ModalConfirmRef>(null);

  useEffect(() => {
    dispatch(settingActions.getListRole.fetch());
    dispatch(settingActions.getPermissionPackages.fetch());
    dispatch(userActions.getListPaginateStaffs.fetch({}));
  }, []);

  useEffect(() => {
    if(!listRole) dispatch(uiActions.setLoadingPage(true));
    else dispatch(uiActions.setLoadingPage(false));
  }, [listRole]);

  const onBack = () => {
    setRoleSelected(undefined);
    setIsAdd(false);

  };

  const Header = () => {
    return (
      <HeaderStyled className='header'>
        <button onClick={() => {
          setIsAdd(true);
          }} className='common-btn'> Thêm vai trò </button>
      </HeaderStyled>
    );
  };

  if(isAdd) return <PermissionSettingFormPage onBack={onBack} />;

  if(roleSelected) return <PermissionSettingFormPage role={roleSelected} onBack={onBack} />;

  const onChangeRole = async (checked: boolean ,role: IRole) => {
    if(!checked && role?.staff_applied > 0) {
      modalConfirmRef.current?.show({
        msg: 'Chức vụ này đã được gán cho một hoặc nhiều user. Việc vô hiêu hoá chức vụ sẽ dẫn đến việc người dùng không còn quyền truy cập vào các chức năng liên quan.',
        submit: () => undefined,
        title: 'Chức vụ không thể vô hiệu quá'
      });
      return;
    }

    try {
      dispatch(uiActions.setLoadingPage(true));
      const rest: AxiosResponse<{ message: string }> = await settingApis.rolePermission.updateRole(role.id, {
        list_permission: role.permissions.map(per => per.id),
        name: role.name,
        user_ids: role.user_ids,
        status: checked
      });

      const msg = get(rest, 'data.message', '');
      if (msg) {
        message.success(msg);
        dispatch(settingActions.getListRole.fetch());
        return true;
      }
      return false;
    } catch (error: any) {
      const response = get<Record<string, string[]>>(
        error,
        'response.data.errors',
        {}
      );

      const errors = Object.values(response);
      errors.map((value) => {
        message.error(get(value, [0], ''));
      });

      return false;
    } finally {
      dispatch(uiActions.setLoadingPage(false));
      onBack();
    }
  };

  return (
    <PermissionSettingPageStyled header={<Header />}>
      <FrameSetting title='Phân quyền' description='Cài đặt vai trò và phân quyền của thệ thống'>
        {listRole?.map((role: IRole) => (
          <div key={role.id}>
            <RoleItem onChangeRole={(checked) => onChangeRole(checked, role)} toDetail={() => setRoleSelected(role)} checked={role.status === 1} description='' roleName={role.name} />
            <Divider />
          </div>
        ))}

      </FrameSetting>
      <ModalConfirm ref={modalConfirmRef} showClose={false} />

    </PermissionSettingPageStyled>
  );
};

export default PermissionSettingPage;

const HeaderStyled = styled.div`
  width: '100%';
    display: flex;
    justify-content: flex-end;
`;

const PermissionSettingPageStyled = styled(SettingCard)`

  .body {
    display: flex;
    width: 500px;
    gap: 24px;
    flex-direction: column;
    padding: 24px;
    border-radius: 12px;
    border: 1px solid #BFBFBF;
  }
`;

