import React, { useState } from 'react';
import styled from 'styled-components';
import styles from '../../pages/index.module.scss';
import { useTranslation } from 'react-i18next';
import { IconInfo, IconRight } from 'assets/svg';
import { Button, Form, Input, Select, Switch } from 'antd';
import IconPlusCircle from 'assets/svg/IconPlusCircle';

const Expiry = () => {
    const { t: productsLang } = useTranslation('products');
    const options = [
        {
            'id': 1,
            'value': 'a'
        },
        {
            'id': 2,
            'value': 'b'
        }
    ];

    const [expiryFields, setExpiryFields] = useState(1);

    const handleAddExpiry = () => {
        setExpiryFields((prevFields) => prevFields + 1);
    };

    const [isAccount, setIsAccount] = useState(Array(expiryFields).fill(false));

    const toggleAccount = (index: any) => {
        setIsAccount((prevStates) => {
            const newStates = [...prevStates];
            newStates[index] = !newStates[index];
            return newStates;
        });
    };

    const expiryTable = [];
    for (let i = 0; i < expiryFields; i++) {
        // const showAdditionalContent = isAccount[i];
        expiryTable.push(
            <div className={styles.consignment_expity_table}>
                <div onClick={() => toggleAccount(i)} className={styles.title_expity_table}>
                    <p className={styles.title_expity}>{productsLang('Consignment')} {i + 1}</p>
                    <div className={`${styles.iconright_expity} ${isAccount[i] ? styles.rotate180 : ''}`}>
                        <IconRight />
                    </div>
                </div>
                <div className={styles.outOfDate_container}>
                    <IconInfo /> <span>Lô hàng sắp hết hạn sử dụng</span>
                </div>
                {isAccount[i] && (
                    <>
                        <div className={styles.consignment_expity_item}>
                            <Form.Item label={productsLang('LotNumber')}
                                rules={[
                                    { required: true, message: productsLang('PleaseEnterIDNumber') || '' }
                                ]}>
                                <Input placeholder={productsLang('EnterIDNumber') || ''} />
                            </Form.Item>
                            <Form.Item label={productsLang('Expiry')}
                                rules={[
                                    { required: true, message: productsLang('PleaseEnterExpity') || '' }
                                ]}>
                                <Input placeholder={'DD.MM.YYY'} />
                            </Form.Item>
                        </div>
                        <Form.Item label={productsLang('IncludesClassification')}
                            rules={[
                                { required: true, message: productsLang('PleaseEnterUnitOfMeasure') || '' }
                            ]}>
                            <Select
                                placeholder={productsLang('SelectTeamMember')}
                                defaultValue={options.find(option => option.id === 1)}
                                options={options}
                            />
                        </Form.Item>
                    </>
                )}
            </div>
        );
    }

    return (
        <ExpityPageStyled>
            <div className={styles.container_expity}>
                <div className={styles.left_expity}>
                    <div className={styles.expity_container}>
                        <p className={styles.title_expity}>{productsLang('Expiry')}</p>

                        {/* <div className={styles.consignment_expity_table}>
                            <div className={styles.title_expity_table}>
                                <p className={styles.title_expity}>{productsLang('Consignment')} 1</p>
                                <div className={styles.iconright_expity}>
                                    <IconRight />
                                </div>
                            </div>
                            <div className={styles.consignment_expity_item}>
                                <Form.Item label={productsLang('LotNumber')}
                                    rules={[
                                        { required: true, message: productsLang('PleaseEnterIDNumber') || '' }
                                    ]}>
                                    <Input placeholder={productsLang('EnterIDNumber') || ''} />
                                </Form.Item>
                                <Form.Item label={productsLang('Expiry')}
                                    rules={[
                                        { required: true, message: productsLang('PleaseEnterExpity') || '' }
                                    ]}>
                                    <Input placeholder={'DD.MM.YYY'} />
                                </Form.Item>
                            </div>
                            <Form.Item label={productsLang('IncludesClassification')}
                                rules={[
                                    { required: true, message: productsLang('PleaseEnterUnitOfMeasure') || '' }
                                ]}>
                                <Select
                                    placeholder={productsLang('SelectTeamMember')}
                                    defaultValue={options.find(option => option.id === 1)}
                                    options={options}
                                />
                            </Form.Item>
                        </div> */}
                        {expiryTable}
                        <Button onClick={handleAddExpiry}><IconPlusCircle /> {productsLang('NewBatchAdded')}</Button>
                    </div>
                </div>
                <div className={styles.right_expity}>
                    <div className={styles.NoticeOfExpiration}>
                        <div className={styles.title_NoticeOfExpiration_table}>
                            <p className={styles.title_NoticeOfExpiration}>{productsLang('NoticeExpiration')}</p>
                            <p className={styles.note_NoticeOfExpiration}>{productsLang('NoteNoticeOfExpiration')}.</p>
                        </div>
                        <div className={styles.switch_setting_NoticeOfExpiration_item}>
                            <span>{productsLang('AllowToReceiveNotifications')}</span>
                            <Switch defaultChecked />
                        </div>

                        <Form.Item label={productsLang('ChooseADeadline')}
                            rules={[
                                { required: true, message: productsLang('PleaseChooseADeadline') || '' }
                            ]}>
                            <Select
                                placeholder={productsLang('EnterChooseADeadline')}
                                defaultValue={options.find(option => option.id === 1)}
                                options={options}
                            />
                        </Form.Item>

                    </div>
                </div>
            </div>
        </ExpityPageStyled>
    );
};

export default Expiry;

const ExpityPageStyled = styled.div`
.ant-form-item {
    margin-bottom: 24px !important;
    .ant-row {
        display: block; 
    }
}

.ant-select-selector {
    min-width: 0px !important;
}
.ant-btn-default {
    margin-top: 12px;
    width: 100%;
    border: 0px;
    font-size: 18px;
    font-weight: 500;
    line-height: 140%;
    color: #1B9AAA;
}
.ant-form-item-label > label {
    font-weight: 600;
    font-size: 16px;
}
`;