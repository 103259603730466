import styled from 'styled-components';
import MemberHorizontalPickerItem from './MemberHorizontalPickerItem';
import { useEffect, useMemo, useState } from 'react';
import bookingSelectors from 'features/bookings/services/selectors';
import { useDispatch } from 'react-redux';
import bookingActions from 'features/bookings/services/actions';
import apisBooking from 'features/bookings/services/apis';
import { IApiBookingParams } from 'features/bookings/services/types/booking';


export const itemWidth = 120;
export const itemGap = 12;
const numView = 2;

/**
 * Render member horizontal picker (Mobile)
 * @returns 
 */
const MemberHorizontalPicker = () => {

  const teamMembers = bookingSelectors.getTeamMembers();
  const teamSelect = bookingSelectors.getCalendarParamValue('employee_id');
  const [mrItem, setMrItem] = useState(0);
  const distSlider = itemWidth + itemGap;
  const merchant_location_id = bookingSelectors.getCalendarParamValue('merchant_location_id') as IApiBookingParams['merchant_location_id'];  
  const dispatch = useDispatch();


  const options = useMemo(() => {    
    if(!teamMembers || !merchant_location_id) return [];

    const teamMemberOption = teamMembers.filter(o => o.merchant_location_id?.toString() === merchant_location_id?.toString());
    
    return teamMemberOption;
    
  }, [ teamMembers, merchant_location_id ]);

  useEffect(() => {

    if(!options || options.length < 0) return;

    dispatch(bookingActions.setCalendarBookingParams({
      employee_id: [options?.[0]?.id ?? undefined],
    }));
  }, [options]);

  const prevSlide = () => {
    setMrItem(mrItem => {
      if( mrItem >= 0 ) return 0;

      const distItem = mrItem + distSlider;
      return distItem;
    });
  };

  const nextSlide = () => {

    const maxDist = (teamMembers.length - numView) * itemWidth;

    setMrItem(mrItem => {
      if(mrItem <= -maxDist) return mrItem;

      const distItem = mrItem - distSlider;
      return distItem;
    });
  };  

  const onSelect = async (val: string | number) => {
    
    dispatch(bookingActions.setCalendarBookingParams({
      employee_id: [val],
    }));

    await apisBooking.storeParamOptionCalendar({action_behavior: 'employee_filter', action_behavior_value: [ val ]});
  };

  return (
    <MemberHorizontalPickerStyled>
      <div className='arrow' onClick={prevSlide}>
        <IconArrowLeft/>
      </div>
      <div className='container-slider'>
        <div className='slider'>
          {/* <div className='container-items'> */}
          {options.map((team, index) => (
            <MemberHorizontalPickerItem 
              key={index} 
              onSelect={() => onSelect(team.id ?? '')} 
              name={team.full_name} 
              mr={mrItem} 
              //@ts-ignore
              isSelected={(teamSelect?.[0] ?? -1) === team.id} />

          ))}
          {/* </div> */}
        </div>
      </div>
      <div className='arrow' onClick={nextSlide}>
        <IconArrowRight/>
      </div>
    </MemberHorizontalPickerStyled>
  );
};

export default MemberHorizontalPicker;

const MemberHorizontalPickerStyled = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding: 8px;
  .container-slider {
    display: flex;
    justify-content: center;
    width: 100%;

    .slider {
      overflow-x: hidden;
      max-width: ${itemWidth * numView + itemGap}px;
      display: flex;
      flex-wrap: nowrap;
      gap: ${itemGap}px;
      .container-items {
      }
    }
  }

  .arrow {
    cursor: pointer;
  }
`;

const IconArrowLeft = () => {
  return (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M15 18.5L9 12.5L15 6.5" stroke="#141414" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
};

const IconArrowRight = () => {
  return (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9 18.5L15 12.5L9 6.5" stroke="#141414" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
};