import { get } from 'lodash';
import { RootState } from 'store';
import { useAppSelector } from 'store/hooks';
import { IService, IWorkingHour, IBookingCancel, ISummary, IRevenueService } from './types/api';


type MyState = RootState['analytic'];

const getCurrentState = (state: RootState): MyState => state.analytic;

const selector = <T = MyState>(key: keyof T, defaultValue?: any) => useAppSelector(state => get(getCurrentState(state), key, defaultValue));

// const getIdBookingDetail = () => selector('id') as (string | number | null);

// new_customer: {},
//     : [],
//     working_hours: [],
//     booking_cancel: {},

const getNewCustomer = () => selector('analytic.new_customer') as ISummary;

const getListService = () => selector('analytic.category_service') as IService[] ?? [];

const getWorkingHours = () => selector('analytic.working_hours') as IWorkingHour[] ?? [];

const bookingCancel = () => selector('analytic.booking_cancel') as IBookingCancel;

const booking = () => selector('analytic.booking') as any;

const getVoucher = () => selector('analytic.voucher') as ISummary;

const getPromotion = () => selector('analytic.promotion') as ISummary;

const getReview = () => selector('analytic.review') as ISummary;

const getTotalDiscount = () => selector('analytic.total_discount') as ISummary;

const getRevenueService = () => selector('analytic.revenue_service') as IRevenueService[] ?? [];

const getSaleFigures = () => selector('analytic.sale_figures') as any;

const getAverageSpendCustomer = () => selector('analytic.average_spend_customer') as ISummary;

const getTotalSale = () => selector('analytic.total_sale') as ISummary;

const analyticSelectors = {
  // getIdBookingDetail,
  getNewCustomer,
  getListService, 
  getWorkingHours,
  bookingCancel,
  booking,
  getVoucher,
  getRevenueService,
  getSaleFigures,
  getPromotion,
  getReview,
  getAverageSpendCustomer,
  getTotalSale,
  getTotalDiscount
};
export default analyticSelectors;
