import { Grid } from 'antd';
import { useMemo } from 'react';

const { useBreakpoint } = Grid;

/**
 * Hook detach screen
 * @returns 
 */
const useDetachScreen = () => {
  const screens = useBreakpoint();
  const isBrowser = useMemo(() => {
    const { lg, xl, xxl } = screens;
    if (lg || xl || xxl) return true;
    return false;
  }, [screens]);

  const isTablet = useMemo(() => {
    const { md } = screens;
    if (md) return true;
    return false;
  }, [screens]);

  return {
    isBrowser,
    isTablet,
    isMobile: !isBrowser && !isTablet,
  };
};

export default useDetachScreen;
