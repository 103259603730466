export enum TopUpType {
  AUTOMATIC = 'AUTOMATIC',
  MANUALLY = 'MANUALLY',
}

export enum TopUpStatus {
  SUCCESS = 'SUCCESS',
  PROCESSING = 'PROCESSING',
  FAILED = 'FAILED',
}

export interface StripeManualReq {
  payment_method: string;
  amount: number;
  stripeToken: string;
  manual_topup_notify?: number;
  auto_charge_amount?: number;
  type?: string
}
