/**
 * Handle format money
 * @param number 
 * @returns 
 */
const formatMoney = (number: any) => {
    if (typeof number !== 'number') {
      return '';
    }
    return number.toFixed(3).replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  };

const MoneyValidations = {
    formatMoney
};
export default MoneyValidations;