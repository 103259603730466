import { useTranslation } from 'react-i18next';
import ProgramAdd from '../widgets/Program/Add';
import ProgramsTable from './ProgramsTable';
import Can from 'features/packagePermission/components/Can';
import { ModulePermission, ProgramPermission } from 'features/packagePermission/services/types/permission';

const ProgramsTab = () => {
  const {t: loyaltyProgramLang} = useTranslation('loyaltyProgram');
  return (
    <>
      <div className='cards-wrapper'>
        <div className='cards-col'>
          <div className='common-card'>
            <div className='card-heading'>
              <div className='card-title is-small'>
                <div>
                  <p>{loyaltyProgramLang('EarnPoints')}</p>
                  {/* <span>{loyaltyProgramLang('AllWaysThatYourCustomersCanEarnPoints')}</span> */}
                </div>
                <Can module={ModulePermission.PROGRAMS} permission={ProgramPermission.ADD_PROGRAM}>
                  <ProgramAdd />
                </Can>
              </div>
            </div>
            <ProgramsTable />
          </div>
        </div>
      </div>
    </>
  );
};

export default ProgramsTab;
