export const displayError = (error: any) => {
  const messageText = error?.response?.data?.error?.message;

  if (messageText){
    return messageText;
  }else {
    const errors = error?.response?.data?.errors;

    if (errors) {
      const errorArray = Object.entries(errors).flatMap(([key, value]) => {
        return (value as any).map((errorMessage: any) => ({ key, errorMessage }));
      }); 

      if (errorArray?.length){
        return errorArray?.[0]?.errorMessage;
      }
    }
  }
  return 'An error occurred. Please try again';
};