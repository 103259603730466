const IconCustomers = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12.4566 14.5532C9.00666 14.5532 6.0957 15.117 6.0957 17.2927C6.0957 19.4694 9.02546 20.0133 12.4566 20.0133C15.9066 20.0133 18.8176 19.4495 18.8176 17.2738C18.8176 15.0972 15.8878 14.5532 12.4566 14.5532" fill="#595959"/>
      <path opacity="0.4" d="M12.4568 12.4802C14.793 12.4802 16.6661 10.5962 16.6661 8.2467C16.6661 5.89625 14.793 4.01318 12.4568 4.01318C10.1207 4.01318 8.24756 5.89625 8.24756 8.2467C8.24756 10.5962 10.1207 12.4802 12.4568 12.4802" fill="#595959"/>
      <path opacity="0.4" d="M21.5959 9.23241C22.2003 6.85495 20.4283 4.71973 18.1719 4.71973C17.9265 4.71973 17.6919 4.74674 17.4627 4.79267C17.4323 4.79988 17.3982 4.81519 17.3803 4.8422C17.3597 4.87642 17.375 4.92235 17.3973 4.95207C18.0752 5.90846 18.4647 7.07288 18.4647 8.32285C18.4647 9.52059 18.1074 10.6373 17.4806 11.564C17.4161 11.6594 17.4734 11.7882 17.5872 11.808C17.7448 11.8359 17.9059 11.8503 18.0707 11.8548C19.7137 11.8981 21.1885 10.8345 21.5959 9.23241" fill="#595959"/>
      <path d="M23.3172 14.8301C23.0164 14.1853 22.2902 13.7432 21.1862 13.5261C20.665 13.3983 19.2548 13.2181 17.943 13.2425C17.9233 13.2452 17.9126 13.2587 17.9108 13.2677C17.9081 13.2803 17.9135 13.3019 17.9394 13.3154C18.5456 13.6171 20.8889 14.9292 20.5943 17.6966C20.5818 17.8164 20.6776 17.9199 20.7967 17.9019C21.3733 17.8191 22.857 17.4985 23.3172 16.4998C23.5715 15.972 23.5715 15.3588 23.3172 14.8301" fill="#595959"/>
      <path opacity="0.4" d="M7.5524 4.79316C7.32407 4.74633 7.08858 4.72021 6.84324 4.72021C4.58683 4.72021 2.81482 6.85543 3.42012 9.2329C3.82663 10.835 5.30136 11.8985 6.94442 11.8553C7.10918 11.8508 7.27124 11.8355 7.42794 11.8085C7.54166 11.7887 7.59896 11.6599 7.53449 11.5644C6.90771 10.6369 6.55044 9.52108 6.55044 8.32334C6.55044 7.07247 6.94084 5.90805 7.61866 4.95256C7.64015 4.92284 7.65627 4.87691 7.63478 4.84269C7.61687 4.81477 7.58374 4.80036 7.5524 4.79316" fill="#595959"/>
      <path d="M3.82937 13.5259C2.72534 13.7429 2.00006 14.1851 1.6992 14.8299C1.44402 15.3585 1.44402 15.9718 1.6992 16.5004C2.15944 17.4982 3.64312 17.8197 4.21976 17.9017C4.33885 17.9197 4.43376 17.817 4.42123 17.6964C4.12664 14.9298 6.46991 13.6177 7.07699 13.3161C7.10206 13.3016 7.10744 13.2809 7.10475 13.2674C7.10296 13.2584 7.09311 13.2449 7.07341 13.2431C5.76075 13.2179 4.35139 13.398 3.82937 13.5259" fill="#595959"/>
    </svg>
  );
};

export default IconCustomers;
