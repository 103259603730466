import { message } from 'antd';
import { IMessageProps } from '../type';
import { translate } from 'utils/moment/getSingleDateFormat';

/**
 * Handle custom success message
 * @param props 
 * @returns 
 */
const CustomSuccessMessage = (props: IMessageProps) => {
  const _text = typeof props === 'string' ? props : props?.text;
  return message.success(
    <p>
      <span>{translate('common', 'message.success')}:</span>{_text}
    </p>
  );
};

export default CustomSuccessMessage;
