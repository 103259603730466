import message from 'components/common/Message';
import { ModalConfirmRef } from 'components/common/Modal/ModalConfirm';
import loyalActions from 'features/loyaltyProgram/services/actions';
import apisLoyal from 'features/loyaltyProgram/services/apis';
import { IProgramItemResData } from 'features/loyaltyProgram/services/types/program';
import { get } from 'lodash';
import React, { RefObject } from 'react';
import { useTranslation } from 'react-i18next';
import { useSetLoadingPage } from 'services/UI/LoadingPage';
import { useAppDispatch } from 'store/hooks';
type Props = {
  data: IProgramItemResData;
  confirmRef?: RefObject<ModalConfirmRef>;
};
const ProgramRemove = ({ confirmRef, data }: Props) => {
  const dispatch = useAppDispatch();
  const setPageLoading = useSetLoadingPage();
  const {t: loyaltyProgramLang} = useTranslation('loyaltyProgram');
  const handleDeleteProgram = async () => {
    if (!data) return;
    setPageLoading(true);
    try {
      const res = await apisLoyal.removeProgram(data.id?.toString());
      dispatch(loyalActions.getListLoyalProgram.fetch({}));
      const msg = get(res, 'data.message', '');
      if (msg) {
        message.success(msg);
      }
    } catch (error) {
      const errors = Object.entries(get(error, 'response.data.errors', {}));
      if (errors.length > 0) {
        errors.map(([, valueArr]) => {
          message.error(get(valueArr, [0], ''));
        });
        return;
      }
      message.error(loyaltyProgramLang('AnErrorOccurredPleaseTryAgain')||'');
    } finally {
      setPageLoading(false);
    }
  };

  const onClick = () => {
    if (!confirmRef) return;
    confirmRef.current?.show({
      title: loyaltyProgramLang('AreYouSure'),
      icon: 'delete',
      msg: loyaltyProgramLang('AreYouSureRemoveThisProgram'),
      submit: handleDeleteProgram,
    });
  };

  return (
    <>
      <li onClick={onClick} className='action-remove'></li>
    </>
  );
};

export default ProgramRemove;