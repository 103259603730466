import salesSelectors from 'features/sales/services/selectors';
import { useTranslation } from 'react-i18next';
import { formatMoney } from 'utils/unit';

const RewardResultRow = () => {
  const {t: saleLang} = useTranslation('sales');
  const result = salesSelectors.getRewardValue();

  if (!result) return null;
  
  return (
    <p className='extra-price-row'>
      {saleLang('Reward')}
      <span>- {formatMoney(result)}</span>
    </p>
  );
};

export default RewardResultRow;
