import { IResInvoiceItem } from 'features/invoices/services/types/invoice.js';
import InvoiceInfoItem from './InvoiceInfoItem.tsx';
import { getFormatSingeDate } from 'utils/moment/getSingleDateShortFormat';
import InvoiceInfoPaymentItem from './InvoiceInfoPaymentItem.tsx';
import { useTranslation } from 'react-i18next';

const InfoIssueDate = ({data} : {data?: IResInvoiceItem | null}) => {
  const {t: invoiceLang} = useTranslation('invoice');
  const {t: dateLang} = useTranslation('date');
  return (
    <table className='invoice-info'>
        <tr>
            <th className='invoice-info-title'>{invoiceLang('ISSUEDDATE')}</th>
            <td className='invoice-info-title'>{invoiceLang('PAYMENTMETHOD')}</td>
        </tr>
        <tr>
            <InvoiceInfoItem content={getFormatSingeDate(data?.created_at ?? '', dateLang) ?? ''}/>
            <InvoiceInfoPaymentItem content={data?.payment_methods ? invoiceLang(data.payment_methods === 'mixin' ? 'mixed' : data.payment_methods) : ''}/>
        </tr>
    </table>
  );
};

export default InfoIssueDate;