const IconNameVoucher = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="19"
      viewBox="0 0 20 19"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 18.5C1.79086 18.5 0 16.7091 0 14.5V13.5C0 12.9477 0.460003 12.5163 0.981894 12.3356C2.15653 11.9289 3 10.813 3 9.5C3 8.18703 2.15653 7.07105 0.981894 6.66437C0.460003 6.48368 0 6.05228 0 5.5V4.5C0 2.29086 1.79086 0.5 4 0.5H16C18.2091 0.5 20 2.29086 20 4.5V5.5C20 6.05228 19.54 6.48368 19.0181 6.66437C17.8435 7.07105 17 8.18703 17 9.5C17 10.813 17.8435 11.9289 19.0181 12.3356C19.54 12.5163 20 12.9477 20 13.5V14.5C20 16.7091 18.2091 18.5 16 18.5H4ZM7 7.5C7.55228 7.5 8 7.05228 8 6.5C8 5.94772 7.55228 5.5 7 5.5C6.44772 5.5 6 5.94772 6 6.5C6 7.05228 6.44772 7.5 7 7.5ZM14 12.5C14 13.0523 13.5523 13.5 13 13.5C12.4477 13.5 12 13.0523 12 12.5C12 11.9477 12.4477 11.5 13 11.5C13.5523 11.5 14 11.9477 14 12.5ZM13.5303 7.03033C13.8232 6.73744 13.8232 6.26256 13.5303 5.96967C13.2374 5.67678 12.7626 5.67678 12.4697 5.96967L6.46967 11.9697C6.17678 12.2626 6.17678 12.7374 6.46967 13.0303C6.76256 13.3232 7.23744 13.3232 7.53033 13.0303L13.5303 7.03033Z"
        fill="#12646F"
      />
    </svg>
  );
};

export default IconNameVoucher;
