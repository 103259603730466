const IconUnsubcribedEmail = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="181"
      height="181"
      viewBox="0 0 181 181"
      fill="none"
    >
      <g clipPath="url(#clip0_3672_178812)">
        <path
          d="M90.5 180.224C140.206 180.224 180.5 139.93 180.5 90.2239C180.5 40.5182 140.206 0.223877 90.5 0.223877C40.7944 0.223877 0.5 40.5182 0.5 90.2239C0.5 139.93 40.7944 180.224 90.5 180.224Z"
          fill="url(#paint0_linear_3672_178812)"
        />
        <path
          d="M115.482 74.5793C121.106 74.5793 126.404 75.9673 131.095 78.3794V48.0325L86.0721 80.2536C85.5293 80.6417 84.8887 80.8372 84.2545 80.8372C83.6203 80.8372 82.9797 80.6417 82.4369 80.2536L37.4141 48.0279V102.74C37.4141 104.469 38.8108 105.868 40.5366 105.868H81.2901C82.8753 88.3532 97.598 74.5793 115.482 74.5793ZM126.975 43.2903H41.5414L84.2545 73.8643L126.975 43.2903ZM115.482 80.8372C99.9602 80.8372 87.3774 93.4449 87.3774 108.997C87.3774 124.55 99.9602 137.157 115.482 137.157C131.003 137.157 143.586 124.55 143.586 108.997C143.586 93.4449 131.003 80.8372 115.482 80.8372ZM127.972 112.126H102.991C101.264 112.126 99.8681 110.727 99.8681 108.997C99.8681 107.268 101.265 105.868 102.991 105.868H127.972C129.699 105.868 131.095 107.268 131.095 108.997C131.095 110.727 129.699 112.126 127.972 112.126Z"
          fill="url(#paint1_linear_3672_178812)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_3672_178812"
          x1="90.5"
          y1="180.224"
          x2="90.5"
          y2="0.223877"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFC2CC" />
          <stop offset="1" stopColor="#FFF2F4" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_3672_178812"
          x1="90.5"
          y1="137.157"
          x2="90.5"
          y2="43.2903"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFA68D" />
          <stop offset="1" stopColor="#FD3A84" />
        </linearGradient>
        <clipPath id="clip0_3672_178812">
          <rect
            width="180"
            height="180"
            fill="white"
            transform="translate(0.5 0.223877)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default IconUnsubcribedEmail;
