import { Avatar, List } from 'antd';
import React from 'react';
import styles from '../../pages/index.module.scss';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import serviceActions from 'features/services/services/actions';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
const ProductTab = () => {
    const { t: productsLang } = useTranslation('products');
    const [searchParams, setSearchParams] = useSearchParams();
    const dispatch = useDispatch();
    const data = [
        {
            avatar: 'https://xsgames.co/randomusers/avatar.php?g=pixel&key',
            title: 'MUK red hair wax',
            id_product: 'ABC123'
        },
        {
            avater: 'https://xsgames.co/randomusers/avatar.php?g=pixel&key',
            title: 'MUK red hair wax',
            id_product: 'ABC123'
        },
        {
            avater: 'https://xsgames.co/randomusers/avatar.php?g=pixel&key',
            title: 'MUK red hair wax',
            id_product: 'ABC123'
        },
        {
            avater: 'https://xsgames.co/randomusers/avatar.php?g=pixel&key',
            title: 'MUK red hair wax',
            id_product: 'ABC123'
        },
    ];
    return (
        <ProductTabStyled>
            <div className={styles.container_infotmation}>
                <div className={styles.Information_item}>
                    <p className={styles.title_overview}>{productsLang('ListProduct')}</p>
                    <List
                        dataSource={data}
                        renderItem={(item) => (
                            <List.Item>
                                <List.Item.Meta
                                    avatar={
                                        <Avatar src={item.avatar} />
                                    }
                                    title={<a href="#">{item.title}</a>}
                                    description={productsLang('IDProduct')+':' + item.id_product}
                                />
                            </List.Item>
                        )}
                        pagination={{
                            defaultCurrent: Number(searchParams.get('page') ?? 1),
                            defaultPageSize: Number(searchParams.get('per_page') ?? 10),
                            // total: data?.total_record,
                            onChange: (pageChange, sizeChange) => {
                                const params: any = {
                                    page: pageChange == 0 ? 1 : pageChange,
                                    per_page: sizeChange,
                                };
                                setSearchParams(params);

                                dispatch(serviceActions.getListPaginateServices.fetch({
                                    page: params.page,
                                    per_page: params.per_page,
                                }));
                            },
                            showSizeChanger: false,
                        }}
                    />
                </div>
            </div>
        </ProductTabStyled>
    );
};

export default ProductTab;
const ProductTabStyled = styled.div`
.ant-pagination {
    display: flex;
    justify-content: center;
}
.ant-avatar {
    width: 48px;
    height: 48px; 
    border-radius: 8px;
}
.ant-list .ant-list-item .ant-list-item-meta .ant-list-item-meta-title >a {
    font-size: 16px;
    font-weight: 500;
    line-height: 140%;
    text-decoration-line: underline;
    color: #1B9AAA;
}
.ant-list .ant-list-item .ant-list-item-meta .ant-list-item-meta-description {
    color: #595959;
    font-size: 14px;
    font-weight: 400;
    line-height: 140%;
}
`;