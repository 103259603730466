import { RootState } from 'store';
import { IApiTimeScheduleListReq } from '../types/api';
import { useAppSelector } from 'store/hooks';
import { get } from 'lodash';
import uiSelector from 'services/UI/selectors';
import { PATH_LOADING } from './constants';

type MyState = RootState['timeSchedule'];


const getCurrentState = (state: RootState): MyState => state.timeSchedule;

export const getPureParams = (root: RootState) => {
    const state = getCurrentState(root);
    return state.params as IApiTimeScheduleListReq;
};

const selector = <T = keyof MyState>(key: T, defaultValue?: any) => useAppSelector(state => get(getCurrentState(state), key as any, defaultValue));

const getParams = () => selector('params') as MyState['params'];

const getTimeScheduleParamValue = (key: keyof IApiTimeScheduleListReq) => selector(`params.${key}`) as IApiTimeScheduleListReq[typeof key];

const getLstTimeSchedule = () => selector('timeSchedules') as MyState['timeSchedules'];

const getLstTimeScheduleOnline = () => selector('timeSchedulesOnline') as MyState['timeSchedulesOnline'];

const getLstTimeSchedulePagination = () => selector('pagination') as MyState['pagination'];

const getMerchantLocation = () => selector('merchantLocations') as MyState['merchantLocations'];

const getMerchantTeamMember = () => selector('teamMembers') as MyState['teamMembers'];

const getLoadingDetailTimeSchedule = () => uiSelector.getLoading(PATH_LOADING.getDetailTimeSchedules) as boolean;

const getLoadingLstTimeSchedules = () => uiSelector.getLoading(PATH_LOADING.getListTimeSchedules) as boolean;

const getLoadingLstTimeScheduleOnline = () => uiSelector.getLoading(PATH_LOADING.getListTimeScheduleOnline) as boolean;

const getLocationDetail = () => selector('locationSelectedDetail') as MyState['locationSelectedDetail'];

const getLoadingLocationDetail = () => uiSelector.getLoading(PATH_LOADING.getLocationDetail) as boolean;


const timeScheduleSelectors = {
    getParams,
    getLstTimeSchedule,
    getLstTimeSchedulePagination,
    getLoadingDetailTimeSchedule,
    getLoadingLstTimeSchedules,
    getMerchantLocation,
    getMerchantTeamMember,
    getTimeScheduleParamValue,
    getLstTimeScheduleOnline,
    getLoadingLstTimeScheduleOnline,
    getLocationDetail,
    getLoadingLocationDetail

};

export default timeScheduleSelectors;