import { Select } from 'antd';
import { IServiceView } from '../services/types/services';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const ServiceSelectView = () => {

    const navigate = useNavigate();
    const {t: serviceLang} = useTranslation('service');

    const serviceViewOption: IServiceView[] = [
        {
            value: '/private/services',
            label: serviceLang('ServiceView')
        },
        {
            value: '/private/services/categories',
            label: serviceLang('CategoryView')
        },
    ];

    const value = () => {
        const option = serviceViewOption.find(o => o.value === window.location.pathname);
        return option?.value;
    };

    const onChange = (valueLink: string) => {
        navigate(`${valueLink}`);    
    };

    return (
        <div className='common-select'>
          <Select options={serviceViewOption} onChange={onChange} value={value()} />
        </div>
    );
};

export default ServiceSelectView;