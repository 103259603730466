const IconSrvice = () => {
    return (
        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path opacity="0.4" d="M2 13.5137C2 12.4091 2.89543 11.5137 4 11.5137H20C21.1046 11.5137 22 12.4091 22 13.5137V17.5137C22 19.7228 20.2091 21.5137 18 21.5137H6C3.79086 21.5137 2 19.7228 2 17.5137V13.5137Z" fill="#141414"/>
        <path d="M4 9.51367C4 8.4091 4.89543 7.51367 6 7.51367H18C19.1046 7.51367 20 8.4091 20 9.51367V11.5137H4V9.51367Z" fill="#141414"/>
        <path opacity="0.4" d="M6 5.51367C6 4.4091 6.89543 3.51367 8 3.51367H16C17.1046 3.51367 18 4.4091 18 5.51367V7.51367H6V5.51367Z" fill="#141414"/>
        <path d="M9.25 14.5137C9.25 14.0995 9.58579 13.7637 10 13.7637H14C14.4142 13.7637 14.75 14.0995 14.75 14.5137C14.75 14.9279 14.4142 15.2637 14 15.2637H10C9.58579 15.2637 9.25 14.9279 9.25 14.5137Z" fill="#141414"/>
        </svg>
    );
  };
  
  export default IconSrvice;