import { Checkbox, ConfigProvider, Form, Input, Select, Spin } from 'antd';
import DatePicker from 'components/common/DatePickerInput';
import FormRow from 'components/common/Form/FormRow';
import message from 'components/common/Message';
import { Dayjs } from 'dayjs';
import { get } from 'lodash';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useSetLoadingPage } from 'services/UI/LoadingPage';
import styled from 'styled-components';
import storage from 'utils/sessionStorage';
import styles from '../pages/index.module.scss';
import apisCustomer from '../services/apis';
import customerSelectors from '../services/selectors';
import { IApiUpdateCustomerBody } from '../services/types/cutomer';
import PhoneNumberInput from 'components/common/PhoneNumberInput';
import dayjs from 'utils/dayjs';
import 'dayjs/locale/vi';
import viVN from 'antd/lib/locale/vi_VN';
import enEL from 'antd/lib/locale/en_US';
import { formatMoney } from 'utils/unit';
import moment from 'moment';
import { getFormatShortDate } from 'utils/moment/getSingleDateShortFormat';
import EditCustomer from '../widgets/EditCustomer';

const { TextArea } = Input;

export interface IFormGeneralValue {
  name: string;
  phoneNumber: string;
  email: string;
  birthDate: Dayjs;
  gender: number | null;
  clientNotify: boolean;
  marketingNotify: boolean;
  importantClientInfo: string | null;
}
type Props = {
  fetchDetailData?: () => void
}
/**
 * General tab
 * @param fetchDetailData 
 * @returns 
 */
const GeneralTab = ({ fetchDetailData = () => undefined }: Props) => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const onChange = () => undefined;
  const { t: customerLang } = useTranslation('customer');
  const { t: bookingLang } = useTranslation('booking');
  const { t: commonLang } = useTranslation('common');
  const { t: dateLang } = useTranslation('date');
  const { t: formLang } = useTranslation('form');
  const { Option } = Select;
  const detail = customerSelectors.getCustomerDetail();
  const id = customerSelectors.getIdCustomerDetail();
  const isWalkin = customerSelectors.getCustomerIsWalkinIn();

  const setPageLoading = useSetLoadingPage();

  const loading = customerSelectors.loadingCustomerDetail();

  const lang = 'vi';
  dayjs.locale(lang === 'vi' ? 'vi' : 'en');
  const locale = lang === 'vi' ? viVN : enEL;

  useEffect(() => {
    form.setFieldsValue({
      name: detail?.name,
      email: detail?.email,
      gender: detail?.gender,
      birthDate: detail?.date_of_birth ? dayjs(detail?.date_of_birth) : null,
      phoneNumber: detail?.phone_number,
      numberVisits: detail?.total_visited,
      cancellations: detail?.total_cancelled,
      noShows: detail?.total_no_show,
      loyaltyPoints: detail?.loyalty_point,
      amountSpent: detail?.amount_spent,
      clientNotify: !!detail?.notification_customer,
      marketingNotify: !!detail?.notify_market,
      importantClientInfo: detail?.important_client_info,
    });
  }, [detail]);

  if (loading) {
    return (
      <Spin />
    );
  }

  /**
   * Check valid
   * @returns 
   */
  const checkValid = async () => {
    try {
      await form.validateFields([
        'name',
        'email',
        'phoneNumber',
      ]);
      return true;
    } catch (error) {
      return false;
    }
  };

  /**
   * On Cancel
   */
  const _onCancel: React.MouseEventHandler<HTMLButtonElement> = () => {
    navigate(-1);
  };

  /**
   * On submit
   * @param e 
   * @returns 
   */
  const _onSubmit: React.MouseEventHandler<HTMLButtonElement> = async (e) => {
    const isFormValid = await checkValid();
    if (!isFormValid) return;
    navigate(-1);
    e.preventDefault();
    form.submit();
  };

  /**
   * Handle form submission
   * @param values Form values
   * @returns 
   */
  const handleSubmit = async (values: IFormGeneralValue) => {
    // Get merchantId from storage
    const merchantId = storage.merchantId.get();
    if (!merchantId) return;
    // Create payload for API
    const payload: IApiUpdateCustomerBody = {
      merchant_id: merchantId,
      email: values.email ?? '',
      name: values.name ?? '',
      gender: values.gender ?? null,
      date_of_birth: values.birthDate ? values.birthDate.format('YYYY-MM-DD') : null,
      notification_customer: values.clientNotify,
      notify_market: values.marketingNotify,
      phone_number: values.phoneNumber ?? '',
      important_client_info: values?.importantClientInfo ?? '',
    };
    // Hiển thị loading
    setPageLoading(true);
    try {
      // Call API to update customer information
      const res = await apisCustomer.updateCustomerInfo(String(id ?? ''), { ...payload, is_walkin_in: isWalkin });
      // Check the response from the API
      const msg = get(res, 'data.message', '');
      if (msg) {
        // Display success message
        message.success(msg);
        // Retrieve detailed data
        fetchDetailData();
      } else {
        throw 'fail';
      }

    } catch (error) {
      // Error handling
      const errors = Object.entries(get(error, 'response.data.errors', {}));
      if (errors.length > 0) {
        // Display errors from the API
        errors.map(([, valueArr]) => {
          message.error(get(valueArr, [0], ''));
        });
        return;
      }
      // Display a general error message
      message.error('An error occurred. Please try again');
    } finally {
      // Hide loading after completion
      setPageLoading(false);
    }

  };
  return (
    <GeneralTabStyled>
      <Form
        form={form}
        data-form-testing='form-customer'
        initialValues={{
          name: detail?.name,
          email: detail?.email ?? '',
          birthDate: detail?.date_of_birth ? dayjs(detail?.date_of_birth) : null,
          phoneNumber: detail?.phone_number ?? '',
          numberVisits: detail?.total_visited,
          cancellations: detail?.total_cancelled,
          importantClientInfo: detail?.important_client_info,
          noShows: detail?.total_no_show,
          loyaltyPoints: detail?.loyalty_point,
          amountSpent: detail?.amount_spent,
          clientNotify: !!detail?.notification_customer,
          marketingNotify: !!detail?.notify_market,
          gender: detail?.gender || 0,
        }}
        onKeyUp={async (e) => {
          if (e.key === 'Enter') {
            const isFormValid = await checkValid();
            if (!isFormValid) return;
            form.submit();
          }
        }}
        onFinish={handleSubmit}
        layout="vertical"
      >
        <div className="cards-wrapper">
          <div className="cards-col">
            <div className="common-card">
              <h2 className="card-title">{customerLang('General.DetailsTitle')}</h2>
              <div className="card-body">
                <div className="form-row">
                  <Form.Item
                    label={customerLang('Họ và tên (*)')}
                    name="name"
                    rules={[
                      { required: true, message: customerLang('PleaseInputYourName') || '' },
                      { min: 2, message: bookingLang('NameMustBeAtLeast2Characters') || '' },
                      { max: 30, message: bookingLang('NameMustBeAtMost30Characters') || '' }
                    ]}
                  >
                    <Input autoFocus />
                  </Form.Item>
                </div>
                <div className="form-row items-end ">
                  <Form.Item label={customerLang('PhoneNumber') + ' (*)'} name="phoneNumber"
                    rules={[
                      { required: true, message: formLang('PhoneNumberIsRequired') || '' },
                      { pattern: /^0+[0-9]{9}$/, message: formLang('ThePhoneNumberIs04AndHas10Digits') || '' }
                    ]}
                  >
                    <PhoneNumberInput placeholder={'0_________'} />
                  </Form.Item>
                </div>
                <div className="form-row">
                  <Form.Item label={
                    <p>
                      {customerLang('Email')}
                    </p>
                  } name="email"
                    rules={detail?.email ? [
                      { type: 'email', message: customerLang('IsNotAValidEmail') || '' },
                    ] : []}
                  >
                    <Input placeholder={customerLang('EnterCustomerEmail') || ''} />
                  </Form.Item>
                </div>
                <div className='gender-birthDate'>
                  <FormRow label={customerLang('Gender')} name='gender'>
                    <Select>
                      <Option value={0}>{customerLang('Female')}</Option>
                      <Option value={1}>{customerLang('Male')}</Option>
                      <Option value={2}>{customerLang('NonBinary')}</Option>
                    </Select>
                  </FormRow>
                  <div className="form-row">
                    <ConfigProvider locale={locale}>
                      <Form.Item
                        name="birthDate"
                        label={
                          <p className='BirthDate-title'>
                            {customerLang('BirthDate')}
                          </p>
                        }
                      >
                        <DatePicker onChange={onChange} clearIcon={false} />
                      </Form.Item>
                    </ConfigProvider>
                  </div>
                </div>
                <div className="form-row address-container">
                  <Form.Item
                    label={customerLang('Địa chỉ')}
                    name="address"
                    extra={'Ví dụ: 223 Nguyễn Xí, phường 13, quận Bình Thạnh, HCMC.'}
                  >
                    <Input placeholder='Nhập địa chỉ' />
                  </Form.Item>
                </div>
              </div>
            </div>
            <StatisticsCardStyled className="common-card w-t-50">
              <div className='header'>
                <h2 className="card-title">{customerLang('General.Statistics.Title')}</h2>
                <EditCustomer fetchDetailData={fetchDetailData} />
              </div>
              <div className="card-body">
                <div className={styles.statistics_wrapper}>
                  <div className={styles.statistics_left}>
                    <ul>
                      <li>
                        <span>{detail?.total_visited ?? 0}</span>
                        <p>{customerLang('General.Statistics.TotalVisits')}</p>
                      </li>
                      <li>
                        <span>{detail?.total_completed ?? 0}</span>
                        <p>{customerLang('General.Statistics.Completed')}</p>
                      </li>
                      <li>
                        <span>{detail?.total_cancelled ?? 0}</span>
                        <p>{customerLang('General.Statistics.Cancelled')}</p>
                      </li>
                      <li>
                        <span>{detail?.total_no_show ?? 0}</span>
                        <p>{customerLang('General.Statistics.NoShows')}</p>
                      </li>
                    </ul>
                  </div>
                  <div className={styles.statistics_right}>
                    <ul>
                      <li>
                        <span>{formatMoney(detail?.amount_spent ?? 0)}</span>
                        <p>{customerLang('General.Statistics.TotalSales(VND)')}</p>
                      </li>
                      <li>
                        <span>{detail?.loyalty_point ?? 0}</span>
                        <p>{customerLang('General.Statistics.LoyaltyPoints')}</p>
                      </li>
                      <li>
                        <span className={moment(detail?.last_visit).isValid() ? '' : 'empty'}>{getFormatShortDate(detail?.last_visit, dateLang) || 'EMPTY'}</span>
                        <p>{customerLang('General.Statistics.LastVisits')}</p>
                      </li>
                      <li>
                        <span className={detail?.last_location ? '' : 'empty'}>{detail?.last_location || 'EMPTY'}</span>
                        <p>{customerLang('General.Statistics.LastLocation')}</p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </StatisticsCardStyled>
            <div className="common-card w-t-50">
              <h2 className="card-title">{customerLang('Ghi chú khách hàng')}</h2>
              {/* <p className='note'>Thông tin này sẽ chỉ hiển thị cho bạn hoặc người có quyền truy cập vào hệ thống.</p> */}
              <div className="card-body">
                <div className='form-row'>
                  <Form.Item
                    label={
                      <p>
                        {customerLang('ImportantClientInfo')}
                        <span>({customerLang('Optional')})</span>
                      </p>
                    }
                    name='importantClientInfo'
                  // extra={customerLang('ThisNoteWillBeDisplayedOnAllBookings')}
                  >
                    <TextArea
                      rows={5}
                      placeholder={customerLang('EgCustomerAllergicToFragrance') || ''}
                    />
                  </Form.Item>
                </div>
                {/* <Form.Item valuePropName={'checked'} name='isUpdateEmail'>
                  <Checkbox className='check-show-booking'>{'Hiển thị cho mọi lịch hẹn'}</Checkbox>
                </Form.Item> */}
              </div>
            </div>
          </div>
          {/* TODO: NEXT PHRASE */}
          <div className="cards-col">
            <div className="common-card">
              <h2 className="card-title">{customerLang('Cài đặt thông báo')}</h2>
              <p className='note-setting-notification'>Cài đặt cập nhật thông báo cho khách hàng về các lịch hẹn, sản phẩm và chương trình khuyến mãi.</p>
              <div className="card-body">
                <div className={styles.notifications_wrapper}>
                  <ul>
                    <li className='notification-booking-service'>
                      <div className={'setting-notification'}>
                        <p>{customerLang('Thông tin lịch hẹn và sản phẩm')}</p>
                        <Form.Item valuePropName={'checked'} name='isUpdateEmail'>
                          <Checkbox className='check-show-booking'>{'Gửi qua tin nhắn SMS'}</Checkbox>
                        </Form.Item>
                        <Form.Item valuePropName={'checked'} name='isUpdateEmail'>
                          <Checkbox style={{ marginBottom: '24px' }} className='check-show-booking'>{'Gửi qua tin nhắn Zalo'}</Checkbox>
                        </Form.Item>
                      </div>
                      {detail?.notification_customer === 0 && <p className={styles.notifications_note}>
                        {customerLang('General.Notifications.ClientNote')}
                      </p>}
                    </li>
                    <li>
                      <div className={'setting-notification'}>
                        <p className='setting-notification-item'>{customerLang('Thông tin chương trình marketings')}</p>
                        <Form.Item valuePropName={'checked'} name='isUpdateEmail'>
                          <Checkbox className='check-show-booking'>{'Gửi qua tin nhắn SMS'}</Checkbox>
                        </Form.Item>
                        <Form.Item valuePropName={'checked'} name='isUpdateEmail'>
                          <Checkbox className='check-show-booking'>{'Gửi qua tin nhắn Zalo'}</Checkbox>
                        </Form.Item>
                      </div>
                      {detail?.notify_market === 0 && <p className={styles.notifications_note}>
                        {customerLang('General.Notifications.MarketingNote')}

                      </p>}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.general_footer}>
          <button type='button' className="common-btn is-white" onClick={_onCancel}>{commonLang('Cancel')}</button>
          <button type='button' className="common-btn" onClick={_onSubmit}>{commonLang('Save')}</button>
        </div>
      </Form>
    </GeneralTabStyled>
  );
};

export default GeneralTab;

const StatisticsCardStyled = styled.div`
  h2 {
    border: none !important;
  }
  .header {
    height: 52px;
    justify-content: space-between;
    display: flex;
    border-bottom: 1px solid var(--color-purple);
  }
  span {
    @media only screen and (max-width: 767.98px) {
      font-size: 18px;
    }
  }
`;

const GeneralTabStyled = styled.div`
.ant-form-vertical .ant-form-item .ant-form-item-control {
  margin-bottom: 0px;
}

@media only screen and (min-width: 1025px) {
.common-card .card-body {
  max-width: none;
}
.cards-wrapper .cards-col {
  max-width: none;
}
}

.ant-input:focus {
  border-color: #141414;
}
  .ant-form-item-extra {
    font-style: italic;
    color:#7980BC;
  }
  .empty {
    opacity:0;
  }

  .setting-notification {
    display: grid;
  }

  .gender-birthDate {
    display: flex;
    gap: 8px;
    justify-content: center;
    align-items: center;
    @media only screen and (max-width: 767.98px) {
      display: grid;
      justify-content: normal;
    }
    .BirthDate-title {
      margin-top: 10px;
    }
  }

  .note-setting-notification {
    padding-bottom: 24px;
    margin-bottom: 24px;
    border-bottom: 1px solid #F0F0F0;
  }

  .address-container {
    display: grid;
    .note-address {
      font-style: italic;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      color:#c3c2e0;
      margin-top: 12px;
    }
  }

.setting-notification-item {
  padding-bottom: 8px;
  font-weight: 600;
}
  .notification-booking-service {
    margin-bottom: 24px;
    border-bottom: 1px solid #F0F0F0;
  }
  .note {
      font-style: italic;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      color:#c3c2e0;
      margin-top: 12px;
    }

    .check-show-booking {
      span {
        color: var(--Gray-95, #141414);
        font-family: Inter;
        font-size: 16px;
        font-weight: 500;
        line-height: 140%;
      }
    }
  
`;

// const StatisticsCardStyled = styled.div`
//   h2 {
//     border: none !important;
//   }
//   .header {
//     height: 52px;
//     justify-content: space-between;
//     display: flex;
//     border-bottom: 1px solid var(--color-purple);
//   }
//   span {
//     @media only screen and (max-width: 767.98px) {
//       font-size: 18px;
//     }
//   }
// `;
