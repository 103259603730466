import React, { useEffect } from 'react';
import { useAppDispatch } from 'store/hooks';
import WorkingHourSummary from '../components/WorkingHourSummary';
import WorkingHourTable from '../components/WorkingHourTable';
import workingHourActions from '../services/actions';
import moment from 'moment';
import WorkingHourDetailFilter from '../components/Detail/Filter';
// eslint-disable-next-line @typescript-eslint/ban-types
type IWorkingHourPageProps = {};
const WorkingHourPage: React.FC<IWorkingHourPageProps> = () => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(workingHourActions.initData());
    dispatch(workingHourActions.setParams({
      date: moment().unix(),
      start_date: undefined,
      end_date: undefined,
    }));
  }, []);

  return (
    <div>
      <WorkingHourDetailFilter />
      <WorkingHourSummary />
      <WorkingHourTable />
    </div>
  );
};

export default WorkingHourPage;