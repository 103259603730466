import { Form, FormInstance, Input, Modal, message } from 'antd';
import bookingSelectors from 'features/bookings/services/selectors';
import { useEffect, useState } from 'react';
import { valueAllOptions } from 'utils/unit';
import OTP from 'components/common/OTP';
import styled from 'styled-components';
import settingApis from 'features/settings/services/apis';
import { get } from 'lodash';
import { useTranslation } from 'react-i18next';
interface IFormModal {
    isModalOpen?: boolean;
    handleCancel?: () => void;
    form?: FormInstance;
    handleSubmit?: any;
    isDisableSubmit?: boolean;
    formData?: any;
    valuePin?: any;
}

const ModalPin = ({
    isModalOpen,
    handleCancel,
    handleSubmit,
    formData,
    isDisableSubmit,
}: IFormModal) => {
    const [form] = Form.useForm();
    const dataForm = bookingSelectors.bookingOnline.getLocations()?.filter((item) => (item.status === 1));
    const [isModalReserPin, setIsModalReserPin] = useState(false);
    const {t: bookingLang} = useTranslation('booking');

    useEffect(() => {
        form?.setFieldsValue({
            locations: dataForm.length <= 1 ? dataForm[0]?.id : valueAllOptions,
        });
    }, [dataForm]);

    useEffect(() => {
        form?.setFieldsValue(formData);
        form.setFieldValue('locations', !formData?.locations ? (dataForm.length > 1 ? valueAllOptions : dataForm[0]?.id) : formData?.locations);
    }, [formData]);

    const openResetPin = async () => {
        try {
            const response = await settingApis.getResetPinNumber();
            const resetPinNumber = response.data.message;
            const msg = get(resetPinNumber, 'data.data.message', resetPinNumber);
            message.success(msg);
            setIsModalReserPin(true);
        } catch (error) {
            console.error('Lỗi khi gọi API getResetPinNumber:', error);
        }
    };
    

    const onBackResetPin = () => {
        setIsModalReserPin(false);
    };

    return (
        <Modal
            maskClosable={false}
            open={isModalOpen}
            onCancel={handleCancel}
            okButtonProps={{ style: { display: 'none' } }}
            width={574}
            footer={null}
            forceRender
            centered
        >
            <h2 className='modal-title'>{isModalReserPin ? bookingLang('ResetPIN') : bookingLang('EnterPIN')}</h2>

            <Form
                className='modal-form'
                onFinish={handleSubmit}
                autoComplete='off'
                form={form}
                layout='vertical'
                initialValues={{
                    locations: null,
                }}
            >
                {
                    isModalReserPin ?
                        <ModalPinStyled>
                            <p className='text-pin'>We`ve sent your new PIN number to your SMS *******123. Please don`t share it with anyone.</p>
                            <div>
                                <div className='form-row'>
                                    <Form.Item
                                        label='New PIN number'
                                        name='pin_number'
                                        rules={[
                                            { required: true },
                                            {
                                                pattern: /^[\d]{4}$/,
                                                message: 'Pin number must have 4 number',
                                            },
                                        ]}
                                    >
                                        <Input placeholder='Enter new PIN number' />
                                    </Form.Item>
                                </div>
                                <div className='form-row'>
                                    <Form.Item
                                        label='Confirm password'
                                        name='password'
                                        rules={[
                                            { required: true }
                                        ]}
                                    >
                                        <Input.Password placeholder='Enter your password' />
                                    </Form.Item>
                                </div>
                            </div>
                        </ModalPinStyled>
                        :

                        <ModalPinStyled>
                            <p className='text-pin'>{bookingLang('PleaseEnterA4DigitsPINToConfirmThisAction')}</p>
                            <OTP size='big' />
                            <p className='text-pin'>{bookingLang('Don’tRememberTheNumber')}   <span onClick={openResetPin}> {bookingLang('ResetPIN')}</span></p>
                        </ModalPinStyled>
                }
                <div className='form-submit-wrapper'>
                    {
                        isModalReserPin ?
                            <>
                                <div onClick={onBackResetPin} className='common-btn is-white'>
                                    {bookingLang('Back')}
                                </div>
                                <button disabled={isDisableSubmit} type='submit' className='common-btn'>
                                    {bookingLang('Save')}
                                </button>
                            </> :

                            <>
                                <div onClick={handleCancel} className='common-btn is-white'>
                                    {bookingLang('Cancel')}
                                </div>
                                <button disabled={isDisableSubmit} type='submit' className='common-btn'>
                                    {bookingLang('Done')}
                                </button>
                            </>
                    }
                </div>
            </Form>
        </Modal>
    );
};

export default ModalPin;

const ModalPinStyled = styled.div`
display: grid;
gap: 24px;
justify-content: center;
.text-pin {
    display: flex;
    justify-content: center;
    color: #363565;
    font-size: 16px;
    text-align: center;
    span {
        color: #4A9D77;
        font-weight: 700;
        cursor: pointer;
    }
}
`;