import React from 'react';
import styled from 'styled-components';
import { ANONYMOUS_NAME, formatMoney, formatTimeMinutes } from 'utils/unit';
import { ServiceItem } from './types';
import { Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
type Props = {
  data: ServiceItem;
  small?: boolean;
}
/**
 * Render service item
 * @props data ServiceItem
 * @props small boolean
 * @returns 
 */
const UIServiceItem: React.FC<Props> = ({ data, small }) => {
  const { t: bookingLang } = useTranslation('booking');
  const { t: dateLang } = useTranslation('date');
  if (!data) return null;
  const { serivce_variant_name, service_name, duration_time, service_price, quantity, assigned_employee } = data;
  if (small) {
    return (
      <UIServiceItemStyled>
      </UIServiceItemStyled>
    );
  }

  return (
    <UIServiceItemStyled>
      <table>
        <tr>
          <td className='content'>
            <div className="d-flex">
              <div>
              </div>
              <div className='group-content'>
                <Tooltip placement="topLeft" title={service_name}>
                  <p className='icon_color name'>
                    {/* <div className="round" style={{ background: category_service_color }}></div> */}
                    <span className={`service-name ${service_name.length > 20 ? 'service-name-ellipsis' : ''}`}>
                      {service_name}
                    </span>
                  </p>
                </Tooltip>
                <p className='icon_color hide subtext'><div className="round"></div>
                  <span className={`service-variant-name ${service_name.length > 20 ? 'service-name-ellipsis' : ''}`}>{serivce_variant_name || service_name}&nbsp;</span>
                  {/* <b style={{ color: '#000' }}>{formatTimeMinutes(duration_time)}</b> */}
                  <span>&nbsp;{bookingLang('with')} <b>{assigned_employee?.employee?.full_name || bookingLang(ANONYMOUS_NAME === 'Any team member' ? 'AnyTeamMember' : '')}</b></span>
                </p>
                <p className='icon_color hide subtext-mobile'><div className="round"></div>
                  <span className={`service-variant-name ${service_name.length > 20 ? 'service-name-ellipsis' : ''}`}>{serivce_variant_name || service_name}&nbsp;</span>
                  <b style={{ color: '#000' }}>{formatTimeMinutes(duration_time, dateLang)}</b>
                  <span>&nbsp;{bookingLang('with')} <b>{assigned_employee?.employee?.full_name || bookingLang(ANONYMOUS_NAME === 'Any team member' ? 'AnyTeamMember' : '')}</b></span>
                </p>
              </div>
              <p>{quantity}</p>
            </div>
          </td>
          <td className='amount'>
            <b>{formatMoney(service_price)}</b>
          </td>
        </tr>
      </table>
    </UIServiceItemStyled>
  );
};

export default UIServiceItem;

const UIServiceItemStyled = styled.div`
width:100%;
padding: 12px;

table {
  width:100%;
td {
  &.content {
    width:100%;
    .d-flex{
      display:flex;
      align-items: center;
      justify-content: space-between;
      padding-right:10%;
    }
    .round {
      display: flex;
      flex-direction: row;
      padding: 2px;
      width: 12px;
      height: 12px;
      background: grey;
      border-radius: 100px;
    }
    .group-content {
      flex:1;
      margin-bottom: 16px;
    }
    .icon_color {
      display:flex;
      align-items: center;
      .service-name {
        @media only screen and (max-width: 767.98px) {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          width: 130px;
        }
      }
      .service-name-ellipsis {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 200px;
      @media only screen and (max-width: 767.98px) {
        width: 130px;
      }
      @media only screen and (min-width: 768px) and (max-width: 1180.98px) {
        width: 150px;
      }
    }
      .service-variant-name {
        @media only screen and (max-width: 767.98px) {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          /* width: 30px; */
        }
      }
      .round {
        margin-right: 8px;
      }
      &.hide .round {
        opacity: 0;
        width:12px;
        height:5px;
      }
      &.name {
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 140%;
        color: var(--color-primary);
      }
      &.subtext {
        display: block;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 140%;
        color: var(--color-primary);
        @media only screen and (max-width: 767.98px) {
          display: none;
        }
      }
      &.subtext-mobile {
        display: none;
        @media only screen and (max-width: 767.98px) {
          display: block;
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 140%;
          color: var(--color-primary);
        }
      }
    }
  }
  &.amount {
    vertical-align: middle;
  }
}
}
`;
