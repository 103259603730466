import { IResInvoiceItem } from 'features/invoices/services/types/invoice';
import styled from 'styled-components';
import CustomerMessage from './CustomerMessage';
import InfoIssueBy from './InfoIssueBy';
import InfoIssueDate from './InfoIssueDate';
import InvoiceServiceDetail from './InvoiceServiceDetail';
import { useTranslation } from 'react-i18next';

interface IInvoiceReviewEmail {
  data?: IResInvoiceItem | null
}

const InvoiceReviewEmail = ({ data }: IInvoiceReviewEmail) => {
  const {t: invoiceLang} = useTranslation('invoice');
  return (
    <InvoiceReviewEmailStyled>
      <h3 className='header'>{invoiceLang('INVOICENO')}. {data?.invoice_no}</h3>
      <InfoIssueBy data={data} />
      <InfoIssueDate data={data} />
      {/* {
        data?.merchant_invoice_details?.length !== 0 ? <InvoiceServiceDetail data={data} /> : <></>
      } */}
      <InvoiceServiceDetail data={data} />
      <CustomerMessage content={data?.customer_message ?? ''} />

    </InvoiceReviewEmailStyled>
  );
};

export default InvoiceReviewEmail;

const InvoiceReviewEmailStyled = styled.div`
  padding: 80px;
   @media only screen and (max-width: 1000px) {
    padding: 24px;
  }
  @media only screen and (max-width: 730px) {
    padding: 10px;;
  }
  .header {
    font-size: 80px; 
    font-weight: 500;
    word-wrap: nowrap;
    color: #363565;
    @media only screen and (max-width: 1000px) {
      font-size: 50px;
    }

    @media only screen and (max-width: 730px) {
      font-size: 26px;
    }
  }

  .invoice-info {
    width: 100%; 
    margin-top: 80px;
    color: #363565;
    @media only screen and (max-width: 767.98px) {
      margin-top: 40px;
    }
    &-title {
      text-align: initial; 
      font-weight: 400;
      width: 50%; 
      font-size: 16px;
    }

    &-content {
      text-align: initial; 
      font-weight: 600; 
      font-size: 16px; 
      width: 50%; 
      padding-top: 12px;
    }
  }
`;

