import InputSearchText from 'components/common/Input/InputSearchDebounce';
import { useEffect, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useAppDispatch } from 'store/hooks';
import userActions from '../services/actions';
import userSelectors from '../services/selectors';
import AddNewUser from '../widgets/AddNewUser';
import LocationsFilter from './LocationsFilter';
import Can from 'features/packagePermission/components/Can';
import { EmployeePermission, ModulePermission } from 'features/packagePermission/services/types/permission';
import packagePermissionSelector from 'features/packagePermission/services/selectors';
// import LocationsFilter from './LocationsFilter';

function StaffFilter() {
  const dispatch = useAppDispatch();
  const params = userSelectors.getParams();
  const [, setSearchParams] = useSearchParams();
  const searchTime = useRef<boolean>(false);
  const staffList = userSelectors.getStaffPaginateList().data;
  const staffLimit = packagePermissionSelector.getPermissionLimit('limit_staff');

  useEffect(() => {
    // @ts-ignore
    setSearchParams(params);
  }, [params]);

  const onChangeSearch = (val: string) => {
    if (searchTime.current) {
      dispatch(userActions.setParams({ 
        ...params,
        keyword: val || undefined, 
      }));
    }

    if (!searchTime.current && !val?.length) {
      searchTime.current = true;
    }
  };

  return (
    <div className={'private-filter'}>
      <LocationsFilter />
      <InputSearchText onSearchText={onChangeSearch} />
      {/* <button className={'export-btn'}></button> */}
      <Can module={ModulePermission.EMPLOYEES} permission={EmployeePermission.ADD_EMPLOYEE} limitAllow={{
        limit: staffLimit,
        size: staffList?.length
      }}>
        <AddNewUser />
      </Can>
    </div>
  );
}

export default StaffFilter;
