import { Form, FormInstance, Input, Modal } from 'antd';
import { useEffect, useRef } from 'react';
import { ICancellationReasonItem } from '.';
import { useTranslation } from 'react-i18next';

interface IFormModal {
  isModalOpen?: boolean;
  handleCancel?: () => void;
  form?: FormInstance;
  handleSubmit?: (values: ICancellationReasonItem) => Promise<boolean>;
  isDisableSubmit?: boolean;
  formData?: any;
}

const FormModal = ({
  isModalOpen,
  handleCancel,
  handleSubmit,
  formData,
  isDisableSubmit
}: IFormModal) => {
  const [form] = Form.useForm();

  useEffect(() => {
    form?.setFieldsValue(formData);
  }, [formData]);

  const myFocus = useRef<any>();

  useEffect(() => {
    myFocus?.current?.input?.focus();
  }, []);
  const {t: settingLang} = useTranslation('setting');
  return (
    <Modal
      maskClosable={false}
      open={isModalOpen}
      onCancel={handleCancel}
      okButtonProps={{ style: { display: 'none' } }}
      width={574}
      footer={null}
      forceRender
      centered
    >
      <h2 className='modal-title'>{settingLang('AddCancellationReason')}</h2>

      <Form
        className='modal-form'
        onFinish={handleSubmit}
        autoComplete='off'
        form={form}
        layout='vertical'
        initialValues={{
          locations: null,
        }}
      >
        <div className='form-row'>
          <Form.Item
            label={settingLang('Name')}
            name='name'
            rules={[{ required: true, message: settingLang('PleaseEnternName')||'' }]}
          >
            <Input ref={myFocus}  placeholder={settingLang('EnterName')||''} />
          </Form.Item>
        </div>
        <div className='form-submit-wrapper'>
          <div onClick={handleCancel} className='common-btn is-white'>
            {settingLang('Cancel')}
          </div>
          <button disabled={isDisableSubmit} type='submit' className='common-btn'>
            {settingLang('Done')}
          </button>
        </div>
      </Form>
    </Modal>
  );
};

export default FormModal;