import { Modal, Spin, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import settingActions from 'features/settings/services/actions';
import settingSelectors from 'features/settings/services/selectors';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { formatMoney } from 'utils/unit';

interface MessageHistoryModalProps {
  isModalOpen?: boolean;
  handleCancel?: () => void;
}

interface MessageHistoryColumnType {
  amount: string;
  full_name: string;
  id: number;
  phone_number: number;
  status: string;
  time: string;
  title?: string;
}

const MessageHistoryModal = ({
  isModalOpen,
  handleCancel,
}: MessageHistoryModalProps) => {
  const { t: topUpLang } = useTranslation('top_up');
  const {t: settingLang} = useTranslation('setting');
  const pagination = settingSelectors.getTopUpMessageHistoryPagination();
  const dispatch = useDispatch();
  const listTopUpSMSHistory = settingSelectors.getTopUpSMSHistory();
  const loadingTopUpSMSHistory = settingSelectors.loadTopUpSMSHistory();

  const columns: ColumnsType<MessageHistoryColumnType> = [
    {
      title: topUpLang('messageHistory.table.column.title'),
      dataIndex: 'title',
      key: 'title',
      render: (value: string) => {
        return <div>{value || '-'}</div>;
      },
    },
    {
      title: topUpLang('messageHistory.table.column.phoneNumber'),
      dataIndex: 'phoneNumber',
      key: 'phoneNumber',
      render: (value: string) => {
        return <div>{value || '-'}</div>;
      },
    },
    {
      title: topUpLang('messageHistory.table.column.date'),
      dataIndex: 'time',
      key: 'time',
      render: (value: string) => {
        return <div>{value || '-'}</div>;
      },
    },
    {
      title: topUpLang('messageHistory.table.column.status'),
      dataIndex: 'status',
      key: 'status',
      render: (text: string) => {
        return (
          <div>
            {text ? (
              <div className='table-status-column d-flex no-wrap'>
                <div className={text?.toLowerCase() + '_dot status_dot'} />
                <div className={text?.toLowerCase() + '_text status_text'}>
                  {text}
                </div>
              </div>
            ) : (
              <div>-</div>
            )}
          </div>
        );
      },
    },
    {
      title: topUpLang('messageHistory.table.column.amount'),
      dataIndex: 'amount',
      key: 'amount',
      render: (value: number) => {
        return <div>{value ? formatMoney(value) : '-'}</div>;
      },
    },
  ];

  return (
    <Modal
      maskClosable={false}
      open={isModalOpen}
      onCancel={handleCancel}
      okButtonProps={{ style: { display: 'none' } }}
      footer={null}
      forceRender
      centered
      className='message-history-modal'
    >
      <MessageHistoryModalStyled>
        <h2 className='modal-title'>{settingLang('MessageHistory')}</h2>
        <div className='message-history-wrap'>
          <Spin spinning={loadingTopUpSMSHistory}>
            <Table
              className={'message-histoty-table'}
              dataSource={listTopUpSMSHistory}
              columns={columns}
              rowKey={(item) => item.id}
              scroll={{
                x: 900,
              }}
              pagination={{
                total: pagination?.total_record,
                defaultCurrent: 1,
                defaultPageSize: 10,
                onChange: (pageChange, sizeChange) => {
                  const params: any = {
                    page: pageChange == 0 ? 1 : pageChange,
                    per_page: sizeChange,
                  };
                  dispatch(settingActions.getTopUpSMSHistory.fetch({
                    page: params.page,
                    per_page: params.per_page,
                  }));
                },
                showSizeChanger: false,
                // showTotal(total) {
                //   return `Total ${total} items`;
                // },
              }}
            />
          </Spin>
          <div className='form-submit-wrapper'>
            <div onClick={handleCancel} className='common-btn is-white message-history-close-btn'>
              {settingLang('Close')}
            </div>
          </div>
        </div>
      </MessageHistoryModalStyled>
    </Modal>
  );
};

const MessageHistoryModalStyled = styled.div`
  text-align: left;
  height: 100%;
  
  .message-histoty-table {
    .ant-table-cell {
      font-weight: 500;
      font-size: 14px;
    }

    .table-status-column {
      align-items: center;
      gap: 8px;

      .status_dot {
        width: 10px;
        height: 10px;
        border-radius: 5px;
      }

      .sent_dot {
        background: #95de64;
      }

      .pending_dot {
        background: #fadb14;
      }

      .failed_dot {
        background: #bfbfbf;
      }

      .success_img,
      .pending_img {
        display: none;
      }
    }
  }

  .message-history-wrap {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }

  @media (max-width: 1180px) {
    .form-submit-wrapper {
      padding-bottom: 24px;
    }
  }

  @media only screen and (max-width: 767.98px) {
    .message-history-close-btn {
      flex: unset;
    }
  }
`;

export default MessageHistoryModal;
