import { ConfigProvider, DatePicker } from 'antd';
import { RangePickerProps } from 'antd/es/date-picker';
import dayjs from 'utils/dayjs';
import workingHourSelectors from 'features/workingHour/services/selectors';
import React from 'react';
import styled from 'styled-components';
import 'dayjs/locale/vi';
import viVN from 'antd/lib/locale/vi_VN';
import enEL from 'antd/lib/locale/en_US';

type IDateRangePickerProps = {
  onChangeDateRange: RangePickerProps['onChange']
};
const DateRangePicker: React.FC<IDateRangePickerProps> = ({
  onChangeDateRange
}) => {
  const params = workingHourSelectors.getParams();

  // const onChangeDateRange: RangePickerProps['onChange'] = (values) => {
  //   const startDate = first(values);
  //   const endDate = last(values);
  //   dispatch(workingHourActions.setParams({
  //     start_date: startDate?.unix(),
  //     end_date: endDate?.unix(),
  //   }));
  // };  

  const lang = 'vi';
  dayjs.locale(lang === 'vi' ? 'vi' : 'en');
  const locale = lang === 'vi' ? viVN : enEL;

  return (
    <DateRangePickerStyled>
      <div className="common-select">
        <ConfigProvider locale={locale}>
          <DatePicker.RangePicker
            allowClear={false}
            suffixIcon={false}
            value={[dayjs((params?.start_date ?? params.date ?? 0) * 1000), dayjs((params?.end_date ?? params.date ?? 0) * 1000)]}
            onChange={onChangeDateRange}
            inputReadOnly
          />
        </ConfigProvider>
      </div>
    </DateRangePickerStyled>
  );
};

export default DateRangePicker;
const DateRangePickerStyled = styled.div``;
