import { ReactNode } from 'react';
import styled from 'styled-components';

/**
 * Frame setting
 * @param title, description, children
 * @returns 
 */
const FrameSetting = ({title, description, children}: {title: string, description: string, children: ReactNode}) => {
  return (
    <FrameSettingStyled className="body">
      <p className="title">{title}</p>
      <p className="des">{description}</p>
      <div className="frame-body">
        {children}
      </div>
    </FrameSettingStyled>
  );
};

export default FrameSetting;

const FrameSettingStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  border-radius: 12px;
  border: 1px solid #BFBFBF;
  padding: 24px;
  width: 500px;
  .title {

    color:#141414;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
  }
  .des {
    color: #8C8C8C;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
  }

  .frame-body {
    margin-top: 24px;
    
  }
`;