import { Image, Spin } from 'antd';
import IconProgram from 'assets/icons/ico_programs.svg';
import ModalConfirm, { ModalConfirmRef } from 'components/common/Modal/ModalConfirm';
import { useRef } from 'react';
import styles from '../pages/index.module.scss';
import { CHOOSE_CATEGORY_REWARD } from '../services/constants';
import loyalSelectors from '../services/selectors';
import { IRewardItemResData } from '../services/types/reward';
import RewardEdit from '../widgets/Reward/Edit';
import RewardRemove from '../widgets/Reward/Remove';
import RewardSwitchStatus from '../widgets/Reward/SwitchStatus';
import { useTranslation } from 'react-i18next';
import { formatMoney } from 'utils/unit';
import Can from 'features/packagePermission/components/Can';
import { ModulePermission, RewardPermission } from 'features/packagePermission/services/types/permission';

const RewardsTable = () => {
  const confirmRef = useRef<ModalConfirmRef>(null);
  const listLoyalReward = loyalSelectors.getListLoyalReward();
  const loading = loyalSelectors.loadingListLoyalReward();
  const {t: loyaltyProgramLang} = useTranslation('loyaltyProgram');
  const getCategoryType = (o: IRewardItemResData) => {
    if (o.category === CHOOSE_CATEGORY_REWARD.FREE_SERVICE) return loyaltyProgramLang('FreeService');
    return ((o.value_reward ? o.value_reward + '%' : '') || (o.amount_reward ?  formatMoney(o.amount_reward) : '')) + loyaltyProgramLang('Reward');
  };
  return (
    <Spin spinning={loading}>
      <div className='card-body'>
        <ul className={styles.programs_list}>
          {listLoyalReward.map((o, index) => (
            <li key={index}>
              <div>
                <Image src={IconProgram} alt='icon-program'></Image>
              </div>
              <div className={styles.item_heading}>
                <p className={styles.item_title}>{o.name}</p>
                <p className={styles.item_point}>{getCategoryType(o)}</p>
              </div>
              <Can module={ModulePermission.REWARDS} permission={RewardPermission.EDIT_REWARD}>
                <RewardSwitchStatus data={o} confirmRef={confirmRef} />
              </Can>

              <ul className={'common-action'}>
                <Can module={ModulePermission.REWARDS} permission={RewardPermission.EDIT_REWARD}>
                  <RewardEdit data={o} />
                </Can>
                <Can module={ModulePermission.REWARDS} permission={RewardPermission.DELETE_REWARD}>
                  <RewardRemove data={o} confirmRef={confirmRef} />
                </Can>
              </ul>
            </li>
          ))}
        </ul>
      </div>
      <ModalConfirm ref={confirmRef} />
    </Spin>
  );
};

export default RewardsTable;
