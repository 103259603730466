import message from 'components/common/Message';
import useSalesPayment from 'features/sales/hooks/useSalesPayment';
import salesActions from 'features/sales/services/actions';
import apisSales from 'features/sales/services/apis';
import { EErrorMethodKeys, EPaymentMethod } from 'features/sales/services/constants';
import salesSelectors from 'features/sales/services/selectors';
import { IBodyApiCreateSaleByCash } from 'features/sales/services/types/checkout';
import { get } from 'lodash';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSetLoadingPage } from 'services/UI/LoadingPage';
import { useAppDispatch } from 'store/hooks';
import { IPurchaseProps } from './type';
import { useDispatch } from 'react-redux';

export const useCashErrors = (): [string, (val: string) => void] => {
  const dispatch = useAppDispatch();
  const errors = salesSelectors.getPurchaseMethodErrors(EPaymentMethod.CASH);
  const errorText = useMemo(() => get(errors, EErrorMethodKeys.cash.amount, ''), [errors]);
  const setErrorValid = (msg: string) => {
    dispatch(salesActions.setPurchaseMethodErrors({
      method: EPaymentMethod.CASH,
      errors: {
        [EErrorMethodKeys.cash.amount]: msg,
      }
    }));
  };
  return [errorText, setErrorValid];
};

const Cash = ({ onSuccess, isActive }: IPurchaseProps) => {
  const { t: checkoutLang } = useTranslation('checkout');
  const setLoadingPage = useSetLoadingPage();
  const currCash = salesSelectors.getPurchaseCurrentCash() ?? 0;

  const amount = salesSelectors.getOriginTotalPrice();
  const dispatch = useDispatch();

  const salesPayment = useSalesPayment();

  const [, setErrorValid] = useCashErrors();

  const onPurchase = async () => {
    setErrorValid('');    
    if (amount > (currCash ?? 0)) {
      setErrorValid(checkoutLang('validate.cash.errorAmount'));
      return;
    }

    setLoadingPage(true);
    try {
      const paymentInfo = await salesPayment.getPaymentInfoAsync();
      if (!paymentInfo) return;

      const payload: IBodyApiCreateSaleByCash = {
        ...paymentInfo,
        amount_given: currCash > amount ? amount : currCash,
      };
      const res = await apisSales.apiPaymentSale.byCash(payload);
      const msg = get(res, 'data.data.message', '');
      const invoiceResult = get(res, 'data.data.notify_invoice', '');
      dispatch(salesActions.setInvoiceResult(invoiceResult));
      if (msg) {
        message.success(msg);
        onSuccess();
      } else {
        const error = get(res, 'data.error.message', '');
        if (error) {
          message.error(error);
        } else
          throw 'fail';
      }
    } catch (error) {
      message.error('An error occurred. Please try again');
    } finally {
      setLoadingPage(false);
    }
  };
  const{t: bookingLang} = useTranslation('booking');
  return (
    <button
      type='button'
      className='form-booking-submit'
      onClick={onPurchase}
      disabled={isActive}
    >
      {bookingLang('Check-out')}
    </button>
  );
};

export default Cash;
