const IconHome = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path opacity="0.4" d="M21.5078 17.98V10.1635C21.5078 8.95256 20.9682 7.80568 20.0379 7.03971L15.038 2.92253C13.5655 1.71007 11.4501 1.71007 9.97767 2.92253L4.97767 7.03971C4.04746 7.80568 3.50781 8.95256 3.50781 10.1635V17.98C3.50781 20.2075 5.29867 22.0132 7.50781 22.0132H17.5078C19.717 22.0132 21.5078 20.2075 21.5078 17.98Z" fill="#595959"/>
            <path d="M7.81757 15.7205C7.97934 15.3391 8.4196 15.1612 8.80092 15.3229C10.3435 15.9774 11.4703 16.266 12.5466 16.2634C13.6222 16.2607 14.7218 15.9669 16.211 15.3247C16.5914 15.1607 17.0327 15.336 17.1967 15.7164C17.3607 16.0968 17.1853 16.5381 16.805 16.7021C15.2305 17.381 13.9159 17.7599 12.5503 17.7634C11.1855 17.7668 9.84408 17.3949 8.21509 16.7038C7.83378 16.542 7.6558 16.1018 7.81757 15.7205Z" fill="#595959"/>
        </svg>
    );
  };
  
  export default IconHome;
  