import { useTranslation } from 'react-i18next';
import RewardAdd from '../widgets/Reward/Add';
import RewardsTable from './RewardsTable';
import Can from 'features/packagePermission/components/Can';
import { ModulePermission, RewardPermission } from 'features/packagePermission/services/types/permission';

const RewardsTab = () => {
  const {t: loyaltyProgramLang} = useTranslation('loyaltyProgram');
  return (
    <>
      <div className='cards-wrapper'>
        <div className='cards-col'>
          <div className='common-card'>
            <div className='card-heading'>
              <div className='card-title is-small'>
                <div>
                  <p>{loyaltyProgramLang('EarnPoints')}</p>
                  <span>{loyaltyProgramLang('AllWaysThatYourCustomersCanEarnPoints')}</span>
                </div>
                <Can module={ModulePermission.REWARDS} permission={RewardPermission.ADD_REWARD}>
                  <RewardAdd />
                </Can>
              </div>
            </div>
            <RewardsTable />
          </div>
        </div>
      </div>
    </>
  );
};

export default RewardsTab;
