import { Form, InputNumber, Modal } from 'antd';
import FormRow from 'components/common/Form/FormRow';
// import { get } from 'lodash';
import { useEffect, useRef, useState, useTransition } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
// import { isNumeric } from 'utils/unit';

export enum EFields {
  'PIN' = 'pin',
  'NOTE' = 'note',
  'REASON' = 'reason',
}

// type IFormValues = {
//   _one: number;
//   _two: number;
//   _three: number;
//   _four: number;
//   note: string;
//   reason: string;
// }


type Errors = {
  [EFields.NOTE]?: string | null,
  [EFields.REASON]?: string | null,
  [EFields.PIN]?: string | null,
};


export type IModalCancelSubmitFunc = (values: {
  reason: string;
  note: string;
  PIN: string;
  form: any;
}, setErrors: (errors: Errors) => void, resetPin: () => void) => void
type Props = {
  visible?: boolean;
  onCancel?: () => void;
  onSubmit?: IModalCancelSubmitFunc;
  setPinNumber?: any;
  resetOnSubmit?: boolean;
};

/**
 * Modal confirm
 * @param onCancel , visible, onSubmit, resetOnSubmit
 * @returns 
 */
const ModalConfirm = ({ onCancel = () => undefined, visible = false, onSubmit = () => undefined, resetOnSubmit }: Props) => {
  const [, startTransition] = useTransition();
  const [form] = Form.useForm();

  const _one = useRef<HTMLInputElement>(null);
  const _two = useRef<HTMLInputElement>(null);
  const _three = useRef<HTMLInputElement>(null);
  const _four = useRef<HTMLInputElement>(null);

  const [, setErrors] = useState<Errors>({});
  const [, setErrorPin] = useState('');

  /**
   * Reset pin
   */
  const resetPin = () => {
    form.setFieldsValue({
      _one: null,
      _two: null,
      _three: null,
      _four: null,
    });
  };



  useEffect(() => {
    if (!visible) form.resetFields();
  }, [visible]);

  /**
   * On cancel
   */
  const _onCancel = () => {
    onCancel();
  };

  /**
   * Reset
   */
  const reset = () => {
    startTransition(() => {
      setErrorPin('');
      setErrors({});
    });
    form.resetFields();
  };

  /**
   * Handle submit
   * @param values 
   * @returns 
   */
  const handleSubmit = (values: any) => {
    setErrorPin('');
    setErrors({});
    const pinValue = String(values['_one'] ?? '') + String(values['_two'] ?? '') + String(values['_three'] ?? '') + String(values['_four'] ?? '');
    if (!pinValue || pinValue.length !== 4) {
      setErrorPin('Please enter digits PIN!');
      return;
    }



    onSubmit({
      form: form,
      note: values?.note ?? '',
      PIN: pinValue,
      reason: values?.reason ?? '',
    }, setErrors, resetPin);
    if (resetOnSubmit) reset();
  };




  /**
   * Step input confirm
   * @returns 
   */
  const StepInputConfirm = () => {

    const pinNumbers = [
      {
        name: '_one',
        ref: _one
      },
      {
        name: '_two',
        ref: _two
      },
      {
        name: '_three',
        ref: _three
      },
      {
        name: '_four',
        ref: _four
      },
    ];

    useEffect(() => {
      const inputsFilled =
        _one.current?.value ||
        _two.current?.value ||
        _three.current?.value ||
        _four.current?.value;
      if (!inputsFilled) {
        _one.current?.focus();
        return;
      } else {
        return;
      }
    }, []);

    /**
     * Handle key press
     * @param e 
     */
    const handleKeyPress = (e: { charCode: any; preventDefault: () => void; }) => {
      const charCode = e.charCode;

      if (charCode < 48 || charCode > 57) {
        e.preventDefault();
      }
    };

    return (
      <div className='PIN_form'>
        <p>Please enter a 4 digits PIN to confirm this action</p>
        <div className="PIN_group" >
          {pinNumbers.map(({ name, ref }, index, arr) => (
            <FormRow key={index} shouldUpdate noStyle name={name}>
              <InputNumber
                ref={ref}
                maxLength={1}
                minLength={1}
                min={0}
                inputMode='numeric'
                className='PIN_input'
                onFocus={() => {
                  // form?.setFieldValue(name, undefined);
                }}
                onKeyPress={handleKeyPress}
                type='number'

                onClick={() => {
                  const inputOtp = _one.current?.value || _two.current?.value || _three.current?.value || _four.current?.value;
                  if (inputOtp) {
                    return;
                  }
                  _one.current?.focus();
                }}

                onKeyUp={(e: React.KeyboardEvent<HTMLInputElement>) => {
                  if (e === null || e === undefined) return;

                  const currentValue = (e.target as HTMLInputElement).value;
                  form?.setFieldsValue({
                    [name]: currentValue,
                  });

                  const item = arr[index + 1] ? arr[index + 1] : undefined;
                  if (item && currentValue.length > 0) {
                    setTimeout(() => item.ref?.current?.focus(), 0);
                  }
                }}
                onChange={(e) => {
                  if (e !== null && e !== undefined) {
                    const currentValue = e.toString();
                    form?.setFieldsValue({
                      [name]: currentValue,
                    });

                    const item = arr[index + 0] ? arr[index + 0] : undefined;
                    if (item) {
                      setTimeout(() => item?.ref?.current?.focus());
                    }
                  }
                }}
                onKeyDown={e => {
                  if (e.key === 'Backspace'|| e.key === 'Delete' || e.keyCode === 46 || e.keyCode === 8) {                    
                    if( ! form.getFieldValue(name) ) {
                      const item = arr[index - 1] ? arr[index - 1] : undefined;
                      form?.setFieldValue(item?.name ?? '', undefined);
                      setTimeout(() => item?.ref?.current?.focus(), 1);
                    } else {
                      const item = arr[index + 0] ? arr[index + 0] : undefined;
                      form?.setFieldValue(item?.name ?? '', undefined);
                      setTimeout(() => item?.ref?.current?.focus(), 0);
                    }
                    
                  }
                }}
              />
            </FormRow>
          ))}
        </div>
        <p>Don’t remember the number? <Link to='/private/settings' className='label-highlight'><b>Reset PIN</b></Link></p>
        <div className='form-row form-submit-wrapper form-submit-floating'>
          <button className='common-btn is-white' type='button' onClick={_onCancel}>
            Cancel
          </button>
          <button className='common-btn' type='submit'>
            Confirm
          </button>
        </div>
      </div>
    );
  };

  return (
    <Modal
      maskClosable={false}
      open={visible}
      onCancel={_onCancel}
      forceRender
      footer={null}
      centered
      className='modal-cancel-booking'
    >
      <ModalCancelStyled>
        <h2 className='modal-title'>
          <p>Enter PIN</p>
        </h2>
        <Form
          data-form-testing='form-checkout-confirm'
          form={form}
          className='modal-form'
          layout='vertical'
          onFinish={handleSubmit}
          onValuesChange={() => setErrors({})}
        >
          <StepInputConfirm />
        </Form>
      </ModalCancelStyled>
    </Modal>
  );
};

export default ModalConfirm;

const ModalCancelStyled = styled.div`
  .ant-form-item-extra {
    color:#ff4d4f;
  }
  .hide {
    display:none;
  }
  .form-confirm {
    text-align: center;
  }
  .PIN_form {
    text-align: center;
    .label-highlight {
      color:#4A9D77;
    }
  }
  .PIN_group {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    width:100%;
    margin-top: 10%;
    margin-bottom: 5%;
  }
  .PIN_input {
    flex:1;
    background: none;
    border: unset;
    border-radius: 0;
    border-bottom: 3px solid #C3C2DE;
    input {
      padding: 0;
      font-style: normal;
      font-weight: 600;
      font-size: 40px;
      line-height: 140%;
      text-align: center;
      color: #363565;
      background: none;
    }
    &.ant-input-number {
      width: 40px;
    }
  }
`;