const IconSettings = () => {
  return (
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12.52 14.8434C10.9156 14.8434 9.61768 13.5934 9.61768 12.0234C9.61768 10.4534 10.9156 9.19336 12.52 9.19336C14.1245 9.19336 15.3917 10.4534 15.3917 12.0234C15.3917 13.5934 14.1245 14.8434 12.52 14.8434" fill="#595959"/>
      <path opacity="0.4" d="M21.7379 14.3832C21.5438 14.0832 21.2678 13.7832 20.9102 13.5932C20.624 13.4532 20.4401 13.2232 20.2765 12.9532C19.7553 12.0932 20.0619 10.9632 20.9306 10.4532C21.9526 9.88318 22.2796 8.61318 21.6868 7.62318L21.0021 6.44318C20.4196 5.45318 19.1422 5.10318 18.1304 5.68318C17.2311 6.16318 16.0763 5.84318 15.5551 4.99318C15.3916 4.71318 15.2996 4.41318 15.3201 4.11318C15.3507 3.72318 15.2281 3.35318 15.0441 3.05318C14.666 2.43318 13.9813 2.01318 13.225 2.01318H11.7841C11.038 2.03318 10.3533 2.43318 9.97521 3.05318C9.78104 3.35318 9.66863 3.72318 9.68907 4.11318C9.70951 4.41318 9.61753 4.71318 9.45402 4.99318C8.93282 5.84318 7.77801 6.16318 6.8889 5.68318C5.86695 5.10318 4.59972 5.45318 4.00698 6.44318L3.32227 7.62318C2.73976 8.61318 3.06678 9.88318 4.07852 10.4532C4.94718 10.9632 5.25377 12.0932 4.74279 12.9532C4.56906 13.2232 4.38511 13.4532 4.09896 13.5932C3.75149 13.7832 3.44491 14.0832 3.28139 14.3832C2.90327 15.0032 2.92371 15.7832 3.30183 16.4332L4.00698 17.6332C4.38511 18.2732 5.09026 18.6732 5.82607 18.6732C6.17353 18.6732 6.58232 18.5732 6.90934 18.3732C7.16483 18.2032 7.47142 18.1432 7.80866 18.1432C8.8204 18.1432 9.66863 18.9732 9.68907 19.9632C9.68907 21.1132 10.6293 22.0132 11.8147 22.0132H13.2046C14.3799 22.0132 15.3201 21.1132 15.3201 19.9632C15.3507 18.9732 16.1989 18.1432 17.2107 18.1432C17.5377 18.1432 17.8443 18.2032 18.11 18.3732C18.437 18.5732 18.8356 18.6732 19.1933 18.6732C19.9189 18.6732 20.624 18.2732 21.0021 17.6332L21.7175 16.4332C22.0854 15.7632 22.1161 15.0032 21.7379 14.3832" fill="#595959"/>
    </svg>
  );
};

export default IconSettings;
