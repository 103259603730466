import { Checkbox } from 'antd';
import React from 'react';
import styled from 'styled-components';
import { PATH } from '../../../constants/path.enum';
import { NavLink, useNavigate } from 'react-router-dom';
import IconEye from 'assets/svg/IconEye';
const RolesAndDecentralizationPage = () => {

    const data = [
        { id: 1, name: 'Admin', content: 'Mặt định 1 người' },
        { id: 2, name: 'User', content: '1 người' },
    ];

    const navigate = useNavigate();

    const handleLinkClick = (name: any) => {
        const url = `${PATH.RolesDetail}/${name}`;
        navigate(url);
    };

    const handleAddRoleClick = () => {
        // Thực hiện chuyển hướng đến trang RolesDetail để thêm vai trò
        navigate(`${PATH.RolesDetail}new`);
      };
    

    return (
        <FormRolesStyled>
            <>
                <div className='roles'>
                <div className='btnContainer'>
                    <NavLink
                        to={`${PATH.RolesDetail}new`}
                        onClick={handleAddRoleClick}
                    >
                        <button className={'common-btn'}>
                            Thêm vai trò
                        </button>
                    </NavLink>
                </div>
                <form className='formRoloes'>
                    <div className='rolesContainer'>
                        <p className='titleRoles'>Phân quyền</p>
                        <span className='contentRoles'>Cài đặt vai trò và phân quyền của hệ thống.</span>

                        <div className='informationContainer'>
                            {data.map((item) => (
                                <div key={item.id}>
                                    <div className='information'>
                                        <Checkbox />
                                        <div className='informationDetail'>
                                            <p>{item.name}</p>
                                            <span>{item.content}</span>
                                        </div>
                                        <NavLink to={`${PATH.RolesDetail}${data[0].name}`} onClick={() => handleLinkClick(data[0].name)}>
                                            <IconEye/>
                                        </NavLink>

                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </form>
                </div>
            </>
        </FormRolesStyled>
    );
};

export default RolesAndDecentralizationPage;
const FormRolesStyled = styled.div`
.roles {
    height: 100vh;
    background: #FFFFFF;
    padding: 24px;
    border-radius: 16px;
    max-height: 100%;
}
.btnContainer {
    display: flex;
    justify-content: end;
}
.formRoloes {
    display: flex;
    justify-content: center;
    .rolesContainer {
        width: 500px;
        border-radius: 12px;
        padding: 24px;
        border: 1px solid #8C8C8C;
    }
    .titleRoles {
        font-size: 20px;
        font-weight: 700;
        margin-bottom: 8px;
    }
    .contentRoles {
        font-size: 14px;
        color: #8C8C8C;
    }
    .informationContainer {
        margin-top: 32px;
    }
    .information {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 12px 0;
    }
    .informationDetail {
        width: 100%;
        padding: 0 24px;
    }
}
`;