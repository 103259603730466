import { AxiosResponse } from 'axios';
import message from 'components/common/Message';
import settingActions from 'features/settings/services/actions';
import settingApis from 'features/settings/services/apis';
import { get } from 'lodash';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import styles from '../../pages/index.module.scss';
// import { Form, Switch } from 'antd';
import styled from 'styled-components';
import { IOptionMulti } from 'components/common/MultipleSelect';
import settingSelectors from 'features/settings/services/selectors';
import HolidaySwitchStatus from './components/HolidaySwitchStatus';
import ModalConfirm, { ModalConfirmRef } from 'components/common/Modal/ModalConfirm';
import { useTranslation } from 'react-i18next';
interface ServiceChargesItem {
  id: number;
  name: string;
  value: number;
  location: ILocation[];
  status: number;
  handleEdit: () => void;
}

interface ILocation {
  id: number,
  name: string
}
const ServiceChargesItem = ({
  id,
  name,
  value,
  location,
  status,
  handleEdit,

}: ServiceChargesItem) => {
  const dispatch = useDispatch();

  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const confirmRef = useRef<ModalConfirmRef>(null);
  const {t: settingLang} = useTranslation('setting');
  const handleDelete = async (e: any, id: number) => {
    setIsDisabled(true);
    try {
      const rest: AxiosResponse<{ message: string }> = await settingApis.deleteServiceCharge(id);

      const msg = get(rest, 'data.message', '');
      if (msg) {
        message.success(msg);
        dispatch(settingActions.getListServiceCharge.fetch());
        setIsDisabled(false);
        return true;
      }
      return false;
    } catch (error: any) {
      const response = get<Record<string, string[]>>(
        error,
        'response.data.errors',
        {}
      );

      const errors = Object.values(response);
      errors.map((value) => {
        message.error(get(value, [0], ''));
      });

      return false;
    }
  };

  const dataDayLocation = settingSelectors.getShowServiceCharge();
  useEffect(() => {
    dispatch(settingActions.getShowServiceCharge.fetch());
  }, []);

  const locations: IOptionMulti[] = useMemo(
    () =>
      (dataDayLocation?.locations as { id: string | number; name: string }[])
        ?.filter((o) => o.id != 99999)
        ?.map((o) => ({ value: o.id, title: o.name })) ?? [],
    [dataDayLocation?.location, dataDayLocation]
  );

  const locationTitle = location.length === locations.length ? settingLang('AllLocation') : location.length +' '+ settingLang('Location');

  return (
    <>
      <div className={styles.item_content}>
        <p className={styles.item_label}>
          {name}
        </p>
        <p>{settingLang('Charge')}: {value}% {settingLang('TotalCostOfBooking')}</p>
        <p>{settingLang('Locations')}: {locationTitle}</p>

      </div>
      <ul className='common-action'>
        <SwitchStyled>
          <HolidaySwitchStatus id={id} status={status} confirmRef={confirmRef}/>
        </SwitchStyled>
        <li className='action-edit' onClick={handleEdit}></li>
        <li className='action-remove'><button disabled={isDisabled} className='action-remove' style={{
          width: '100%',
          height: '100%'
        }} onClick={(e) => handleDelete(e, id)}></button></li>
      </ul>
      <ModalConfirm ref={confirmRef} />
    </>
  );
};

export default ServiceChargesItem;

const SwitchStyled = styled.div`
.form-row {
  width: auto;
  margin-bottom: 0px;
}
`;