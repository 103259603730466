import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { Form, Input, Select, Upload, message } from 'antd';
import { RcFile, UploadChangeParam, UploadFile, UploadProps } from 'antd/es/upload';
import { FormInstance } from 'antd/lib/form/Form';
import SwitchInput from 'components/common/Input/SwitchInput';
import PhoneNumberInput from 'components/common/PhoneNumberInput';
import userActions from 'features/users/services/actions';
import { ITeamMemberItemResData } from 'features/users/services/types/teamMember';
import { get } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

interface BasicInformationProps {
    errors: any,
    locationDetail: any,
    idLocation?: string,
    form: FormInstance<any>
}

/**
 * Basic information
 * @param  errors, idLocation, locationDetail, form 
 * @returns 
 */
const BasicInformation = ({ errors, idLocation, locationDetail, form }: BasicInformationProps) => {
    const [loading, setLoading] = useState(false);
    const [imageUrl, setImageUrl] = useState<string>();
    const { t: locationLang } = useTranslation('location');
    const { t: formLang } = useTranslation('form');
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(userActions.getListPaginateStaffs.fetch({ per_page: 9999 }));
        const managerId = locationDetail?.location_manager?.manager_id ?? locationDetail?.merchant_employee?.[0]?.manager_id;
        if (!managerId) return;
        form.setFieldValue('manager', managerId);
    }, []);


    /**
     * Before Upload
     * @param file 
     * @returns 
     */
    const beforeUpload = (file: RcFile) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            // If the file type is not JPG or PNG, display an error message
            message.error('You can only upload JPG/PNG file!');
        }
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
            // If the file size is larger than 2MB, display an error message
            message.error('Image must smaller than 2MB!');
        }
        // Return true if the file is JPG or PNG and its size is less than 2MB, otherwise return false
        return isJpgOrPng && isLt2M;
    };

    /**
     * On change staff
     * @param value 
     */
    const onChangeStaff = (value: number) => {
        form?.setFieldsValue({
            manager: value
        });
    };

    /**
     * getBase64
     * @param img 
     * @param callback 
     */
    const getBase64 = (img: RcFile, callback: (url: string) => void) => {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result as string));
        reader.readAsDataURL(img);
    };

    /**
     * Handle change
     * @param info 
     * @returns 
     */
    const handleChange: UploadProps['onChange'] = (info: UploadChangeParam<UploadFile>) => {
        if (info.file.status === 'uploading') {
            // If the file is currently being uploaded, set loading state to true
            setLoading(true);
            return;
        }
        if (info.file.status === 'done') {
            // If the file upload is complete:
            // - Set loading state to false
            // - Get the base64-encoded URL of the uploaded image
            getBase64(info.file.originFileObj as RcFile, (url) => {
                setLoading(false);
                setImageUrl(url);// Set the base64-encoded URL as the image URL
            });
        }
    };

    const uploadButton = (
        <button style={{ border: 0, background: 'none' }} type="button">
            {loading ? <LoadingOutlined /> : <PlusOutlined />}
            <div style={{ marginTop: 8 }}>Upload</div>
        </button>
    );

    const errorsText = useMemo(() => ({
        name: get(errors, 'name', ''),
        contact_number: get(errors, 'contact_number', ''),
        address: get(errors, 'address', ''),
    }), [errors]);

    const [errorsTextPhone, setErrorsTextPhone] = useState<any>();

    /**
     * onChangeKey
     * @param key 
     * @returns 
     */
    const onChangeKey = (key: string) => () => {
        // If errorsTextPhone is not defined, or the specified key does not exist in errorsTextPhone, return without further action
        if (!errorsTextPhone) return;

        // Create a new object by copying errorsTextPhone
        if (errorsTextPhone[key] === undefined) return;
        const newErrorsText = { ...errorsTextPhone };

        // Delete the specified key from newErrorsText
        delete newErrorsText[key];

        // Update errorsTextPhone state with the newErrorsText
        setErrorsTextPhone(newErrorsText);
    };

    return (
        <BasicInformationStyled>
            <div className='cards-wrapper'>
                <div className='cards-col'>
                    <div className='common-card'>
                        <h2 className='card-title'>Thông tin cơ bản</h2>
                        <div className='card-body'>
                            <div className='form-row'>
                                <Form.Item name='image'>
                                    <div className='avatar-image-location-container'>
                                        <div className='avatar-image-location'>
                                            <Upload
                                                name="avatar"
                                                listType="picture-card"
                                                className="avatar-uploader"
                                                showUploadList={false}
                                                action="https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188"
                                                beforeUpload={beforeUpload}
                                                onChange={handleChange}>
                                                {imageUrl ? <img src={imageUrl} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
                                            </Upload>
                                        </div>
                                    </div>
                                </Form.Item>
                            </div>
                            <div className='form-row'>
                                <Form.Item
                                    label={'Tên cửa hàng (*)'}
                                    name='name'
                                    rules={[
                                        { required: true, message: locationLang('PleaseEnterName') || '' },
                                    ]}
                                    validateStatus={errorsText?.name ? 'error' : undefined}
                                    help={errorsText?.name ? errorsText?.name : undefined}
                                >
                                    <Input placeholder={'Nhập tên cửa hàng'} />
                                </Form.Item>
                            </div>
                            {
                                !idLocation ? <>
                                    <Form.Item
                                        label='Số hotline (*)'
                                        name='contact_number'
                                        rules={[
                                            { required: true, message: locationLang('PleaseEnterContactNumber') || '' },
                                            { pattern: /^0+[0-9]{9}$/, message: formLang('ThePhoneNumberIs04AndHas10Digits') || '' }
                                        ]}
                                        validateStatus={errorsText?.contact_number ? 'error' : undefined}
                                        help={errorsText?.contact_number ? errorsText?.contact_number : undefined}
                                        validateTrigger={['onChange', 'onRed']}
                                    >
                                        <PhoneNumberInput disabled={!!idLocation} placeholder={'Nhập số điện thoại'} onChange={onChangeKey('contact_number')} />
                                    </Form.Item>
                                    <Form.Item
                                        label='Tên quản lý'
                                        name='managerName'
                                    >
                                        <Input type='email' disabled={!!idLocation} placeholder={'Nhập tên quản lý'} />
                                    </Form.Item>
                                </> : <div className='form-row'>
                                    <Form.Item
                                        label={locationLang('ManagerName')}
                                        name='manager'
                                        rules={[
                                            { required: true, message: locationLang('PleaseChooseManager') || '' }
                                        ]}>
                                        <Select
                                            placeholder={locationLang('SelectManager')}
                                            onChange={onChangeStaff}
                                            options={locationDetail?.merchant_employee?.map((item: ITeamMemberItemResData) => ({
                                                label: item.full_name,
                                                value: item.manager_id,
                                            })) ?? []}
                                        />
                                    </Form.Item>
                                </div>
                            }

                            <Form.Item
                                label='Email cửa hàng'
                                name='managerEmail'
                            >
                                <Input type='email' placeholder={'example@email.com'} />
                            </Form.Item>
                            <Form.Item
                                label='Địa chỉ (*)'
                                name='address'
                                // help="Ví dụ: 223 Nguyễn Xí, phường 13, quận Bình Thạnh, HCMC."
                                rules={[{ required: true, message: locationLang('PleaseEnterAddress') || '' }]}
                                validateStatus={errorsText?.address ? 'error' : undefined}
                            >
                                <Input placeholder={'Nhập địa chỉ'} />
                            </Form.Item>

                            <Form.Item
                                label='Tên thuế'
                                name='business_name'>
                                <Input placeholder={'Nhập tên thuế'} />
                            </Form.Item>
                            <Form.Item
                                label='Mã số thuế'
                                name='business_abn'>
                                <Input type='number' placeholder={'Nhập mã số'} />
                            </Form.Item>
                            {
                                idLocation ?
                                    <SwitchInput name="status" label={locationLang('EnableLocation') || ''} /> : <></>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </BasicInformationStyled>
    );
};

export default BasicInformation;

const BasicInformationStyled = styled.div`
display: flex;
justify-content: center;
align-items: center;
@media only screen and (max-width: 767.98px) {
    display: block;
}
.card-title {
    @media only screen and (max-width: 767.98px) {
        margin: 0 0 20px;
    }
}
.card-body {
    display: grid;
}
/* .ant-form-item {
    height: 100px;
} */
.ant-form-item-row {
    display: grid;
}
.form-row {
    margin-top: 0px !important;
    justify-content: space-between;
}
.common-card {
    border: 1px solid #D9D9D9;
    width: 452px;
    @media only screen and (max-width: 767.98px) {
        width: 100%;
    }
}
.avatar-image-location-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: inherit;
    .avatar-image-location {
        padding: 40px 0;
        border-radius: 10px;
        border: 1px dashed var(--Gray-50, #8C8C8C);
        background: var(--Gray-05, #FAFAFA);
        .ant-upload-wrapper.ant-upload-picture-card-wrapper .ant-upload.ant-upload-select {
            position: relative;
            transform: none;
            left: auto;
            top: auto;
            background: #FAFAFA;
            border: 1px solid transparent;
        }
    }
}
`;