import { Form } from 'antd';
import IconAdd from 'assets/svg/IconAdd';
import IconEdit from 'assets/svg/IconEdit';
import { ICustomerItemResData } from 'features/customers/services/types/cutomer';
import React, { useState } from 'react';
import DetailCustomerModal from '../DetailCustomerModal';
import InputSearchCustomer from '../InputSearchCustomer';
import NewCustomerModal, { InitialDataFormAdd } from '../NewCustomerModal';
import salesSelectors from 'features/sales/services/selectors';
import styled from 'styled-components';
import { CUSTOMER_WALKIN_INDEX } from 'utils/unit';
import { pluralizeWord } from 'utils/unit';
import ModalEditLoyalty, { IModalEditLoyaltyForm } from 'components/common/Modal/ModalEditLoyalty';
import { useTranslation } from 'react-i18next';
import Can from 'features/packagePermission/components/Can';
import { ModulePermission, RewardPermission } from 'features/packagePermission/services/types/permission';
import { translate } from 'utils/moment/getSingleDateFormat';

const IconReward = () => <div className='icon'><svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M19.7858 5.71261L15.5001 9.99833L10.5001 2.85547L5.50007 9.99833L1.21436 5.71261V14.9983C1.21436 15.5666 1.44012 16.1117 1.84198 16.5136C2.24385 16.9154 2.78889 17.1412 3.35721 17.1412H17.6429C18.2113 17.1412 18.7563 16.9154 19.1582 16.5136C19.56 16.1117 19.7858 15.5666 19.7858 14.9983V5.71261Z" fill="#FAAD14" />
</svg></div>;
type IProps = {
  viewOnly?: boolean;
  isInProgress?: boolean;
  isTotalWillPointEarn?: boolean;
  currCustomer: ICustomerItemResData | any;
  setCurrCustomer: (currCustomer: ICustomerItemResData | null) => void | any;
  isEdit: boolean,
  isDraft?: boolean,
  handleUpdateLoyalty: (value:any) => Promise<void>
};
const CustomerDetail: React.FC<IProps> = ({ isInProgress = false, currCustomer, setCurrCustomer, viewOnly, isEdit, handleUpdateLoyalty, isDraft = false }) => {
  const [isOpenCustomerModal, setIsOpenCustomerModal] = useState<boolean>(false);
  const [isOpenDetailCustomerModal, setIsOpenDetailCustomerModal] = useState<boolean>(false);
  const [dataFormAdd, setDataFormAdd] = useState<InitialDataFormAdd>();
  const handleChooseCustomer = (item: ICustomerItemResData) => setCurrCustomer(item);
  const [openEditLoyalty, setOpenEditLoyalty] = useState<boolean>(false);
  const [loadingEditLoyalty, setLoadingEditLoyalty] = useState<boolean>(false);  

  const handleOpenCustomerModal = () => setIsOpenCustomerModal(true);
  const handleCancelCustomerModal = () => {
    setDataFormAdd(undefined);
    setIsOpenCustomerModal(false);
  };
  
  const handleOpenEditLoyalty = () => {
    setOpenEditLoyalty(true);
  };

  const handleCancelEditLoyalty = () => {
    setOpenEditLoyalty(false);
  };

  const handleLoyalty = async (value: IModalEditLoyaltyForm) => {
    setLoadingEditLoyalty(true);

    await handleUpdateLoyalty(value);
    setLoadingEditLoyalty(false);
    handleCancelEditLoyalty();

  };
  const {t: bookingLang} = useTranslation('booking');
  const resetCurrCustomer = () => setCurrCustomer(null);
  // const total_point_program_will_earn = salesSelectors.getTotalPointWillEarn();
  const total_point_of_customer = salesSelectors.getTotalPointOfCustomer();
  const Rewards = () => {
    if (!currCustomer) return null;
    const totalPoint = (total_point_of_customer ?? currCustomer?.loyalty_point ?? 0);


    // if (isTotalWillPointEarn) {
    //   return <><IconReward /> <span>Have {totalPoint} Point{totalPoint > 1 ? 's' : ''}</span></>;

    // }

    return <RewardStyled onClick={handleOpenEditLoyalty}><IconReward /> <span>{bookingLang('Have')} {totalPoint} {bookingLang('LoyaltyPoint')}{totalPoint > 1 ? bookingLang('s') : ''}</span></RewardStyled>;
    // if (total_point_program_will_earn && total_point_program_will_earn > 0) {
    //   return <><IconReward /> <span>Will earn {total_point_program_will_earn} points</span></>;
    // }

  };

  // const getLoyaltyPoint = useMemo(() => {

  //   if(!currCustomer) return 0;
    
  //   return currCustomer?.loyalty_point ?? currCustomer?.current_point ?? total_point_of_customer ?? 0;
  // }, [currCustomer]);

  const isCustomerLoyalty = () => {
    return Number(currCustomer?.is_walkin_in ?? 0) !== CUSTOMER_WALKIN_INDEX;
  };
  
  return (
    <div>
      <div className='block-title'>{bookingLang('CustomerDetails')}</div>
      {!currCustomer ? (
        <Form.Item rules={[
          { required: true }
        ]}>
          <div className='search-wrapper'>
            <InputSearchCustomer
              onChooseCustomer={handleChooseCustomer}
              currCustomer={currCustomer}
              setDataFormAdd={setDataFormAdd}
            />
            <button id={'customer-detail-add-customer'} onClick={handleOpenCustomerModal}>
              <IconAdd />
            </button>
          </div>
          <p className='note'>
            {bookingLang('EnterThePhoneNumberOrNameToSearch')}
          </p>
        </Form.Item>
      ) : (
        viewOnly ?
          <div className='group-customer-content'>
            <div className='current-customer'>

              {isCustomerLoyalty() ? <p className='customer-avatar common-avatar'>
                {currCustomer.name && Array.from(Number(currCustomer.is_walkin_in) !== CUSTOMER_WALKIN_INDEX ? currCustomer.name : translate('common', 'WalkInCustomer'))[0]}
              </p> : <IconAvatarWalkIn />}
              <div className='customer-detail'>
                <p style={isCustomerLoyalty() ? { cursor: 'pointer' } : { pointerEvents: 'none' }} onClick={() => setIsOpenDetailCustomerModal(true)} className={`detail-name ${isCustomerLoyalty() ? 'customer-link' : ''}`}>{Number(currCustomer.is_walkin_in) !== CUSTOMER_WALKIN_INDEX ? currCustomer.name : translate('common', 'WalkInCustomer')}</p>
                {isCustomerLoyalty() ?
                  <>
                    <p>{bookingLang('PhoneNumber')}: <span>{currCustomer.phone_number}</span></p>
                    <Can module={ModulePermission.REWARDS} permission={RewardPermission.VIEW_REWARD}>
                      <div className='reward'> <Rewards /></div>
                    </Can>

                  </> : <></>}
              </div>

              {!isInProgress && <button onClick={resetCurrCustomer} className='btn-reset'>
                <IconEdit />
              </button>}
            </div>
            {currCustomer.important_client_info && <div className='customer-note'>
              <ImportantIcon /> <span>{currCustomer.important_client_info}</span>
            </div>}
          </div>
          :
          <div className='group-customer-content'>
            <div className='current-customer'>
              {isCustomerLoyalty() ? <div className='customer-avatar common-avatar'>
                {currCustomer.name && Array.from(Number(currCustomer.is_walkin_in) !== CUSTOMER_WALKIN_INDEX ? currCustomer.name : translate('common', 'WalkInCustomer'))[0]}
              </div> : <IconAvatarWalkIn />}
              <div className='customer-detail'>
                <p
                  className={`detail-name customer-link
                ${isCustomerLoyalty() ? '' : 'customer-link-disable'}`}
                  onClick={() => setIsOpenDetailCustomerModal(true)}>
                  {Number(currCustomer.is_walkin_in) !== CUSTOMER_WALKIN_INDEX ? currCustomer.name : translate('common', 'WalkInCustomer')}</p>
                {isCustomerLoyalty() ?
                  <>
                    <p>
                      {bookingLang('PhoneNumber')}: <span>{currCustomer?.phone_number}</span> {!!currCustomer.is_walkin_in && '(walk-in)'}
                      {<RewardStyled onClick={handleOpenEditLoyalty}>
                        <IconReward /> <span>{bookingLang('Have')} {currCustomer?.current_point ?? currCustomer?.loyalty_point ?? 0} {pluralizeWord(bookingLang('LoyaltyPoint'), currCustomer?.current_point ?? currCustomer?.loyalty_point)}</span>
                      </RewardStyled>}
                    </p>
                    {/* <span
                      className='customer-link'
                      onClick={() => setIsOpenDetailCustomerModal(true)}
                    >
                      View details
                    </span> */}
                  </>
                  : <></>}
              </div>
              {(!isEdit && !isInProgress) || isDraft ? <button onClick={resetCurrCustomer} className='btn-reset'>
                <IconEdit />
              </button> : <></>}
            </div>
            {currCustomer.important_client_info && <div className='customer-note'>
              <ImportantIcon /> <span>{currCustomer.important_client_info}</span>
            </div>}
          </div>
      )}

      {isOpenCustomerModal && (
        <NewCustomerModal
          isOpenModal={isOpenCustomerModal}
          handleCancel={handleCancelCustomerModal}
          initialData={dataFormAdd}
          setCustomer={setCurrCustomer}
        />
      )}

      {isOpenDetailCustomerModal && (
        <DetailCustomerModal
          viewOnly={viewOnly}
          isOpenModal={isOpenDetailCustomerModal}
          handleCancel={() => setIsOpenDetailCustomerModal(false)}
          customer={currCustomer}
          setCurrCustomer={setCurrCustomer}
        />
      )}

      <ModalEditLoyalty loading={loadingEditLoyalty} loyaltyPoint={currCustomer?.current_point ?? currCustomer?.loyalty_point ?? total_point_of_customer ?? 0} isOpen={openEditLoyalty} handleCancel={handleCancelEditLoyalty} handleUpdate={(value) => handleLoyalty(value)} />

    </div>
  );
};

export default CustomerDetail;

const RewardStyled =styled.div`
  display: flex;
  gap: 4px;
  text-decoration: underline;
  cursor: pointer;
  span {
    font-size: 14px;
    font-weight: 400 !important;
  }
`;

const ImportantIcon = () => {
  return (
    <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M11.3535 21.6797C16.8764 21.6797 21.3535 17.2025 21.3535 11.6797C21.3535 6.15684 16.8764 1.67969 11.3535 1.67969C5.83067 1.67969 1.35352 6.15684 1.35352 11.6797C1.35352 17.2025 5.83067 21.6797 11.3535 21.6797Z" stroke="#2F54EB" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M11.3535 15.6797V11.6797" stroke="#2F54EB" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M11.3535 7.67969H11.3635" stroke="#2F54EB" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export const IconAvatarWalkIn = () => {
  return (
    <svg width="32" height="32" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_9508_65285)">
        <rect width="32" height="32" rx="32" fill="white" />
        <g clipPath="url(#clip1_9508_65285)">
          <path d="M64 0H0V64H64V0Z" fill="white" />
          <path d="M64 0.0114746H0V64.0115H64V0.0114746Z" fill="url(#paint0_linear_9508_65285)" />
          <path opacity="0.3" d="M30.9352 -37.3432L-37.8477 31.4396L-2.7443 66.5429L66.0385 -2.23988L30.9352 -37.3432Z" fill="url(#paint1_linear_9508_65285)" />
          <path opacity="0.3" d="M20.7828 -47.4953L-48 21.2875L-11.7961 57.4913L56.9867 -11.2915L20.7828 -47.4953Z" fill="url(#paint2_linear_9508_65285)" />
          <path opacity="0.3" d="M20.7828 -47.4953L-48 21.2875L-20.8471 48.4404L47.9357 -20.3424L20.7828 -47.4953Z" fill="url(#paint3_linear_9508_65285)" />
          <path d="M31.9993 18.6666C28.506 18.6666 25.666 21.5066 25.666 25C25.666 28.4266 28.346 31.2 31.8393 31.32C31.946 31.3066 32.0527 31.3066 32.1327 31.32C32.1593 31.32 32.1727 31.32 32.1993 31.32C32.2127 31.32 32.2127 31.32 32.226 31.32C35.6393 31.2 38.3193 28.4266 38.3327 25C38.3327 21.5066 35.4927 18.6666 31.9993 18.6666Z" fill="white" />
          <path d="M38.7733 34.8667C35.0533 32.3867 28.9866 32.3867 25.2399 34.8667C23.5466 36 22.6133 37.5334 22.6133 39.1734C22.6133 40.8134 23.5466 42.3334 25.2266 43.4534C27.0933 44.7067 29.5466 45.3334 31.9999 45.3334C34.4533 45.3334 36.9066 44.7067 38.7733 43.4534C40.4533 42.32 41.3866 40.8 41.3866 39.1467C41.3733 37.5067 40.4533 35.9867 38.7733 34.8667Z" fill="white" />
        </g>
      </g>
      <defs>
        <linearGradient id="paint0_linear_9508_65285" x1="64" y1="64.0116" x2="0" y2="0.0114746" gradientUnits="userSpaceOnUse">
          <stop stopColor="#6587FF" />
          <stop offset="1" stopColor="#C883FF" />
        </linearGradient>
        <linearGradient id="paint1_linear_9508_65285" x1="66.0387" y1="-2.23979" x2="-21.7616" y2="-28.8994" gradientUnits="userSpaceOnUse">
          <stop stopColor="white" stopOpacity="0.6" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
        <linearGradient id="paint2_linear_9508_65285" x1="56.9868" y1="-11.2915" x2="-33.2784" y2="-37.4289" gradientUnits="userSpaceOnUse">
          <stop stopColor="white" stopOpacity="0.6" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
        <linearGradient id="paint3_linear_9508_65285" x1="47.9358" y1="-20.3425" x2="-20.7476" y2="-48.8628" gradientUnits="userSpaceOnUse">
          <stop stopColor="white" stopOpacity="0.6" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
        <clipPath id="clip0_9508_65285">
          <rect width="64" height="64" rx="32" fill="white" />
        </clipPath>
        <clipPath id="clip1_9508_65285">
          <rect width="64" height="64" fill="white" />
        </clipPath>
      </defs>
    </svg>

  );
};

