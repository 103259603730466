import { Form, Modal, Spin, UploadFile, UploadProps, message } from 'antd';
import Dragger from 'antd/es/upload/Dragger';
import IconUploadClound from 'assets/svg/IconUploadClound';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

export type ImportModalProps = {
  isOpenModal: boolean;
  handleCancel: () => void;
  onSubmit?: (file: UploadFile) => Promise<boolean>;
};
export type ImportModalRef = {
  reset: () => void;
};

/**
 * Import modal
 * @param isOpenModal, handleCancel, onSubmit
 * @returns 
 */
// @ts-ignore
const ImportModal = ({ isOpenModal, handleCancel, onSubmit = () => undefined }: ImportModalProps) => {
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [isSpin, setIsSpin] = useState<boolean>(false);


  /**
   * Before upload
   * Add a beforeUpload function to check the file type before uploading
   * @param file 
   * @returns 
   */
  const beforeUpload: UploadProps['beforeUpload'] = (file) => {
    // Define allowed file types
    const allowedTypes = ['application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'text/csv'];

    // Check if the uploaded file type is allowed
    if (!allowedTypes.includes(file.type)) {
      // Display an error message if the file type is not allowed
      message.error('You can only upload xls, xlsx, or csv files!');
      return false; // Prevent file upload
    }

    // If the file type is allowed, set the fileList to contain only the uploaded file
    setFileList([file]);
    return false; // Prevent default upload behavior
  };

  /**
   * Handle upload
   * Add handleUpload function to handle file uploads
   * @returns 
   */
  const handleUpload = async () => {
    // Check if fileList contains any files
    if (fileList.length === 0) return;
    // Set the loading spinner state to true
    setIsSpin(true);
    try {
      // Call the onSubmit function to upload the first file in fileList
      const isSuccess = await onSubmit(fileList[0]);
      // If upload is successful, set fileList to an empty array
      if (isSuccess) setFileList([]);
    } catch (error) {
      // Handle errors if any
      // You can add error handling code here if necessary
    }
    finally {
      // Set the loading spinner state back to false
      setIsSpin(false);
    }
  };
  const { t: formLang } = useTranslation('form');
  return (

    <Modal
      open={isOpenModal}
      onCancel={handleCancel}
      okButtonProps={{ style: { display: 'none' } }}
      width={574}
      footer={null}
      forceRender
      closable={!isSpin}
      maskClosable={false}
      centered
    // closable={false}
    >
      <Spin spinning={isSpin}>
        <h2 className='modal-title'>
          <p>{formLang('ImportFile')}</p>
        </h2>
        <Form
          data-form-testing='form-customer'
          name='basic'
          className='modal-form'
          autoComplete='off'
          layout='vertical'
        >
          <div className='form-row'>
            <p className='form-notifications-note'>
              {formLang('NoteImportFile')}
            </p>
          </div>
          <Form.Item name={'file'} label={formLang('UploadFile')}>
            <Dragger beforeUpload={beforeUpload} fileList={fileList} maxCount={1}>
              <p className='ant-upload-drag-icon'>
                <IconUploadClound />
              </p>
              <p className='ant-upload-text'>{formLang('ChooseAFile')} </p>
            </Dragger>
          </Form.Item>
          <div className='form-row form-submit-wrapper'>
            <button className='common-btn is-white' onClick={handleCancel}>
              {formLang('Cancel')}
            </button>
            <button
              type='button'
              onClick={handleUpload}
              className='common-btn'>{formLang('Upload')}</button>
          </div>
        </Form>
      </Spin>
    </Modal>
  );
};

export default ImportModal;
