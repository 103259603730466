import { createSlice } from '@reduxjs/toolkit';
import actions from './actions';
import {  NAME_REDUCER } from './constants';
import { IState } from './types/reducer';

const initialState: IState = {
  merchant_permission: {
    package_expiration: '',
    package_name: '',
    package_features: undefined,
    package_extras: undefined
  }
};

export const Slice = createSlice({
  name: NAME_REDUCER,
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(actions.getMerchantPermission.success, (state, { payload }) => {
        state.merchant_permission = payload;
      })
      .addCase(actions.setMerchantPermission, (state, { payload }) => {
        state.merchant_permission = payload;
      });
      
  },
});
const packagePermissionReducer = Slice.reducer;
export default packagePermissionReducer;
