import styled from 'styled-components';
import DateHorizontalPickerItem from './DateHorizontalPickerItem';
import moment from 'moment';
import { getDaysBetweenDates } from 'utils/unit';
import bookingActions from 'features/bookings/services/actions';
import { IApiBookingParams } from 'features/bookings/services/types/booking';
import { useDispatch } from 'react-redux';
import bookingSelectors from 'features/bookings/services/selectors';

/**
 * Render date horizontal item
 * @returns 
 */
const DateHorizontalPicker = () => {

  const dispatch = useDispatch();

  const dates = getDaysBetweenDates(moment().subtract(1, 'month'), moment().add(1, 'month'));
  const dateSelected = bookingSelectors.getCalendarParamValue('date');
  

  const onChangeDate = (_date: number, addOnParams?: IApiBookingParams) => {
    dispatch(
      bookingActions.setCalendarBookingParams({
        start_date: undefined,
        end_date: undefined,
        date: _date,
        ...(addOnParams ?? {}),
      })
    );
  };


  return (
    <DateHorizontalPickerStyled>
      {dates.map((date, index) => (
        <DateHorizontalPickerItem
          onSelect={() => onChangeDate(date.valueOf())} 
          key={index} 
          date={date.format()} 
          isSelected={date.isSame(moment(dateSelected), 'date')} 
          isCurrent={date.isSame(moment(), 'date')} />
        ))}
    </DateHorizontalPickerStyled>
  );
};

export default DateHorizontalPicker;

const DateHorizontalPickerStyled = styled.div`
  display: flex;
  justify-content: space-between;
  overflow-x: auto;
  overflow: auto !important;
`;