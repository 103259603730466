import { Checkbox, ConfigProvider, Form, Input, InputNumber, Modal, Select, Spin } from 'antd';
import { AxiosResponse } from 'axios';
import DatePicker from 'components/common/DatePickerInput';
import FormRow from 'components/common/Form/FormRow';
import message from 'components/common/Message';
import PhoneNumberInput from 'components/common/PhoneNumberInput';
import customerActions from 'features/customers/services/actions';
import apisCustomer from 'features/customers/services/apis';
import { IApiCreateNewCustomerBody, ICustomerItemResData } from 'features/customers/services/types/cutomer';
import {
  IFormAddCustomerData,
  IFuncSubmit,
} from 'features/customers/widgets/AddNewCustomer/CustomersForm';
import { get, set } from 'lodash';
import moment from 'moment';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
import storage from 'utils/sessionStorage';
// import NumberValidations from 'utils/validations/number';
import 'dayjs/locale/vi';
import viVN from 'antd/lib/locale/vi_VN';
import enEL from 'antd/lib/locale/en_US';
import dayjs from 'dayjs';
import IconClose from 'assets/svg/IconClose';
export type InitialDataFormAdd = {
  phoneNumber?: IFormAddCustomerData['phoneNumber'],
  name?: IFormAddCustomerData['name'],
  email?: IFormAddCustomerData['email'],
  importantClientInfo?: IFormAddCustomerData['importantClientInfo'],
  walkIn?: boolean,
} | undefined;
const { TextArea } = Input;
type Props = {
  isOpenModal: boolean;
  handleCancel: any;
  initialData?: InitialDataFormAdd,
  setCustomer: (val: ICustomerItemResData | null) => void;
};

const NewCustomerModal = ({ isOpenModal, handleCancel, initialData, setCustomer }: Props) => {
  const dispatch = useAppDispatch();
  const [isWalkIn, setIsWalkIn] = useState<boolean>(false);
  const [form] = Form.useForm();
  const { t: formLang } = useTranslation('form');
  const { t: customerLang } = useTranslation('customer');
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState<any>({});
  const { Option } = Select;

  const momentDate = moment().utcOffset(10).format('YYMMDDHHmmss');

  useEffect(() => {
    if (initialData?.phoneNumber) {
      form.setFieldValue('phoneNumber', initialData.phoneNumber);
    }

    form.setFieldValue('name', isWalkIn ? 'walkin' + momentDate : '');
    form.setFieldValue('phoneNumber', isWalkIn ? '04' + momentDate.toString().substring(4, 12) : '');

  }, [isWalkIn]);

  useEffect(() => {
    form.setFieldsValue(initialData ?? null);
    setIsWalkIn(!!initialData?.walkIn);
  }, [initialData]);

  const getPayload = (data: IFormAddCustomerData) => {
    const merchant_id = storage.merchantId.get() ?? '';
    const name = data?.name;
    const phone_number = data?.phoneNumber ?? '';
    const email = data?.email ?? '';
    const gender = data?.gender ?? null;
    const date_of_birth = data.birthDate ? data.birthDate.format('YYYY-MM-DD') : null;
    const loyalty_point = data?.loyalty_point ?? null;

    const payload: IApiCreateNewCustomerBody = {
      merchant_id,
      name,
      phone_number,
      email,
      gender,
      is_walkin_in: isWalkIn,
      date_of_birth,
      important_client_info: data.importantClientInfo ?? '',
      loyalty_point
    };
    return payload;
  };

  const handleSubmit: IFuncSubmit = async (data) => {
    const payload = getPayload(data);
    setLoading(true);
    try {
      const rest: AxiosResponse<{ message: string, data: ICustomerItemResData }> = await apisCustomer.createNewCustomer(payload);
      if (rest.data) {
        message.success(rest?.data?.message);
        const params = {
          keyword: payload.phone_number
        };
        const res: AxiosResponse<{ data: ICustomerItemResData[] }> = await apisCustomer.getListCustomers(params);
        if (res?.data?.data) {
          const customerList = res?.data?.data;
          dispatch(customerActions.getListCustomers.success(customerList));
          const currentCus = customerList.find(o => o.phone_number === payload.phone_number) ?? null;
          const walkInCus = { ...rest?.data?.data ?? {}, is_walkin_in: true };
          setCustomer(currentCus || walkInCus);
        }
        handleCancel();
        return true;
      }
      return false;
    } catch (error: any) {
      const textError = get(error, 'response.data.error.message', '');
      if (textError) {
        message.error(textError);
      }

      const response = get<Record<string, string[]>>(
        error,
        'response.data.errors',
        {}
      );

      const errors = Object.entries(response);
      const errorsObj = {};
      errors.forEach(([key, value]) => {
        set(errorsObj, [key], value?.[0]);
      });
      setErrors(errorsObj);
      return false;
    } finally {
      setLoading(false);
    }
  };

  const handleValueChange = (value: any) => {
    // eslint-disable-next-line no-prototype-builtins
    if (value.hasOwnProperty('walkIn')) {
      setIsWalkIn(value.walkIn);
    }
  };

  const errorsText = useMemo(() => {
    return ({
      name: get(errors, 'name', ''),
      phone_number: get(errors, 'phone_number', ''),
      email: get(errors, 'email', ''),
      date_of_birth: get(errors, 'date_of_birth', ''),
      important_client_info: get(errors, 'important_client_info', ''),
      loyalty_point: get(errors, 'loyalty_point', ''),
    });
  }, [errors]);


  const myFocus = useRef<any>();

  useEffect(() => {
    myFocus?.current?.input?.focus();
  }, []);
  const { t: bookingLang } = useTranslation('booking');
  const { t: voucherLang } = useTranslation('vouchers');

  const lang = 'vi';
  dayjs.locale(lang === 'vi' ? 'vi' : 'en');
  const locale = lang === 'vi' ? viVN : enEL;

//  const onChange = () => undefined;

  return (
    <ModalStyled
      maskClosable={false}
      open={isOpenModal}
      onCancel={handleCancel}
      okButtonProps={{ style: { display: 'none' } }}
      width={1200}
      footer={null}
      forceRender
      centered
      closable={false}
    >

      <Spin spinning={loading}>
        <Form
          data-form-testing="form-booking-customer"
          name='basic'
          className='modal-form'
          onFinish={handleSubmit}
          autoComplete='off'
          form={form}
          onValuesChange={handleValueChange}
          onKeyUp={(e) => {
            e.preventDefault();
            if (e.key === 'Enter') {
              form.submit();
            }
          }}
          initialValues={{
            phoneNumberCode: '+61',
            walkIn: false,
            gender: 0,
          }}
          layout='vertical'
        >
          <AddCustomerStyled>
            <div className='icon-close' onClick={handleCancel}>
              <IconClose />
            </div>
            <h2 className='modal-title'>{bookingLang('AddNewCustomer')}</h2>
            <button type='submit' className='common-btn' >
              {voucherLang('Xác nhận')}
            </button>
          </AddCustomerStyled>
          {/* <Form.Item name='walkIn' valuePropName='checked'>
            <div className='form-row'>
              <p className='form-label'>Walk-In Customer</p>
              <Switch defaultChecked={isWalkIn} onChange={() => setIsWalkIn(!isWalkIn)} />
            </div>
          </Form.Item> */}
          <CustomersFormFirstStepStyled>
            <div className='left'>
              <div className='profile-customer'>
                <div className='title'>
                  <h4>Thông tin khách hàng</h4>
                </div>
                <Form.Item
                  name='name'
                  label={bookingLang('Name')}
                  requiredMark={false}
                  rules={[
                    { required: true, message: bookingLang('NameIsRequired') || '' },
                    { min: 2, message: bookingLang('NameMustBeAtLeast2Characters') || '' },
                    { max: 30, message: bookingLang('NameMustBeAtMost30Characters') || '' }
                  ]}
                  validateStatus={errorsText.name ? 'error' : undefined}
                  help={errorsText.name ? errorsText.name : undefined}
                >
                  <Input ref={myFocus} autoFocus placeholder={formLang('EnterCustomerNamePlaceholder') || ''}></Input>
                </Form.Item>

                <Form.Item
                  label={bookingLang('PhoneNumber')}
                  name='phoneNumber'
                  requiredMark={false}
                  rules={[
                    {
                      required: true,
                      message: bookingLang('PhoneNumberIsRequired') || '',
                    },
                    // NumberValidations.PHONE
                    { pattern: /^0+[0-9]{9}$/, message: formLang('ThePhoneNumberIs04AndHas10Digits') || '' }
                  ]}
                  validateStatus={errorsText.phone_number ? 'error' : undefined}
                  help={errorsText.phone_number ? errorsText.phone_number : undefined}
                >
                  <PhoneNumberInput placeholder={'0_________'} />
                </Form.Item>

                <FormRow
                  name='email'
                  label={'Email'}
                  rules={[{ type: 'email', message: bookingLang('IsNotAValidEmail') || '' },]}
                  requiredMark={false}
                  // optional
                  validateStatus={errorsText.email ? 'error' : undefined}
                  help={errorsText.email ? errorsText.email : undefined}
                >
                  <Input placeholder={formLang('EnterCustomerEmailPlaceholder') || ''} />
                </FormRow>

                <div className='gender-birthDate'>
                  <FormRow label={customerLang('Gender')} name='gender'>
                    <Select>
                      <Option value={0}>{customerLang('Female')}</Option>
                      <Option value={1}>{customerLang('Male')}</Option>
                      <Option value={2}>{customerLang('NonBinary')}</Option>
                    </Select>
                  </FormRow>
                  <div className="form-row">
                    <ConfigProvider locale={locale}>
                      <Form.Item
                        name="birthDate"
                        label={
                          <p className='BirthDate-title'>
                            {customerLang('BirthDate')}
                          </p>
                        }
                      >
                        <DatePicker placeholder='DD-MM-YYYY' clearIcon={false} />
                      </Form.Item>
                    </ConfigProvider>
                  </div>
                </div>

                <FormRow
                  label={bookingLang('Point')}
                  name='loyalty_point'
                  requiredMark={false}
                  validateStatus={errorsText.loyalty_point ? 'error' : undefined}
                  optional
                  help={errorsText.loyalty_point ? errorsText.loyalty_point : undefined}
                >
                  <InputNumber type='number' min={0} placeholder={bookingLang('EnterPointPlaceholder') || ''} style={{ width: '100%', border: 'none' }} />
                </FormRow>
              </div>
              <div className='note-customer'>
                <div className='title'>
                  <h4>Ghi chú khách hàng</h4>
                  {/* <p>Thông tin này sẽ chỉ hiển thị cho bạn hoặc người có quyền truy cập vào hệ thống.</p> */}
                </div>
                <Form.Item
                  className='important-note'
                  label={
                    <p>
                      {formLang('ImportantClientInfoLabel')}
                      <span>({bookingLang('Optional')})</span>
                    </p>
                  }
                  name='importantClientInfo'
                  extra={bookingLang('ThisNoteWillBeDisplayedOnAllBookings')}
                  validateStatus={errorsText.important_client_info ? 'error' : undefined}
                  help={errorsText.important_client_info ? errorsText.important_client_info : undefined}
                >
                  <TextArea
                    rows={5}
                    placeholder={bookingLang('EgCustomerAllergicToFragrance') || ''}
                  />
                </Form.Item>
              </div>
            </div>
            <div className='right'>
              <div className='right-content'>
                <div className='title-right'>
                  <h4>Cài đặt thông báo</h4>
                  <p>Cài đặt cập nhật thông báo cho khách hàng về các lịch hẹn, sản phẩm và chương trình khuyến mãi.</p>
                </div>
                <ul>
                  <li className='notification-booking-service'>
                    <div className={'setting-notification'}>
                      <p>{customerLang('Thông tin lịch hẹn và sản phẩm')}</p>
                      <Form.Item valuePropName={'checked'} name='1'>
                        <Checkbox className='check-show-booking'>{'Gửi qua tin nhắn SMS'}</Checkbox>
                      </Form.Item>
                      <Form.Item valuePropName={'checked'} name='2'>
                        <Checkbox style={{ marginBottom: '24px' }} className='check-show-booking'>{'Gửi qua tin nhắn Zalo'}</Checkbox>
                      </Form.Item>
                    </div>
                  </li>
                  <li className='maketings'>
                    <div className={'setting-notification'}>
                      <p>{customerLang('Thông tin chương trình marketings')}</p>
                      <Form.Item valuePropName={'checked'} name='3'>
                        <Checkbox className='check-show-booking'>{'Gửi qua tin nhắn SMS'}</Checkbox>
                      </Form.Item>
                      <Form.Item valuePropName={'checked'} name='4'>
                        <Checkbox className='check-show-booking'>{'Gửi qua tin nhắn Zalo'}</Checkbox>
                      </Form.Item>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            {/* <div className='form-row form-submit-wrapper'>
              <button className='common-btn is-white' onClick={handleCancel}>
                {bookingLang('Cancel')}
              </button>
              <button type='submit' className='common-btn'>{bookingLang('Save')}</button>
            </div> */}
          </CustomersFormFirstStepStyled>
        </Form>
      </Spin>
    </ModalStyled>
  );
};

export default NewCustomerModal;

const ModalStyled = styled(Modal)`
.ant-modal-content{
  padding: 46px 20px 0px;
}
`;


const AddCustomerStyled = styled.div`
display: flex;
justify-content: space-between;
align-items: center;
border-bottom: 1px solid #1B9AAA;
position: sticky;
background: white;
z-index: 99999;
top: -50px;
padding-top: 10px;
.icon-close {
  cursor: pointer;
  width: 20%;
}
.modal-title {
  width: 60%;
  margin-bottom: 0px;
  text-align: center;
}
button {
  width: 20%;
  min-width: 0px;
}
`;

const CustomersFormFirstStepStyled = styled.div`
display: flex;
gap: 24px;
margin-top: 24px;
padding: 0 50px;
@media only screen and (max-width: 767.98px) {
  padding: 0 8px;
  display: grid;
}
.left {
  flex: 1;
  display: grid;
  gap: 24px;
  .profile-customer {
    border-radius: 12px;
    border: 1px solid var(--Gray-30, #D9D9D9);
    padding: 24px;
    display: grid;
    gap: 24px;
    .title {
      color: var(--Gray-95, #141414);
      font-family: Inter;
      font-size: 20px;
      font-weight: 700;
      line-height: 140%;
    }
    .gender-birthDate {
      display: flex;
      gap: 12px;
      @media only screen and (max-width: 767.98px) {
        display: grid;
      }
    }
  }
  .note-customer {
    border-radius: 12px;
    border: 1px solid var(--Gray-30, #D9D9D9);
    padding: 24px;
    display: grid;
    gap: 24px;
    .title {
      display: grid;
      gap: 8px;
      h4 {
        color: var(--Gray-95, #141414);
        font-family: Inter;
        font-size: 20px;
        font-weight: 700;
        line-height: 140%;
      }
      p {
        color: var(--Gray-60, #595959);
        font-family: Inter;
        font-size: 12px;
        font-weight: 400;
        line-height: 140%;
      }
    }
    .note-container {
      display: grid;
      gap: 16px;
    }
  }
}

.ant-form-item-control {
  margin-bottom: 0 !important;
}

.right {
  flex: 1;
  .right-content {
    border-radius: 12px;
    border: 1px solid var(--Gray-30, #D9D9D9);
    padding: 24px;
    display: grid;
    gap: 24px;

    .title-right {
    display: grid;
    gap: 8px;
    h4 {
      color: var(--Gray-95, #141414);
      font-family: Inter;
      font-size: 20px;
      font-weight: 700;
      line-height: 140%;
    }
    p {
      color: var(--Gray-60, #595959);
      font-family: Inter;
      font-size: 12px;
      font-weight: 400;
      line-height: 140%;
    }
  }
  ul {
    .notification-booking-service {
      margin-bottom: 24px;
      border-bottom: 1px solid #F0F0F0;
      .setting-notification {
      p {
        color: var(--Gray-95, #141414);
        font-family: Inter;
        font-size: 16px;
        font-weight: 600;
        line-height: 140%;
      }
      span {
        color: var(--Gray-95, #141414);
        font-family: Inter;
        font-size: 16px;
        font-weight: 500;
        line-height: 140%;
      }
    }
    
    }
    .maketings {
      .setting-notification {
        p {
          color: var(--Gray-95, #141414);
          font-family: Inter;
          font-size: 16px;
          font-weight: 600;
          line-height: 140%;
        }
        span {
          color: var(--Gray-95, #141414);
          font-family: Inter;
          font-size: 16px;
          font-weight: 500;
          line-height: 140%;
        }
      }
    }
  }
  }
}
`;
