import { Input } from 'antd';
import message from 'components/common/Message';
import { ButtonSuccessBig } from 'features/bookings/components/BookingForm/Confirm/BasicContent';
import { IVoucherDataItem } from 'features/sales/services/types/voucher';
import vouchersApis from 'features/vouchers/services/apis';
import { first, get } from 'lodash';
import { useEffect, useState } from 'react';
import { useSetLoadingPage } from 'services/UI/LoadingPage';
import styled from 'styled-components';
import { IServiceSelectedItem as CheckoutSer } from 'features/checkout/services/types/service';
import { IServiceSelectedItem as SalesSer } from 'features/sales/services/types/service';
import { useTranslation } from 'react-i18next';


export type IService = CheckoutSer | SalesSer;

export type IFuncVoucherAccept = (val: IVoucherDataItem | null) => void
type IVoucherInputProps = {
  defaultValue?: string;
  onApply?: IFuncVoucherAccept;
  customer_code?: string;
  services?: IService[];
  voucherSelected?: IVoucherDataItem | null;
  openModal?: boolean;
};

/**
 * Voucher input
 * @param defaultValue , customer_code, onApply, services, voucherSelected, openModal
 * @returns 
 */
const VoucherInput = ({ defaultValue = '', customer_code, onApply = () => undefined, services = [], voucherSelected, openModal }: IVoucherInputProps) => {
  const [text, setText] = useState<string>(defaultValue);
  const [error, setError] = useState('');
  const [warning, setWarning] = useState('');
  const setLoadingPage = useSetLoadingPage();
  const [, setData] = useState<IVoucherDataItem | null>(null);
  const {t: bookingLang} = useTranslation('booking');

  // const [, startTransition] = useTransition();
  // const reset = () => {
  //   startTransition(() => {
  //     setError('');
  //     setText('');
  //     setData(null);
  //     setWarning('');
  //   });
  // };


  useEffect(() => {
    setError('');
    setWarning('');
    if(voucherSelected) {
      setText('');
    }
  }, [voucherSelected, openModal]);

  if (customer_code === null || customer_code === undefined) return null;

  /**
   * On redeem
   * @returns 
   */
  const onRedeem = async () => {
    if (!text) return setError(bookingLang('PleaseInputVoucherCode')||'');
    setData(null);
    setLoadingPage(true);
    try {
      const res = await vouchersApis.checkVoucherCodValid(text, customer_code);
      const errorMsg = get(res, 'data.error.message');
      if (errorMsg) {
        setError(errorMsg);
      } else {
        // @ts-ignore
        const resData = res?.data?.data as IVoucherDataItem;
        if (resData) {
          const result: string[] = [];
          resData.services.forEach(o => {
            if (o.service_variants.length > 0) {
              o.service_variants.forEach(s => {
                const id = o?.id + '_' + s.id;
                result.push(id);
              });
            } else {
              result.push(o?.id?.toString());
            }
          });          

          const validService = !!services.find(o => {
            const id = (o?.id?.toString() ?? '') + (o?.service_variant_id ? '_' + o?.service_variant_id : '');
            return !result.includes(id);
          });

          const resultMapping = {
            ...resData,
            services_flatten_ids: result,
            value_remaining: resData.value - resData.redeemed_value
          };
          if (!validService) {
            setWarning('Please check again, there are currently no services that match the voucher you selected');
          } else {
            onApply(resultMapping);
          }
          setData(resultMapping);
        }
      }
    } catch (error: any) {
      const response = get<Record<string, string[]>>(
        error,
        'response.data.errors',
        {}
      );

      const errors = Object.entries(response);
      const err = first(errors);
      if (err) {
        const msg = get(err, [1, 0], '');
        if (err[0] === 'voucher_code') {
          setError(msg);
        } else {
          message.error(msg);
        }
      }
    } finally {
      setLoadingPage(false);
    }
  };


  return (
    <Styled>
      {/* <div className='title'>Voucher code</div> */}
      <div className='form-group'>
        <div className='form-row' style={{ marginTop: 0 }}>
          <Input placeholder={bookingLang('EnterVoucherCode')||''} value={text} onChange={e => {
            setError('');
            setWarning('');
            if (!e.target.value) {
              setData(null);
            }
            setText(e.target.value);
          }} />
          <ButtonSuccessBig className='blue' onClick={onRedeem}>{bookingLang('Check')}</ButtonSuccessBig>
        </div>
      </div>
      {error ? <p className='voucher-error'>{error}</p> : ''}
      {!!warning && <p className='voucher-warning'>{warning}</p>}

      {/* {error ? <p className='voucher-error'>{error}</p> : data ? <div className='gr-content'>
        <div className='row none-border'>
          <div className='title'>voucher information</div>
          <div className='value'></div>
        </div>
        <div className='row'>
          <div className='title'>Voucher name</div>
          <div className='value'>{data.name}</div>
        </div>
        <div className='row none-border'>
          <div className='title'>Voucher’s owner</div>
          <div className='value'>{data?.customer?.name}</div>
        </div>
        <div className='row'>
          <div className='title'>Value</div>
          <div className='value'>{data?.value}</div>
        </div>
        <div className='row'>
          <div className='title'>Redeemed</div>
          <div className='value'>{data?.redeemed_value}</div>
        </div>
        <div className='row'>
          <div className='title'>Expiry date</div>
          <div className='value'>{getFormatShortDate(data.expiry_date, 'YYYY-MM-DD HH:mm:ss')}</div>
        </div>
        <div className='row'>
          <div className='title'>Restrict services</div>
          <div className='value'>None</div>
        </div>
        {!!warning && <p className='voucher-warning'>{warning}</p>}
        <div className='form-submit-wrapper'>
          <div
            className='common-btn is-white'
            // onClick={reset}
          >
            Clear
          </div>
        </div>
      </div> : null} */}
    </Styled>

  );
};

export default VoucherInput;

const Styled = styled.div`
margin-top: 48px;
.form-group {
  display: flex;
  align-items: flex-start;
  .form_item {
    flex:1;
  }
}
.gr-content {
  margin-top:12px;
}
  .title {
    font-weight: 600;
    font-size: 18px;
    color: var(--color-primary);
  }

  .voucher-error {
    color: var(--grayish-navy-main, red);
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 22.4px */
    margin-top: 0px;
  }
  .voucher-warning {
    color: #faad14;
    font-weight: 400;
    margin-top:15px;
  }
  .row {
    display:flex;
    justify-content: space-between;
    padding: 12px 0;
    border-bottom: 1px solid var(--background-offwhite, #F0F2F7);
    &.none-border {
      border-bottom:unset;
    }
    .title {
      color: var(--grayish-navy-main, #363565);
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 140%; /* 19.6px */
      text-transform: capitalize;
    }
    .value {
      color: var(--grayish-navy-main, #363565);
      text-align: right;
      /* font-family: 'Inter'; */
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 140%; /* 22.4px */
    }
  }
  .blue {
    white-space: nowrap;
  }
`;
