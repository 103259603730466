import { Form, Switch } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
type Props = {
    isCategoryPromotion?: string;
};
const SwitchSetting = ({ isCategoryPromotion }: Props) => {
    const { t: formLang } = useTranslation('form');

    return (
        <SwitchSettingstyled>
            {
                isCategoryPromotion === 'auto' || isCategoryPromotion === 'code' ?
                    <>
                        <div className='form-row switch-check'>
                            <Form.Item
                                name={'limitToUsePerCustomer'}
                                valuePropName='checked'
                            >
                                <Switch />
                            </Form.Item>
                            <div>
                                <p className='form-label'>{formLang('Giới hạn sử dụng của khách hàng')}</p>
                                <p>Mỗi khách hàng sẽ chỉ có thể sử dụng khuyến mãi này một lần.</p>
                            </div>
                        </div>

                        <div className='form-row switch-check'>
                            <Form.Item name={'limitTotalNumberOfUse'} valuePropName='checked'>
                                <Switch />
                            </Form.Item>
                            <div>
                                <p className='form-label'>{formLang('Giới hạn lần sử dụng')}</p>
                                <p>Cài đặt tổng số lần khuyến mãi này có thể được sử dụng.</p>
                            </div>
                        </div>
                    </> : <></>
            }

            {
                isCategoryPromotion === 'vist_point' ?
                    <div className='form-row switch-check'>
                        <Form.Item noStyle name={'applyWithOtherPromotion'} valuePropName='checked'>
                            <Switch />
                        </Form.Item>
                        <p className='form-label'>{formLang('Không tích điểm cho đơn hàng sử dụng phần thưởng')}</p>
                    </div>
                    : <></>
            }

            {
                isCategoryPromotion === 'retention' || isCategoryPromotion === 'signup' || isCategoryPromotion === 'birthday' || isCategoryPromotion === 'auto' || isCategoryPromotion === 'code' ?
                    <div className='form-row switch-check'>
                        <Form.Item noStyle name={'applyWithOtherPromotion'} valuePropName='checked'>
                            <Switch />
                        </Form.Item>
                        <p className='form-label'>{formLang('Cài đặt giá trị tối thiểu áp dụng')}</p>
                    </div>
                    : <></>
            }

            {
                isCategoryPromotion === 'retention' || isCategoryPromotion === 'signup' || isCategoryPromotion === 'birthday' || isCategoryPromotion === 'auto' || isCategoryPromotion === 'code' ?
                    <div className='form-row switch-check'>
                        <Form.Item
                            noStyle
                            name={'enableBookingOnline'}
                            valuePropName='checked'
                        >
                            <Switch defaultChecked />
                        </Form.Item>
                        <p className='form-label'>
                            {formLang('Cài đặt giá trị tối đa được khuyến mãi')}
                        </p>
                    </div>
                    : <></>
            }

            <div className='form-row switch-check'>
                <Form.Item noStyle name={'enableVoucherSale'} valuePropName='checked'>
                    <Switch />
                </Form.Item>
                <p className='form-label'>{formLang('Áp dụng với chương trình khuyến mãi khác')}</p>
            </div>
            {
                isCategoryPromotion === 'retention' ?
                    <div className='form-row switch-check'>
                        <Form.Item
                            noStyle
                            name={'enableApplyWalkIn'}
                            valuePropName='checked'
                        >
                            <Switch defaultChecked />
                        </Form.Item>
                        <p className='form-label'>
                            {formLang('Áp dụng khi đặt lịch trực tuyến')}
                        </p>
                    </div>
                    : <></>
            }

            <div className='form-row switch-check'>
                <Form.Item noStyle name={'enablePromotion'} valuePropName='checked'>
                    <Switch defaultChecked />
                </Form.Item>
                <p className='form-label'>{formLang('Hiển thị chương trình khuyến mãi')}</p>
            </div>
        </SwitchSettingstyled>
    );
};

export default SwitchSetting;

const SwitchSettingstyled = styled.div`
.ant-form-item {
    width: unset;
}
`;