import i18next from 'i18next';
import moment, { Moment } from 'moment';
export const translate = (namespace: string, key: string) => i18next.t(`${namespace}:${key}`);
/**
 * Handle get single date format
 * @param _date 
 * @returns 
 */
const getSingleDateFormat = (_date: Moment) => {
  
    const today = moment();
    
    if (_date.isSame(today, 'day')) {
      return `${translate('form', 'Today')}, ${_date.format('DD.MM.YYYY')}`;
    }

    if (_date.isSame(today.clone().subtract(1, 'days'), 'day')) {
      return `${translate('form', 'Yesterday')}, ${_date.format('DD.MM.YYYY')}`;
    }

    if (_date.isSame(today.clone().add(1, 'days'), 'day')) {
      return `${translate('form', 'Tomorrow')}, ${_date.format('DD.MM.YYYY')}`;
    }

    return `${translate('form',_date.format('dddd'))}, ${_date.format('DD.MM.YYYY')}`;
};

export default getSingleDateFormat;