import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const CustomerMessage = ({content} : {content: string}) => {
  const{t: invoiceLang} = useTranslation('invoice');
  return (
    <CustomerMessageStyled>
      <p style={!content ? {display: 'none'} : {}} className='customer-message-header'>*{invoiceLang('Note')}:</p>
      <p className='customer-message-content'>{content}</p>
    </CustomerMessageStyled>
  );
};

export default CustomerMessage;

const CustomerMessageStyled = styled.div`
  text-align: initial; 
  margin-top: 90px; 
  color: #363565;
  .customer-message-header {
    font-size: 20px; 
    font-weight: 400;
  }
  .customer-message-content {
    font-size: 20px; 
    font-weight: 600;
  }
`;