import { createSlice } from '@reduxjs/toolkit';
import { NAME_REDUCER } from './constants';
import actions from './actions';

const initialState: any = {
  listPaginateCategory: [],
};

export const Slice = createSlice({
  name: NAME_REDUCER,
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(actions.getListPaginateCategory.success, (state, { payload }) => {
        const data = payload as any[];
        state.listPaginateCategory = data;
      });
  },
});
const productServiceReducer = Slice.reducer;
export default productServiceReducer;
