const IconCheckOverview = () => {
  return (
    <svg
      width="80"
      height="80"
      viewBox="0 0 80 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="check-circle">
        <path
          id="Vector"
          d="M73.3333 36.9334V40.0001C73.3292 47.1882 71.0017 54.1824 66.6978 59.9395C62.3939 65.6967 56.3443 69.9084 49.4512 71.9464C42.5581 73.9845 35.1909 73.7398 28.4482 71.2487C21.7056 68.7577 15.9488 64.1538 12.0365 58.1237C8.12421 52.0936 6.26596 44.9603 6.73891 37.7878C7.21186 30.6153 9.99068 23.7879 14.6609 18.3237C19.3312 12.8595 25.6426 9.05139 32.654 7.46727C39.6653 5.88315 47.0009 6.60791 53.5667 9.53345"
          stroke="#1BB250"
          strokeWidth="5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_2"
          d="M73.3333 13.3333L40 46.6999L30 36.6999"
          stroke="#1BB250"
          strokeWidth="5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};

export default IconCheckOverview;
