import { Input } from 'antd';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

type Props = {
  handleChange?: any;
  isGray?: boolean;
};

const InputSearchServices = (props: Props) => {
  const { handleChange, isGray } = props;
  const { t: bookingLang } = useTranslation('booking');

  return (
    <>
      <InputSearchServicesStyled>
        <div className={`common-input-search ${isGray ? 'is-gray' : null}`}>
          <Input
            type='text'
            placeholder={bookingLang('SearchServicesPlaceholder') || ''}
            onChange={(e) => handleChange(e.target.value)}
            onPressEnter={(e) => e.preventDefault()}
          ></Input>
        </div>
      </InputSearchServicesStyled>
    </>
  );
};

export default InputSearchServices;

const InputSearchServicesStyled = styled.div`
  position: relative;
  color: var(--color-primary);
  margin-bottom: 16px;
`;
