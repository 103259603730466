import message from 'components/common/Message';
import checkoutActions from 'features/checkout/services/actions';
import apisCheckout from 'features/checkout/services/apis';
import { EErrorMethodKeys } from 'features/checkout/services/constants';
import checkoutSelectors from 'features/checkout/services/selectors';
import { EPaymentMethod } from 'features/checkout/services/types/checkout';
import { get } from 'lodash';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useSetLoadingPage } from 'services/UI/LoadingPage';
import { useAppDispatch } from 'store/hooks';
import { IPurchaseProps } from './type';
import { IFuncPromotionAccept } from 'features/checkout/services/types/promotion';
import useDoRedeem from 'features/checkout/components/PromotionCode/useDoRedeem';
import useFee from './useFee';
import { getUniqueTransactionId, roundNumber } from 'utils/unit';
import { EResultTransactionType, ITransactionCompleteCallbackResponse } from 'hooks/useIClientTyro';
import settingSelectors from 'features/settings/services/selectors';
import { useCheckoutPayment } from './useSurcharge';
import useIClientTyroOptimize from 'hooks/useClientTyroOptimize';

export type IErrorMixPayment = string;
export const useMixErrors = (): [IErrorMixPayment, (val: IErrorMixPayment) => void] => {
  const dispatch = useAppDispatch();
  const errors = checkoutSelectors.getPurchaseMethodErrors(EPaymentMethod.MIX);
  const errorText = useMemo(() => get(errors, EErrorMethodKeys.mix, ''), [errors]);
  const setErrorValid = (msg: IErrorMixPayment) => {
    dispatch(checkoutActions.setPurchaseMethodErrors({
      method: EPaymentMethod.MIX,
      errors: { [EErrorMethodKeys.mix]: msg, }
    }));
  };
  return [errorText, setErrorValid];
};

/**
 * Mix
 */
const Mix = ({ onSuccess, isActive }: IPurchaseProps) => {
  const { t: checkoutLang } = useTranslation('checkout');
  const selectedReward = checkoutSelectors.getSelectedReward();

  const id = checkoutSelectors.getIdBookingDetail();
  const setLoadingPage = useSetLoadingPage();

  const amount = checkoutSelectors.getOriginTotalPrice();
  const promotionInfo = checkoutSelectors.getPromotionInfo();
  const softPromotionCode = checkoutSelectors.getSoftPromotionCode();
  const onPromotionAccept: IFuncPromotionAccept = (val) => dispatch(checkoutActions.setPromotionInfo(val));
  const detail = checkoutSelectors.getBookingDetail();
  const servicesSelected = checkoutSelectors.getSelectedServices();
  const iClient = useIClientTyroOptimize();
  const setting = settingSelectors.getSetting();

  const obFeeValues = useFee();
  const obCheckoutPayment = useCheckoutPayment();
  const doSoftRedeem = useDoRedeem(detail?.merchant_location_id || '', {
    servicesSelected,
    setErrorBoundary: (val) => dispatch(checkoutActions.setPromotionErrorBoundary(val)),
  });

  const cardValue = checkoutSelectors.getOriginalCardValue();

  const curMixPayments = checkoutSelectors.getMixPayments();
  const { cash, voucher } = useMemo(() => ({
    voucher: curMixPayments.find(o => o.type === EPaymentMethod.VOUCHER),
    cash: curMixPayments.find(o => o.type === EPaymentMethod.CASH),
  }), [curMixPayments, cardValue]);

  const [, setErrorValid] = useMixErrors();
  const dispatch = useDispatch();

  const voucherSalesSelected = checkoutSelectors.getSelectedVouchersForSales() ?? [];

  /**
   * Transaction complete callback
   * @param model 
   * @returns 
   */
  const transactionCompleteCallback = (model: 'purchase' | 'refund') => async (response: ITransactionCompleteCallbackResponse) => {
    let a ='EResultTransactionType.APPROVED';
    a=EResultTransactionType.APPROVED;
    switch (a) {
      case EResultTransactionType.APPROVED: {
        const transactionId = response.transactionReference ?? getUniqueTransactionId();
        const mid = setting.general.merchant_id;
        const tid = setting.general.teminal_id;
        setLoadingPage(true);
        const amount_given = roundNumber(amount - (voucher?.value ?? 0) > 0 ? amount - (voucher?.value ?? 0) : 0);

        try {
          const res = await apisCheckout.apiCheckoutWithMixin(String(id), {
            amount_given,
            ...obCheckoutPayment ?? undefined,
            mixin_payment: {
              cash: cash?.value ? {
                amount_pay: roundNumber(amount_given - (cardValue ?? 0)),
                amount_received: cash?.value  
              }: undefined,
              voucher: voucher?.data?.voucher_code ? {
                voucher_code: voucher?.data?.voucher_code,
                amount_pay: roundNumber(voucher?.value ?? 0)
              } : undefined,
              card: (cardValue ?? 0) > 0 ? {
                transaction_id: transactionId,
                MID: mid,
                TID: tid,
                amount_pay: roundNumber(cardValue ?? 0),
              } : undefined,
            },
            voucher_code: cash?.value === 0 && cardValue === 0 ? voucher?.data?.voucher_code : undefined,
            voucher_sales: voucherSalesSelected.map(({ id, quantity, receiver_info, is_gift }) => ({ 
              voucher_id: id, 
              quantity,
              is_gift: is_gift,
              receiver_info: receiver_info
            })),
            loyalty_reward_id: selectedReward?.id,
            ...obFeeValues ?? {},
            ...obCheckoutPayment ?? undefined,
          });
          const msg = get(res, 'data.data.message', '');
          if (msg) {
            message.success(model === 'purchase' ? 'Purchase' : 'Refund' + ' Successful');
            dispatch(checkoutActions.setLoyaltyPoint(res?.data?.data?.notify_invoice?.loyalty_point));
            dispatch(checkoutActions.setResultTotal(res?.data?.data?.notify_invoice));
            onSuccess();
          } else {
            const error = get(res, 'data.error.message', '');
            if (error) {
              message.error(error);
            } else
              throw 'fail';
          }
        } catch (error) {
          message.error('An error occurred. Please try again');
          // if (model === 'purchase') {
          //   iClient.refund(amount, {
          //     transactionCompleteCallback: transactionCompleteCallback('refund'),
          //   });
          // }
        } finally {
          setLoadingPage(false);
        }
        return;
      }
      case EResultTransactionType.CANCELLED:
      case EResultTransactionType.REVERSED:
      case EResultTransactionType.DECLINED: {
        return;
      }
      case EResultTransactionType.SYSTEM_ERROR: {
        return;
      }
      default: {
        return;
      }
    }
  };

  /**
   * On purchase
   * @returns 
   */
  const onPurchase = async () => {
    setErrorValid('');
    if (curMixPayments.length === 0) {
      setErrorValid('Please add a payment method');
      return;
    }
    const totalPay = (cash?.value ?? 0) + (voucher?.value ?? 0) + (cardValue ?? 0);

    if (amount > totalPay) {
      setErrorValid(checkoutLang('validate.mix.errorAmount'));
      return;
    }

    if (cardValue && cardValue > 0 && setting.general.merchant_id !== '' && setting.general.teminal_id !== '') {
      iClient.purchase(roundNumber(cardValue), { transactionCompleteCallback: transactionCompleteCallback('purchase') });
      return;
    }

    setLoadingPage(true);
    const amount_given = amount - (voucher?.value ?? 0) > 0 ? amount - (voucher?.value ?? 0) : 0;
    try {
      if (!promotionInfo && softPromotionCode) {
        onPromotionAccept(await doSoftRedeem(softPromotionCode));
        return;
      }
      const res = await apisCheckout.apiCheckoutWithMixin(String(id), {
        amount_given,
        mixin_payment: {
          cash: cash?.value ? {
            amount_pay: amount_given - (cardValue ?? 0),
            amount_received: cash?.value
          }: undefined,
          voucher: voucher?.data?.voucher_code ? {
            voucher_code: voucher?.data?.voucher_code,
            amount_pay: roundNumber(voucher?.value ?? 0)
          } : undefined,
          card: cardValue ? {
            transaction_id: getUniqueTransactionId(),
            MID: 'N/A',
            TID: 'N/A',
            amount_pay: roundNumber(cardValue ?? 0),
          } : undefined,
        },
        voucher_code: cash?.value === 0 && cardValue === 0 ? voucher?.data?.voucher_code : undefined,
        voucher_sales: voucherSalesSelected.map(({ id, quantity, is_gift, receiver_info }) => ({ 
          voucher_id: id, 
          quantity,
          is_gift,
          receiver_info
        })),
        loyalty_reward_id: selectedReward?.id,
        ...obFeeValues ?? {},
        ...obCheckoutPayment ?? undefined,
      });

      const msg = get(res, 'data.data.message', '');
      if (msg) {
        message.success(msg);
        dispatch(checkoutActions.setLoyaltyPoint(res?.data?.data?.notify_invoice?.loyalty_point));
        dispatch(checkoutActions.setResultTotal(res?.data?.data?.notify_invoice));
        onSuccess();
      } else {
        const error = get(res, 'data.error.message', '');
        if (error) {
          message.error(error);
        } else
          throw 'fail';
      }
    } catch (error) {
      message.error('An error occurred. Please try again');
    } finally {
      setLoadingPage(false);
    }
  };
  const{t: bookingLang} = useTranslation('booking');
  return (
    <button
      type='button'
      className='form-booking-submit'
      onClick={onPurchase}
      disabled={isActive}
    >
      {bookingLang('Confirm')}
    </button>
  );
};

export default Mix;
