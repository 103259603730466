import React from 'react';
import bookingFormImage from 'assets/images/booking/bookingForm';
import styled from 'styled-components';
import dayjs from 'utils/dayjs';
import { useTranslation } from 'react-i18next';
interface IFooter {
  closedDates: any
}

const Footer: React.FC<IFooter> = ({closedDates}) => {

  const {t: bookingLang} = useTranslation('booking');
  const closedDate = closedDates.lenght !== 0 ? closedDates[0] : undefined; 

  return (
    <FooterStyled>
      <div className='footer_container'>
        <div className='footer_content'>
          <p className='footer_content_title'>{closedDate?.name ? `${bookingLang('WeAreClosedFor')} ${closedDate?.name}` : bookingLang('WeAreClosed')}</p>
          <table className='footer_store_info'>
            <tbody>
              <tr>
                <td className='label'>{bookingLang('Date')}</td>
                <td className='value'>
                  <p>{dayjs(closedDate?.start_time).format('DD.MM.YYYY') } - {dayjs(closedDate?.end_time).format('DD.MM.YYYY')}</p>
                </td>
              </tr>
              <tr>
                <td className='label'>{bookingLang('Locations')}</td>
                <td className='value'>
                  <p>{closedDate?.location?.map((item: any) => item?.name)?.join(', ')}</p>
                  {/* <p>2972 Westheimer Rd. Santa Ana, Illinois 85486</p> */}
                </td>
              </tr>
              <tr>
                <td colSpan={2}>{closedDate?.description}</td>
              </tr>
            </tbody>
          </table>
        </div>
        {/* <img className='footer_img_overlay' src={bookingFormImage.closed_period} /> */}
      </div>
      <img className='footer_img' src={bookingFormImage.closed_period} />
    </FooterStyled>
  );
};

export default Footer;

const FooterStyled = styled.div`
  flex-direction: column;
  position: relative;
  margin-top: 40px;
  background: var(--color-primary-02);
  @media only screen and (min-width: 1025px) {
    margin-left: 40px;
    margin-right: 40px;
  }
  @media only screen and (max-width: 767.98px) {
    margin-top: 24px;
    padding: 12px 12px 260px;
  }
  .footer_container {
    top: 10px;
    right: 0;
    left: 0;
    bottom: 0;
    border-radius: 6px;
    flex: none;
    display: flex;
    justify-content: space-between;
    position: relative;
    z-index: 11;
  }
  .footer_img {
    width: 280px;
    position: absolute;
    z-index: 9;
    bottom: 0;
    right: 5%;
    opacity: 1;
    @media only screen and (max-width: 767.98px) {
      left: 50%;
      right: auto;
      transform: translate(-50%, 20px)
    }
  }
  .footer_content {
    flex: 1;
    padding: 40px;

    @media only screen and (max-width: 767.98px) {
      padding: 12px;
    }
  }
  .footer_content_title {
    line-height: 140%;
    color: var(--color-primary);
    font-weight: 600;
    font-size: 24px;
    margin-bottom: 14px;
  }
  .footer_store_info {
    td {
      font-weight: 400;
      color: var(--color-primary);
      font-size: 16px;
      line-height: 140%;
      padding-bottom: 8px;
      &.label {
        width: 25%;
      }
      &.value {
        width: 70%;
        font-weight: 600;
        p {
          padding-bottom: 8px;
        }
      }
    }
  }
`;
