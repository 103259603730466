import { createAsyncAction } from 'services/actionConfigs';
import { PREFIX_ACTIONS } from './constants';
import { IHolidayParam } from './types/holiday';
import { createAction } from '@reduxjs/toolkit';

const getSetting = createAsyncAction(PREFIX_ACTIONS + 'GET_SETTING');

const getSettingBookingForm = createAsyncAction<string>(PREFIX_ACTIONS + 'GET_SETTING_BOOKING_FORM');

const getOptionBooking = createAsyncAction(PREFIX_ACTIONS + 'GET_OPTION_BOOKING');

const getListClosedDate = createAsyncAction(PREFIX_ACTIONS + 'GET_LIST_CLOSED_DATE');

const getNumberOfNotification = createAsyncAction(PREFIX_ACTIONS + 'GET_NUMBER_OF_NOTIFICATION');

const getListCancellationReason = createAsyncAction(PREFIX_ACTIONS + 'GET_LIST_CANCELLATION_REASON');

const getListServiceCharge = createAsyncAction(PREFIX_ACTIONS + 'GET_SERVICE_CHARGE');

const getDetailServiceCharge = createAsyncAction<any>(PREFIX_ACTIONS + 'GET_DETAIL_SERVICE_CHARGE');

const getShowServiceCharge = createAsyncAction(PREFIX_ACTIONS + 'GET_SHOW_SERVICE_CHARGE');

const getTopUpHistory = createAsyncAction<any>(PREFIX_ACTIONS + 'GET_TOP_UP_HISTORY');

const getTopUpSMSHistory = createAsyncAction<any>(PREFIX_ACTIONS + 'GET_TOP_UP_SMS_HISTORY');

const getMerchantStripeBalance = createAsyncAction(PREFIX_ACTIONS + 'GET_MERCHANT_STRIPE_BALANCE');

const getHolidayInfoToday = createAsyncAction<IHolidayParam>(PREFIX_ACTIONS + 'GET_HOLIDAY_INFO');

const getTopUpInitData = createAsyncAction<any>(PREFIX_ACTIONS + 'GET_TOP_UP_INIT_DATA');

const setTopUpInitData = createAction<any>(PREFIX_ACTIONS + 'SET_TOP_UP_INIT_DATA');

const updateAutomaticTopUp = createAction<any>(PREFIX_ACTIONS + 'UPDATE_AUTOMATIC_TOPUP');

const	getPermissionPackages = createAsyncAction(PREFIX_ACTIONS + 'GET_PERMISSION_PACKAGE');
const	getListRole = createAsyncAction(PREFIX_ACTIONS + 'GET_LIST_ROLE');

const settingActions = {
	getSetting,
	getOptionBooking,
	getListClosedDate,
	getNumberOfNotification,
	getSettingBookingForm,
	getListCancellationReason,
	getListServiceCharge,
	getDetailServiceCharge,
	getShowServiceCharge,
	getTopUpHistory,
	getTopUpSMSHistory,
	getMerchantStripeBalance,
	getHolidayInfoToday,
	getTopUpInitData,
	setTopUpInitData,
	updateAutomaticTopUp,
	getPermissionPackages,
	getListRole
};

export default settingActions;