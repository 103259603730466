import { Empty, Table } from 'antd';
import analyticSelectors from '../services/selectors';
import { IService } from '../services/types/api';
import { ColumnsType } from 'antd/es/table';
import styled from 'styled-components';
import { formatMoney } from 'utils/unit';
import { useTranslation } from 'react-i18next';

/**
 * Sales table
 * @returns 
 */
const SalesTable = () => {

  const categoryOfSale = analyticSelectors.getListService();

  const dataSource = categoryOfSale;
  const { t: analyticLang } = useTranslation('analytic');
  const { t: invoiceLang } = useTranslation('invoice');

  /**
   * Columns table
   */
  const columns: ColumnsType<any> = [
    {
      title: analyticLang('ServiceCategoryName'),
      dataIndex: 'name',
      key: 'name',
      width: '35%'
    },
    {
      title: analyticLang('Quantity'),
      dataIndex: 'total_deal',
      key: 'total_deal',
      width: '35%',
      render: (text: string) => {
        return (<p> {text}</p>);
      }
    },
    {
      title: analyticLang('Amount'),
      dataIndex: 'total_amount',
      key: 'total_amount',
      width: 120,
      className: 'total_amount',
      render: (text: string, o: IService) => {
        return (
          <p>
            <span>{formatMoney(+text)}</span>
            <span className={`common-growth ${o.percent < 0 ? 'is-reduce' : ''}`}>{o.percent}%</span>
          </p>
        );
      },
    },
  ];

  return (
    <SalesTableStyled>
      <Table
        className={'custom-table'}
        dataSource={dataSource}
        columns={columns}
        scroll={{
          x: 600,
        }}
        pagination={false}
        locale={{
          emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={invoiceLang('NoData')} />,
        }}
      />
    </SalesTableStyled>
  );
};

export default SalesTable;

const SalesTableStyled = styled.div`
  .ant-table-cell.total_amount {
    
    p {
      display: flex;
      flex-wrap: nowrap;
    }
  }
`;
