import fetch from 'services/request';
import {
  IApiAddDateOff,
  IApiTimeScheduleListReq,
  IApiTimeShift,
} from '../types/api';

const BASE_URL = 'api/v1/merchants/:merchant_code/staffs';
const LOCATION_URL = 'api/v1/merchants/:merchant_code/location';

const getTeamMembers = () => {
  return fetch({
    method: 'get',
    url: BASE_URL,
    params: {
      per_page: Number.MAX_SAFE_INTEGER,
    },
  });
};

const getLocations = () => {
  return fetch({
    method: 'get',
    url: LOCATION_URL,
  });
};

const getLocationsDetail = (locationId: string | number) => {
  return fetch({
    method: 'get',
    url: LOCATION_URL + `/${locationId}`,
  });
};

const getListTimeSchedule = (params?: IApiTimeScheduleListReq) => {
  const _params = { ...params };

  if (_params['per_page'] === undefined) {
    _params.per_page = 20;
  }

  return fetch({
    method: 'get',
    url: `${BASE_URL}/time-schedules`,
    params: _params,
  });
};

const createDayOff = (body: IApiAddDateOff) => {
  return fetch({
    method: 'post',
    url: `${BASE_URL}/day-off`,
    body: body as any,
  });
};

const editTimeShift = (employeeId: string | number, body: IApiTimeShift) => {
  return fetch({
    method: 'post',
    url: `${BASE_URL}/${employeeId}/edit-shift`,
    body: body as any,
  });
};

const getListTimeScheduleOnline = (params?: IApiTimeScheduleListReq) => {
  const _params = { ...params };

  if (_params['per_page'] === undefined) {
    _params.per_page = 99;
  }
  return fetch({
    method: 'get',
    url: `api/v1/public/merchants/${_params.merchant_code}/staffs/time-schedules`,
    params: _params,
  });
};

const deleteTimeOff = (offId: number | string, removeRepeat?: boolean) => {
  return fetch({
    method: 'delete',
    url: `${BASE_URL}/day-off/delete/${offId}`,
    params: removeRepeat ? { remove_repeat: 1 } : {},
  });
};

const apiTimeSchedule = {
  getListTimeSchedule,
  getTeamMembers,
  getLocations,
  createDayOff,
  editTimeShift,
  getListTimeScheduleOnline,
  getLocationsDetail,
  deleteTimeOff,
};

export default apiTimeSchedule;
