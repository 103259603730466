import { Spin, Table } from 'antd';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import serviceActions from '../../../services/services/actions';
import serviceSelectors from '../../../services/services/selectors';
const ListProductTable = ({ columns }: any) => {
    const [searchParams, setSearchParams] = useSearchParams();

    // const page: { page: number, per_page: number } = {
    //     page: Number(searchParams.get('page') ?? 1),
    //     per_page: Number(searchParams.get('per_page') ?? 10),
    // };

    const dispatch = useDispatch();

    const data = serviceSelectors.getPaginateListServices();
    const loading = serviceSelectors.loadingStaffPaginateList();
    //   const dataSource: any = data?.data?.map((item: any) => ({
    //     ...item,
    //     status: item.enable ? locationLang('Active') : locationLang('Disable'),
    //     category_title: item.category.name,
    //     category_color: item.category.color,
    //     sale_price: (item.sale_price == 0 ? item.regular_price : item.sale_price) ?? 0,
    //   }));

    const dataSource: any = [
        {
            'image': 'aaaa',
            'id_product': 'ABC123',
            'name': 'Sửa rửa mặt',
            'category': 'SỬA RỬA MẶT',
            'brand': 'Phisolophy',
            'depot': 5,
            'expiry': '23.06.2023',
            'sale_price': 900,
            'status': 'Hiển thị',
            'color': '#8E07F5'
        },
        {
            'image': 'aaaa',
            'id_product': 'DEF456',
            'name': 'Kem dưỡng',
            'category': 'Kem dưỡng',
            'brand': 'Fresh',
            'depot': 100,
            'expiry': '23.05.2023',
            'sale_price': 10,
            'status': 'Đã ẩn',
            'color': '#FF6F00'
        }
    ];

    return (
        <>
            <Spin spinning={loading}>
                <Table
                    className={'custom-table'}
                    dataSource={dataSource}
                    columns={columns}
                    scroll={{
                        x: 900,
                    }}
                    pagination={{
                        defaultCurrent: Number(searchParams.get('page') ?? 1),
                        defaultPageSize: Number(searchParams.get('per_page') ?? 10),
                        total: data?.pagination?.total_record,
                        onChange: (pageChange, sizeChange) => {
                            const params: any = {
                              page: pageChange == 0 ? 1 : pageChange,
                              per_page: sizeChange,
                            };
                            setSearchParams(params);
                
                            dispatch(serviceActions.getListPaginateServices.fetch({
                              page: params.page,
                              per_page: params.per_page,
                            }));
                          },
                        showSizeChanger: false,
                    }}
                />
            </Spin>
        </>
    );
};

export default ListProductTable;
