import React from 'react';

const IconCashSetting = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">
            <g clipPath="url(#clip0_726_122850)">
                <path d="M28.3242 0L0 10.2644V22.1944L6.88455 36.063L35.2065 25.7963V13.8686L28.3242 0Z" fill="#61BC8A" />
                <path d="M28.3242 0L0 10.2644L6.88455 24.133L35.2065 13.8686L28.3242 0Z" fill="#59A57C" />
                <path d="M10.3914 16.8285C9.3881 17.1924 8.21833 16.7679 7.77909 15.8814C7.33984 14.9949 7.79527 13.9825 8.79859 13.6186C9.80192 13.2547 10.9717 13.6792 11.4109 14.5657C11.8502 15.4522 11.3948 16.4646 10.3914 16.8285Z" fill="#7ED4A7" />
                <path d="M26.033 11.1577C25.032 11.5216 23.8599 11.097 23.4207 10.2245C22.9814 9.35205 23.4369 8.32329 24.4402 7.96171C25.4435 7.60012 26.6133 8.02003 27.0548 8.9065C27.4964 9.79297 27.0364 10.8077 26.033 11.1693" fill="#7ED4A7" />
                <path d="M19.6572 16.7776C16.8992 17.776 13.6858 16.6119 12.4767 14.1765C11.2676 11.741 12.5206 8.95562 15.2786 7.95718C18.0366 6.95873 21.25 8.12281 22.4591 10.5583C23.6682 12.9937 22.4129 15.7791 19.6572 16.7776Z" fill="#7ED4A7" />
                <path d="M30.9803 9.6933L30.8994 9.72129C29.05 10.3885 28.2177 12.2501 29.0268 13.8784C29.0727 13.9714 29.1236 14.0617 29.1794 14.149L11.2306 20.6552C11.1936 20.5595 11.1543 20.4662 11.1081 20.3729C10.2989 18.7399 8.15126 17.9655 6.30875 18.635L4.10791 14.2026C5.95735 13.5354 6.7896 11.6739 5.98047 10.0479C5.97526 10.0342 5.96908 10.021 5.96198 10.0082L23.8831 3.51367C23.8883 3.52656 23.8945 3.53903 23.9016 3.55099C24.7084 5.18396 26.8584 5.95846 28.7009 5.29128L28.7795 5.26095L30.9803 9.6933Z" fill="#7ED4A7" />
                <path d="M15.2786 7.95913C12.5206 8.95758 11.2676 11.743 12.4767 14.1784C13.6858 16.6139 16.8992 17.778 19.6572 16.7795C22.4152 15.7811 23.6682 12.9957 22.4591 10.5602C21.25 8.12475 18.0343 6.96069 15.2786 7.95913Z" fill="#59A57C" />
                <path d="M8.7986 13.6183C7.79528 13.9822 7.33754 14.9946 7.7791 15.8811C8.22065 16.7676 9.39736 17.1921 10.3914 16.8282C11.3855 16.4643 11.8502 15.4495 11.4109 14.5654C10.9717 13.6813 9.79268 13.2544 8.7986 13.6183Z" fill="#59A57C" />
                <path d="M24.4402 7.95091C23.4369 8.31249 22.9814 9.32727 23.4207 10.2137C23.8599 11.1002 25.039 11.5224 26.033 11.1469C27.0271 10.7713 27.4941 9.7705 27.0548 8.88403C26.6156 7.99756 25.4366 7.57533 24.4402 7.93925" fill="#59A57C" />
                <path d="M24.644 17.701L17.741 20.2018L10.8564 6.33319L17.7595 3.83008L24.644 17.701Z" fill="#EDDFCE" />
                <path d="M6.88455 24.1323V36.0623L0 22.1937V10.2637L6.88455 24.1323Z" fill="#7ED4A7" />
                <path d="M24.6438 29.6242V17.7012L17.7407 20.202V32.1273L24.6438 29.6242Z" fill="#DBCBB9" />
                <path d="M28.3242 0L0 10.2644V22.1944L6.88455 36.063L35.2065 25.7963V13.8686L28.3242 0Z" fill="#61BC8A" />
                <path d="M28.3242 0L0 10.2644L6.88455 24.133L35.2065 13.8686L28.3242 0Z" fill="#59A57C" />
                <path d="M10.3914 16.8285C9.3881 17.1924 8.21833 16.7679 7.77909 15.8814C7.33984 14.9949 7.79527 13.9825 8.79859 13.6186C9.80192 13.2547 10.9717 13.6792 11.4109 14.5657C11.8502 15.4522 11.3948 16.4646 10.3914 16.8285Z" fill="#7ED4A7" />
                <path d="M26.033 11.1577C25.032 11.5216 23.8599 11.097 23.4207 10.2245C22.9814 9.35205 23.4369 8.32329 24.4402 7.96171C25.4435 7.60012 26.6133 8.02003 27.0548 8.9065C27.4964 9.79297 27.0364 10.8077 26.033 11.1693" fill="#7ED4A7" />
                <path d="M19.6572 16.7776C16.8992 17.776 13.6858 16.6119 12.4767 14.1765C11.2676 11.741 12.5206 8.95562 15.2786 7.95718C18.0366 6.95873 21.25 8.12281 22.4591 10.5583C23.6682 12.9937 22.4129 15.7791 19.6572 16.7776Z" fill="#7ED4A7" />
                <path d="M30.9803 9.6933L30.8994 9.72129C29.05 10.3885 28.2177 12.2501 29.0268 13.8784C29.0727 13.9714 29.1236 14.0617 29.1794 14.149L11.2306 20.6552C11.1936 20.5595 11.1543 20.4662 11.1081 20.3729C10.2989 18.7399 8.15126 17.9655 6.30875 18.635L4.10791 14.2026C5.95735 13.5354 6.7896 11.6739 5.98047 10.0479C5.97526 10.0342 5.96908 10.021 5.96198 10.0082L23.8831 3.51367C23.8883 3.52656 23.8945 3.53903 23.9016 3.55099C24.7084 5.18396 26.8584 5.95846 28.7009 5.29128L28.7795 5.26095L30.9803 9.6933Z" fill="#7ED4A7" />
                <path d="M15.2786 7.95913C12.5206 8.95758 11.2676 11.743 12.4767 14.1784C13.6858 16.6139 16.8992 17.778 19.6572 16.7795C22.4152 15.7811 23.6682 12.9957 22.4591 10.5602C21.25 8.12475 18.0343 6.96069 15.2786 7.95913Z" fill="#59A57C" />
                <path d="M8.7986 13.6183C7.79528 13.9822 7.33754 14.9946 7.7791 15.8811C8.22065 16.7676 9.39736 17.1921 10.3914 16.8282C11.3855 16.4643 11.8502 15.4495 11.4109 14.5654C10.9717 13.6813 9.79268 13.2544 8.7986 13.6183Z" fill="#59A57C" />
                <path d="M24.4402 7.95091C23.4369 8.31249 22.9814 9.32727 23.4207 10.2137C23.8599 11.1002 25.039 11.5224 26.033 11.1469C27.0271 10.7713 27.4941 9.7705 27.0548 8.88403C26.6156 7.99756 25.4366 7.57533 24.4402 7.93925" fill="#59A57C" />
                <path d="M24.644 17.701L17.741 20.2018L10.8564 6.33319L17.7595 3.83008L24.644 17.701Z" fill="#EDDFCE" />
                <path d="M6.88455 24.1323V36.0623L0 22.1937V10.2637L6.88455 24.1323Z" fill="#7ED4A7" />
                <path d="M24.6438 29.6242V17.7012L17.7407 20.202V32.1273L24.6438 29.6242Z" fill="#DBCBB9" />
                <path d="M41.1154 16.418L12.7935 26.6824V34.131L19.678 47.9996L47.9999 37.7329V30.2866L41.1154 16.418Z" fill="#61BC8A" />
                <path d="M41.1154 16.418L12.7935 26.6824L19.678 40.5509L47.9999 30.2866L41.1154 16.418Z" fill="#59A57C" />
                <path d="M23.1849 33.2465C22.1816 33.6104 21.0118 33.1858 20.5703 32.2994C20.1287 31.4129 20.5888 30.4005 21.5898 30.0366C22.5908 29.6726 23.7629 30.0972 24.2044 30.9837C24.646 31.8701 24.1882 32.8826 23.1849 33.2465Z" fill="#7ED4A7" />
                <path d="M38.827 27.5756C37.8236 27.9395 36.6539 27.5173 36.2146 26.6425C35.7754 25.7677 36.2308 24.7413 37.2341 24.3797C38.2375 24.0181 39.4072 24.438 39.8465 25.3245C40.2857 26.2109 39.8303 27.2257 38.827 27.5873" fill="#7ED4A7" />
                <path d="M32.4483 33.1975C29.6927 34.1959 26.477 33.0318 25.2679 30.5964C24.0588 28.1609 25.3141 25.3755 28.0698 24.3771C30.8255 23.3787 34.0412 24.5427 35.2502 26.9782C36.4593 29.4136 35.2063 32.199 32.4483 33.1975Z" fill="#7ED4A7" />
                <path d="M43.7738 26.1089L43.6929 26.1369C41.8434 26.8041 41.0112 28.6657 41.8203 30.294C41.8665 30.3873 41.9174 30.4783 41.9706 30.5646L24.024 37.0732C23.987 36.9775 23.9454 36.8842 23.8992 36.7909C23.0924 35.1579 20.9447 34.3834 19.1022 35.053L16.9014 30.6206C18.7508 29.9534 19.5831 28.0918 18.7739 26.4659C18.7679 26.4522 18.7609 26.4389 18.7531 26.4262L36.6765 19.9316C36.6765 19.9456 36.6765 19.9573 36.6927 19.969C37.5019 21.6019 39.6495 22.3764 41.492 21.7092L41.5729 21.6789L43.7738 26.1089Z" fill="#7ED4A7" />
                <path d="M28.0698 24.3771C25.3141 25.3755 24.0588 28.1609 25.2679 30.5964C26.477 33.0318 29.6927 34.1959 32.4483 33.1975C35.204 32.199 36.4593 29.4136 35.2502 26.9782C34.0412 24.5427 30.8278 23.3787 28.0698 24.3771Z" fill="#59A57C" />
                <path d="M21.5898 30.0382C20.5887 30.4021 20.131 31.4145 20.5702 32.301C21.0095 33.1875 22.1885 33.6121 23.1849 33.2481C24.1813 32.8842 24.6436 31.8695 24.2044 30.9853C23.7652 30.1012 22.5861 29.6743 21.5898 30.0382Z" fill="#59A57C" />
                <path d="M37.2341 24.3689C36.2308 24.7305 35.7754 25.7452 36.2146 26.6317C36.6539 27.5182 37.8329 27.9404 38.827 27.5648C39.821 27.1893 40.288 26.1885 39.8465 25.302C39.4049 24.4155 38.2282 23.9933 37.2341 24.3572" fill="#59A57C" />
                <path d="M37.4375 34.1166L30.5345 36.6197L23.6499 22.7512L30.553 20.248L37.4375 34.1166Z" fill="#EDDFCE" />
                <path d="M19.6757 40.5522V48.0009L12.7935 34.1323V26.6836L19.6757 40.5522Z" fill="#7ED4A7" />
                <path d="M37.4372 41.5612V34.1172L30.5342 36.6203V44.0643L37.4372 41.5612Z" fill="#DBCBB9" />
            </g>
            <defs>
                <clipPath id="clip0_726_122850">
                    <rect width="48" height="48" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};

export default IconCashSetting;