import { DatepickerEvent} from '@meinefinsternis/react-horizontal-date-picker';
import HorizontalDate from 'components/common/HorizontalDate';
import moment, { Moment } from 'moment';
import { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import TimeSchedulePicker from './TimeSchedulePicker';
import bookingSelectors from 'features/bookings/services/selectors';
import {  range, uniqBy } from 'lodash';
import { DATE_FORMAT_RESPONSE, HOUR_MINUTE_FORMAT } from 'features/bookings/services/constants';
import {  IPeriodTime, arrayTimeNotCollapse, daysOfWeek, getOpeningLocation } from 'utils/unit';
import timeScheduleSelectors from 'features/timeSchedule/services/selectors';
import { useDispatch } from 'react-redux';
import bookingActions from 'features/bookings/services/actions';
import { NUMBER_DAY_HORIZONTAL_DATE } from '..';
import { Empty, Spin } from 'antd';
import { TIME_START_FORMAT_RESPONSE } from 'features/bookings/services/constants';
import getSingleDateFormat from 'utils/moment/getSingleDateFormat';
import { useTranslation } from 'react-i18next';



const BookingDateForm = () => {

  const activeLocation = bookingSelectors.bookingOnline.getActiveLocation();
  const timeSchedule = timeScheduleSelectors.getLstTimeScheduleOnline();
  const loadingSchedule = timeScheduleSelectors.getLoadingLstTimeScheduleOnline();
  const teamMemberSelected = bookingSelectors.bookingOnline.getTeamMemberSelected();

  const [dateSelected, setDateSelected] = useState<Date>(new Date());
  const dispatch = useDispatch();

  useEffect(() => {
    setDateSelected(new Date());
  }, [teamMemberSelected]);

  const date_closed: {start: Moment, end: Moment}[] | undefined = useMemo(() => {
    return activeLocation?.date_closed.map((o) => ({
      start: moment(o.start_date, 'YY-MM-DD'),
      end: moment(o.end_date, 'YY-MM-DD'),
    }));
  }, [activeLocation?.date_closed]);

  const isDisableDate = (d: Moment) => {
    const isDateClosed = date_closed?.some(
      (o: any) => {
        return d.format('YY-MM-DD') == o.start.format('YY-MM-DD') ||
          (d.isSameOrAfter(o.start, 'date') && d.isSameOrBefore(o.end, 'date'));
      });
    
    if (isDateClosed) return true;
    
    const isOpening = !activeLocation?.time_opening.some(
      (o) => o?.weekday === d?.format('dddd')?.toLowerCase()
    );

    if(isOpening) return true;

    return false;

    // if(timeSchedule.length <= 0)  return false;

    // const isTeamMemberSchedule = timeSchedule[0]?.timing.some(time => moment(time.date).isSame(d, 'date') && time.shift_type !== ETimeSchedule.ON);

    // return isTeamMemberSchedule;
  };

  const handleChange = (date: DatepickerEvent) => {
    const [startValue] = date;
    // format(new Date(startValue), 'DDD');
    setDateSelected(startValue ?? new Date());

    const timing = getOpeningLocation(moment(startValue?.toString()), activeLocation);
      
    dispatch(bookingActions.setBookingOnlineFormValues({
      bookingDate: moment(startValue?.toString()).format(),
      bookingTime: moment(timing?.time_start, HOUR_MINUTE_FORMAT).format(),
    }));

  };

  // const userOnline = userSelectors.getStaffPublic();
  // const scheduleOnline = timeScheduleSelectors.getLstTimeScheduleOnline();


  const disabledDates: Date[] = useMemo(() => {
    const startDate = moment().subtract(1, 'day').clone();
    const disableDate: Date[] = [];

    let availableDate = dateSelected;

    range(0, NUMBER_DAY_HORIZONTAL_DATE).forEach(() => {      
      const date = startDate.add(1, 'day');
      if(isDisableDate(date)) {
      
        disableDate.push(new Date(date.format(DATE_FORMAT_RESPONSE)));
        
        if(availableDate.toDateString() === new Date(date.format(DATE_FORMAT_RESPONSE)).toDateString()) {
          const addDate = new Date(availableDate);
          addDate.setDate(addDate.getDate() + 1);
          availableDate = addDate;
        }
      }
    });
      setDateSelected(availableDate);

      if(availableDate.toDateString() === new Date().toDateString()) return disableDate;

      const timing = getOpeningLocation(moment(availableDate.toISOString()), activeLocation);
      
      dispatch(bookingActions.setBookingOnlineFormValues({
        bookingDate: moment(availableDate?.toString()).format(),
        bookingTime: moment(timing?.time_start, HOUR_MINUTE_FORMAT).format(),
      }));

    return disableDate;
    
  }, [date_closed, timeSchedule]);

  const timeScheduleOpt = useMemo(() => {

    if(!dateSelected) return [];
    
    const timeScheduleOpts: IPeriodTime[] = [];
    if(teamMemberSelected?.[0]?.id === '') {
      const timming = activeLocation?.time_opening.find(time => time.weekday === daysOfWeek.find(day => day.index === moment().day())?.value);
      
      timeScheduleOpts.push({
        time_start: moment(timming?.time_start, HOUR_MINUTE_FORMAT).format(HOUR_MINUTE_FORMAT) ?? '00:00',
        time_end: moment(timming?.time_end, HOUR_MINUTE_FORMAT).format(HOUR_MINUTE_FORMAT) ?? '00:00'
      });

      return timeScheduleOpts;
    } 

    
    if(!timeSchedule[0]?.timing) return [];
    
    const teamMemberSchedule = timeSchedule[0]?.timing.find(time => moment(time.date).isSame(moment(dateSelected.toISOString()), 'date'));
    if(!teamMemberSchedule?.timing_schedules) return [];
    const timingSchedule = teamMemberSchedule.timing_schedules.filter(schedule => schedule.is_work === true);
    const timeBusies: IPeriodTime[] = arrayTimeNotCollapse((teamMemberSchedule?.timing_busies ?? []));

    if(timeBusies.length > 0) {
      timeBusies?.forEach(timeBusy => {
         timingSchedule.forEach(sche => {
            const timeStartBusy = moment(timeBusy.time_start, TIME_START_FORMAT_RESPONSE);
            const timeEndBusy = moment(timeBusy.time_end, TIME_START_FORMAT_RESPONSE);
            const stringDate = timeStartBusy.format(DATE_FORMAT_RESPONSE);
            const timeStartSchedule = moment(stringDate + ' ' + sche.time_start, TIME_START_FORMAT_RESPONSE);
            const timeEndSchedule = moment(stringDate + ' ' + sche.time_end, TIME_START_FORMAT_RESPONSE);
  
            if(!timeStartBusy.isBetween(timeStartSchedule, timeEndSchedule) && !timeEndBusy.isBetween(timeStartSchedule, timeEndSchedule)) {
              return timeScheduleOpts.push({
                time_start: timeStartSchedule.format(HOUR_MINUTE_FORMAT),
                time_end: timeEndSchedule.format(HOUR_MINUTE_FORMAT)
              });
            }
            
            if(timeStartBusy.isBetween(timeStartSchedule, timeEndSchedule)) {
               timeScheduleOpts.push({
                time_start: timeStartSchedule.format(HOUR_MINUTE_FORMAT),
                time_end: timeStartBusy.format(HOUR_MINUTE_FORMAT)
              });
            }
  
            if(timeEndBusy.isBetween(timeStartSchedule, timeEndSchedule)) {
              timeScheduleOpts.push({
                time_start: timeEndBusy.format(HOUR_MINUTE_FORMAT),
                time_end: timeEndSchedule.format(HOUR_MINUTE_FORMAT)
              });
            }
  
          });
      });
    } else {
      timingSchedule.forEach(sche => {
        timeScheduleOpts.push(sche);
      });
    }

     
     const timeScheduleOptsUnique = uniqBy(timeScheduleOpts, function (e) {
       return e.time_start;
      });
    
    const timeScheduleOptsUniqueResult: IPeriodTime[] = arrayTimeNotCollapse(timeScheduleOptsUnique ?? [], 'lower');

    return timeScheduleOptsUniqueResult;
  }, [dateSelected, activeLocation, timeSchedule]);
  const {t: bookingLang} = useTranslation('booking');
  return (
    <BookingDateFormStyled>
      <Spin spinning={loadingSchedule}>
        <HorizontalDate 
          endDate={new Date(moment().add(NUMBER_DAY_HORIZONTAL_DATE, 'day').format(DATE_FORMAT_RESPONSE))}
          disabledDates={disabledDates} 
          value={dateSelected ?? new Date()} 
          handleChange={handleChange} />
        {timeScheduleOpt.length > 0 ? <TimeSchedulePicker timeSchedule={timeScheduleOpt} /> : <Empty description={`${teamMemberSelected?.[0]?.full_name} ${bookingLang('HasNoScheduleFor')} ${getSingleDateFormat(moment(dateSelected?.toISOString()))}`} />}
      </Spin>
    </BookingDateFormStyled>
  );
};

export default BookingDateForm;

const BookingDateFormStyled = styled.div`
 
`;