import IconClose from 'assets/svg/IconClose';
import { CHOOSE_CATEGORY_REWARD } from 'features/loyaltyProgram/services/constants';
import { IRewardItemResData } from 'features/loyaltyProgram/services/types/reward';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import formatNumber from 'utils/formatNumber';
type Props = {
  data: IRewardItemResData;
  active?: boolean;
  onClick?: () => void;
  disabled?: boolean;
}

/**
 * Reward item
 * @param  data, active, onClick ,disabled
 * @returns 
 */
const RewardItem: React.FC<Props> = ({ data, active, onClick = () => undefined, disabled = false }) => {
  if (!data) return null;
  const { category, amount_reward, value_reward, name, free_service = [], points_to_earn_reward } = data;
  const{t: bookingLang} = useTranslation('booking');
  const Content = () => {
    if (category === CHOOSE_CATEGORY_REWARD.FREE_SERVICE) {
      const services: string[] = [];
      free_service.forEach(o => {
        if (o.extend_services.length > 0) {
          o.extend_services.forEach(a => {
            const id = o.id + '_' + a;
            services.push(id);
          });
        } else {
          services.push(o?.id?.toString());
        }
      });

      const getServiceVariantTitle = () => {
        return free_service[0]?.name_extend_services_free?.length === 1 ? `( ${free_service[0]?.name_extend_services_free[0].name_variant} )` : '';
      };

      return (
        <>
          <p>{bookingLang('Free')}</p>
          {services?.length === 1 ?
            <p>{free_service[0].name} {getServiceVariantTitle()} - {points_to_earn_reward} {bookingLang('points')}</p>
            :
            <p>{services?.length} services - {points_to_earn_reward} {bookingLang('points')}</p>
          }
        </>
      );
    }
    if (value_reward) {
      return (
        <>
          <p>{bookingLang('Discount')} {value_reward}%</p>
          <p>{name} - {points_to_earn_reward} {bookingLang('points')}</p>
        </>
      );
    }

    return (
      <>
        <p>{bookingLang('Discount')} ${formatNumber(amount_reward)}</p>
        <p>{name} - {points_to_earn_reward} {bookingLang('points')}</p>
      </>
    );
  };

  return (
    <RewardItemStyled type='button' onClick={!active ? onClick : undefined} disabled={!active && disabled} className={active ? 'active' : ''}>
      <IconReward />
      <Content />
      {!!active && <button type='button' className="close-icon" onClick={onClick}><IconClose /></button>}
    </RewardItemStyled>
  );
};

export default RewardItem;

const RewardItemStyled = styled.button<{ disabled: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 12px;
  gap: 8px;
  width: 136px;
  background: #FFFCEE;
  border-radius: 8px;
  border:1px solid #FFFCEE;
  position:relative;
  opacity: ${props => props.disabled ? 0.5 : 1};
  &:hover {
    border:1px solid #D48806;
  }

  .close-icon {
    position: absolute;
    top: -10px;
    right: -10px;
    background: #F0F2F6;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

  }
  &.active {
    border:1px solid #D48806;
    background:#F2E8D0;
    &:hover {
      cursor: inherit;
    }
  }

  p {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 140%;
    text-align: center;
    color: #D48806;
  }
`;

const IconReward = () => <div className='icon'><svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M19.7858 5.71261L15.5001 9.99833L10.5001 2.85547L5.50007 9.99833L1.21436 5.71261V14.9983C1.21436 15.5666 1.44012 16.1117 1.84198 16.5136C2.24385 16.9154 2.78889 17.1412 3.35721 17.1412H17.6429C18.2113 17.1412 18.7563 16.9154 19.1582 16.5136C19.56 16.1117 19.7858 15.5666 19.7858 14.9983V5.71261Z" fill="#FAAD14" />
</svg></div>;
