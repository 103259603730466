import moment from 'moment';
import { MutableRefObject, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { translate } from 'utils/moment/getSingleDateFormat';


interface IDateHorizontalPickerItem {
  date: string,
  isSelected: boolean,
  isCurrent: boolean,
  ref?: MutableRefObject<any>,
  onSelect: () => void
}

/**
 * Render filter date horizontal item
 * @props date string
 * @props isSelected boolean
 * @returns 
 */
const DateHorizontalPickerItem = ({date, isSelected, isCurrent, onSelect}: IDateHorizontalPickerItem) => {

    const day = moment(date);
    const dateRef = useRef(null);


    useEffect(() => {
      //@ts-ignore
      dateRef?.current?.scrollIntoView({
        block: 'start',
        inline: 'start'
      });
    }, []);

    // console.log(moment(date).isSame(moment(), 'date'), dateRef);
    

    return (
    <DateHorizontalPickerItemStyled ref={moment(date).isSame(moment(), 'date') ? dateRef : undefined} isCurrent={isCurrent} isSelected={isSelected} onClick={onSelect}>
      <p className='date'>{day.format('DD')}</p>
      <p className='date-week'>{translate('date', day.format('dd')) }</p>
    </DateHorizontalPickerItemStyled>
  );
};

export default DateHorizontalPickerItem;

const DateHorizontalPickerItemStyled = styled.div<{isCurrent: boolean, isSelected: boolean}>`
  display: flex;
  flex-direction: column;
  padding: 16px 8px;
  border-radius: 12px;
  gap: 9px;
  cursor: pointer;
  min-width: 52px;
  background-color: ${props => props.isSelected ? '#D1EBEE' : 'transparent' };

  .date {
    font-size: 16px;
    font-weight: 600;
    color: ${props => props.isCurrent ? '#1B9AAA' : '#000000' };
  }

  .date-week {
    font-size: 12px;
    font-weight: 400;
    color: ${props => props.isCurrent ? '#1B9AAA' : '#000000' };
  }
`;