import { Empty, Spin, message } from 'antd';
import { AxiosResponse } from 'axios';
import settingActions from 'features/settings/services/actions';
import settingApis from 'features/settings/services/apis';
import settingSelectors from 'features/settings/services/selectors';
import { get } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import styles from '../../pages/index.module.scss';
import ServiceChargesItem from './ServiceChargesItem';
import FormModal from './FormModal';
import serviceChargeSelect from './../../services/selectors';
import dayjs, { Dayjs } from 'dayjs';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
export interface IServiceChageItem {
  id: number;
  name: string;
  value: number;
  start_date: Date;
  end_date: Date;
  day_of_week: any;
  description: string;
  type: string;
  status: number;
  is_each_service: number;
  location: any,
}

// interface IDaySurcharge {
//     id: number,
//     name: string
// }

interface ILocation {
  id: number,
  name: string
}


export interface IServiceChageRes {
  id: number;
  name: string;
}

export interface IServiceChargeReasonForm {
  name: string;
  value: number;
  start_date: Date;
  end_date: Date;
  day_of_week: any;
  description: string;
  type: string;
  status: number;
  is_each_service: number;
  location: ILocation[];
}

export interface IServiceChargeReason {
  name: string;
  value: number;
  start_date: Dayjs;
  end_date: Dayjs;
  id: number;
  day_of_week: any,
  description: string,
  type: string,
  status: number,
  is_each_service: any,
  location: ILocation[]
}



const ServiceChargesTab = () => {

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(settingActions.getSetting.fetch());
    dispatch(settingActions.getOptionBooking.fetch());
    dispatch(settingActions.getListClosedDate.fetch());
  }, []);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [idField, setIdField] = useState<number>(0);
  const [isDisableSubmit, setIsDisableSubmit] = useState<boolean>(false);
  const [formData, setFormData] = useState<IServiceChargeReason>();
  const selectorServiceCharge = serviceChargeSelect.getListServiceCharge();


  useEffect(() => {
    dispatch(settingActions.getListServiceCharge.fetch());
    dispatch(settingActions.getListCancellationReason.fetch());
  }, []);


  const loadingServiceChageReason = settingSelectors.loadListServiceCharge();

  const showModal = () => {
    setIsEdit(false);
    setIsModalOpen(true);
    setIsDisableSubmit(false);
    setFormData(undefined);
  };



  const handleCancel = () => {
    setIsModalOpen(false);
    setFormData(undefined);
  };


  const getPayload = (values: any) => {
    const id = values?.id;
    const name = values?.name;
    const value = values?.value;
    const start_date = values?.start_date?.format('YYYY-MM-DD HH:mm:ss');
    const end_date = values?.end_date?.format('YYYY-MM-DD HH:mm:ss');
    const description = values?.description;
    const type = 'percent';
    const status = values?.status;
    const is_each_service = values?.is_each_service;
    const location_ids = values?.location;
    const surcharge_day_ids = values?.day_of_week;
    const is_repeat_weekly = values?.day_of_week?.length == null ? false : true;

    return {
      name,
      value,
      start_date,
      end_date,
      id,
      description,
      type,
      status,
      is_each_service,
      location_ids,
      surcharge_day_ids,
      is_repeat_weekly
    };
  };

  const handleSubmitEdit = async (values: IServiceChargeReasonForm) => {
    setIsDisableSubmit(true);
    const payload = getPayload(values);

    try {
      const rest: AxiosResponse<{ message: string }> = await settingApis.updateServiceChage(idField, payload);

      const msg = get(rest, 'data.message', '');
      if (msg) {
        message.success(msg);
        dispatch(settingActions.getListServiceCharge.fetch());
        handleCancel();
        return true;
      }
      return false;
    } catch (error: any) {
      const response = get<Record<string, string[]>>(
        error,
        'response.data.errors',
        {}
      );

      const errors = Object.values(response);
      errors.map((value) => {
        message.error(get(value, [0], ''));
      });

      return false;
    }
  };

  const handleSubmit = async (values: IServiceChargeReasonForm) => {
    setIsDisableSubmit(true);
    const payload = getPayload(values);

    try {
      const rest: AxiosResponse<{ message: string }> = await settingApis.createServiceCharge(payload);
      const msg = get(rest, 'data.message', '');
      if (msg) {
        message.success(msg);
        dispatch(settingActions.getListServiceCharge.fetch());
        handleCancel();
        setIsDisableSubmit(false);

        return true;

      }
      setIsDisableSubmit(false);
      return false;
    } catch (error: any) {
      const response = get<Record<string, string[]>>(
        error,
        'response.data.errors',
        {}
      );

      const errors = Object.values(response);
      errors.map((value) => {
        message.error(get(value, [0], ''));
      });
      setIsDisableSubmit(false);
      return false;
    }
  };

  const handleEdit = (item: IServiceChageItem) => {
    showModal();
    setIsEdit(true);
    setIdField(item.id);

    dispatch(settingActions.getDetailServiceCharge.fetch(item.id.toString()));
    setFormData({
      name: item.name,
      value: item.value,
      start_date: dayjs(item.start_date),
      end_date: dayjs(item.end_date),
      id: item.id,
      day_of_week: item.day_of_week,
      description: item.description,
      type: item.type,
      status: item.status,
      is_each_service: item.is_each_service,
      location: item.location,
    });
  };

  const { t: settingLang } = useTranslation('setting');

  return (
    <ServiceChargesTabStyled>
      <Spin spinning={loadingServiceChageReason}>
        <div className='cards-wrapper'>
          <div className='cards-col'>
            <div className='common-card'>
              <div className='card-title'>
                <TitleHolidayStyled>
                  <div>
                    <div>
                      <div className='d-flex' style={{ gap: '10px' }}>
                        <p className='form-label is-biggest'>{settingLang('PublicHolidayCharge')}</p>
                      </div>
                      <p className='form-note'>
                        {settingLang('ManageAnyExtraChargesThatApplyToServicesAndItemsSoldAtCheckout')}
                      </p>
                    </div>
                    <button
                      className='common-btn'
                      style={{ marginLeft: 'auto' }}
                      onClick={showModal}
                    >
                      {settingLang('AddNew')}
                    </button>
                  </div>
                </TitleHolidayStyled>
              </div>
              <div className='card-body'>
                <ul className={styles.periods_list}>
                  {
                    selectorServiceCharge?.length > 0 ?
                      selectorServiceCharge.map((item: any, key: number) => (
                        <li key={key}>
                          <ServiceChargesItem {...item}
                            handleEdit={() => { handleEdit(item); }} />
                        </li>
                      )) : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={settingLang('NoServiceCharges')} />
                  }
                </ul>
              </div>
            </div>
          </div>
        </div>
        {isModalOpen && <FormModal
          isEdit={isEdit}
          isDisableSubmit={isDisableSubmit}
          formData={formData}
          isModalOpen={isModalOpen}
          handleCancel={handleCancel}
          handleSubmit={isEdit ? handleSubmitEdit : handleSubmit}
        />}
      </Spin>
    </ServiceChargesTabStyled>
  );
};

export default ServiceChargesTab;

const ServiceChargesTabStyled = styled.div`
margin-top: 100px;
@media only screen and (max-width: 767.98px) {
  margin-top: 0;
}
`;

const TitleHolidayStyled = styled.div`
gap: 10px;
display: flex;
@media only screen and (max-width: 767.98px) {
    display: grid;
    .common-btn {
        width: 100%;
    }
}
`;