import { createAsyncAction } from 'services/actionConfigs';
import { PREFIX_ACTIONS } from './constants';
import { createAction } from '@reduxjs/toolkit';
import { IState } from './types/reducer';

const getMerchantPermission = createAsyncAction(PREFIX_ACTIONS + 'GET_MERCHANT_PERMISSION');
const setMerchantPermission = createAction<IState['merchant_permission']>(PREFIX_ACTIONS + 'SET_MERCHANT_PERMISSION');



const packagePermissionActions = {
  getMerchantPermission,
  setMerchantPermission
};

export default packagePermissionActions;
