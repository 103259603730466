import { Modal } from 'antd';
import React from 'react';
import CategoryForm, { ICategoryFormProps } from './Form';
import { useTranslation } from 'react-i18next';
interface ICategoryModalProps extends ICategoryFormProps {
  visible?: boolean;
}
const CategoryModal: React.FC<ICategoryModalProps> = ({ visible, ...rest }) => {
  const {t: serviceLang} = useTranslation('service');
  return (
    <Modal
      maskClosable={false}
      open={visible}
      onCancel={rest.onClose}
      okButtonProps={{ style: { display: 'none' } }}
      width={574}
      footer={null}
      forceRender
      centered
    >
      <h2 className='modal-title'>
        {rest.isEdit ? serviceLang('EditCategory') : serviceLang('AddNewCategory')}
      </h2>
      <CategoryForm {...rest} />
    </Modal>
  );
};

export default CategoryModal;
