import { Checkbox, ConfigProvider, DatePicker, Form, Popover } from 'antd';
import TimePickerAutoChange from 'components/common/TimePickerAutoChange';
import dayjs, { Dayjs } from 'dayjs';
import { IconRemove } from 'features/checkout/components/FeeButton';
import { ButtonStyled } from 'features/workingHour/widgets/WorkingTime/Modal';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { timeFormat } from 'utils/unit';
import { TimingShiftScheduleType } from '../types/timeSchedule';
import moment from 'moment';
import { get } from 'lodash';
import 'dayjs/locale/vi';
import viVN from 'antd/lib/locale/vi_VN';
import enEL from 'antd/lib/locale/en_US';
interface EditShiftFormType {
  timeShift?: TimingShiftScheduleType[];
  handleRemoveTimeOff?: (off_id: string | number, isRepeat?: boolean) => Promise<boolean>;
}

const EditShiftForm = ({
  timeShift,
  handleRemoveTimeOff,
}: EditShiftFormType) => {
  const { t: formLang } = useTranslation('form');

  const form = Form.useFormInstance();

  const getDisabledHours = () => {
    const hours = [];
    const hoursDisable: any = [];

    if (timeShift?.length) {
      for (let i = 0; i < timeShift?.length; i++) {
        const time_start = timeShift[i]?.time_start;
        const time_end = timeShift[i]?.time_end;
        const getTimeStart = moment(time_start, 'HH:mm:ss').get('hours');
        const getTimeEnd = moment(time_end, 'HH:mm:ss').get('hours');

        for (let i = getTimeStart; i <= getTimeEnd; i++) {
          hours.push(i);
        }
      }

      for (let i = 0; i < 24; i++) {
        if (!hours?.includes(i)) {
          hoursDisable?.push(i);
        }
      }
    }
    return hoursDisable;
  };

  const getDisabledMinutes = (selectedHour: number) => {
    const minutes = [];

    if (timeShift?.length) {
      for (let i = 0; i < timeShift?.length; i++) {
        const time_start = timeShift[i]?.time_start;
        const time_end = timeShift[i]?.time_end;
        const getTimeStart = moment(time_start, 'HH:mm:ss').get('hours');
        const getTimeEnd = moment(time_end, 'HH:mm:ss').get('hours');

        if (selectedHour === getTimeStart) {
          for (
            let i = 0;
            i < moment(time_start, 'HH:mm:ss').get('minutes');
            i++
          ) {
            minutes.push(i);
          }
        }

        if (selectedHour === getTimeEnd) {
          for (
            let i = moment(time_end, 'HH:mm:ss').get('minutes') + 1;
            i < 60;
            i++
          ) {
            minutes.push(i);
          }
        }
      }
    }
    return minutes;
  };

  const checkDateTimeFallCloseHour = (timeIndex: number) => {
    if (timeShift?.length) {
      const timeEndCurr = get(form.getFieldValue('times'), [
        timeIndex,
        'end',
      ]);

      const timeStartCurr = get(form.getFieldValue('times'), [
        timeIndex,
        'start',
      ]);

      const findValid = timeShift?.find((item) => {
        const time_start = item?.time_start;
        const time_end = item?.time_end;
        return dayjs(time_start, 'HH:mm:ss').isSameOrBefore(dayjs(timeStartCurr, 'HH:mm:ss')) && dayjs(timeEndCurr, 'HH:mm:ss').isSameOrBefore(dayjs(time_end, 'HH:mm:ss'));
      });

      if (!findValid && timeEndCurr && timeStartCurr) {
        return true;
      }
    }
    return false;
  };

  const validateStartEndTimeDuplicate = (
    value: Dayjs,
    index: number,
  ) => {
    const timeValue = form.getFieldValue('times');

    if (!timeValue[index]?.is_work) return false;

    const findTimeExist = timeValue?.find((item: any, id: number) => {
      const caseTimeBetween = dayjs(value, 'HH:mm').isBetween(
        dayjs(item?.start, 'HH:mm'),
        dayjs(item?.end, 'HH:mm')
      );

      const caseStartEndBetween =
        dayjs(timeValue[index]?.start, 'HH:mm')?.isBefore(
          dayjs(item?.start, 'HH:mm')
        ) &&
        dayjs(item?.end, 'HH:mm')?.isBefore(
          dayjs(timeValue[index]?.end, 'HH:mm')
        );

      return id !== index && (caseTimeBetween || caseStartEndBetween);
    });

    if (findTimeExist) {
      return true;
    }
    return false;
  };

  const handleUpdateTimeScheduleCheck = () => {
    const updateTimeScheduleCheck = form.getFieldValue('updateTimeScheduleCheck');
    form.setFieldValue('updateTimeScheduleCheck', !updateTimeScheduleCheck);
  };

  const lang = 'vi';
  dayjs.locale(lang === 'vi' ? 'vi' : 'en');
  const locale = lang === 'vi' ? viVN : enEL;

  return (
    <EditShiftFormStyled>
      <ConfigProvider locale={locale}>

        <div className='edit-shift-form-wrap'>
          <div className='form-row'>
            <Form.Item
              label={formLang('timeSchedule.editShiftForm.selectDay')}
              name='dayOff'
            >
              <DatePicker
                format={'DD.MM.YYYY'}
                picker='date'
                placeholder={'DD.MM.YYYY'}
                className='editshift-datepicker'
                disabled={true}
              />
            </Form.Item>
          </div>

          <Form.List name={'times'}>
            {(fields, { remove }) => (
              <>
                {fields.map((field, index) => (
                  <div
                    className='form-row group-time items-end time-form-item'
                    key={index}
                  >
                    <Form.Item
                      label={formLang('timeSchedule.editShiftForm.StartTime')}
                      name={[index, 'start']}
                      dependencies={[['times', index, 'end']]}
                      rules={[
                        { required: true, message: 'Please select start time' },
                        {
                          validator(rule, value, callback) {
                            const isWork = form.getFieldValue('times')?.[index]?.is_work;

                            if (isWork) {
                              const fallCloseHour =
                                checkDateTimeFallCloseHour(index);

                              if (fallCloseHour) {
                                return callback(
                                  'Start time and end time are not include in the time shift'
                                );
                              }

                              const timeStartCurr = get(
                                form.getFieldValue('times'),
                                [index, 'start']
                              );

                              const timeEndCurr = get(
                                form.getFieldValue('times'),
                                [index, 'end']
                              );

                              if (
                                timeEndCurr &&
                                timeStartCurr &&
                                timeEndCurr.isSameOrBefore(timeStartCurr)
                              ) {
                                return callback(
                                  'Time start is must before time end'
                                );
                              }

                              const timeEndPrev = get(form.getFieldValue('times'), [
                                index - 1,
                                'end',
                              ]);

                              if (
                                timeStartCurr &&
                                timeEndPrev &&
                                timeStartCurr.isBefore(timeEndPrev) && index !== 0
                              ) {
                                return callback(
                                  'Time start is not nested'
                                );
                              }

                              const checkDuplicateStart = validateStartEndTimeDuplicate(value, index);
                              const checkDuplicateEnd = validateStartEndTimeDuplicate(timeEndCurr, index);

                              if (checkDuplicateStart || checkDuplicateEnd) {
                                return callback(
                                  'Time start and time end cannot duplicate'
                                );
                              }
                            }

                            return callback();
                          },
                        },
                      ]}
                    >
                      <TimePickerAutoChange
                        placeholder='__:__'
                        allowClear={false}
                        className='is-time-picker edit-shift-time-picker'
                        showNow={false}
                        format={timeFormat}
                        minuteStep={15}
                        disabled={!form.getFieldValue('times')?.[index]?.is_work}
                        disabledHours={getDisabledHours}
                        disabledMinutes={getDisabledMinutes}
                      />
                    </Form.Item>
                    <p className='center-text'>{formLang('timeSchedule.editShiftForm.to')}</p>
                    <Form.Item
                      label={formLang('timeSchedule.editShiftForm.EndTime')}
                      name={[index, 'end']}
                      rules={[
                        { required: true, message: 'Please select end time' },
                      ]}
                    >
                      <TimePickerAutoChange
                        placeholder='__:__'
                        allowClear={false}
                        className='is-time-picker edit-shift-time-picker'
                        showNow={false}
                        format={timeFormat}
                        minuteStep={15}
                        disabled={!form.getFieldValue('times')?.[index]?.is_work}
                        disabledHours={getDisabledHours}
                        disabledMinutes={getDisabledMinutes}
                      />
                    </Form.Item>
                    {!form.getFieldValue('times')?.[index]?.is_work ? (
                      <Popover
                        placement='top'
                        arrow={false}
                        zIndex={1050}
                        content={
                          <PopoverStyled>
                            <p
                              className='delete-time-off'
                              onClick={async () => {
                                const offId =
                                  form.getFieldValue('times')?.[index]?.off_id;
                                const deleteResult = await handleRemoveTimeOff?.(
                                  offId
                                );

                                if (deleteResult) {
                                  remove(field.name);
                                }
                              }}
                            >
                              {formLang('timeSchedule.editShiftForm.DeleteThisTimeOff')}
                            </p>

                            {form.getFieldValue('times')?.[index]?.is_repeat ? (
                              <p
                                className='delete-repetition'
                                onClick={async () => {
                                  const offId =
                                    form.getFieldValue('times')?.[index]?.off_id;
                                  const deleteResult =
                                    await handleRemoveTimeOff?.(offId, true);

                                  if (deleteResult) {
                                    remove(field.name);
                                  }
                                }}
                              >
                                {formLang('timeSchedule.editShiftForm.DeleteTheRepetition')}
                              </p>
                            ) : null}
                          </PopoverStyled>
                        }
                        trigger='click'
                      >
                        <ButtonStyled className='remove' type='button'>
                          <IconRemove />
                        </ButtonStyled>
                      </Popover>
                    ) : null}
                  </div>
                ))}
              </>
            )}
          </Form.List>

          <div className='form-row'>
            <Form.Item noStyle name={'updateTimeScheduleCheck'} valuePropName='checked'>
              <Checkbox />
            </Form.Item>
            <p
              className='form-label is-center'
              onClick={() => handleUpdateTimeScheduleCheck()}
              style={{ cursor: 'pointer' }}
            >
              {formLang('timeSchedule.editShiftForm.updateTimeScheduleForTeamMember')}
            </p>
          </div>

          <div className='time-shift-error'>
            <Form.Item
              className='messageError'
              name={'timeShift'}
              rules={[
                {
                  validateTrigger: 'onChange',
                  validator() {
                    const timeList = form.getFieldValue('times');
                    if (!timeList || timeList.length === 0) {
                      return Promise.reject(
                        new Error('Time shift can not blank')
                      );
                    }
                    return Promise.resolve();
                  },
                },
              ]}
              dependencies={['times']}
            />
          </div>
        </div>
      </ConfigProvider>

    </EditShiftFormStyled>
  );
};

const EditShiftFormStyled = styled.div`
  .edit-shift-form-wrap {
    margin-top: 28px;
  }

  .add-shift-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    margin-bottom: 16px;
    cursor: pointer;
  }

  .edit-shift-datepicker {
    background: #f0f2f6 !important;
  }

  .ant-picker {
    min-width: unset;
  }

  .repeat-everyweek-check {
    margin-bottom: 16px;
  }

  .center-items {
    display: flex;
    justify-content: center;
  }
  .group-time {
    position: relative;
  }
  .editshift-datepicker,
  .editshift-datepicker:hover {
    background: #f0f2f6 !important;
  }

  .messageError {
    margin-top: 16px;
    text-align: center;
  }

  .time-shift-error .ant-form-item-control-input {
    display: none;
  }

  .edit-shift-time-picker,
  .edit-shift-time-picker:hover {
    /* background: #f0f2f6 !important; */
  }

  .time-form-item {
    margin-bottom: 30px;
  }
`;

const PopoverStyled = styled.div`
  padding: 16px;

  .delete-time-off,
  .delete-repetition {
    cursor: pointer;
  }

  .delete-repetition {
    color: #f5222d;
    margin-top: 16px;
  }
`;

export default EditShiftForm;
