import {
    Form,
    Input,
    Modal,
} from 'antd';
import  { IOptionMulti } from 'components/common/MultipleSelect';
import SYSTEM_VARIABLES from 'constants/variables';
import { first, get } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import storage from 'utils/sessionStorage';
import serviceSelectors from '../../../services/services/selectors';
import { useTranslation } from 'react-i18next';
import FormBlock from '../../../Products/Components/BrandFrom/FormBlock';
import TextArea from 'antd/lib/input/TextArea';
export interface IServiceForm {
    service_name?: string;
    category?: number;
    price?: number;
    availableLocation?: number | number[];
    locationTemp: number[];
    newCategory?: string;
    teamMember?: number[];
    teamMemberTemp: number[];
    duration?: number;
    isEnableService?: boolean;
    isEnableBookingLink?: boolean;
    isEnableVoucherSales?: boolean;
    isEnableDiscounts?: boolean;
    isMakeNewCategory?: boolean;
    serviceDescription?: string;
    serviceVariants?: IServiceVariant[];
}

interface IServiceVariant {
    name?: string;
    price?: number;
    duration?: number;
}

type IEmployeeItem = {
    id: string | number;
    title_name: string;
    merchant_location_id: string;
    employee_id: number;
    user: IUser;
};

interface IUser {
    id: number;
    full_name: string;
    email: string;
    phone: string;
}

export type IFuncHandleSubmit = (value: IPayloadReturnType) => void;
interface ServicesFormProps {
    isModalOpen?: boolean;
    handleCancel?: () => void;
    handleSubmit: IFuncHandleSubmit;
    currService?: any;
    formMode?: any
}

const BrandForm = ({
    isModalOpen,
    handleCancel,
    handleSubmit,
    currService,
    formMode,
}: ServicesFormProps) => {
    const [form] = Form.useForm<IServiceForm>();
    const { t: serviceLang } = useTranslation('service');
    const dataForm = serviceSelectors.getShowCreateService();

    const locations: IOptionMulti[] = useMemo(
        () =>
            (dataForm?.locations as { id: string | number; name: string }[])
                ?.filter((o) => o.id != 99999)
                ?.map((o) => ({ value: o.id, title: o.name })) ?? [],
        [dataForm?.locations]
    );
    const [selectedLocations, setSelectedLocations] = useState<any[]>(
        locations.map(({ value }) => value)
    );

    const employees: IOptionMulti[] = useMemo(
        () =>
            (dataForm?.employees as IEmployeeItem[])
                ?.filter((o) => selectedLocations.includes(o.merchant_location_id))
                ?.map((o) => ({ value: o.id, title: o.user.full_name })) ?? [],
        [dataForm?.employees, selectedLocations]
    );
    const [selectedEmployees, setSelectedEmployees] = useState<any[]>(employees);

    useEffect(() => {
        if (!currService) return;
        form.setFieldsValue(currService);
        setSelectedLocations(currService.availableLocation);
        setSelectedEmployees(currService.teamMember);
    }, [currService]);

    useEffect(() => {
        form?.setFieldValue('availableLocation', [...selectedLocations]);
        if (locations.length > 1 && selectedLocations.length === locations.length) {
            form?.setFieldValue('locationTemp', [SYSTEM_VARIABLES.ALL_LOCATIONS]);
        } else {
            form?.setFieldValue('locationTemp', [...selectedLocations]);
        }
    }, [selectedLocations]);

    useEffect(() => {
        form?.setFieldValue('teamMember', [...selectedEmployees]);
        if (employees.length > 1 && selectedEmployees.length === employees.length) {
            form?.setFieldValue('teamMemberTemp', [SYSTEM_VARIABLES.ALL_EMPLOYEES]);
        } else {
            form?.setFieldValue('teamMemberTemp', [...selectedEmployees]);
        }
    }, [selectedEmployees]);

    const checkValid = async () => {
        try {
            await form.validateFields([]);
            return true;
        } catch (error) {
            return false;
        }
    };

    const _onSubmit: React.MouseEventHandler<HTMLButtonElement> = async (e) => {
        e.preventDefault();
        const isFormValid = await checkValid();
        if (!isFormValid) return;
        form.submit();
    };

    const _handleSubmit = (values: IServiceForm) => {
        const payload: IPayloadReturnType = getPayload(values);
        handleSubmit(payload);
    };

    const initialValues = useMemo(() => {
        const result = {
            isMakeNewCategory: false,
            isEnableService: currService?.isEnableService === 0 ? false : true,
            isEnableBookingLink: currService?.isEnableBookingLink === 0 ? false : true,
            isEnableVoucherSales: currService?.isEnableVoucherSales === 0 ? false : true,
            isEnableDiscounts: currService?.isEnableDiscounts === 0 ? false : true,
            isNewCategory: false,
            serviceVariants: [],
        };

        if (locations.length === 0) {
            form.setFieldValue('locationTemp', [SYSTEM_VARIABLES.ALL_LOCATIONS]);
            form.setFieldValue('availableLocation', SYSTEM_VARIABLES.ALL_LOCATIONS);
            form.setFieldValue('teamMemberTemp', [SYSTEM_VARIABLES.ALL_EMPLOYEES]);
            form.setFieldValue('teamMember', SYSTEM_VARIABLES.ALL_EMPLOYEES);
        }

        return result;
    }, [locations]);

    return (
        <Modal
            maskClosable={false}
            open={isModalOpen}
            onCancel={handleCancel}
            okButtonProps={{ style: { display: 'none' } }}
            width={574}
            footer={null}
            forceRender
        >
            <Form
                name='basic'
                className='modal-form'
                form={form}
                onFinish={_handleSubmit}
                autoComplete='off'
                layout='vertical'
                initialValues={initialValues}
            >
                <FormBlock label={formMode === 'add' ? serviceLang('btn.AddNewBrand') || '': serviceLang('EditBrand') || ''}>
                    <Form.Item
                        label={serviceLang('Brand') + '(*)'}
                        name={'service_name'}
                        rules={[
                            {
                                validator: (_, value) =>
                                    value?.trim() ? Promise.resolve() : Promise.reject(new Error(`${serviceLang('BrandNameIsRequired')}`)),
                            },
                        ]}
                    >
                        <Input placeholder={serviceLang('input.EnterBrandName') || ''}></Input>
                    </Form.Item>
                    <Form.Item
                        label={serviceLang('Description')}
                        name={'Description'}
                    >
                        <TextArea
                            placeholder={serviceLang('input.EnterDescription') || ''}
                            rows={2}></TextArea>
                    </Form.Item>
                </FormBlock>
                <div className='modal-footer'>
                    <button className='common-btn is-brand' onSubmit={_onSubmit}>
                        {serviceLang('btn.Confirm')}
                    </button>
                </div>
            </Form>
        </Modal>
    );
};

export default BrandForm;

const getPayload = (values: IServiceForm) => {
    const firstVariant = first(values?.serviceVariants);
    const servicePrice =
        get(firstVariant, 'price', 0) != 0
            ? get(firstVariant, 'price', 0)
            : values.price;
    const serviceDuration =
        get(firstVariant, 'duration', 0) != 0
            ? get(firstVariant, 'duration', 0)
            : values.duration;

    const merchant_id = storage.merchantId.get() ?? '';
    const service_category_id = values?.category;

    const merchant_location_id =
        values?.locationTemp?.length > 0
            ? values?.locationTemp
            : values?.availableLocation;
    const regular_price = servicePrice;
    const sale_price = servicePrice;
    const name = values?.service_name;
    const duration_time = serviceDuration;
    const enable = !!values?.isEnableService;

    const enable_online_booking = !!values?.isEnableBookingLink;
    const enable_voucher_sale = !!values?.isEnableVoucherSales;
    const enable_discount = !!values?.isEnableDiscounts;
    const checkNewCategory = values?.isMakeNewCategory;
    const checkNewExtraService =
        values?.serviceVariants?.length != 0 ? true : false;

    const emoloyee_service_id =
        values.teamMemberTemp.length > 0
            ? values?.teamMemberTemp
            : values?.teamMember;
    const service_variants = values?.serviceVariants?.map(
        (item: IServiceVariant) => ({
            extra_name: item?.name,
            price: item?.price,
            duration: item?.duration,
        })
    );

    const name_category = values?.newCategory;
    const color_id = 1;

    const description = values?.serviceDescription ?? '';

    return {
        merchant_id,
        service_category_id,
        merchant_location_id,
        regular_price,
        sale_price,
        name,
        duration_time,
        enable,
        enable_online_booking,
        enable_voucher_sale,
        enable_discount,
        checkNewCategory,
        checkNewExtraService,
        emoloyee_service_id,
        service_variants,
        name_category,
        color_id,
        description,
    };
};

export type IPayloadReturnType = ReturnType<typeof getPayload>;
