const IconPaymentMobile = () => {
    return (
        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path opacity="0.4" d="M16 2.99512H8C6.89543 2.99512 6 3.89055 6 4.99512V20.9951C6 22.0997 6.89543 22.9951 8 22.9951H16C17.1046 22.9951 18 22.0997 18 20.9951V4.99512C18 3.89055 17.1046 2.99512 16 2.99512Z" fill="#595959"/>
        <path d="M12 7.99512H18C19.1046 7.99512 20 8.89055 20 9.99512V13.9951C20 15.0997 19.1046 15.9951 18 15.9951H12V7.99512Z" fill="#595959"/>
        <path d="M13 19.9951C13 20.5474 12.5523 20.9951 12 20.9951C11.4477 20.9951 11 20.5474 11 19.9951C11 19.4428 11.4477 18.9951 12 18.9951C12.5523 18.9951 13 19.4428 13 19.9951Z" fill="#595959"/>
        <path opacity="0.4" d="M12 11.4951L20 11.4951L20 9.99512L12 9.99512L12 11.4951Z" fill="#595959"/>
        </svg>
    );
};

export default IconPaymentMobile;