import { useTranslation } from 'react-i18next';
import PaymentMethodsTab from '../components/PaymentMethodsTab';

const PaymentMethodspage = () => {
    const { t: settingLang } = useTranslation('setting');
    return (
        <>
            <div className={'private-filter'}>
                <div className={'common-btn'}>
                    {settingLang('AddMethod')}
                </div>
            </div>
            <PaymentMethodsTab/>
        </>
    );
};

export default PaymentMethodspage;