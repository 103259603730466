import InputSearchText from 'components/common/Input/InputSearchDebounce';
import React, { useEffect, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
import promotionActions from '../services/actions';
import promotionSelectors from '../services/selectors';
import AddPromotion from './Add';

// eslint-disable-next-line @typescript-eslint/ban-types
type IPromotionFilterProps = {};
const PromotionFilter: React.FC<IPromotionFilterProps> = () => {
  const params = promotionSelectors.getParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useAppDispatch();
  const searchTime = useRef<number>(0);

  useEffect(() => {
    // @ts-ignore
    setSearchParams(params);
  }, [params]);

  const onSearch = (event: string) => {
    //Hạn chế bị render lần đầu
    if (searchTime.current === 1) {
      const _params = {
        keyword: event || undefined,
        page: 1,
      };
      dispatch(promotionActions.setListPromotionParams(_params));
    }

    if (!searchTime.current && !event?.length) {
      searchTime.current = 1;
    }
  };

  // const onChangeType = (type: string) => {
  //   dispatch(promotionActions.setListPromotionParams({
  //     type
  //   }));
  // };

  return (
    <PromotionFilterStyled className={'private-filter'}>
      <InputSearchText
        value={searchParams.get('keyword') ?? ''}
        onSearchText={onSearch}
      />
      {/* <div className='common-select'>
        <Select
          value={params.type}
          options={[
            {
              value: PROMOTION_TYPES.PERCENT,
              label: 'By percentage',
            },
            {
              value: PROMOTION_TYPES.PRICE,
              label: 'By price',
            },
          ]}
          onChange={onChangeType}
        />
      </div> */}
      <AddPromotion />
    </PromotionFilterStyled>
  );
};

export default PromotionFilter;
const PromotionFilterStyled = styled.div``;
