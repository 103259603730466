import { Avatar } from 'antd';
import IconClose from 'assets/svg/IconClose';
import bookingActions from 'features/bookings/services/actions';
import bookingSelectors from 'features/bookings/services/selectors';
import { StaffPublic } from 'features/users/services/types/teamMember';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';


export type ITeamMemberItemData = StaffPublic & {
  isAvailable: boolean
}
interface ITeamMember {
  data: ITeamMemberItemData;
  selected: boolean;
}

const TeamMemberItem = ({
  data,
  selected,
}: ITeamMember) => {

  // if(data.id === '') {
  //   return (
  //     <TeamMemberItemStyled className={'active'} >
  //       {/* {!!selected && <button type='button' className="close-icon"><IconClose /></button>} */}

  //       <Avatar className='avatar'
  //         size={{ xs: 24 }}
  //       >
  //         <p>{data.full_name.charAt(0)}</p>
  //       </Avatar>
  //       {data.full_name}
  //   </TeamMemberItemStyled>
  //   );
  // }

  const teamMemberSelected = bookingSelectors.bookingOnline.getTeamMemberSelected();
  const dispatch = useAppDispatch();

  const teamMemberSelect = () => {
    if(selected) {
      dispatch(bookingActions.setBookingOnlineTeamMemberSelected(teamMemberSelected.filter(o => o.id !== data.id)));
      dispatch(bookingActions.bookingOnlineServiceSelected.set([]));
    } else {
      dispatch(bookingActions.setBookingOnlineTeamMemberSelected([data]));
    }
  };

  return (
    <TeamMemberItemStyled className={`${selected ? 'active' : ''}`} onClick={ teamMemberSelect}>
      {!!selected && <button type='button' className="close-icon"><IconClose /></button>}

      <Avatar className='avatar'
        size={{ xs: 12 }}
      >
        <p>{data.full_name.charAt(0)}</p>
      </Avatar>
      {data.full_name}
    </TeamMemberItemStyled>
  );
};

const TeamMemberItemStyled = styled.div`
  border: 1px solid lightgray;
  border-radius: 12px;
  padding: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-weight: 600;
  font-size: 14px;
  gap: 18px;
  cursor: pointer;
  min-width: 100%;

  :hover {
    border-color: #363565;
    background-color: #e2e5ff;
  }

  .close-icon {
    top: -12px;
    right: 0px;
    position: absolute;
    background-color: white;
    padding: 0px 2px;
    border-radius: 100%;
  }

  .avatar{
    background: linear-gradient(315deg, #6587FF 0%, #C883FF 100%);
    display: flex;
    align-items: center;
    @media only screen and (max-width: 767.98px) {
      width: 50px !important;
      height: 50px !important;
    }
    .ant-avatar-string {
      font-weight: 800;
      font-size: 20px;
      display: flex;
      line-height: 1;
      transform: scale(1) translateX(-50%) translateY(-1px) !important;
    }
  }
`;

export default TeamMemberItem;