const IconAddCalendar = () => {
    return (
        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path opacity="0.4" d="M3 8.49512C3 6.28598 4.79086 4.49512 7 4.49512H17C19.2091 4.49512 21 6.28598 21 8.49512V9.99512V18.9951C21 21.2043 19.2091 22.9951 17 22.9951H7C4.79086 22.9951 3 21.2043 3 18.9951V9.99512V8.49512Z" fill="#595959"/>
        <path d="M17 4.49512H7C4.79086 4.49512 3 6.28598 3 8.49512V9.99512H21V8.49512C21 6.28598 19.2091 4.49512 17 4.49512Z" fill="#595959"/>
        <path opacity="0.4" d="M8 2.24512C8.41421 2.24512 8.75 2.5809 8.75 2.99512V5.99512C8.75 6.40933 8.41421 6.74512 8 6.74512C7.58579 6.74512 7.25 6.40933 7.25 5.99512V2.99512C7.25 2.5809 7.58579 2.24512 8 2.24512ZM16 2.24512C16.4142 2.24512 16.75 2.5809 16.75 2.99512V5.99512C16.75 6.40933 16.4142 6.74512 16 6.74512C15.5858 6.74512 15.25 6.40933 15.25 5.99512V2.99512C15.25 2.5809 15.5858 2.24512 16 2.24512Z" fill="#595959"/>
        <path d="M12 12.2451C12.4142 12.2451 12.75 12.5809 12.75 12.9951V15.2451H15C15.4142 15.2451 15.75 15.5809 15.75 15.9951C15.75 16.4093 15.4142 16.7451 15 16.7451H12.75V18.9951C12.75 19.4093 12.4142 19.7451 12 19.7451C11.5858 19.7451 11.25 19.4093 11.25 18.9951V16.7451H9C8.58579 16.7451 8.25 16.4093 8.25 15.9951C8.25 15.5809 8.58579 15.2451 9 15.2451H11.25V12.9951C11.25 12.5809 11.5858 12.2451 12 12.2451Z" fill="#595959"/>
        </svg>
    );
};

export default IconAddCalendar;