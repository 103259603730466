export enum EPackageName {
  ENTERPRISE = 'ENTERPRISE',
  STARTER = 'STARTER',
  ADVANCED = 'ADVANCED'
}

export enum CustomerPermission {
  ADD_CUSTOMER = 'add_customer',
  VIEW_CUSTOMER = 'view_customer',
  EDIT_CUSTOMER = 'edit_customer',
  DELETE_CUSTOMER = 'delete_customer',
  IMPORT_CUSTOMER = 'import_customer',
  EXPORT_CUSTOMER = 'export_customer',
}

export enum EmployeePermission {
  ADD_EMPLOYEE = 'add_employee',
  VIEW_EMPLOYEE = 'view_employee',
  EDIT_EMPLOYEE = 'edit_employee',
  DELETE_EMPLOYEE = 'delete_employee',
  IMPORT_EMPLOYEE = 'import_employee',
  EXPORT_EMPLOYEE = 'export_employee',
}

export enum SalesPermission {
  MANAGE_SALE = 'manage_sale'
}

export enum BookPermission {
  VIEW_GRID = 'view_gird',
  VIEW_CALENDAR = 'view_calendar',
  VIEW_LIST = 'view_list',
  CREATE_BOOK = 'create_book',
  EDIT_BOOK = 'edit_book',
  DELETE_BOOK = 'delete_book',
  DUPLICATE_BOOK = 'duplicate_book',
  NO_SHOW_BOOK = 'no_show_book',
  ALLOW_CHECK_TEAM_MEMBER = 'allow_check_team_member'
}

export enum ServicePermission {
  ADD_SERVICE = 'add_service',
  VIEW_SERVICE = 'view_service',
  EDIT_SERVICE = 'edit_service',
  DELETE_SERVICE = 'delete_service',
  IMPORT_SERVICE = 'import_service',
  EXPORT_SERVICE = 'export_service'
}

export enum CategoryPermission {
  ADD_CATEGORY = 'add_category',
  VIEW_CATEGORY = 'view_category',
  EDIT_CATEGORY = 'edit_category',
  DELETE_CATEGORY = 'delete_category'

}

export enum VoucherPermission {
  ADD_VOUCHER = 'add_voucher',
  VIEW_VOUCHER = 'view_voucher',
  EDIT_VOUCHER = 'edit_voucher',
  DELETE_VOUCHER = 'delete_voucher',
  SEND_MAIL_VOUCHER_PURCHASED = 'send_mail_voucher_purchased',
}

export enum PromotionPermission {
  ADD_PROMOTION = 'add_promotion',
  VIEW_PROMOTION = 'view_promotion',
  EDIT_PROMOTION = 'edit_promotion',
  DELETE_PROMOTION = 'delete_promotion',
}

export enum ProgramPermission {
  ADD_PROGRAM = 'add_program',
  VIEW_PROGRAM = 'view_program',
  EDIT_PROGRAM = 'edit_program',
  DELETE_PROGRAM = 'delete_program',
}

export enum RewardPermission {
  ADD_REWARD = 'add_reward',
  VIEW_REWARD = 'view_reward',
  EDIT_REWARD = 'edit_reward',
  DELETE_REWARD = 'delete_reward',
}

export enum LoyaltySettingPermission {
  MANAGE_SETTING_LOYAL = 'manage_setting_loyal',
}

export enum AnalyticPermission {
  VIEW_ANALYTIC = 'view_analytic',
  EXPORT_ANALYTIC = 'export_analytic',
}

export enum LocationPermission {
  ADD_LOCATION = 'add_location',
  VIEW_LOCATION = 'view_location',
  EDIT_LOCATION = 'edit_location',
  DELETE_LOCATION = 'delete_location',
}

export enum WorkingHourPermission {
  VIEW_WORKING_HOUR = 'view_working_hour',
  CHECKIN_WORKING_HOUR = 'checkin_working_hour',
  CHECKOUT_WORKING_HOUR = 'checkout_working_hour',
  ADD_SHIFT = 'add_shift',
  EDIT_SHIFT = 'edit_shift',
}

export enum SettingPermission {
  MANAGE_GENERAL_SETTING = 'manage_genaral_setting',
  MANAGE_BOOKING_SETTING = 'manage_booking_setting',
  MANAGE_CLOSED_PERIODS_SETTING = 'manage_closed_periods_setting',
  MANAGE_TYRO_SETTING = 'manage_tyro_setting',
  MANAGE_BOOKING_LINK_SETTING = 'manage_booking_link_setting',
  MANAGE_CANCELLATION_REASON_SETTING = 'manage_cancellation_reason_setting',
  SERVICE_CHARGE = 'service_charge',
  SETTING_NOTIFICATION = 'setting_notification',
  AUTO_MESSAGE = 'auto_message',
  STAFF_PERMISSION = 'staff_persmission'
}

export enum PaymentMethodPermission {
  CASH_PAYMENT = 'cash_payment',
  CARD_PAYMENT = 'card_payment',
  VOUCHER_PAYMENT = 'voucher_payment',
  MIX_PAYMENT = 'mix_payment',
  DISCOUNT_FEE = 'discount_fee',
  EXTRA_FEE = 'extra_fee',
}

export enum CheckinFormPermission {
  CHECKIN_QR_CODE = 'checkin_qr_code',
}

export enum BookingFormPermission {
  BOOKING_ONLINE = 'booking_online',
}

export enum InvoicePermission {
  VIEW_INVOICE = 'view_invoice',
  REFUND_INVOICE = 'refund_invoice',
  SEND_INVOICE = 'send_invoice',
}

export const PackagePermissions = {
  ...CustomerPermission, 
  ...EmployeePermission, 
  ...SalesPermission, 
  ...BookPermission, 
  ...ServicePermission, 
  ...CategoryPermission, 
  ...VoucherPermission, 
  ...PromotionPermission, 
  ...ProgramPermission, 
  ...RewardPermission, 
  ...LoyaltySettingPermission, 
  ...AnalyticPermission, 
  ...LocationPermission, 
  ...WorkingHourPermission, 
  ...SettingPermission, 
  ...PaymentMethodPermission, 
  ...CheckinFormPermission, 
  ...BookingFormPermission, 
  ...InvoicePermission,
};

export type PackagePermissionsType = typeof PackagePermissions[keyof typeof PackagePermissions];

export enum ModulePermission {
  CUSTOMERS = 'customers',
  EMPLOYEES = 'employees',
  SALES = 'sales',
  BOOKS = 'books',
  SERVICES = 'services',
  SERVICE_CATEGORIES = 'service_categories',
  VOUCHERS = 'vouchers',
  PROMOTIONS = 'promotions',
  PROGRAMS = 'programs',
  REWARDS = 'rewards',
  LOYALTY_SETTINGS = 'loyaltysettings',
  ANALYTICS = 'analytics',
  LOCATIONS = 'locations',
  WORKING_HOURS = 'workinghours',
  SETTINGS = 'settings',
  PAYMENT_METHODS = 'paymentmethods',
  CHECKIN_FORMS = 'checkinforms',
  BOOKING_FORMS = 'bookingforms',
  INVOICES = 'invoices'
}