import salesSelectors from 'features/sales/services/selectors';
import { useTranslation } from 'react-i18next';
import { formatMoney } from 'utils/unit';

const SubTotalAmount = () => {
  const result = salesSelectors.getSubTotal();
  const {t: saleLang} = useTranslation('sales');
  if (!result) return null;
  
  return (
    <p className='extra-price-row'>
      {saleLang('Subtotal')}
      <span>{formatMoney(result ?? 0)}</span>
    </p>
  );
};

export default SubTotalAmount;
