import MainLogo from 'assets/icons/main_logo.svg';
import AuthAvatar from 'assets/images/auth/auth_avatar.jpg';
import actions from 'features/auth/services/actions';
import { IApiChangePasswordBody, IFormChangePassword } from 'features/auth/services/types/login';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useSetLoadingPage } from 'services/UI/LoadingPage';
import { useAppDispatch } from 'store/hooks';
import '../../styles/Auth.scss';
import { Form } from 'antd';
import apisAuth from 'features/auth/services/apis';
import message from 'components/common/Message';

/**
 * Reset password page
 * @returns 
 */
const ResetPasswordPage = () => {
  const { t: signInLang } = useTranslation('sign_in');
  // const { t: commonLang } = useTranslation('common');
  // const { t: formLang } = useTranslation('form');

  // const [userName] = useState('Abdullah');
  // const [isShowInputPass, setIsShowInputPass] = useState(false);
  // const [isShowForgotPass, setIsShowForgotPass] = useState(false);
  // const [isShowBtnBack, setIsShowBtnBack] = useState(false);
  const navigate = useNavigate();
  // const [errorText, setErrorText] = useState('');
  const setIsLoading = useSetLoadingPage();
  const dispatch = useAppDispatch();
  // const [errorEmail, setErrorEmail] = useState(false);

  // =============== Change language ====================
  const { t, i18n } = useTranslation();

  /**
   * Change language
   * @param languageValue 
   */
  const changeLanguage = (languageValue: any) => {
    i18n.changeLanguage(languageValue);
    document.title = t('MindHana');
    localStorage.setItem('selectedLanguage', languageValue);
  };

  // const handleLanguageChange = (e: any) => {
  //   const languageValue = e.target.value;
  //   changeLanguage(languageValue);
  // };

  useEffect(() => {
    const storedLanguage = localStorage.getItem('selectedLanguage');
    const defaultLanguage = 'vi';
    const selectedLanguage = storedLanguage || defaultLanguage;

    if (selectedLanguage !== i18n.language) {
      changeLanguage(selectedLanguage);
    }

    const handleBeforeUnload = () => {
      localStorage.setItem('selectedLanguage', i18n.language);
    };

    const handlePageVisibilityChange = () => {
      if (document.visibilityState === 'hidden') {
        localStorage.setItem('selectedLanguage', i18n.language);
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    document.addEventListener('visibilitychange', handlePageVisibilityChange);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
      document.removeEventListener('visibilitychange', handlePageVisibilityChange);
    };
  }, [i18n.language]);
  // ========================================

  
  // const {token} = useParams();
  
  const [params] = useSearchParams();

  /**
   * Set token
   * @param _token 
   */
  const setToken = (_token: string) => {
    dispatch(actions.setToken(_token));
  };
  
  /**
   * Get payload
   * @param values 
   * @returns 
   */
  const getPayload = (values: IFormChangePassword) => {
    return {
      password: values.password,
      password_confirmation: values.passwordConfirm,
      token: values.token,
    } as IApiChangePasswordBody;
  };

  /**
   * Handle submit
   * @param values 
   */
  const handleSubmit = async (values: IFormChangePassword) => {
    const payload = getPayload(values) as IApiChangePasswordBody; 
    try {
      
      const rest = await apisAuth.changePassword(payload);

      if(rest?.data?.message) {
        message.success(rest?.data?.message);
        
        setToken('');
        navigate('/sign-in');
      }
      
    } catch (error: any) {

      const errs = error?.response?.data.errors;
      const errKeys = Object.keys(errs); 

      errKeys.forEach(key => {
        message.error(errs[key]?.[0]);
      });
      dispatch(actions.login.fail({}));
    } finally {
      setIsLoading(false);
    }
  };

  // const handleLogin = (e: any) => {
  //   e.preventDefault();
  //   if (email && password) login();
  // };

  // const handleBtnClick = (e: any) => {
  //   e.preventDefault();
  //   /* eslint-disable-next-line */
  //   if (!/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g.test(email)) {
  //     setErrorEmail(true);
  //     return;
  //   }
  //   setErrorEmail(false);
  //   setIsShowInputPass(true);
  //   setIsShowBtnBack(true);
  // };


  return (
    <section className='auth-layout'>
      <div className='layout-right'>
        <div className='auth-content'>
          <div className='content-inner'>

            <div className='auth-block'>
                <>
           
                  <div className='auth-avatar'>
                    <img src={AuthAvatar} alt='Abdullah'></img>
                  </div>
                  <h1 className='auth-title'>
                    {signInLang('secondStep.title')}
                  </h1>
                  <p className='auth-text'>
                    Reset password in here
                  </p>
                  <Form
                    data-form-testing='form-reset-password'
                    onFinish={handleSubmit}>
                    <Form.Item name={'token'} initialValue={params.get('token')} noStyle>
                      <input value={params.get('token') ?? ''} type='hidden'></input>
                    </Form.Item>
                    <Form.Item className='form-row' name={'password'}>
                      <input
                        type='password'
                        placeholder={ 'Enter new password' }
                        autoFocus
                      ></input>
                    </Form.Item>
                    <Form.Item className='form-row' name={'passwordConfirm'}>
                      <input
                        type='password'
                        autoFocus
                        placeholder={ 'Enter confirm password' }
                      ></input>
                    </Form.Item>
                    <div className='form-row'>
                      <button
                        type='submit'
                        className='btn-submit'>
                        Reset Password
                      </button>
                     
                    </div>
                  </Form>
                </>
             
            </div>
            <div className='auth-logo'>
              <img src={MainLogo} alt='Natural Nails and Beauty'></img>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ResetPasswordPage;
