import IconFilter from 'assets/svg/IconFilter';
import bookingSelectors from 'features/bookings/services/selectors';
import { IApiBookingParams } from 'features/bookings/services/types/booking';
import useDetachScreen from 'hooks/useDetachScreen';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import getAddBookingLink from './getAddBookingLink';
import DateViewPicker from './widgets/DateViewPicker';
import FilterModal from './widgets/FilterModal';
import InputSearchText from './widgets/InputSearchText';
import LocationPicker from './widgets/LocationPicker';
import SelectLayout from './widgets/SelectLayout';
// import StatusPicker from './widgets/StatusPicker';
import TeamMemberSelect from './widgets/TeamMemberSelect';
import ZoomView from './widgets/ZoomStatus';
import IconTimeAndDate from 'assets/svg/IconTimeAndDate';
import { useTranslation } from 'react-i18next';
import DateHorizontalPicker from './widgets/FilterMobile/DateHorizontalPicker';
import MemberHorizontalPicker from './widgets/FilterMobile/MemberHorizontalPicker';
import StatusPicker from './widgets/StatusPicker';
import DateHorizontalWeekPicker from './widgets/FilterMobile/DateHorizontalWeekPicker';
import { CalendarViewType } from 'constants/index';
import FilterModalMobile from './widgets/FilterMobile/FilterModalMobile';


const CalendarFilter = () => {
  const navigate = useNavigate();
  const dateStore = bookingSelectors.getCalendarParamValue('date') as number;
  const merchant_location_id = (bookingSelectors.getCalendarParamValue('merchant_location_id') ?? 'all') as IApiBookingParams['merchant_location_id'];
  const onAdd = () => {
    navigate(getAddBookingLink(dateStore, merchant_location_id));
  };


  const { isTablet, isMobile } = useDetachScreen();

  
  if (isTablet) return <TabletView onAdd={onAdd} />;
  if(isMobile) return <MobileView onAdd={onAdd}/>;

  return (
    <BrowserView onAdd={onAdd} />
  );
};

export default CalendarFilter;

type IChildViewProps = {
  onAdd?: () => void;
};
const BrowserView = ({ onAdd }: IChildViewProps) => {
  const { t: commonLang } = useTranslation('common');

  return (
    <>
      <div className='block-left desktop-view'>
        <LocationPicker />
        <TeamMemberSelect isCalendar />
      </div>
      <div className='block-right'>
        <div className='d-flex no-wrap'>
          <InputSearchText />
          <div className='desktop-view'>
            <DateViewPicker isNoDatePicker={true} />
          </div>
          <div className='desktop-view'>
            <ZoomView />
          </div>
          <SelectLayout />
        </div>
        <div className='quick-booking-btn'>
          <button
            className={'common-btn'}
            onClick={onAdd}
          >
            <p>{commonLang('AddBooking')}</p>
          </button>
        </div>
      </div>
      <div className='booking-grid-block tablet-desktop-view is-full'>
        <div className='block-heading no-border'>
          <div className='heading-date'>
            <DateViewPicker isNoSelect={true} />
          </div>
        </div>
      </div>
    </>
  );
};

const TabletView = ({ onAdd }: IChildViewProps) => {
  const [isShowModal, setIsShowModal] = useState<boolean>(false);
  const { t: commonLang } = useTranslation('common');
  return <TabletViewStyled className='TabletViewStyled'>
    <div className='block-left desktop-view'>
      <LocationPicker />
      <TeamMemberSelect isCalendar />
    </div>
    <div className='form-search-filter'>
      <div className='block-right'>
        <div className='d-flex no-wrap'>
          <button
            className='filter-btn tablet-mobile-view'
            onClick={() => setIsShowModal(true)}
          >
            <IconFilter />

          </button>
          <div className='desktop-view'>
            <DateViewPicker isNoDatePicker={true} />
          </div>
          {/* <div className='desktop-view'>
          <LocationPicker />
        </div> */}
          {/* <div className='desktop-view'>
          <StatusPicker />
        </div> */}
          <div className='desktop-view'>
            <ZoomView />
          </div>
          <SelectLayout />
        </div>
        <div className='quick-booking-btn'>
          <button
            className={'common-btn'}
            onClick={onAdd}
          >
            <p>{commonLang('AddBooking')}</p>
            <IconTimeAndDate />
          </button>
        </div>
      </div>
      <InputSearchText />
    </div>
    <div className='item-full'>
      <DateViewPicker isNoSelect={true} />
    </div>
    <FilterModal isShow={isShowModal} onClose={() => setIsShowModal(false)} />
  </TabletViewStyled>;
};

const MobileView = ({ onAdd }: IChildViewProps) => {
  const lstMerchantLocations = bookingSelectors.getLstMerchantLocations();
  const [isShowModal, setIsShowModal] = useState<boolean>(false);
  const { t: commonLang } = useTranslation('common');
  const viewType = bookingSelectors.getCalendarViewType();


  return <MobileViewStyled className='MobileViewStyled'>
    <div className='form-search-filter'>
      <div className='form-filter'>
        <div className='form-filter-row'>
          {lstMerchantLocations?.length > 1 ? 
            <div className='item-filter item-picker'>
              <LocationPicker /> 
            </div>
          : <></>}
          <div className='item-filter item-picker'>
            <StatusPicker />
          </div>
        </div>
        <div className='form-filter-row'>
          <InputSearchText />
          <button
            className='filter-btn tablet-mobile-view'
            onClick={() => setIsShowModal(true)}
          >
            <IconFilter />
          </button>
          <div className='item-filter'>
            <SelectLayout/>
          </div>
        </div>

        <div className='quick-booking-btn'>
          <button
            className={'common-btn'}
            onClick={onAdd}
          >
            <p>{commonLang('AddBooking')}</p>
            <IconTimeAndDate />
          </button>
        </div>
      </div>
    </div>
    <div className='item-full'>
      {viewType === CalendarViewType.DaysView && <DateViewPicker isNoSelect={true} />}
      {viewType === CalendarViewType.MemberView && <MemberHorizontalPicker />}
      {viewType === CalendarViewType.MemberView && <DateHorizontalPicker />}
      {viewType === CalendarViewType.DaysView && <DateHorizontalWeekPicker />}
    </div>
    <FilterModalMobile isShow={isShowModal} onClose={() => setIsShowModal(false)} />
  </MobileViewStyled>;
};

const TabletViewStyled = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
  .common-input-search {
    width:100%;
    input {
      width:100%;
    }
  }

  .quick-booking-btn {
    margin-left: 0px;
  }

  .form-search-filter {
    @media only screen and (max-width: 767.98px) {
      display: flex;
      gap: 10px;
    }
  }
  .item-full {
    text-align: center;
    background-color: #ffffff;
  }
`;
const MobileViewStyled = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 8px;

  .item-full {
    margin: 0px;
    text-align: center;
    padding: 8px 0px;
    background-color: #ffffff;
    border-radius: 12px;


  }

  .common-input-search {
    width: 100%;
    min-width: unset;
    min-width: 56px;
    input {
      width: 100%;
      min-width: 56px;
    }

  }

  .form-filter {
    display: flex;
    gap: 8px;
    flex-wrap: wrap;

    .form-filter-row {
      display: flex;
      gap: 8px;
      width: 100%;
      flex-wrap: nowrap;

      .inputSelect {
        width: 100%;
      }

      .ant-select {
        width: 100%;
      }

      .item-picker {
        width: 100%;
      }

      .ant-select-selector {
        min-width: unset !important;
      }
    }
  }
`;
