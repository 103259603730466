import styled from 'styled-components';
import cardReject from 'assets/images/checkout/card_reject.png';
import cardTimeout from 'assets/images/checkout/card_timeout.png';
import cardPending from 'assets/images/checkout/card_pending.png';
export type ICardType = 'process' | 'systemError' | 'wrongInfo' | 'notPair' | null;

/**
 * Card payment
 * @param type 
 * @returns 
 */
const CardPayment = ({ type }: { type: ICardType }) => {
  return (
    <CardPaymentStyled>
      {type === 'wrongInfo' && <div className='card-payment'>
        <div className='payment-image'>
          <img src={cardReject} alt='cardReject'></img>
        </div>
        <p className='payment-text'>
          Please double-check the card information and try again, or use a
          different payment method to complete your transaction.
        </p>
      </div>}
      {type === 'systemError' && <div className='card-payment'>
        <div className='payment-image'>
          <img src={cardTimeout} alt='cardTimeout'></img>
        </div>
        <p className='payment-text'>
          Oops! Something went wrong. Please double-check the card information
          or contact the issued bank for further information
        </p>
      </div>}
      {type === 'notPair' && <div className='card-payment'>
        <div className='payment-image'>
          <img src={cardTimeout} alt='cardTimeout'></img>
        </div>
        <p className='payment-text'>
          Oops! Something went wrong. Please double-check your Tyro connection!
        </p>
      </div>}
      {type === 'process' && <div className='card-payment'>
        <div className='payment-image'>
          <img src={cardPending} alt='cardPending'></img>
        </div>
        <p className='payment-text'>
          Please wait until the payment is processed.
        </p>
      </div>}
    </CardPaymentStyled>
  );
};

export default CardPayment;

const CardPaymentStyled = styled.div`
  .card-payment {
    display: flex;
    flex-direction: column;
    align-items: center;
    justity-content: center;
    gap: 35px;
    color: #363565;
    padding: 100px 0;
  }
  .payment-image {
  }
  .payment-text {
    font-size: 16px;
    text-align: center;
  }
`;
