import { POS_PRODUCT_INFO, api_key } from 'constants/pos';
import storage from 'utils/sessionStorage';
export enum EResultTransactionType {
  APPROVED = 'APPROVED',
  DECLINED = 'DECLINED',
  REVERSED = 'REVERSED',
  CANCELLED = 'CANCELLED',
  SYSTEM_ERROR = 'SYSTEM ERROR',

}
export type ITransactionCompleteCallbackResponse = {
  result: EResultTransactionType,
  transactionReference: string,
  authorisationCode: string;
  surchargeAmount: number;
  baseAmount: number;
};
type ITransactionCallback = {
  receiptCallback?: ((receipt: any) => undefined);
  transactionCompleteCallback?: ((receipt: ITransactionCompleteCallbackResponse) => void) | undefined;
}

/**
 * Handle use client tyro optimize
 * @returns 
 */
const useIClientTyroOptimize = () => {


  /**
   * 
   * @returns IClientWithUI
   */
  const getIClientWithUI = () => {

    const iClientLocal = storage.iClient.get();
    // @ts-ignore
    const iClient = iClientLocal && iClientLocal !== '' ? Object.assign(new window.TYRO.IClientWithUI(api_key, POS_PRODUCT_INFO), JSON.parse(iClientLocal)) : new window.TYRO.IClientWithUI(api_key, POS_PRODUCT_INFO);
    return iClient;
  };

  /**
   * 
   * @param mid: merchant ID
   * @param tid: terminal ID
   * @param receivedCallback 
   */
  const pairTerminal = (mid: string, tid: string, receivedCallback: (response: any) => void) => {
    try {
      // @ts-ignore
      const iClient = getIClientWithUI();
      iClient.pairTerminal(mid, tid, (response: any) => {
        receivedCallback(response);
        storage.iClient.set(JSON.stringify(iClient));
      });
    } catch (error) {
      console.log(error);
     }
  };

  /**
   * DO PURCHASE
   * @param amount: amount need purchase
   * @param callBack ITransactionCallback
   */
  const purchase = (amount: number, callBack?: ITransactionCallback) => {
    try {      
      const amountPurchase = (amount * 100).toFixed(0);    

      // @ts-ignore
      const iClient = getIClientWithUI();
      iClient.initiatePurchase({ amount: amountPurchase, cashout: 0, integratedReceipt: true }, {
        receiptCallback: function (receipt: any) {
          if (typeof callBack?.receiptCallback === 'function')
            callBack?.receiptCallback(receipt);
        },
        transactionCompleteCallback: function (response: any) {
          if (typeof callBack?.transactionCompleteCallback === 'function')
            callBack?.transactionCompleteCallback(response);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  /**
   * DO REFUND
   * @param amount: amount refund
   * @param callBack ITransactionCallback
   */
  const refund = (amount: number, callBack?: ITransactionCallback) => {

    const amountPurchase = (amount * 100).toFixed(0);

    try {      
      // @ts-ignore
      const iClient = getIClientWithUI();
      iClient.initiateRefund({ amount: amountPurchase, integratedReceipt: true }, {
        receiptCallback: function (receipt: unknown) {
          if (typeof callBack?.receiptCallback === 'function')
            callBack?.receiptCallback(receipt);
        },
        transactionCompleteCallback: function (response: any) {
          if (typeof callBack?.transactionCompleteCallback === 'function')
            callBack?.transactionCompleteCallback(response);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  return ({
    pairTerminal,
    refund,
    purchase,
  });
};

export default useIClientTyroOptimize;

