import { message } from 'antd';
import { IMessageProps } from '../type';
import { translate } from 'utils/moment/getSingleDateFormat';

/**
 * Handle custom error message
 * @param props 
 * @returns 
 */
const CustomErrorMessage = (props?: IMessageProps) => {
  const _text = (typeof props === 'string' ? props : props?.text) || 'An error occurred. Please try again';  
  return message.error({
    className: 'is-error',
    content: (
      <p>
        <span>{translate('common', 'message.error')}:</span>
        {_text}
      </p>
    ),
  });
};

export default CustomErrorMessage;
