import { Empty, Form, Input, Modal, Popover, Spin, message } from 'antd';
import styles from './index.module.scss';
import AvatarUser from './widgets/AvatarUser';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ModalConfirm from 'components/common/Modal/ModalConfirm';
import { ModalConfirmRef } from 'components/common/Modal/ModalConfirm';
import ImportModal from 'features/customers/components/ImportModal';
import { AxiosResponse } from 'axios';
import apisService from 'features/services/services/apis';
import serviceActions from 'features/services/services/actions';
import { get } from 'lodash';
import { useDispatch } from 'react-redux';
// import SetUp, { IFuncHandleSubmit } from 'features/services/components/ServicesForm';
import SetUpForm, { IFuncHandleSubmit } from '../Header/SetUpForm';
import { IconRight } from 'assets/svg';
import styled from 'styled-components';
import notificationSelectors from './service/selectors';
import apisNotification from './service/apis';
import notificationActions from './service/actions';
import { NavLink, useNavigate } from 'react-router-dom';
import getSingleDateShortFormat from 'utils/moment/getSingleDateShortFormat';
import moment from 'moment';
import AuthAvatar from 'assets/images/auth/auth_avatar.jpg';
import settingActions from 'features/settings/services/actions';
// import settingSelectors from 'features/settings/services/selectors';
// import { formatMoney } from 'utils/unit';
import userSelectors from 'features/users/services/selectors';

/**
 * get avatar
 * @returns 
 */
export const getAvatar = () => {
  const detail = userSelectors.getProfileStaffDetail();
  return detail?.avatar_url !== '' ? detail?.avatar_url : AuthAvatar;
};

/**
 * Header
 * @returns 
 */
const Header = () => {
  const [isShowSearchModal, setIsShowSearchModal] = useState(false);
  const [isShowNoti, setIsShowNoti] = useState(false);
  const headerNoti = useRef<any>(null);
  const selectors = notificationSelectors.getNoificationList();
  const loadingListNotification = notificationSelectors.loadingNotification();
  const { t: formLang } = useTranslation('form');
  const { t: headerLang } = useTranslation('header');
  const { t: notificationLang } = useTranslation('notification');

  // const merchantBalance = settingSelectors.getMerchantStripeBalance();

  const [open, setOpen] = useState(false);
  const modalConfirmRef = useRef<ModalConfirmRef>(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    dispatch(notificationActions.getListNotification.fetch());
    dispatch(settingActions.getMerchantStripeBalance.fetch());
  }, []);

  /**
   * handle open change
   * @param newOpen 
   */
  const handleOpenChange = (newOpen: boolean) => {
    setOpen(newOpen);
  };

  /**
   * onclick check view
   * @param e 
   * @param bookingId 
   * @param merchantId 
   */
  const onclickCheckView = async (e: number, bookingId: number, merchantId: number) => {
    if (bookingId || merchantId) {
      navigate(`/private/bookings/edit-booking?bookId=${bookingId}&isCheckIn=${merchantId}`);
    }
    await apisNotification.getCheckViewNotification(e);
    dispatch(notificationActions.getListNotification.fetch());
  };

  /**
   * on click delete notification
   */
  const onClickDeleteNotification = () => {
    setOpen(!open);
    modalConfirmRef.current?.show({
      title: headerLang('DeleteThisNotifications'),
      msg: headerLang('AreYouSureYouWantToDeleteThisNotifications'),
      submit: async () => {
        try {
          const res = await apisNotification.deleteNotification();
          const msg = get(res, 'data.message', '');
          if (msg) {
            message.success(msg);
            dispatch(notificationActions.getListNotification.fetch());
          } else {
            const msgError = get(res, 'data.error.message');
            if (msgError) message.error(msgError);
          }
        } catch (error) {
          message.error('An error occurred. Please try again');
        }
      },
      icon: 'delete',
    });
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClicks);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClicks);
    };
  }, [isShowNoti]);

  /**
   * handle out side clicks
   * @param event 
   */
  const handleOutsideClicks = (event: any) => {
    if (
      isShowNoti && headerNoti &&
      !headerNoti.current.contains(event.target)
    ) {
      setIsShowNoti(false);
    }
  };

  const { t, i18n } = useTranslation();

  /**
   * change language
   * @param languageValue 
   */
  const changeLanguage = (languageValue: any) => {
    i18n.changeLanguage(languageValue);
    document.title = t('MindHana');
    localStorage.setItem('selectedLanguage', languageValue);
  };

  /**
   * handle language change
   * @param e 
   */
  const handleLanguageChange = (e: any) => {
    const languageValue = e.target.value;
    changeLanguage(languageValue);
  };

  useEffect(() => {
    const storedLanguage = localStorage.getItem('selectedLanguage');
    const defaultLanguage = 'vi';
    const selectedLanguage = storedLanguage || defaultLanguage;

    if (selectedLanguage !== i18n.language) {
      changeLanguage(selectedLanguage);
    }

    /**
     * handle before unload
     */
    const handleBeforeUnload = () => {
      localStorage.setItem('selectedLanguage', i18n.language);
    };

    /**
     * handle page visibility change
     */
    const handlePageVisibilityChange = () => {
      if (document.visibilityState === 'hidden') {
        localStorage.setItem('selectedLanguage', i18n.language);
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    document.addEventListener('visibilitychange', handlePageVisibilityChange);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
      document.removeEventListener('visibilitychange', handlePageVisibilityChange);
    };
  }, [i18n.language]);
  // const [, setIsEdit] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [fileXsls, setFileXsls] = useState<any>();
  const [, setIsSpin] = useState<boolean>(false);
  const [isOpenImportModal, setIsOpenImportModal] = useState<boolean>(false);
  const [currService, setCurrService] = useState<any>();
  // const onAddItem = () => {
  //   setIsEdit(false);
  //   setIsModalOpen(true);
  // };
  /**
   * on submit import
   * @returns 
   */
  const _onSubmitImport = async () => {
    setIsSpin(true);
    try {
      const formData = new FormData();
      formData.append('file', fileXsls);

      const rest: AxiosResponse<{ message: string }> =
        await apisService.importServiceInfo(formData);

      const msg = `${(rest as any).data.data.customerSuccess} record inserted`;
      if (msg) {
        message.success(msg);
        setIsSpin(false);
        dispatch(
          serviceActions.getListPaginateServices.fetch({
            // page: Number(searchParams.get('page') ?? 1),
            // per_page: Number(searchParams.get('per_page') ?? 10),
          })
        );
        return true;
      }
      return false;
    } catch (error: any) {
      const response = get<Record<string, string[]>>(
        error,
        'response.data.errors',
        {}
      );

      const errors = Object.values(response);
      errors.map((value) => {
        message.error(get(value, [0], ''));
      });

      setIsSpin(false);
      return false;
    } finally {
      setFileXsls(undefined);
    }
  };
  // const handleFileChange = async (event: any) => {
  //   const formData = new FormData();
  //   setFileXsls(event.file);
  //   formData.append('file', event.target.files[0]);
  // };
  /**
   * handle cancel
   */
  const handleCancel = () => {
    setIsModalOpen(false);
    setCurrService(null);
  };

  /**
   * handle submit
   * @returns 
   */
  const handleSubmit: IFuncHandleSubmit = async () => {
    return null;
  };

  const { t: dateLang } = useTranslation('date');
  const ContentNotification = () => (
    <NotificationListStyled>
      <div>
        <button className={selectors.length > 0 ? 'notification-remove-btn' : 'dl-none'} onClick={() => onClickDeleteNotification()}></button>
        <p className='notification-title'>{headerLang('Notifications')}</p>
        <Spin spinning={loadingListNotification}>
          <ul className='notification-list'>
            {
              selectors.length > 0 ?
                selectors?.map((o, index) =>
                  <>
                    <li onClick={() => onclickCheckView(o.id, o.booking_id, o.merchant_id)} key={index}>
                      <div className={o.viewed == 0 ? 'icon' : 'no_icon'}></div>
                      <NotificationImageStyled>
                        <div className='notification-avatar'>

                          <div className='notification-img common-avatar'>
                            {o.full_name && Array.from(o.full_name)[0]}
                          </div>
                        </div>
                      </NotificationImageStyled>
                      <div>
                        <p className='notification-text'>
                          {/* <span>{o.full_name}</span> {o.notify_type} {' '} */}
                          <div dangerouslySetInnerHTML={{ __html: o.message }}></div>
                          {/* <span>{e.service}</span> */}
                        </p>
                        <div className='notification-time'>
                          <p>{getSingleDateShortFormat(moment(o.time), dateLang)} {headerLang('at')} {moment(o.time).format('HH:mm')}</p>
                          <span>{o.location_name}</span>
                        </div>
                      </div>
                    </li>
                  </>
                ) : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={notificationLang('NoNotification')} />
            }
          </ul>
        </Spin>
        <NavLink to={'notification'}>
          <div className={selectors.length > 0 ? 'notification-footer' : 'dl-none'}>
            <span>
              {headerLang('ViewAll')}{' '}
              <span
                style={{
                  marginLeft: '8px',
                  fontSize: '24px',
                  fontWeight: '400',
                }}
              >
                <IconRight />
              </span>
            </span>
          </div>
        </NavLink>
      </div>
    </NotificationListStyled>
  );
  const checkView = selectors.find(o => o.viewed === 0);
  return (
    <>
      <HeaderStyled>
        <div className={`${styles.header_wrapper} header-wrapper`}>
          {/* <div className="form-input-search">
            <span onClick={() => setIsShowSearchModal(true)}></span>
            <input type="text"></input>
          </div> */}
          <div
            className={`${styles.header_notification} ${isShowNoti && styles.active
              }`}
          >
            <ImportModal
              onSubmit={_onSubmitImport}
              // fileXsls={fileXsls}
              // isSpin={isSpin}
              handleCancel={() => setIsOpenImportModal(false)}
              // handleFileChange={handleFileChange}
              isOpenModal={isOpenImportModal}
            />
            {/* <IndexSetUpStyled>
              <button onClick={onAddItem} className={'common-btn'}>
                <div className='mobile'>
                  <IconBtnSetUp />
                  <span>{headerLang('Setup')}</span>
                </div>
                <IconInstance />
              </button>
              <div className='icon-help'>
                <IconHelpCircle />
              </div>
            </IndexSetUpStyled> */}
            {/* <div className={styles.header_wallet}>
              <span className={styles.wallet_text}>{headerLang('Wallet')}</span> <span className={styles.wallet_amount}>{formatMoney(merchantBalance || 0)}</span>
            </div> */}
            <SelectStyled>
              <select className='selectChange' value={i18n.language} onChange={handleLanguageChange}>
                <option value="en">EN</option>
                <option value="vi">VN</option>
              </select>
            </SelectStyled>
            <div
              className={`${styles.header_notification} ${styles.active
                }`}
            >
              <Popover
                content={<ContentNotification />}
                trigger="click"
                open={open}
                onOpenChange={handleOpenChange}
                placement='bottom'
                className={`
                btn-noti 
                ${styles.notification_icon} 
                ${checkView ? styles.notification_icon_active : ''} 
                `}
              >
              </Popover>
            </div>
          </div>
          <AvatarUser />
        </div>
        <Modal
          maskClosable={false}
          open={isShowSearchModal}
          onCancel={() => setIsShowSearchModal(false)}
          okButtonProps={{ style: { display: 'none' } }}
          width={574}
          footer={null}
          forceRender
          className='search-modal'
        >
          <h2 className='modal-title'>
            <p>Search</p>
          </h2>
          <Form data-form-testing='form-header' name='basic' className='modal-form' autoComplete='off'>
            <Input placeholder={formLang('SearchProfileLabel') || ''} />
            <p className='search-result-text'>
              Can not find the item you are searching
            </p>
          </Form>
        </Modal>
      </HeaderStyled>
      {isModalOpen && (
        <SetUpForm
          handleCancel={handleCancel}
          handleSubmit={handleSubmit}
          isModalOpen={isModalOpen}
          currService={currService}
        />
      )}
      <ModalConfirm ref={modalConfirmRef} />
    </>
  );
};

export default Header;

// const IndexSetUpStyled = styled.div`
// display: flex;
// justify-content: center;
// align-items: center;
// gap: 10px;
// .icon-help {
//   display: none;
//   @media only screen and (max-width: 767.98px) {
//     display: block;
//   }
// }
// .common-btn {
//   display: flex;
//   justify-content: space-between;
//   padding: 0 20px;
//   min-width: 159px;
//   height: 48px;
//   @media only screen and (max-width: 767.98px) {
//     min-width: 0px;
//     width: 32px;
//     height: 32px;
//     padding: 4px;
//     border-radius: 6px;
//   }
//   .mobile{
//     display: flex;
//     justify-content: space-between;
//     align-items:center;
//     gap: 20px;
//     @media only screen and (max-width: 767.98px) {
//       display: none;
//     }
//     svg {
//     display: block;
//     @media only screen and (max-width: 767.98px) {
//       display: none;
//     }
//   }
//   }
//   svg {
//     display: none;
//     @media only screen and (max-width: 767.98px) {
//       display: block;
//     }
//   }
// }
// `;

const SelectStyled = styled.div`
display: flex;
.selectChange {
  border: 0px;
  margin-left: 8px;
  background: #FFFFFF;
} 
`;

const NotificationListStyled = styled.div`
  
  background-color: var(--color-white);
  filter: drop-shadow(0px 4px 24px rgba(225, 225, 239, 0.6));
  border-radius: 6px;
  top: calc(100% + 8px);
  right: 0;
  padding: 12px;
  z-index: 101;

  @media only screen and (min-width: 1025px) {
    width: 520px;
  }

  @media only screen and (max-width: 767.98px) {
    right: 12px;
    width: calc(100% - 24px);
    top: 90px;
    width: 300px;
  }

  .dl-none {
    display: none;
  }

  .notification-remove-btn {
    display: block;
    width: 24px;
    height: 24px;
    background: var(--icon-remove-red) center/contain no-repeat;
    position: absolute;
    left: 16px;
    top: 12px;
  }

  .notification-title {
    text-align: center;
    padding: 12px 16px;
    font-weight: 600;
    font-size: 16px;
    color: var(--color-primary);
  }

  :where(.css-dev-only-do-not-override-1qkz0az).ant-popover {
    @media only screen and (max-width: 767.98px) {
      left: 0 !important;
      right: 0;
    }
  }

  .notification-list {
    overflow: scroll;
    /* height: 500px; */
    max-height: 500px;
    &::-webkit-scrollbar {
      width: 6px;
      height: 6px;
    }
  
    &::-webkit-scrollbar-track {
      border-radius: 10px;
    }
  
    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: #c3c2e0;
    }
    li {
      display: flex;
      gap: 16px;
      padding: 16px;
      border-bottom: 1px solid var(--color-white-02);
      position: relative;
      transition: all 0.3s ease;
      cursor: pointer;

      &:last-child {
        border-bottom: 1px solid var(--color-purple);
      }

      &:hover {
        opacity: 0.7;
      }

      .no_icon {
        content: "";
        display: block;
        width: 8px;
        height: 8px;
        position: absolute;
        left: 8px;
        top: 8px;
        border-radius: 50%;
      }

      .icon {
        content: "";
        display: block;
        width: 8px;
        height: 8px;
        background: var(--color-blue-02);
        border: 1px solid var(--color-blue-01);
        position: absolute;
        left: 8px;
        top: 8px;
        border-radius: 50%;
      }
    }
  }

  .notification-text {
    color: var(--color-primary);

    span {
      font-weight: 600;
    }
  }

  .notification-time {
    color: var(--color-purple-07);
    margin-top: 28px;
  }

  .notification-avatar {
    width: 30px;
    height: 30px;
    overflow: hidden;
    border-radius: 50%;

    &+div {
      flex: 1;
    }
  }

  .notification-footer {
    text-align: center;
    padding: 10px 16px 14px;

    span {
      font-weight: 600;
      font-size: 16px;
      text-align: center;
      color: var(--color-primary);
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &.is-all {
    position: static;
    width: 566px;
    padding: 0 20px;

    @include mobile {
      width: 100%;
    }

    .notification-title {
      text-align: left;
      border-bottom: 1px solid var(--color-white-01);
      padding: 20px 0;
      margin-bottom: 20px;
    }

    .notification-remove-btn {
      left: auto;
      right: 20px;
      top: 20px;
    }

    .notification-list {
      li {
        border-bottom: none;
      }
    }
  }
`;

const NotificationImageStyled = styled.div`
.notification-img {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #FFF;
}
`;


const HeaderStyled = styled.header`
    @media only screen and (max-width: 767.98px) {
      height: 100px;
      .header-wrapper {
        position: fixed;
        z-index: 999;
        width: 100%;
      }
    }
`;

export const IconInstance = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
      <path d="M10 4.66669V16.3334" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M4.16663 10.5H15.8333" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export const IconHelpCircle = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="17" viewBox="0 0 18 17" fill="none">
      <circle opacity="0.4" cx="8.99996" cy="8.50002" r="8.33333" fill="#595959" />
      <path fillRule="evenodd" clipRule="evenodd" d="M8.99992 4.95837C8.42462 4.95837 7.95825 5.42474 7.95825 6.00004C7.95825 6.34522 7.67843 6.62504 7.33325 6.62504C6.98807 6.62504 6.70825 6.34522 6.70825 6.00004C6.70825 4.73439 7.73427 3.70837 8.99992 3.70837C10.2656 3.70837 11.2916 4.73439 11.2916 6.00004C11.2916 6.45532 11.1582 6.88135 10.9281 7.2389C10.784 7.46285 10.6155 7.67666 10.4602 7.86973C10.4317 7.90505 10.4038 7.93961 10.3764 7.97358C10.2475 8.13313 10.1292 8.27958 10.0198 8.42985C9.75016 8.80045 9.62492 9.07895 9.62492 9.33337V9.75004C9.62492 10.0952 9.3451 10.375 8.99992 10.375C8.65474 10.375 8.37492 10.0952 8.37492 9.75004V9.33337C8.37492 8.66732 8.70193 8.11652 9.00905 7.69442C9.13882 7.51606 9.28103 7.34016 9.41034 7.1802C9.43624 7.14816 9.46162 7.11676 9.48629 7.08611C9.64061 6.89432 9.77198 6.72558 9.87692 6.5625C9.98109 6.40061 10.0416 6.20834 10.0416 6.00004C10.0416 5.42474 9.57522 4.95837 8.99992 4.95837Z" fill="#595959" />
      <path fillRule="evenodd" clipRule="evenodd" d="M9 11.2084C9.34518 11.2084 9.625 11.4882 9.625 11.8334V12.6667C9.625 13.0119 9.34518 13.2917 9 13.2917C8.65482 13.2917 8.375 13.0119 8.375 12.6667V11.8334C8.375 11.4882 8.65482 11.2084 9 11.2084Z" fill="#595959" />
    </svg>
  );
};

