
import { EPaymentMethod } from 'features/sales/services/constants';
import salesSelectors from 'features/sales/services/selectors';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { formatMoney } from 'utils/unit';

const ResultAmountRow = ({ }: { step: number }) => {
  const totalOriginal = salesSelectors.getOriginTotalPrice();
  const vouchersValue = salesSelectors.getVoucherValue();
  const voucherMix = salesSelectors.getMixPayments().find(o => o.type === EPaymentMethod.VOUCHER);
  const method = salesSelectors.getPaymentMethod();
  const { t: bookingLang } = useTranslation('booking');
  const getTotal = useMemo(() => {
    const voucherValue = method === EPaymentMethod.VOUCHER ? vouchersValue ?? 0 : voucherMix?.value ?? 0;
    return totalOriginal - voucherValue;
  }, [method, totalOriginal, vouchersValue, voucherMix]);

  return (
    <ResultAmountRowStyled>
      <p className='total'>
        {bookingLang('TOTAL')}<div className='form-note-mobile'>({bookingLang('AllPricesIncludeGST')})</div>
        <span>{formatMoney(getTotal > 0 ? getTotal : 0)}</span>
      </p>
      {/* <div className='form-note'>{bookingLang('AllPricesIncludeGST')}</div> */}
    </ResultAmountRowStyled>
  );
};

export default ResultAmountRow;

const ResultAmountRowStyled = styled.div`
.form-note-mobile {
  display: none;
  @media only screen and (max-width: 767.98px) {
      display: flex;
      align-items: center;
      font-style: italic;
      font-weight: 500;
      font-size: 14px;
      color: var(--color-purple-06);
      padding: 10px 0;
    }
}

.form-note {
  display: block;
  @media only screen and (max-width: 767.98px) {
    display: none;
  }
}
`;
