import React from 'react';
import { Modal } from 'antd';
import ProgramForm, { ProgramFormProps } from './Form';
import { useTranslation } from 'react-i18next';

interface Props extends ProgramFormProps {
  visible?: boolean;
  isEdit?: boolean;
}
const ProgramModal = ({ visible, ...rest }: Props) => {
  if (!visible) return null;
  const {t: loyaltyProgramLang} = useTranslation('loyaltyProgram');
  return (
    <Modal
      maskClosable={false}
      open={visible}
      onCancel={rest.onCancel}
      okButtonProps={{ style: { display: 'none' } }}
      width={574}
      footer={null}
      forceRender
      centered
    >
      <h2 className='modal-title'>
        <p>{!rest?.isEdit ? loyaltyProgramLang('AddProgram') : loyaltyProgramLang('EditProgram')} </p>
      </h2>
      <ProgramForm {...rest} />
    </Modal>
  );
};

export default ProgramModal;