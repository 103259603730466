import { Popover } from 'antd';
import IconBack from 'assets/svg/IconBack';
import { AxiosResponse } from 'axios';
import message from 'components/common/Message';
import ModalSendInvoiceEmail, { ICustomerSendEmail } from 'components/common/Modal/ModalSendInvoiceEmail';
import { PATH } from 'constants/path.enum';
import { ActionsStyled, IconDash, IconSendInvoice } from 'features/checkout/widgets/ButtonHandler';
import salesActions from 'features/sales/services/actions';
import apisSales from 'features/sales/services/apis';
import salesSelectors from 'features/sales/services/selectors';
import { IVoucherItemResData } from 'features/vouchers/services/types/voucher';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useSetLoadingPage } from 'services/UI/LoadingPage';
import { useAppDispatch } from 'store/hooks';
import Purchase from './Purchase';
import { useTranslation } from 'react-i18next';
import { translate } from 'utils/moment/getSingleDateFormat';

type IButtonHandlerProps = {
  step: number,
  setStep: (val: number) => void;
};
const ButtonHandler = ({ step, setStep }: IButtonHandlerProps) => {
  const customerInfo = salesSelectors.getCustomer();
  const selectedServices = salesSelectors.getSelectedServices();
  const selectedVouchers = salesSelectors.getSelectedVouchers();
  const totalPrice = salesSelectors.getOriginTotalPrice();
  const invoiceResult = salesSelectors.getInvoiceResult();
  const{t: bookingLang} = useTranslation('booking');
  const{t: salesLang} = useTranslation('sales');
  const [isOpenPopover, setIsOpenPopover] = useState<boolean>(false);
  const [isSendMailDone, setIsSendMailDone] = useState<boolean>(false);

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handleCancel = () => {
    setIsOpen(false);
  };

  const dispatch = useAppDispatch();
  const setLoadingPage = useSetLoadingPage();

  const onContinue = async () => {
    if(! customerInfo) {
      message.error(translate('common', 'message.noSelectCustomer'));
      window.scrollTo(0, 0);
      return;
    }

    if (selectedServices.length === 0 && selectedVouchers.length == 0) {
      message.error(translate('common', 'message.noSelectServiceAndVoucher'));
      
      return;
    }

    if (!customerInfo?.customer_code) return;
    dispatch(salesActions.resetFormPayment());
    dispatch(salesActions.reviewPointCustomerWillReceive.fetch(totalPrice));
    dispatch(salesActions.getRewardOfCustomer.fetch({ id: customerInfo?.customer_code?.replace('#', '') }));
    setLoadingPage(true);
    try {
      const res: AxiosResponse<{ data: IVoucherItemResData[] }> = await apisSales.getVoucherOfCustomer(customerInfo?.customer_code);
      if (res?.data?.data) {
        dispatch(salesActions.getVoucherOfCustomer.success(res?.data?.data));
      }
    } catch (error) { }
    finally {
      setLoadingPage(false);
      setStep(step + 1);
    }
  };

  const onBack = () => {
    dispatch(salesActions.resetFormPayment());
    setStep(step - 1);
  };

  const onSuccessDone = () => {
    dispatch(salesActions.getTotalPointCustomer.fetch({
      is_walkin_in: !!customerInfo?.is_walkin_in,
      customer_code: customerInfo?.customer_code?.replace('#', '') ?? '',
    }));
    setStep(3);
  };
  
  return (
    <div className='search-wrapper'>
      {step !== 1 && step !== 3 && (
        <button
          className='icon-back'
          onClick={onBack}
        >
          <IconBack />
        </button>
      )}
      {step === 1 && (
        <button
          type='submit'
          className={'form-booking-submit'}
          onClick={onContinue}
          // disabled={!customerInfo || (selectedServices.length === 0 && selectedVouchers.length == 0)}
        >
          {bookingLang('Continue')}
        </button>
      )}
      {step === 2 && <Purchase onSuccess={onSuccessDone} />}
      {step === 3 && (
        <>
          {!isSendMailDone && <Popover
            trigger={'click'}
            placement='topRight'
            open={isOpenPopover}
            onOpenChange={setIsOpenPopover}
            content={
              <ActionsStyled>
                <ul className='more-settings'>
                  <li onClick={() => {
                    setIsOpenPopover(false);
                    setIsOpen(true);
                  }}>
                    <IconSendInvoice />
                    {bookingLang('SendInvoice')}
                  </li>
                  {/* <li className='icon-refund'>
                    <IconRefund />
                    Refund
                  </li> */}
                </ul>
              </ActionsStyled>
            }>
            <button
              onClick={() => setIsOpenPopover(true)}
              className='icon-back'
            >
              <IconDash />
            </button>
          </Popover>}
          <Link to={PATH.sales} reloadDocument className='form-booking-submit link'>
            {salesLang('MakeNewSales')}
          </Link>
        </>
      )}
      {isOpen && <ModalSendInvoiceEmail
        handleCancel={handleCancel}
        isOpen={isOpen}
        onDone={() => setIsSendMailDone(true)}
        customer={customerInfo as ICustomerSendEmail}
        invoice_id={invoiceResult?.invoice_no ?? ''} />}
    </div>
  );
};

export default ButtonHandler;
