import { get } from 'lodash';
import { RootState } from 'store';
import { useAppSelector } from 'store/hooks';
import { IApiPromotionListReq } from '../types/api';
import uiSelector from 'services/UI/selectors';
import { PATH_LOADING } from './constants';
import { IPromotionItemResData } from '../types/promotion';

type MyState = RootState['promotion'];

const getCurrentState = (state: RootState): MyState => state.promotion;

export const getPureParams = (root: RootState) => {
    const state = getCurrentState(root);
    return state.params as IApiPromotionListReq;
};
const selector = <T = keyof MyState>(key: T, defaultValue?: any) => useAppSelector(state => get(getCurrentState(state), key as any, defaultValue));

const getConfigsData = () => selector('configsData') as MyState['configsData'];

const getParams = () => selector('params') as MyState['params'];

const getLstPromotions = () => selector('promotions') as MyState['promotions'];

const getLstPromotionPagination = () => selector('pagination') as MyState['pagination'];

const getDetailPromotion = () => selector('detail') as IPromotionItemResData;

const generatePromotionCode = () => selector('promotionCode') as MyState['promotionCode'] ;

const getLoadingDetailPromotion = () => uiSelector.getLoading(PATH_LOADING.getDetailPromotion) as boolean;

const getLoadingLstPromotions = () => uiSelector.getLoading(PATH_LOADING.getListPromotions) as boolean;

const getLoadingPromotionActions = () => uiSelector.getLoading(PATH_LOADING.promotionActions) as boolean;

const getLoadingGeneratePromotionCodeActions = () => uiSelector.getLoading(PATH_LOADING.generatePromotionCode) as boolean;

const getPromotionAuto = () => selector('promotionAutoOffer') as MyState['promotionAutoOffer'];

const promotionSelectors = {
    getParams,
    getConfigsData,
    getLstPromotions,
    getLstPromotionPagination,
    getLoadingLstPromotions,
    getLoadingPromotionActions,
    getDetailPromotion,
    getLoadingDetailPromotion,
    generatePromotionCode,
    getLoadingGeneratePromotionCodeActions,
    getPromotionAuto
};

export default promotionSelectors;
