import React from 'react';

const IconInfoPayment = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
            <path d="M12.3535 22.1797C17.8764 22.1797 22.3535 17.7025 22.3535 12.1797C22.3535 6.65684 17.8764 2.17969 12.3535 2.17969C6.83067 2.17969 2.35352 6.65684 2.35352 12.1797C2.35352 17.7025 6.83067 22.1797 12.3535 22.1797Z" stroke="#4860CC" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M12.3535 16.1797V12.1797" stroke="#4860CC" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M12.3535 8.17969H12.3635" stroke="#4860CC" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    );
};

export default IconInfoPayment;