import React from 'react';

const IconLogoZLPSetting = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">
            <path d="M8.94555 7.88562L44.134 2.74282C42.1681 1.02856 39.6085 0 36.8113 0H11.1885C5.00054 0 0 5.01093 0 11.1823V36.7908C0 42.3423 4.05057 46.9577 9.35457 47.828L4.71027 13.5823C4.31445 10.8526 6.24077 8.28122 8.94555 7.88562Z" fill="#118ACB" />
            <path d="M44.1341 2.74414L8.94558 7.88693C6.2408 8.28253 4.31447 10.8539 4.71029 13.5968L9.35459 47.8425C9.94832 47.9348 10.5684 48.0007 11.1886 48.0007H36.8114C42.9994 48.0007 47.9999 42.9898 47.9999 36.8185V11.1836C47.9999 7.821 46.509 4.78807 44.1341 2.74414Z" fill="white" />
            <path d="M44.134 2.74414L44.0152 2.77051C46.4297 4.88038 47.8811 7.97924 47.8811 11.1968V36.7657C47.8811 42.8843 42.8937 47.8689 36.7717 47.8689H11.1753C10.5948 47.8689 9.92186 47.8029 9.32812 47.7106L9.34132 47.8425C9.96144 47.948 10.5552 48.0007 11.1621 48.0007H36.7453C42.9465 48.0007 47.9866 42.9634 47.9866 36.7657V11.1836C47.9998 7.91331 46.6013 4.88038 44.134 2.74414Z" fill="#B3B3B3" />
            <path d="M29.8979 15.9318C29.6736 15.7076 29.4229 15.5889 29.0667 15.5889C28.5917 15.5889 28.2355 15.8131 28.0244 16.2878C27.5494 15.7208 26.8897 15.457 26.0585 15.457C25.0557 15.457 24.2245 15.8526 23.5252 16.5911C22.8259 17.3823 22.5225 18.2922 22.5225 19.3867C22.5225 20.4811 22.8655 21.4306 23.5252 22.1822C24.2245 22.9734 25.0557 23.3163 26.0585 23.3163C26.8897 23.3163 27.5362 23.0525 28.0244 22.4855C28.2487 22.9602 28.5917 23.1844 29.0667 23.1844C29.4097 23.1844 29.6736 23.0525 29.8979 22.8416C30.1222 22.6306 30.2014 22.3668 30.2014 22.0108V16.6438C30.241 16.4592 30.109 16.1559 29.8979 15.9318ZM27.5362 20.7713C27.2723 21.1141 26.8765 21.2987 26.4015 21.2987C25.9265 21.2987 25.5307 21.1273 25.2668 20.7713C25.0029 20.4284 24.8314 19.9801 24.8314 19.413C24.8314 18.8856 24.9634 18.4504 25.2668 18.1075C25.5307 17.7647 25.9265 17.5801 26.4015 17.5801C26.8765 17.5801 27.2723 17.7515 27.5362 18.1075C27.8001 18.4504 27.9716 18.8987 27.9716 19.413C27.932 19.9405 27.8001 20.4416 27.5362 20.7713Z" fill="#118ACB" />
            <path d="M32.8136 22.907C32.5893 23.1312 32.3386 23.2498 31.9823 23.2498C31.6393 23.2498 31.3754 23.118 31.1511 22.907C30.9268 22.6828 30.8477 22.4323 30.8477 22.0762V12.4763C30.8477 12.1731 30.94 11.9093 31.1511 11.6456C31.3754 11.4214 31.6261 11.3027 31.9823 11.3027C32.3254 11.3027 32.5893 11.4346 32.8136 11.6456C33.0379 11.8698 33.117 12.1203 33.117 12.4763V22.0762C33.117 22.4323 33.0379 22.696 32.8136 22.907Z" fill="#118ACB" />
            <path d="M40.3608 16.6321C39.6219 15.8409 38.6983 15.498 37.5636 15.498C36.4289 15.498 35.5186 15.8936 34.7665 16.6321C34.0672 17.3706 33.6714 18.3332 33.6714 19.4672C33.6714 20.6013 34.0144 21.5112 34.7665 22.3024C35.4658 23.0408 36.4289 23.4364 37.5636 23.4364C38.6983 23.4364 39.6087 23.0408 40.3608 22.3024C41.06 21.5639 41.4031 20.6013 41.4031 19.5068C41.4559 18.3332 41.1128 17.3837 40.3608 16.6321ZM38.7511 20.7727C38.4872 21.1156 38.0914 21.3002 37.6164 21.3002C37.1414 21.3002 36.7456 21.1287 36.4817 20.7727C36.2178 20.4299 36.0463 19.9815 36.0463 19.4145C36.0463 18.887 36.1783 18.4519 36.4817 18.109C36.7456 17.7662 37.1414 17.5815 37.6164 17.5815C38.0914 17.5815 38.4872 17.753 38.7511 18.109C39.015 18.4519 39.1865 18.9002 39.1865 19.4145C39.1469 19.9419 39.015 20.443 38.7511 20.7727Z" fill="#118ACB" />
            <path d="M21.2554 15.7591C22.047 14.7173 22.4824 14.0184 22.4824 13.6624C22.4824 12.8316 21.9547 12.3965 20.9124 12.3965H15.3709C14.9355 12.3965 14.5792 12.4888 14.3681 12.6998C14.1438 12.924 14.0251 13.1745 14.0251 13.491C14.0251 13.8338 14.157 14.0976 14.3681 14.2822C14.5924 14.5063 14.9355 14.5855 15.3709 14.5855H19.1707L14.289 20.8623C13.9855 21.2579 13.814 21.6535 13.814 21.9568C13.814 22.8667 14.4209 23.315 15.6479 23.315H21.361C22.2713 23.315 22.7199 22.9194 22.7199 22.181C22.7199 21.3898 22.2845 21.0469 21.361 21.0469H17.1653L21.2554 15.7591Z" fill="#118ACB" />
            <path d="M18.5378 29.3418H17.0996V32.3105H18.5378C18.9732 32.3105 19.3294 32.1785 19.5801 31.8751C19.844 31.6112 19.9759 31.2153 19.9759 30.8327C19.9759 30.3973 19.844 30.0411 19.5801 29.7904C19.3294 29.4737 18.9864 29.3418 18.5378 29.3418Z" fill="#39B54A" />
            <path d="M25.6623 32.0723C25.2269 32.0723 24.8706 32.2438 24.6199 32.5473C24.3561 32.8903 24.2241 33.2861 24.2241 33.7743C24.2241 34.2625 24.3561 34.6847 24.6199 35.0013C24.8838 35.3444 25.2269 35.4763 25.6623 35.4763C26.0977 35.4763 26.4539 35.3048 26.7046 35.0013C26.9685 34.6583 27.1004 34.2625 27.1004 33.7743C27.1004 33.2993 26.9685 32.8639 26.7046 32.5473C26.4407 32.2438 26.0977 32.0723 25.6623 32.0723Z" fill="#39B54A" />
            <path d="M35.7029 25.1094H15.0542C14.3549 25.1094 13.7876 25.6764 13.7876 26.3753V38.4543C13.7876 39.1532 14.3549 39.7202 15.0542 39.7202H31.3357C31.1641 39.5488 31.0718 39.3773 31.0718 39.1532C31.0718 38.9817 31.1114 38.8103 31.2037 38.5861L31.9954 36.8851L29.8579 31.5576C29.8183 31.4258 29.7656 31.2544 29.7656 31.0829C29.7656 30.8588 29.8579 30.6478 30.069 30.5159C30.2406 30.3445 30.4648 30.2522 30.676 30.2522C31.1509 30.2522 31.4676 30.4763 31.6391 30.9511L32.9453 34.5774L34.3439 30.9511C34.5154 30.5159 34.8189 30.2522 35.3071 30.2522C35.5314 30.2522 35.7425 30.3445 35.914 30.5159C36.0855 30.6873 36.2174 30.8588 36.2174 31.0829C36.2174 31.2544 36.1779 31.4258 36.1251 31.5576L32.9849 39.1927C32.8926 39.4169 32.8134 39.5883 32.6814 39.7202H35.6897C36.389 39.7202 36.9563 39.1532 36.9563 38.4543V26.3753C36.9563 25.6368 36.389 25.1094 35.7029 25.1094ZM21.1235 33.2192C20.4638 33.8258 19.6457 34.0895 18.6826 34.0895H17.1125V36.3576C17.1125 36.7004 17.0201 36.9642 16.809 37.1488C16.6375 37.3202 16.3736 37.4521 16.0702 37.4521C15.7667 37.4521 15.5028 37.3598 15.3313 37.1488C15.1598 36.9774 15.0278 36.6741 15.0278 36.3576V28.5115C15.0278 27.7731 15.3709 27.417 16.1229 27.417H18.6166C19.6589 27.417 20.4902 27.7203 21.1894 28.3269C21.8491 28.9335 22.1922 29.7643 22.1922 30.7269C22.1658 31.8082 21.8228 32.5994 21.1235 33.2192ZM29.1982 36.318C29.1982 36.6213 29.1059 36.8455 28.8948 37.0565C28.6705 37.2807 28.4594 37.3598 28.1559 37.3598C27.7205 37.3598 27.3643 37.1356 27.1531 36.7004C26.7177 37.2279 26.1108 37.4916 25.3192 37.4916C24.4088 37.4916 23.6171 37.1488 23.0102 36.4499C22.4033 35.751 22.0471 34.8807 22.0471 33.8389C22.0471 32.7972 22.3505 31.9664 23.0102 31.228C23.6171 30.5291 24.4088 30.1862 25.3192 30.1862C26.1108 30.1862 26.6782 30.45 27.1531 30.9774C27.3774 30.5423 27.6809 30.3181 28.1559 30.3181C28.4594 30.3181 28.7232 30.4104 28.8948 30.6214C29.1191 30.8456 29.1982 31.0566 29.1982 31.3598V36.318Z" fill="#39B54A" />
        </svg>
    );
};

export default IconLogoZLPSetting;