import Logo from 'assets/icons/sidebar_logo.svg';
// import IconHome from 'assets/svg/IconHome';
// import IconContents from 'assets/svg/IconContents';
// import IconBill from 'assets/svg/IconBill';
// import IconCalendar from 'assets/svg/IconCalendar';
// import IconChart from 'assets/svg/IconChart';
// import IconCustomers from 'assets/svg/IconCustomers';
// import IconHeart from 'assets/svg/IconHeart';
// import IconMap from 'assets/svg/IconMap';
// import IconMore from 'assets/svg/IconMore';
// import IconSettings from 'assets/svg/IconSettings';
// import IconSpa from 'assets/svg/IconSpa';
// import IconFeature from 'assets/svg/IconFeature';
// import IconPersonnel from 'assets/svg/IconPersonnel';
// import IconLocation from 'assets/svg/IconLocation';
// import IconStar from 'assets/svg/IconStar';
// import IconUsers from 'assets/svg/IconUsers';
// import IconVoucher from 'assets/svg/IconVoucher';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, useLocation } from 'react-router-dom';
import { PATH } from '../../../constants/path.enum';
import NavItem from './components/NavItem';
import styles from './index.module.scss';
import {
  IconHome, IconSrvice, IconCalendar, IconCustomers,
  IconSettings, IconPersonnel, IconLocation, IconInvoice, IconStar, IconAnnouncement, IconVoucher, IconHeart, IconUsers
} from 'assets/svg/index';
import { RightOutlined } from '@ant-design/icons';
import IconSales from 'assets/svg/IconSales';
import { AnalyticPermission, CustomerPermission, EmployeePermission, InvoicePermission, LocationPermission, ModulePermission, ProgramPermission, PromotionPermission, SalesPermission, ServicePermission, SettingPermission, VoucherPermission, WorkingHourPermission } from 'features/packagePermission/services/types/permission';

import Can from 'features/packagePermission/components/Can';
import IconAnalytic from 'assets/svg/IconAnalytic';

type Props = {
  setIsShowSidebar: any;
  isShowSidebar: boolean;
};

/**
 * Sidebar
 * @param props 
 * @returns 
 */
const Sidebar = (props: Props) => {
  const { isShowSidebar, setIsShowSidebar } = props;
  const { t: commonLang } = useTranslation('common');
  const location = useLocation();

  useEffect(() => {
    setIsShowSidebar(false);
  }, [location]);

  /**
   * get navbar
   * @returns 
   */
  const getNavbar = () => {
    return [
      {
        text: commonLang('Sidebar.Dashboard'),
        // path: PATH.bookings,
        path: '/private/dashboards',
        icon: <IconHome />,
        module: ModulePermission.ANALYTICS,
        permission: AnalyticPermission.VIEW_ANALYTIC,
        dataTesting: 'menu-analytic',
      },
      {
        text: commonLang('Sidebar.Bookings'),
        path: PATH.bookings,
        icon: <IconCalendar />,
        dataTesting: 'menu-booking',

      },
      {
        text: commonLang('Sidebar.Sales'),
        path: PATH.sales,
        icon: <IconSales />,
        module: ModulePermission.SALES,
        permission: SalesPermission.MANAGE_SALE,
        dataTesting: 'menu-sale',

      },
      {
        text: commonLang('Sidebar.Services'),
        path: PATH.services,
        icon: <IconSrvice />,
        icon_right: <RightOutlined />,
        module: ModulePermission.SERVICES,
        permission: ServicePermission.VIEW_SERVICE,
        dataTesting: 'menu-service',
        childrens: [
          {
            text: commonLang('Sidebar.List'),
            path: PATH.services,
            dataTesting: 'submenu-service-list',

          },
          // {
          //   text: commonLang('Sidebar.Directory'),
          //   path: PATH.categores,
          // },
        ],
      },
      //  Note
      // {
      //   text: commonLang('Sidebar.Products'),
      //   path: PATH.customers,
      //   icon: <IconProduct />,
      //   icon_right: <RightOutlined />,
      //   childrens: [
      //     {
      //       text: commonLang('Sidebar.ListProduct'),
      //       path: PATH.list_product
      //     },
      //     {
      //       text: commonLang('Sidebar.Categores'),
      //       path: PATH.product_category
      //     },
      //     {
      //       text: commonLang('Sidebar.Brands'),
      //       path: PATH.brands
      //     },
      //     {
      //       text: commonLang('Sidebar.Supplier'),
      //       path: PATH.supplier
      //     },
      //   ],
      // },
      {
        text: commonLang('Sidebar.Customers'),
        path: PATH.customers,
        icon: <IconCustomers />,
        module: ModulePermission.CUSTOMERS,
        permission: CustomerPermission.VIEW_CUSTOMER,
        dataTesting: 'menu-customer',

      },
      {
        text: commonLang('Sidebar.Locations'),
        path: PATH.locations,
        icon: <IconLocation />,
        module: ModulePermission.LOCATIONS,
        permission: LocationPermission.VIEW_LOCATION,
        dataTesting: 'menu-location',

      },
      {
        text: commonLang('Sidebar.Personnel'),
        path: PATH.users,
        icon: <IconPersonnel />,
        module: ModulePermission.EMPLOYEES,
        permission: EmployeePermission.VIEW_EMPLOYEE,
        dataTesting: 'menu-user',


        // icon_right: <IconRight />,
        // childrens: [
        //   {
        //     text: commonLang('Sidebar.Staff'),
        //     path: PATH.users,
        //   },
        //   // {
        //   //   text: commonLang('Sidebar.WorkingHours'),
        //   //   path: PATH.promotions,
        //   // },
        //   // {
        //   //   text:  commonLang('Sidebar.TimeOff'),
        //   //   path: PATH.vouchers,
        //   // },
        // ],
      },
      // {
      //   text: commonLang('Sidebar.Invoices'),
      //   path: PATH.invoices,
      //   icon: <IconBill />,
      // },
      // {
      //   text: commonLang('Sidebar.More'),
      //   path: PATH.more,
      //   icon: <IconMore />,
      //   childrens: [
      //     {
      //       text: commonLang('Sidebar.Reviews'),
      //       path: PATH.reviews,
      //       icon: <IconStar />,
      //     },
      //     {
      //       text: commonLang('Sidebar.Promotions'),
      //       path: PATH.promotions,
      //       icon: <IconAnnouncement />,
      //     },
      //     {
      //       text: commonLang('Sidebar.Vouchers'),
      //       path: PATH.vouchers,
      //       icon: <IconVoucher />,
      //     },
      //     {
      //       text: commonLang('Sidebar.Locations'),
      //       path: PATH.locations,
      //       icon: <IconMap />,
      //     },
      //     {
      //       text: commonLang('Sidebar.LoyaltyProgram'),
      //       path: PATH.loyaltyProgram,
      //       icon: <IconHeart />,
      //     },
      //     {
      //       text: commonLang('Sidebar.Users'),
      //       path: PATH.users,
      //       icon: <IconUsers />,
      //     },
      //   ],
      // },
      {
        text: commonLang('Statistical'),
        path: PATH.analytics_overview,
        icon: <IconAnalytic />,
        icon_right: <RightOutlined />,
        dataTesting: 'menu-statistical',

        childrens: [
          {
            text: commonLang('Overview'),
            path: PATH.analytics_overview,
            dataTesting: 'submenu-statistical-overview',

          },
          // {
          //   text: commonLang('Sidebar.Directory'),
          //   path: PATH.categores,
          // },
        ],
      },
      {
        text: commonLang('Sidebar.Invoices'),
        path: PATH.invoices,
        icon: <IconInvoice />,
        module: ModulePermission.INVOICES,
        permission: InvoicePermission.VIEW_INVOICE,
        dataTesting: 'menu-invoice',
      },
     
      {
        text: commonLang('Sidebar.Reviews'),
        path: PATH.reviews,
        icon: <IconStar />,
        module: ModulePermission.SETTINGS,
        permission: SettingPermission.SETTING_NOTIFICATION,
        dataTesting: 'menu-review',

      },
      {
        text: commonLang('Sidebar.Promotions'),
        path: PATH.promotions,
        icon: <IconAnnouncement />,
        module: ModulePermission.PROMOTIONS,
        permission: PromotionPermission.VIEW_PROMOTION,
        dataTesting: 'menu-promotions',

      },
      {
        text: commonLang('Sidebar.Vouchers'),
        path: PATH.vouchers,
        icon: <IconVoucher />,
        module: ModulePermission.VOUCHERS,
        permission: VoucherPermission.VIEW_VOUCHER,
        dataTesting: 'menu-vouchers',
      },
      {
        text: commonLang('Sidebar.LoyaltyProgram'),
        path: PATH.loyaltyProgram,
        icon: <IconHeart />,
        module: ModulePermission.PROGRAMS,
        permission: ProgramPermission.VIEW_PROGRAM,
        dataTesting: 'menu-promotion',

      },
      {
        text: commonLang('Sidebar.WorkingHour'),
        path: PATH.workingHour,
        icon: <IconUsers />,
        module: ModulePermission.WORKING_HOURS,
        permission: WorkingHourPermission.VIEW_WORKING_HOUR,
        dataTesting: 'menu-working-hour',

      },
      {
        text: commonLang('Sidebar.TimeSchedule'),
        path: PATH.timeSchedule,
        icon: <IconUsers />,
        module: ModulePermission.WORKING_HOURS,
        permission: WorkingHourPermission.VIEW_WORKING_HOUR,
        dataTesting: 'menu-time-schedule',

      },
       {
        text: commonLang('Sidebar.Settings'),
        path: '',
        icon: <IconSettings />,
        module: ModulePermission.SETTINGS,
        icon_right: <RightOutlined />,
        dataTesting: 'menu-setting',
        childrens: [
          {
            text: commonLang('Sidebar.Setting.setting_general'),
            path: PATH.setting_general,
            icon: <IconStar />,
            permission: SettingPermission.MANAGE_GENERAL_SETTING,
            dataTesting: 'submenu-setting-general',

          },
          {
            text: commonLang('Sidebar.Setting.setting_booking'),
            path: PATH.setting_booking,
            icon: <IconStar />,
            permission: SettingPermission.MANAGE_BOOKING_SETTING,
            dataTesting: 'submenu-setting-booking',

          },
          {
            text: commonLang('Sidebar.Setting.setting_customer_link'),
            path: PATH.setting_customer_link,
            icon: <IconStar />,
            permission: SettingPermission.MANAGE_BOOKING_LINK_SETTING,
            dataTesting: 'submenu-setting-booking-link',


          },
          // {
          //   text: commonLang('Sidebar.Setting.setting_service_charge'),
          //   path: PATH.setting_service_charge,
          //   icon: <IconStar />,
          //   permission: SettingPermission.SERVICE_CHARGE,

          // },
          {
            text: commonLang('Sidebar.Setting.setting_closed_period'),
            path: PATH.setting_closed_period,
            icon: <IconStar />,
            permission: SettingPermission.MANAGE_CLOSED_PERIODS_SETTING,
            dataTesting: 'submenu-setting-closed-period',
          },
          {
            text: commonLang('Sidebar.Setting.setting_notification'),
            path: PATH.setting_notification,
            icon: <IconStar />,
            permission: SettingPermission.SETTING_NOTIFICATION,
            dataTesting: 'submenu-setting-notification',
          },
          {
            text: commonLang('Sidebar.Setting.setting_cancellation'),
            path: PATH.setting_cancellation,
            icon: <IconStar />,
            permission: SettingPermission.MANAGE_CANCELLATION_REASON_SETTING,
            dataTesting: 'submenu-setting-cancellation',

          },
          // {
          //   text: commonLang('Sidebar.Setting.setting_auto_message'),
          //   path: PATH.setting_auto_message,
          //   icon: <IconStar />,
          //   permission: SettingPermission.AUTO_MESSAGE,

          // },
          {
            text: 'Quyền nhân viên',
            path: PATH.setting_role,
            icon: <IconStar />,
            permission: SettingPermission.STAFF_PERMISSION,
            dataTesting: 'submenu-setting-role',


          },

        ]
      },
    ];
  };

  /**
   * active style
   */
  const activeStyle = {
    background: '#1B9AAA',
    color: '#ffffff',
  };

  /**
   * active style children
   */
  const activeStyle_children = {
    color: '#ffffff',
    // background: '#1B9AAA',
  };

  

  return (
    <>
      <button
        onClick={() => setIsShowSidebar(!isShowSidebar)}
        className={styles.aside_toggle}
      ></button>
      <aside
        className={`${styles.aside} ${isShowSidebar ? styles.aside_show : undefined}`}
      >
        <div className={styles.aside_inner}>
          <div className={styles.aside_close}>
            <img src={Logo} alt='N'></img>
          </div>
          <div className={styles.aside_logo}>
            <img src={Logo} alt='N'></img>
          </div>
          <ul className={styles.list_navbar}>
            {getNavbar().map((nav) => {
              const { text, path, icon, childrens, module, permission, dataTesting } = nav;
              return (
                <Can module={module} permission={permission} key={path}>
                  <li  data-menu-testing={dataTesting} className={`${styles.navbar_item}`}>
                    {!childrens ? (
                      <NavLink
                        to={path}
                        style={({ isActive }) =>
                          isActive ? activeStyle : undefined
                        }
                        className={({ isActive }) =>
                          isActive ? styles.navbar_link_active : undefined
                        }
                      >
                        {icon}
                        <span>{text}</span>
                      </NavLink>
                    ) : (
                      <NavItem data={nav} isNavShow={isShowSidebar} />
                    )}
                    {childrens && (
                      <ul className={styles.navbar_sub_menu}>
                        {childrens.map((children, index) => {
                          //@ts-ignore
                          const { text, path, permission, dataTesting } = children;
                          return (
                            <Can key={index} module={module} permission={permission}>
                              <li data-submenu-testing={dataTesting} key={index} className={`${styles.navbar_item_childrens}`}>
                                <NavLink
                                  to={path}
                                  style={({ isActive }) =>
                                    isActive ? activeStyle_children : undefined
                                  }
                                  className={({ isActive }) =>
                                    isActive ? styles.navbar_link_active_childrens : undefined
                                  }
                                >
                                  {/* {icon} */}
                                  <span>{text}</span>
                                </NavLink>
                              </li>
                            </Can>
                          );
                        })}
                      </ul>
                    )}
                  </li>
                </Can>
              );
            })}
          </ul>
        </div>
      </aside>
      <div
        className={styles.aside_overlay}
        onClick={() => setIsShowSidebar(false)}
      ></div>
    </>
  );
};

export default Sidebar;

