import { Button, ConfigProvider, Form, Modal, TimePicker } from 'antd';
import DatePicker from 'components/common/DatePickerInput';
import dayjs, { Dayjs } from 'dayjs';
import salesSelectors from 'features/sales/services/selectors';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import 'dayjs/locale/vi';
import viVN from 'antd/lib/locale/vi_VN';
import enEL from 'antd/lib/locale/en_US';

type IModalEditBookingTimeProps = {
  visible?: boolean;
  onCancel?: () => void;
  onSubmit?: (dateFormat: string) => void;
  date?: string;
}

const ModalEditBookingTime = ({
  visible = false,
  onCancel = () => undefined,
  onSubmit = () => undefined,
  date = '',
}: IModalEditBookingTimeProps) => {
  const activeLocation = salesSelectors.getMerchantLocationActive();

  const [form] = Form.useForm();

  const currentTimeDate = useMemo(() => {
    return dayjs(date);
  }, [date]);

  useEffect(() => {
    form.setFieldValue('bookingTime', currentTimeDate);
  }, [date]);

  const _onSubmit = (e: {
    bookingDate: Dayjs,
    bookingTime: Dayjs,
  }) => {
    const bookingDate = moment(e.bookingDate.format());
    const bookingTime = moment(e.bookingTime.format());

    bookingDate.set({
      hour: bookingTime.get('hour'),
      minute: bookingTime.get('minute'),
      second: bookingTime.get('second')
    });
    onSubmit(bookingDate.format());
  };

  const date_closed = useMemo(() => {
    return activeLocation?.date_closed?.map(o => ({
      start: dayjs(o.start_date, 'YY-MM-DD'),
      end: dayjs(o.end_date, 'YY-MM-DD'),
    }));
  }, [activeLocation?.date_closed]);

  const [_bookingTime, setBookingTime] = useState<Dayjs | null>(currentTimeDate);
  useEffect(() => {
    if (!_bookingTime) return;
    form.setFieldValue('bookingTime', _bookingTime);
  }, [_bookingTime]);

  const onPickDate = (d: Dayjs) => {
    const isDateClosed = !!date_closed?.some(o => d.format('YY-MM-DD') == o.start.format('YY-MM-DD') || d.isBetween(o.start, o.end));
    if (isDateClosed) return true;

    return !activeLocation?.time_opening?.some(o => o?.weekday === d?.locale('en').format('dddd')?.toLowerCase());
  };

  const _submit = async () => {
    try {
      await form.validateFields();
      form.submit();
    } catch (error) {
    }
  };

  const lang = 'vi';
  dayjs.locale(lang === 'vi' ? 'vi' : 'en');
  const locale = lang === 'vi' ? viVN : enEL;

  if (!visible) return null;
  const { t: bookingLang } = useTranslation('booking');
  return (
    <Modal
      maskClosable={false}
      open
      onCancel={onCancel}
      okButtonProps={{ style: { display: 'none' } }}
      width={574}
      footer={null}
      forceRender
      centered
    >
      <h2 className='modal-title'>
        <p>{bookingLang('ChooseBookingDate')}</p>
      </h2>
      <Form
        data-form-testing='form-sale-time'
        name='basic'
        className='modal-form'
        onFinish={_onSubmit}
        autoComplete='off'
        form={form}
        layout='vertical'
        initialValues={{
          bookingDate: currentTimeDate,
          bookingTime: currentTimeDate,
        }}
      >
        <ModalStyled>
          <ConfigProvider locale={locale}>
            <Form.Item name='bookingDate' label={bookingLang('BookingDate')}
              rules={[
                {
                  validator(_, value) {
                    const isValid = onPickDate(value);
                    if (isValid) {
                      return Promise.reject(new Error(bookingLang('YouCannotSelectADateThatIsNotWithinOpenTimeHours') || ''));
                    }
                    return Promise.resolve();
                  },
                }
              ]}
            >

              <DatePicker
                placeholder={bookingLang('SelectBookingDatePlaceholder') || ''}
                clearIcon={false}
                disabledDate={d => {
                  return !d || d.isBefore(dayjs().subtract(1, 'days'));
                }} />

            </Form.Item>
          </ConfigProvider>
          <Form.Item shouldUpdate noStyle>
            {() => {
              return <Form.Item name='bookingTime' label={bookingLang('BookingTime')}>
                <TimePicker
                  format={'HH:mm'}
                  allowClear={false}
                  className='is-time-picker'
                  minuteStep={5}
                  showNow={false}
                  onSelect={setBookingTime}
                  dropdownClassName='time-picker-custom'
                />
              </Form.Item>;
            }}
          </Form.Item>
        </ModalStyled>

        <div className='form-row form-submit-wrapper'>
          <button className='common-btn is-white' onClick={onCancel}>
            {bookingLang('Cancel')}
          </button>
          <Button
            className='common-btn'
            htmlType='button'
            onClick={_submit}
          >
            {bookingLang('Save')}
          </Button>
        </div>
      </Form>
    </Modal>

  );
};

export default ModalEditBookingTime;

const ModalStyled = styled.div`
  .ant-picker-status-error {
    background-color: var(--color-white-01) !important;
  }
`;