import { Select, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import Can from 'features/packagePermission/components/Can';
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { formatMoney } from 'utils/unit';
import TimeScheduleDateView from '../filter/TimeScheduleDateView';

export interface IWork {
    id_booking: string;
    booking: string;
    image: { id: number; img: string }[];
    address: string;
    total_money: number;
}

export const lstData = [
    {
        id: 1,
        id_booking: '123456',
        booking: '20.2.2023 - 12:00',
        image: [
            {
                id: 1,
                image: 'https://assets-prd.ignimgs.com/2022/08/17/top25animecharacters-blogroll-1660777571580.jpg'
            },
            {
                id: 2,
                image: 'https://assets-prd.ignimgs.com/2022/08/17/top25animecharacters-blogroll-1660777571580.jpg'
            }
        ],
        address: 'hcm',
        total_money: 20000,
    },
    {
        id: 2,
        id_booking: '123456',
        booking: '20.2.2023 - 12:00',
        image: [
            {
                id: 1,
                image: 'https://assets-prd.ignimgs.com/2022/08/17/top25animecharacters-blogroll-1660777571580.jpg'
            },
            {
                id: 2,
                image: 'https://assets-prd.ignimgs.com/2022/08/17/top25animecharacters-blogroll-1660777571580.jpg'
            },
            {
                id: 3,
                image: 'https://assets-prd.ignimgs.com/2022/08/17/top25animecharacters-blogroll-1660777571580.jpg'
            },
            {
                id: 4,
                image: 'https://assets-prd.ignimgs.com/2022/08/17/top25animecharacters-blogroll-1660777571580.jpg'
            }
        ],
        address: 'hcm',
        total_money: 20000,
    },
    {
        id: 3,
        id_booking: '123456',
        booking: '20.2.2023 - 12:00',
        image: [
            {
                id: 1,
                image: 'https://assets-prd.ignimgs.com/2022/08/17/top25animecharacters-blogroll-1660777571580.jpg'
            },
            {
                id: 2,
                image: 'https://assets-prd.ignimgs.com/2022/08/17/top25animecharacters-blogroll-1660777571580.jpg'
            }
        ],
        address: 'hcm',
        total_money: 20000,
    },
    {
        id: 4,
        id_booking: '123456',
        booking: '20.2.2023 - 12:00',
        image: [
            {
                id: 1,
                image: 'https://assets-prd.ignimgs.com/2022/08/17/top25animecharacters-blogroll-1660777571580.jpg'
            },
            {
                id: 2,
                image: 'https://assets-prd.ignimgs.com/2022/08/17/top25animecharacters-blogroll-1660777571580.jpg'
            },
            {
                id: 3,
                image: 'https://assets-prd.ignimgs.com/2022/08/17/top25animecharacters-blogroll-1660777571580.jpg'
            },
            {
                id: 4,
                image: 'https://assets-prd.ignimgs.com/2022/08/17/top25animecharacters-blogroll-1660777571580.jpg'
            }
        ],
        address: 'hcm',
        total_money: 20000,
    },
    {
        id: 5,
        id_booking: '123456',
        booking: '20.2.2023 - 12:00',
        image: [
            {
                id: 1,
                image: 'https://assets-prd.ignimgs.com/2022/08/17/top25animecharacters-blogroll-1660777571580.jpg'
            },
            {
                id: 2,
                image: 'https://assets-prd.ignimgs.com/2022/08/17/top25animecharacters-blogroll-1660777571580.jpg'
            }
        ],
        address: 'hcm',
        total_money: 20000,
    },
    {
        id: 6,
        id_booking: '123456',
        booking: '20.2.2023 - 12:00',
        image: [
            {
                id: 1,
                image: 'https://assets-prd.ignimgs.com/2022/08/17/top25animecharacters-blogroll-1660777571580.jpg'
            },
            {
                id: 2,
                image: 'https://assets-prd.ignimgs.com/2022/08/17/top25animecharacters-blogroll-1660777571580.jpg'
            },
            {
                id: 3,
                image: 'https://assets-prd.ignimgs.com/2022/08/17/top25animecharacters-blogroll-1660777571580.jpg'
            },
            {
                id: 4,
                image: 'https://assets-prd.ignimgs.com/2022/08/17/top25animecharacters-blogroll-1660777571580.jpg'
            }
        ],
        address: 'hcm',
        total_money: 20000,
    },
    {
        id: 7,
        id_booking: '123456',
        booking: '20.2.2023 - 12:00',
        image: [
            {
                id: 1,
                image: 'https://assets-prd.ignimgs.com/2022/08/17/top25animecharacters-blogroll-1660777571580.jpg'
            },
            {
                id: 2,
                image: 'https://assets-prd.ignimgs.com/2022/08/17/top25animecharacters-blogroll-1660777571580.jpg'
            }
        ],
        address: 'hcm',
        total_money: 20000,
    },
    {
        id: 8,
        id_booking: '123456',
        booking: '20.2.2023 - 12:00',
        image: [
            {
                id: 1,
                image: 'https://assets-prd.ignimgs.com/2022/08/17/top25animecharacters-blogroll-1660777571580.jpg'
            },
            {
                id: 2,
                image: 'https://assets-prd.ignimgs.com/2022/08/17/top25animecharacters-blogroll-1660777571580.jpg'
            },
            {
                id: 3,
                image: 'https://assets-prd.ignimgs.com/2022/08/17/top25animecharacters-blogroll-1660777571580.jpg'
            },
            {
                id: 4,
                image: 'https://assets-prd.ignimgs.com/2022/08/17/top25animecharacters-blogroll-1660777571580.jpg'
            }
        ],
        address: 'hcm',
        total_money: 20000,
    },
];

/**
 * Work tab
 * @returns 
 */
const WorkTab = () => {

    /**
     * Columns table
     */
    const columns: ColumnsType<any> = [
        {
            title: 'Mã lịch hẹn',
            dataIndex: 'id_booking',
            key: 'id_booking',
            fixed: 'left',
            render: (text) => (
                <IdBookingStyled>
                    <Link to={''}>
                        {text}
                    </Link>
                </IdBookingStyled>
            ),
        },
        {
            title: 'Lịch hẹn',
            dataIndex: 'booking',
            key: 'booking',
            render: (text) => <div>{text}</div>,
        },
        {
            title: 'Hình ảnh',
            dataIndex: 'image',
            key: 'image',
            render: (images) => (
                <ImageWorkStyled>
                    {images?.map((image: any) => (
                        <img key={image.id} src={image.image} alt={`Image ${image.id}`} />
                    ))}
                </ImageWorkStyled>
            ),
        },
        {
            title: 'Địa điểm',
            dataIndex: 'address',
            key: 'address',
            render: (value: string) => <div>{value}</div>,
        },
        {
            title: 'Tổng số tiền',
            dataIndex: 'total_money',
            key: 'total_money',
            render: (value: number) => `${formatMoney(+value ?? 0)}`,
        },
        {
            title: 'Thao tác',
            dataIndex: 'actions',
            key: 'actions',
            width: '100px',
            render: (text: string, record: any) => (
                <ul className={'common-action'}>
                    <Can>
                        <li
                            className="action-edit"
                        ></li>
                    </Can>
                    <Can>

                        <li className="action-remove" onClick={() => confirm(record)}></li>
                    </Can>

                </ul>
            ),
        },
    ];

    const dataSelect = [
        {
            value: 'jack',
            label: 'Jack (100)',
        },
        {
            value: 'lucy',
            label: 'Lucy (101)',
        },
    ];

    const handleChange = (value: { value: string; label: React.ReactNode }) => {
        console.log(value);
    };

    return (
        <CardsColStyled>
            <div className='heading-date'>
                <i className='developing'>Đang phát triển</i>
                <div className='selectTime'>
                    <TimeScheduleDateView />
                    <Select
                        labelInValue
                        defaultValue={{ value: 'lucy', label: 'Lucy (101)' }}
                        style={{ width: 120 }}
                        onChange={handleChange}
                        options={dataSelect}
                    />
                </div>
            </div>
            <div className="common-card">
                <div>
                    <Table
                        className={'custom-table'}
                        scroll={{ x: 900 }}
                        columns={columns}
                        rowKey={(record) => record?.id}
                        dataSource={lstData}
                        pagination={false}
                    />
                </div>
            </div>
        </CardsColStyled>
    );
};

export default WorkTab;

const CardsColStyled = styled.div`
    width: 100%;
    background: var(--color-white);
    border-radius: 6px;
    padding: 20px 16px;

    .heading-date {
        display: flex;
        justify-content: space-between;
        align-items: center;
        @media only screen and (max-width: 767.98px) {
            display: grid;
        }
        .developing {
            color: #141414;
            font-size: 32px;
            font-weight: 600;
        }
        .selectTime {
            display: flex;
            justify-content: end;
            align-items: center;
            gap: 8px;
            .ant-select-selector {
                height: 42px;
            }
            .ant-select-single {
                width: auto !important;
            }
        }
    }
`;

const ImageWorkStyled = styled.div`
display: flex;
justify-content: start;
align-items: center;
gap: 8px;
img {
    width: 32px;
    height: 32px;
    border-radius: 6px;
}
`;
const IdBookingStyled = styled.div`
a {
    overflow: hidden;
    color: var(--Blue-Munsell-Main, #1B9AAA);
    text-overflow: ellipsis;
    font-family: Inter;
    font-size: 16px;
    font-weight: 500;
    line-height: 140%;
    text-decoration-line: underline;
}
`;