import { Spin, Table } from 'antd';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import serviceActions from '../../../services/services/actions';
import productSelectors from 'features/Products/services/selectors';
import { useEffect } from 'react';
import productActions from 'features/Products/services/actions';
const CategoryTable = ({ columns }: any) => {
  const [searchParams, setSearchParams] = useSearchParams();
  // const page: { page: number, per_page: number } = {
  //   page: Number(searchParams.get('page') ?? 1),
  //   per_page: Number(searchParams.get('per_page') ?? 10),
  // };

  const dispatch = useDispatch();

  const data = productSelectors.getPaginateListCategory();
  const loading = productSelectors.loadingCategoryPaginateList();

  useEffect(()=>{
    dispatch(productActions.getListPaginateCategory.fetch({
      page: Number(searchParams.get('page')?? 1),
      per_page: Number(searchParams.get('per_page')?? 10),
    }));
  },[data]);

  // const { t: locationLang } = useTranslation('location');
//   const dataSource: any = [
//     {
//         'id_product': 'ABC123',
//         'category_name': 'Sửa rửa mặt aaa',
//         'status': 'Action',
//         'quantity_service': 5,
//     },
//     {
//         'id_product': 'DEF456',
//         'category_name': 'Kem dưỡng',
//         'status': 'Deactive',
//         'quantity_service': 10,
//     }
// ];

  return (
    <>
      <Spin spinning={loading}>
        <Table
          className={'custom-table'}
          dataSource={data}
          columns={columns}
          scroll={{
            x: 500,
          }}
          pagination={{
            defaultCurrent: Number(searchParams.get('page') ?? 1),
            defaultPageSize: Number(searchParams.get('per_page') ?? 10),
            total: data?.pagination?.total_record,
            onChange: (pageChange, sizeChange) => {
              const params: any = {
                page: pageChange == 0 ? 1 : pageChange,
                per_page: sizeChange,
              };
              setSearchParams(params);

              dispatch(serviceActions.getListPaginateServices.fetch({
                page: params.page,
                per_page: params.per_page,
              }));
            },
            showSizeChanger: false,
          }}
        />
      </Spin>
    </>
  );
};

export default CategoryTable;
