import { Select } from 'antd';
import workingHourActions from 'features/workingHour/services/actions';
import { EDateOption } from 'features/workingHour/services/types/workingHour';
import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
type ITypeViewPickerProps = {
  value?: string;
};

/**
 * Type view picker
 * @param value 
 * @returns 
 */
const TypeViewPicker: React.FC<ITypeViewPickerProps> = ({ value }) => {

  const dispatch = useDispatch();
  const { t: workingHourLang } = useTranslation('workingHour');
  const options: { value: EDateOption, label: string }[] = [
    { value: EDateOption.TODAY, label: workingHourLang('Today') },
    { value: EDateOption.YESTERDAY, label: workingHourLang('Yesterday') },
    { value: EDateOption.LAST_7_DAYS, label: workingHourLang('Last7Days') },
    { value: EDateOption.LAST_30_DAYS, label: workingHourLang('Last30Days') },
    { value: EDateOption.LAST_QUARTER, label: workingHourLang('LastQuarter') },
    { value: EDateOption.LAST_YEAR, label: workingHourLang('LastYear') },
    { value: EDateOption.CUSTOM, label: workingHourLang('CustomRange') },
  ];

  /**
   * Get range date
   * @param unit 
   * @param amount 
   * @returns 
   */
  const getRangeDate = (unit: string, amount: number) => {
    // Calculate the start date based on the unit and amount
    const dateStart = unit === 'days' ?
      moment().unix() : // If unit is 'days', start date is today
      moment().subtract(amount, unit as moment.unitOfTime.DurationConstructor).startOf(unit as moment.unitOfTime.DurationConstructor).unix(); // Otherwise, calculate start date based on unit and amount

    // Calculate the end date based on the unit and amount
    const dateEnd = unit === 'day' ?
      dateStart : // If unit is 'day', end date is same as start date
      moment(moment()).subtract(amount, unit as moment.unitOfTime.DurationConstructor).endOf(unit as moment.unitOfTime.DurationConstructor).unix(); // Otherwise, calculate end date based on unit and amount

    // Return the date range as an array
    if (unit === 'days')  // If unit is 'days', return [dateEnd, dateStart]
      return [dateEnd, dateStart];

    // Otherwise, return [dateStart, dateEnd]
    return [dateStart, dateEnd];
  };

  /**
   * On change
   * @param val 
   * @returns 
   */
  const onChange = (val: string) => {
    // If the selected value is custom, return without further action
    if (val === EDateOption.CUSTOM)
      return;

    // Split the selected value into unit and amount
    const [unit, amount] = val.split('_');

    // Get the date range based on the unit and amount
    const [dateStart, dateEnd] = getRangeDate(unit, Number(amount));

    // If the start and end dates are the same
    if (dateStart === dateEnd) {
      // Set start_date, end_date, and date parameters accordingly
      dispatch(workingHourActions.setParams({
        start_date: undefined,
        end_date: undefined,
        date: dateStart,
      }));
      return;
    }

    // Set start_date, end_date, and date parameters accordingly
    dispatch(workingHourActions.setParams({
      start_date: dateStart,
      end_date: dateEnd,
      date: undefined,
    }));
  };

  return (
    <TypeViewPickerStyled>
      <div className="common-select">
        <Select
          value={value}
          defaultValue={EDateOption.TODAY}
          options={options}
          className='is-normal'
          onChange={onChange}
        />
      </div>
    </TypeViewPickerStyled>
  );
};

export default TypeViewPicker;
const TypeViewPickerStyled = styled.div`
.common-select {
  border: 1px solid #D9D9D9;
  border-radius: 8px;
}
`;
