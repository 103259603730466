/**
 * Handle format number
 * @param val  
 * @returns 
 */
const formatNumber = (val: number | undefined | null) => {
    if (!val) return 0;

    if (val.toString()?.split('.').length > 1) {
        return +val.toFixed(2);
    }

    return val;
};

export default formatNumber;
