import { Form, Input } from 'antd';
import TimePickerAutoChange from 'components/common/TimePickerAutoChange';
import dayjs from 'dayjs';
import { get } from 'lodash';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { timeFormat } from 'utils/unit';

interface TimePickerHourElementProps {
  field: any;
  index: number;
  remove: any;
  weekday: string;
  timeWorking?: { time_start: string; time_end: string }[];
  addUserPage?: boolean
}

const TimePickerHourElement = ({
  field,
  index,
  remove,
  weekday,
  timeWorking = [],
  addUserPage
}: TimePickerHourElementProps) => {
  const form = Form.useFormInstance();

  const getDisabledHours = () => {
    const hours: any = [];
    const hoursDisable: any = [];

    if (timeWorking?.length && addUserPage){
      for (let i = 0; i < timeWorking?.length; i++) {
        const time_start = timeWorking?.[i]?.time_start;
        const time_end = timeWorking?.[i]?.time_end;
        const getTimeStart = moment(time_start, 'HH:mm:ss').get('hours');
        const getTimeEnd = moment(time_end, 'HH:mm:ss').get('hours');
        for (let i = getTimeStart; i <= getTimeEnd; i++) {
          hours.push(i);
        }
      }
  
      for (let i = 0; i < 24; i++) {
        if (!hours?.includes(i)) {
          hoursDisable?.push(i);
        }
      }
    }
    return hoursDisable;
  };

  const getDisabledMinutes = (selectedHour: number) => {
    const minutes: any = [];

    if (timeWorking?.length && addUserPage){
      for (let i = 0; i < timeWorking?.length; i++) {
        const time_start = timeWorking?.[i]?.time_start;
        const time_end = timeWorking?.[i]?.time_end;
  
        const getTimeStart = moment(time_start, 'HH:mm:ss').get('hours');
        const getTimeEnd = moment(time_end, 'HH:mm:ss').get('hours');
  
        if (selectedHour === getTimeStart) {
          for (
            let i = 0;
            i < moment(time_start, 'HH:mm:ss').get('minutes');
            i++
          ) {
            minutes.push(i);
          }
        }
  
        if (selectedHour === getTimeEnd) {
          for (
            let i = moment(time_end, 'HH:mm:ss').get('minutes') + 1;
            i < 60;
            i++
          ) {
            minutes.push(i);
          }
        }
      }
    }
    return minutes;
  };

  const checkDateTimeFallCloseHour = () => {
    if (timeWorking?.length && addUserPage) {
      const timeEndCurr = get(form.getFieldValue('workingTime'), [
        weekday,
        index,
        'time_end',
      ]);   

      const timeStartCurr = get(form.getFieldValue('workingTime'), [
        weekday,
        index,
        'time_start',
      ]);
      
      const findValid = timeWorking?.find((item) => {
        const time_start =item?.time_start;
        const time_end =item?.time_end;
        return dayjs(time_start, 'HH:mm:ss').isSameOrBefore(dayjs(timeStartCurr, 'HH:mm:ss')) && dayjs(timeEndCurr, 'HH:mm:ss').isSameOrBefore(dayjs(time_end, 'HH:mm:ss'));
      });

      if (!findValid && timeEndCurr && timeStartCurr){
        return true;
      }
    }
    return false;
  };

  const {t: locationLang} = useTranslation('location');
  const {t: dateLang} = useTranslation('date');

  return (
    <>
      <Form.Item name={[index, 'weekday']} initialValue={weekday} noStyle>
        <Input type='hidden' />
      </Form.Item>
      <li key={index} className='pick-item'>
        <Form.Item
          name={[index, 'time_start']}
          // validateTrigger={['onSelect']}
          dependencies={[['workingTime', weekday, index, 'time_end']]}
          rules={[
            {
              required: true,
              message: `${locationLang('TimeStartIn')} ${weekday} ${locationLang('AtRow')} ${
                index + 1
              } ${locationLang('IsRequired')}`,
            },
            {
              validator(rule, value, callback) {
                const fallCloseHour = checkDateTimeFallCloseHour();

                if (fallCloseHour) {
                  return callback(
                    `${locationLang('TimeStartAndTimeEndIn')} ${weekday} ${locationLang('AtRow')} ${
                      index + 1
                    } ${locationLang('FallsWithinClosedHours')}`
                  );
                }

                const timeStartCurr = get(form.getFieldValue('workingTime'), [
                  weekday,
                  index,
                  'time_start',
                ]);

                const timeEndCurr = get(form.getFieldValue('workingTime'), [
                  weekday,
                  index,
                  'time_end',
                ]);

                if (
                  timeEndCurr &&
                  timeStartCurr &&
                  timeEndCurr.isSameOrBefore(timeStartCurr)
                ) {
                  return callback(
                    `${locationLang('TimeStartIn')} ${dateLang(weekday)} ${locationLang('AtRow')} ${
                      index + 1
                    } ${locationLang('IsMustBeforeTimeEnd')}`
                  );
                }

                if (index === 0) return callback();

                const timeEndPrev = get(form.getFieldValue('workingTime'), [
                  weekday,
                  index - 1,
                  'time_end',
                ]);

                if (
                  timeStartCurr &&
                  timeEndPrev &&
                  timeStartCurr.isBefore(timeEndPrev)
                ) {
                  return callback(
                    `${locationLang('TimeStartAndTimeEndIn')} ${dateLang(weekday)} ${locationLang('AtRow')} ${
                      index + 1
                    } ${locationLang('AreNotNested')}`
                  );
                }
                return callback();
              },
            },
          ]}
          noStyle
        >
          <TimePickerAutoChange
            placeholder='__:__'
            format={timeFormat}
            disabledHours={getDisabledHours}
            disabledMinutes={getDisabledMinutes}
          />
        </Form.Item>
        {locationLang('to')}
        <Form.Item
          name={[index, 'time_end']}
          noStyle
          rules={[
            {
              required: true,
              message: `${locationLang('TimeEndIn')} ${dateLang(weekday)} ${locationLang('AtRow')} ${index + 1} ${locationLang('IsRequired')}`,
            },
          ]}
        >
          <TimePickerAutoChange
            placeholder='__:__'
            format={timeFormat}
            disabledHours={getDisabledHours}
            disabledMinutes={getDisabledMinutes}
          />
        </Form.Item>
        <span className='btn-delete' onClick={() => remove(field.name)}></span>
      </li>
    </>
  );
};

export default TimePickerHourElement;
