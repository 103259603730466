const IconAddService = () => {
    return (
        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path opacity="0.4" d="M11.1723 22.6188L3.0027 18.9053C2.2211 18.5501 2.22111 17.4399 3.0027 17.0846L11.1723 13.3712C11.6981 13.1321 12.3017 13.1321 12.8275 13.3712L20.9971 17.0846C21.7787 17.4399 21.7787 18.5501 20.9971 18.9053L12.8275 22.6188C12.3017 22.8578 11.6981 22.8578 11.1723 22.6188Z" fill="#595959"/>
        <path d="M11.1723 17.6188L3.0027 13.9053C2.2211 13.5501 2.22111 12.4399 3.0027 12.0846L11.1723 8.37116C11.6981 8.13214 12.3017 8.13214 12.8275 8.37116L20.9971 12.0846C21.7787 12.4399 21.7787 13.5501 20.9971 13.9053L12.8275 17.6188C12.3017 17.8578 11.6981 17.8578 11.1723 17.6188Z" fill="#595959"/>
        <path opacity="0.4" d="M11.1723 12.6188L3.0027 8.90534C2.2211 8.55007 2.22111 7.43988 3.0027 7.08461L11.1723 3.37116C11.6981 3.13214 12.3017 3.13214 12.8275 3.37116L20.9971 7.08461C21.7787 7.43988 21.7787 8.55007 20.9971 8.90534L12.8275 12.6188C12.3017 12.8578 11.6981 12.8578 11.1723 12.6188Z" fill="#595959"/>
        </svg>
    );
};

export default IconAddService;