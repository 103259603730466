import { Empty, Popover, Spin, Table, message } from 'antd';
import { useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import EditShiftModal from '../widgets/EditShiftModal';
import TimeOffModal from '../widgets/TimeOffModal';
import IconEdit from 'assets/svg/IconEdit';
import timeScheduleSelectors from '../services/selectors';
import { getDaysBetweenDates } from 'utils/unit';
import moment from 'moment';
import {
  DATE_FORMAT_RESPONSE,
  DATE_FORMAT_TIME_SCHEDULE,
  DAY_OF_WEEK_FORMAT,
} from 'features/bookings/services/constants';
import {
  ETimeSchedule,
  EditShiftInitValue,
  ITimeTimeOffFormValue,
  TimeOffModalType,
  TimingShiftScheduleType,
  TimingType,
} from '../types/timeSchedule';
import { IApiAddDateOff, IApiTimeShift } from '../types/api';
import { useSetLoadingPage } from 'services/UI/LoadingPage';
import apisTimeSchedule from '../services/api';
import { useAppDispatch } from 'store/hooks';
import timeScheduleActions from '../services/actions';
import dayjs from 'dayjs';
import { ColumnsType } from 'antd/es/table';
import { displayError } from '../utils/displayError';
import ModalDeleteDayOff, { ModalConfirmRef } from 'components/common/Modal/ModalDeleteDayOff';
import { useTranslation } from 'react-i18next';

const timeScheduleValueEdit = [ETimeSchedule.ON, ETimeSchedule.DAY_OFF];

interface TimeScheduleTableProps {
  handleAddTimeOff: (values: IApiAddDateOff) => Promise<boolean>;
}

export function sortTimeSchedule(timeSchedule?: TimingShiftScheduleType[]) {
  if (timeSchedule?.length) {
    return timeSchedule.sort((a, b) => {
      const timeStartA = moment(a?.time_start, 'HH:mm:ss');
      const timeStartB = moment(b?.time_start, 'HH:mm:ss');

      return timeStartA.diff(timeStartB);
    });
  }
  return [];
}

const TimeScheduleTable = ({ handleAddTimeOff }: TimeScheduleTableProps) => {
  const [editShiftModalOpen, setEditShiftModalOpen] = useState(false);
  const [isTimeOffModalOpen, setIsTimeOffModalOpen] = useState(false);
  const { t: bookingLang } = useTranslation('booking');
  const { t: dateLang } = useTranslation('date');
  const [mouseHoverData, setMouseHoverData] = useState({
    date: '',
    key: '',
  });
  const [editShiftInitData, setEditShiftInitData] =
    useState<EditShiftInitValue>({
      date: '',
      time: [],
      merchantEmployeeId: '',
    });
  const [editTimeOffInitData, setEnitTimeOffInitData] =
    useState<ITimeTimeOffFormValue>({});
  const modalConfirmRef = useRef<ModalConfirmRef>(null);
  const pagination = timeScheduleSelectors.getLstTimeSchedulePagination();

  const param = timeScheduleSelectors.getParams();
  const setLoadingPage = useSetLoadingPage();

  const dayBetweenDate = getDaysBetweenDates(
    moment(param.start_date, DATE_FORMAT_RESPONSE),
    moment(param.end_date, DATE_FORMAT_RESPONSE)
  );
  const timeSchedule = timeScheduleSelectors.getLstTimeSchedule();
  const dispatch = useAppDispatch();

  const loadingTimeSchedules =
    timeScheduleSelectors.getLoadingLstTimeSchedules();

  const COLUMN_DATA = dayBetweenDate.map((o) => ({
    key: o.format(DATE_FORMAT_TIME_SCHEDULE).replace(' ', ''),
    label: o.format(DATE_FORMAT_TIME_SCHEDULE),
    subLabel: o.format(DAY_OF_WEEK_FORMAT),
    dateValue: o.format(DATE_FORMAT_RESPONSE),
  }));

  const TABLE_DATA = useMemo(() => {
    return timeSchedule.map((o, index) => {
      const weekWorkingTime: any = {};
      Object.values(o.timing).forEach((workTime: TimingType) => {
        if (workTime?.shift_type === ETimeSchedule.ON) {
          weekWorkingTime[moment(workTime.date).format('MMMDD')] =
            sortTimeSchedule([...(workTime.timing_schedules || [])])?.map(
              (o) => ({
                text: `${o.time_start ? dayjs(o.time_start, 'HH:mm').format('HH:mm') : ''
                  } - ${o.time_end ? dayjs(o.time_end, 'HH:mm').format('HH:mm') : ''
                  }`,
                time_start: o?.time_start,
                time_end: o?.time_end,
                type: workTime.shift_type,
                date: workTime?.date,
                is_work: o?.is_work,
                is_repeat: o?.is_repeat,
                off_id: !o?.is_work ? o?.id : '',
                timing_shifts: workTime?.timing_shifts,
              })
            );
        } else {
          weekWorkingTime[moment(workTime.date).format('MMMDD')] = [
            {
              text: workTime?.content,
              type: workTime.shift_type,
              date: workTime?.date,
              is_work: false,
              time_off: workTime?.time_off,
            },
          ];
        }
      });

      return {
        key: index,
        teamMember: o.full_name,
        employeeId: o.id,
        ...weekWorkingTime,
      };
    });
  }, [timeSchedule]);


  const handleCloseEditTimeShiftModal = () => {
    setEditShiftModalOpen(false);
  };

  const handleCloseTimeOffModal = () => {
    setIsTimeOffModalOpen(false);
  };

  const submitEditTimeShift = async (payload: IApiTimeShift) => {
    setLoadingPage(true);
    try {
      const rest = await apisTimeSchedule.editTimeShift(
        editShiftInitData.merchantEmployeeId,
        payload
      );

      const code = rest?.data?.code;

      if (code === 1000) {
        handleCloseEditTimeShiftModal();
        dispatch(
          timeScheduleActions.setListTimeScheduleParams({
            page: 1,
          })
        );
        message.success(rest?.data?.message || '');
      } else {
        const msgError = rest?.data?.error?.message;
        if (msgError) message.error(msgError);
        else throw 'fail';
      }
    } catch (error: any) {
      const err = displayError(error);
      message.error(err);
    } finally {
      setLoadingPage(false);
    }
  };

  const handleRemoveTimeOff = async (
    off_id: string | number,
    is_repeat?: boolean
  ) => {
    setLoadingPage(true);
    try {
      const rest = await apisTimeSchedule.deleteTimeOff(off_id, is_repeat);
      setLoadingPage(false);

      const code = rest?.data?.code;

      if (code === 1000) {
        setEditShiftInitData({
          ...editShiftInitData,
          time: rest?.data?.data?.map((item: any) => {
            return {
              text: item?.time_start + ' - ' + item?.time_end,
              is_work: true,
              time_start: item?.time_start,
              time_end: item?.time_end
            };
          })
        });

        dispatch(timeScheduleActions.setListTimeScheduleParams({}));

        message.success(rest?.data?.message || '');

        return true;
      } else {
        const msgError = rest?.data?.error?.message;
        message.error(msgError || 'An error occurred. Please try again');
        return false;
      }
    } catch (error: any) {
      const err = displayError(error);
      message.error(err);

      setLoadingPage(false);
      return false;
    }
  };

  const handleOpenDeleteModal = (off_id: number | string) => {
    modalConfirmRef.current?.show({
      content: bookingLang('AreYouSureYouWantToDeleteThisDayThisActionCannotBeUndone'),
      checkboxContent: bookingLang('DeleteForAllRepeateDayOff'),
      title: bookingLang('YouWantToDeleteThisDayOff'),
      submit: (is_repeat: boolean) => handleRemoveTimeOff?.(off_id, is_repeat),
    });
  };

  const handleEditShiftDayOffModal = (value: any, record: any) => {
    if (
      value?.[0]?.type &&
      value?.[0]?.type !== ETimeSchedule.CLOSED_DAY &&
      value?.[0]?.type !== ETimeSchedule.HOLIDAY_OFF
    ) {
      if (value?.[0]?.type === ETimeSchedule.DAY_OFF) {
        setIsTimeOffModalOpen(true);
        setEnitTimeOffInitData({
          teamMember: record?.employeeId,
          dayOff: value?.[0]?.date,
        });
      } else {
        setEditShiftInitData({
          date: value?.[0]?.date,
          time: value,
          merchantEmployeeId: record?.employeeId,
          timing_shifts: value?.[0]?.timing_shifts,
        });
        setEditShiftModalOpen(true);
      }
    }
  };

  const displayTimeScheduleValue = (value: any) => {
    const values = value ? [...value] : [];

    const offValue = values?.filter(it => !it?.is_work && (!it?.time_start && !it?.time_end));

    const workValue = values?.filter(it => it?.is_work);

    const timingShifts = (workValue?.[0]?.timing_shifts ?? []);

    const newWorkValue = [];

    for (const shift of timingShifts) {
      const timeStart = shift?.time_start;
      const timeEnd = shift?.time_end;

      const findSame = workValue?.find((item) => {
        return moment(item?.time_start, 'HH:mm:ss')?.isSame(moment(timeStart, 'HH:mm:ss')) && moment(item?.time_end, 'HH:mm:ss')?.isSame(moment(timeEnd, 'HH:mm:ss'));
      });

      if (findSame) {
        newWorkValue?.push(findSame);
      } else {
        const filterTimeChange = workValue?.filter((item) => {
          return moment(item?.time_start, 'HH:mm:ss')?.isSameOrAfter(moment(timeStart, 'HH:mm:ss')) && moment(item?.time_end, 'HH:mm:ss')?.isSameOrBefore(moment(timeEnd, 'HH:mm:ss'));
        });


        if (filterTimeChange?.length) {
          newWorkValue.push({
            ...(filterTimeChange[0]),
            text: moment(timeStart, 'HH:mm:ss').format('HH:mm') + ' - ' + moment(timeEnd, 'HH:mm:ss').format('HH:mm'),
            markText: true
          });
        }
      }
    }

    return (offValue ?? [])?.concat(newWorkValue ?? []);
  };

  const scheduleColumns: ColumnsType<any> = [
    {
      title: bookingLang('TeamMember'),
      dataIndex: 'teamMember',
      key: 'teamMember',
      className: 'nowrap schedule-table-row',
      fixed: 'left',
    },
    ...(COLUMN_DATA?.map((item: any) => {
      const headerColor =
        moment().format(DATE_FORMAT_RESPONSE) === item?.dateValue
          ? '#409E78'
          : '';

      const month = dateLang(moment(item.label).format('MMM'));
      const day = moment(item.label).format('DD');
      return {
        title: (
          <div className='table-header'>
            <p style={{ color: headerColor }}>{day + ' ' + month}</p>
            <p style={{ color: headerColor }} className='table-subheader'>
              {dateLang(item?.subLabel)}
            </p>
          </div>
        ),
        dataIndex: item.key,
        key: item.key,
        className: 'nowrap schedule-table-row',
        render: (value: any[], record: any) => {
          return (
            <div
              className='time-schedule-value'
              onMouseEnter={() => {
                if (
                  mouseHoverData?.date !== item?.key ||
                  mouseHoverData?.key !== record?.employeeId
                ) {
                  if (
                    value?.[0]?.type &&
                    value?.[0]?.type !== ETimeSchedule.CLOSED_DAY &&
                    value?.[0]?.type !== ETimeSchedule.HOLIDAY_OFF
                  ) {
                    setMouseHoverData({
                      date: item?.key,
                      key: record?.employeeId,
                    });
                  }
                }
              }}
              onMouseLeave={() => {
                setMouseHoverData({
                  date: '',
                  key: '',
                });
              }}
            >
              {displayTimeScheduleValue(value)?.map((it: any, id: number) => {
                return (
                  <>
                    {!it?.is_work && (it?.time_start || it?.time_end) ?
                      null :
                      <div
                        key={'time-schedule-column-' + id}
                        className={`${(!it?.is_work ? 'time-schedule-value-off ' : '') +
                          (timeScheduleValueEdit.indexOf(it?.type) >= 0
                            ? 'time-schedule-value-edit'
                            : '')
                          }`}
                        style={{
                          marginLeft:
                            !it?.is_work && (it?.time_start || it?.time_end)
                              ? '8px'
                              : '',
                          fontSize: '16px',
                        }}
                      >
                        {it?.text === 'HOLIDAY OFF' ? bookingLang('HOLIDAYOFF') : it?.text === 'CLOSED DATE' ? bookingLang('CLOSEDDATE') : it?.text === 'DAY OFF' ? bookingLang('DAYOFF') : it?.text}
                        {it?.markText ? (
                          <span className='mark-time-off'>*</span>
                        ) : (
                          ''
                        )}
                      </div>
                    }
                  </>
                );
              })}
              <div
                className={`${mouseHoverData?.date === item?.key &&
                  mouseHoverData?.key === record?.employeeId
                  ? 'wrap-edit-value'
                  : 'wrap-value-none'
                  }`}
              >
                {value?.[0]?.type === ETimeSchedule.DAY_OFF ? (
                  <Popover
                    arrow={false}
                    content={
                      <PopoverStyled>
                        <div className='popover-content-wrap'>
                          <div
                            className='popover-content'
                            onClick={() =>
                              handleEditShiftDayOffModal(value, record)
                            }
                          >
                            {bookingLang('EditDayOff')}
                          </div>
                          <div
                            className='content-delete popover-content'
                            onClick={() => {
                              handleOpenDeleteModal?.(value?.[0]?.time_off?.id);
                            }}
                          >
                            {bookingLang('DeleteDayOff')}
                          </div>
                        </div>
                      </PopoverStyled>
                    }
                    placement='bottomLeft'
                    trigger={'click'}
                  >
                    <button>
                      <IconEdit />
                    </button>
                  </Popover>
                ) : (
                  <div
                    onClick={() => handleEditShiftDayOffModal(value, record)}
                  >
                    <IconEdit />
                  </div>
                )}
              </div>
            </div>
          );
        },
      };
    }) ?? []),
  ];

  return (
    <>
      <Spin spinning={loadingTimeSchedules}>
        <TimeScheduleTableStyled>
          <Table
            className={'custom-table'}
            dataSource={TABLE_DATA}
            columns={scheduleColumns}
            rowKey={(item) => item.key}
            scroll={{
              x: 900,
            }}
            pagination={{
              total: pagination?.total_record,
              defaultCurrent: 1,
              defaultPageSize: 10,
              onChange: (pageChange, sizeChange) => {
                const params: any = {
                  page: pageChange == 0 ? 1 : pageChange,
                  per_page: sizeChange,
                };
                dispatch(
                  timeScheduleActions.setListTimeScheduleParams({
                    page: params?.page,
                    per_page: params?.per_page,
                  })
                );
              },
              showSizeChanger: false,
              // showTotal(total) {
              //   return `Total ${total} items`;
              // },
            }}
            locale={{
              emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={bookingLang('NoData')} />,
            }}
          />

          {editShiftModalOpen ? (
            <EditShiftModal
              isModalOpen={editShiftModalOpen}
              handleClose={handleCloseEditTimeShiftModal}
              handleSubmit={submitEditTimeShift}
              initData={editShiftInitData}
              handleRemoveTimeOff={handleRemoveTimeOff}
            />
          ) : null}

          {isTimeOffModalOpen ? (
            <TimeOffModal
              handleClose={handleCloseTimeOffModal}
              isModalOpen={isTimeOffModalOpen}
              initData={editTimeOffInitData}
              handleSubmit={async (payload) => {
                const res = await handleAddTimeOff?.(payload);
                if (res) {
                  handleCloseTimeOffModal();
                }
              }}
              modalType={TimeOffModalType.UPDATE}
            />
          ) : null}
        </TimeScheduleTableStyled>
      </Spin>
      <ModalDeleteDayOff ref={modalConfirmRef} titleConfirm={bookingLang('Delete') || ''} />
    </>
  );
};

const TimeScheduleTableStyled = styled.div`
  .ant-table-cell {
    vertical-align: middle;
  }
  .table-subheader {
    color: #949bda;
    font-size: 14px;
    font-weight: 400;
  }
  .table-header {
    text-align: center;
  }
  .time-schedule-value {
    text-align: center;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .time-schedule-value-off {
    color: #949bda;
    font-weight: 500;
  }

  .time-schedule-value-edit {
    cursor: pointer;
    border-radius: 6px;
    background: var(--Blue-Munsell-10, #D1EBEE);
    padding: 8px;
  }

  .ant-table-cell {
    position: relative;
    height: 1px;
  }

  .wrap-edit-value {
    position: absolute;
    top: 0;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: rgba(54, 53, 101, 0.4);
    backdrop-filter: blur(4px);
    cursor: pointer;
    width: 100%;
  }

  .wrap-value-none {
    display: none;
  }

  .mark-time-off {
    color: #f6303a;
    margin-left: 2px;
  }

  @media (max-width: 1240px) {
    .time-schedule-value-edit {
      white-space: nowrap;
    }

    .schedule-table-row {
      width: 160px;
    }
  }
`;

const PopoverStyled = styled.div`
  padding: 16px;
  min-width: 200px;

  .popover-content-wrap {
    display: flex;
    flex-direction: column;
    gap: 24px;

    .content-delete {
      color: #f5222d;
    }

    .popover-content {
      cursor: pointer;
    }
  }
`;


export default TimeScheduleTable;
