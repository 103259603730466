import { ConfigProvider, Form, Modal } from 'antd';
import DatePicker from 'components/common/DatePickerInput';
import TimePickerAutoChange from 'components/common/TimePickerAutoChange';
import dayjs from 'utils/dayjs';
import { EditWorkingHourFormValue, EditWorkingHourPayload } from 'features/workingHour/services/types/member';
import { Moment } from 'moment';
import { MouseEventHandler, useEffect, useState } from 'react';
import styled from 'styled-components';
import { HOURS_OF_DAY, MINUTES_OF_DAY, timeFormat } from 'utils/unit';
import { useTranslation } from 'react-i18next';
import 'dayjs/locale/vi';
import viVN from 'antd/lib/locale/vi_VN';
import enEL from 'antd/lib/locale/en_US';

interface Props extends EditWorkingHourFormProps {
  visible?: boolean;
}
const EditWorkingHourModal = ({ visible, ...rest }: Props) => {
  const { t: workingHourLang } = useTranslation('workingHour');
  if (!visible) return null;

  return (
    <Modal
      maskClosable={false}
      open={visible}
      onCancel={rest.onCancel}
      okButtonProps={{ style: { display: 'none' } }}
      width={574}
      footer={null}
      forceRender
      centered
    >
      <h2 className='modal-title'>
        <p>{workingHourLang('EditWorkingHour')}</p>
      </h2>
      <EditWorkingHourForm {...rest} />
    </Modal>
  );
};

export default EditWorkingHourModal;
type EditWorkingHourFormProps = {
  onSubmit: (values: EditWorkingHourPayload) => void;
  onCancel?: () => void;
  formData?: EditWorkingHourFormValue;
};

export const EditWorkingHourForm = ({ onCancel, onSubmit, formData }: EditWorkingHourFormProps) => {
  const [form] = Form.useForm();
  useEffect(() => {
    form.setFieldsValue(formData);
  }, [formData]);
  const [errorText, setErrorText] = useState<string>();

  const getPayload = (values: EditWorkingHourFormValue) => {
    const result: EditWorkingHourPayload = { ...values };
    return result;
  };
  const _onSubmit = (values: EditWorkingHourFormValue) => {
    const payload = getPayload(values);
    onSubmit(payload);
  };

  const checkValid = () => {
    try {
      form.validateFields();
      return true;
    } catch (error) {
      return false;
    }
  };
  const _submit: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.preventDefault();
    const isValid = checkValid();
    if (!isValid) return;
    form.submit();
  };

  const getDisableTimeBefore = (type: 'hour' | 'minute', disableTimeFrom: number) => {
    const disableTimes = [];
    for (let i = disableTimeFrom; i > 0; i--)
      disableTimes.push(i - 1);

    return disableTimes;
  };

  const getDisableTimeAfter = (type: 'hour' | 'minute', disableTimeTo: number) => {
    const disableTimes = [];

    const maxTime = type === 'hour' ? HOURS_OF_DAY : MINUTES_OF_DAY;
    for (let i = disableTimeTo; i < maxTime; i++)
      disableTimes.push(i + 1);

    return disableTimes;
  };

  const disabledStartHour = (index: number) => {
    const times = form.getFieldValue(['times']);

    const hoursEndPrev = (times[index - 1]?.end as Moment)?.hour() ?? 0;
    const hoursEndCurrent = (times[index]?.end as Moment)?.hour() ?? HOURS_OF_DAY;
    const disabledHoursBefore: number[] = getDisableTimeBefore('hour', hoursEndPrev);
    const disabledHoursAfter: number[] = getDisableTimeAfter('hour', hoursEndCurrent);

    return [...disabledHoursBefore, ...disabledHoursAfter];
  };

  const disabledStartMinutes = (hourSelected: number, index: number) => {
    const times = form.getFieldValue(['times']);
    if (!times[index - 1]) return [];

    const timeEndPrev = times[index - 1]?.end as Moment;
    const timeEndCurrent = times[index]?.end as Moment;

    if (hourSelected === timeEndPrev?.hour()) {
      const minutesEndPrev = timeEndPrev?.minute();
      const disabledMinutes: number[] = getDisableTimeBefore('minute', minutesEndPrev);

      return disabledMinutes;
    }
    if (hourSelected === timeEndCurrent?.hour()) {
      const minutesEndCurrent = timeEndCurrent?.minute();
      const disabledMinutes: number[] = getDisableTimeAfter('minute', minutesEndCurrent);

      return disabledMinutes;
    }
    return [];
  };


  const disabledEndHour = (index: number) => {
    const times = form.getFieldValue(['times']);

    const hoursStartCurrent = (times[index]?.start as Moment)?.hour() ?? (times[index - 1]?.start as Moment)?.hour() ?? 0;
    const hoursStartNext = (times[index + 1]?.start as Moment)?.hour() ?? HOURS_OF_DAY;
    const disabledHoursBefore: number[] = getDisableTimeBefore('hour', hoursStartCurrent);
    const disabledHoursAfter: number[] = getDisableTimeAfter('hour', hoursStartNext);

    return [...disabledHoursBefore, ...disabledHoursAfter];
  };

  const disableEndMinutes = (hourSelected: number, index: number) => {
    const times = form.getFieldValue(['times']);

    const timeStartCurrent = times[index]?.start as Moment;
    const timeStartNext = times[index + 1]?.start as Moment;



    if (hourSelected === timeStartCurrent?.hour()) {
      const minutesStartCurrent = timeStartCurrent?.minute();
      const disabledMinutes: number[] = getDisableTimeBefore('minute', minutesStartCurrent);

      return disabledMinutes;
    }

    if (hourSelected === timeStartNext?.hour()) {
      const minutesStartNext = timeStartNext?.minute();
      const disabledMinutes: number[] = getDisableTimeAfter('minute', minutesStartNext);

      return disabledMinutes;
    }

    return [];
  };
  const { t: workingHourLang } = useTranslation('workingHour');

  const lang = 'vi';
  dayjs.locale(lang === 'vi' ? 'vi' : 'en');
  const locale = lang === 'vi' ? viVN : enEL;

  return (
    <FormStyled>
      <Form
        form={form}
        name='basic'
        className='modal-form'
        onFinish={_onSubmit}
        autoComplete='off'
        layout='vertical'
        initialValues={{
          date: dayjs(new Date()),
        }}
      >
        <>
          <ConfigProvider locale={locale}>
            <div className='form-row'>
              <Form.Item label={workingHourLang('Date')} name='date' rules={[{ required: true }]}>
                <DatePicker disabled={true} />
              </Form.Item>
            </div>
          </ConfigProvider>
          <Form.List name={'times'}>
            {(fields, { add, remove }) => (
              <>
                {fields.map((field, index) => (
                  <div className='form-row group-time items-end' key={index}>
                    <Form.Item label={workingHourLang('CheckIn')} name={[index, 'start']}>
                      <TimePickerAutoChange
                        placeholder='__:__'
                        allowClear={false}
                        className='is-time-picker'
                        showNow={false}
                        format={timeFormat}
                        disabledTime={() => {
                          return {
                            disabledHours: () => disabledStartHour(index),
                            disabledMinutes: (hour) => disabledStartMinutes(hour, index)
                          };
                        }}

                      />
                    </Form.Item>
                    <p className='center-text'>{workingHourLang('to')}</p>
                    <Form.Item label={workingHourLang('CheckOut')} name={[index, 'end']}>
                      <TimePickerAutoChange
                        placeholder='__:__'
                        allowClear={false}
                        className='is-time-picker'
                        showNow={false}
                        format={timeFormat}
                        disabledTime={() => ({
                          disabledHours: () => disabledEndHour(index),
                          disabledMinutes: (hour) => disableEndMinutes(hour, index)
                        })}
                      />
                    </Form.Item>
                    <ButtonStyled className='remove' type='button' onClick={() => remove(field.name)}>
                      <IconRemove />
                    </ButtonStyled>
                  </div>
                ))}
                {!!errorText && (
                  <p style={{ color: 'var(--color-red)' }}>{errorText}</p>
                )}
                <div className='form-row center-items'>
                  <ButtonStyled type='button' onClick={() => {
                    const times = form.getFieldValue(['times']);
                    if (times.length > 0 && (!times[times.length - 1]?.start || !times[times.length - 1]?.end)) {
                      setErrorText(workingHourLang('PleaseFillInAllAvailableTimeSlots') || '');
                      return;
                    }
                    setErrorText(undefined);
                    add();
                  }}>
                    <IconPlus />
                    <span>{workingHourLang('AddShift')}</span>
                  </ButtonStyled>
                </div>
              </>
            )}
          </Form.List>


          <div className='form-submit-wrapper'>
            <div onClick={onCancel} className='common-btn is-white'>
              {workingHourLang('Cancel')}
            </div>
            <button type='button' onClick={_submit} className='common-btn'>
              {workingHourLang('Save')}
            </button>
          </div>
        </>
      </Form>
    </FormStyled >
  );
};

const FormStyled = styled.div`
  .ant-picker {
    min-width: unset;
  }
  .center-items {
    justify-content: center;
    align-items: center;
  }
  .group-time {
    position: relative;
    @media only screen and (max-width: 767.98px) {
      display: grid;
    }
  }
`;

export const ButtonStyled = styled.button`
display: flex;
align-items: center;
gap: 2px;
span {
  color: var(--green-1, #4A9D77);
  font-variant-numeric: slashed-zero;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
}
&:hover {
  background:rgba(195, 194, 224, 0.2);
}
&.remove {
  position: absolute;
  right: -12px;
  top: 18px;
}
`;

const IconPlus = () => (
  <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="Icon/Plus">
      <path id="Vector" d="M8.5 3.33203V12.6654" stroke="#4A9D77" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path id="Vector_2" d="M3.8335 8H13.1668" stroke="#4A9D77" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </g>
  </svg>
);

const IconRemove = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="24" height="24" rx="12" fill="#FFF1F0" />
    <path d="M16.6666 16.6654L7.33331 7.33203" stroke="#F5222D" strokeWidth="1.5" strokeLinecap="round" />
    <path d="M16.6666 7.33203L7.33331 16.6654" stroke="#F5222D" strokeWidth="1.5" strokeLinecap="round" />
  </svg>

);