const IconPromoCode = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="49"
      height="49"
      viewBox="0 0 49 49"
      fill="none"
    >
      <path
        d="M12.668 17.1096C12.4028 17.1096 12.1484 17.215 11.9609 17.4025C11.7733 17.59 11.668 17.8444 11.668 18.1096V20.2815C11.668 20.5467 11.7733 20.8011 11.9609 20.9886C12.1484 21.1761 12.4028 21.2815 12.668 21.2815C12.9332 21.2815 13.1875 21.1761 13.3751 20.9886C13.5626 20.8011 13.668 20.5467 13.668 20.2815V18.1096C13.668 17.8444 13.5626 17.59 13.3751 17.4025C13.1875 17.215 12.9332 17.1096 12.668 17.1096Z"
        fill="#1B9AAA"
      />
      <path
        d="M12.668 22.5413C12.4028 22.5413 12.1484 22.6466 11.9609 22.8342C11.7733 23.0217 11.668 23.276 11.668 23.5413V25.7131C11.668 25.9784 11.7733 26.2327 11.9609 26.4202C12.1484 26.6078 12.4028 26.7131 12.668 26.7131C12.9332 26.7131 13.1875 26.6078 13.3751 26.4202C13.5626 26.2327 13.668 25.9784 13.668 25.7131V23.5413C13.668 23.276 13.5626 23.0217 13.3751 22.8342C13.1875 22.6466 12.9332 22.5413 12.668 22.5413Z"
        fill="#1B9AAA"
      />
      <path
        d="M12.668 27.9729C12.4028 27.9729 12.1484 28.0783 11.9609 28.2658C11.7733 28.4533 11.668 28.7077 11.668 28.9729V31.1448C11.668 31.41 11.7733 31.6643 11.9609 31.8519C12.1484 32.0394 12.4028 32.1448 12.668 32.1448C12.9332 32.1448 13.1875 32.0394 13.3751 31.8519C13.5626 31.6643 13.668 31.41 13.668 31.1448V28.9729C13.668 28.7077 13.5626 28.4533 13.3751 28.2658C13.1875 28.0783 12.9332 27.9729 12.668 27.9729Z"
        fill="#1B9AAA"
      />
      <path
        d="M9.53516 5.63501C8.37041 5.73253 7.30927 6.50881 6.92188 7.67993L5.58985 11.7014C3.96579 11.8372 2.66797 13.201 2.66797 14.8577V18.8362C2.66829 19.2701 2.94835 19.6543 3.36133 19.7874C4.60884 20.19 5.66754 21.7573 5.66797 23.676C5.66814 25.5954 4.60924 27.1639 3.36133 27.5667C2.94835 27.6997 2.66829 28.0839 2.66797 28.5178V34.4944C2.66797 36.2397 4.10428 37.676 5.84961 37.676H21.6367L38.6152 43.4729C40.1826 44.0081 41.8975 43.1321 42.4141 41.5706L43.7109 37.6526C45.3514 37.5339 46.668 36.163 46.668 34.4944V28.5159C46.6676 28.082 46.3876 27.6978 45.9746 27.5647C44.7271 27.1621 43.6684 25.5948 43.668 23.676C43.6678 21.7567 44.7267 20.1882 45.9746 19.7854C46.3876 19.6523 46.6676 19.2681 46.668 18.8342V14.8577C46.668 13.1123 45.2317 11.676 43.4863 11.676H27.7031L10.7266 5.77759C10.3335 5.63995 9.90515 5.65735 9.53516 5.63501ZM9.68164 7.61939C9.81132 7.61055 9.94661 7.6276 10.082 7.67212C13.9233 9.00598 17.7643 10.3412 21.6055 11.676H7.70508L8.82031 8.30688C8.95471 7.90061 9.2926 7.64591 9.68164 7.61939ZM5.84961 13.676H11.668V14.6272C11.668 15.1795 12.1157 15.6272 12.668 15.6272C13.2203 15.6272 13.668 15.1795 13.668 14.6272V13.676H43.4863C44.1584 13.676 44.668 14.1855 44.668 14.8577V18.262C42.8211 19.235 41.6678 21.3319 41.668 23.676C41.6685 26.0194 42.8216 28.1155 44.668 29.0881V34.4944C44.668 35.1665 44.1584 35.676 43.4863 35.676H13.666C13.671 35.3309 13.668 34.9758 13.668 34.6272C13.668 34.0749 13.2202 33.6272 12.668 33.6272C12.1157 33.6272 11.668 34.0749 11.668 34.6272C11.666 34.9743 11.6688 35.3331 11.67 35.676H5.84966C5.17734 35.676 4.66797 35.1665 4.66797 34.4944V29.0901C6.51485 28.1171 7.66818 26.0201 7.66797 23.676C7.66745 21.3327 6.51433 19.2366 4.66797 18.2639V14.8577C4.66797 14.1855 5.17749 13.676 5.84961 13.676ZM27.8262 37.676H41.5957L40.5156 40.9417C40.3362 41.484 39.7914 41.7612 39.2617 41.5803L27.8262 37.676Z"
        fill="#1B9AAA"
      />
      <path
        d="M33.4297 26.1923C35.2098 26.1923 36.6738 27.6563 36.6738 29.4365C36.6738 31.2166 35.2098 32.6826 33.4297 32.6826C31.6496 32.6826 30.1836 31.2166 30.1836 29.4365C30.1836 27.6563 31.6496 26.1923 33.4297 26.1923ZM33.4297 28.1923C32.7304 28.1923 32.1836 28.7372 32.1836 29.4365C32.1836 30.1357 32.7304 30.6826 33.4297 30.6826C34.1289 30.6826 34.6738 30.1357 34.6738 29.4365C34.6738 28.7372 34.1289 28.1923 33.4297 28.1923Z"
        fill="#1B9AAA"
      />
      <path
        d="M23.9062 16.67C25.6864 16.67 27.1504 18.1341 27.1504 19.9142C27.1504 21.6943 25.6864 23.1583 23.9062 23.1583C22.1261 23.1583 20.6602 21.6943 20.6602 19.9142C20.6602 18.1341 22.1261 16.67 23.9062 16.67ZM23.9062 18.67C23.207 18.67 22.6602 19.2149 22.6602 19.9142C22.6602 20.6134 23.207 21.1583 23.9062 21.1583C24.6055 21.1583 25.1504 20.6134 25.1504 19.9142C25.1504 19.2149 24.6055 18.67 23.9062 18.67Z"
        fill="#1B9AAA"
      />
      <path
        d="M32.7225 19.2073L23.1991 28.7307C22.8086 29.1212 22.8086 29.7542 23.1991 30.1448C23.5896 30.5352 24.2226 30.5352 24.6131 30.1448L34.1366 20.6213C34.527 20.2308 34.527 19.5978 34.1366 19.2073C33.7281 18.8441 33.1076 18.806 32.7225 19.2073Z"
        fill="#1B9AAA"
      />
    </svg>
  );
};

export default IconPromoCode;
