import React, { useEffect } from 'react';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
import workingHourActions from '../services/actions';
import WorkingHourDetailFilter from '../components/Detail/Filter';
import LayoutDetailView from '../components/Detail/Views';
import moment from 'moment';
// eslint-disable-next-line @typescript-eslint/ban-types
type IWorkingHourDetailProps = {};
const WorkingHourDetail: React.FC<IWorkingHourDetailProps> = () => {
  const dispatch = useAppDispatch();


  useEffect(() => {
    dispatch(workingHourActions.initData());
    dispatch(workingHourActions.setParams({
      date: moment().unix(),
      start_date: undefined,
      end_date: undefined,
    }));
  }, []);

  return (
    <WorkingHourDetailStyled>
      <WorkingHourDetailFilter />
      <LayoutDetailView />
    </WorkingHourDetailStyled>
  );
};

export default WorkingHourDetail;
const WorkingHourDetailStyled = styled.div`
    padding-right: 24px;
    position: absolute;
    top: 130px;
    @media only screen and (max-width: 767.98px) {
      padding-right: 0%;
    position: relative;
    top: 0%;
    }
`;
