import { Empty, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import message from 'components/common/Message';
import ModalConfirm, { ModalConfirmRef } from 'components/common/Modal/ModalConfirm';
import serviceActions from 'features/services/services/actions';
import apisService from 'features/services/services/apis';
import serviceSelectors from 'features/services/services/selectors';
import { ICategoryResItem } from 'features/services/services/types/category';
import CategoryEdit from 'features/services/widgets/category/Edit';
import { get } from 'lodash';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { useSetLoadingPage } from 'services/UI/LoadingPage';
import { useAppDispatch } from 'store/hooks';

const CategoryTable = () => {
  const dispatch = useAppDispatch();
  const loading = serviceSelectors.category.getLoading();
  const lstData = serviceSelectors.category.getData();
  const pagination = serviceSelectors.category.getPagination();
  const params = serviceSelectors.category.getParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const confirmRef = useRef<ModalConfirmRef>(null);
  const setPageLoading = useSetLoadingPage();
  const {t: serviceLang} = useTranslation('service');
  const handleCategoryDelete = async (id: number) => {
    setPageLoading(true);
    try {
      const res = await apisService.category.delete(id);
      const msg = get(res, 'data.message', '');
      if (msg) {
        message.success(msg);
        dispatch(serviceActions.category.getLstData.fetch(params));

      } else {
        const msgError = get(res, 'data.error.message', '');
        if (msgError) {
          message.error(msgError);
        } else {
          throw 'fail';
        }
      }

    } catch (error) {
      const errors = Object.entries(get(error, 'response.data.errors', {}));
      if (errors.length > 0) {
        errors.map(([, valueArr]) => {
          message.error(get(valueArr, [0], ''));
        });
        return;
      }
      message.error(serviceLang('AnErrorOccurredPleaseTryAgain')||'');
    } finally {
      setPageLoading(false);
    }
  };

  const onDelete = (id: number) => {
    confirmRef.current?.show({
      title: serviceLang('AreYouSure'),
      icon: 'delete',
      msg: serviceLang('AreYouSureRemoveThisCategory'),
      submit: () => handleCategoryDelete(id),
    });
  };

  const columns: ColumnsType<ICategoryResItem> = [
    {
      title: '',
      dataIndex: 'color',
      key: 'color',
      fixed: 'left',
      width: '100px',
      className: 'nowrap',
      render: (color: string) => {
        return (
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
            <circle cx="12" cy="12.5" r="12" fill={color} />
          </svg>
        );
      },
    },
    {
      title: serviceLang('CategoryName'),
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: serviceLang('NumberOfServices'),
      dataIndex: 'total_services',
      key: 'total_services',
    },
    {
      title: serviceLang('Status'),
      dataIndex: 'status',
      key: 'status',
      render: (text: number) => (
        <div className={`common-status is-green ${!text ? 'disabled' : ''}`}>{!text ? serviceLang('Disabled') : serviceLang('Active')}</div>
      ),
    },
    {
      title: serviceLang('Actions'),
      dataIndex: 'actions',
      key: 'actions',
      render: (text: string, record: any) => {
        return (
          <ul className={'common-action'}>
            <CategoryEdit data={record} />
            <li onClick={() => onDelete(record.id)}
              className='action-remove'
            ></li>
          </ul>
        );
      },
    },
  ];

  return (
    <>
      <Table
        className={'custom-table'}
        dataSource={lstData}
        loading={loading}
        scroll={{ x: 900 }}
        columns={columns}
        rowKey={(record) => record?.id}
        pagination={{
          total: pagination?.total_record,
          defaultCurrent: Number(searchParams.get('page') ?? 1),
          defaultPageSize: Number(searchParams.get('per_page') ?? 10),
          onChange: (pageChange, sizeChange) => {
            const params: any = {
              page: pageChange == 0 ? 1 : pageChange,
              per_page: sizeChange,
            };
            setSearchParams(params);

            dispatch(serviceActions.category.setParams({
              page: params.page,
              per_page: params.per_page,
            }));
          },
          showSizeChanger: false,
          // showTotal(total) {
          //   return `Total ${total} items`;
          // },
        }}
        locale={{
          emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={serviceLang('NoData')} />,
        }}
      />
      <ModalConfirm ref={confirmRef} />
    </>
  );
};

export default CategoryTable;
