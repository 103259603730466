import styled from 'styled-components';
import DateHorizontalPickerItem from '../DateHorizontalPicker/DateHorizontalPickerItem';
import moment from 'moment';
import { getDaysBetweenDates } from 'utils/unit';
import bookingActions from 'features/bookings/services/actions';
import { IApiBookingParams } from 'features/bookings/services/types/booking';
import { useDispatch } from 'react-redux';
import bookingSelectors from 'features/bookings/services/selectors';

/**
 * Render Date Horizontal week picker (Mobile)
 * @returns 
 */
const DateHorizontalWeekPicker = () => {

  const dispatch = useDispatch();

  const startDateStore = bookingSelectors.getCalendarParamValue(
    'start_date'
  ) as number;
  const endDateStore = bookingSelectors.getCalendarParamValue(
    'end_date'
  ) as number;

  const dates = getDaysBetweenDates(moment(startDateStore), moment(endDateStore));
  const dateSelected = bookingSelectors.getCalendarParamValue('date');
  

  const onChangeDate = (_date: number, addOnParams?: IApiBookingParams) => {
    dispatch(
      bookingActions.setCalendarBookingParams({
        start_date: startDateStore,
        end_date: endDateStore,
        date: _date,
        ...(addOnParams ?? {}),
      })
    );
  };


  return (
    <DateHorizontalWeekPickerStyled>
      {dates.map((date, index) => (
        <DateHorizontalPickerItem
          onSelect={() => onChangeDate(date.valueOf())} 
          key={index} 
          date={date.format()} 
          isSelected={date.isSame(moment(dateSelected), 'date')} 
          isCurrent={date.isSame(moment(), 'date')} />
        ))}
    </DateHorizontalWeekPickerStyled>
  );
};

export default DateHorizontalWeekPicker;

const DateHorizontalWeekPickerStyled = styled.div`
  display: flex;
  justify-content: space-between;
  overflow-x: auto;
  overflow: auto !important;
`;