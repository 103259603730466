import authActions from 'features/auth/services/actions';
import authSelectors from 'features/auth/services/selectors';
import { useEffect } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import uiSelector from 'services/UI/selectors';
import { useAppDispatch } from 'store/hooks';
import storage from 'utils/sessionStorage';

type ShieldComponent = ((props: any) => JSX.Element) | React.LazyExoticComponent<(props: any) => JSX.Element>;

/**
 * Hook refresh and check token is existed
 * @returns 
 */
const useRefreshToken = () => {
  const dispatch = useAppDispatch();
  const token = authSelectors.getToken();
  const loading = uiSelector.getRefreshTokenLoading();
  const location = useLocation();

  /**
   * Handle refresh token
   */
  const refreshToken = () => {
    const href = window.location.href;
    const fileNamePart = location?.pathname !== '/' ? href.slice(href.search(location?.pathname)) : ''; 
      storage.pathname.set(fileNamePart);
    dispatch(authActions.refreshToken({ pathname: fileNamePart }));
  };
  useEffect(() => { refreshToken(); }, []);

  const shield = (Component: ShieldComponent) => {
    if (!token) return <Navigate to={'/sign-in'} replace />;
    // return <Suspense><Component /></Suspense>;
    return <Component />;
  };

  return { loading, token, shield };
};

export default useRefreshToken;
