import { ConfigProvider, Form, Input, Select, TimePicker } from 'antd';
import DatePicker from 'components/common/DatePickerInput';
import FormRow from 'components/common/Form/FormRow';
import { Dayjs } from 'dayjs';
import configBookingDateTime from 'features/bookings/hooks/getNextWorkingDate';
import getTimeOpening from 'features/bookings/hooks/getTimeOpening';
import bookingActions from 'features/bookings/services/actions';
import { DATE_FORMAT_RESPONSE } from 'features/bookings/services/constants';
import bookingSelectors from 'features/bookings/services/selectors';
import { IMerchantLocationItemResData } from 'features/bookings/services/types/booking';
import settingSelectors from 'features/settings/services/selectors';

import { first, max, range } from 'lodash';
import moment from 'moment';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
import { daysOfWeek } from 'utils/unit';
import { formatTimeMinutes, getTimeRound5Minute } from 'utils/unit';
import 'dayjs/locale/vi';
import viVN from 'antd/lib/locale/vi_VN';
import enEL from 'antd/lib/locale/en_US';
import dayjs from 'dayjs';
const { TextArea } = Input;

const BookingDetails: React.FC<{ modalConfirmRef: any, isTimeRoster?: boolean }> = ({
  isTimeRoster = false
}) => {
  const dispatch = useAppDispatch();
  const form = Form.useFormInstance();
  const locationsStore = bookingSelectors.bookingOnline.getLocations();
  const setting = settingSelectors.getSettingBookingForm();
  const [isFirst, setIsFirst] = useState(true);
  // const workingHour = bookingSelectors.getWorkingHour();
  const isSetBookingDateTimeDefault = useRef<boolean>(false);
  const formValue = bookingSelectors.bookingOnline.getFormValues();
  const [dateForm, setDateForm] = useState<string>(form.getFieldValue('bookingDate')?.format());
  // const isSetBookingDateTimeDefault = useRef<boolean>(false);
  // const [disableSubmit, setDisableSubmit] = useState<boolean>();

  // const scheduleOnline = timeScheduleSelectors.getLstTimeScheduleOnline();

  // const serviceSelected = bookingSelectors.bookingOnline.getSelectedServices();


  // useEffect(() => {
  //   dispatch(settingActions.getSetting.fetch());
  // }, []);
  const activeLocation = bookingSelectors.bookingOnline.getActiveLocation();

  const lang = 'vi';
  dayjs.locale(lang === 'vi' ? 'vi' : 'en');
  const locale = lang === 'vi' ? viVN : enEL;

  useEffect(() => {

    if (!formValue?.bookingDate) return;
    setDateForm(formValue?.bookingDate);
  }, [formValue?.bookingDate]);

  const setActiveLocation = (val: IMerchantLocationItemResData | null) => {
    dispatch(bookingActions.setBookingOnlineActiveLocation(val));
  };

  const { t: formLang } = useTranslation('form');

  const locations = useMemo(() => {
    return locationsStore.map((item) => ({
      value: item.id,
      label: item.name,
    }));
  }, [locationsStore]);

  useEffect(() => {

    if (locationsStore.length > 0) {
      const defaultItem = first(locationsStore);
      form.setFieldValue('location', defaultItem?.id);
      setActiveLocation(defaultItem ?? null);
    }

  }, [locationsStore]);

  useEffect(() => {
    if (activeLocation?.id && !isSetBookingDateTimeDefault.current) {
      //assign default value to booking date on first render location
      const bookingTime = getTimeRound5Minute(moment());
      const bookingDate = getTimeRound5Minute(moment());

      const bookingDateTime = configBookingDateTime(
        bookingDate,
        bookingTime,
        activeLocation
      );

      const times = bookingTime
        .set('date', bookingDate.date())
        .set('month', bookingDate.month())
        .set('year', bookingDate.year());

      if (
        moment(times.format()).isBefore(
          moment().add(setting?.cancel_reschedule ?? 0, 'minutes')
        )
      ) {

        form.setFieldValue(
          'bookingDate',
          dayjs(bookingDateTime?.bookingDate.format())
        );
        form.setFieldValue(
          'bookingTime',
          dayjs(bookingDateTime?.bookingTime.format()).add(setting?.cancel_reschedule ?? 0, 'minutes')
        );

      } else {

        form.setFieldValue(
          'bookingDate',
          dayjs(bookingDateTime?.bookingDate.format())
        );
        form.setFieldValue(
          'bookingTime',
          dayjs(bookingDateTime?.bookingTime.format())
        );
      }

      dispatch(bookingActions.setBookingOnlineFormValues({
        bookingDate: form.getFieldValue('bookingDate')?.format(),
        bookingTime: form.getFieldValue('bookingTime')?.format()
      }));

      isSetBookingDateTimeDefault.current = true;
    }
  }, [activeLocation]);

  const date_closed: { start: Dayjs, end: Dayjs }[] | undefined = useMemo(() => {
    return activeLocation?.date_closed.map((o) => ({
      start: dayjs(o.start_date, 'YY-MM-DD'),
      end: dayjs(o.end_date, 'YY-MM-DD'),
    }));
  }, [activeLocation?.date_closed]);

  const getLocationTimeOpenings = (bookingDate: Dayjs) => {
    const bookingDay = daysOfWeek?.find(day => day.index === bookingDate.day())?.value ?? '';
    const locationTimeOpenings = activeLocation?.time_opening?.filter(time => time.weekday === bookingDay) ?? [];
    return locationTimeOpenings;
  };

  const getDisabledHours = () => {

    if (!dateForm || !form.getFieldValue('bookingDate')) return [];

    const hoursDisable: number[] = [];
    const hourActive: number[] = [];
    const nowHour: number = dayjs().hour();
    const bookingDate: Dayjs = dateForm ? dayjs(dateForm) : form.getFieldValue('bookingDate');

    const locationTimeOpenings = getLocationTimeOpenings(bookingDate);


    for (const locationTimeOpening of locationTimeOpenings) {

      const locationOpenTimeHour: number = moment(locationTimeOpening?.time_start, 'HH:mm:ss').hour() ?? 0;
      let locationCloseTimeHour: number = moment(locationTimeOpening?.time_end, 'HH:mm:ss').hour() ?? 0;

      if (moment(locationTimeOpening?.time_end, 'HH:mm:ss').minute() > 0) {
        locationCloseTimeHour += 1;
      }
      const hourFirstActive = bookingDate.isSame(dayjs(), 'date') ? max([locationOpenTimeHour, nowHour]) ?? 0 : locationOpenTimeHour;

      hourActive.push(...range(hourFirstActive, locationCloseTimeHour));
    }

    range(0, 24).forEach((hour) => {
      if (!hourActive.includes(hour)) {
        hoursDisable.push(hour);
      }
    });

    return hoursDisable;
    // hoursDisable.push(...getNumberBetweenNumbers(locationCloseTimeHour, hourFinish));

  };

  const getDisabledMinutes = (hour: number): number[] => {

    const minuteDisable: number[] = [];
    const minuteInit = 0;
    const minuteFinish = 59;

    const bookingDate: Dayjs = dayjs(dateForm);

    const locationTimeOpenings = getLocationTimeOpenings(bookingDate);

    for (const locationTimeOpening of locationTimeOpenings) {
      const locationOpenTimeHour = moment(locationTimeOpening?.time_start, 'HH:mm:ss');
      const locationCloseTimeHour = moment(locationTimeOpening?.time_end, 'HH:mm:ss');

      if (locationOpenTimeHour.hour() === hour) {
        minuteDisable.push(...range(minuteInit, locationOpenTimeHour.minute()));
      }

      if (locationCloseTimeHour.hour() === hour) {
        minuteDisable.push(...range(locationCloseTimeHour.minute(), minuteFinish));
      }
    }

    if (bookingDate.format(DATE_FORMAT_RESPONSE) === moment().format(DATE_FORMAT_RESPONSE) && hour === moment().hour()) {
      minuteDisable.push(...range(minuteInit, moment().minute()));
    }

    return minuteDisable;
  };

  const isDisableDate = (d: Dayjs) => {
    if (
      !d ||
      d.isBefore(dayjs(), 'date')
    )
      return true;

    const isDateClosed = !!date_closed?.some(
      (o: any) => {

        if (o.end.format('YY-MM-DD') === o.start.format('YY-MM-DD')) {
          return d.format('YY-MM-DD') === o.start.format('YY-MM-DD') || d.isSame(o.start);
        }

        return d.format('YY-MM-DD') == o.start.format('YY-MM-DD') ||
          d.isBetween(o.start, o.end);
      }
    );
    if (isDateClosed) return true;

    return !activeLocation?.time_opening.some(
      (o) => o?.weekday === d?.locale('en').format('dddd')?.toLowerCase()
    );
  };

  const [_bookingTime, setBookingTime] = useState<Dayjs | null>(null);

  useEffect(() => {
    if (!_bookingTime) return;

    form.setFields([
      {
        name: 'bookingTime',
        errors: [],
      },
    ]);
    setIsFirst(false);
    // handleCheckEmployeeAvailable();

    form.setFieldValue('bookingTime', dayjs(_bookingTime.isValid() ? _bookingTime.format() : undefined));

    dispatch(bookingActions.setBookingOnlineFormValues({
      bookingDate: form.getFieldValue('bookingDate')?.format(),
      bookingTime: form.getFieldValue('bookingTime')?.format()
    }));

  }, [_bookingTime]);
  const { t: dateLang } = useTranslation('date');
  const { t: bookingLang } = useTranslation('booking');
  const checkBookingTimeValid = () => {
    const date = form.getFieldValue('bookingDate') as Dayjs;
    const time = form.getFieldValue('bookingTime') as Dayjs;
    if (!time || !date) return;

    const times = time
      .set('date', date.date())
      .set('month', date.month())
      .set('year', date.year());

    if (
      moment(times.format()).isBefore(
        moment().add(setting?.cancel_reschedule ?? 0, 'minutes')
      ) &&
      !isFirst
    ) {
      form.setFields([
        {
          name: 'bookingTime',
          errors: [
            `${bookingLang('SorryForInconvenienceWeWillServeBetterIfHaveABookingBefore')} ${formatTimeMinutes(
              setting?.cancel_reschedule ?? 0, dateLang
            )}`,
          ],
        },
      ]);
      return;
    }

    form.setFields([
      {
        name: 'bookingTime',
        errors: [],
      },
    ]);
  };

  useEffect(() => {
    form.setFields([
      {
        name: 'bookingTime',
        errors: [],
      },
    ]);
  }, []);

  return (
    <BookingDetailsStyled>
      {!isTimeRoster ? <div className='row'>
        <ConfigProvider locale={locale}>
          <FormRow
            containerClassName='form_item'
            label={bookingLang('BookingDate')}
            name='bookingDate'
            rules={[
              { required: true, message: bookingLang('PleaseSelectYourBookingDate') || '' },
              {
                validator(rule, value, callback) {
                  if (isDisableDate(value))
                    callback(`${bookingLang('BookingDateInvalidWithCurrentLocation')}`);
                  else callback(undefined);
                },
              },
            ]}
          >

            <DatePicker
              clearIcon={false}
              showToday={false}
              className='picker-date'
              name='bookingDate'
              disabledDate={isDisableDate}
              onChange={(d) => {
                if (!d) return;

                const timeOpening = getTimeOpening(d?.format(), activeLocation);
                const isBetween = moment(_bookingTime?.format()).isBetween(
                  timeOpening?.timeStart,
                  timeOpening?.timeEnd
                );

                if (!isBetween) {
                  setBookingTime(dayjs(timeOpening?.timeStart.format()));
                }
                checkBookingTimeValid();
                dispatch(bookingActions.setBookingOnlineFormValues({
                  bookingDate: form.getFieldValue('bookingDate')?.format(),
                  bookingTime: form.getFieldValue('bookingTime')?.format()
                }));
                setDateForm(form.getFieldValue('bookingDate')?.format());
                // dispatch(bookingActions.setBookingOnlineTeamMemberSelected([]));
                // handleCheckEmployeeAvailable();
              }}
            />

          </FormRow>
        </ConfigProvider>
        <ConfigProvider locale={locale}>
          <Form.Item shouldUpdate>
            {() => {
              const _date = form.getFieldValue('bookingDate')?.format();
              const today = moment();
              const isNow = moment(_date).isSame(today, 'day');

              // const timeOpening = getTimeOpening(_date, activeLocation);
              // const _timeStart = timeOpening?.timeStart.get('hour') || workingHour.open;
              // const _timeEnd = timeOpening?.timeEnd.get('hour') || workingHour.close;

              return (
                <FormRow containerClassName='form_item' label={bookingLang('BookingTime')}>
                  <Form.Item
                    name='bookingTime'
                    rules={[
                      {
                        required: true,
                        message: bookingLang('PleaseSelectYourBookingTime') || '',
                      },
                      {
                        validator(rule, value, callback) {
                          if (activeLocation?.time_opening) {
                            const bookingDate = form.getFieldValue('bookingDate');
                            const formatBookingDate = (bookingDate as any).format();
                            const formatBookingTime = (value as any).format(
                              'HH:mm:ss'
                            );

                            const timeOpening = getTimeOpening(
                              formatBookingDate,
                              activeLocation
                            );
                            if (
                              moment(formatBookingTime, 'HH:mm:ss').isBefore(
                                moment(timeOpening?.timeStart, 'HH:mm:ss')
                              )
                            ) {
                              return callback(
                                `${bookingLang('ChooseBookingTimeAfterOpenTime')}`
                              );
                            }

                            if (
                              moment(formatBookingTime, 'HH:mm:ss').isAfter(
                                moment(timeOpening?.timeEnd, 'HH:mm:ss')
                              )
                            ) {
                              return callback(
                                `${bookingLang('ChooseBookingTimeBeforeCloseTime')}`
                              );
                            }
                          }

                          callback(undefined);
                        },
                      },
                    ]}
                  >
                    <TimePicker
                      placeholder='__:__'
                      format={'HH:mm'}
                      inputReadOnly
                      minuteStep={5}
                      hourStep={1}
                      clearIcon={false}
                      showNow={isNow}
                      className='is-time-picker'
                      disabledHours={getDisabledHours}
                      disabledMinutes={getDisabledMinutes}
                      onSelect={setBookingTime}
                      dropdownClassName='time-picker-custom'
                    />
                  </Form.Item>
                </FormRow>
              );
            }}
          </Form.Item>
        </ConfigProvider>
      </div> : <></>}
      {locations.length !== 1 ? (
        <FormRow label={bookingLang('Location')} name='location'>
          <Select
            placeholder={formLang('SelectLocationLabel')}
            options={locations}
            onChange={(id) => {
              const _activeLocation = locationsStore.find(
                (o) => o.id.toString() === id?.toString()
              );
              setActiveLocation(_activeLocation ?? null);
              form.validateFields(['bookingDate']);
              form.validateFields(['bookingTime']);
              dispatch(bookingActions.bookingOnlineServiceSelected.set([]));
              dispatch(bookingActions.setBookingOnlineTeamMemberSelected([]));
            }}

          />
        </FormRow>
      ) : (
        <Form.Item name={'location'} initialValue={locations[0]} />
      )}
      {!isTimeRoster ? <FormRow label={bookingLang('AppointmentNote')} name={'note'} optional>
        <TextArea rows={6} placeholder={bookingLang('TypeSomeNotesForOurStore') || ''} />
      </FormRow> : <></>}
    </BookingDetailsStyled>
  );
};

export default BookingDetails;

const BookingDetailsStyled = styled.div`
  .row {
    display: flex;
    justify-content: space-between;
    margin: 0 -6px;
    @media only screen and (max-width: 767.98px) {
      display: block;
    }
    .form_item {
      flex: 1;
      margin: 0 6px;
    }
  }
  .ant-picker-status-error {
    background-color: var(--color-white-01) !important;
  }
`;