/* eslint-disable @typescript-eslint/no-unused-vars */
import { Checkbox, Input, Radio, RadioChangeEvent, Select, message } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import apisAuth from '../services/apis';
import authActions from '../services/actions';
import { first, get } from 'lodash';
import { useAppDispatch } from 'store/hooks';
import { useSetLoadingPage } from 'services/UI/LoadingPage';
type Props = {
    ToRegiterSuccess: any;
    ToLogin: () => void;
    isStep: any;
    setIsStep: any;
};

/**
 * Sign up
 * @param ToRegiterSuccess , ToLogin, isStep, setIsStep
 * @returns 
 */
const SignUp = ({
    ToRegiterSuccess = () => undefined,
    ToLogin = () => undefined,
    isStep,
    setIsStep
}: Props) => {
    const { t: formLang } = useTranslation('form');
    const [phone, setPhone] = useState('');
    const [password, setPassword] = useState('');
    const [enterThePassword, setEnterThePassword] = useState('');

    // const [domain, setDomain] = useState('');

    const [errorPhone, setErrorPhone] = useState(false);
    const [errorPassword, setErrorPassword] = useState(false);
    const [errorEnterThePassword, setErrorEnterThePassword] = useState(false);
    const dispatch = useAppDispatch();
    const [isShowInputPass,] = useState(false);

    /**
     * Handle btn click email
     * @param event 
     */
    const handleBtnClickEmail = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        let hasError = false;

        if (!/^0+[0-9]{9}$/.test(phone)) {
            setErrorPhone(true);
            hasError = true;
        } else {
            setErrorPhone(false);
        }

        if (password === '') {
            setErrorPassword(true);
            hasError = true;
        } else {
            setErrorPassword(false);
        }

        if (enterThePassword !== password) {
            setErrorEnterThePassword(true);
            hasError = true;
        } else {
            setErrorEnterThePassword(false);
        }
        if (!hasError) {
            setIsStep(2);
        }
    };

    const [selectedCareer, setSelectedCareer] = useState(1);
    const [companyName, setCompanyName] = useState('');
    const [address, setAddress] = useState('');
    const [valueModel, setValueModel] = useState(1);

    const [errorCareer, setErrorCareer] = useState(false);
    const [errorCompanyName, setErrorCompanyName] = useState(false);

    const optionsCareer = [
        { value: 1, label: 'Nail Salon' },
        { value: 2, label: 'Hair Salon' },
    ];

    /**
     * Handle change career
     * @param value 
     */
    function handleChangeCareer(value: any) {
        setSelectedCareer(value);
    }

    /**
     * On Change model
     * @param e 
     */
    const onChangeModel = (e: RadioChangeEvent) => {
        setValueModel(e.target.value);
    };

    const setIsLoading = useSetLoadingPage();

    const payload = {
        phone: phone,
        password: password,
        confirm_password: enterThePassword,
        job_id: selectedCareer,
        model_type_id: valueModel,
        name: companyName,
        address: address,
    };

    /**
     * Handle success sign up
     * @param event 
     */
    const handleSuccessSignUp = async (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();

        let hasError = false;
        if (!selectedCareer) {
            setErrorCareer(true);
            hasError = true;
        } else {
            setErrorCareer(false);
        }
        if (companyName === '') {
            setErrorCompanyName(true);
            hasError = true;
        } else {
            setErrorCompanyName(false);
        }

        if (!hasError) {
            try {
                const res = await apisAuth.register(payload);

                if (!res.data?.data) {
                    throw { response: res };
                }
                dispatch(authActions.register.success(res));
                setIsLoading(true);
                ToRegiterSuccess();
                const msg = get(res, 'data.data.message', '');
                message.success(msg);
            } catch (error) {
                const errors = Object.values(get(error, 'response.data.errors', {}));
                const msg = get(first(errors), [0], '');
                if (msg)
                    message.error(msg);
                dispatch(authActions.register.fail({}));
            } finally {
                setIsLoading(false);
            }
        }
    };

    return (
        <FormSignUpStyled>
            <form className='formSignUp'>
                {
                    isStep === 1 ?
                        <div className='AccountInformationContainer'>
                            <div className='form-row'>
                                <span className='title-domain'>{formLang('SignUp.PhoneNumber/Zalo')} (*)</span>
                                <Input
                                    value={phone}
                                    onChange={(e) => setPhone(e.target.value)}
                                    type='number'
                                    min={0}
                                    placeholder={formLang('SignUp.InputPhoneNumber/ZaloPlaceholder') || ''}
                                    disabled={isShowInputPass}
                                    autoFocus
                                    className={errorPhone ? 'error-input' : ''}
                                ></Input>
                                {!!errorPhone && (
                                    <p style={{ color: 'red', textAlign: 'start', margin: 'auto', width: '502px', maxWidth: '100%', marginTop: '4px' }}>
                                        {formLang('SignUp.ErrorPhoneNumber/Zalo')}
                                    </p>
                                )}
                            </div>
                            <div className='form-row'>
                                <span className='title-domain'>{formLang('SignUp.PasswordLabel')} (*)</span>
                                <Input
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    type='password'
                                    placeholder={formLang('SignUp.InputPasswordPlaceholder') || ''}
                                    disabled={isShowInputPass}
                                    autoFocus
                                    className={errorPassword ? 'error-input' : ''}
                                ></Input>
                                {!!errorPassword && (
                                    <p style={{ color: 'red', textAlign: 'start', margin: 'auto', width: '502px', maxWidth: '100%', marginTop: '4px' }}>
                                        {formLang('SignUp.ErrorPassword')}
                                    </p>
                                )}
                            </div>
                            <div className='form-row'>
                                <span className='title-domain'>{formLang('SignUp.EnterThePasswordLabel')} (*)</span>
                                <Input
                                    value={enterThePassword}
                                    onChange={(e) => setEnterThePassword(e.target.value)}
                                    type='password'
                                    placeholder={formLang('SignUp.InputEnterThePasswordPlaceholder') || ''}
                                    disabled={isShowInputPass}
                                    autoFocus
                                    className={errorEnterThePassword ? 'error-input' : ''}
                                ></Input>
                                {!!errorEnterThePassword && (
                                    <p style={{ color: 'red', textAlign: 'start', margin: 'auto', width: '502px', maxWidth: '100%', marginTop: '4px' }}>
                                        {formLang('SignUp.ErrorEnterThePassword')}
                                    </p>
                                )}
                            </div>
                        </div>
                        :
                        <div className='AccountInformationContainer'>
                            <div className='form-row'>
                                <span className='title-domain'>{formLang('Career')} (*)</span>
                                <Select
                                    onChange={handleChangeCareer}
                                    options={optionsCareer}
                                    placeholder={formLang('ChooseACareer') || ''}
                                />
                                {!!errorCareer && (
                                    <p style={{ color: 'red', textAlign: 'start', margin: 'auto', width: '502px', maxWidth: '100%', marginTop: '4px' }}>
                                        {formLang('PleaseSelectaCareer')}
                                    </p>
                                )}
                            </div>
                            <div className='form-row'>
                                <span className='title-domain'>{formLang('SelectModel')} (*)</span>
                                <Radio.Group onChange={onChangeModel} value={valueModel}>
                                    <Radio value={1}>{formLang('Individual')}</Radio>
                                    <Radio value={2}>{formLang('HouseholdBusiness')}</Radio>
                                    <Radio value={3}>{formLang('Enterprise')}</Radio>
                                </Radio.Group>
                            </div>
                            <div className='form-row'>
                                <span className='title-domain'>{formLang('CompanyName')} (*)</span>
                                <Input
                                    value={companyName}
                                    onChange={(e) => setCompanyName(e.target.value)}
                                    type='text'
                                    placeholder={formLang('InputCompanyNamePlaceholder') || ''}
                                    disabled={isShowInputPass}
                                    autoFocus
                                    className={errorPassword ? 'error-input' : ''}
                                ></Input>
                                {!!errorCompanyName && (
                                    <p style={{ color: 'red', textAlign: 'start', margin: 'auto', width: '502px', maxWidth: '100%', marginTop: '4px' }}>
                                        {formLang('PleaseEnterCompanyName')}
                                    </p>
                                )}
                            </div>
                            <div className='form-row'>
                                <span className='title-domain'>{formLang('Address')}</span>
                                <Input
                                    value={address}
                                    onChange={(e) => setAddress(e.target.value)}
                                    type='text'
                                    placeholder={formLang('InputAddressPlaceholder') || ''}
                                    disabled={isShowInputPass}
                                    autoFocus
                                    className={errorPassword ? 'error-input' : ''}
                                ></Input>
                            </div>
                        </div>
                }


                <div className='BusinessInformationContainer'>
                    {
                        isStep === 1 ?
                            <>
                                <div>
                                    <Checkbox>{formLang('SignUp.IAgreeWith')} <a href="#">{formLang('SignUp.RegistrationAgreement')}</a> {formLang('SignUp.and')} <a href="#">{formLang('SignUp.PrivacyPolicy')}</a> {formLang('SignUp.ByMindHana')}.</Checkbox>
                                </div>
                                <button
                                    className='btn-submit'
                                    onClick={handleBtnClickEmail}
                                >
                                    {formLang('Continue')}
                                </button>
                            </>
                            :
                            <button
                                className='btn-submit'
                                onClick={handleSuccessSignUp}
                            >
                                {formLang('SignUp.Register')}
                            </button>
                    }

                    <span className='HaveAccount'>{formLang('SignUp.DoYouAlreadyHaveAnAccount')} <span className='login' onClick={ToLogin}> {' '} {formLang('SignUp.Login')}</span></span>
                    <div className='auth-contact'>
                        <p className='auth-contact-p'>{formLang('TextContact')}
                            <a className='auth-contact-a' href="#"> 0964074541 </a> {formLang('TextForSupport')}</p>
                    </div>
                </div>
            </form>
        </FormSignUpStyled>
    );
};

export default SignUp;

const FormSignUpStyled = styled.div`
.title-domain {
    color: var(--Gray-95, #141414);
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%;
}
.ant-select-single .ant-select-selector {
    height: 48px !important;
}
.form-row {
    display: grid;
}
.auth-contact-a {
    color: var(--Gray-95, #141414);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
}
.auth-contact-p {
    color: var(--Gray-50, #8C8C8C);
    text-align: center;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; 
}
.formSignUp {
    height: 500px;
    .AccountInformationContainer{
        text-align: start;
        .titleAccountInformation {
            font-size: 24px;
            font-weight: 600;
            line-height: 34px;
            width: 502px;
            display: flex;
            margin: auto;
            max-width: 100%;
        }
    }
    .BusinessInformationContainer {
        text-align: start;
        width: 502px;
        margin: auto;
        max-width: 100%;
        .titleBusinessInformation {
            font-size: 24px;
            font-weight: 600;
            line-height: 34px;
        }
        .ant-select {
            width: 100%;
        }
        .ant-input-wrapper {
            margin-top: 8px;
        }
        .ant-checkbox-wrapper {
            font-size: 16px;
            font-weight: 400;
            margin-bottom: 40px;
            a {
                font-size: 16px;
                font-weight: 600;
                color: #5A78FF;
            }
        }
        .HaveAccount {
            display: flex;
            justify-content: center;
            margin-top: 24px;
            font-size: 16px;
            font-weight: 400;
            .login {
                font-size: 16px;
                font-weight: 600;
                color: #1B9AAA;
                cursor: pointer;
            }
        }
        .auth-contact {
            position: inherit;
            text-align: center;
            margin: 50px 0 40px;
        }
    }
}

`;