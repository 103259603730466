import IconCheckOverview from 'assets/svg/IconCheckOverview';
import { TIME_DATE_MERCHANT } from 'features/bookings/services/constants';

import salesSelectors from 'features/sales/services/selectors';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import dayjs from 'utils/dayjs';

const CustomerInfoStepSuccess = () => {
  const customerInfo = salesSelectors.getCustomer();
  const location = salesSelectors.getMerchantLocationActive();
  const [, setSearchParams] = useSearchParams();
  const{t: bookingLang} = useTranslation('booking');
  useEffect(() => {
    // @ts-ignore
    setSearchParams({ success: 1 });
  }, []);
  return (
    <div className='complete-order'>
      <div className='complete-icon'>
        <IconCheckOverview />
      </div>
      <div className='complete-content'>
        <p className='complete-title'>{bookingLang('SaleOverview')}</p>
        <p className='complete-text'>
          {bookingLang('PaymentReceivedOn')} <span> {dayjs(new Date()).format(TIME_DATE_MERCHANT)}</span> {bookingLang('at')}{' '}
          <span>{location?.name}</span> {bookingLang('with')} <span className='customer-name'> {customerInfo?.name} </span>
          <span></span>.
        </p>
      </div>
    </div>
  );
};

export default CustomerInfoStepSuccess;
