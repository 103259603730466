import message from 'components/common/Message';
import useDoRedeem from 'features/checkout/components/PromotionCode/useDoRedeem';
import { IFuncPromotionAccept } from 'features/checkout/services/types/promotion';
import useSalesPayment from 'features/sales/hooks/useSalesPayment';
import salesActions from 'features/sales/services/actions';
import apisSales from 'features/sales/services/apis';
import salesSelectors from 'features/sales/services/selectors';
import { IBodyApiCreateSaleByCard } from 'features/sales/services/types/checkout';
import settingSelectors from 'features/settings/services/selectors';
import useIClientTyroOptimize from 'hooks/useClientTyroOptimize';
import { EResultTransactionType, ITransactionCompleteCallbackResponse } from 'hooks/useIClientTyro';
import { get } from 'lodash';
import { useTranslation } from 'react-i18next';
// import { useState } from 'react';
import { useSetLoadingPage } from 'services/UI/LoadingPage';
import { useAppDispatch } from 'store/hooks';
import { getUniqueTransactionId, roundNumber } from 'utils/unit';
// import storage from 'utils/sessionStorage';

function Card({ onSuccess, isActive }: { onSuccess: () => void, isActive?: boolean }) {
  const dispatch = useAppDispatch();
  // const [typeCardModalStatus, setTypeCardModalStatus] = useState<ICardType>(null);
  const setLoadingPage = useSetLoadingPage();
  const iClient = useIClientTyroOptimize();

  const amount = salesSelectors.getOriginTotalPrice();
  const merchant_location_id = salesSelectors.getMerchantLocationId();
  const promotionInfo = salesSelectors.getPromotionInfo();
  const softPromotionCode = salesSelectors.getSoftPromotionCode();
  const onPromotionAccept: IFuncPromotionAccept = (val) => dispatch(salesActions.setPromotionInfo(val));
  const selectedServices = salesSelectors.getSelectedServices();
  const setting = settingSelectors.getSetting();
  

  const doSoftRedeem = useDoRedeem(merchant_location_id || '', {
    servicesSelected: selectedServices,
    setErrorBoundary: (val) => dispatch(salesActions.setPromotionErrorBoundary(val)),
  });

  const salesPayment = useSalesPayment();

  const transactionCompleteCallback = (model: 'purchase' | 'refund') => async (response: ITransactionCompleteCallbackResponse) => {
    // setTypeCardModalStatus(null);
    switch (response.result) {
      case EResultTransactionType.APPROVED: {
        const paymentInfo = salesPayment.getPaymentInfo();
        
        const payload: IBodyApiCreateSaleByCard = {
          ...paymentInfo,
          transaction_id: response.transactionReference ?? getUniqueTransactionId(),
          MID: setting.general.merchant_id,
          TID: setting.general.teminal_id,
          amount_given: amount,
        };

        setLoadingPage(true);
        try {
          const res = await apisSales.apiPaymentSale.byCard(payload);
          const msg = get(res, 'data.data.message', '');
          const invoiceResult = get(res, 'data.data.notify_invoice', '');
          dispatch(salesActions.setInvoiceResult(invoiceResult));
          if (msg) {
            message.success(model === 'purchase' ? 'Purchase' : 'Refund' + ' Successful');
            onSuccess();
          } else {
            throw 'fail';
          }
        } catch (error) {
          message.error('An error occurred. Please try again');
          if (model === 'purchase') {
            iClient.refund(amount, {
              transactionCompleteCallback: transactionCompleteCallback('refund'),
            });
          }
        } finally {
          setLoadingPage(false);
        }
        return;
      }
      case EResultTransactionType.CANCELLED:
      case EResultTransactionType.REVERSED:
      case EResultTransactionType.DECLINED: {
        // setTypeCardModalStatus('wrongInfo');
        return;
      }
      case EResultTransactionType.SYSTEM_ERROR: {
        // setTypeCardModalStatus('systemError');
        return;
      }
      default: {
        // setTypeCardModalStatus('notPair');
        return;
      }
    }
  };


  const onPress = async () => {       

    if (!promotionInfo && softPromotionCode) {
      setLoadingPage(true);
      try {
        const softPromotionInfo = await doSoftRedeem(softPromotionCode);
        onPromotionAccept(softPromotionInfo);
      } catch (error) { }
      finally {
        setLoadingPage(false);
      }
      return;
    }
    if (setting.general.merchant_id === '' || setting.general.teminal_id === '') {
      setLoadingPage(true);
      const paymentInfo = salesPayment.getPaymentInfo();

        const payload: IBodyApiCreateSaleByCard = {
          ...paymentInfo,
          transaction_id: getUniqueTransactionId(),
          MID: setting.general.merchant_id,
          TID: setting.general.teminal_id,
          amount_given: amount,
        };
      try {
        const res = await apisSales.apiPaymentSale.byCard(payload);

        const msg = get(res, 'data.data.message', '');
        const invoiceResult = get(res, 'data.data.notify_invoice', '');
        dispatch(salesActions.setInvoiceResult(invoiceResult));
        if (msg) {
          message.success(msg);
          onSuccess();

        } else {
          const error = get(res, 'data.error.message', '');
          if (error) {
            message.error(error);
          } else
            throw 'fail';
          }
        } catch (error) {
          message.error('An error occurred. Please try again');
          // if (model === 'purchase') {
          //   iClient.refund(amount, {
          //     transactionCompleteCallback: transactionCompleteCallback('refund'),
          //   });
          // }
        } finally {
          setLoadingPage(false);
        }
      return;
    }
      // setTypeCardModalStatus('process');      
      iClient.purchase(roundNumber(amount), { transactionCompleteCallback: transactionCompleteCallback('purchase') });
  };

  const{t: bookingLang}= useTranslation('booking');
  return (
    <>
      <button
        type='button'
        className='form-booking-submit'
        onClick={onPress}
        disabled={isActive}
      >
        {bookingLang('Check-out')}
      </button>
      {/* <CardModal type={typeCardModalStatus} handleCancel={() => setTypeCardModalStatus(null)} /> */}
    </>
  );
}

// const CardModal = ({ type, handleCancel }: { type: ICardType, handleCancel: () => void }) => {
//   if (!type) return null;
//   return (
//     <Modal
//       maskClosable={false}
//       open
//       onCancel={handleCancel}
//       footer={null}
//       centered
//       forceRender
//     >
//       <CardPayment type={type} />
//     </Modal>
//   );
// };



export default Card;
