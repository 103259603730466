const IconPhoneCardBooking = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <g clipPath="url(#clip0_1813_173166)">
        <path
          d="M14.6666 11.28V13.28C14.6674 13.4657 14.6294 13.6494 14.555 13.8195C14.4806 13.9897 14.3715 14.1424 14.2347 14.2679C14.0979 14.3934 13.9364 14.489 13.7605 14.5485C13.5846 14.6079 13.3982 14.63 13.2133 14.6133C11.1619 14.3904 9.19131 13.6894 7.45998 12.5667C5.84919 11.5431 4.48353 10.1774 3.45998 8.56665C2.33329 6.82745 1.63214 4.84731 1.41331 2.78665C1.39665 2.60229 1.41856 2.41649 1.47764 2.24107C1.53673 2.06564 1.63169 1.90444 1.75649 1.76773C1.88128 1.63102 2.03318 1.52179 2.2025 1.447C2.37183 1.37221 2.55487 1.33349 2.73998 1.33332H4.73998C5.06351 1.33013 5.37717 1.4447 5.62248 1.65567C5.8678 1.86664 6.02803 2.15961 6.07331 2.47998C6.15772 3.12003 6.31428 3.74847 6.53998 4.35332C6.62967 4.59193 6.64908 4.85126 6.59591 5.10057C6.54274 5.34988 6.41922 5.57872 6.23998 5.75998L5.39331 6.60665C6.34235 8.27568 7.72428 9.65761 9.39331 10.6067L10.24 9.75998C10.4212 9.58074 10.6501 9.45722 10.8994 9.40405C11.1487 9.35088 11.408 9.37029 11.6466 9.45998C12.2515 9.68568 12.8799 9.84224 13.52 9.92665C13.8438 9.97234 14.1396 10.1355 14.351 10.385C14.5624 10.6345 14.6748 10.953 14.6666 11.28Z"
          stroke="#141414"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1813_173166">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default IconPhoneCardBooking;
