import { Form } from 'antd';
import dayjs from 'dayjs';
import ActiveHoursElement from 'features/locations/pages/widgets/ActiveHoursElement';
import userActions from 'features/users/services/actions';
import userSelectors from 'features/users/services/selectors';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

type Props = {
  onCancel?: () => void;
  onNextStep?: () => void;
  onPrevStep?: () => void;
  onSubmit?: () => void;
  isEdit?: boolean;
};

export const dayOfWeek = [
  {
    label: 'SUN',
    value: 'sunday',
  },
  {
    label: 'MON',
    value: 'monday',
  },
  {
    label: 'TUE',
    value: 'tuesday',
  },
  {
    label: 'WED',
    value: 'wednesday',
  },
  {
    label: 'THU',
    value: 'thursday',
  },
  {
    label: 'FRI',
    value: 'friday',
  },
  {
    label: 'SAT',
    value: 'saturday',
  },
];

export enum WorkTimeStatus {
  AVAILABLE = 'AVAILABLE',
  UNAVAILABLE = 'UNAVAILABLE'
}

const UserFormFouthStep = ({
  isEdit
}: Props) => {
  const form = Form.useFormInstance();
  const dispatch = useDispatch();

  const locationId = form.getFieldValue('location');
  const locationDetail = userSelectors.getLocationDetail();
  const {t: dateLang} = useTranslation('date');
  useEffect(() => {
    if (locationId) {
      dispatch(userActions.getLocationDetail.fetch(locationId));
    }
  }, [locationId]);

  const locationOpenDate = useMemo(() => {
    if (locationDetail){
      const weekDateWorkingTime = locationDetail?.weekday_working_times;
      
      const formattedData: { [weekday: string]: { time_start: string, time_end: string }[] } = {};

      weekDateWorkingTime?.forEach((item: any) => {
        const weekday = item?.weekday;
        const time_start = item?.time_start;
        const time_end = item?.time_end;
        if (!formattedData[weekday]) {
          formattedData[weekday] = [];
        }
        formattedData[weekday].push({ time_start, time_end });
      });

      const formattedResult = Object.keys(formattedData).map(weekday => ({
        weekday,
        time: formattedData[weekday]
      }));

      const workingTimeData = dayOfWeek?.map((item) => {
        const foundItem = formattedResult?.find((it: any) => it?.weekday === item?.value);
        
        return {
          ...item,
          timeWorking: foundItem?.time || [],
          status: foundItem ? WorkTimeStatus.AVAILABLE : WorkTimeStatus.UNAVAILABLE
        };
      });

      if (!isEdit) {
        form.setFieldValue(
          'workingTime',
          workingTimeData.reduce(
            (obj, item) =>
              Object.assign(obj, {
                [item.value]: item?.timeWorking?.map((it) => {
                  return {
                    time_start: dayjs(it?.time_start, 'H:m:s'),
                    time_end: dayjs(it?.time_end, 'H:m:s'),
                    weekday: item.value,
                    status: item?.status,
                  };
                }),
              }),
            {}
          )
        );
      }
      return workingTimeData;
    }
    return [];
  }, [locationDetail]);


  return (
    <>
      <div className='round'>
        <h3 className='title'>Giờ làm việc</h3>
        <p className='helper'>Cài đặt giờ làm việc của nhân viên.</p>
          
        <ActiveHoursStyled className='form-calendar'>
          <Form.Item name='calenda'>
            {locationOpenDate?.map((item: any, index: number) => (
              <ActiveHoursElement
                name={item.value}
                key={index}
                label={dateLang(item.label)||''}
                form={form}
                timeWorking={item?.timeWorking}
                status={item?.status}
                addUserPage={true}
              />
            ))}
          </Form.Item>
        </ActiveHoursStyled>
      </div>
    </>
  );
};

export default UserFormFouthStep;

const ActiveHoursStyled = styled.div`
  .calendar-row {
    padding: 8px 18px !important;
    @media only screen and (max-width: 767.98px) {
      padding: 8px 0px !important;
    }
  }
  .ant-form-item-control {
    margin-bottom: 0px !important;
  }

  .pick-item {
    .ant-picker {
      background-color: #D1EBEE;
    }
  }
  .calendar-add {
    background: var(--icon-plus-green) center/contain no-repeat;
  }
`;
