import React, { useEffect, useMemo, useState } from 'react';
import { Button, Form, Spin, message } from 'antd';
import { IconRight } from 'assets/svg';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import styles from './index.module.scss';
import BasicInformation from './BasicInformation';
import HoursOfOperation from './HoursOfOperation';
import locationSelectors from 'features/locations/services/selectors';
import locationActions from 'features/locations/services/actions';
import { useDispatch } from 'react-redux';
import { get, set } from 'lodash';
import dayjs from 'dayjs';
import { DayLocation, ILocationForm, WorkingTime } from 'features/locations_v2/services/types/location';
import apisLocation from 'features/locations_v2/services/apis';
import { AxiosResponse } from 'axios';
import { useSetLoadingPage } from 'services/UI/LoadingPage';
import userApis from 'features/users/services/apis';
import storage from 'utils/sessionStorage';

/**
 * Add Location Page
 * @returns 
 */
const AddLocationPage = () => {
  const [form] = Form.useForm();
  const { id } = useParams();
  const [step, setStep] = useState<number>(1);
  const [errors, setErrors] = useState<any>(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const locationDetail = locationSelectors.getLocationDetail();
  const loadingLocationDetail = locationSelectors.loadingLocationsDetail();
  const [locationId, setLocationId] = useState<number>(0);
  const setPageLoading = useSetLoadingPage();
  const [loadingInitialValues, setLoadingInitialValues] = useState(true);

  useEffect(() => {
    setLocationId(Number(id));
    if (id) {
      dispatch(locationActions.getLocationDetail.fetch(id ?? ''));
    }
    setLoadingInitialValues(false);
  }, [id, dispatch]);


  const initialValues = useMemo(() => {
    const workingTime = {};
    locationDetail?.weekday_working_times?.forEach((item: any) => {
      const time_start = dayjs(item.time_start, 'h:m:s');
      const time_end = dayjs(item.time_end, 'h:m:s');
      const exists = get(workingTime, [item.weekday]);
      if (exists) {
        exists.push({
          weekday: item.weekday,
          time_start: time_start.isValid() ? time_start : null,
          time_end: time_end.isValid() ? time_end : null,
        });
      } else {
        set(workingTime, [item.weekday], [{
          weekday: item.weekday,
          time_start: time_start.isValid() ? time_start : null,
          time_end: time_end.isValid() ? time_end : null,
        }]);
      }
    });

    return {
      name: id ? locationDetail?.name : '',
      contact_number: id ? locationDetail?.contact_number : '',
      address: id ? locationDetail?.address : '',
      manager: id ? locationDetail?.location_manager?.manager_id : '',
      managerName: id ? locationDetail?.location_manager?.full_name : '',
      managerEmail: id ? locationDetail?.location_manager?.email : '',
      managerPhoneNumber: id ? locationDetail?.location_manager?.phone : '',
      description: id ? locationDetail?.description : '',
      workingTime,
      email: id ? locationDetail?.location_manager?.email : '',
      status: id ? !!locationDetail?.status : '',
      business_abn: id ? locationDetail?.abn : '',
    };
  }, [loadingInitialValues, locationDetail]);

  /**
   * checkValid
   * @returns 
   */
  const checkValid = async () => {
    try {
      // Validate specified fields using form.validateFields
      await form.validateFields(['name', 'contact_number', 'address']);
      return true;// Return true if validation succeeds
    } catch (error: any) {
      const response = get<Record<string, string[]>>(error, 'response.data.errors', {});
      const errorsObj = {};
       // Extract errors from the response and construct errorsObj
      Object.entries(response).forEach(([key, value]) => {
        set(errorsObj, [key], value?.[0]);
      });

      errors.forEach(([, value]: any) => {
        form.setFields([
          {
            name: 'pinNumber',
            errors: [get(value, [0], '')],
          },
        ]);
      });

      return false;// Return false to indicate validation failure
    }
  };

  const onClickBack = () => {
    setStep(step - 1);
    if (step <= 1) {
      navigate(-1);
    }
  };

  const [step1Data, setStep1Data] = useState<any>({});

  const getPayload = (values: ILocationForm) => {

    const name = values.name;
    const address = values.address;
    const contact_code = values.phoneNumberCode;
    const contact_number = values.contact_number;
    const manager_id = values?.manager ?? locationDetail?.location_manager?.manager_id;


    const weekWorkingTime: any = [];
    Object.values(values.workingTime as WorkingTime).forEach((workTime) => {
      workTime.forEach((element: any) => {
        weekWorkingTime.push(element);
      });
    });

    const weekday_working_times = weekWorkingTime.map((item: DayLocation) => ({
      weekday: item?.weekday,
      time_start: item?.time_start?.format('HH:mm:ss'),
      time_end: item?.time_end?.format('HH:mm:ss'),
    }));

    return {
      manager_id,
      name,
      address,
      contact_code,
      contact_number,
      description: values.description,
      // manager_phone,
      // manager_name,
      // manager_email,
status: values.status ? 1 : 0,
      weekday_working_times,
      abn: values.business_abn,
    };
  };

  const onClickNext: React.MouseEventHandler<HTMLButtonElement> = async (e) => {
    e.preventDefault();
    const isFormValid = await checkValid();
    if (!isFormValid) return;
    setStep1Data(form.getFieldsValue());
    setStep(step + 1);
    if (step >= 2) {
      setStep(2);
    }
  };

  const _onSubmit: React.MouseEventHandler<HTMLButtonElement> = async (e) => {
    e.preventDefault();
    form.submit();
  };

  const handleSubmit = async (values: any) => {
    setErrors(null);
    setPageLoading(true);
    
    try {
      const mergedData = { ...step1Data, ...values };
      // form.setFieldsValue(mergedData);
      const payload = getPayload(mergedData);

      if (!id) {
        const rest: AxiosResponse<{ data: { message: string, id: number } }> = await apisLocation.storeLocation(payload);
        const msg = get(rest, 'data.data.message', '');
        if (msg) {
          message.success(rest.data.data.message);
          await userApis.createStaffs({
          full_name: step1Data.managerName,
          is_receive_book: true,
          access_level: 1,
          merchant_id: storage.merchantId.get(),
          merchant_location_id: rest.data.data?.id,
          phone: step1Data.contact_number,
          services: [],
          weekday_working_times: []
          });
          navigate(-1);
        }

        return false;
      }

      const rest: AxiosResponse<{ data: { message: string } }> =
        await apisLocation.updateLocation(locationId, payload);
      const msg = get(rest, 'data.data.message', '');

      if (msg) {
        message.success(rest?.data?.data?.message);
        navigate(-1);
      }

      return false;
    } catch (error: any) {
      const response = get<Record<string, string[]>>(error, 'response.data.errors', {});
      const _errors = {};

      Object.entries(response).forEach(([key, value]) => {
        set(_errors, key, get(value, 0, ''));
      });

      setErrors(_errors);
      return false;
    } finally {
      setTimeout(() => {
        setPageLoading(false);
      }, 100);
    }
  };

  if (loadingLocationDetail || loadingInitialValues) return <Spin />;

  return (
    <Spin spinning={false}>
      <Form
        form={form}
        onFinish={handleSubmit}
        initialValues={initialValues}
        layout='vertical'
      >
        <CheckoutPageStyled>
          <div className='block-heading'>
            <ul>
              <li className={`${step >= 1 && 'is-finish'} ${step == 1 ? 'dislay-block' : 'dislay-none'}`}>
                <span>1.</span>
                Thông tin cơ bản
              </li>
              <li className={`${step >= 2 && 'is-finish'} ${step == 2 ? 'dislay-block' : 'dislay-none'}`}>
                <span>2.</span>
                Giờ hoạt động
              </li>
            </ul>
            <div className={styles.btn_beading}>
              <Button className={styles.icon_heading} onClick={onClickBack}><IconRight /></Button>
              {
                step === 1 ?
                  <button onClick={onClickNext} className='common-btn'>Xác nhận</button>
                  :
                  <button onClick={_onSubmit} type="submit" className='common-btn'>Xác nhận</button>
              }
            </div>
          </div>
          <div className='body'>
            {step === 1 && <BasicInformation locationDetail={locationDetail} form={form} errors={errors} idLocation={id} />}
            {step === 2 && <HoursOfOperation form={form} />}
          </div>
        </CheckoutPageStyled>
      </Form>
    </Spin>
  );
};

export default AddLocationPage;

const CheckoutPageStyled = styled.div`
  padding: 0px 0px 24px;
  gap: 24px;
  max-width: 1300px;
  padding: 0 20px;
  min-height: 958px;
  background: var(--color-white);
  border-radius: 24px;
  color: var(--color-primary);
  @media only screen and (max-width: 767.98px) {
    padding: 0 8px;
  }
  .block-heading li.is-finish::before {
    display: none !important;
  }
  .block-heading {
    display: flex;
    align-items: center;
    gap: 12px;
    padding: 15px 80px;
    font-weight: 600;
    font-size: 16px;
    line-height: 140%;
    text-transform: capitalize;
    border-bottom: 1px solid #C3C2E0;
    justify-content: space-between;
    ul {
      display: flex;
      gap: 80px;
    }
    li {
      display: flex;
      gap: 12px;
      position: relative;
      align-items: baseline;
      @media only screen and (max-width: 767.98px) {
        &.dislay-none {
          display: none;
        }
        &.dislay-block {
          display: flex;
        }
      }
      &:after {
        content: "";
        display: block;
        width: 48px;
        height: 1px;
        position: absolute;
        border-bottom: 1px dashed #C3C2E0;
        top: 50%;
        left: calc(100% + 16px);
      }
      &.is-finish {
        color: #1B9AAA;
        border-bottom: 2px solid #1B9AAA;
        &:before {
          box-sizing: border-box;
          position: absolute;
          top: 50%;
          inset-inline-start: 30%;
          display: table;
          width: 5.7142857142857135px;
          height: 9.142857142857142px;
          border: 2px solid #fff;
          border-top: 0;
          border-inline-start: 2px;
          content: "";
          opacity: 1;
          transform: rotate(45deg) scale(1) translate(-50%,-50%);
          transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
        }
      }
      &:last-child:after {
        display: none;
      }
      span {
        display: flex;
        width: 20px;
        height: 20px;
        align-items: center;
        justify-content: center;
        font-weight: 500;
        font-size: 16px;
        line-height: 140%;
        padding-bottom: 2px;
      }
    }
    
  }
  
  .is-complete {
    .list-detail-items {
      max-height: unset !important;
    }
  }
  .body {
    display: flex;
    padding: 24px 56px;
    justify-content: center;
  }
  .block {
    display: flex;
    flex-direction: column;
    max-width: 50%;
    padding: 0 24px;
    flex: 1;
    border-right: 1px solid #949BDA;
    &:first-child {
      .form-row:last-child {
        margin-top: auto;
        margin-bottom: -15px;
      }
    }
    &:last-child {
      border-right: none;
      & > div:last-child {
        margin-top: auto;
      }
    }
  }
  .block-address {
    display: flex;
    gap: 4px;
    font-weight: 500;
    font-size: 14px;
    line-height: 140%;
    color: #363565;
  }
  .block-tabs {
    margin: 24px 0 16px;
  }
  .block-title {
    font-weight: 700;
    font-size: 18px;
    margin-bottom: 24px;
  }
  .form-row {
    margin-top: 48px;
    margin-bottom: 0;
  }
  .note {
    font-size: 16px;
    text-align: center;
    margin: 16px 0 80px;
    color: var(--color-primary);
  }
  .common-input-search {
    flex: 1;
    input {
      height: 48px;
      width: 100%;
      background-color: var(--color-white-01);
    }
  }
  .detail {
    display: flex;
    padding: 24px;
    height: 365px;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    font-size: 16px;
    text-align: center;
    color: var(--color-primary);
    font-weight: 500;
  }
  .total,
  .voucher {
    display: flex;
    align-items: center;
    font-size: 14px;
    text-transform: uppercase;
    color: var(--color-primary);
    span {
      flex: 1;
      font-weight: 700;
      font-size: 20px;
      text-align: right;
      text-transform: uppercase;
      color: var(--color-green);
    }
  }
  .voucher {
    text-transform: capitalize;
    margin-bottom: 10px;
    span {
      font-weight: 600;
      font-size: 16px;
      color: var(--color-primary);
    }
  }
  .input_search {
    flex: 1;
  }
  .form-note {
    margin-bottom: 10px;
  }
  .search-wrapper {
    display: flex;
    gap: 12px;
    margin-bottom: 16px;
    &:last-child {
      margin-bottom: 0;
    }
    .icon-back {
      width: 64px;
      height: 64px;
      background: #F0F2F7;
      border-radius: 6px;
    }
  }
  .form-notifications-note {
    margin-bottom: 20px;
  }

  .current-customer {
    display: flex;
    align-items: center;
    gap: 24px;
    padding: 12px;
    transition: all 0.3s ease;
    border-radius: 6px;
    margin-bottom: 70px;

    .customer-avatar {
      width: 120px;
      height: 120px;
      border-radius: 50%;
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 64px;
      font-weight: bold;
      text-transform: uppercase;
      color: #fff;
    }

    .customer-detail {
      flex: 1;
      overflow: hidden;
      p {
        font-size: 12px;
        span {
          font-weight: bold;
        }
      }
      .detail-name {
        font-weight: 600;
        font-size: 16px;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }

    .btn-reset {
      background: #F0F2F7;
      border-radius: 6px;
    }

    .customer-link {
      display: inline-block;
      font-weight: 500;
      font-size: 16px;
      text-decoration-line: underline;
      color: var(--color-green);
      cursor: pointer;
      &.is-text {
        text-decoration-line: none;
      }
    }
  }

  .form-voucher {
    display: flex;
    gap: 4px;
    margin-bottom: 20px;
    button {
      display: flex;
      width: 112px;
      height: 64px;
      align-items: center;
      justify-content: center;
      border: 1px solid #4A9D77;
      border-radius: 6px;
      background: #fff;
      font-weight: 600;
      font-size: 16px;
      line-height: 140%;
      text-transform: capitalize;
      color: #4A9D77;
    }
  }

  .complete-order {
    display: flex;
    align-items: center;
    gap: 30px;
    margin-bottom: 30px;
    .complete-icon {

    }

    .complete-content {
      flex: 1;
    }

    .complete-title {
      font-weight: 600;
      font-size: 28px;
    }

    .complete-text {
      overflow: hidden;
      font-size: 16px;
      span {
        font-weight: 700;
      }
      .customer-name {
        text-overflow: ellipsis;
        overflow: hidden;
        width: 73%;
      }
    }
  }


  @media (max-width: 1023.98px) {
    .body {
      display: flex;
      padding: 24px 0;
    }
  }

  @media (max-width: 767.98px) {
    .block-heading {
      padding: 20px 12px;
      overflow: auto;
      li {
        min-width: max-content;
      }
    }
    .body {
      padding: 24px 12px 200px;
      flex-direction: column;
      gap: 50px;
    }
    .block {
      padding: 0;
      max-width: unset;
      border-right: none;
      &:first-child {
        .form-row:last-child {
          margin-top: 24px;
        }
      }
    }
    .block-fixed {
      position: fixed;
      bottom: 0;
      left: 0;
      width: 100%;
      background: var(--color-white);
      z-index: 101;
      padding: 12px;
    }
    .form-note {
      margin-bottom: 12px;
    }
  }

  .ant-input {
    min-width: unset;
  }

  .form-booking-submit {
    background: #363565;
    &.disabled {
      background: #949bda;
      cursor: no-drop;
    }
  }
  .common-btn {
    z-index: 999;
    min-width: 120px;
  }
`;