import { Select } from 'antd';
import workingHourActions from 'features/workingHour/services/actions';
import { EDateOption } from 'features/workingHour/services/types/workingHour';
import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
type ITypeViewPickerProps = {
  value?: string;
};

const TypeViewPicker: React.FC<ITypeViewPickerProps> = ({value}) => {

  const dispatch = useDispatch();
  const {t: workingHourLang} = useTranslation('workingHour');
  const options : {value: EDateOption, label: string}[] = [
    { value: EDateOption.TODAY, label: workingHourLang('Today') },
    { value: EDateOption.YESTERDAY, label: workingHourLang('Yesterday') },
    { value: EDateOption.LAST_7_DAYS, label: workingHourLang('Last7Days') },
    { value: EDateOption.LAST_30_DAYS, label: workingHourLang('Last30Days') },
    { value: EDateOption.LAST_QUARTER, label: workingHourLang('LastQuarter') },
    { value: EDateOption.LAST_YEAR, label: workingHourLang('LastYear') },
    { value: EDateOption.CUSTOM, label: workingHourLang('CustomRange') },
  ];

  const getRangeDate = ( unit: string, amount: number) => {
    const dateStart = unit === 'days' ? moment().unix() : moment().subtract(amount, unit as moment.unitOfTime.DurationConstructor).startOf(unit as moment.unitOfTime.DurationConstructor).unix();
    const dateEnd = unit === 'day' ? dateStart : moment(moment()).subtract(amount, unit as moment.unitOfTime.DurationConstructor).endOf(unit as moment.unitOfTime.DurationConstructor).unix();
    
    if(unit === 'days')
      return [dateEnd, dateStart];

    return [dateStart, dateEnd];
  };

  const onChange = (val: string) => {
    if(val === EDateOption.CUSTOM)
      return;
    const [unit, amount] = val.split('_');
    const [dateStart, dateEnd] = getRangeDate(unit, Number(amount));    
    
    if(dateStart === dateEnd) {      
      dispatch(workingHourActions.setParams({
        start_date: undefined,
        end_date: undefined,
        date: dateStart,
      }));
      return;
    } 

    dispatch(workingHourActions.setParams({
      start_date: dateStart,
      end_date: dateEnd,
      date: undefined,
    }));    
  };

  return (
    <TypeViewPickerStyled>
      <div className="common-select">
        <Select
          value={value}
          defaultValue={EDateOption.TODAY}
          options={options}
          className='is-normal'
          onChange={onChange}
        />
      </div>
    </TypeViewPickerStyled>
  );
};

export default TypeViewPicker;
const TypeViewPickerStyled = styled.div``;
