export const NAME_REDUCER = 'Voucher';

export const PREFIX_ACTIONS = 'VOUCHER_FEATURE_';

export const VOUCHER_STATUS = {
  ALL: 'all',
  ACTIVE: 'active',
  DISABLE: 'disable',
  REDEEMED: 'redeemed',
  INVALID: 'invalid',
  SOLD: 'sold',
  DEACTIVE: 'deactive'
};

export const VOUCHER_VIEW = {
  SALE: 'sale',
  PURCHASED: 'purchased',
};

export const PATH_LOADING = {
  getListVouchers: `loading.${NAME_REDUCER}.getListVouchers`,
  voucherActions: `loading.${NAME_REDUCER}.voucherActions`,
  getVoucherDetail: `loading.${NAME_REDUCER}.getVoucherDetail`,
};