import { AxiosResponse } from 'axios';
import { call, put, takeLatest } from 'redux-saga/effects';
import apis from './apis';
import { setLoading } from 'services/UI/sagas';
import { PATH_LOADING } from './constants';
import actions from './actions';

const getListCategoryProduct = function* (payload: any) {
  try {

    const params = payload.payload;

    yield setLoading(PATH_LOADING.getListPaginateCategory, true);
    const res: AxiosResponse<{ data: any[] }> = yield call(apis.getListPaginateCategory, params);
    if (res?.data) {
      const customerList = res?.data;
      
      yield put(actions.getListPaginateCategory.success(customerList));
    } else {
      throw 'fail';
    }
  } catch (error) {
    yield put(actions.getListPaginateCategory.fail({}));
  } finally {
    yield setLoading(PATH_LOADING.getListPaginateCategory, false);
  }
};

export default function* productServiceSaga() {
  yield takeLatest(actions.getListPaginateCategory.fetch, getListCategoryProduct);
}

