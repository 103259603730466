import React, { useState } from 'react';
import styles from '../../pages/index.module.scss';
import { Button, Form, Input, Select, Switch, Upload } from 'antd';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import TextArea from 'antd/es/input/TextArea';
import { PlusOutlined } from '@ant-design/icons';
import IconPlusCircle from 'assets/svg/IconPlusCircle';

const Overview = () => {
    const { t: productsLang } = useTranslation('products');
    const options = [
        {
            'id': 1,
            'value': 'a'
        },
        {
            'id': 2,
            'value': 'b'
        }
    ];
    const uploadButton = (
        <div className={styles.upload_overview}>
            <PlusOutlined />
            <div style={{ marginTop: 8 }}>{productsLang('Upload')}</div>
        </div>
    );

    const [priceProductFields, setPriceProductFields] = useState(1);

    const handleAddPriceProduct = () => {
        setPriceProductFields((prevFields) => prevFields + 1);
    };

    const priceProductTable = [];
    for (let i = 0; i < priceProductFields; i++) {
        priceProductTable.push(
            <div className={styles.classify_overview_table}>
            <p className={styles.title_price_product}>{productsLang('PriceProduct')}</p>
            <div className={styles.prices_overview}>
                <Form.Item label={productsLang('Price')+' (*)'}
                    rules={[
                        { required: true, message: productsLang('PleaseEnterPrice') || '' }
                    ]}>
                    <Input placeholder={productsLang('EnterPrice') || ''} />
                </Form.Item>
                {/* <Form.Item label={productsLang('Promotion')}
                    rules={[
                        { required: true, message: productsLang('PleaseEnterPromotion') || '' }
                    ]}>
                    <Input placeholder={productsLang('EnterPrice') || ''} />
                </Form.Item> */}
            </div>
            {/* <div className={styles.switch_setting_overview_item}>
                <span>{productsLang('Promotion')}</span>
                <Switch defaultChecked />
            </div> */}
            <div className={styles.mass_overview}>
                <Form.Item className={styles.mass_overview_item} label={productsLang('Mass')+' (*)'}
                    rules={[
                        { required: true, message: productsLang('PleaseEnterMass') || '' }
                    ]}>
                    <Input placeholder={productsLang('EnterMass') || ''} />
                </Form.Item>
                <Form.Item className={styles.mass_overview_item} label={productsLang('UnitOfMeasure')}
                    rules={[
                        { required: true, message: productsLang('PleaseEnterUnitOfMeasure') || '' }
                    ]}>
                    <Select 
                        placeholder={productsLang('SelectTeamMember')}
                        defaultValue={options.find(option => option.id === 1)}
                        options={options}
                    />
                </Form.Item>
            </div>
        </div>
        );
    }

    return (
        <OverviewPageStyled>
            <div className={styles.container_overview}>
                <div className={styles.left_overview}>
                    <div className={styles.detail_product}>
                        <p className={styles.title_overview}>{productsLang('ProductInformation')}</p>
                        <Form.Item label={productsLang('ProductName')+' (*)'}
                            rules={[
                                { required: true, message: productsLang('PleaseEnterProductName') || '' }
                            ]}>
                            <Input placeholder={productsLang('EnterProductName') || ''} />
                        </Form.Item>
                        <Form.Item label={productsLang('IDProduct')+' (*)'}
                            rules={[
                                { required: true, message: productsLang('PleaseEnterIDProduct') || '' }
                            ]}>
                            <Input placeholder={productsLang('EnterIDProduct') || ''} />
                        </Form.Item>
                        <Form.Item label={productsLang('Brand')}
                            rules={[
                                { required: true, message: productsLang('PleaseEnterBrand') || '' }
                            ]}>
                            <Input placeholder={productsLang('EnterBrand') || ''} />
                        </Form.Item>
                        <Form.Item label={productsLang('Category')+' (*)'}
                            rules={[
                                { required: true, message: productsLang('PleaseEnterCategory') || '' }
                            ]}>
                            <Select
                                placeholder={productsLang('SelectTeamMember')}
                                defaultValue={options.find(option => option.id === 1)}
                                options={options}
                            />
                        </Form.Item>
                        <Form.Item label={productsLang('ProductType')+' (*)'}
                            rules={[
                                { required: true, message: productsLang('PleaseEnterProductType') || '' }
                            ]}>
                            <Select
                                placeholder={productsLang('SelectTeamMember')}
                                defaultValue={options.find(option => option.id === 1)}
                                options={options}
                            />
                        </Form.Item>
                        <Form.Item label={productsLang('NoteProduct')}>
                            <TextArea
                                placeholder={productsLang('ExampleProductNotes') || ''}
                                rows={4}
                            />
                        </Form.Item>
                        <Form.Item label={productsLang('Image')}
                            rules={[
                                { required: true, message: productsLang('PleaseEnterImage') || '' }
                            ]}>
                            <Upload>
                                {uploadButton}
                            </Upload>
                        </Form.Item>
                    </div>
                    <div className={styles.detail_product}>
                        <p className={styles.setting_overview}>{productsLang('DisplaySettings')}</p>
                        <p className={styles.content_overview}>{productsLang('ProductDisplaySettingsOnTheSystem')}</p>
                        <div className={styles.switch_setting_overview}>
                            <div className={styles.switch_setting_overview_item}>
                                <span>{productsLang('AllowProductDisplay')}</span>
                                <Switch defaultChecked />
                            </div>
                            <div className={styles.switch_setting_overview_item}>
                                <span>{productsLang('DisplayProductsOnTheOnlineLink')}</span>
                                <Switch defaultChecked />
                            </div>
                        </div>
                    </div>
                </div>
                <div className={styles.right_overview}>
                    <div className={styles.setting_overview_container}>
                        <p className={styles.setting_overview}>{productsLang('ProductCategories')}</p>
                        <p className={styles.content_overview}>{productsLang('SetProductPriceAndVolumeOptions')}.</p>
                        {priceProductTable}
                        <Button onClick={handleAddPriceProduct}><IconPlusCircle/> {productsLang('AdditionalCategories')}</Button>
                    </div>
                </div>
            </div>
        </OverviewPageStyled>
    );
};

export default Overview;

const OverviewPageStyled = styled.div`
.ant-form-item {
    margin-bottom: 24px !important;
    .ant-row {
        display: block; 
    }
}
.ant-select-selector {
    min-width: 0px !important;
}
.ant-btn-default {
    margin-top: 12px;
    width: 100%;
    border: 0px;
    font-size: 18px;
    font-weight: 500;
    line-height: 140%;
    color: #1B9AAA;
}
.ant-form-item-label > label {
    font-weight: 600;
    font-size: 16px;
}
`;