import styled from 'styled-components';
import { EPaymentMethod, ICONS } from 'features/sales/services/constants';
import salesSelectors from 'features/sales/services/selectors';
import CashPayment from './CashPayment';
import { IconPlus } from 'features/checkout/components/FeeButton/Add';
import { Modal } from 'antd';
import { useEffect, useMemo, useState } from 'react';
import VoucherPayment from './Mix/VoucherPaymentCreate';
import { useDispatch } from 'react-redux';
import CardPayment from './CardPayment';
import salesHook from 'features/sales/hooks/salesHook';
import salesActions from 'features/sales/services/actions';
import { IVoucherDataItem } from 'features/sales/services/types/voucher';
// import BoxDenyPromo from 'components/common/Box/BoxDenyPromo';
import { CUSTOMER_WALKIN_INDEX, getValueNotLower0 } from 'utils/unit';
import promotionActions from 'features/promotions/services/actions';
import moment from 'moment';
import { sumBy } from 'lodash';
import { useTranslation } from 'react-i18next';
import Can from 'features/packagePermission/components/Can';
import { ModulePermission, PaymentMethodPermission } from 'features/packagePermission/services/types/permission';

export const listPayment = [
  {
    value: EPaymentMethod.CASH,
    name: 'Cash',
    icon: ICONS[EPaymentMethod.CASH],
    permission: PaymentMethodPermission.CASH_PAYMENT
  },
  {
    value: EPaymentMethod.CARD,
    name: 'Card',
    icon: ICONS[EPaymentMethod.CARD],
    permission: PaymentMethodPermission.CARD_PAYMENT


  },
  // {
  //   value: EPaymentMethod.MIX,
  //   name: 'Mix payment',
  // },
];

export type BlockPaymentProps = {
  hook?: typeof salesHook
  selector?: typeof salesSelectors
  action?: typeof salesActions
}

const BlockPayment = ({
  hook = salesHook,
  selector = salesSelectors,
  action = salesActions
}: BlockPaymentProps) => {

  const props = {
    hook,
    selector,
    action,
  };

  const [method, setMethod] = hook.usePaymentMethod();
  const [isOpenModalVoucher, setIsOpenModalVoucher] = useState<boolean>(false);

  const amount = selector.getOriginTotalPrice();
  const amountBeforeCardSurcharge = selector.getOriginTotalPriceAfterCardSurcharge();
  const voucherValue = selector.getVoucherValue() ?? 0;
  const cashValue = selector.getPurchaseCurrentCash() ?? 0;
  const cardValue = selector.getPurchaseCurrentCard() ?? 0;
  const selectedService = selector.getSelectedServices();
  const selectedVoucherForSale = selector.getSelectedVouchers();
  const customer = selector.getCustomer();
  const bookingDate = selector.getBookingDate();
  const merchantLocationId = selector.getMerchantLocationId();
  const currCustomer = selector.getCustomer();

  useEffect(() => {

    if (!merchantLocationId || !currCustomer) return;

    dispatch(promotionActions.getPromotionAutoOffer.fetch({
      customer_code: currCustomer?.customer_code,
      today: moment(bookingDate).unix(),
      merchant_location_id: Number(merchantLocationId),
      total_booking: +sumBy(selectedService, (o) => o.price * o.quantity),
      total_voucher: +sumBy(selectedVoucherForSale, o => o.retail_price)
    }));

    dispatch(salesActions.getTotalPointCustomer.fetch({
      is_walkin_in: !!currCustomer?.is_walkin_in,
      customer_code: currCustomer?.customer_code?.replace('#', '') ?? '',
    }));

  }, [currCustomer, bookingDate]);

  useEffect(() => {

    if (method === EPaymentMethod.CARD) {
      dispatch(action.setPurchaseCard(getValueNotLower0(amountBeforeCardSurcharge - voucherValue)));

    } else if (voucherValue > 0) {
      dispatch(action.setPurchaseCash(getValueNotLower0(amountBeforeCardSurcharge - voucherValue)));
      cashValue === 0 ? dispatch(action.setPaymentMethod(EPaymentMethod.CASH)) : undefined;
    }
  }, [voucherValue]);

  useEffect(() => {
    if (method === EPaymentMethod.CARD) {
      if (voucherValue + cashValue >= amount) {
        dispatch(action.setPurchaseCard(cashValue));
        dispatch(action.setPurchaseCash(0));
        return;
      }

      dispatch(action.setPurchaseCard(getValueNotLower0(amountBeforeCardSurcharge - voucherValue - cashValue)));
    }
  }, [method, cashValue, voucherValue, amount]);

  useEffect(() => {
    if (method === EPaymentMethod.CASH) {
      dispatch(action.setPurchaseCard(null));
      dispatch(action.setPurchaseCash(getValueNotLower0(amount - voucherValue)));
    } else if (method === null) {
      dispatch(action.setPaymentMethod(EPaymentMethod.CASH));
    }
  }, [method, amount]);

  useEffect(() => {
    // cash > 0 ? dispatch(salesActions.setPaymentMethod(EPaymentMethod.CASH)) : dispatch(salesActions.setPaymentMethod(null));
    if ((cashValue + cardValue) === 0 || (cashValue + cardValue + voucherValue) === 0) {
      dispatch(action.setPaymentMethod(null));
    } else if (cashValue > 0 && cardValue === 0 && voucherValue === 0) {
      dispatch(action.setPaymentMethod(EPaymentMethod.CASH));
    }
  }, [cashValue, cardValue]);

  const purchasedVouchers = selector.getPurchasedVouchers()?.filter((o: any) => {
    for (const service of selectedService) {
      const haveServiceReward = o?.services?.find((item: any) => service.service_variant_id === undefined || service.service_variant_id === null ? item.id === service.id : item.id === service.id && item.service_variants?.find((i: any) => i.id === service.service_variant_id));
      if (haveServiceReward && o.is_enable && o.remaining >= 0.01)
        return true;
    }
  }) as IVoucherDataItem[];

  const isPaymentSelected = (type: EPaymentMethod) => {
    if (type === EPaymentMethod.CARD)
      return cardValue > 0 && method === EPaymentMethod.CARD;
    else if ((type === EPaymentMethod.CASH))
      return cashValue > 0;
    else if ((type === EPaymentMethod.VOUCHER))
      return voucherValue > 0;
  };

  const dispatch = useDispatch();


  const openModalVoucher = () => {
    setIsOpenModalVoucher(true);
  };

  const closeModalVoucher = () => {
    setIsOpenModalVoucher(false);
  };

  const isDisable = useMemo(() => {
    return amount - voucherValue === 0;
  }, [amount, voucherValue]);

  // const isDisablePromo = selector.disablePromo();

  // const onRemove = (type: EPaymentMethod) => {
  //   if(type === EPaymentMethod.CASH) {
  //     dispatch(action.setPurchaseCash(null));
  //   } else if (type === EPaymentMethod.CARD) {
  //     dispatch(action.setPurchaseCard(null));
  //   }
  // };

  // useEffect(() => {
  //   if(checkCardIsActive(EPaymentMethod.VOUCHER)) {

  //     const newMixPayments = [...curMixPayments];
  //     remove(newMixPayments, o => o.type === EPaymentMethod.CARD);
  //     dispatch(action.setMixPayments(newMixPayments));
  //   }
  // },[method]);
  const { t: bookingLang } = useTranslation('booking');
  const renderPayment = (method: string) => {
    switch (method) {
      case EPaymentMethod.CASH:
        return <CashPayment {...props} />;
      // case EPaymentMethod.VOUCHER:
      //   return <VoucherPayment  />;
      case EPaymentMethod.CARD:
        return <CardPayment />;
      // case EPaymentMethod.MIX:
      //   return <MixPayment />;
      default:
        return (
          <NonePayment>
            <p>{bookingLang('ProcessYourPaymentUsingTheMethodsAbove')}.</p>
          </NonePayment>
        );
        break;
    }
  };
  
  return (
    <div className='block'>
      <BlockPaymentStyled>
        <div>
          <div className='block-title'>
            {bookingLang('SelectPaymentOption')}
            {/* <BoxDenyPromo isOpen={isDisablePromo} onClick={() => openModalVoucher()} > */}
            <Can module={ModulePermission.PAYMENT_METHODS} permission={PaymentMethodPermission.VOUCHER_PAYMENT}>
              {selectedService.length > 0 && !isDisable && <AddVoucherStyled
                type='button'
                onClick={() => openModalVoucher()}
              >
                <IconPlus />
                <span>{voucherValue > 0 ? bookingLang('AppliedVoucher') : bookingLang('ApplyVoucher')} {customer?.is_walkin_in !== CUSTOMER_WALKIN_INDEX ? purchasedVouchers.length > 0 ? `(${purchasedVouchers.length})` : '' : ''}</span>
              </AddVoucherStyled>}
            </Can>
            {/* </BoxDenyPromo> */}
          </div>
          <ul className='list-payment'>
            {listPayment.map((o) => {
              return (
                // <Can key={o.value} module={ModulePermission.PAYMENT_METHODS} permission={o.permission}>
                  <li
                    className={`payment-item ${method === o.value ? 'payment-item-active' : ''} ${isDisable ? 'payment-item-disabled' : ''}`}
                    key={o.value}
                    onClick={() => setMethod(o.value)}>
                    {isPaymentSelected(o.value) && !isDisable && <button className='button-remove' onClick={e => {
                      e.stopPropagation();
                      // onRemove(o.value);
                    }}><IconMethodSelected />
                    </button>}
                    <div className='payment-label'>
                      {o.icon}
                      <span className='payment-type'>{bookingLang(o.name)}</span>
                    </div>
                  </li>
                // </Can>
              );
            })}
          </ul>
        </div>
        <div>
          {renderPayment(method)}
          {/* <div className='form-row'>
            <div className='form-label'>Payment method</div>
          </div> */}
        </div>
      </BlockPaymentStyled>
      {isOpenModalVoucher && <Modal

        open={isOpenModalVoucher}
        onCancel={closeModalVoucher}
        okButtonProps={{ style: { display: 'none' } }}
        footer={null}>
        <VoucherPayment open={isOpenModalVoucher} onCloseModal={closeModalVoucher} customerVouchers={purchasedVouchers} {...props} />
      </Modal>}
    </div>
  );
};

export default BlockPayment;

const AddVoucherStyled = styled.button`
  display: flex;
  align-items: center;
  gap: 2px;
  span {
    color: var(--green-1, #4A9D77);
    font-variant-numeric: slashed-zero;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 19.6px */
  }
  &:hover {
    background:rgba(195, 194, 224, 0.2);
  }
`;

const NonePayment = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 165px;
  width: 100%;
  font-size: 16px;
  opacity: 0.8;
`;

const BlockPaymentStyled = styled.div`
  position: relative;
  color: var(--color-primary);

  .list-payment {
    margin-top: 16px;
    display: flex;
    gap: 8px;
    @media only screen and (max-width: 767.98px) {
      display: grid;
    }
  }

  .button-remove {
    position: absolute;
    z-index: 100;
    top:0;
    right:0;
    transform: translate(10px, -10px);
  }

  .block-title {
    display: flex;
    justify-content: space-between;
    @media only screen and (max-width: 767.98px) {
      display: grid;
    }
  }

  .payment-item {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid var(--color-purple);
    border-radius: 6px;
    padding: 36px 16px;
    font-size: 22px;
    font-weight: 600;
    width: 100%;
    margin-bottom: 10px;
    position: relative;
  }

  .payment-item-active {
    border-color: var(--color-primary, #363565);
    background-color: var(--color-purple-05);
  }

  .payment-item-disabled {
   opacity: 0.5;
   pointer-events: none;
  }

  .payment-label {
    margin: auto;
    display: flex;
    align-items: center;
    gap: 16px;
    margin-right: auto;
  }

  .payment-type {
    /* text-transform: capitalize; */
  }
`;

export const IconMethodSelected = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 68 68" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path opacity="1" d="M67.3327 33.9998C67.3327 52.4093 52.4088 67.3332 33.9994 67.3332C15.5899 67.3332 0.666016 52.4093 0.666016 33.9998C0.666016 15.5903 15.5899 0.666504 33.9994 0.666504C52.4088 0.666504 67.3327 15.5903 67.3327 33.9998Z" fill="#008F5D" />
      <path opacity='1' fillRule="evenodd" clipRule="evenodd" d="M48.8676 22.0267C49.9574 22.8744 50.1538 24.4451 49.3061 25.535L35.9494 42.7078C33.8704 45.3809 29.9597 45.7278 27.4426 43.4624L18.9936 35.8584C17.9674 34.9347 17.8842 33.354 18.8078 32.3277C19.7314 31.3014 21.3122 31.2182 22.3384 32.1419L30.7874 39.7459C31.147 40.0695 31.7056 40.02 32.0027 39.6381L45.3593 22.4653C46.207 21.3754 47.7777 21.1791 48.8676 22.0267Z" fill="white" />
    </svg>
  );
};
