import { AxiosResponse } from 'axios';
import message from 'components/common/Message';
import { get, set } from 'lodash';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { setLoading } from 'services/UI/sagas';
import { ISagaFunc } from 'services/actionConfigs';
import { IApiPromotionListReq } from '../types/api';
import { IPromotionItemResData, IResConfigPromotion, IResPromotionList } from '../types/promotion';
import actions from './actions';
import apis from './apis';
import { PATH_LOADING } from './constants';
import { getPureParams } from './selectors';
import { IPromotionAutoParam } from './types/promotion';

const getConfigs = function* () {
  try {
    const res: AxiosResponse<{ data: IResConfigPromotion }> = yield call(apis.getConfigs);
    if (res?.data?.data) {
      yield put(actions.getConfigs.success(res.data.data));
    }
  } catch (error) {
    yield put(actions.getConfigs.fail({}));
  }
};

const getListPromotions: ISagaFunc<IApiPromotionListReq> = function* ({ payload }) {
  yield setLoading(PATH_LOADING.getListPromotions, true);
  try {
    const res: AxiosResponse<{ data: IResPromotionList }> = yield call(apis.getListPromotion, payload);
    if (res?.data) {
      yield put(actions.getListPromotions.success(res.data));
    }
  } catch (error) {
    yield put(actions.getListPromotions.fail({}));
  }
  yield setLoading(PATH_LOADING.getListPromotions, false);
};

const getDetailPromotion: ISagaFunc<string> = function* ({ payload }) {
  yield setLoading(PATH_LOADING.getDetailPromotion, true);
  try {
    const res: AxiosResponse<{ data: IResPromotionList }> = yield call(apis.getDetailPromotion, payload);
    if (res?.data?.data) {
      yield put(actions.getDetailPromotion.success(res.data.data));
    }
  } catch (error) {
    yield put(actions.getDetailPromotion.fail({}));
  }
  yield setLoading(PATH_LOADING.getDetailPromotion, false);
};

const setListPromotionParams: ISagaFunc<IApiPromotionListReq> = function* ({ payload }) {
  const oldParams = yield select(getPureParams);
  const _params: IApiPromotionListReq = { ...oldParams, ...payload ?? {} };
  if (_params.keyword === undefined || _params.keyword === null) {
    delete _params.keyword;
  }
  if (_params.type === undefined || _params.type === null) {
    delete _params.type;
  }
  if (_params.per_page === undefined || _params.per_page === null) {
    set(_params, 'per_page', 20);
  }
  yield put(actions.getListPromotions.fetch(_params));
};

const deletePromotion: ISagaFunc<IPromotionItemResData['code']> = function* ({ payload }) {
  const id = payload;
  yield setLoading(PATH_LOADING.promotionActions, true);
  try {
    const res = yield call(apis.deletePromotion, id);
    const msg = get(res, 'data.message');
    if (msg) {
      message.success(msg);
      yield put(actions.setListPromotionParams({}));
    } else {
      const msgError = get(res, 'data.error.message');
      if (msgError) message.error(msgError);
      else throw 'fail';
    }
  } catch (error) {
    const errors = Object.entries(get(error, 'response.data.errors', {}));
    if (errors.length > 0) {
      errors.map(([, valueArr]) => {
        message.error(get(valueArr, [0], ''));
      });
      return;
    }

    message.error('An error occurred. Please try again');
  } finally {
    yield setLoading(PATH_LOADING.promotionActions, false);
  }
};

const generatePromotionCode = function* () {
  yield setLoading(PATH_LOADING.generatePromotionCode, true);
  try {
    const res: AxiosResponse<{ data: {code: string} }> = yield call(apis.generatePromotionCode);
    if (res?.data?.data) {
      yield put(actions.generatePromotionCode.success(res.data.data?.code || '' ));
    }
  } catch (error) {
    yield put(actions.generatePromotionCode.fail({}));
  }
  yield setLoading(PATH_LOADING.generatePromotionCode, false);
};

const getPromotionAutoOffer: ISagaFunc<IPromotionAutoParam> = function*({payload}) {

  const param = payload;

  try {
    const res: AxiosResponse<{ data: {code: string} }> = yield call(apis.getPromotionAutoOffer, param);
    if (res?.data?.data) {
      yield put(actions.getPromotionAutoOffer.success(res.data.data || '' ));
    }
  } catch (error) {
    yield put(actions.getPromotionAutoOffer.fail({}));
  }
};

const init = function* () {
  yield all([
    put(actions.getConfigs.fetch()),
  ]);
};

export default function* promotionServiceSaga() {
  yield takeLatest(actions.init, init);
  yield takeLatest(actions.getConfigs.fetch, getConfigs);
  yield takeLatest(actions.getListPromotions.fetch, getListPromotions);
  yield takeLatest(actions.setListPromotionParams, setListPromotionParams);
  yield takeLatest(actions.deletePromotion, deletePromotion);
  yield takeLatest(actions.getDetailPromotion.fetch, getDetailPromotion);
  yield takeLatest(actions.generatePromotionCode.fetch, generatePromotionCode);
  yield takeLatest(actions.getPromotionAutoOffer.fetch, getPromotionAutoOffer);
}

