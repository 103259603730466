import { Form, Input } from 'antd';
import IconCheck from 'assets/icons/ico_check.svg';
import IconBack from 'assets/svg/IconBack';
import apis from 'features/auth/services/apis';
import { get } from 'lodash';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSetLoadingPage } from 'services/UI/LoadingPage';
import styled from 'styled-components';

type Props = {
  avatar?: string;
  email: string;
  backToSignIn: () => void;
};

/**
 * Forgot password
 * @param backToSignIn 
 * @returns 
 */
const ForgotPassword = ({
  // email,
  backToSignIn = () => undefined,
}: Props) => {
  const { t: signInLang } = useTranslation('sign_in');

  const [isSendSuccess, setIsSendSuccess] = useState(false);
  const [errorText, setErrorText] = useState('');
  const setIsLoading = useSetLoadingPage();

  /**
   * Handle reset password
   * @param values 
   */
  const handleResetPassword = async (values: { email: string }) => {
    setIsLoading(true);
    setErrorText('');
    try {
      const res = await apis.forgotPassword(values.email);
      if (res && res.data) {
        setIsSendSuccess(true);
      }
    } catch (error) {
      const message = get(
        error,
        'response.data.error.message',
        signInLang('AnErrorHasOccurredPleaseTryAgain!')
      );
      setErrorText(message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      {!isSendSuccess ? (
        <>
          <h1 className='auth-title'>{signInLang('forgotPass.title')}</h1>
          <div style={{ marginBottom: 50 }}>
            <p className='auth-text' style={{ marginBottom: 0 }}>
              {signInLang('forgotPass.label')} {''}
              {/* <strong>{email}</strong> */}
            </p>
            {!!errorText && (
              <p style={{ color: 'var(--color-red)' }}>{errorText}</p>
            )}
          </div>
          <Form
            data-form-testing='form-forgot-password'
            autoComplete='off'
            layout='vertical'
            onFinish={handleResetPassword}>
            <InputForgotPasswordStyled>
              <Form.Item name={'email'}
                rules={[{ required: true, message: signInLang('PleaseEnterEmail') || '' }]}>
                <Input placeholder={signInLang('EnterYourEmail') || ''} />
              </Form.Item>
            </InputForgotPasswordStyled>
            <ButtonStyled>
              <div className='form-row dl-flex'>
                <a href="/sign-in">
                  <button
                    type='button'
                    // onClick={backToSignIn}
                    className='icon-back'>
                    <IconBack />
                  </button>
                </a>

                <button className='btn-submit' type='submit'>
                  {signInLang('forgotPass.btn')}
                </button>
              </div>
            </ButtonStyled>
          </Form>
        </>
      ) : (
        <>
          <div className='forgot-logo'>
            <img src={IconCheck} alt=''></img>
          </div>
          <p className='auth-text'>{signInLang('forgotPass.successText')}</p>
          <button className='btn-submit' onClick={backToSignIn}>
            {signInLang('forgotPass.backBtn')}
          </button>
        </>
      )}
    </>
  );
};

export default ForgotPassword;

const InputForgotPasswordStyled = styled.div`
.ant-form-item-explain-error {
  margin-left: 80px;
  @media only screen and (min-width: 768px) and (max-width: 1180.98px) {
    margin-left: 80px;
  }
  @media only screen and (max-width: 767.98px) {
    margin-left: 0px;
  }
}
`;

const ButtonStyled = styled.div`
.btn-submit {
  width: 470px;
  margin-left: unset;
  margin-right: unset;
}
.dl-flex {
  display: flex;
  justify-content: center;
  align-items: center;
}
`;
