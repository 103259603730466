import { Form, InputNumber } from 'antd';
import FormRow from 'components/common/Form/FormRow';
import { Link } from 'react-router-dom';

const FormSecondStep = () => {
  return (
    <>
      <h2 className='modal-title'>Enter PIN</h2>
      <div className='modal-content'>
        <div className='PIN_form'>
          <p>Please enter a 4 digits PIN to confirm this action</p>
          <Form.Item>
            <div className='PIN_group'>
              <FormRow shouldUpdate noStyle name='_one'>
                <InputNumber
                  className='PIN_input'
                  maxLength={1}
                  minLength={1}
                  type='number'
                />
              </FormRow>
              <FormRow shouldUpdate noStyle name='_two'>
                <InputNumber
                  className='PIN_input'
                  maxLength={1}
                  minLength={1}
                  type='number'
                />
              </FormRow>
              <FormRow shouldUpdate noStyle name='_three'>
                <InputNumber
                  className='PIN_input'
                  maxLength={1}
                  minLength={1}
                  type='number'
                />
              </FormRow>
              <FormRow shouldUpdate noStyle name='_four'>
                <InputNumber
                  className='PIN_input'
                  accept='/^[0-9]*$/'
                  maxLength={1}
                  minLength={1}
                  type='number'
                />
              </FormRow>
            </div>
          </Form.Item>
          <p>
            Don’t remember the number?{' '}
            <Link to='/private/settings' className='label-highlight'>
              <b>Reset PIN</b>
            </Link>
          </p>
        </div>
      </div>
    </>
  );
};

export default FormSecondStep;
