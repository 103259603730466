import checkoutSelectors from 'features/checkout/services/selectors';
import { useTranslation } from 'react-i18next';
import { formatMoney } from 'utils/unit';

/**
 * Reward result row
 * @returns 
 */
const RewardResultRow = () => {
  const result = checkoutSelectors.getRewardValue();
  const invoice = checkoutSelectors.getBookingDetail();
  const{t: bookingLang} = useTranslation('booking');
  if (!result) return null;

  const rewardValue = (result ?? 0) - Number(invoice?.total_price ?? 0) > 0 ? +(invoice?.total_price ?? '') : result;
  return (
    <p className='extra-price-row'>
      {bookingLang('Reward')}
      <span>- {formatMoney(+rewardValue ?? 0)}</span>
    </p>
  );
};

export default RewardResultRow;
