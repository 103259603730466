import IconCustomerCircle from 'assets/svg/IconCustomerCircle';
import IconDiscountCircle from 'assets/svg/IconDiscountCircle';
import IconStarCircle from 'assets/svg/IconStarCircle';
import IconVoucherCircle from 'assets/svg/IconVoucherCircle';
import styles from './index.module.scss';
import { useState } from 'react';
import analyticActions from '../services/actions';
import { useDispatch } from 'react-redux';
import analyticSelectors from '../services/selectors';
import moment from 'moment';
import { ISummary } from '../services/types/api';
import { formatMoney } from 'utils/unit';
import { useTranslation } from 'react-i18next';
import { Link, NavLink } from 'react-router-dom';
import IconCalenderMobile from 'assets/svg/IconCalenderMobile';
import IconPickList from 'assets/svg/IconPickList';
import IconUserMobileAnalytics from 'assets/svg/IconUserMobileAnalytics';
import styled from 'styled-components';

export interface IOptionAnalyticItem {
    value: 'month' | 'week' | 'year' | 'month_3';
    label: string;
}

/**
 * Dashboards page
 * @returns 
 */
const DashboardsPage = () => {

    const dispatch = useDispatch();
    const [optionDate, ] = useState<{ dateStart: string, dateEnd: string }>(() => {

        const dateStart = moment().format('YYYY-MM-DD 00:00:00');
        const dateEnd = moment().format('YYYY-MM-DD 23:59:59');


        dispatch(analyticActions.getAnalytics.fetch({
            start_date: dateStart,
            end_date: dateEnd,
        }));

        return { dateStart: dateStart, dateEnd: dateEnd };
    }
    );

    const getNewCustomer = analyticSelectors.getNewCustomer();
    const totalDiscount = analyticSelectors.getTotalDiscount();
    const getAverageSpendCustomer = analyticSelectors.getAverageSpendCustomer();
    const totalSale = analyticSelectors.getTotalSale();

    /**
     * Data card summary
     */
    const dataCardSummary = [
        {
            ...totalSale,
            icon: <IconCustomerCircle />,
            money: true
        },
        {
            ...getNewCustomer,
            icon: <IconDiscountCircle />,

        },
        {
            ...getAverageSpendCustomer,
            icon: <IconVoucherCircle />,
            money: true
        },
        {
            ...totalDiscount,
            icon: <IconStarCircle />,
            money: true
        }
    ];
    const { t: analyticLang } = useTranslation('analytic');

    /**
     * Card summary
     * @param values 
     * @returns 
     */
    const CardSummary = (values: ISummary) => {
        const dateComparisonCondition = optionDate.dateStart === optionDate.dateEnd;
        return (
            <li className={`${styles.overview_item}`}>
                <div className={styles.card_info}>
                    <p className={styles.info_text}>{values.content === 'Total Sales' ? analyticLang('TotalSales') : values.content === 'Total Customers' ? analyticLang('TotalCustomers') : values.content === 'Avg Customer Spent' ? analyticLang('AvgCustomerSpent') : analyticLang('TotalDiscount')}</p>
                    <p className={styles.info_title}>
                        {values.money ? formatMoney(values.total_amount) : values.total_amount}
                        {Number(values.total_amount) !== 0 ?
                            <span className={`common-growth ${values.percent < 0 ? 'is-reduce' : values.percent === 0 ? 'no-reduce' : ''}`}>
                                {values.percent} {dateComparisonCondition ? '' : '%'}
                            </span> : null
                        }
                    </p>
                </div>
            </li>
        );
    };

    const queryAddBooking = localStorage.getItem('query-add-booking');

    return (
        <>
            <DashboardsPageStyled>
                <section className={'section_analytics'}>
                    <ShortcutsStyled>
                        {/* <div className='title'>
                            Phím tắt
                        </div> */}
                        <div className='short-item-container'>
                            <Link to={`/private/bookings/quick-booking?${queryAddBooking}`}>
                                <IconCalenderMobile />
                                <p>Đặt lịch hẹn</p>
                            </Link>
                            <Link to={'/private/sales'}>
                                <IconPickList />
                                <p>Thêm đơn hàng</p>
                            </Link>
                            <Link to={'/private/users?page=1&per_page=10'}>
                                <IconUserMobileAnalytics />
                                <p>Thêm nhân viên</p>
                            </Link>
                        </div>
                    </ShortcutsStyled>
                    <div className={styles.analytics_left}>
                        <div className={'left_inner'}>
                            <div className='title-container'>
                                <p>Thống kê hôm nay</p>
                                <NavLink
                                    to={'/private/statistical/overview'}>
                                    Xem thêm
                                </NavLink>
                            </div>
                            <ul className={styles.analytics_overview}>
                                {dataCardSummary.map((o, index) => (
                                    <CardSummary key={index} {...o} />
                                ))}
                            </ul>
                        </div>
                    </div>
                </section>
            </DashboardsPageStyled>
        </>
    );
};

export default DashboardsPage;

const DashboardsPageStyled = styled.div`
.section_analytics {
    display: grid;
    gap: 20px;
    padding-bottom: 50px;
}
.title-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    p {
        color: var(--Gray-95, #141414);
        font-family: Inter;
        font-size: 20px;
        font-weight: 700;
        line-height: 140%;
    }
    a {
        color: var(--Blue-Munsell-Main, #1B9AAA);
        text-align: center;
        font-family: Inter;
        font-size: 14px;
        font-weight: 500;
        line-height: 140%;

        padding: 0px 16px;
        border-radius: 6px;
        background: var(--Blue-Munsell-05, #F3FAFB);
        height: 32px;
        display: flex;
        align-items: center;
    }
}
.left_inner {
    display: grid;
    gap: 24px;
    background: #fff;
    padding: 24px;
    border-radius: 10px;
}
`;

const ShortcutsStyled = styled.div`
display: grid;
background: #FFFFFF;
padding: 24px;
gap: 24px;
border-radius: 10px;
@media only screen and (max-width: 767.98px) {
  background: #FFF;
  padding: 16px;
  display: grid;
  gap: 24px;
  border-radius: 16px;
}

.title {
    color: var(--Gray-95, #141414);
    font-family: Inter;
    font-size: 20px;
    font-weight: 700;
    line-height: 140%;
}

.short-item-container {
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 24px;
  a {
    display: grid;
    gap: 12px;
    align-items: center;
    justify-content: center;
    justify-items: center;
  }
  @media only screen and (max-width: 767.98px) {
    gap: 16px;
  }
}
`;