import { Form, Input } from 'antd';
import { IStaffFormInput } from '.';
import styled from 'styled-components';

type Props = IStaffFormInput;

const StaffFormInoutPin = ({
    label,
    name,
    rules,
    placeholder,
    defaultValue,
    subTitle,
    ...extra
}: Props) => {


    return (
        <div className='form-row'>
            <InputPinNumberStyled>
                <Form.Item
                    label={label}
                    name={name}
                    rules={rules}
                    {...extra}
                >

                    <Input.Password placeholder={placeholder} defaultValue={defaultValue} />
                </Form.Item>
                <p className='form-note'>
                    {subTitle}
                </p>
            </InputPinNumberStyled>
        </div>
    );
};

export default StaffFormInoutPin;

const InputPinNumberStyled = styled.div`
display: block;
flex: 1;
`;