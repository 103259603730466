import MainLogo from 'assets/icons/ico_logo.svg';
import AuthAvatar from 'assets/images/auth/auth_avatar.jpg';
import actions from 'features/auth/services/actions';
import apis from 'features/auth/services/apis';
import { IApiLoginResData } from 'features/auth/services/types/login';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSetLoadingPage } from 'services/UI/LoadingPage';
import { useAppDispatch } from 'store/hooks';
import storage from 'utils/sessionStorage';
import ForgotPassword from '../../components/ForgotPassword';
import '../../styles/Auth.scss';
import styled from 'styled-components';
import settingActions from 'features/settings/services/actions';
import { Form, Input } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { get } from 'lodash';
import { getDeviceId } from 'utils/unit';
import message from 'components/common/Message';
import ModalConfirmSignIn, { ModalConfirmSignInRef } from 'components/common/Modal/ModalConfirmSignIn';

type IAuthForm = {
  email: string;
  password: string;
}

const SignInPage = () => {
  const { t: signInLang } = useTranslation('sign_in');
  const { t: commonLang } = useTranslation('common');
  const { t: formLang } = useTranslation('form');

  // const [userName] = useState('Abdullah');
  const [email, setEmail] = useState('');
  const [isShowInputPass] = useState(true);
  const [isShowForgotPass, setIsShowForgotPass] = useState(false);
  const [isShowBtnBack] = useState(false);
  const navigate = useNavigate();
  const setIsLoading = useSetLoadingPage();
  const dispatch = useAppDispatch();
  const [form] = useForm<IAuthForm>();
  const [error, setError] = useState('');
  const [pageDisplay, setPageDisplay] = useState(false);
  const modalConfirmRef = useRef<ModalConfirmSignInRef>(null);

  // =============== Change language ====================
  // const { t, i18n } = useTranslation();

  // const changeLanguage = (languageValue: any) => {
  //   i18n.changeLanguage(languageValue);
  //   document.title = t('common.appTitle');
  //   localStorage.setItem('selectedLanguage', languageValue);
  // };

  // const handleLanguageChange = (e: any) => {
  //   const languageValue = e.target.value;
  //   changeLanguage(languageValue);
  // };

  // useEffect(() => {
  //   const storedLanguage = localStorage.getItem('selectedLanguage');
  //   const defaultLanguage = 'en';
  //   const selectedLanguage = storedLanguage || defaultLanguage;

  //   if (selectedLanguage !== i18n.language) {
  //     changeLanguage(selectedLanguage);
  //   }

  //   const handleBeforeUnload = () => {
  //     localStorage.setItem('selectedLanguage', i18n.language);
  //   };

  //   const handlePageVisibilityChange = () => {
  //     if (document.visibilityState === 'hidden') {
  //       localStorage.setItem('selectedLanguage', i18n.language);
  //     }
  //   };

  //   window.addEventListener('beforeunload', handleBeforeUnload);
  //   document.addEventListener('visibilitychange', handlePageVisibilityChange);

  //   return () => {
  //     window.removeEventListener('beforeunload', handleBeforeUnload);
  //     document.removeEventListener('visibilitychange', handlePageVisibilityChange);
  //   };
  // }, [i18n.language]);
  // ========================================

  const setToken = (_token: string) => {
    dispatch(actions.setToken(_token));
  };

  const setupLogin = (resData: IApiLoginResData) => {
    setToken(resData?.access_token ?? '');
    const merchant_code = resData?.user?.merchant?.merchant_code;
    storage.merchantCode.set(merchant_code);
    storage.isConfirmDevice.set('');
    storage.merchantName.set(resData?.user?.merchant?.merchant_name);
    storage.merchantId.set(resData?.user?.merchant?.merchant_id);


    dispatch(actions.login.success(resData));
    dispatch(settingActions.getSetting.fetch());

    navigate(
      // (pathname !== '/sign-in' ? pathname : '') || '/private/bookings'
      '/private/bookings'
    );
  };

  const handleLoginConfirm = async (values: { pin: string }, resData: IApiLoginResData) => {
    try {
      const resConfirm = await apis.loginConfirm({
        device_id: getDeviceId(),
        device_activity: resData?.device_activity?.toString() ?? '',
        pin_number: values.pin
      });

      if (!resConfirm.data?.data) {
        if (resConfirm?.data?.error.message) {
          message.error(resConfirm?.data?.error.message);
          return;
        } else {
          throw { err: 'throw' };
        }
      }
      const resDataConfirm = resConfirm.data?.data;


      setupLogin(resDataConfirm);
    } catch (err) {
      message.error('Login is fail, please try again !');
    }
  };

  const login = async (values: IAuthForm) => {
    const { email, password } = values;
    const device_id = getDeviceId();
    setIsLoading(true);
    try {
      const res = await apis.login({ email, password, device_id });

      if (!res.data?.data) throw { response: res };

      const resData = res?.data?.data as IApiLoginResData;

      const packageExpiration = resData.permission?.package_expiration;

      if (packageExpiration && new Date(packageExpiration) < new Date()) {
        setError('YourPackageHasExpiredPleaseRenewToContinue');
        setIsLoading(false);
        return;
      }

      setToken(resData?.access_token ?? '');

      if (res.data?.data.device_confirm) {
        modalConfirmRef.current?.show({
          title: 'Confirm Login?',
          msg: 'Welcome back! We\'ve detected another login session from a different device using your account. To continue, please enter your pin number and click \'Continue\'',
          submit: (values: any) => handleLoginConfirm(values, resData)
        });
        return;
      }

      storage.permission.set(JSON.stringify(resData?.permission));
      setupLogin(resData);

      // const pathname = storage.pathname.get();

    } catch (error) {
      console.log(error, '124');
      const message = get(
        error,
        'response.data.error.message',
        'An error has occurred. Please try again !'
      );
      setError(message);
      dispatch(actions.login.fail({}));
    } finally {
      setIsLoading(false);
    }
  };

  // const handleLogin = (e: any) => {
  //   e.preventDefault();
  //   login();
  // };

  // const handleBtnClick = (e: any) => {
  //   e.preventDefault();
  //   /* eslint-disable-next-line */
  //   if (!/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g.test(email)) {
  //     setErrorEmail(true);
  //     return;
  //   }
  //   setErrorEmail(false);
  //   if (errorText === '') {
  //     setErrorText('Please enter password');
  //     return;
  //   }
  //   setErrorText('');

  //   handleLogin(e);
  //   // setIsShowInputPass(true);
  //   // setIsShowBtnBack(true);
  // };

  const onBackToSignIn = () => {
    // setIsShowInputPass(false);
    setIsShowForgotPass(false);
    // setIsShowBtnBack(false);
  };

  const handleBtnBackClick = () => {
    if (isShowForgotPass) {
      setIsShowForgotPass(false);
    } else {
      setEmail('');
      // setIsShowInputPass(false);
      // setIsShowBtnBack(false);
    }
  };

  useEffect(() => {
    const currToken = storage.token.get();
    if (currToken) {
      navigate('/private/bookings');
    } else {
      setPageDisplay(true);
    }
  }, []);

  const location = useLocation();
  const isForgotPasswordPage = location.pathname === '/forgot-password';

  return (
    <>
      {pageDisplay ? (
        <SignInPageStyled className='auth-layout'>
          <div className='layout-left'>
          </div>
          <div className='layout-right'>
            <div className='auth-content'>
              <div className='content-inner'>
                {isShowBtnBack && (
                  <span
                    onClick={handleBtnBackClick}
                    className='btn-back'
                  ></span>
                )}
                <div className='auth-block'>
                  {!isShowForgotPass && !isForgotPasswordPage ? (
                    <>
                      {/* <select value={i18n.language} onChange={handleLanguageChange}>
                    <option value="en">English</option>
                    <option value="vi">Vietnamese</option>
                  </select> */}
                      <h1 className='auth-title'>
                        {!isShowInputPass
                          ? signInLang('firstStep.title')
                          : signInLang('secondStep.title')}
                      </h1>
                      <p className='auth-text'>
                        {!isShowInputPass
                          ? signInLang('firstStep.text')
                          : signInLang('secondStep.text')}
                      </p>
                      <Form
                        data-form-testing='form-login'
                        onFinish={login}
                        className='modal-form'
                        layout='vertical'
                        form={form}
                      >
                        <Form.Item
                          name={'email'}
                          label={'Số điện thoại / Email (*)'}
                          rules={[
                            {
                              required: true,
                              message: signInLang('PleaseEnterEmailOrPhoneNumber') || '',
                            },
                            {
                              // type: 'email',
                              message:
                                signInLang('PleaseEnterTheValidEmailAddress') ||
                                '',
                            },
                          ]}
                        >
                          <Input
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            // type='email'
                            placeholder={
                              formLang('InputEmailOrPhoneNumberPlaceholder') || ''
                            }
                            autoFocus
                          />
                        </Form.Item>
                        <Form.Item
                          name={'password'}
                          label={'Mật khẩu (*)'}
                          rules={[
                            {
                              required: true,
                              message:
                                signInLang(
                                  'YourEmailOrPasswordIsNotCorrectPleaseCheckAgain'
                                ) || '',
                            },
                          ]}
                        >
                          <Input
                            type='password'
                            placeholder={
                              formLang('InputPasswordPlaceholder') || ''
                            }
                          />
                        </Form.Item>
                        {!!error && (
                          <p
                            data-testing='msg-wrong-password-email'
                            style={{
                              color: 'var(--color-red)',
                              padding: '0 0 16px',
                            }}
                          >
                            {error}
                          </p>
                        )}

                        <button
                          className='btn-submit'
                          type='submit'
                        // onClick={handleBtnClick}
                        >
                          {signInLang('SignIn')}
                        </button>
                        {isShowInputPass && (
                          <div className='auth-forgot'>
                            <a href="/forgot-password">
                              <span>
                                {commonLang('ForgotPassword')}
                              </span>
                            </a>
                          </div>
                        )}
                      </Form>
                    </>
                  ) : (
                    <ForgotPassword
                      avatar={AuthAvatar}
                      email={email}
                      backToSignIn={onBackToSignIn}
                    />
                  )}
                </div>
                <div className='auth-logo'>
                  <img src={MainLogo} alt='Natural Nails and Beauty'></img>
                </div>
              </div>
            </div>
          </div>
          <ModalConfirmSignIn ref={modalConfirmRef} usePinVerify />
        </SignInPageStyled>
      ) : null}
    </>
  );
};

export default SignInPage;

const SignInPageStyled = styled.section`
  .auth-logo {
    position: absolute;
    /* bottom: 2.5vh; */
    bottom: 5.5vh;
  }
  .ant-form-item-explain-error {
    display: flex;
    align-items: start;
  }
  .auth-forgot {
    margin: 16px 0;
  }
`;