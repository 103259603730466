import { Empty, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import ModalConfirm, {
  ModalConfirmRef,
} from 'components/common/Modal/ModalConfirm';
import { useRef } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useAppDispatch } from 'store/hooks';
import promotionActions from '../services/actions';
import promotionSelectors from '../services/selectors';
import { IPromotionItemResData } from '../types/promotion';
import EditPromotion from '../widgets/Edit';
import { useTranslation } from 'react-i18next';
import { formatMoney } from 'utils/unit';
import styled from 'styled-components';

const PromotionsTable = () => {
  const confirmRef = useRef<ModalConfirmRef>(null);
  // const lstData = promotionSelectors.getLstPromotions();
  const pagination = promotionSelectors.getLstPromotionPagination();
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();

  const { t: promotionLang } = useTranslation('promotions');

  const loading = promotionSelectors.getLoadingLstPromotions();
  const loadingActions = promotionSelectors.getLoadingPromotionActions();

  const confirm = (id: IPromotionItemResData['id']) => {
    confirmRef.current?.show({
      title: promotionLang('AreYouSure'),
      icon: 'delete',
      msg: promotionLang('AreYouSureRemoveThisPromotion'),
      submit: async () => {
        dispatch(promotionActions.deletePromotion(id.toString()));
      },
    });
  };

  const data = [
    {
      id: 1,
      name: 'Promotion name',
      type: 'Khuyến mãi sinh nhật',
      data_start: '24.12.2023',
      date_end: '24.12.2023',
      price: 10,
      value_type: 'Percent',
      applied: 100000,
      status: 'active'
    },
    {
      id: 2,
      name: 'Promotion name',
      type: 'Tái tương tác',
      data_start: '24.12.2023',
      date_end: 'Đang tiếp tục',
      price: 100000,
      value_type: 'Normal',
      applied: 0,
      status: 'deactive'
    },
    {
      id: 3,
      name: 'Promotion name',
      type: 'Đăng ký lần đầu',
      data_start: '24.12.2023',
      date_end: 'Đang tiếp tục',
      price: 10,
      value_type: 'Percent',
      applied: 0,
      status: 'active'
    },
    {
      id: 4,
      name: 'Promotion name',
      type: 'Tích điểm đổi quà',
      data_start: null,
      date_end: null,
      price: 10,
      value_type: 'Percent',
      applied: 0,
      status: 'active'
    },
    {
      id: 5,
      name: 'Promotion name',
      type: 'Khuyến mãi tự động',
      data_start: '24.12.2023',
      date_end: '24.12.2023',
      price: 10,
      value_type: 'Percent',
      applied: 0,
      status: 'active'
    },
    {
      id: 6,
      name: 'Promotion name',
      type: 'Mã khuyến mãi',
      data_start: null,
      date_end: null,
      price: 10,
      value_type: 'Percent',
      applied: 0,
      status: 'end'
    }
  ];

  const columns: ColumnsType<any> = [
    {
      title: 'Tên khuyến mãi',
      dataIndex: 'name',
      key: 'name',
      fixed: 'left',
      width: 200,
      render: (value) => (
        <NameStyled>
          <a>
            {value}
          </a>
        </NameStyled>
      ),
    },
    {
      title: 'Loại',
      dataIndex: 'type',
      key: 'type',
      className: 'nowrap',
      width: 200,
      render: (text, record) => (
        <TypeStyled>
          <div>
            <p>{text}</p>
            {
              record?.data_start && record?.date_end ?
                <p className='date'>{record?.data_start} - {record?.date_end}</p>
                :
                <></>
            }

          </div>
        </TypeStyled>
      ),
    },
    {
      title: 'Giá trị áp dụng',
      dataIndex: 'price',
      key: 'price',
      render: (text, record) => (
        <div>
          {record?.value_type === 'Percent' ? text + '%' : formatMoney(text)}
        </div>
      ),
    },
    {
      title: 'Đã áp dụng',
      dataIndex: 'applied',
      key: 'applied',
      className: 'nowrap',
      render: (text) => (
        <div>
          {formatMoney(text)}
        </div>
      ),
    },
    {
      title: 'Trạng thái',
      dataIndex: 'status',
      key: 'status',
      render: (value) => (
        <StatusStyled
          className={`common-status promotion ${value === 'active' ? 'active' : value === 'end' ? 'end' : 'deactive'}`}
        >
          {value === 'active' ? 'Hiển thị' : value === 'deactive' ? 'Đã ẩn' : 'Kết thúc'}
        </StatusStyled>
      ),
    },
    {
      title: promotionLang('Actions'),
      dataIndex: 'actions',
      key: 'actions',
      render: (tex, record) => {
        return (
          <ul className={'common-action'}>
            <EditPromotion record={record} />
            {/* <li className='action-send'></li> */}
            <li
              className='action-remove'
              onClick={() => confirm(record.id)}
            ></li>
          </ul>
        );
      },
    },
  ];

  return (
    <>
      <Table
        className={'custom-table'}
        dataSource={data}
        columns={columns}
        loading={loading || loadingActions}
        scroll={{
          x: 900,
        }}
        rowKey={(row) => row.id}
        pagination={{
          total: pagination?.total_record,
          pageSize: pagination?.record_per_page,
          defaultCurrent: Number(searchParams.get('page') ?? 1),
          defaultPageSize: Number(searchParams.get('per_page') ?? 10),
          onChange: (pageChange, sizeChange) => {
            const params: any = {
              page: pageChange == 0 ? 1 : pageChange,
              per_page: sizeChange,
            };
            dispatch(
              promotionActions.setListPromotionParams({
                page: params.page,
                per_page: params.per_page,
              })
            );
          },
          showSizeChanger: false,
          // showTotal(total) {
          //   return `Total ${total} items`;
          // },
        }}
        locale={{
          emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={promotionLang('NoPromotion')} />,
        }}
      />
      <ModalConfirm ref={confirmRef} />
    </>
  );
};

export default PromotionsTable;

const StatusStyled = styled.div`
&.end {
  background-color: #F5F5F5;
  color: var(--Gray-50, #8C8C8C);
  font-size: 12px;
  font-weight: 500;
  line-height: 140%;
}
`;

const NameStyled = styled.div`
  a {
    overflow: hidden;
    color: var(--Blue-Munsell-Main, #1B9AAA);
    text-overflow: ellipsis;
    font-size: 16px;
    font-weight: 500;
    line-height: 140%;
    text-decoration-line: underline;
  }
`;
const TypeStyled = styled.div`
p {
  white-space: nowrap;
  padding: 6px 12px;
  gap: 10px;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 500;
  line-height: 140%;
  max-width: 90px;
}
.date {
  font-size: 12px;
  color: var(--Gray-60, #595959);
}
`;