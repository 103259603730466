import React, { useEffect, useMemo, useState, useTransition } from 'react';
import styled from 'styled-components';
import PromotionModal from './Modal';
import { IPromotionFormProps } from './Form';
import apisPromotion from '../services/apis';
import { useSetLoadingPage } from 'services/UI/LoadingPage';
import { get, set } from 'lodash';
import message from 'components/common/Message';
import { useAppDispatch } from 'store/hooks';
import promotionActions from '../services/actions';
import { IPromotionFormValue, IPromotionItemResData } from '../types/promotion';
import { EPromotionType } from '../services/types/promotion';
import promotionSelectors from '../services/selectors';
import { Form } from 'antd';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

type EditPromotionProps = {
  record: IPromotionItemResData;
};
const EditPromotion: React.FC<EditPromotionProps> = ({ record }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [, startTransition] = useTransition();
  const configsData = promotionSelectors.getConfigsData();
  const [form] = Form.useForm();
  const data = promotionSelectors.getDetailPromotion();
  const {t: promotionLang} = useTranslation('promotions');
  useEffect(() => {
    if (isModalOpen)
      dispatch(
        promotionActions.getDetailPromotion.fetch(record?.id?.toString())
      );
  }, [isModalOpen]);

  const formData: IPromotionFormValue | null = useMemo(() => {
    if (isModalOpen) {
      const acceptServices: string[] = [];
      if (typeof data?.services_restricts !== 'string')
        data?.services_restricts?.forEach((service) => {
          if (service?.service_variants?.length > 0) {
            service?.service_variants.forEach((serviceVariantId) => {
              acceptServices.push(service?.id + '-' + serviceVariantId.id);
            });
          } else {
            acceptServices.push(service.id?.toString());
          }
        });

      const acceptLocations = data?.location_restricts
        ?.filter((o) => o.status === 1)
        .map((o) => String(o.id));
      const restrictLocations = configsData?.locarions
        ?.filter((o) => {
          if (o.status !== 1) return false;
          return acceptLocations?.includes(o.id.toString());
        })
        .map((o) => o.id.toString());

      const configServices: string[] = [];
      configsData?.services.forEach((service) => {
        if (service?.service_variants?.length > 0) {
          service?.service_variants.forEach((serviceVariant) => {
            configServices.push(service?.id + '-' + serviceVariant.id);
          });
        } else {
          configServices.push(service.id?.toString());
        }
      });

      const restrictServices = configServices.filter(
        (o) => acceptServices?.includes(o)
      );

      return {
        amountValue: data?.type === EPromotionType.PRICE ? +data?.value : null,
        percentValue:
          data?.type === EPromotionType.PERCENT ? +data?.value : null,
        enableBooking: !!data?.enable_booking,
        locations: restrictLocations,
        name: data?.name ?? null,
        code: data?.code ?? null,
        services: restrictServices,
        promotionType: data?.promotion_type,
        enableBookingOnline: !!data?.enable_booking_online,
        enableVoucherSale: !!data?.enable_voucher_sales,
        enableApplyWalkIn: !!data?.apply_for_walkin,
        enablePromotion: !!data?.status,
        startDate: data?.start_date ? dayjs(data?.start_date) : '',
        endDate: data?.end_date ? dayjs(data?.end_date) : '',
        isUsed: data?.is_used,
        reEngageOffer: (data?.re_engage_offer ?? 0) > 0,
        reEngageOfferValue: data?.re_engage_offer,
        limitToUsePerCustomer: (data?.usage_limit_per_customer ?? 0) > 0,
        limitToUsePerCustomerValue: data?.usage_limit_per_customer,
        limitTotalNumberOfUse: (data?.usage_limit_per_promotion ?? 0) > 0,
        limitTotalNumberOfUseValue: data?.usage_limit_per_promotion,
        applyWithOtherPromotion: data?.apply_other_promotion
      };
    }
    return null;
    // });
  }, [data]);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const hideModal = () => {
    startTransition(() => {
      // setFormData(undefined);
      setIsModalOpen(false);
    });
  };

  const setLoadingPage = useSetLoadingPage();
  const dispatch = useAppDispatch();

  const [errors, setErrors] = useState<IPromotionFormProps['errors']>(null);

  const handleSubmit: IPromotionFormProps['onSubmit'] = async (payload) => {
    setLoadingPage(true);
    try {
      const rest = await apisPromotion.updatePromotion(data?.id ?? 0, payload);
      const msg = get(rest, 'data.message', '');
      if (msg) {
        hideModal();
        dispatch(
          promotionActions.setListPromotionParams({
            page: 1,
          })
        );
        message.success(msg);
      } else {
        const msgError = get(rest, 'data?.error.message', '');
        if (msgError) message.error(msgError);
        else throw 'fail';
      }
    } catch (error: any) {
      const errorText = get(error, 'response.data?.error.message', '');
      if (errorText) {
        message.error(errorText);
        return;
      }

      const response: Record<string, string[]> = error?.response?.data?.errors;

      const errors = Object.entries(response);
      if (errors.length > 0) {
        const errorsObj = {};
        errors.forEach(([key, value]) => {
          set(
            errorsObj,
            [key?.includes('services') ? 'services' : key],
            value?.[0]
          );
        });
        setErrors(errorsObj);
        errors.map(([, value]) => {
          message.error(get(value, [0], ''));
        });
        return;
      }
      message.error(promotionLang('AnErrorOccurredPleaseTryAgain')||'');
    } finally {
      setLoadingPage(false);
    }
  };

  return (
    <>
      <EditPromotionStyled>
        <li className='action-edit' onClick={showModal}></li>
      </EditPromotionStyled>
      {isModalOpen && (
        <PromotionModal
          form={form}
          visible={isModalOpen}
          isEdit
          formData={formData}
          onClose={hideModal}
          onSubmit={handleSubmit}
          errors={errors}
          formType='UPDATE'
          handleUpdateError={(data: Record<string, string>) => {
            setErrors({
              ...errors,
              ...data
            });
          }}
        />
      )}
    </>
  );
};

export default EditPromotion;
const EditPromotionStyled = styled.div``;
