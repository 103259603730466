import { debounce } from 'lodash';
import { useCallback, useEffect, useState } from 'react';

/**
 * Hook debounce for search
 * @param onSearch 
 * @param initialData 
 * @returns 
 */

const useSearchDebounce = (onSearch: (text: string) => void, initialData?: any): [string, React.ChangeEventHandler<HTMLInputElement>] => {
  const [text, setText] = useState('');

  const onChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    if (!e.target.value) {
      setText('');
      onSearch('');
      return;
    }
    setText(e.target.value);
  };

  const _debounce = useCallback(debounce((_text) => {
    onSearch(_text);
  }, 800), [initialData]);

  useEffect(() => _debounce(text), [text, initialData]);
  return [text, onChange];
};

export default useSearchDebounce;
