import { QRCode, Spin, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
// import { AxiosResponse } from 'axios';
// import message from 'components/common/Message';
import ModalConfirm, { ModalConfirmRef } from 'components/common/Modal/ModalConfirm';
// import { PATH } from 'constants/path.enum';
// import { get } from 'lodash';
import { useRef, useState } from 'react';
// import { useDispatch } from 'react-redux';
import { Link, NavLink, useSearchParams } from 'react-router-dom';
// import { useSetLoadingPage } from 'services/UI/LoadingPage';
// import locationActions from '../services/actions';
// import apisLocation from '../services/apis';
import locationSelectors from '../services/selectors';
import styled from 'styled-components';
import ModalQRCode from 'components/common/Modal/ModalQRCode';
import { useTranslation } from 'react-i18next';
import { PATH } from 'constants/path.enum';
import message from 'components/common/Message';
import locationActions from '../services/actions';
import apisLocation from '../services/apis';
import { useAppDispatch } from 'store/hooks';
import { get } from 'lodash';


interface DataType {
  key: React.Key;
  name: string;
  address: string;
  contact_number: string;
  status: string;
  actions: string;
  qr_code: string
}

/**
 * Locations table
 * @returns 
 */
const LocationsTable = () => {
  // const [searchParams] = useSearchParams();

  const data = locationSelectors.getPaginateListLocations();
  const pagination = data.pagination;
  const [searchParams, setSearchParams] = useSearchParams();
  const loading = locationSelectors.loadingLocationsPaginateList();
  const [locationSelected, setLocationSelected] = useState<DataType>();

  const [, setPageLoading] = useState<boolean>(false);

  const { t: vouchersLang } = useTranslation('vouchers');

  const dispatch = useAppDispatch();

  // const page: { page: number, per_page: number } = {
  //   page: Number(searchParams.get('page')) ?? 1,
  //   per_page: Number(searchParams.get('per_page')) ?? 10
  // };

  const dataSource = data?.data?.map((item: any) => ({
    ...item,
    statusLabel: item.status ? 'Active' : 'Disable'
  }));

  // const setPageLoading = useSetLoadingPage();
  const confirmRef = useRef<ModalConfirmRef>(null);

  // useEffect(() => {
  //   dispatch(locationActions.getListPaginateLocations.fetch({
  //     page: Number(searchParams.get('page')),
  //     per_page: Number(searchParams.get('per_page')),
  //   }));
  // },[searchParams]);

  const getPathQr = (qrCode: string) => {
    const href = window.location.href;
    return href.replace(location?.pathname, '') + '/' + qrCode;
  };

  const confirm = (id: string) => {
    const onOk = async () => {

      setPageLoading(true); // Set loading state to true to indicate deletion process is in progress
      try {
        // Send request to delete the location
        const res = await apisLocation.deleteLocation(id?.replace('#', ''));
        const msg = get(res, 'data.message', ''); // Extract message from the response
        if (msg) {
          // If success message is available:
          // - Display success message
          // - Dispatch action to update state indicating successful location deletion
          // - Fetch updated list of locations
          message.success(msg);
          dispatch(locationActions.deleteLocation.success({}));
          dispatch(locationActions.getListPaginateLocations.fetch({
            page: Number(searchParams.get('page')),
            per_page: Number(searchParams.get('per_page')),
          }));
        } else {
          // If error message is available, display it
          const msgError = get(res, 'data.error.message');
          if (msgError) message.error(msgError);
          else throw 'fail'; // If neither success nor error message is available, throw an error
        }
      } catch (error) {
         // Handle errors
        message.error('An error occurred. Please try again!');
        // Log the error for debugging purposes
      } finally {
        setPageLoading(false);// Reset loading state to false after deletion process completes
      }
    };
    // Show confirmation modal
    confirmRef.current?.show({
      title: vouchersLang('AreYouSure'),
      msg: vouchersLang('AreYouSureRemoveThisVouchers'),
      icon: 'delete',
      submit: onOk// Call onOk function when user confirms deletion
    });
  };
  // const handleUpdateStatus = async (id: number, status: boolean) => {
  //   setPageLoading(true);
  //   try {
  //     const res = await apisLocation.updateLocationStatus(id, status);
  //     const msg = get(res, 'data.message', '');
  //     if (msg) {
  //       dispatch(locationActions.getListPaginateLocations.fetch({
  //         page: 1,
  //         per_page: 10
  //       }));
  //       message.success(msg);
  //     } else {
  //       const msgError = get(res, 'data.error.message', '');
  //       if (msgError) message.error(msgError);
  //       else throw 'fail';
  //     }
  //   } catch (error) {
  //     const errors = Object.entries(get(error, 'response.data.errors', {}));
  //     if (errors.length > 0) {
  //       errors.map(([, valueArr]) => {
  //         message.error(get(valueArr, [0], ''));
  //       });
  //       return;
  //     }
  //     message.error('An error occurred. Please try again');
  //   } finally {
  //     setPageLoading(false);
  //   }
  // };

  // const onSwitchStatus = (id: number, status: boolean) => {
  //   if (!confirmRef) return;
  //   const nextStatus = !status;
  //   confirmRef.current?.show({
  //     title: 'Are you sure',
  //     msg: `Are you sure ${nextStatus ? 'enable' : 'disable'} this location?`,
  //     submit: () => handleUpdateStatus(id, nextStatus),
  //   });
  // };

  const { t: locationLang } = useTranslation('location');
  const columns: ColumnsType<DataType> = [
    {
      // title: ,
      dataIndex: 'image',
      key: 'image',
      fixed: 'left',
      render: (text, record) => (
        <ImageLocationStyled>
          {/* <img src={qr_code} alt="" /> */}
          <QRCode size={70} value={getPathQr(record?.qr_code ?? '') ?? ''} />
        </ImageLocationStyled>
      ),
    },
    {
      title: locationLang('StoreName'),
      dataIndex: 'name',
      key: 'name',
      // fixed: 'left',
      render: (text: any, record: any) => (
        <LocationNameStyled>
          <Link to={`edit/${record.id}`}>{text}</Link>
        </LocationNameStyled>
      ),
    },
    {
      title: locationLang('Address'),
      dataIndex: 'address',
      key: 'address',
    },
    {
      title: 'Số hotline',
      dataIndex: 'contact_number',
      key: 'contact_number',
      render: (text: any) => (
        <div>{text}</div>
      ),
    },
    {
      title: locationLang('Status'),
      dataIndex: 'statusLabel',
      key: 'statusLabel',
      render: (text: string) => (
        <StatusLocationstyled>
          <div className={`common-status is-green ${text == 'Disable' ? 'disabled' : ''}`}>{locationLang(text)}</div>
        </StatusLocationstyled>
      ),
    },
    {
      title: locationLang('Actions'),
      dataIndex: 'actions',
      key: 'actions',
      render: (text: string, record: any) => {
        return (
          <ul className={'common-action'}>
            {/* <Switch onClick={() => onSwitchStatus(+record?.id, !!record?.status)} checked={!!record?.status} /> */}
            <li>
              <NavLink
                to={PATH.locations_edit + record?.id}
              >
                <span className='action-item action-edit'></span>
              </NavLink>
            </li>
            {dataSource?.length > 1 && <li className="action-remove" onClick={() => confirm(String(record?.id ?? ''))}></li>}
          </ul>
        );
      },
    },
  ];

  return (
    <>
      <Spin spinning={loading}>

        <Table
          className={'custom-table'}
          dataSource={dataSource}
          columns={columns}
          scroll={{
            x: 900,
          }}
          pagination={{
            total: pagination?.total_record,
            pageSize: pagination?.record_per_page,
            defaultCurrent: Number(searchParams.get('page') ?? 1),
            defaultPageSize: Number(searchParams.get('per_page') ?? 10),
            onChange: (pageChange, sizeChange) => {
              const params: any = {
                page: pageChange == 0 ? 1 : pageChange,
                per_page: sizeChange,
              };
              setSearchParams(params);
              // dispatch(promotionActions.setListPromotionParams({
              //   page: params.page,
              //   per_page: params.per_page,
              // }));
            },
            showSizeChanger: false,
          }}
        />
      </Spin>
      <ModalQRCode isModalVisible={!!locationSelected} handleModalCancel={() => setLocationSelected(undefined)} locationName={locationSelected?.name} QRpath={getPathQr(locationSelected?.qr_code ?? '')} />
      <ModalConfirm ref={confirmRef} />
    </>
  );
};

export default LocationsTable;

// const QRCodeStyled = styled(QRCode)`
//   cursor: pointer;
// `;


const LocationNameStyled = styled.div`
  a {
    color: #1B9AAA;
    font-family: Inter;
    font-size: 16px;
    font-weight: 500;
    line-height: 140%;
    text-decoration-line: underline;
  }
`;

const StatusLocationstyled = styled.div`
.disabled {
  padding: 6px 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  color:#8C8C8C;
  font-family: Inter;
  font-size: 12px;
  font-weight: 500;
  line-height: 140%
}
`;

const ImageLocationStyled = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
