import { flatten, get, uniq } from 'lodash';
import moment from 'moment';
import getTimes, { setTime } from 'utils/moment/getTimes';
import { BookingItem, IRowCalendar, IRowSpanInfo, SchemaCalendar } from './types';
import { useMemo } from 'react';
import { IBookingItemResData } from 'features/bookings/services/types/booking';
import bookingSelectors from 'features/bookings/services/selectors';
import { CalendarViewType } from 'constants/index';
import { BREAK_TIME_MINUTES } from 'features/bookings/services/constants';

/**
 * Mapping form schema and data booking to data for render booking item
 * @param schema 
 * @param _data 
 * @returns 
 */
const useMappingCalendar = (schema: SchemaCalendar, _data: BookingItem[]): ([IRowCalendar[], IRowSpanInfo[]]) => {
  const viewType = bookingSelectors.getCalendarViewType();
  const data = useMemo(() => {
    if (viewType !== CalendarViewType.MemberView) return _data;
    
    const result: IBookingItemResData[] = [];
    _data.forEach(parent => {
      const timeStart = moment(parent.time_start);
      if (parent.book_assignment_services.length > 0)
        parent.book_assignment_services.forEach(s => {

          result.push({
            ...parent,
            // time_start: s.assigned_employee.time_start,
            // time_end: s.assigned_employee.time_end,
            time_start: timeStart.format('YYYY-MM-DD HH:mm:ss'),
            parent_time_start: parent.time_start,
            time_end: timeStart.add(s.duration_time * s.quantity, 'minutes').format('YYYY-MM-DD HH:mm:ss'),
            book_assignment_services: [s],
          });
          timeStart.add(BREAK_TIME_MINUTES, 'minutes');
        });
      else {
        result.push(parent);
      }
    });
    return result;
  }, [_data, viewType]);

  const rowTimesMaster = getTimes(setTime(schema.timeHourStart, schema.timeMinuteStart), setTime(schema.timeHourEnd, schema.timeMinuteEnd), schema.distanceMinute || 30);
  const rowsSpan = schema.headers.map(col => {
    const listFilter = data.filter(order => {
      return schema.filterBooking(order, col);
    });

    if (listFilter.length === 0) return;
    const mapping = rowTimesMaster.map(rowTime => {
      const obSetTime = { hour: rowTime.value.get('hour'), minute: rowTime.value.get('minute'), second: 0 };
      const orderList = listFilter.filter(order => {
        const orderTimeStart = moment(order.time_start);
        const orderTimeFinish = moment(order.time_end);
        const time = orderTimeStart.clone().set(obSetTime);
        const currentNextTime = time.clone().add(schema.distanceMinute, 'minute');
        const isBetween = orderTimeStart.isBetween(time, currentNextTime);
        const isOrderTimeFinishValid = time.isSameOrAfter(orderTimeStart) && orderTimeFinish.subtract(1, 'minute').isSameOrAfter(time);
        return isBetween || isOrderTimeFinishValid;
      }).map(o => ({
        id: o.id,
        rowTimeId: rowTime.value.format('HH:mm'),
        timeStart: moment(o.time_start),
        timeStartLabel: moment(o.time_start).format('HH:mm'),
        timeEnd: moment(o.time_end),
        timeEndLabel: moment(o.time_end).format('HH:mm'),
      }));
      return ({
        orderList: orderList.map(o => o.id.toString()),
        rowId: rowTime.id,
        rowValue: rowTime.value,
      });
    });
    const spam: IRowSpanInfo[] = [];
    mapping.forEach((data, index) => {
      const prevOrders = get(mapping, [index - 1, 'orderList'], []) as string[];
      const orderList = data.orderList ?? [];
      if (orderList.length === 0) return;
      const isContinue = orderList.some(o => prevOrders.includes(o));
      if (!isContinue) {
        spam.push({
          rowStart: { id: data.rowId, value: data.rowValue.clone() },
          rowEnd: { id: data.rowId, value: data.rowValue.clone() },
          orderIds: [...data.orderList],
          rowId: data.rowId,
          colId: col.id,
          rowSpan: 1,
        });
      } else {
        const current = spam.find(o => !!orderList.find(k => o.orderIds.includes(k)));
        if (current) {
          current.orderIds = uniq([...current.orderIds, ...orderList]);
          current.rowEnd = { id: data.rowId, value: data.rowValue.clone() };
        }
      }
    });
    return spam;
  }).filter(o => !!o);
  const rowsSpanInfoTemp = flatten(rowsSpan) as IRowSpanInfo[];
  const rowsSpanInfo = rowsSpanInfoTemp.map(o => {
    return {
    ...o,
    rowSpan: (o.rowEnd.value.diff(o.rowStart.value, 'minutes') / schema.distanceMinute) + 1
  };});

  const result = rowTimesMaster.map((rowTime) => {
    const colData = schema.headers.map((col) => {
      const listFilter = data.filter(order => {
        return schema.filterBooking(order, col);
      });
      const rowInfo = rowsSpanInfo.find(o => rowTime.id === o.rowId && o.colId === col.id);
      const bookingDataFilter = listFilter.filter(o => rowInfo?.orderIds.includes(o.id.toString()));

      return ({
        id: col.id,
        data: bookingDataFilter,
        rowTime: rowTime.value,
      });
    }).filter((col) => {
      const rowInfo = rowsSpanInfo.filter(o => o.colId === col.id);
      return !rowInfo.some(o => o.rowSpan > 1 && (
        rowTime.value.isBetween(o.rowStart.value, o.rowEnd.value) ||
        rowTime.value.isSame(o.rowEnd.value)
      ));
    });

    return {
      id: rowTime.id,
      rowTime: rowTime.value,
      colData,
    };
  });
  return [result, rowsSpanInfo];
};

export default useMappingCalendar;
