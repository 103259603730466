import { Col, Row } from 'antd';
import { uniqueId } from 'lodash';
import React from 'react';
import styled from 'styled-components';
import BoxSummaryItem from './BoxSummaryItem';
import workingHourSelectors from 'features/workingHour/services/selectors';
import { useTranslation } from 'react-i18next';
import { getFormatTime } from 'utils/moment/getSingleDateShortFormat';
// eslint-disable-next-line @typescript-eslint/ban-types
type IWorkingHourSummaryProps = {};
const WorkingHourSummary: React.FC<IWorkingHourSummaryProps> = ({ }) => {
  const totalTopAnalytic = workingHourSelectors.getTotalTopAnalytic();
  const { t: workingHourLang } = useTranslation('workingHour');
  const { t: dateLang } = useTranslation('date');


  const mockData = [
    {
      id: uniqueId(),
      label: workingHourLang('TotalHoursWorked'),
      value: getFormatTime(totalTopAnalytic?.total_worked, dateLang) ?? 0,
    },
    {
      id: uniqueId(),
      label: workingHourLang('TotalTeamMember'),
      value: totalTopAnalytic?.total_employee_actived ?? 0,
    },
    {
      id: uniqueId(),
      label: workingHourLang('TotalCustomerServed'),
      value: totalTopAnalytic?.total_customer_serviced ?? 0,
    },
    // {
    //   id: uniqueId(),
    //   label: 'Holiday day off',
    //   value: totalTopAnalytic?.total_holiday ?? 0,
    // }
  ];
  return (
    <WorkingHourSummaryStyled>
      <Row gutter={[16, 16]}>
        {mockData.map(o => (
          <Col key={o.id} xs={12} sm={12} md={8} lg={8} xl={8} xxl={8}>
            <BoxSummaryItem label={o.label} value={o.value} />
          </Col>
        ))}
      </Row>
    </WorkingHourSummaryStyled>
  );
};

export default WorkingHourSummary;
const WorkingHourSummaryStyled = styled.div`
  margin-bottom: 24px;
`;

