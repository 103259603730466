import { Select } from 'antd';
import { EDateOption } from 'features/workingHour/services/types/workingHour';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
type ITypeViewPickerProps = {
  onChange: (val: string) => void;
  _options?: {value: string, label: string}[]
  value?: string;
};

/**
 * Handle option picker
 * @param onChange, _options, value 
 * @returns 
 */
const OptionPicker: React.FC<ITypeViewPickerProps> = ({ onChange, _options, value }) => {
  const { t: dateLang } = useTranslation('date');
  const options: { value: string, label: string }[] = _options ?? [
    { value: EDateOption.TODAY, label: dateLang('Today') },
    { value: EDateOption.YESTERDAY, label: dateLang('Yesterday') },
    { value: EDateOption.LAST_7_DAYS, label: dateLang('Last7Days')},
    { value: EDateOption.LAST_30_DAYS, label: dateLang('Last30Days')},
    { value: EDateOption.LAST_QUARTER, label: dateLang('LastQuarter')},
    { value: EDateOption.LAST_YEAR, label: dateLang('LastYear')},
    { value: EDateOption.CUSTOM, label: dateLang('CustomRange')},
  ];

  return (
    <TypeViewPickerStyled>
      <div className="common-select">
        <Select
          defaultValue={options[0]?.value ?? ''}
          options={options}
          className='is-normal'
          onChange={onChange}
          value={value}
        />
      </div>
    </TypeViewPickerStyled>
  );
};

export default OptionPicker;
const TypeViewPickerStyled = styled.div``;
