import IconCustomerCircle from 'assets/svg/IconCustomerCircle';
import IconDiscountCircle from 'assets/svg/IconDiscountCircle';
import IconStarCircle from 'assets/svg/IconStarCircle';
import IconVoucherCircle from 'assets/svg/IconVoucherCircle';
import ChartSales from '../components/ChartSales';
import ChartServices from '../components/ChartServices';
import SalesTable from '../components/SalesTable';
import WorkingTable from '../components/WorkingTable';
import styles from './index.module.scss';
import { Select, message } from 'antd';
import { useEffect, useMemo, useState } from 'react';
import analyticActions from '../services/actions';
import { useDispatch } from 'react-redux';
import analyticSelectors from '../services/selectors';
import moment from 'moment';
import downloadFile from 'utils/downloadFile';
import { first, get, last } from 'lodash';
import apisAnalytic from '../services/apis';
import { useSetLoadingPage } from 'services/UI/LoadingPage';
import { Dayjs } from 'dayjs';
import { useRangeDateTime } from 'components/common/GroupFilter/hook/useRangeDate';
import GroupFilterDate from 'components/common/GroupFilter';
import { ISummary } from '../services/types/api';
import { formatMoney } from 'utils/unit';
import { TIME_START_FORMAT_RESPONSE } from 'features/bookings/services/constants';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Can from 'features/packagePermission/components/Can';
import { AnalyticPermission, ModulePermission } from 'features/packagePermission/services/types/permission';
import locationActions from 'features/locations_v2/services/actions';
import locationSelectors from 'features/locations_v2/services/selectors';

type MyDivProps = React.HTMLProps<HTMLDivElement> & {
  style?: {
    '--p': number;
  };
};

export interface IOptionAnalyticItem {
  value: 'month' | 'week' | 'year' | 'month_3';
  label: string;
}

/**
 * Analytics page
 * @returns 
 */
const AnalyticsPage = () => {

  const dispatch = useDispatch();
  const setLoadingPage = useSetLoadingPage();
  const [locationSelect, setLocationSelect] = useState();
  const locations = locationSelectors.getPaginateListLocations()?.data;
  const [optionDate, setOptionDate] = useState<{ dateStart: string, dateEnd: string }>(() => {

    const dateStart = moment().format('YYYY-MM-DD 00:00:00');
    const dateEnd = moment().format('YYYY-MM-DD 23:59:59');

    return { dateStart: dateStart, dateEnd: dateEnd };
  }
  );

  useMemo(() => {
    const dateStart = moment().format('YYYY-MM-DD 00:00:00');
    const dateEnd = moment().format('YYYY-MM-DD 23:59:59');

    setLocationSelect(locations?.[0]?.id);
    dispatch(analyticActions.getAnalytics.fetch({
      start_date: dateStart,
      end_date: dateEnd,
      merchant_location_id: locations?.[0]?.id
    }));
  }, [locations]);

  useEffect(() => {
    dispatch(locationActions.getListPaginateLocations.fetch({}));
  }, []);
  const locationOption = locations?.filter((location: any) => location?.status)?.map((location: any) => ({
    label: location.name,
    value: location.id
  }));

  console.log(locationOption);
  console.log(locations);
  
  /**
   * on change location
   * @param value 
   */
  const onChangeLocation = (value: any) => {
    setLocationSelect(value);
    dispatch(analyticActions.getAnalytics.fetch({
      start_date: moment(optionDate?.dateStart).format('YYYY-MM-DD 00:00:00'),
      end_date: moment(optionDate?.dateEnd).format('YYYY-MM-DD 23:59:59'),
      merchant_location_id: value
    }));
  };

  const getRangeDate = useRangeDateTime;

  const [isMoment, setIsMoment] = useState<any>();

  /**
   * on change option picker
   * @param val 
   */
  const onChangeOptionPicker = (val: string) => {
    const [unit, amount] = val.split('_');
    setIsMoment(unit);
    const daysGap7 = '7';
    const daysGap30 = '30';

    const amountSv = amount === daysGap7 ? '6' : amount === daysGap30 ? '29' : amount;

    const [dateStart, dateEnd] = getRangeDate(unit, Number(amountSv));

    setOptionDate({ dateStart, dateEnd });
    dispatch(analyticActions.getAnalytics.fetch({
      start_date: moment(dateStart).format('YYYY-MM-DD 00:00:00'),
      end_date: moment(dateEnd).format('YYYY-MM-DD 23:59:59'),
      merchant_location_id: locationSelect,
    }));
  };

  const today = moment();

  const selectedStartDate = moment(optionDate.dateStart);
  const selectedEndDate = moment(optionDate.dateEnd);
  let comparisonText;

  if (today.isSame(selectedStartDate, 'day') && today.isSame(selectedEndDate, 'day')) {
    comparisonText = 'So sánh với ngày hôm qua';
  } else {
    const daysDifferenceStart = today.diff(selectedStartDate, 'days');
    const daysDifferenceEnd = today.diff(selectedEndDate, 'days');

    const totalStartDay = daysDifferenceStart + 2;
    const totalEndDay = daysDifferenceEnd + 1;

    const modifiedStartDate = selectedStartDate.clone().subtract(totalStartDay, 'days');
    const modifiedEndDate = selectedStartDate.clone().subtract(totalEndDay, 'days');

    if (isMoment === 'quarter') {
      const modifiedStartQuarters = selectedStartDate.clone().subtract(Math.ceil(totalEndDay / 90), 'quarters').startOf('quarter');
      const modifiedEndQuarters = selectedEndDate.clone().subtract(Math.ceil(totalEndDay / 90), 'quarters').endOf('quarter');
      
      comparisonText = `So sánh từ ${modifiedStartQuarters.format('DD.MM.YYYY')} - ${modifiedEndQuarters.format('DD.MM.YYYY')}`;
    } else if (isMoment === 'year') {
      const modifiedStartYears = selectedStartDate.clone().subtract(Math.ceil(daysDifferenceEnd / 365), 'years').startOf('year');
      const modifiedEndYears = selectedEndDate.clone().subtract(Math.ceil(daysDifferenceEnd / 365), 'years').endOf('year');

      comparisonText = `So sánh từ ${modifiedStartYears.format('DD.MM.YYYY')} - ${modifiedEndYears.format('DD.MM.YYYY')}`;
    } else {
      if (daysDifferenceStart === 1) {
        comparisonText = 'So sánh với ngày hôm trước';
      } else if (daysDifferenceStart === daysDifferenceEnd) {
        comparisonText = `So sánh với ${daysDifferenceStart} ngày trước`;
      }
      else if (daysDifferenceStart > daysDifferenceEnd) {
        comparisonText = `So sánh từ ${modifiedStartDate.format('DD.MM.YYYY')} - ${modifiedEndDate.format('DD.MM.YYYY')}`;
      } else {
        comparisonText = `So sánh từ ${today.clone().subtract(daysDifferenceStart - 1, 'days').format('DD.MM.YYYY')} - ${today.clone().subtract(1, 'days').format('DD.MM.YYYY')}`;
      }
    }
  }

  /**
   * On change date range picker
   * @param values 
   */
  const onChangeDateRangePicker = (values: Dayjs[]) => {
    const [start, end] = getRangeDate('day', moment().unix());
    const dateStart = first(values)?.format(TIME_START_FORMAT_RESPONSE) ?? start;
    const dateEnd = last(values)?.format(TIME_START_FORMAT_RESPONSE) ?? end;
    setOptionDate({ dateStart, dateEnd });
    dispatch(analyticActions.getAnalytics.fetch({
      start_date: moment(dateStart).format('YYYY-MM-DD 00:00:00'),
      end_date: moment(dateEnd).format('YYYY-MM-DD 23:59:59'),
      merchant_location_id: locationSelect
    }));
  };

  const cancelledBooking = analyticSelectors.bookingCancel();
  const numberOfBooking = analyticSelectors.booking();
  const getNewCustomer = analyticSelectors.getNewCustomer();
  // const getVoucher = analyticSelectors.getVoucher();
  const revenueService = analyticSelectors.getRevenueService() ?? [];
  // const promotion = analyticSelectors.getPromotion();
  const totalDiscount = analyticSelectors.getTotalDiscount();
  const getAverageSpendCustomer = analyticSelectors.getAverageSpendCustomer();
  const totalSale = analyticSelectors.getTotalSale();


  const cancelledProps: MyDivProps = {
    style: {
      '--p': cancelledBooking.booking_cancel * 100 / cancelledBooking.total,
    },
  };

  const bookingProps: MyDivProps = {
    style: {
      '--p': numberOfBooking.booking_offline * 100 / (numberOfBooking.booking_online + numberOfBooking.booking_offline),
    },
  };

  /**
   * On click export
   * @returns 
   */
  const onClickExport = async () => {
    setLoadingPage(true);

    try {
      const res = await apisAnalytic.exportAnalytic({
        start_date: optionDate.dateStart,
        end_date: optionDate.dateEnd
      });
      const file_export_url = get(res, ['data', 'data', 'file_export'], '');
      if (!file_export_url) return;
      downloadFile(file_export_url);
    } catch (error) {
      const errorText = get(error, 'response.data.error.message', '');
      if (errorText) {
        message.error(errorText);
        return;
      }
      const errors = Object.entries(get(error, 'response.data.errors', {}));
      if (errors.length > 0) {
        errors.map(([, valueArr]) => {
          message.error(get(valueArr, [0], ''));
        });
        return;
      }
      message.error('An error occurred. Please try again');
    } finally {
      setLoadingPage(false);
    }
  };

  const dataCardSummary = [
    {
      ...totalSale,
      icon: <IconCustomerCircle />,
      money: true
    },
    {
      ...getNewCustomer,
      icon: <IconDiscountCircle />,

    },
    {
      ...getAverageSpendCustomer,
      icon: <IconVoucherCircle />,
      money: true
    },
    {
      ...totalDiscount,
      icon: <IconStarCircle />,
      money: true
    }
  ];
  const { t: analyticLang } = useTranslation('analytic');

  /**
   * Card summary
   * @param values 
   * @returns 
   */
  const CardSummary = (values: ISummary) => {
    // const dateComparisonCondition = optionDate.dateStart === optionDate.dateEnd;
    return (
      <li className={`${styles.overview_item}`}>
        <div className={styles.card_info}>
          <p className={styles.info_text}>{values.content === 'Total Sales' ? analyticLang('TotalSales') : values.content === 'Total Customers' ? analyticLang('TotalCustomers') : values.content === 'Avg Customer Spent' ? analyticLang('AvgCustomerSpent') : analyticLang('TotalDiscount')}</p>
          <p className={styles.info_title}>
            {values.money ? formatMoney(values.total_amount) : values.total_amount}
            {Number(values.percent) !== 0 ?
              <span className={`common-growth ${values.percent < 0 ? 'is-reduce' : values.percent === 0 ? 'no-reduce' : ''}`}>
                {values.percent} %
              </span> : null
            }
          </p>
        </div>
        {/* <div>
          {values.icon}
        </div> */}
      </li>
    );
  };


  return (
    <>
      <div className={'private-filter'}>
        <CompareDatesStyled>{comparisonText}</CompareDatesStyled>

        {/* <button className='common-btn-export'>Export</button> */}
        <GroupFilterDateStyled>
          <GroupFilterDate onChangeOptionPicker={onChangeOptionPicker} onChangeDateRangePicker={onChangeDateRangePicker} optionDate={{
            dateEnd: moment(optionDate.dateEnd).unix(),
            dateStart: moment(optionDate.dateStart).unix()
          }} />

        </GroupFilterDateStyled>
        
        <Select
          options={locationOption}
          className='is-normal'
          onChange={onChangeLocation}
          value={locationSelect}
        />
        <ExportStyled>
          <Can module={ModulePermission.ANALYTICS} permission={AnalyticPermission.EXPORT_ANALYTIC}>
            <button className='common-btn-export' onClick={onClickExport}>
              <span>{analyticLang('Export')}</span>
            </button>
          </Can>
        </ExportStyled>
        {/* <div className='common-select'>
          <OptionPicker onChange={onChangeOptionPicker} />
        </div>
        <div className='common-select'>
          <DateRangePicker onChange={onChangeDateRangePicker} start_date={optionDate.dateStart} end_date={optionDate.dateEnd}/>
        </div> */}
      </div >
      <section className={styles.section_analytics}>
        <div className={styles.analytics_left}>
          <div className={styles.left_inner}>
            <ul className={styles.analytics_overview}>
              {dataCardSummary.map((o, index) => (
                <CardSummary key={index} {...o} />
              ))}
            </ul>
            <div className={`${styles.analytics_card}`}>
              <div className={styles.analytics_label}>{analyticLang('SalesFigures')}</div>
              <div style={{ maxWidth: '100%' }}>
                <ChartSales />
              </div>
            </div>
            <div className={`${styles.analytics_card}`}>
              <div className={styles.analytics_label}>{analyticLang('CategoryOfSales')}</div>
              <SalesTable />
            </div>
            <div className={`${styles.analytics_card}`}>
              <div className={styles.analytics_label}>{analyticLang('WorkingHours')}</div>
              <WorkingTable />
            </div>
          </div>
        </div>
        <div className={styles.analytics_right}>
          <div
            className={`${styles.analytics_services} ${styles.analytics_card}`}
          >
            <div className={styles.analytics_label}>{analyticLang('Services')}</div>
            <ChartServices revenueServices={revenueService} />
          </div>
          <div
            className={`${styles.analytics_bookings} ${styles.analytics_card}`}
          >
            <div className={styles.analytics_label}>{analyticLang('NumberOfBookings')}</div>
            <div className='pie-wrapper'>
              <div className='pie animate' {...bookingProps}></div>
              <div className='pie-content'>
                <span>{analyticLang('Total')}</span>
                <p>{(numberOfBooking?.booking_online ?? 0) + (numberOfBooking?.booking_offline ?? 0)}</p>
              </div>
            </div>
            <ul className={styles.pie_detail}>
              <li>
                <span>{analyticLang('OnlineBooking')}</span>
                <span>{numberOfBooking?.booking_online ?? 0}</span>
              </li>
              <li>
                <span>{analyticLang('WalkInCustomer')}</span>
                <span>{numberOfBooking?.booking_offline ?? 0}</span>
              </li>
            </ul>
          </div>
          <div
            className={`${styles.analytics_cancel} ${styles.analytics_card}`}
          >
            <div className={styles.analytics_label}>{analyticLang('CancelledBookings')}</div>
            <div className='pie-wrapper is-red'>
              <div className='pie animate' {...cancelledProps}></div>
              <div className='pie-content'>
                <span>{analyticLang('Total')}</span>
                <p>{(cancelledBooking?.booking_cancel ?? 0) + (cancelledBooking?.booking_no_show ?? 0) + (cancelledBooking?.booking_refund ?? 0)}</p>
              </div>
            </div>
            <ul className={`${styles.pie_detail} ${styles.is_red}`}>
              <li>
                <span>{analyticLang('Cancel&Refund')}</span>
                <span>{(cancelledBooking?.booking_cancel ?? 0) + (cancelledBooking?.booking_refund ?? 0)}</span>
              </li>
              <li>
                <span>{analyticLang('NoShow')}</span>
                <span>{cancelledBooking?.booking_no_show ?? 0}</span>
              </li>
            </ul>
          </div>
        </div>
      </section>
    </>
  );
};

export default AnalyticsPage;

const GroupFilterDateStyled = styled.div`
display: flex;
gap: 10px;
@media only screen and (max-width: 767.98px) {
  gap: 4px;
}
.ant-select-selector {
  @media only screen and (max-width: 767.98px) {
    min-width: 120px !important;
    width: 120px !important;
    padding-inline-end: 25px !important;
  }
}
`;

const CompareDatesStyled = styled.div`
  font-variant-numeric: slashed-zero;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: var(--color-primary);
  display: flex;
  justify-content: center;
  align-items: center;
`;


const ExportStyled = styled.div`
@media only screen and (max-width: 767.98px) {
  display: none;
}
`;