
import { memo, useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import Table from './Table';
import { useAppDispatch } from 'store/hooks';
import bookingActions from 'features/bookings/services/actions';
import moment from 'moment';
import useCalendarFilter from '../BookingFilter/useCalendarFilter';
import bookingSelectors from 'features/bookings/services/selectors';
import useDetachScreen from 'hooks/useDetachScreen';
import TableMobile from './TableMobile';

/**
 * Render Booking Calendar
 * @returns 
 */
const BookingCalendar = () => {

  const dispatch = useAppDispatch();
  const schema = useCalendarFilter();
  const [currentTime] = useState<string>(moment().format());
  const calendarBookingRef = useRef<HTMLDivElement>(null);
  const pixelPerMinute = bookingSelectors.getPixelPerMinute();
  const bookDate = bookingSelectors.getCalendarParamValue('date');
  const activeLocation = bookingSelectors.getCalendarCurrentLocation();

  const workingHour = {
    open: schema.timeHourStart,
    close: schema.timeHourEnd,
  };

  const _workingHour = useMemo(() => {
    const open = moment().set({ hour: workingHour.open, minute: 0, second: 0 });
    const close = moment().set({
      hour: workingHour.close,
      minute: 0,
      second: 0,
    });
    return {
      open,
      close,
    };
  }, [workingHour]);

  useEffect(() => {
    dispatch(bookingActions.getParamOptionCalendar.fetch());
  }, []);

  useEffect(() => {
      const { open } = _workingHour;
      const sunIndex = 6;

      const currDay = moment(bookDate).day() > 0 ? moment(bookDate).day() - 1 : sunIndex;
      
      const timeBooking = activeLocation?.time_opening.find((o, index) => index === currDay );

      const currentSpaceMinutes = moment(bookDate).isSame(moment(), 'day') ? 
        moment(currentTime).diff(open, 'minutes') : 
        moment().set({
          hour: (timeBooking?.start ?? 0) + (timeBooking?.startMinute ?? 0 > 0 ? 1 : 0)
        }).diff(moment().set({
          hour: 0
        }), 'minute');
    
      setTimeout(() => calendarBookingRef.current?.scrollTo({ top: currentSpaceMinutes * pixelPerMinute, behavior: 'smooth' }), 0);
  }, [bookDate, activeLocation?.time_opening]);

  const {isMobile} = useDetachScreen();

  if( isMobile ) return (
    <BookingCalendarStyled>
      <TableMobile calendarBookingRef={calendarBookingRef} />
    </BookingCalendarStyled>
  );

  return (
    <BookingCalendarStyled>
      <Table calendarBookingRef={calendarBookingRef} />
    </BookingCalendarStyled>
  );
};

export default memo(BookingCalendar);

const BookingCalendarStyled = styled.div`
.container-table {
  position: relative;
  .loading-overLay {
      background-color: rgba(255,255,255,0.5);
      position: absolute;
      inset:0;
      display: flex;
      align-items: center;
      justify-content:center;
      z-index:99999;
  }
}
`;
