import React, { useEffect, useState } from 'react';
import { Provider } from 'react-redux';
import { Route, Routes, useResolvedPath } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import store from 'store';
import './App.scss';

import NotFoundPage from 'features/NotFoundPage';
import AnalyticsPage_v2 from 'features/analytics_v2/pages/AnalyticsPage';
import AnalyticsPage from 'features/analytics/pages/AnalyticsPage';
import BookingForm from 'features/bookings/pages/BookingForm';
import EditBookingPage from 'features/bookings/pages/EditBookingPage';
import QuickBookingPage from 'features/bookings/pages/QuickBookingPage';
import CheckoutPage from 'features/checkout/pages/CheckoutPage';
import EditProfilePage from 'features/editProfile/pages/EditProfilePage';
import LoyaltyProgramPage from 'features/loyaltyProgram/pages/LoyaltyProgramPage';
import NotificationPage from 'features/notification/pages/NotificationPage';
import RolesAndDecentralizationPage from 'features/RolesAndDecentralization/pages/RolesAndDecentralizationPage';
import PromotionsPage from 'features/promotions/pages/PromotionsPage';
import PromotionsPage_v2 from 'features/promotions_v2/pages/PromotionsPage';
import ReviewsPage from 'features/reviews/pages/ReviewsPage';
import VouchersPage_v2 from 'features/vouchers_v2/pages/VouchersPage';
import VouchersPage from 'features/vouchers/pages/VouchersPage';
import useRefreshToken from 'hooks/useRefreshToken';
import { ToastContainer } from 'react-toastify';
import LoadingPage from 'services/UI/LoadingPage';
import BaseLayout from './components/layout/BaseLayout';
import SignInPage from './features/auth/pages/SignInPage';
import SignUpPage from './features/auth/pages/SignUpPage';
import SignUpPage_v2 from './features/auth/pages/SignUpPage_v2';
import BookingsPage from './features/bookings/pages/BookingsPage';
import CustomersDetailPage from './features/customers/pages/CustomersDetailPage';
import CustomersDetailPage_v2 from './features/customers_v2/pages/CustomersDetailPage_v2';
import CustomersPage from './features/customers/pages/CustomersPage';
import CustomersPage_v2 from './features/customers_v2/pages/CustomersPage';
import HomePage from './features/home/pages/HomePage';
import InvoicesDetailPage from './features/invoices/pages/InvoicesDetailPage';
import InvoicesPage from './features/invoices/pages/InvoicesPage';
// import LocationsAddNewPage from './features/locations/pages/LocationsAddNewPage';
// import LocationsPage from './features/locations/pages/LocationsPage';
import LocationsPage_v2 from './features/locations_v2/pages/LocationsPage_v2';
import ServicesPage from './features/services/pages/ServicesPage';
import BrandsPage from './features/Products/pages/BrandPage';
import UsersPage from './features/users/pages/UsersPage';
import UsersPage_v2 from './features/users_v2/pages/UsersPage';
import ForCustomerPage from 'features/reviews/pages/ForCustomer';
import RefundPage from 'features/refund/page/RefundPage';
import CheckInPage from 'features/checkin/pages/CheckInPage';
import UnsubcribedEmail from 'features/UnsubcribedEmail';

// import './assets/i18n/i18n';
import { useTranslation } from 'react-i18next';
import CategoriesPase from 'features/services/pages/CategoriesPage';
import CategoryPage from 'features/Products/pages/CategoryPage';
import SupplierPage from 'features/Products/pages/SupplierPage';
import ListProductPage from 'features/Products/pages/ListProductPage';
import AddProductTable from 'features/Products/Components/ProductFrom/AddProductTable';
import RolesDetail from 'features/RolesAndDecentralization/components/RolesDetail';
import SalesPage from 'features/sales/pages/SalesPage';
import ResetPasswordPage from 'features/auth/pages/ResetPasswordPage';
import TemporaryClosedPage from 'features/temporaryClosedPage/pages/TemporaryClosedPage';
// import CategoriesPage from 'features/services/pages/CategoriesPage';
import WorkingHourPage from 'features/workingHour/pages/workingHourPage';
import UserCheckInPage from 'features/users/pages/UserCheckIn';
import WorkingHourDetail from 'features/workingHour/pages/workingHourDetail';
// import moment from 'moment-timezone';
// import 'moment-timezone';
import TimeSchedulePage from 'features/timeSchedule/pages/TimeSchedulePage';
import PaymentPage from 'features/payment/page';
import { IMenuConfig } from 'constants/path.enum';
import SupplierEditPage from 'features/Products/Components/SupplierFrom/SupplierEditPage';
import PaymentMethodspage from 'features/settings/pages/PaymentMethodspage';
import RequireAuth from 'features/packagePermission/components/RequireAuth';
import { AnalyticPermission, CustomerPermission, ModulePermission, RewardPermission } from 'features/packagePermission/services/types/permission';
import PermissionDeniedPage from 'features/NotFoundPage/403';
import AddLocationPage from 'features/locations_v2/pages/AddLoactionPage';
import LocationsEditPage from 'features/locations_v2/pages/widgets/LocationsEditPage';
import BookingsTab from 'features/settings/components/BookingsTab';
import CustomerLinksTab from 'features/settings/components/CustomerLinksTab';
import GeneralTab from 'features/settings/components/GeneralTab';
import ServiceChargesTab from 'features/settings/components/ServiceChargesTab';
import ClosedPeriodsTab from 'features/settings/components/ClosedPeriodsTab';
import NotificationTab from 'features/settings/components/NotificationTab';
import CancellationsReasonsTab from 'features/settings/components/CancellationsReasonsTab';
import AutoMessageTab from 'features/settings/components/ AutoMessagesTab';
import LocationsPage from './features/locations_v2/pages/LocationsPage_v2';
import PermissionSettingPage from 'features/settings/pages/PermissionSettingPage';
import UserSignInPage from 'features/auth/pages/UserSignInPage';
import DashboardsPage from 'features/analytics/pages/DashboardsPage';



const CalendarBooking = React.lazy(
  () => import('./features/bookings/components/BookingCalendar')
);

function AppUI() {
  const { loading, shield } = useRefreshToken();
  if (loading) return null;

  return (
    <React.Fragment>
      <Routes>
        <Route path='/private' element={shield(BaseLayout)}>
          <Route path='bookings' element={<BookingsPage />} />
          <Route path='bookings/quick-booking' element={<QuickBookingPage />} />
          <Route path='bookings/edit-booking' element={<EditBookingPage />} />
          <Route
            path='calendar-bookings'
            element={
              <React.Suspense>
                <CalendarBooking />
              </React.Suspense>
            }
          />
          <Route
            path='bookings/checkout/:book_assignment_id'
            element={<CheckoutPage />}
          />
          <Route path='sales' element={<SalesPage />} />
          {/* Customer Route */}
          <Route element={<RequireAuth module={ModulePermission.CUSTOMERS} permission={CustomerPermission.VIEW_CUSTOMER} />}>
            <Route path='customers' element={<CustomersPage_v2 />} />
            <Route path='customers_v1' element={<CustomersPage />} />
            <Route element={<RequireAuth module={ModulePermission.CUSTOMERS} permission={CustomerPermission.EDIT_CUSTOMER} />}>
              <Route path='customers/:customer_code' element={<CustomersDetailPage_v2 />} />
              <Route path='customers_v1/:customer_code' element={<CustomersDetailPage />} />
            </Route>
          </Route>
          <Route path='services' element={<ServicesPage />} />
          <Route path='brands' element={<BrandsPage />} />
          <Route path='services/categories' element={<CategoriesPase />} />
          <Route path='product-category' element={<CategoryPage />} />
          <Route path='supplier' element={<SupplierPage />} />
          <Route path='supplier/:name' element={<SupplierEditPage />} />
          <Route path='list-product' element={<ListProductPage />} />
          <Route path='list-product/:name' element={<AddProductTable />} />
          <Route path='invoices' element={<InvoicesPage />} />
          <Route path='invoices/:invoice_no' element={<InvoicesDetailPage />} />
          <Route path='reviews' element={<ReviewsPage />} />
          <Route path='promotions' element={<PromotionsPage />} />
          <Route path='promotions_v2' element={<PromotionsPage_v2 />} />
          <Route path='vouchers_v2' element={<VouchersPage />} />
          <Route path='vouchers' element={<VouchersPage_v2 />} />
          <Route path='locations_v2' element={<LocationsPage />} />
          <Route path='locations' element={<LocationsPage_v2 />} />
          <Route path='locations/add-new' element={<AddLocationPage />} />
          <Route path='locations/edit/:id' element={<AddLocationPage />} />
          {/* <Route path='locations/add-new' element={<LocationsAddNewPage />} /> */}
          <Route path='locations_v2/add-new' element={<LocationsEditPage />} />
          <Route path='locations_v2/edit/:id' element={<LocationsEditPage />} />
          <Route element={<RequireAuth module={ModulePermission.REWARDS} permission={RewardPermission.VIEW_REWARD} />}>
            <Route path='loyalty-program' element={<LoyaltyProgramPage />} />
          </Route>
          <Route path='users' element={<UsersPage />} />
          <Route path='users_v2' element={<UsersPage_v2 />} />
          {/* <Route path='settings' element={<SettingsPage />} /> */}

          <Route path='settings'>
            <Route path='booking' element={<BookingsTab />} />
            <Route path='customer-link' element={<CustomerLinksTab />} />
            <Route path='general' element={<GeneralTab />} />
            <Route path='service-charge' element={<ServiceChargesTab />} />
            <Route path='closed-period' element={<ClosedPeriodsTab />} />
            <Route path='notification' element={<NotificationTab />} />
            <Route path='cancellation' element={<CancellationsReasonsTab />} />
            <Route path='auto-message' element={<AutoMessageTab />} />
            <Route path='role' element={<PermissionSettingPage />} />
          </Route>

          <Route path='payment-methods' element={<PaymentMethodspage />} />
          <Route path='edit-profile' element={<EditProfilePage />} />
          <Route element={<RequireAuth module={ModulePermission.ANALYTICS} permission={AnalyticPermission.VIEW_ANALYTIC} />}>
          <Route path='analytics' element={<AnalyticsPage />} />‘
            <Route path='dashboards' element={<DashboardsPage />} />
            <Route path='dashboards_v2' element={<AnalyticsPage_v2 />} />
          </Route>
          <Route path='statistical/overview' element={<AnalyticsPage />} />
          <Route path='notification' element={<NotificationPage />} />
          <Route path='RolesDetail' element={<RolesAndDecentralizationPage />} />
          <Route path='RolesDetail/:name' element={<RolesDetail />} />
          <Route path='working-hour' element={<WorkingHourPage />} />
          <Route path='working-hour/detail' element={<WorkingHourDetail />} />
          <Route path='time-schedule' element={<TimeSchedulePage />} />
        </Route>
        <Route path='/' element={<HomePage />} />
        <Route path='/sign-in' element={<SignInPage />} />
        <Route path='/forgot-password' element={<SignInPage />} />
        <Route path='/:merchantCode/sign-in' element={<UserSignInPage />} />
        <Route path='/reset-password' element={<ResetPasswordPage />} />
        <Route path='/sign-up' element={<SignUpPage />} />
        <Route path='/sign-up_v2' element={<SignUpPage_v2 />} />
        <Route
          path='/:store_name/booking-form/:merchant_code'
          element={<BookingForm />}
        />
        <Route
          path='/:store_name/check-in/:merchant_code'
          element={<BookingForm isCheckIn />}
        />
        <Route
          path='/:store_name/checkin/:merchant_code/:location_id'
          element={<BookingForm isCheckIn />}
        />
        <Route
          path='/check-in'
          element={<CheckInPage />}
        />
        <Route
          path='/staff-check-in'
          element={shield(UserCheckInPage)}
        />
        <Route
          path='/:store_name/reviews/:merchant_code/:customer_code/:invoice_code'
          element={<ForCustomerPage />}
        />
        <Route path='/refund' element={<RefundPage />} />
        <Route
          path='booking-form/:merchant_code'
          element={<BookingForm />}
        />
        <Route path='unsubcribed-email' element={<UnsubcribedEmail/>}/>
        <Route path='*' element={<NotFoundPage />} />
        <Route path='/403' element={<PermissionDeniedPage />} />
        <Route path='temporary' element={<TemporaryClosedPage />} />
        <Route path='/create-intent' element={<PaymentPage />} />
      </Routes>
    </React.Fragment>
  );
}
const App = () => {
  type BreadcrumbFunc = (pathName: string) => string[];
  type MenuConfigType = {
    path: string;
    breadcrumbs: string[] | BreadcrumbFunc;
  };

  const getListPaths = (pathname: string) => pathname?.split('/') ?? [];
  // @ts-ignore
  const pathname = useResolvedPath().pathname;
  const [breadcrumbsList, setBreadcrumbsList] = useState<string[]>([]);

  const findingBreadcrumbItem = (item: MenuConfigType) => {
    if (item.path === pathname) return true;
    if (
      pathname?.includes(item.path) &&
      getListPaths(pathname).length === getListPaths(item.path).length
    )
      return true;

    return false;
  };
  const getBreadcrumbs = (
    breadcrumbs: MenuConfigType['breadcrumbs']
  ): string[] => {
    return typeof breadcrumbs === 'function'
      ? breadcrumbs(pathname?.replace('/private/', ''))
      : breadcrumbs;
  };
  useEffect(() => {
    if (pathname !== '') {
      const currentBreadcrumbs = IMenuConfig.find(findingBreadcrumbItem);

      if (currentBreadcrumbs) {
        const breadcrumbs: string[] = getBreadcrumbs(
          currentBreadcrumbs.breadcrumbs
        );
        setBreadcrumbsList(breadcrumbs);
      }
    }
  }, [pathname]);

  const { t, i18n } = useTranslation();
  // moment.tz.setDefault('Australia/Sydney'); 

  const changeLanguage = (languageValue: any) => {
    i18n.changeLanguage(languageValue);
    document.title = t('MindHana - Giải pháp tăng trưởng kinh doanh Spa, Dental, Salon');
    localStorage.setItem('selectedLanguage', languageValue);
  };

  useEffect(() => {
    const storedLanguage = localStorage.getItem('selectedLanguage');
    const defaultLanguage = 'vi';
    const selectedLanguage = storedLanguage || defaultLanguage;

    if (selectedLanguage !== i18n.language) {
      changeLanguage(selectedLanguage);
    }
  }, []);
  const {t: commonLang} = useTranslation('common');
  useEffect(() => {
    if (document.title === undefined) {
      document.title = 'MindHana';
      return;
    }

    if (breadcrumbsList[2]) {
      document.title = commonLang(breadcrumbsList[0]) + ' > ' + commonLang(breadcrumbsList[1]) + ' > ' + commonLang(breadcrumbsList[2]);
      return;
    }
    if (breadcrumbsList[1]) {
      document.title = commonLang(breadcrumbsList[0]) + ' > ' + commonLang(breadcrumbsList[1]);
      return;
    }
    
    document.title = commonLang(breadcrumbsList[0]);
  }, [breadcrumbsList, commonLang]);

  return (
    <Provider store={store}>
      <AppUI />
      <LoadingPage />
      <ToastContainer />
    </Provider>
  );
};
export default App;