import React, { memo } from 'react';
type Props = {
  label: string;
};

/**
 * Header calendar
 * @param label 
 * @returns 
 */
const HeaderCalendar: React.FC<Props> = ({ label = '' }) => {
  return (
    <th>
      <p>{label}</p>
      <p className='subtext'>Mon</p>
    </th>
  );
};

export default memo(HeaderCalendar);