import checkoutSelectors from 'features/checkout/services/selectors';
import { useTranslation } from 'react-i18next';
import { formatMoney } from 'utils/unit';
// import formatNumber from 'utils/formatNumber';

/**
 * Sub total amount
 * @returns 
 */
const SubTotalAmount = () => {
  const result = Number(checkoutSelectors.getSubTotal());
  const {t: bookingLang} = useTranslation('booking');
  if (!result) return null;
  
  return (
    <p className='extra-price-row'>
      {bookingLang('Subtotal')}
      <span>{formatMoney(result ?? 0)}</span>
    </p>
  );
};

export default SubTotalAmount;
