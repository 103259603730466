import React from 'react';

const IconEditSetting = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
            <path d="M14.1667 2.5009C14.3856 2.28203 14.6455 2.10842 14.9314 1.98996C15.2174 1.87151 15.5239 1.81055 15.8334 1.81055C16.1429 1.81055 16.4494 1.87151 16.7354 1.98996C17.0214 2.10842 17.2812 2.28203 17.5001 2.5009C17.719 2.71977 17.8926 2.97961 18.011 3.26558C18.1295 3.55154 18.1904 3.85804 18.1904 4.16757C18.1904 4.4771 18.1295 4.7836 18.011 5.06956C17.8926 5.35553 17.719 5.61537 17.5001 5.83424L6.25008 17.0842L1.66675 18.3342L2.91675 13.7509L14.1667 2.5009Z" stroke="#141414" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    );
};

export default IconEditSetting;