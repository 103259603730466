import { Checkbox, Form, FormInstance } from 'antd';
import dayjs from 'utils/dayjs';
import { get } from 'lodash';
import TimePickerHourElement from './TimePickerHourElement';
import styled from 'styled-components';
import { WorkTimeStatus } from 'features/users/widgets/ModalUserForm/FouthStep';

interface ActiveHoursElementProps {
  label?: string;
  name: string;
  form?: FormInstance;
  timeWorking?: {time_start: string, time_end: string}[]
  status?: WorkTimeStatus;
  addUserPage?: boolean
}

const ActiveHoursElement = ({
  // onChange,
  label,
  name,
  form,
  timeWorking,
  status,
  addUserPage
}: ActiveHoursElementProps) => {
  return (
    <ActiveHoursElementStyled>
      <div className={`calendar-row ${status === WorkTimeStatus.UNAVAILABLE && addUserPage ? 'calendar-row-disabled' : ''}`}>
        <p className='calendar-label'>
          {status === WorkTimeStatus.UNAVAILABLE && addUserPage ? (
            <div className='calendar-row-disabled-item'>
              <div className='checkbox-disabled' />
              <div className='checkbox-disabled-label'>{label}</div>
            </div>
          ) : (
            <Form.Item
              shouldUpdate={(prevValues, curValues) =>
                prevValues.additional !== curValues.additional
              }
            >
              {() => (
                <Checkbox
                  onChange={(val) => {
                    const workingTime =
                      form?.getFieldValue('workingTime') ?? [];
                      
                    if (!workingTime) return;
                    if (val.target.checked) {
                      form?.setFieldValue('workingTime', {
                        ...workingTime,
                        [name]: [
                          {
                            time_start: dayjs(
                              addUserPage ? timeWorking?.[0]?.time_start : '09:00:00',
                              'H:m:s'
                            ),
                            time_end: dayjs(addUserPage ? timeWorking?.[0]?.time_end : '18:00:00', 'H:m:s'),
                            weekday: name,
                          },
                        ],
                      });
                    } else {
                      form?.setFieldValue('workingTime', {
                        ...workingTime,
                        [name]: [],
                      });
                    }
                  }}
                  checked={
                    get(
                      form?.getFieldValue('workingTime'),
                      [name, 'length'],
                      0
                    ) > 0
                  }
                >
                  {label}
                </Checkbox>
              )}
            </Form.Item>
          )}
        </p>
        <div className='calendar-pick'>
          <ul className='list-pick'>
            {status === WorkTimeStatus.UNAVAILABLE && addUserPage ? (
              <div className='text-unavailable'>Unavailable</div>
            ) : (
              <Form.List name={'workingTime'}>
                {() => (
                  <>
                    <Form.List name={name}>
                      {(fields, { add, remove }) => (
                        <>
                          {fields.map((field, index) => (
                            <>
                              <TimePickerHourElement
                                weekday={name}
                                key={index}
                                remove={remove}
                                field={field}
                                index={index}
                                timeWorking={timeWorking}
                                addUserPage={addUserPage}
                              />
                            </>
                          ))}
                          {fields.length < 3 && (
                            <span
                              className='calendar-add'
                              onClick={() => add()}
                            ></span>
                          )}
                        </>
                      )}
                    </Form.List>
                  </>
                )}
              </Form.List>
            )}
          </ul>
        </div>
      </div>
    </ActiveHoursElementStyled>
  );
};

const ActiveHoursElementStyled = styled.div`
  .text-unavailable {
    color: #c3c2e0;
    font-size: 16px;
    font-style: italic;
    font-weight: 400;
  }

  .checkbox-disabled {
    width: 24px;
    height: 24px;
    border-radius: 6px;
    background: rgba(195, 194, 224, 0.20);
  }

  .calendar-row-disabled {
    align-items: center;
  }

  .calendar-row-disabled-item {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .checkbox-disabled-label {
    color: #363565;
    font-size: 14px;
    font-weight: 600;
  }
`;

export default ActiveHoursElement;
