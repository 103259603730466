import { Avatar, Empty, Rate } from 'antd';
import styles from '../pages/index.module.scss';
import { useTranslation } from 'react-i18next';
import reviewSelectors from 'features/reviews/services/selectors';
import moment from 'moment';
import styled from 'styled-components';
import { getFormatShortDate } from 'utils/moment/getSingleDateShortFormat';
import getSingleDateFormat from 'utils/moment/getSingleDateFormat';

const ReviewsTab = () => {
  const { t: customerLang } = useTranslation('customer');
  const {t: dateLang} = useTranslation('date');
  const review = reviewSelectors.getListReview()?.data;

  return (
    <div className="cards-wrapper">
      <div className="cards-col">
        <div className="common-card">
          <h2 className="card-title">{customerLang('ReviewsTitle')}</h2>
          <div className="card-body">
            <ul className={styles.reviews_list} >
              {review?.length === 0 ?
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={customerLang('NoReview')} /> :
                review?.map((o, index) => (
                  <ReviewItemStyled key={index}>
                    <div>
                      <Avatar className='avatar'
                        size={50}
                      >
                        {o?.customer?.name ? o?.customer?.name[0].toUpperCase() : ''}
                      </Avatar>
                    </div>
                    <div className={'review_detail'}>
                      <ul className={styles.list_items}>
                        <li className="d-flex">
                          <div className="flex-1">
                            <p className='text-title'>Review added</p>
                            <p className={'text-time'}>
                              {getFormatShortDate(o.review_date, dateLang)} - with {o.customer.name}
                            </p>
                          </div>
                          <Rate value={o.rating} disabled />
                        </li>
                        <li>
                          <p className='text-title'>Booking</p>
                          <p className={'text-time'}>
                            {getSingleDateFormat(moment(o.review_date))}
                          </p>

                        </li>
                        <li>
                          <p>
                            {o.content}
                          </p>
                        </li>
                      </ul>
                    </div>
                  </ReviewItemStyled>
                ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReviewsTab;

const ReviewItemStyled = styled.li`
  
  .avatar{
    margin-right: 24px;
    background: linear-gradient(315deg, #6587FF 0%, #C883FF 100%);
    display: flex;
    align-items: center;
    .ant-avatar-string {
      font-weight: 800;
      font-size: 30px;
      display: flex;
      line-height: 1;
    }
  }

  .review_detail {
    flex: 1 1;
    font-weight: 500;
  }

  .text-title {
    font-size: 18px;
    font-weight: 600;
  }

  .text-time {
    color: var(--grayish-navy-50, #949BDA);
    /* font-family: 'Inter'; */
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
  }
`;