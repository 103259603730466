import { createAction, createAsyncAction } from 'services/actionConfigs';
import { IApiListVoucherParams } from './types/api';
import { PREFIX_ACTIONS} from './constants';

const setVoucherListParams = createAsyncAction<IApiListVoucherParams>(PREFIX_ACTIONS + 'SET_VOUCHER_LIST_PARAMS');

const getDataConfigs = createAction(PREFIX_ACTIONS + 'GET_DATA_CONFIGS');

const getLstPeriodOpts = createAsyncAction(PREFIX_ACTIONS + 'GET_LST_PERIOD_OPTIONS');
const getAllServices = createAsyncAction(PREFIX_ACTIONS + 'GET_ALL_SERVICES');
const deleteVoucher = createAction<string>(PREFIX_ACTIONS + 'DELETE_VOUCHER');
const getVoucherDetail = createAsyncAction<{voucherCode: string, type: string}>(PREFIX_ACTIONS + 'GET_VOUCHER_DETAIL');

const voucherActions = {
  setVoucherListParams,
  getDataConfigs,
  getAllServices,
  getLstPeriodOpts,
  deleteVoucher,
  getVoucherDetail
};

export default voucherActions;
