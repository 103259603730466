import React from 'react';

const IconTransferMoney = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">
            <path d="M46.4764 35.5769C44.4804 38.3825 45.9065 36.3778 39.426 45.4882C39.426 45.4882 36.5871 50.3955 31.7619 46.4841L1.7458 14.8725C1.7458 14.8725 -0.24058 11.2453 3.09409 9.2901C16.5283 3.95066 13.5397 5.13863 17.4996 3.56411C17.4996 3.56411 19.9829 2.67613 23.7433 7.22699C26.9194 11.0701 41.7538 27.4864 46.2965 32.5047C47.0697 33.359 47.1449 34.6381 46.4764 35.5769Z" fill="#52528C" />
            <path d="M44.9391 37.7384L39.426 45.4888C39.426 45.4888 36.5871 50.3961 31.7619 46.4847L1.7458 14.8731C1.7458 14.8731 -0.24058 11.2458 3.09409 9.29068L14.5186 4.75C18.3968 12.5768 28.4567 29.8341 44.9391 37.7384Z" fill="#404077" />
            <path d="M44.2015 35.1053C42.943 36.8745 37.6871 44.2635 37.6805 44.2753C37.0821 45.2661 35.6972 45.3964 34.9169 44.5421L5.9036 12.7614C5.08365 11.8622 5.41488 10.4172 6.54574 9.96816L8.03629 9.37652L8.90907 10.1209C9.63555 10.7411 10.6577 10.8806 11.5234 10.4783L15.3488 8.70239C16.3924 8.21737 16.9671 7.06535 16.7062 5.9285L17.4805 5.62097C17.4805 5.62097 19.7696 4.80326 23.2354 8.99872C26.1667 12.5445 39.8732 27.7123 44.039 32.3141C44.7411 33.0901 44.8091 34.2521 44.2015 35.1053Z" fill="#BAEDFE" />
            <path d="M43.0229 36.7616C37.5397 44.4709 37.6879 44.2615 37.6805 44.2748C37.0821 45.2656 35.6972 45.3959 34.9169 44.5416L5.9036 12.7609C5.08365 11.8617 5.41488 10.4167 6.54574 9.96766L8.03629 9.37601L8.90907 10.1204C9.63555 10.7406 10.6577 10.8801 11.5234 10.4778L15.3488 8.70189C15.6993 8.53896 15.995 8.30169 16.2246 8.01758C20.7471 16.2782 29.6334 29.5448 43.0229 36.7616Z" fill="#9AE1FF" />
            <path d="M15.7681 42.3706C13.3656 47.4072 7.64076 49.5973 3.61923 46.7148C0.48472 44.4633 -0.483574 39.7332 1.51601 35.5408C3.51763 31.3473 7.79926 29.1325 11.516 30.1661C16.3022 31.5 18.1615 37.3554 15.7681 42.3706Z" fill="#EDD15A" />
            <path d="M13.6182 41.3401C11.6176 45.5335 7.33492 47.7483 3.61923 46.7148C0.48472 44.4633 -0.483574 39.7332 1.51601 35.5408C3.51763 31.3473 7.79926 29.1325 11.516 30.1661C14.6505 32.4167 15.6188 37.1478 13.6182 41.3401Z" fill="#FAE26B" />
            <path d="M10.8553 40.3274C12.368 37.1545 11.7774 33.7123 9.53615 32.639C7.29489 31.5657 4.2517 33.2677 2.739 36.4406C1.22629 39.6134 1.8169 43.0556 4.05816 44.129C6.29942 45.2023 9.34261 43.5003 10.8553 40.3274Z" fill="#EDD15A" />
            <path d="M42.8664 28.5044C42.8694 28.8618 42.7973 29.2152 42.654 29.5431C40.9308 33.4687 37.4996 37.0206 34.8813 39.3393C33.4263 40.6272 31.1245 39.858 30.7368 37.9564C27.903 24.1093 20.7368 17.5941 17.0994 15.0972C15.9228 14.2897 15.6505 12.6543 16.506 11.5107C19.6232 7.34427 23.7718 3.47894 27.9488 0.475209C29.0176 -0.292602 30.497 -0.113378 31.3464 0.892719C42.0972 13.6165 42.84 25.8211 42.8664 28.5044Z" fill="#79E9B3" />
            <path d="M42.866 28.5044C42.8691 28.8618 42.7969 29.2152 42.6537 29.5431C40.9304 33.4687 37.4992 37.0206 34.8809 39.3393C33.7846 40.3097 32.2066 40.1132 31.3298 39.1407C34.9652 22.9871 30.201 7.85393 27.3794 0.890682C27.5785 0.743026 27.7685 0.604535 27.9484 0.475209C29.0173 -0.292602 30.4966 -0.113378 31.346 0.892719C42.0969 13.6165 42.8396 25.8211 42.866 28.5044Z" fill="#14D2AA" />
            <path d="M39.8028 28.7812C38.4627 30.826 36.7364 32.6356 35.3119 33.9573C34.5755 31.9201 31.7895 32.2059 31.7567 32.1722C29.3883 23.764 24.1506 17.8853 21.0659 15.0034C22.6814 12.9474 21.5972 10.8487 20.8413 9.81509C22.7972 7.32938 24.9086 5.42512 26.5952 4.10742C28.1392 5.27849 29.9854 5.42095 31.7598 4.23369C36.9945 11.199 39.5691 19.1317 40.636 23.2314C39.7876 24.1357 38.6333 26.0023 39.8028 28.7812Z" fill="#B4FFC6" />
            <path d="M39.8027 28.78C38.4625 30.8248 36.7362 32.6343 35.3117 33.9561C34.8403 32.6516 33.5225 32.2667 32.4139 32.1873C33.3853 21.3921 31.0992 11.5704 28.9116 5.04198C29.7417 5.1153 30.711 4.93404 31.7596 4.23242C36.9943 11.1977 39.569 19.1304 40.6358 23.2301C39.7874 24.1344 38.6332 26.001 39.8027 28.78Z" fill="#79E9B3" />
            <path d="M45.0394 15.5748C41.6205 18.3084 36.2713 16.6508 34.0031 11.8967C31.8299 7.34172 33.6374 2.39453 37.5075 1.13406C40.8706 0.0230775 44.8423 1.99454 46.6702 5.82545C48.3965 9.44353 47.6354 13.5118 45.0394 15.5748Z" fill="#EDD15A" />
            <path d="M45.0394 15.5748C41.6763 16.6848 37.7066 14.7113 35.8787 10.8814C34.1534 7.26433 34.9135 3.19717 37.5074 1.13406C40.8705 0.0230775 44.8423 1.99454 46.6702 5.82545C48.3964 9.44353 47.6354 13.5118 45.0394 15.5748Z" fill="#FAE26B" />
            <path d="M44.3934 13.4864C46.3853 12.5325 46.9103 9.47319 45.5659 6.65329C44.2214 3.83339 41.5167 2.32074 39.5248 3.27468C37.5328 4.22863 37.0078 7.28793 38.3523 10.1078C39.6967 12.9277 42.4014 14.4404 44.3934 13.4864Z" fill="#EDD15A" />
        </svg>
    );
};

export default IconTransferMoney;