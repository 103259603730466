import { Button, Form, Spin } from 'antd';
import { IconRight } from 'assets/svg';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import styles from '../../pages/index.module.scss';
import Overview from './Overview';
import Expiry from './Expiry';
import WarehouseCard from './WarehouseCard';
import { useNavigate  } from 'react-router-dom';

const AddProducttable = () => {
    const { t: productsLang } = useTranslation('products');
    const [form] = Form.useForm();
    const [step, setStep] = useState<number>(1);
    const navigate = useNavigate ();
    const onClickBack = () => {
        setStep(step - 1);
        if (step <= 1) {
            navigate(-1);
        }
    };

    const onClickRemove = () => {
        setStep(step + 1);
    };

    const onClickNext = () => {
        setStep(step + 1);
        if (step >= 3) {
            setStep(3);
        }
    };

    return (
        <Spin spinning={false}>
            <Form
                form={form}
            >
                <CheckoutPageStyled>
                    <div className='block-heading'>
                        <ul>
                            <li className={`${step >= 1 && 'is-finish'}`}>
                                {/* <span>1.</span> */}
                                {productsLang('Overview')}
                            </li>
                            <li className={`${step >= 2 && 'is-finish'}`}>
                                {/* <span>2.</span> */}
                                {productsLang('WarehouseCard')}
                            </li>
                            <li className={`${step >= 3 && 'is-finish'}`}>
                                {/* <span>3.</span> */}
                                {productsLang('Expiry')}
                            </li>
                        </ul>
                        <div className={styles.btn_beading}>
                            <button className={styles.icon_heading} onClick={onClickBack}><IconRight/></button>
                            {step === 2 ? <Button className={styles.remove_heading} onClick={onClickRemove}>Bỏ qua</Button> : ''}
                            <Button className={styles.confirm_heading} onClick={onClickNext}>Xác nhận</Button>
                        </div>
                    </div>
                    <div className='body'>
                    {step === 1 && <Overview/>}
                    {step === 2 && <WarehouseCard/>}
                    {step === 3 && <Expiry/>}
                    </div>

                </CheckoutPageStyled>
            </Form>
        </Spin>
    );
};

export default AddProducttable;

const CheckoutPageStyled = styled.div`
  padding: 0px 0px 24px;
  gap: 24px;
  max-width: 1300px;
  padding: 0 20px;
  min-height: 958px;
  background: var(--color-white);
  border-radius: 24px;
  color: var(--color-primary);
  .block-heading {
    display: flex;
    align-items: center;
    gap: 12px;
    padding: 15px 80px;
    font-weight: 600;
    font-size: 16px;
    line-height: 140%;
    text-transform: capitalize;
    border-bottom: 1px solid #C3C2E0;
    justify-content: space-between;
    ul {
      display: flex;
      gap: 80px;
    }
    li {
      display: flex;
      gap: 12px;
      position: relative;
      align-items: baseline;
      &:after {
        content: "";
        display: block;
        width: 48px;
        height: 1px;
        position: absolute;
        border-bottom: 1px dashed #C3C2E0;
        top: 50%;
        left: calc(100% + 16px);
      }
      &.is-finish {
        color: #1B9AAA;
        border-bottom: 2px solid #1B9AAA;
        &:before {
          box-sizing: border-box;
          position: absolute;
          top: 50%;
          inset-inline-start: 30%;
          display: table;
          width: 5.7142857142857135px;
          height: 9.142857142857142px;
          border: 2px solid #fff;
          border-top: 0;
          border-inline-start: 2px;
          content: "";
          opacity: 1;
          transform: rotate(45deg) scale(1) translate(-50%,-50%);
          transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
        }
      }
      &:last-child:after {
        display: none;
      }
      span {
        display: flex;
        width: 20px;
        height: 20px;
        align-items: center;
        justify-content: center;
        font-weight: 500;
        font-size: 16px;
        line-height: 140%;
        padding-bottom: 2px;
      }
    }
    
  }
  
  .is-complete {
    .list-detail-items {
      max-height: unset !important;
    }
  }
  .body {
    display: flex;
    padding: 24px 56px;
    justify-content: center;
  }
  .block {
    display: flex;
    flex-direction: column;
    max-width: 50%;
    padding: 0 24px;
    flex: 1;
    border-right: 1px solid #949BDA;
    &:first-child {
      .form-row:last-child {
        margin-top: auto;
        margin-bottom: -15px;
      }
    }
    &:last-child {
      border-right: none;
      & > div:last-child {
        margin-top: auto;
      }
    }
  }
  .block-address {
    display: flex;
    gap: 4px;
    font-weight: 500;
    font-size: 14px;
    line-height: 140%;
    color: #363565;
  }
  .block-tabs {
    margin: 24px 0 16px;
  }
  .block-title {
    font-weight: 700;
    font-size: 18px;
    margin-bottom: 24px;
  }
  .form-row {
    margin-top: 48px;
    margin-bottom: 0;
  }
  .note {
    font-size: 16px;
    text-align: center;
    margin: 16px 0 80px;
    color: var(--color-primary);
  }
  .common-input-search {
    flex: 1;
    input {
      height: 48px;
      width: 100%;
      background-color: var(--color-white-01);
    }
  }
  .detail {
    display: flex;
    padding: 24px;
    height: 365px;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    font-size: 16px;
    text-align: center;
    color: var(--color-primary);
    font-weight: 500;
  }
  .total,
  .voucher {
    display: flex;
    align-items: center;
    font-size: 14px;
    text-transform: uppercase;
    color: var(--color-primary);
    span {
      flex: 1;
      font-weight: 700;
      font-size: 20px;
      text-align: right;
      text-transform: uppercase;
      color: var(--color-green);
    }
  }
  .voucher {
    text-transform: capitalize;
    margin-bottom: 10px;
    span {
      font-weight: 600;
      font-size: 16px;
      color: var(--color-primary);
    }
  }
  .input_search {
    flex: 1;
  }
  .form-note {
    margin-bottom: 10px;
  }
  .search-wrapper {
    display: flex;
    gap: 12px;
    margin-bottom: 16px;
    &:last-child {
      margin-bottom: 0;
    }
    .icon-back {
      width: 64px;
      height: 64px;
      background: #F0F2F7;
      border-radius: 6px;
    }
  }
  .form-notifications-note {
    margin-bottom: 20px;
  }

  .current-customer {
    display: flex;
    align-items: center;
    gap: 24px;
    padding: 12px;
    transition: all 0.3s ease;
    border-radius: 6px;
    margin-bottom: 70px;

    .customer-avatar {
      width: 120px;
      height: 120px;
      border-radius: 50%;
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 64px;
      font-weight: bold;
      text-transform: uppercase;
      color: #fff;
    }

    .customer-detail {
      flex: 1;
      overflow: hidden;
      p {
        font-size: 12px;
        span {
          font-weight: bold;
        }
      }
      .detail-name {
        font-weight: 600;
        font-size: 16px;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }

    .btn-reset {
      background: #F0F2F7;
      border-radius: 6px;
    }

    .customer-link {
      display: inline-block;
      font-weight: 500;
      font-size: 16px;
      text-decoration-line: underline;
      color: var(--color-green);
      cursor: pointer;
      &.is-text {
        text-decoration-line: none;
      }
    }
  }

  .form-voucher {
    display: flex;
    gap: 4px;
    margin-bottom: 20px;
    button {
      display: flex;
      width: 112px;
      height: 64px;
      align-items: center;
      justify-content: center;
      border: 1px solid #4A9D77;
      border-radius: 6px;
      background: #fff;
      font-weight: 600;
      font-size: 16px;
      line-height: 140%;
      text-transform: capitalize;
      color: #4A9D77;
    }
  }

  .complete-order {
    display: flex;
    align-items: center;
    gap: 30px;
    margin-bottom: 30px;
    .complete-icon {

    }

    .complete-content {
      flex: 1;
    }

    .complete-title {
      font-weight: 600;
      font-size: 28px;
    }

    .complete-text {
      overflow: hidden;
      font-size: 16px;
      span {
        font-weight: 700;
      }
      .customer-name {
        text-overflow: ellipsis;
        overflow: hidden;
        width: 73%;
      }
    }
  }


  @media (max-width: 1023.98px) {
    .body {
      display: flex;
      padding: 24px 0;
    }
  }

  @media (max-width: 767.98px) {
    .block-heading {
      padding: 20px 12px;
      overflow: auto;
      li {
        min-width: max-content;
      }
    }
    .body {
      padding: 24px 12px 200px;
      flex-direction: column;
      gap: 50px;
    }
    .block {
      padding: 0;
      max-width: unset;
      border-right: none;
      &:first-child {
        .form-row:last-child {
          margin-top: 24px;
        }
      }
    }
    .block-fixed {
      position: fixed;
      bottom: 0;
      left: 0;
      width: 100%;
      background: var(--color-white);
      z-index: 101;
      padding: 12px;
    }
    .form-note {
      margin-bottom: 12px;
    }
  }

  .ant-input {
    min-width: unset;
  }

  .form-booking-submit {
    background: #363565;
    &.disabled {
      background: #949bda;
      cursor: no-drop;
    }
  }
`;