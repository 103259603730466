import { Avatar } from 'antd';
// import { PATH } from 'constants/path.enum';
import moment from 'moment';
import { useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import getSingleDateShortFormat from 'utils/moment/getSingleDateShortFormat';
import { ANONYMOUS_NAME, formatMoney, roundNumber, statusTagBackground } from 'utils/unit';
import { IAppointmentItemResData, IBookAssignmentServiceItemResData } from '../services/types/appointment';
import { IResultItem } from 'features/invoices/components/Forms/ServiceDetails';
import customerSelectors from '../services/selectors';
import ModalSendInvoiceEmail, { ICustomerSendEmail } from 'components/common/Modal/ModalSendInvoiceEmail';
import { EBookingStatus } from 'constants/index';
import { useTranslation } from 'react-i18next';

type Props = {
  isItemOpen?: boolean,
  data: IAppointmentItemResData;
  cusInfo: {
    customerCode: string;
    name: string;
  },
  onSendInvoice: (invoiceId: string) => void;
};

/**
 * Booking card
 * @param param: Pass the data, cusInfo, onSendInvoice into the function
 * @returns 
 */
const BookingCard = ({ data, cusInfo, onSendInvoice }: Props) => {

  const bookStatus = data?.book_status;
  const booking_time = useMemo(() => {
    if (!data?.date_book) return '';
    return moment(data?.date_book);
  }, [data]);

  const dataServices = [...data.book_assignment_services, ...(data?.book_invoice?.voucher_sales?.map(o => ({
    service_name: 'Voucher',
    quantity: o.quantity,
    id: -o.voucher_id,
    service_price: o.retail_price,
    duration_time: '',
    assigned_employee: {
      employee: {
        full_name: o.voucher_code
      }
    },
    category_service_color: '',
    category_service_name: '',
    service_id: -o.voucher_id,
    serivce_variant_name: o.voucher_name

  } as IBookAssignmentServiceItemResData)) ?? [])];
  const { t: dateLang } = useTranslation('date');
  const { t: bookingLang } = useTranslation('booking');

  return (
    <BookingCardStyled className="common-booking-card">
      <div className={'item-heading'}>
        <div className={'heading-avatar'}>
          <Avatar className='avatar'
            size={{ xs: 24, sm: 32, md: 40, lg: 64, xl: 80, xxl: 100 }}
          >
            {cusInfo.name ? cusInfo.name[0].toUpperCase() : ''}
          </Avatar>
        </div>
        <div className={'heading-detail'}>
          <p className={'heading-title text-ellipsis'}>
            {/* <NavLink
              to={PATH.invoicesDetail + data?.book_invoice?.invoice_no?.replace('#', '')}
              style={{ color: '#949BDA' }}
            >
              {data?.book_invoice?.invoice_no}
            </NavLink> */}
            {/* {data?.book_invoice?.invoice_no ? ' - ' : ''}  */}
            {cusInfo?.name}</p>
          {!!booking_time && <p>{bookingLang('BookingTime')}: <b>{getSingleDateShortFormat(booking_time, dateLang)} &bull; {booking_time.format('HH:mm')}</b></p>}
          <p>{bookingLang('Store')}: <b>{data?.location_name || ''}</b></p>
        </div>
        {data?.book_status && <div className={`status-tag ${statusTagBackground(data?.book_status)}`} >
          {bookingLang(data?.book_status.replace('_', ' ') === 'in progress' ? 'InProgress' : data?.book_status.replace('_', ' ') === 'no show' ? 'NoShows' : data?.book_status.replace('_', ' '))}
        </div>}
      </div>
      <BookingServiceList onSendInvoice={() => onSendInvoice(data?.book_invoice?.invoice_no?.toString())} bookingData={data} data={dataServices} bookStatus={bookStatus} />
    </BookingCardStyled>
  );
};

export default BookingCard;

type BookingServiceListProps = {
  data: IBookAssignmentServiceItemResData[],
  bookStatus: string
  bookingData: IAppointmentItemResData;
  onSendInvoice: () => void;
};
const BookingServiceList = ({ data, bookStatus, bookingData }: BookingServiceListProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenSendEmail, setIsOpenSendEmail] = useState<boolean>(false);
  const [searchParams] = useSearchParams();
  const { t: bookingLang } = useTranslation('booking');

  const isWalkIn = Number(searchParams.get('is_walkin_in'));
  const customerDetail = customerSelectors.getCustomerDetail();


  const mapItem = (o: IBookAssignmentServiceItemResData) => {
    // const duration_time = o.duration_time;
    const employeeName = o.assigned_employee?.employee?.full_name;
    return (
      <LiStyled key={o.id}>
        <div className='content'>
          <div className="d-flex">
            <div>
            </div>
            <div className='group-content'>
              <p className='icon_color name'><div className="round" style={{ background: o.category_service_color }}></div>{o.service_name}</p>
              <p className='icon_color hide subtext'><div className="round"></div>
                <span>{o.serivce_variant_name}</span>
                {/* <b style={{ color: '#000' }}>{parseDurationMinute(duration_time)}</b> */}
                <span>&nbsp; {o.id < 0 ? '-' : bookingLang('with')}  {employeeName || bookingLang(ANONYMOUS_NAME === 'Any team member' ? 'AnyTeamMember' : '')}</span>
              </p>
            </div>
            <p>{o.quantity}</p>
          </div>
        </div>
        <div className='amount'>
          <b>{formatMoney(o.service_price)}</b>
        </div>
      </LiStyled>
    );
  };

  const ItemTotals = () => {

    const dataInvoice = bookingData.book_invoice;


    const getValuePercent = (percent: number) => {

      return roundNumber((dataInvoice?.subtotal ?? 0) * (percent / 100));
    };

    const extraFeeValue = dataInvoice?.extra_fee ? ((dataInvoice?.extra_fee?.fee_type ?? 'amount') === 'percent' ? getValuePercent(Number(dataInvoice?.extra_fee?.fee_value ?? 0)) : Number(dataInvoice?.extra_fee?.fee_value)) : 0;
    const discountFeeValue = (dataInvoice?.discount_type ?? 'amount') === 'percent' ?
      ((dataInvoice?.subtotal ?? 0) + (extraFeeValue ?? 0)) * (Number(dataInvoice?.discount_value ?? 0) / 100) :
      Number(dataInvoice?.discount_value) ?? 0;
    const { t: bookingLang } = useTranslation('booking');
    return <>
      {
        [
          {
            label: bookingLang('ExtraFee'),
            value: extraFeeValue,
            isPlus: true,
            customName: dataInvoice?.extra_fee?.name
          },
          {
            label: bookingLang('ExtraDiscount'),
            value: discountFeeValue,
            isMinus: true,
            customName: dataInvoice?.discount_name
          },
          {
            label: bookingLang('Reward'),
            value: dataInvoice?.reward?.total_amount_reward,
            isMinus: true,
          },
          {
            label: bookingLang('Promotion'),
            value: dataInvoice?.total_apply_promotion,
            isMinus: true,
          },
          {
            label: bookingLang('Promotion') + `(${bookingLang('Auto')})`,
            value: dataInvoice?.total_apply_promotion_auto_offer ?? 0,
            isMinus: true,
          },
          {
            label: bookingLang('PublicHolidayCharge'),
            value: roundNumber(dataInvoice?.holiday_public?.total_amount_surcharge ?? 0),
            isPlus: true,
          },
          {
            label: bookingLang('CardSurcharge'),
            value: roundNumber(dataInvoice?.card_surcharge_price ?? 0),
            isPlus: true,
          },
          {
            label: bookingLang('Voucher'),
            value: dataInvoice?.total_amount_apply_voucher,
            isMinus: true,
          },
          {
            label: bookingLang('Refund') + (dataInvoice?.refunds && dataInvoice?.refunds?.length > 0 ? ' - ' + bookingLang(dataInvoice?.refunds[0]?.refund_method) : ''),
            value: dataInvoice?.refunds && dataInvoice?.refunds?.length > 0 ? dataInvoice?.refunds[0]?.amount_refund : 0,
            isMinus: true,
          },
        ].map(({ label, value, isMinus = false, isPlus = false, isFinalTotal = false, customName = undefined }: IResultItem) => {
          return value > 0 || isFinalTotal ? (
            <div>
              <p className='subtotal-label'>{customName ?? label}</p>
              <p className='subtotal-price'>{isMinus ? '-' : ''} {isPlus ? '+' : ''} {formatMoney(value)}</p>
            </div>
          ) : <></>;
        })
      }
    </>;
    //   <div key={index}>
    //   <p className={'subtotal-label'}>{label}</p>
    //   <p className={'subtotal-price'}>{formatMoney(value)}</p>
    // </div>
  };

  const refundValue = bookingData?.book_invoice?.refunds && bookingData.book_invoice?.refunds?.length > 0 ? bookingData.book_invoice?.refunds[0].amount_refund : 0;
  const total = formatMoney(Number((bookingData?.book_invoice?.total_price ?? bookingData?.total_price) - (refundValue ?? 0) ?? 0));
  return (
    <div className={'item-services'}>
      <ul
        className={`services-list is-accordion ${isOpen ? 'is-open' : 'is-close'
          }`}
      >
        {data.length > 1 ? <>
          {isOpen ? data.map(mapItem) : mapItem(data[0])}
        </> : data.length === 1 ? data.map(mapItem) : null}

      </ul>
      {
        data.length > 1 && <div className="accordion-control">
          <p className="control-btn" onClick={() => setIsOpen(!isOpen)}>
            {!isOpen ? (
              <p>
                {/* Explore <span>({data.length - 1} services)</span> */}
                <span>{bookingLang('ViewMore')}</span>
              </p>
            ) : (
              <p>{bookingLang('Close')}</p>
            )}
          </p>
        </div>
      }
      <div className="booking-summary">
        {bookingData.book_status === EBookingStatus.COMPLETED &&
          <>
            <div>
              <p className={'subtotal-label'}>{bookingLang('Subtotal')}</p>
              <p className={'subtotal-price'}>{bookingData.book_invoice ? formatMoney(bookingData.book_invoice.subtotal) : total}</p>
            </div>
            <ItemTotals />
          </>
        }

        <div>
          <p className={'total-label'}>{bookingLang('TOTAL')}</p>
          <p className={'total-price'}>{total}</p>
        </div>
        {bookingData.book_invoice?.payment_methods ? <div>
          <p className={'subtotal-label'}>{bookingLang('PaymentMethod')}</p>
          <p className={'subtotal-price'}>{bookingData.book_invoice?.payment_methods === 'mixin' ? bookingLang('mixed') : bookingLang(bookingData.book_invoice?.payment_methods)}</p>
        </div> : <></>}
      </div>
      {
        bookStatus == 'completed' && isWalkIn === 0 ?
          <div className='btn-refund-print'>
            {/* <button className='btn-refund'>Refund</button> */}
            {/* <button className='btn-print' onClick={() => setIsOpenSendEmail(true)} >Print invoice</button> */}
            <button className='btn-print' onClick={() => setIsOpenSendEmail(true)} >{bookingLang('SendInvoice')}</button>
          </div>
          : ''
      }
      {/* <ModalConfirmEmail invoiceBook={bookingData.book_invoice} customerBook={customerDetail} handleCancel={() => setIsOpenSendEmail(false)} isOpen={isOpenSendEmail}/> */}
      {isOpenSendEmail && <ModalSendInvoiceEmail
        type='card_booking'
        handleCancel={() => setIsOpenSendEmail(false)}
        isOpen={isOpenSendEmail}
        customer={customerDetail as ICustomerSendEmail}
        invoice_id={bookingData?.book_invoice?.invoice_no} />}
    </div >
  );
};

const BookingCardStyled = styled.li`
.avatar{
  background: linear-gradient(315deg, #6587FF 0%, #C883FF 100%);
  .ant-avatar-string {
    font-weight: 800;
    display: flex;
    text-transform: uppercase;
    line-height: 1;
    align-items: center;
    height: 100%;
  }
}

.booking-summary {
  border-top: 1px solid var(--color-white-01);
  padding: 15px 0;
  .total-label {
    font-weight: 500;
    font-size: 16px;
  }
  div {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .total-price {
    font-weight: 600;
    font-size: 24px;
    color: var(--color-green);
  }
}
`;

const LiStyled = styled.li`
  justify-content: space-between;
  .content {
    flex:1;
  }
  .amount {
    width: 90px;
    text-align:right;
  }
  .d-flex{
    display:flex;
    align-items: center;
    justify-content: space-between;
  }
  .round {
    display: flex;
    flex-direction: row;
    padding: 2px;
    width: 12px;
    height: 12px;
    background: grey;
    border-radius: 100px;
  }
  .group-content {
    flex:1;
    margin-bottom: 16px;
  }
  .icon_color {
    display:flex;
    align-items: center;
    @media only screen and (max-width: 480px) {
      display: block;
    }
    .round {
      margin-right: 8px;
    }
    &.hide .round {
      opacity: 0;
      width:12px;
      height:5px;
    }
    &.name {
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 140%;
      color: var(--color-primary);
      @media only screen and (max-width: 480px) {
        display: flex;
      }
    }
    &.subtext {
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 140%;
      color: var(--color-primary);

    }
  }
`;
