import IconEdit from 'assets/svg/IconEdit';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { getFormatShortDate } from 'utils/moment/getSingleDateShortFormat';

type BookingDateProps = {
  isEdit: boolean,
  bookingDate: string,
  setIsOpenModal: (value: boolean) => void
}

/**
 * Handle booking date
 * @param isEdit , bookingDate , setIsOpenModal
 * @returns 
 */
const BookingDate = ({isEdit, bookingDate, setIsOpenModal}: BookingDateProps) => {
  const{t:bookingLang} = useTranslation('booking');
  const{t: dateLang} = useTranslation('date');
  return (
    <EditTimeStyled>
      <div className='time' >
        {bookingLang('BookingDate')}:
        <div className={`booking_date ${!isEdit ? 'booking_date_none_edit' : 'booking_date_edit'}`} onClick={() => { setIsOpenModal(true); }}>
          <p>{getFormatShortDate(bookingDate, dateLang)}</p>
          {isEdit && (
            <span className='time-edit' >
              <IconEdit />
            </span>
          )}
        </div>
      </div>
    </EditTimeStyled>
  );
};

export default BookingDate;

const EditTimeStyled = styled.div`
  .time {
    margin-bottom: 16px;
    display: flex;
    font-weight: 400 !important;
    /* font-size: 14px; */
    font-size: 18px;
    color: #60669f;
    align-items: center;
    .booking_date {
      display: flex;
      align-items: center;
      font-size: 16px;
      font-weight: 600;
    }

    .booking_date_edit {
      cursor: pointer;
      color: #4A9D77;
    }

    .booking_date_none_edit {
      color: var(--green-1,#4A9D77);
      pointer-events: none;
    }
    gap: 4px;
    span {
      font-weight: 600;
      color: var(--color-primary);
    }
    .time-edit {
      max-width: 20px;
      margin-left: 8px;
      cursor: pointer;
      svg {
        max-width: 100%;
      }
    }
  }
`;