import SYSTEM_VARIABLES from 'constants/variables';
import bookingSelectors from 'features/bookings/services/selectors';
import moment, { Moment } from 'moment';
import React, { memo, useCallback, useRef } from 'react';
import BreakingTimeOverlay from './BreakingTimeOverlay';
import Droppable from './DnD/Droppable';
import UITeamMemberMultiItem from './UITeamMemberMultiItem';
import { IColCalendar, IRowCalendar, IRowSpanInfo } from './types';
import { isNaNOrInfinity } from 'utils/unit';
export type IDndDataCalendar = {
  id?: string;
  rowId: string;
  colData: IColCalendar;
  rowTime: Moment,
};
type Props = {
  data: IRowCalendar,
  rowsSpanInfo: IRowSpanInfo[],
  isSingleCol?: boolean;
  distance: number;
};
const RowCalendar: React.FC<Props> = ({ data, rowsSpanInfo = [], isSingleCol = false, distance }) => {
  const { id, colData = [], rowTime } = data;
  const viewType = bookingSelectors.getCalendarViewType();
  
  const activeLocation = bookingSelectors.getCalendarCurrentLocation();
  const dateStore = bookingSelectors.getCalendarParamValue('date') as number;

  const memeDateBreakDay = useRef<{
    openDate: {
      open: moment.Moment;
      close: moment.Moment;
    }[],
  }>({
    openDate: []
  });

  /**
   * hook to check and render column calendar
   * @param col
   */
  const checkIsBreakDay = useCallback((col: IColCalendar) => {
    // for member view

    // if(! activeLocation) return false;

    const isValidColDate = moment(col.id, 'DD/MM/YYYY', true).isValid();
    
    if (!isValidColDate) {
      if (dateStore) {
        const curDate = moment(dateStore);
        const openDate = activeLocation?.time_opening
          .filter(o => o.weekday === curDate.format('dddd').toLocaleLowerCase())
          .map(o => ({
            open: data.rowTime.clone().set({ hour: o.start, minute: o.startMinute }),
            close: data.rowTime.clone().set({ hour: o.end, minute: o.endMinute }),
          }));
        memeDateBreakDay.current.openDate = openDate ?? [];

        if (openDate?.length === 0) {
          return true;
        } else {
          const isClosedDate = activeLocation?.date_closed.some(o => {
            const start_date = moment(o.start_date, 'YY-MM-DD');
            const end_date = moment(o.end_date, 'YY-MM-DD');
            if (curDate.isSame(start_date, 'day') || curDate.isBetween(start_date, end_date) || curDate.isSame(end_date, 'day')) return true;
            return false;
          });

          if (isClosedDate) return true;
          else {
            
            const isWorkingTime = openDate?.find(o => {
              const isBetween = rowTime.isBetween(o.open, o.close);
              const isSameStart = rowTime.isSame(o.open);
              const isSameEnd = rowTime.isSame(o.close);
              return (isSameStart || isBetween || isSameEnd);
            });
            return !isWorkingTime;
          }
        }
      }
      return false;
    }

    // for week view
    const curDate = moment(col.id, 'DD/MM/YYYY');
    const openDate = activeLocation?.time_opening
      .filter(o => o.weekday === curDate.format('dddd').toLocaleLowerCase())
      .map(o => ({
        open: data.rowTime.clone().set({ hour: o.start, minute: o.startMinute }),
        close: data.rowTime.clone().set({ hour: o.end, minute: o.endMinute }),
      }));
    memeDateBreakDay.current.openDate = openDate ?? [];
    const isBreakingDay = openDate?.length === 0;

    if (isBreakingDay) return true;
    else {
      const isClosedDate = activeLocation?.date_closed.some(o => {
        const start_date = moment(o.start_date, 'YY-MM-DD');
        const end_date = moment(o.end_date, 'YY-MM-DD');
        if (curDate.isSame(start_date, 'day') || curDate.isBetween(start_date, end_date) || curDate.isSame(end_date, 'day')) return true;
        return false;
      });
      if (isClosedDate) return true;
      else {
        const isWorkingTime = openDate?.find(o => {
          const isBetween = rowTime.isBetween(o.open, o.close);
          const isSameStart = rowTime.isSame(o.open);
          const isSameEnd = rowTime.isSame(o.close);
          return (isSameStart || isBetween || isSameEnd);
        });
        return !isWorkingTime;
      }
    }
  }, [activeLocation, dateStore]);


  /**
   * Hook to render column
   * @param col
   * @param index
   */
  const renderColumn = useCallback((col: IColCalendar, index: number) => {
    const dropId = data.id + '/' + col.id;
    const dropData: IDndDataCalendar = { rowId: data.id, colData: col, rowTime: data.rowTime };
    const orderGroup = rowsSpanInfo.find(it =>
      rowTime.isSameOrBefore(it?.rowEnd.value) &&
      it.colId === col.id &&
      col.data.some(i => it.orderIds.includes(i?.id?.toString())),
    );

    const isExact = !!orderGroup && orderGroup.rowStart.id === id;
    const isBreak = checkIsBreakDay(col);
    if (isExact) {

      const arr = Array.from(Array( isNaNOrInfinity(orderGroup.rowSpan) && !orderGroup.rowSpan ? 1 : orderGroup.rowSpan).keys());
      return (
        <td key={col.id + 'col'} rowSpan={orderGroup?.rowSpan || 1} className={`${col.id === SYSTEM_VARIABLES.UNASSIGNED ? SYSTEM_VARIABLES.UNASSIGNED : ''} ${rowTime.format('HH:mm')}`}>
          <Droppable distance={distance} rowSpan={orderGroup?.rowSpan} key={dropId} id={dropId} className={`col-table exact ${viewType} ${(isBreak) ? 'isBreakTime' : ''}`} data={dropData}>
            <div className={`wrap ${index === 0 ? 'first' : ''} ${isSingleCol ? 'singleCol' : ''}`} >
              <UITeamMemberMultiItem grData={col} rowSpan={orderGroup?.rowSpan} col_id={col.id} />
            </div>
            {(isBreak) && <div className="frameBreakingTime">
              {arr.filter(o => {
                const add = o * distance;
                const rowTimeA = rowTime?.clone()?.set('minute', add);
                const isWorkingTime = memeDateBreakDay.current.openDate?.find(f => {
                  const isBetween = rowTimeA.isBetween(f.open, f.close);
                  const isSameStart = rowTimeA.isSame(f.open);
                  const isSameEnd = rowTimeA.isSame(f.close);
                  return (isSameStart || isBetween || isSameEnd);
                });
                return !isWorkingTime;
              }).map(o => <BreakingTimeOverlay key={o} />)}
            </div>}
          </Droppable>
        </td>
      );
    }

    const isBefore = !!orderGroup;
    if (isBefore) return <td key={col.id + 'col'} className='empty' ><Droppable distance={distance} key={dropId} id={dropId} data={dropData} /></td>;
    return (
      <td key={col.id + 'col'} className={col.id === SYSTEM_VARIABLES.UNASSIGNED ? SYSTEM_VARIABLES.UNASSIGNED : ''}>
        <Droppable distance={distance} key={dropId} id={dropId} data={dropData} className={`col-table before ${viewType} ${(isBreak) ? 'isBreakTime' : ''}`} >
          {(isBreak) && <div className="frameBreakingTime"> <BreakingTimeOverlay /></div>}
        </Droppable>
      </td>
    );
  }, [rowsSpanInfo, activeLocation, checkIsBreakDay, viewType, distance]);
  return (
    <tr>
      <td className='time'><div className='col-table flex-center'>{rowTime.format('HH:mm')}</div></td>
      {colData.map(renderColumn)}
      {/* <td className='row-span' ><div className='col-table'/></td> */}
    </tr>
  );
};

export default memo(RowCalendar);
