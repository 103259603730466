import React, { useState } from 'react';
import styles from '../../pages/index.module.scss';
import { Checkbox, Form, Input, Switch } from 'antd';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { IconLocation, IconRight } from 'assets/svg';

const WarehouseCard = () => {
    const { t: productsLang } = useTranslation('products');
    const [table1, settable1] = useState(false);
    const [table2, settable2] = useState(false);
    const [table3, settable3] = useState(false);

    const clickTable1 = () => {
        settable1(!table1);
    };

    const clickTable2 = () => {
        settable2(!table2);
    };

    const clickTable3 = () => {
        settable3(!table3);
    };

    return (
        <WarehouseCardPageStyled>
            <div className={styles.container_warehouseCard}>
                <div className={styles.left_warehouseCard}>
                    <div className={styles.quantity_warehouseCard}>
                        <p className={styles.title_quantity}>{productsLang('Quantity')}</p>
                        <div className={styles.classify_warehouseCard_table}>
                            <div className={styles.title_warehousrCard_table}>
                                <Checkbox />
                                <div className={styles.icon_location}>
                                    <IconLocation />
                                </div>
                                <div className={styles.title_warehousrCard}>
                                    <p className={styles.title_warehousrCard}>Beauty Center 1</p>
                                    <p className={styles.title_warehousrCard_address}>157 Nguyễn Gia Trí, phường 13, quận Bình Thạnh, TP.Hồ Chí Minh, Việt Nam</p>
                                </div>
                                <div onClick={clickTable1} className={`${styles.iconright_warehouseCard} ${table1 ? styles.rotate180 : ''}`}>
                                    <IconRight />
                                </div>
                            </div>
                            {table1 ?
                                <>
                                    <div className={styles.prices_warehousrCard}>
                                        <Form.Item label={productsLang('Product')}
                                            rules={[
                                                { required: true, message: productsLang('PleaseEnterProduct') || '' }
                                            ]}>
                                            <Input value={'Variant 1'} placeholder={productsLang('EnterProduct') || ''} disabled />
                                        </Form.Item>
                                        <Form.Item label={productsLang('Quantity')}
                                            rules={[
                                                { required: true, message: productsLang('PleaseEnterQuantity') || '' }
                                            ]}>
                                            <Input placeholder={productsLang('EnterQuantity') || ''} />
                                        </Form.Item>
                                    </div>
                                    <div className={styles.prices_warehousrCard}>
                                        <Form.Item label={productsLang('Product')}
                                            rules={[
                                                { required: true, message: productsLang('PleaseEnterProduct') || '' }
                                            ]}>
                                            <Input placeholder={productsLang('EnterProduct') || ''} value={'Variant 2'} disabled />
                                        </Form.Item>
                                        <Form.Item label={productsLang('Quantity')}
                                            rules={[
                                                { required: true, message: productsLang('PleaseEnterQuantity') || '' }
                                            ]}>
                                            <Input placeholder={productsLang('EnterQuantity') || ''} />
                                        </Form.Item>
                                    </div>
                                </>
                                : ''}

                        </div>
                        <div className={styles.classify_warehouseCard_table}>
                            <div className={styles.title_warehousrCard_table}>
                                <Checkbox />
                                <div className={styles.icon_location}>
                                    <IconLocation />
                                </div>
                                <div className={styles.title_warehousrCard}>
                                    <p className={styles.title_warehousrCard}>Beauty Center 1</p>
                                    <p className={styles.title_warehousrCard_address}>157 Nguyễn Gia Trí, phường 13, quận Bình Thạnh, TP.Hồ Chí Minh, Việt Nam</p>
                                </div>
                                <div onClick={clickTable2} className={`${styles.iconright_warehouseCard} ${table2 ? styles.rotate180 : ''}`}>
                                    <IconRight />
                                </div>
                            </div>
                            {table2 ?
                                <>
                                    <div className={styles.prices_warehousrCard}>
                                        <Form.Item label={productsLang('Product')}
                                            rules={[
                                                { required: true, message: productsLang('PleaseEnterProduct') || '' }
                                            ]}>
                                            <Input placeholder={productsLang('EnterProduct') || ''} value={'Variant 1'} disabled />
                                        </Form.Item>
                                        <Form.Item label={productsLang('Quantity')}
                                            rules={[
                                                { required: true, message: productsLang('PleaseEnterQuantity') || '' }
                                            ]}>
                                            <Input placeholder={productsLang('EnterQuantity') || ''} />
                                        </Form.Item>
                                    </div>
                                    <div className={styles.prices_warehousrCard}>
                                        <Form.Item label={productsLang('Product')}
                                            rules={[
                                                { required: true, message: productsLang('PleaseEnterProduct') || '' }
                                            ]}>
                                            <Input placeholder={productsLang('EnterProduct') || ''} value={'Variant 2'} disabled />
                                        </Form.Item>
                                        <Form.Item label={productsLang('Quantity')}
                                            rules={[
                                                { required: true, message: productsLang('PleaseEnterQuantity') || '' }
                                            ]}>
                                            <Input placeholder={productsLang('EnterQuantity') || ''} />
                                        </Form.Item>
                                    </div>
                                </>
                                : ''}

                        </div>
                        <div className={styles.classify_warehouseCard_table}>
                            <div className={styles.title_warehousrCard_table}>
                                <Checkbox />
                                <div className={styles.icon_location}>
                                    <IconLocation />
                                </div>
                                <div className={styles.title_warehousrCard}>
                                    <p className={styles.title_warehousrCard}>Beauty Center 1</p>
                                    <p className={styles.title_warehousrCard_address}>157 Nguyễn Gia Trí, phường 13, quận Bình Thạnh, TP.Hồ Chí Minh, Việt Nam</p>
                                </div>
                                <div onClick={clickTable3} className={`${styles.iconright_warehouseCard} ${table3 ? styles.rotate180 : ''}`}>
                                    <IconRight />
                                </div>
                            </div>
                            {table3 ?
                                <>
                                    <div className={styles.prices_warehousrCard}>
                                        <Form.Item label={productsLang('Product')}
                                            rules={[
                                                { required: true, message: productsLang('PleaseEnterProduct') || '' }
                                            ]}>
                                            <Input placeholder={productsLang('EnterProduct') || ''} value={'Variant 1'} disabled />
                                        </Form.Item>
                                        <Form.Item label={productsLang('Quantity')}
                                            rules={[
                                                { required: true, message: productsLang('PleaseEnterQuantity') || '' }
                                            ]}>
                                            <Input placeholder={productsLang('EnterQuantity') || ''} />
                                        </Form.Item>
                                    </div>
                                    <div className={styles.prices_warehousrCard}>
                                        <Form.Item label={productsLang('Product')}
                                            rules={[
                                                { required: true, message: productsLang('PleaseEnterProduct') || '' }
                                            ]}>
                                            <Input placeholder={productsLang('EnterProduct') || ''} value={'Variant 2'} disabled />
                                        </Form.Item>
                                        <Form.Item label={productsLang('Quantity')}
                                            rules={[
                                                { required: true, message: productsLang('PleaseEnterQuantity') || '' }
                                            ]}>
                                            <Input placeholder={productsLang('EnterQuantity') || ''} />
                                        </Form.Item>
                                    </div>
                                </>
                                : ''}

                        </div>
                    </div>
                </div>
                <div className={styles.right_warehouseCard}>
                    <div className={styles.NoticeOfInventory}>
                        <div className={styles.title_NoticeOfInventory_table}>
                            <p className={styles.title_NoticeOfInventory}>{productsLang('NoticeOfInventory')}</p>
                            <p className={styles.note_NoticeOfInventory}>{productsLang('NoteNoticeOfInventory')}.</p>
                        </div>
                        <div className={styles.switch_setting_warehouseCard_item}>
                            <span>{productsLang('AllowToReceiveNotifications')}</span>
                            <Switch defaultChecked />
                        </div>

                        <Form.Item label={productsLang('Số lượng tối thiểu')}
                            rules={[
                                { required: true, message: productsLang('MinimumQuantity') || '' }
                            ]}>
                            <Input placeholder={productsLang('Được tính trên 1 phân loại.') || ''} />
                            <p className={styles.note_category_warehouseCard}>{productsLang('CalculatedOn1Category')}.</p>
                        </Form.Item>

                    </div>
                </div>
            </div>
        </WarehouseCardPageStyled>
    );
};

export default WarehouseCard;

const WarehouseCardPageStyled = styled.div`
.ant-form-item {
    margin-bottom: 24px !important;
    .ant-row {
        display: block; 
    }
}
.ant-select-selector {
    min-width: 0px !important;
}
.ant-btn-default {
    margin-top: 12px;
    width: 100%;
    border: 0px;
    font-size: 18px;
    font-weight: 500;
    line-height: 140%;
    color: #1B9AAA;
    display: flex;
    gap: 12px;
    align-items: center;
}
.ant-form-item-label > label {
    font-weight: 600;
    font-size: 16px;
}
`;