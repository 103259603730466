import { Form, Input } from 'antd';
import { AxiosResponse } from 'axios';
import SettingCard from 'components/common/Cards/SettingCard';
import FrameSetting from 'components/common/FrameSetting';
import message from 'components/common/Message';
import MultipleSelect from 'components/common/MultipleSelect';
import PermissionGroup from 'features/settings/components/widgets/PermissionGroup';
import settingActions from 'features/settings/services/actions';
import settingApis from 'features/settings/services/apis';
import settingSelectors from 'features/settings/services/selectors';
import { IRole, IRoleBodyReq } from 'features/settings/services/types/role';
import userSelectors from 'features/users/services/selectors';
import { get } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import uiActions from 'services/UI/actions';
import styled from 'styled-components';

interface IPermissionSettingForm {
  role?: IRole, 
  onBack: () => void
}

const getPackageName = (name: string, id:  number) => {
  return `${name}_${id}`;
};

const PermissionSettingFormPage = ({role, onBack}: IPermissionSettingForm) => {
  const { t: bookingLang } = useTranslation('booking');
  const { t: commonLang } = useTranslation('common');


  const teamMembers = userSelectors.getStaffPaginateList();
  const [form] = Form.useForm();
  const packages = settingSelectors.rolePermission.getPermissionPackages()?.package_features;
  const dispatch = useDispatch();
  const [memberSelected, setMemberSelected] = useState<any[]>();
  const [values, setValues] = useState<string[]>(() => {

    if(!role) return [];
    form.setFieldValue('name' ,role?.name);
    form.setFieldValue('user_ids' ,role?.user_ids);
    return role?.permissions?.map(permission => getPackageName(permission.name, permission.id)) ?? [];
  });

  useEffect(() => {
    if(!packages) dispatch(uiActions.setLoadingPage(true));
    else dispatch(uiActions.setLoadingPage(false));
  }, [packages]);

  const options = useMemo(() => {    
    if(!teamMembers) return [];
    const teamMemberOption = teamMembers?.data?.map((item: any) => {
      return ({
        value: item.user_id,
        title: item.full_name,
      });
    });
    
    return teamMemberOption;
    
  }, [ teamMembers ]);

  const Header = () => {
    return (
      <HeaderStyled >
        <div onClick={onBack} style={{cursor: 'pointer'}}>
          <BackButton/>
        </div>
        <button onClick={() => form.submit()} type='submit' className='common-btn'>Xác nhận</button>
      </HeaderStyled>
    );
  };

  const permissions = useMemo(() => {
    if(!packages) return;

    return Object.keys(packages).map((key) => ({
      key: key,
      //@ts-ignore
      permissions: packages[key].map((pack: any) => ({
        ...pack,
        name: getPackageName(pack.name, pack.id)
      }))
    }));
  }, [packages]); 

  const getPayload = (formVal: any) => {

    const name = formVal.name;
    const user_ids = formVal.user_ids;
    //@ts-ignore
    const permissions = Object.keys(formVal).filter(key => formVal[key] === true);

    const list_permission = permissions?.map(permission => {
      const slipt = permission.split('_');
      return Number(slipt[slipt.length - 1]);
    });
    

    return {
      name,
      user_ids,
      list_permission
    } as IRoleBodyReq;
  };

  const handleSubmit = async (val: any) => {

    const payload = getPayload(val);

    if(role) {
      try {
        dispatch(uiActions.setLoadingPage(true));
        const rest: AxiosResponse<{ message: string }> = await settingApis.rolePermission.updateRole(role.id, payload);
  
        const msg = get(rest, 'data.message', '');
        if (msg) {
          message.success(msg);
          dispatch(settingActions.getListRole.fetch());
          return true;
        }
        return false;
      } catch (error: any) {
        const response = get<Record<string, string[]>>(
          error,
          'response.data.errors',
          {}
        );
  
        const errors = Object.values(response);
        errors.map((value) => {
          message.error(get(value, [0], ''));
        });
  
        return false;
      } finally {
        dispatch(uiActions.setLoadingPage(false));
        onBack();
      }
    } else {
      try {
        dispatch(uiActions.setLoadingPage(true));
        const rest: AxiosResponse<{ message: string }> = await settingApis.rolePermission.storeRole(payload);
  
        const msg = get(rest, 'data.message', '');
        if (msg) {
          message.success(msg);
          dispatch(settingActions.getListRole.fetch());
          return true;
        }
        return false;
      } catch (error: any) {
        const response = get<Record<string, string[]>>(
          error,
          'response.data.errors',
          {}
        );
  
        const errors = Object.values(response);
        errors.map((value) => {
          message.error(get(value, [0], ''));
        });
  
        return false;
      } finally {
        dispatch(uiActions.setLoadingPage(false));
        onBack();

      }
    }
  };

  return (
    <PermissionSettingFormPageStyled header={<Header />}>
      <FrameSetting title='Cài đặt phân quyền' description='Admin là vai trò quan trọng trong hệ thống MindHana, nên vui lòng không cung cấp tài khoản admin cho bất kì ai khác'>
      <Form
        name='basic'
        form={form}
        className='modal-form'
        autoComplete='off'
        onFinish={handleSubmit}
        layout='vertical'
      >
        <Form.Item name={'name'} label={'Vai trò (*)'}>
          <Input defaultValue={role?.name ?? ''} />
        </Form.Item>
        <Form.Item name={'user_ids'} label={'Chọn nhân viên (*)'}>
          <MultipleSelect
            onChange={setMemberSelected}
            value={memberSelected ?? []}
            options={options}
            maxTagPlaceholder={commonLang('employees')||''}
            placeholder={bookingLang('SelectEmployees')||''}
          />
        </Form.Item>

        {permissions?.map(permission => {
          return (
          <PermissionGroup key={permission.key} onChange={setValues} label={permission.key} options={permission.permissions} values={values} />
        );})}
      </Form>

      </FrameSetting>
    </PermissionSettingFormPageStyled>
  );
};

export default PermissionSettingFormPage;

const HeaderStyled = styled.div`
    width: '100%';
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 8px;

    .common-btn {
      margin-bottom: 5px;
      height: 46px;
    }
`;

const PermissionSettingFormPageStyled = styled(SettingCard)`
`;

const BackButton = () => {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="48" height="48" rx="10" fill="#F5F5F5" />
      <path d="M28 16L20.7071 23.2929C20.3166 23.6834 20.3166 24.3166 20.7071 24.7071L28 32" stroke="#141414" strokeWidth="1.5" strokeLinecap="round" />
    </svg>

  );
};