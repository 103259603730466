import bookingActions from 'features/bookings/services/actions';
import bookingSelectors from 'features/bookings/services/selectors';
import _debounce from 'lodash/debounce';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from 'store/hooks';

/**
 * Render input search text
 * @returns 
 */
const InputSearchText = () => {
  const dispatch = useAppDispatch();
  const value = (bookingSelectors.getCalendarParamValue('keyword') ?? '') as string;
  const [text, setText] = useState(value);
  const { t: formLang } = useTranslation('form');
  const onChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setText(e.target.value ?? '');
  };

  const debounce = useCallback(_debounce((text) => {
    dispatch(bookingActions.setCalendarBookingParams({
      keyword: text || undefined,
    }));
  }, 200), []);

  useEffect(() => {
    debounce(text);
  }, [text]);

  return (
    <div data-search-testing='search-booking' className='common-input-search'>
      <input
        placeholder={formLang('SearchPlaceholder') || ''}
        type='text'
        value={text}
        onChange={onChange}
      />
    </div>
  );
};

export default InputSearchText;