import { Form } from 'antd';
import StaffFormContent, { IStaffFormInput } from 'features/users/components/StaffFormContent';
import userApis from 'features/users/services/apis';
import userSelectors from 'features/users/services/selectors';
import { get, set } from 'lodash';
import React, { useMemo } from 'react';
// import StringValidations from 'utils/validations/string';
import { IFormAddStaffData } from '.';
import { useAppDispatch } from 'store/hooks';
import userActions from 'features/users/services/actions';
import { useTranslation } from 'react-i18next';
// import StringValidations from 'utils/validations/string';

type Props = {
  errors: any;
  onCancel: () => void;
  onNextStep: () => void;
  isEdit?: boolean;
  defaultData?: IFormAddStaffData;
}
const UserFormFirstStep = ({
  errors = {},
  onCancel = () => undefined,
  onNextStep = () => undefined,
  isEdit,
  defaultData
}: Props) => {


  const getShowCreateStaffs = userSelectors.getShowCreateStaffs();
  const locationOptions = getShowCreateStaffs?.merchant_locations;
  const form = Form.useFormInstance();
  const accessLevelOptions = getShowCreateStaffs?.access_levels;
  const dispatch = useAppDispatch();
  const { t: userLang } = useTranslation('user');
  const { t: bookingLang } = useTranslation('booking');
  const checkValid = async () => {
    try {
      await form.validateFields([
        'name',
        'accessLevel',
        'phone',
        'pinNumber',
        'location'
      ]);

      if (isEdit) {
        if (defaultData?.pinNumber !== form.getFieldValue('pinNumber'))
          await userApis.checkPinNumberExist({
            pin_number: form.getFieldValue('pinNumber')
          });
      } else {
        await userApis.checkPinNumberExist({
          pin_number: form.getFieldValue('pinNumber')
        });
      }

      return true;
    } catch (error: any) {
      const response = get<Record<string, string[]>>(
        error,
        'response.data.errors',
        {}
      );
      const errors = Object.entries(response);
      const errorsObj = {};
      errors.forEach(([key, value]) => {
        set(errorsObj, [key], value?.[0]);
      });

      errors.map(([, value]) => {
        form.setFields([
          {
            name: 'pinNumber',
            errors: [get(value, [0], '')]
          }
        ]);

      });
      return false;
    }
  };



  const handleNextStep: React.MouseEventHandler<HTMLButtonElement> = async (e) => {
    e.preventDefault();
    const isFormValid = await checkValid();
    if (!isFormValid) return;
    onNextStep();
  };


  const getFeedback = (text: string) => {
    if (!text) return {};
    return ({
      validateStatus: text ? 'error' : undefined,
      help: text ? text : undefined,
    });
  };



  // @ts-ignore
  const inputs: IStaffFormInput[] = useMemo(() => [
    {
      label: userLang('Name'),
      name: 'name',
      placeholder: userLang('EnterUserName'),
      type: 'text',
      rules: [
        {
          validator(_, value,) {
            if (!value?.trim()) return Promise.reject(new Error(userLang('TeamMemberNameIsRequired') || ''));
            if (value && value.trim().length > 15) return Promise.reject(new Error(userLang('TeamMemberNameMustBe15CharactersOrFewer') || ''));
            return Promise.resolve();
          },
        },
        { min: 2, message: bookingLang('NameMustBeAtLeast2Characters') || '' },
      ],
      ...getFeedback(get(errors, ['full_name'])),
    },
    {
      label: userLang('PhoneNumber'),
      name: 'phone',
      type: 'phone',
      rules: [{
        validator(_, value,) {
          if (!value?.trim()) return Promise.reject(new Error(userLang('PhoneNumberIsRequired') || ''));
          if (value && !/^0+[0-9]{9}$/.test(value)) return Promise.reject(new Error(userLang('ThePhoneNumberIs04AndHas10Digits') || ''));
          return Promise.resolve();
        },
      }],
      ...getFeedback(get(errors, ['phone'])),
    },
    // {
    //   label: 'Email',
    //   name: 'email',
    //   placeholder: 'Enter user\'s email',
    //   rules: [
    //     { required: true },
    //   ]
    // },
    {
      label: userLang('PinNumber'),
      name: 'pinNumber',
      placeholder: userLang('EnterPinNumber'),
      type: 'textPin',
      rules: [{
        validator(_, value,) {
          if (!value) return Promise.reject(new Error(userLang('PinNumberIsRequired') || ''));
          if (value.length < 4) return Promise.reject(new Error(userLang('ThePinNumberMustBe4Digits') || ''));
          return Promise.resolve();
        },
      }],
      subTitle: userLang('YouCanUseThisPINNumberToEasilyAccessTheTimeAttendanceSystem'),
      ...getFeedback(get(errors, ['pin_number'])),
    },
    {
      label: userLang('AccessLevel'),
      name: 'accessLevel',
      placeholder: userLang('SelectAccessLevel'),
      options: accessLevelOptions,
      type: 'select',
      disabled: defaultData?.is_manager,
      rules: [{ required: true, message: userLang('AccessLevelRequired') }],
      ...getFeedback(get(errors, ['access_level'])),
    },
    {
      label: userLang('Locations'),
      name: 'location',
      placeholder: userLang('SelectLocation'),
      options: locationOptions,
      type: 'select',
      disabled: defaultData?.is_manager,
      onChange: (value: any) => {
        dispatch(userActions.setLocationSelectedId(value));
      },
      rules: [{ required: true, message: userLang('LocationsRequired') }],
    },
    {
      label: userLang('Booking'),
      title: userLang('AllowBookingCalendar'),
      name: 'isReceiveBook',
      subTitle: userLang('AllowThisTeamMemberToReceiveBookingNotification'),
      type: 'switch'
    }
  ], [accessLevelOptions, errors]);

  return (
    <>
      <StaffFormContent inputs={inputs} />

      <div className='form-submit-wrapper'>
        <button
          className='common-btn is-white'
          onClick={onCancel}
          type='button'>
          {userLang('Cancel')}
        </button>
        <button
          className='common-btn'
          onClick={handleNextStep}
        >
          {userLang('Continue')}
        </button>
      </div>
    </>
  );
};

export default UserFormFirstStep;
