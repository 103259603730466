import { AxiosResponse } from 'axios';
import message from 'components/common/Message';
import ModalConfirm, {
    ModalConfirmRef,
} from 'components/common/Modal/ModalConfirm';
import { IMerchantEmployee } from 'features/bookings/components/BookingForm/types';
import ImportModal from 'features/customers/components/ImportModal';
import { get } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import CategoryTable from '../Components/CategoryFrom/CategoryTable';
import serviceActions from '../../services/services/actions';
import apisService from '../../services/services/apis';
// import styled from 'styled-components';
import styles from './index.module.scss';
import { Form, Input, Modal } from 'antd';
import { IFuncCancel, IFuncSubmit } from 'features/users/widgets/ModalUserForm';
import SelectColor from '../Components/CategoryFrom/SelectColor';
import styled from 'styled-components';
interface IServiceVariant {
    name?: string;
    price?: number;
    duration?: number;
}

export type IServiceReponse = {
    category: {
        id: number;
        name: string;
        color: string;
    };
    enable_discount?: boolean;
    enable_voucher_sale?: boolean;
    enable_online_booking?: boolean;
    enable?: boolean;
    description?: string;
    id: number;
    merchant_location_id: number[];
    merchant_employees: IMerchantEmployee[];
    regular_price: number;
    sale_price: number;
    service_variants: IServiceVariant[];
    name?: string;
    service_name?: string;
    service_price?: number;
    duration_time?: number;
    is_favourite?: boolean;

    assigned_employee: {
        employee: {
            id: number;
            full_name: string;
            email: string;
            phone: string;
            merchant_location_id: number;
        };
        time_start: string;
        duration: string;
        time_end: string;
        status: string;
    };

    checked?: boolean;
    time_start?: string;
    employee_id?: number;
    quantity?: number;
    serviceVariantName?: string;
    serviceVariantPrice?: number;
    service_id?: string;
};

const CategoryPage = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const modalConfirmRef = useRef<ModalConfirmRef>(null);
    const { t: productLang } = useTranslation('products');

    const [fileXsls, setFileXsls] = useState<any>();
    const [, setIsSpin] = useState<boolean>(false);
    const [isOpenImportModal, setIsOpenImportModal] = useState<boolean>(false);
    const [errors, setErrors] = useState<Record<string, string>>({});
    const [searchParams] = useSearchParams();
    const [titleModel, setTitleModel] = useState('add');

    const page: any = {
        page: searchParams.get('page') ?? 1,
        per_page: searchParams.get('per_page') ?? 10,
    };

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(
            serviceActions.getListPaginateServices.fetch({
                page: Number(searchParams.get('page') ?? 1),
                per_page: Number(searchParams.get('per_page') ?? 10),
            })
        );

        dispatch(serviceActions.getShowCreateService.fetch());
    }, []);

    const onAddItem = () => {
        setTitleModel('add');
        setIsModalOpen(true);
    };

    const onEditItem = () => {
        setTitleModel('edit');
        setIsModalOpen(true);
    };

    const onClickRemoveItem = (record: IServiceReponse) => {
        modalConfirmRef.current?.show({
            title: productLang('modalConfirm.deleteCategory.title'),
            msg: productLang('modalConfirm.deleteCategory.message'),
            submit: async () => {
                try {
                    const res = await apisService.deleteService(record.id);
                    const msg = get(res, 'data.message', '');
                    if (msg) {
                        message.success(msg);
                        dispatch(serviceActions.getListPaginateServices.fetch(page));
                    } else {
                        const msgError = get(res, 'data.error.message');
                        if (msgError) message.error(msgError);
                    }
                } catch (error) {
                    message.error('An error occurred. Please try again');
                }
            },
            icon: 'delete',
        });
    };

    const columns = [
        {
            title: '',
            dataIndex: 'color',
            key: 'color',
            render: () => {
                return (
                    <LoadingPageStyled>
                        <div className='common-tag categoryColor'>

                        </div>
                    </LoadingPageStyled>
                );
            },
        },
        {
            title: 'Tên danh mục',
            dataIndex: 'category_name',
            key: 'category_name',
            fixed: 'left',
            className: `${styles.width_category_table_mobile}`,
            render: (text: any) => {
                return (
                    <div onClick={onEditItem} className={`${styles['is-category']}`}>
                        {text}
                    </div>
                );
            },
        },
        {
            title: 'Số lượng dịch vụ',
            dataIndex: 'quantity_service',
            key: 'quantity_service',
            className: `${styles.width_category_table_mobile}`,
            render: (text: string) => `${text}`,
        },
        // {
        //     title: 'Trạng thái',
        //     dataIndex: 'status',
        //     key: 'status',
        //     className: `${styles.width_category_table_mobile}`,
        //     render: (text: string) => `${productLang(text)}`,
        // },
        {
            title: productLang('Actions'),
            dataIndex: 'actions',
            key: 'actions',
            className: `${styles.width_category_table_mobile}`,
            render: (text: string, record: IServiceReponse) => (
                <ul className={'common-action'}>
                    <li
                        className='action-edit'
                        onClick={onEditItem}
                    // onClick={() => handleModalUpdate(record)}
                    ></li>
                    <li
                        className='action-remove'
                        onClick={() => onClickRemoveItem(record)}
                    ></li>
                </ul>
            ),
        },
    ];

    const _onSubmitImport = async () => {
        setIsSpin(true);
        try {
            const formData = new FormData();
            formData.append('file', fileXsls);

            const rest: AxiosResponse<{ message: string }> =
                await apisService.importServiceInfo(formData);

            const msg = `${(rest as any).data.data.customerSuccess} record inserted`;
            if (msg) {
                message.success(msg);
                setIsSpin(false);
                dispatch(
                    serviceActions.getListPaginateServices.fetch({
                        page: Number(searchParams.get('page') ?? 1),
                        per_page: Number(searchParams.get('per_page') ?? 10),
                    })
                );
                return true;
            }
            return false;
        } catch (error: any) {
            const response = get<Record<string, string[]>>(
                error,
                'response.data.errors',
                {}
            );

            const errors = Object.values(response);
            errors.map((value) => {
                message.error(get(value, [0], ''));
            });

            setIsSpin(false);
            return false;
        } finally {
            setFileXsls(undefined);
        }
    };

    const handleSubmit: IFuncSubmit = async (data: any) => {
        return data;
    };

    const handleCancel: IFuncCancel = () => {
        setIsModalOpen(false);
        setErrors({});
    };

    return (
        <>
            <div className={'private-filter'}>
                <button onClick={onAddItem} className={'common-btn'} style={{ marginTop: '30px' }}>
                    {productLang('AddNew')}
                </button>
                {isModalOpen &&
                    <AddCustomer
                        titleModel={titleModel}
                        visible
                        errors={errors}
                        handleCancel={handleCancel}
                        handleSubmit={handleSubmit}
                    />}
            </div>
            <CategoryTable columns={columns} />
            <ImportModal
                isOpenModal={isOpenImportModal}
                handleCancel={() => setIsOpenImportModal(false)}
                onSubmit={_onSubmitImport}
            />
            <ModalConfirm ref={modalConfirmRef} />
        </>
    );
};

export default CategoryPage;

type AddCustomerProps = {
    visible?: boolean;
    handleCancel?: () => void;
    handleSubmit?: IFuncSubmit;
    errors?: Record<string, string>;
    titleModel?: any;
};
const AddCustomer = ({ titleModel, visible, handleCancel, handleSubmit }: AddCustomerProps) => {
    const { t: productLang } = useTranslation('products');
    return (
        <Modal
            open={visible}
            onCancel={handleCancel}
            okButtonProps={{ style: { display: 'none' } }}
            width={574}
            footer={null}
            forceRender
            centered
            className='modal-category-service'
        >
            <h2 className='form-row form-title justify-content-center'>
                <p className={styles.title_model}>{titleModel === 'add' ? productLang('AddCategory') : productLang('EditCategory')}</p>
            </h2>
            <Form
                name="basic"
                className="modal-form"
                onFinish={handleSubmit}
                autoComplete="off"
                layout="vertical"
            >
                <div className='is-primary'>
                    <div>
                        <div className={styles.nameCategoryContainer}>
                            <p className={styles.nameCategory}>{productLang('CategoryName')} (*)</p>
                            <Input placeholder={productLang('CategoryName') || ''} className={styles.inputCategory} type="text" />
                        </div>
                        {/* ====================================== */}
                        <SelectColor />
                    </div>
                </div>
                <div className='modal-footer'>
                    <button className='common-btn is-brand' >
                        {productLang('Confirm')}
                    </button>
                </div>
            </Form>
        </Modal>
    );
};

const LoadingPageStyled = styled.div``;