import { useEffect, useState } from 'react';
import { useNavigate, useResolvedPath } from 'react-router-dom';
import { PATH } from '../../../constants/path.enum';
import styles from './index.module.scss';
import { useTranslation } from 'react-i18next';
type BreadcrumbFunc = (pathName: string) => string[];
type MenuConfigType = {
  path: string;
  breadcrumbs: string[] | BreadcrumbFunc;
};
const IMenuConfig = [
  {
    path: PATH.bookings,
    breadcrumbs: ['Bookings'],
    dataTesting: 'bc-booking',
  },
  {
    path: PATH.quick_booking,
    breadcrumbs: ['Bookings','AddBooking'],
    dataTesting: 'bc-add-booking',
  },
  {
    path: PATH.edit_booking,
    breadcrumbs: ['EditBooking'],
    dataTesting: 'bc-edit-booking',

  },
  {
    path: PATH.dashboard,
    breadcrumbs: ['Dashboard'],
    dataTesting: 'bc-dashboard',

  },
  {
    path: PATH.customers,
    breadcrumbs: ['customers'],
    dataTesting: 'bc-customer',

  },
  {
    path: PATH.customersDetail,
    breadcrumbs: (pathname: string) => pathname.split('/'),
    dataTesting: 'bc-customer-detail',

  },
  {
    path: PATH.sales,
    breadcrumbs: ['Sales'],
    dataTesting: 'bc-sale',

  },
  {
    path: PATH.services,
    breadcrumbs: ['Services', 'List'],
    dataTesting: 'bc-service',

  },
  {
    path: PATH.list_product,
    breadcrumbs: ['Products'],
    dataTesting: 'bc-product',

  },
  {
    path: PATH.workingHour,
    breadcrumbs: ['WorkingHour'],
    dataTesting: 'bc-working-hour',

  },
  {
    path: PATH.workingHourDetail,
    breadcrumbs: ['WorkingHour', 'ViewDetails'],
    dataTesting: 'bc-working-hour-detail',

  },
  {
    path: PATH.supplier,
    breadcrumbs: ['Supplier'],
    dataTesting: 'bc-supplier',

  },
  {
    path: PATH.supplier_edit,
    breadcrumbs: (pathname: string) => pathname.split('/'),
    dataTesting: 'bc-supplier-edit',

  },
  {
    path: PATH.brands,
    breadcrumbs: ['Products', 'Brands'],
    dataTesting: 'bc-product-brand',

  },
  {
    path: PATH.product_category,
    breadcrumbs: ['Products', 'Categores'],
    dataTesting: 'bc-product-categories',

  },
  {
    path: PATH.add_product,
    breadcrumbs: ['Products', 'AddNewProduct'],
    dataTesting: 'bc-product-add',

  },
  {
    path: PATH.services,
    breadcrumbs: ['Services'],
    dataTesting: 'bc-services',

  },
  {
    path: PATH.categores,
    breadcrumbs: ['Services', 'Categores'],
    dataTesting: 'bc-categories',

  },
  {
    path: PATH.analytics,
    breadcrumbs: ['analytics'],
    dataTesting: 'bc-analytics',

  },
  {
    path: PATH.invoices,
    breadcrumbs: ['invoices'],
    dataTesting: 'bc-invoices',

  },
  {
    path: PATH.invoicesDetail,
    breadcrumbs: (pathname: string) => pathname.split('/'),
    dataTesting: 'bc-invoice-detail',

  },
  {
    path: PATH.more,
    breadcrumbs: ['more'],
    dataTesting: 'bc-more',

  },
  {
    path: PATH.reviews,
    // breadcrumbs: ['More', 'reviews'],
    breadcrumbs: ['Reviews'],
    dataTesting: 'bc-reviews',

  },
  {
    path: PATH.promotions,
    // breadcrumbs: ['More', 'promotions'],
    breadcrumbs: ['Promotions'],
    dataTesting: 'bc-promotions',

  },
  {
    path: PATH.vouchers,
    // breadcrumbs: ['More', 'vouchers'],
    breadcrumbs: ['Vouchers'],
    dataTesting: 'bc-vouchers',

  },
  {
    path: PATH.locations,
    breadcrumbs: ['Locations'],
    dataTesting: 'bc-locations',

  },
  {
    path: PATH.locations_addnew,
    breadcrumbs: ['Locations', 'AddNew'],
    dataTesting: 'bc-location-add',

  },
  {
    path: PATH.locations_edit,
    breadcrumbs: [ 'Locations', 'edit'],
    dataTesting: 'bc-location-edit',

  },
  {
    path: PATH.analytics_overview,
    // breadcrumbs: [ 'Statistical', 'Overview'],
    breadcrumbs: [ 'Statistical'],
    dataTesting: 'bc-statistical',

  },
  // {
  //   path: PATH.loyaltyProgram,
  //   // breadcrumbs: ['More', 'loyalty program'],
  //   breadcrumbs: ['LoyaltyProgram'],
  // },
  {
    path: PATH.users,
    breadcrumbs: ['Personnel'],
    dataTesting: 'bc-users',

    // breadcrumbs: ['Staff'],
  },
  {
    path: PATH.placeholder,
    breadcrumbs: ['placeholder'],
    dataTesting: 'bc-placeholder',

  },
  // {
  //   path: PATH.settings,
  //   breadcrumbs: ['Settings'],
  // },
  {
    path: PATH.PaymentMethod,
    breadcrumbs: ['PaymentMethods'],
    dataTesting: 'bc-payment-method',

  },
  {
    path: PATH.notification,
    breadcrumbs: ['notification'],
    dataTesting: 'bc-notification',

  },
  {
    path: PATH.editProfile,
    breadcrumbs: ['EditProfile'],
    dataTesting: 'bc-edit-profile',

  },
  {
    path: PATH.checkout,
    breadcrumbs: ['Checkout'],
    dataTesting: 'bc-checkout',

  },
  // {
  //   path: PATH.RolesAndDecentralization,
  //   breadcrumbs: ['RolesDetail'],
  // },
  {
    path: PATH.RolesDetail,
    breadcrumbs: (pathname: string) => pathname.split('/'),
    dataTesting: 'bc-role-detail',

  },
  {
    path: PATH.timeSchedule,
    breadcrumbs: ['TimeSchedule'],
    dataTesting: 'bc-time-schedule',

  },
  {
    path: PATH.loyaltyProgram,
    breadcrumbs: ['loyaltyProgram'],
    dataTesting: 'bc-loyalty-program',

  },
  {
    path: PATH.workingHour,
    breadcrumbs: ['workingHour'],
    dataTesting: 'bc-working-hour',

  },
  {
    path: PATH.workingHourDetail,
    breadcrumbs: ['workingHour', 'workingHourDetail'],
    dataTesting: 'bc-working-hour-detail',

  },
  {
    path: PATH.setting_booking,
    breadcrumbs: ['Sidebar.Setting.setting_booking'],
    dataTesting: 'bc-setting-booking',
    viewOnly: true
  },
  {
    path: PATH.setting_customer_link,
    breadcrumbs: ['Sidebar.Setting.setting_customer_link'],
    dataTesting: 'bc-setting-customer-link',
    viewOnly: true
  },
  {
    path: PATH.setting_general,
    breadcrumbs: ['Sidebar.Setting.setting_general'],
    dataTesting: 'bc-setting-general',
    viewOnly: true
  },
  {
    path: PATH.setting_service_charge,
    breadcrumbs: ['Sidebar.Setting.setting_service_charge'],
    dataTesting: 'bc-setting-service-charge',
    viewOnly: true
  },
  {
    path: PATH.setting_closed_period,
    breadcrumbs: ['Sidebar.Setting.setting_closed_period'],
    dataTesting: 'bc-setting-closed-period',
    viewOnly: true
  },
  {
    path: PATH.setting_notification,
    breadcrumbs: ['Sidebar.Setting.setting_notification'],
    dataTesting: 'bc-setting-notification',
    viewOnly: true
  },
  {
    path: PATH.setting_cancellation,
    breadcrumbs: ['Sidebar.Setting.setting_cancellation'],
    dataTesting: 'bc-setting-cancellation',
    viewOnly: true
  },
  {
    path: PATH.setting_auto_message,
    breadcrumbs: ['Sidebar.Setting.setting_auto_message'],
    dataTesting: 'bc-setting-auto-messages',
    viewOnly: true
  },
  {
    path: PATH.setting_role,
    breadcrumbs: ['Sidebar.Setting.setting_role'],
    dataTesting: 'bc-setting-role',
    viewOnly: true
  },
];

/**
 * Handle get list paths
 * @param pathname 
 * @returns 
 */
const getListPaths = (pathname: string) => pathname?.split('/') ?? [];

/**
 * Handle breadcrumbs
 * @returns 
 */
const Breadcrumbs = () => {
  const { t: commonLang } = useTranslation('common');
  // @ts-ignore
  const pathname = useResolvedPath().pathname;
  const [breadcrumbsList, setBreadcrumbsList] = useState<string[]>([]);
  const [isViewOnly, setIsViewOnly] = useState<boolean>();
  const [dataTesting, setDataTesting] = useState<string>();
  const navigate = useNavigate();

  /**
   * Handle finding breadcrumb item
   * @param item 
   * @returns 
   */
  const findingBreadcrumbItem = (item: MenuConfigType) => {
    // case with static path
    if (item.path === pathname) return true;

    // case with dynamic path
    if (
      pathname?.includes(item.path) &&
      getListPaths(pathname).length === getListPaths(item.path).length
    )
      return true;

    return false;
  };

  /**
   * Handle get breadcrumbs
   * @param breadcrumbs 
   * @returns 
   */
  const getBreadcrumbs = (
    breadcrumbs: MenuConfigType['breadcrumbs']
  ): string[] => {
    return typeof breadcrumbs === 'function'
      ? breadcrumbs(pathname.replace('/private/', ''))
      : breadcrumbs;
  };

  useEffect(() => {
    if (pathname !== '') {
      const currentBreadcrumbs = IMenuConfig.find(findingBreadcrumbItem);

      if (currentBreadcrumbs) {
        const breadcrumbs: string[] = getBreadcrumbs(
          currentBreadcrumbs.breadcrumbs
        );
        setBreadcrumbsList(breadcrumbs);
        setIsViewOnly(currentBreadcrumbs.viewOnly);
        setDataTesting(currentBreadcrumbs.dataTesting);
      }
    }
  }, [pathname]);

  /**
   * Handle breadcrumb click
   */
  const handleBreadcrumbClick = () => {
    navigate(`/private/${getListPaths(pathname.replace('/private/', ''))[0]}`);
  };

  return (
    <ol className={styles.breadcrumbs}>
      {breadcrumbsList &&
        breadcrumbsList.map((breadcrumb, index) => {
          // return <li key={index}>{commonLang(`Breadcrumb.${breadcrumb.toLocaleLowerCase()}`)}</li>;
          return (
            //@ts-ignore
            <li key={index} data-bc-testing={dataTesting} onClick={() => isViewOnly ? undefined : handleBreadcrumbClick()}>
              {commonLang(`${breadcrumb}`)}
            </li>
          );
        })}
    </ol>
  );
};

export default Breadcrumbs;
