import { IconRemove } from 'features/checkout/components/FeeButton';
import checkoutHook from 'features/checkout/hook/checkoutHook';
import checkoutActions from 'features/checkout/services/actions';
import checkoutSelectors from 'features/checkout/services/selectors';
import { EPaymentMethod } from 'features/sales/services/constants';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from 'store/hooks';
import { formatMoney } from 'utils/unit';

/**
 * Card result row
 * @param viewOnly 
 * @returns 
 */
const CardResultRow = ({viewOnly} : {viewOnly: boolean}) => {

  const card = checkoutSelectors.getPurchaseCurrentCard() ?? 0;
  const cash = checkoutSelectors.getPurchaseCurrentCash() ?? 0;
  const [method] = checkoutHook.usePaymentMethod();
  const {t: bookingLang} = useTranslation('booking');
  const dispatch = useAppDispatch();
  

  /**
   * On remove
   */
  const onRemove = () => {
    dispatch(checkoutActions.setPurchaseCard(0));
    if(cash > 0) {
      dispatch(checkoutActions.setPaymentMethod(EPaymentMethod.CASH));
    }
  };
  

  return (
    <div>
      {method === EPaymentMethod.CARD && card > 0 && 
      <p className='extra-price-row'>
        <div className='label'>
        {!viewOnly &&
          <button type='button' onClick={onRemove}><IconRemove /></button>}
          {bookingLang('Card')}
        </div>
    
        <span> {formatMoney(card)}</span>
      </p>}
  </div>
  );
};

export default CardResultRow;