import { Form, Input } from 'antd';
import invoiceSelectors from 'features/invoices/services/selectors';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { getFormatSingeDate } from 'utils/moment/getSingleDateShortFormat';

const BusinessDetails = () => {
  const [form] = Form.useForm();
  const data = invoiceSelectors.detailInvoice.getData();
  const { t: dateLang } = useTranslation('date');
  useEffect(() => {
    form.setFieldsValue({
      locationName: data?.location.name ?? '',
      address: data?.location.address,
      contactNumber: data?.location.contact_number,
      abn: data?.location.abn,
      bookingDate: getFormatSingeDate(data?.date_book ?? '', dateLang),
      nameCustomer: data?.customer?.name,
      phoneCustomer: data?.customer?.phone_number
    });
  }, [data]);
  
  const {t: invoiceLang} = useTranslation('invoice');

  return (
    <>
      <div className="common-card">
        <h2 className="card-title">{invoiceLang('BusinessDetails')}</h2>
        <div className="card-body">
          <Form
            data-form-testing='form-business'
            form={form}
            layout="vertical"
            className='business-form-invoice'
          >
            <div className="form-row">
              <Form.Item label={invoiceLang('LocationName')} name="locationName">
                <Input value={form.getFieldValue('locationName')} disabled />
              </Form.Item>
            </div>
            <div className="form-row">
              <Form.Item label={invoiceLang('BookingDate')} name="bookingDate">
                <Input value={form.getFieldValue('bookingDate')} disabled />
              </Form.Item>
            </div>

            <div className="form-row">
              <Form.Item label={invoiceLang('CustomerName')} name="nameCustomer">
                <Input value={form.getFieldValue('nameCustomer')} disabled />
              </Form.Item>
            </div>

            <div className="form-row">
              <Form.Item label={invoiceLang('PhoneNumber')} name="phoneCustomer">
                <Input value={form.getFieldValue('phoneCustomer')} disabled />
              </Form.Item>
            </div>

          </Form>
        </div>
      </div>
    </>
  );
};

export default BusinessDetails;
