
interface ButtonFavoriteProps {
  isFavourite?: boolean;
  handleSwitchFavourite?: () => void;
}

/**
 * Button favorite
 * @param isFavourite, handleSwitchFavourite 
 * @returns 
 */
const ButtonFavorite = ({ isFavourite, handleSwitchFavourite }: ButtonFavoriteProps) => {
  return (
    <li
      onClick={handleSwitchFavourite}
      className={`action-favorite ${isFavourite && 'is-active'}`}
    ></li>
  );
};

export default ButtonFavorite;
