import fetch from 'services/request';
import { IApiChangePasswordBody, IApiLoginBody, IApiLoginConfirmBody, IApiRegisterBody } from './types/login';

export const login = (body: IApiLoginBody) => {
  return fetch({
    method: 'post',
    url: 'api/v1/auth/login',
    body,
  });
};

export const register = (body: IApiRegisterBody) => {
  return fetch({
    method: 'post',
    url: 'api/v1/auth/register',
    body,
  });
};

export const loginConfirm = (body: IApiLoginConfirmBody) => {
  return fetch({
    method: 'post',
    url: 'api/v1/auth/login-confirm',
    body,
  });
};

export const logout = () => {
  return fetch({
    method: 'post',
    url: 'api/v1/auth/logout',
  });
};

export const forgotPassword = (email: string) => {
  return fetch({
    method: 'post',
    url: 'api/v1/auth/forgot-password',
    body: { email },
  });
};

const changePassword = (body: IApiChangePasswordBody) => {
  return fetch({
    method: 'post',
    url: 'api/v1/auth/change-password',
    body
  });
};

const refreshToken = (token: string) => {
  return fetch({
    method: 'post',
    url: 'api/v1/auth/refresh-token',
    body: { token },
  });
};

// api here
const apisAuth = {
  logout,
  login,
  loginConfirm,
  forgotPassword,
  refreshToken,
  changePassword,

  register
};

export default apisAuth;
