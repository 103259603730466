import storage from 'utils/sessionStorage';

const merchantCode: string = storage.merchantCode.get();
const merchantName: string = storage.merchantName.get();


export const PATH = {
  bookings: '/private/bookings',
  quick_booking: '/private/bookings/quick-booking',
  edit_booking: '/private/bookings/edit-booking',
  checkout: '/private/bookings/checkout/',
  sales: '/private/sales',
  customers: '/private/customers',
  customersDetail: '/private/customers/',
  customersDetail_v1: '/private/customers_v1/',
  services: '/private/services',
  brands: '/private/brands',
  supplier: '/private/supplier',
  supplier_edit: '/private/supplier/',
  categores: '/private/categores',
  product_category: '/private/product-category',
  list_product: '/private/list-product',
  add_product: '/private/list-product/',
  analytics: '/private/dashboards',
  analytics_overview: '/private/statistical/overview',
  dashboard: '/private/dashboard',
  invoices: '/private/invoices',
  invoicesDetail: '/private/invoices/',
  more: '/private/more',
  reviews: '/private/reviews',
  promotions: '/private/promotions',
  vouchers: '/private/vouchers',
  locations: '/private/locations',
  locations_addnew: '/private/locations/add-new',
  locations_edit: '/private/locations/edit/',
  loyaltyProgram: '/private/loyalty-program',
  users: '/private/users',
  workingHour: '/private/working-hour',
  unsubcribedEmail: '/unsubcribed-email',
  timeSchedule: '/private/time-schedule',
  workingHourDetail: '/private/working-hour/detail',
  placeholder: '/private/placeholder',
  settings: '/private/settings',
  PaymentMethod: '/private/payment-methods',
  editProfile: '/private/edit-profile',
  notification: '/private/notification',
  sign_in: '/sign-in',
  team_check_in: '/staff-check-in',
  booking_online: `/${merchantName.replaceAll(' ','-')}/booking-form/${merchantCode}`.toLowerCase(),
  // check_in: `/${merchantName.replaceAll(' ','')}/checkin/${merchantCode}/2`,
  RolesAndDecentralization: '/private/RolesDetail',
  RolesDetail: '/private/RolesDetail/',


  setting_booking: '/private/settings/booking',
  setting_customer_link: '/private/settings/customer-link',
  setting_general: '/private/settings/general',
  setting_service_charge: '/private/settings/service-charge',
  setting_closed_period: '/private/settings/closed-period',
  setting_notification: '/private/settings/notification',
  setting_cancellation: '/private/settings/cancellation',
  setting_auto_message: '/private/settings/auto-message',
  setting_role: '/private/settings/role',
};


export const IMenuConfig = [
  {
    path: PATH.booking_online,
    breadcrumbs: ['BookingOnline'],
  },
  {
    path: PATH.team_check_in,
    breadcrumbs: ['TeamCheckIn'],
  },
  {
    path: PATH.sign_in,
    breadcrumbs: ['SignIn'],
  },
  {
    path: PATH.bookings,
    breadcrumbs: ['Bookings'],
  },
  {
    path: PATH.quick_booking,
    breadcrumbs: ['AddBooking'],
  },
  {
    path: PATH.edit_booking,
    breadcrumbs: ['EditBooking'],
  },
  {
    path: PATH.sales,
    breadcrumbs: ['Sales'],
  },
  {
    path: PATH.customers,
    breadcrumbs: ['Customers'],
  },
  {
    path: PATH.customersDetail,
    breadcrumbs: (pathname: string) => pathname.split('/'),
  },
  {
    path: PATH.services,
    breadcrumbs: ['Services'],
  },
  {
    path: PATH.analytics,
    breadcrumbs: ['Analytics'],
  },
  {
    path: PATH.invoices,
    breadcrumbs: ['Invoices'],
  },
  {
    path: PATH.invoicesDetail,
    breadcrumbs: (pathname: string) => pathname.split('/'),
  },
  {
    path: PATH.more,
    breadcrumbs: ['more'],
  },
  {
    path: PATH.reviews,
    breadcrumbs: ['Reviews'],
  },
  {
    path: PATH.promotions,
    breadcrumbs: ['Promotions'],
  },
  {
    path: PATH.vouchers,
    breadcrumbs: ['Vouchers'],
  },
  {
    path: PATH.locations,
    breadcrumbs: ['Locations'],
  },
  {
    path: PATH.locations_addnew,
    breadcrumbs: ['Locations', 'AddNew'],
  },
  {
    path: PATH.locations_edit,
    breadcrumbs: ['Locations', 'Edit'],
  },
  {
    path: PATH.loyaltyProgram,
    breadcrumbs: ['LoyaltyProgram'],
  },
  {
    path: PATH.users,
    breadcrumbs: ['Users'],
  },
  {
    path: PATH.timeSchedule,
    breadcrumbs: ['TimeSchedule'],
  },
  {
    path: PATH.workingHour,
    breadcrumbs: ['WorkingHour'],
  },
  {
    path: PATH.workingHourDetail,
    breadcrumbs: ['WorkingHour', 'ViewDetails'],
  },
  // {
  //   path: PATH.categories,
  //   breadcrumbs: ['Service Categories'],
  // },
  {
    path: PATH.placeholder,
    breadcrumbs: ['Placeholder'],
  },
  {
    path: PATH.settings,
    breadcrumbs: ['Settings'],
  },
  {
    path: PATH.notification,
    breadcrumbs: ['Notification'],
  },
  {
    path: PATH.editProfile,
    breadcrumbs: ['EditProfile'],
  },
  {
    path: PATH.unsubcribedEmail,
    breadcrumbs: ['UnsubcribedEmail'],
  }, 
  {
    path: PATH.setting_booking,
    breadcrumbs: ['Settings'],
  },
  {
    path: PATH.setting_customer_link,
    breadcrumbs: ['Settings'],
  },
  {
    path: PATH.setting_general,
    breadcrumbs: ['Settings'],
  },
  {
    path: PATH.setting_service_charge,
    breadcrumbs: ['Settings'],
  },
  {
    path: PATH.setting_closed_period,
    breadcrumbs: ['Settings'],
  },
  {
    path: PATH.setting_notification,
    breadcrumbs: ['Settings'],
  },
  {
    path: PATH.setting_cancellation,
    breadcrumbs: ['Settings'],
  },
  {
    path: PATH.setting_auto_message,
    breadcrumbs: ['Settings'],
  },
  {
    path: PATH.setting_role,
    breadcrumbs: ['Settings'],
  },
];