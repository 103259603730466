import { message } from 'antd';
import { IMessageProps } from '../type';
import { translate } from 'utils/moment/getSingleDateFormat';

/**
 * Handle custom warning message
 * @param props 
 * @returns 
 */
const CustomWarningMessage = (props: IMessageProps) => {
  const _text = typeof props === 'string' ? props : props?.text;
  return message.warning({
    className: 'is-warning',
    content: (
      <p>
        <span>{translate('common', 'message.warning')}:</span>
        {_text}
      </p>
    ),
  });
};

export default CustomWarningMessage;
