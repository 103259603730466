import { ClockCircleOutlined } from '@ant-design/icons';
import { DndContext, MouseSensor, TouchSensor, closestCenter, useSensor, useSensors } from '@dnd-kit/core';
import { Spin } from 'antd';
import message from 'components/common/Message';
import { CalendarViewType, EBookingStatus } from 'constants/index';
import SYSTEM_VARIABLES from 'constants/variables';
import bookingSelectors from 'features/bookings/services/selectors';
import moment from 'moment';
import { memo, useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import { DraggableOverlay } from '../Table/DnD/DraggableOverlay';
import HeaderCalendar from '../Table/HeaderCalendar';
import RowCalendar, { IDndDataCalendar } from '../Table/RowCalendar';
import { ICalendarBookingHeader as _ICalendarBookingHeader } from '../Table/types';
import useMappingCalendar from '../Table/useMappingCalendar';
import useUpdateDateView from '../Table/useUpdateDateView';
import useUpdateMemberView from '../Table/useUpdateMemberView';
import { useNavigate } from 'react-router-dom';
import ModalConfirmOTP, { ModalConfirmOTPRef } from 'components/common/Modal/ModalOTP';
import bookingActions from 'features/bookings/services/actions';
import { useAppDispatch } from 'store/hooks';
import useCalendarMobileFilter from '../../BookingFilter/BookingFilterMobile/hooks/useCalendarMobileFilter';

export type ICalendarBookingHeader<Modal = any> =
  _ICalendarBookingHeader<Modal>;

/**
 * Render Calendar Booking Table
 * @props calendarBookingRef 
 * @returns 
 */
const CalendarBookingTable = ({calendarBookingRef}: {calendarBookingRef: any}) => {
  const schema = useCalendarMobileFilter();
  const data = bookingSelectors.getBookings();
  const loading = bookingSelectors.loadingBookings();
  const [rows, rowsSpanInfo] = useMappingCalendar(schema, data);
  const isSingleCol = schema.isSingleCol ?? false;
  const [updatingDateView, updateDateView] = useUpdateDateView();
  const [updatingMemberView, updateMemberView] = useUpdateMemberView();
  const loadingBooking = updatingDateView || updatingMemberView;
  const viewType = bookingSelectors.getCalendarViewType();
  const dateStore = bookingSelectors.getCalendarParamValue('date') as number;
  const pixelPerMinute = bookingSelectors.getPixelPerMinute();

  // const calendarBookingRef = useRef<HTMLDivElement>(null);
  const isScrolled = useRef<boolean>(false);
  const activeLocation = bookingSelectors.getCalendarCurrentLocation();
  const workingHour = bookingSelectors.getCalendarWorkingHour();
  const navigate = useNavigate();

  const merchant_location_id = bookingSelectors.getCalendarParamValue('merchant_location_id');
  const modalConfirmOTPRef = useRef<ModalConfirmOTPRef>(null);
  const realTimeRef = useRef<any>(null);
  const dispatch = useAppDispatch();
  const loadingFilterOption = bookingSelectors.getLoadingOptionFilter();
  const loadingParamOptionCalendar = bookingSelectors.getParamOptionCalendar();

  const isNow = useMemo(() => {
    const _date = moment(dateStore);
    const today = moment();
    return _date.isSame(today, 'day');
  }, [dateStore]);
  const mouseSensor = useSensor(MouseSensor, {
    // Require the mouse to move by 10 pixels before activating
    activationConstraint: {
      distance: 10,
    },
  });
  const touchSensor = useSensor(TouchSensor, {
    // Press delay of 250ms, with tolerance of 5px of movement
    activationConstraint: {
      delay: 500,
      tolerance: 8,
    },
  });

  const sensors = useSensors(mouseSensor, touchSensor,);

  const getStatusDraftLabel = (status: string) => {
    switch (status) {
      case EBookingStatus.CANCELLED: return 'cancelled';
      case EBookingStatus.NO_SHOW: return 'no-show';
      case EBookingStatus.IN_PROGRESS: return 'in progress';
      default: return '';
    }
  };

  const checkScroll = () => {
    // if (isScrolled.current) return;
    const minutes = (workingHour?.open ?? 0) * 60 + (workingHour?.openMinute ?? 0);
    setTimeout(() => calendarBookingRef.current?.scrollTo({ top: minutes * pixelPerMinute, behavior: 'smooth' }), 0);
    isScrolled.current = true;
  };

  useEffect(() => {
    if (!activeLocation) return;
    !isNow && checkScroll();
  }, [activeLocation]);

  // Realtime Line
  const RealTimeLine = ({
    workingHour,
    distanceMinutes,
  }: {
    workingHour: { open: number; close: number };
    distanceMinutes: number;
  }) => {
    const pixelPerMinute = bookingSelectors.getPixelPerMinute();
    const [currentTime, setCurrentTime] = useState<string>(moment().format());
    const _workingHour = useMemo(() => {
      const open = moment().set({ hour: workingHour.open, minute: 0, second: 0 });
      const close = moment().set({
        hour: workingHour.close,
        minute: 0,
        second: 0,
      });
      return {
        open,
        close,
      };
    }, [workingHour]);

    useEffect(() => {

      const time = setInterval(() => {
        const { close, open } = _workingHour;
        const now = moment().set({ second: 0 });
        if (now.isBetween(open, close)) {
          setCurrentTime(now.format());
        } else {
          clearInterval(time);
        }
      }, 30000);
      return () => {
        clearInterval(time);
      };
    }, [_workingHour]);

    const top = useMemo(() => {
      const { close, open } = _workingHour;
      const now = moment(currentTime);
      const currentSpaceMinutes = now.diff(open, 'minutes');

      // const selector = useAppSelector(state => state.booking.hasScrolled);
      // if (selector === false) {
      //   setTimeout(() => calendarBookingRef.current?.scrollTo({ top: currentSpaceMinutes * pixelPerMinute, behavior: 'smooth' }), 0);
      //   dispatch(bookingActions.setHasScrolled(true));
      // }   
      let space = 0;
      if (now.isBetween(open, close))
        space =  pixelPerMinute * (1 + currentSpaceMinutes);

      return space;
    }, [_workingHour, distanceMinutes, currentTime, pixelPerMinute]);

    if (!top) return null;

    useEffect(() => {
      if(data.length === 0) {
      const { open } = _workingHour;
        const currentSpaceMinutes = moment(currentTime).diff(open, 'minutes');        
      setTimeout(() => calendarBookingRef.current?.scrollTo({ top: currentSpaceMinutes * pixelPerMinute, behavior: 'smooth' }), 0);
        dispatch(bookingActions.setHasScrolled(true));
      }
    }, []);

    return (
      <div ref={realTimeRef} className='realtime-line' style={{ top }}>
        <div className='circle left'></div>
        <div className='line'></div>
        <div className='circle'></div>
      </div>
    );
  };

  return (
    <WrapCalendarBookingStyled>
      {(loading || loadingFilterOption || loadingParamOptionCalendar) && (
        <div className='loading-overLay'>
          <Spin />
        </div>
      )}
      {loadingBooking && (
        <div className='loading-overLay'>
          <Spin />
        </div>
      )}
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragEnd={(e) => {
          const book = data.find(o => String(e.active.id ?? '').includes(o?.id?.toString()));
          if (!book || !e.over?.data.current) return;
          switch (book.book_status) {
            case EBookingStatus.DRAFT:
            case EBookingStatus.OPEN: {
              if (viewType === CalendarViewType.MemberView)
                return updateMemberView(
                  {
                    serviceId: e.active?.data?.current?.serviceId,
                    service_variant_id: e.active?.data?.current?.service_variant_id,
                  },
                  book, e.over?.data.current as IDndDataCalendar);
              return updateDateView(book, e.over?.data.current as IDndDataCalendar);
            }
            case EBookingStatus.IN_PROGRESS: {
              if (viewType === CalendarViewType.MemberView) {
                if (!e.active.data.current?.serviceId)
                  return navigate(`/private/bookings/edit-booking?bookId=${e?.active?.data?.current?.bookingId}&merchant_location_id=${merchant_location_id}`);
                else {
                  if (!e?.active?.data?.current?.assigned_employee?.employee) {
                    updateDateView(book, e.over?.data.current as IDndDataCalendar);
                  } else {
                    modalConfirmOTPRef.current?.show({
                      title: 'Enter pin number',
                      msg: '',
                      submit: async ({ pin }: { pin: string }) => {
                        updateDateView(book, e.over?.data.current as IDndDataCalendar, pin);
                      }
                    });
                  }
                  return;
                }
              }
              else if (viewType === CalendarViewType.DaysView && !e.active.data.current?.serviceId) {
                return message.info('Please select a service to choose a member');
              }

              return message.warning('We cannot be re-scheduled ' + getStatusDraftLabel(book.book_status));
            }
            default: {
              return message.warning('We cannot be re-scheduled ' + getStatusDraftLabel(book.book_status));
            }
          }
        }}
      >
        <CalendarBookingStyled ref={calendarBookingRef} distance={schema.distanceMinute} pixelPerMinute={pixelPerMinute} className='CalendarBookingStyled'>
          <table className={`table-overflow ${viewType}`}>
            <thead>
              <tr>
                <th className='time'>
                  <div className='col-table'>
                    <ClockCircleOutlined style={{ fontSize: '23px' }} />
                  </div>
                </th>
                {schema.headers.map((item) => {
                  if (typeof schema.renderHeader === 'function') {
                    return (
                      <th
                        key={item.id}
                        className={item.id === SYSTEM_VARIABLES.UNASSIGNED ? SYSTEM_VARIABLES.UNASSIGNED : ''}
                      >
                        <div className='col-table'>
                          {schema.renderHeader(item)}
                        </div>
                      </th>
                    );
                  }
                  return <HeaderCalendar key={item.id} label={item.label} />;
                })}
                {/* <th className='row-span' ><div className='col-table' /></th> */}
              </tr>
            </thead>
            <tbody>
              {rows.map((row) => (
                <RowCalendar
                  key={row.id}
                  data={row}
                  rowsSpanInfo={rowsSpanInfo}
                  isSingleCol={isSingleCol}
                  distance={schema.distanceMinute}
                />
              ))}
              {isNow && <RealTimeLine
                distanceMinutes={schema.distanceMinute}
                workingHour={{
                  open: schema.timeHourStart,
                  close: schema.timeHourEnd,
                }}
              />}
            </tbody>
          </table>
        </CalendarBookingStyled>
        <DraggableOverlay data={data} />
      </DndContext>
      <ModalConfirmOTP ref={modalConfirmOTPRef} />
    </WrapCalendarBookingStyled >

  );
};

export default memo(CalendarBookingTable);

const heightRow = 92;

const WrapCalendarBookingStyled = styled.div`
  position: relative;
  .loading-overLay {
    background-color: rgba(255, 255, 255, 0.5);
    position: absolute;
    inset: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 99999;
  }
`;
type CalendarBookingStyledProps = {
  pixelPerMinute: number;
  distance: number;
};
const CalendarBookingStyled = styled.div`
  position: relative;
  max-height: 75vh;
  z-index: 1;
  width: 100%;
  overflow: scroll;
  @media only screen and (max-width: 767.98px) {
    margin-bottom: 68px;
  }
  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #c3c2e0;
  }
  .table-overflow {
    min-width: 100%;
    tbody {
      position: relative;
      .realtime-line {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        z-index: 11;
        display: flex;
        align-items: center;
        .line {
          height: 2px;
          background: #e95060;
          width: 100%;
        }
        .circle {
          width: 10px;
          height: 10px;
          background: #e95060;
          border-radius: 30px;
          &.left {
            position: sticky;
            left: 0;
            z-index: 11;
          }
        }
      }
    }
  }
  tr:first-child th {
    div:not(.item-inner) {
      display: grid;
      /* flex-wrap: wrap; */
      align-items: center;
      justify-content: center;
      justify-items: center;
    }
  }
  td,
  th {
    color: var(--color-primary);
    text-align: center;
    vertical-align: middle;
    height: ${heightRow}px;

    &.row-span {
      width:100%;
    }

    // @media only screen and (max-width: 767.98px) {
    //   height: 50px;
    // }

    .col-table {
      border: 1px solid var(--color-white);
      height: 100%;
      width: 100%;
      padding: 5px 0px;
      &.MemberView {
        padding:0px;
      }
      vertical-align: middle;
      font-size: 16px;
      .singleCol,
      &.singleCol {
        width: 250px;
      }
      &.flex-center {
        display: flex;
        align-items: center;
        justify-content: center;
      }
      &.isBreakTime {
        position: relative;
        overflow: hidden;
        .frameBreakingTime {
          position: absolute;
          inset: 0;
          z-index:-1;
          display: flex;
          flex-direction: column;
          .breakingTimeOverlay {
            height: 100%;
            /* height:${({ distance = 0, pixelPerMinute }: CalendarBookingStyledProps) => (distance * pixelPerMinute) - 1}px; */
            border-bottom: 1px solid var(--color-white);
          }
        }
      }

      @media only screen and (max-width: 767.98px) {
        font-size: 12px;
      }
    }
    .wrap {
      height: 100%;
      width: 100%;
      overflow:hidden;
      // transform: translate(5px,5px);
      // padding-top:5px;
      // padding-left:5px;
      padding-right: 5px;

      &.first {
        padding-left: 0px;
      }
    }
    .subtext {
      display: block;
      width: 100%;
      text-align: center;
      font-weight: 400;
      font-size: 14px;
      line-height: 140%;
      color: var(--color-primary);
      opacity: 0.6;
    }
    &.time {
      min-width: 100px;
      width: 100px;
      min-height: 60px;
      position: sticky;
      left: 0;
      background: #eef0f6;
      z-index: 10;
      @media only screen and (max-width: 767.98px) {
        min-width: 50px;
        min-height: 40px;
        height: 40px;
      }
    }
    &.${SYSTEM_VARIABLES.UNASSIGNED} {
      /* position: sticky; */
      left: 100px;
      z-index: 10;
      background: #eff1f6;
      /* @media only screen and (max-width: 767.98px) {
        display: block;
      } */
    }
  }
  th {
    min-width: 170px;
    height: 60px;
    position: sticky;
    top: 0;
    background: #eef0f6;
    z-index: 12 !important;
    @media only screen and (max-width: 767.98px) {
      min-width: 120px;
    }
    &.unassigned {
      @media only screen and (max-width: 767.98px) {
        // min-width: 76px;
        /* display: block; */
        left: 0 !important;
      }
    }
  }
  th.time {
    z-index: 14 !important;
  }
  th.${SYSTEM_VARIABLES.UNASSIGNED} {
    left: 100px;
    /* z-index: 14 !important; */
    background: #eff1f6;
  }
  td, th {
    /* height:${({ distance = 0, pixelPerMinute }: CalendarBookingStyledProps) => distance * pixelPerMinute}px; */
    /* height: 105px; */
    .wrap {
      padding-top:0px;
    }
  }  
`;
