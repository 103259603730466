import { Modal } from 'antd';
import message from 'components/common/Message';
import { get, set } from 'lodash';
import { useEffect, useState, useTransition } from 'react';
import { useSetLoadingPage } from 'services/UI/LoadingPage';
import { useAppDispatch } from 'store/hooks';
import voucherActions from '../services/actions';
import vouchersApis from '../services/apis';
import VoucherForm, { IVoucherFormValue, VoucherFormProps } from './VoucherForm';
import getMappingDataForm from './hooks/getMappingDataForm';
import voucherSelectors from '../services/selectors';

type EditVoucherProps = {
  open: boolean;
  handleClose: () => void;
};

function EditVoucher({ open, handleClose }: EditVoucherProps) {
  const [formData, setFormData] = useState<IVoucherFormValue>();
  // const [isModalOpen, setIsModalOpen] = useState(false);
  const dispatch = useAppDispatch();
  const setPageLoading = useSetLoadingPage();
  const [errors, setErrors] = useState<any>({});
  const [, startTransition] = useTransition();
  const view = voucherSelectors.getLstVoucherParams().view;
  const loading = voucherSelectors.getLoadingVoucherDetail();
  const voucherDetail = voucherSelectors.getVoucherDetail();


  useEffect(() => {

    if (voucherDetail){
      startTransition(() => {
        const _dataMapping = getMappingDataForm(voucherDetail, view);
        setFormData(_dataMapping);
      });
    }
  }, [voucherDetail]);


  // const hideModal = () => {
  //   setFormData(undefined);
  //   setIsModalOpen(false);
  // };

  const handleSubmit: VoucherFormProps['onSubmit'] = async (payload) => {
    setPageLoading(true);
    try {
      const res = await vouchersApis.editVoucher(voucherDetail?.id?.toString(), {
        ...payload,
        voucher_code: voucherDetail.voucher_code
      });
      const msg = get(res, 'data.message');
      if (msg) {
        message.success(msg);
        dispatch(voucherActions.setVoucherListParams.fetch({}));
        handleClose();
      } else {
        const errorMsg = get(res, 'data.error.message');
        if (errorMsg) message.error(errorMsg);
        else throw 'fail';
      }

    } catch (error) {
      const errors = Object.entries(get(error, 'response.data.errors', {}));

      if (errors.length > 0) {
        const _errors = {};
        errors.map(([key, valueArr]) => {
          const msg = get(valueArr, [0], '');
          message.error(msg);
          set(_errors, [key], msg);
        });
        setErrors(_errors);
        return;
      }
      message.error('An error occurred. Please try again');
    } finally {
      setPageLoading(false);
    }
  };

  return (
    <>
      {/* <li onClick={showModal} className='action-edit'></li> */}
      <ModalForm errors={errors} formData={formData} visible={open} onCancel={handleClose} onsubmit={handleSubmit} loading={loading} />
    </>
  );
}

export default EditVoucher;

type ModalFormProps = {
  visible?: boolean;
  onCancel?: () => void;
  onsubmit?: VoucherFormProps['onSubmit'],
  formData?: IVoucherFormValue;
  errors?: any;
  loading?: boolean
};

const ModalForm = ({ visible, errors, formData, onCancel = () => undefined, onsubmit = () => undefined, loading }: ModalFormProps) => {
  if (!visible) return null;
  return (
    <Modal
      maskClosable={false}
      open={visible}
      onCancel={onCancel}
      okButtonProps={{ style: { display: 'none' } }}
      width={574}
      footer={null}
      forceRender
      centered
    >
      <h2 className='modal-title'>Edit Voucher</h2>
      <VoucherForm errors={errors} isEdit formData={formData} onCancel={onCancel} onSubmit={onsubmit} loading={loading}/>
    </Modal>
  );
};
