import { IPagination } from 'models/config';
import { IApiTimeScheduleBodyReq } from './api';

export enum ETimeSchedule {
  ON = 'working_day',
  CLOSED_DAY = 'closed_day',
  HOLIDAY_OFF = 'holiday_off',
  DAY_OFF = 'day_off',
}

export enum TimeOffModalType {
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
}

export type TimingShiftScheduleType = {
  time_start: string;
  time_end: string;
  is_work: boolean;
  is_repeat?: boolean;
  id?: string | number;
};

export type TimingOffScheduleType = {
  id: number;
  is_work?: boolean;
  one_day_leave?: number;
  period_time?: string;
  is_repeat?: false;
  info_day_off?: {
    id: number;
    merchant_employee_id?: number;
    day_off?: string;
    time_start?: string;
    time_end?: string;
    created_at?: string;
    updated_at?: string;
    one_day_leave?: number;
    parent_repeat_id?: number;
    status?: string;
    repeat?: boolean;
    reason?: string;
    approving_manager_id?: number;
    parent_repeat_date?: string;
  };
};

export interface TimingType {
  shift_type: ETimeSchedule;
  date: string;
  day_of_week: string;
  full_day_of_week: string;
  content?: string;
  timing_shifts?: TimingShiftScheduleType[];
  timing_schedules?: TimingShiftScheduleType[];
  timing_busies?: TimingShiftScheduleType[];
  time_off?: TimingOffScheduleType
}

export interface IResTimeScheduleList {
  data: IApiTimeScheduleBodyReq[];
  pagination: IPagination;
}

export interface ILocationTimeOpeningItem {
  weekday: string;
  time_start: string;
  time_end: string;
}

export interface ILocationDateCloseItem {
  start_date: string;
  end_date: string;
  description: null | string;
}

export interface ITimeOffFormValue {
  teamMember?: number | string;
  oneDayLeave?: boolean;
  dayOff?: string;
  startTime?: string;
  endTime?: string;
  repeatCheck?: boolean;
  timeOffRepeatType?: string;
  endRepeatDate?: string;
}

export interface EditShiftInitValue {
  date: string;
  time: {
    text?: string;
    type?: ETimeSchedule;
    is_work?: boolean;
    off_id?: string | number;
    is_repeat?: boolean
  }[];
  merchantEmployeeId: string | number;
  timing_shifts?: TimingShiftScheduleType[]
}

export interface ITimeShiftFormValue {
  dayOff?: string;
  times?: {
    start: string;
    end: string;
    is_work: boolean
    off_id?: string | number
  }[];
  repeatEveryWeek?: boolean;
  updateTimeScheduleCheck?: boolean
}

export interface ITimeTimeOffFormValue {
  teamMember?: string | number;
  dayOff?: string;
  startTime?: string;
  endTime?: string;
}

export enum TimeScheduleRepeatType {
  DAILY = 'daily',
  WEEKLY = 'weekly',
  MONTHLY = 'monthly',
}