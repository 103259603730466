import {
    Checkbox,
    Form,
    Modal,
} from 'antd';
import { IOptionMulti } from 'components/common/MultipleSelect';
import SYSTEM_VARIABLES from 'constants/variables';
import { useEffect, useMemo, useState } from 'react';
import styles from './setUpForm.module.scss';
import serviceSelectors from 'features/services/services/selectors';
import { IconRight, IconPersonnel, IconStatus, IconHoliday, IconLocation,
    IconSettingSetUp, IconAddCustomer, IconAddCalendar, IconPaymentMobile, IconAddService } from 'assets/svg/index';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
export interface IServiceForm {
    service_name?: string;
    category?: number;
    price?: number;
    availableLocation?: number | number[];
    locationTemp: number[];
    newCategory?: string;
    teamMember?: number[];
    teamMemberTemp: number[];
    duration?: number;
    isEnableService?: boolean;
    isEnableBookingLink?: boolean;
    isEnableVoucherSales?: boolean;
    isEnableDiscounts?: boolean;
    isMakeNewCategory?: boolean;
    serviceDescription?: string;
    serviceVariants?: IServiceVariant[];
}

interface IServiceVariant {
    name?: string;
    price?: number;
    duration?: number;
}

type IEmployeeItem = {
    id: string | number;
    title_name: string;
    merchant_location_id: string;
    employee_id: number;
    user: IUser;
};

interface IUser {
    id: number;
    full_name: string;
    email: string;
    phone: string;
}

export type IFuncHandleSubmit = () => void;
interface ServicesFormProps {
    isModalOpen?: boolean;
    handleCancel?: () => void;
    handleSubmit: IFuncHandleSubmit;
    currService?: any;
}

/**
 * Services form
 * @param isModalOpen, handleCancel, currService 
 * @returns 
 */
const ServicesForm = ({
    isModalOpen,
    handleCancel,
    currService,
}: ServicesFormProps) => {
    const [form] = Form.useForm<IServiceForm>();

    const dataForm = serviceSelectors.getShowCreateService();
    const { t: headerLang } = useTranslation('header');
    
    const locations: IOptionMulti[] = useMemo(
        () =>
            (dataForm?.locations as { id: string | number; name: string }[])
                ?.filter((o) => o.id != 99999)
                ?.map((o) => ({ value: o.id, title: o.name })) ?? [],
        [dataForm?.locations]
    );
    const [selectedLocations, setSelectedLocations] = useState<any[]>(
        locations.map(({ value }) => value)
    );

    const employees: IOptionMulti[] = useMemo(
        () =>
            (dataForm?.employees as IEmployeeItem[])
                ?.filter((o) => selectedLocations.includes(o.merchant_location_id))
                ?.map((o) => ({ value: o.id, title: o.user.full_name })) ?? [],
        [dataForm?.employees, selectedLocations]
    );
    const [selectedEmployees, setSelectedEmployees] = useState<any[]>([]);

    useEffect(() => {
        if (!currService) return;
        form.setFieldsValue(currService);
        setSelectedLocations(currService.availableLocation);
        setSelectedEmployees(currService.teamMember);
    }, [currService]);

    useEffect(() => {
        form?.setFieldValue('availableLocation', [...selectedLocations]);
        if (locations.length > 1 && selectedLocations.length === locations.length) {
            form?.setFieldValue('locationTemp', [SYSTEM_VARIABLES.ALL_LOCATIONS]);
        } else {
            form?.setFieldValue('locationTemp', [...selectedLocations]);
        }
    }, [selectedLocations]);

    useEffect(() => {
        form?.setFieldValue('teamMember', [...selectedEmployees]);
        if (employees.length > 1 && selectedEmployees.length === employees.length) {
            form?.setFieldValue('teamMemberTemp', [SYSTEM_VARIABLES.ALL_EMPLOYEES]);
        } else {
            form?.setFieldValue('teamMemberTemp', [...selectedEmployees]);
        }
    }, [selectedEmployees]);
    const [isAccount, setIsAccount] = useState(false);
    const [isStore, setIsStoret] = useState(false);

    const [isBooking, setIsbooking] = useState(false);
    const [isCustomer, setIsCustomer] = useState(false);
    const [isBookingLink, setIsBookingLink] = useState(false);
    const [isHolyday, setIsHolyday] = useState(false);

    /**
     * toggle account
     */
    const toggleAccount = () => {
        setIsAccount(!isAccount);
    };

    /**
     * toggle store
     */
    const togglestore = () => {
        setIsStoret(!isStore);
    };

    /**
     * toggle booking
     */
    const toggleBooking = () => {
        setIsbooking(!isBooking);
    };

    /**
     * toggle customer
     */
    const toggleCustomer = () => {
        setIsCustomer(!isCustomer);
    };

    /**
     * toggle booking link
     */
    const toggleBookingLink = () => {
        setIsBookingLink(!isBookingLink);
    };

    /**
     * toggle holyday
     */
    const toggleHolyday = () => {
        setIsHolyday(!isHolyday);
    };

    const [isCheckedNewAccount, setIsCheckedNewAccount] = useState(false);
    const [isCheckedEmail, setIsCheckedEmail] = useState(false);
    const [isCheckedPhone, setIsCheckedPhone] = useState(false);
    const [completedCount, setCompletedCount] = useState(0);

    /**
     * handle checked new account
     */
    const handleCheckedNewAccount = () => {
        setIsCheckedNewAccount(!isCheckedNewAccount);
        setCompletedCount(isCheckedNewAccount ? completedCount - 1 : completedCount + 1);
    };

    /**
     * handle checked email
     */
    const handleCheckedEmail = () => {
        setIsCheckedEmail(!isCheckedEmail);
        setCompletedCount(isCheckedEmail ? completedCount - 1 : completedCount + 1);
    };

    /**
     * handle checked phone
     */
    const handleCheckedPhone = () => {
        setIsCheckedPhone(!isCheckedPhone);
        setCompletedCount(isCheckedPhone ? completedCount - 1 : completedCount + 1);
    };

    return (
        <SetUpStyled>
            <Modal
                open={isModalOpen}
                onCancel={handleCancel}
                okButtonProps={{ style: { display: 'none' } }}
                closable={false}
                width={1148}
                footer={null}
                className='modal-big-set-up'
                bodyStyle={{
                    maxHeight: '600px',
                    overflowY: 'auto',
                  }}
            >
                <div className={styles.btnContainer}>
                    <button onClick={handleCancel} className={styles.btnCancel}>{headerLang('back')}</button>
                </div>
                <div className={`${styles.containerModal} ${styles.customScrollbar}`}>
                    <div className={styles.contentContainer}>
                        <p className={styles.content_p}>{headerLang('CompleteTheseSimpleStepsToCompleteYourAccountSetupAndBeReadyToUse')}.
                        </p>
                        <IconStatus />
                    </div>
                    <div className={styles.account}>
                        <div className={styles.AccountContainer}>
                            <div onClick={toggleAccount} className={styles.AccountTitle}>
                                <div>
                                    <p className={styles.title}>{headerLang('Account')}</p>
                                    <p className={styles.contentTitle}>{headerLang('Accomplished')} {completedCount} {headerLang('on')} 3</p>
                                </div>
                                <button className={`${styles.rotate_button} ${isAccount ? styles.rotate : ''}`} onClick={toggleAccount}><IconRight /></button>
                            </div>
                            {isAccount && (
                                <div className={styles.contenContainer}>
                                    <div className={styles.progress_container}>
                                        <div className={styles.progress_scroll} style={{ width: `${(completedCount / 3) * 100}%` }} />
                                    </div>
                                    <div className={styles.checkStyle}>
                                        <Checkbox className={styles.custom_checkbox} checked={isCheckedNewAccount} onChange={handleCheckedNewAccount} />
                                        <p className={isCheckedNewAccount ? `${styles.content} ${styles.line_through}` : styles.content}>{headerLang('CreateANewAccount')}</p>
                                    </div>
                                    <div className={styles.checkStyle}>
                                        <Checkbox className={styles.custom_checkbox} checked={isCheckedEmail} onChange={handleCheckedEmail} />
                                        <p className={isCheckedEmail ? `${styles.content} ${styles.line_through}` : styles.content}>{headerLang('VerifyEmailAccount')}</p>
                                    </div>
                                    <div className={styles.checkStyle}>
                                        <Checkbox className={styles.custom_checkbox} checked={isCheckedPhone} onChange={handleCheckedPhone} />
                                        <p className={isCheckedPhone ? `${styles.content} ${styles.line_through}` : styles.content}>{headerLang('VerifyPhoneNumber')}</p>
                                    </div>
                                </div>
                            )}
                        </div>

                        <div onClick={togglestore} className={styles.AccountContainer}>
                            <div className={styles.AccountTitle}>
                                <div>
                                    <p className={styles.title}>{headerLang('SetUpShop')}</p>
                                    <p className={styles.contentTitle}>{headerLang('Accomplished')} {completedCount} {headerLang('on')} 4</p>
                                </div>
                                <button className={`${styles.rotate_button} ${isStore ? styles.rotate : ''}`} onClick={togglestore}><IconRight /></button>
                            </div>
                            {isStore && (
                                <div className={styles.contenContainer}>
                                    <div className={styles.progress_container}>
                                        <div className={styles.progress_scroll} style={{ width: `${(completedCount / 3) * 100}%` }} />
                                    </div>
                                    <div className={styles.storeContainer}>
                                        <div className={styles.checkStyle}>
                                            <IconLocation />
                                            <p className={styles.storeContent}>{headerLang('StoreInformation')} <span>(0/1)</span></p>
                                        </div>
                                        <a href="#">{headerLang('Setting')}</a>
                                    </div>
                                    <div className={styles.storeContainer}>
                                        <div className={styles.checkStyle}>
                                            <IconPersonnel />
                                            <p className={styles.storeContent}>{headerLang('EmployeeSettings')}</p>
                                        </div>
                                        <a href="#">{headerLang('Setting')}</a>
                                    </div>
                                    <div className={styles.storeContainer}>
                                        <div className={styles.checkStyle}>
                                            <IconAddService />
                                            <p className={styles.storeContent}>{headerLang('AddServices')}</p>
                                        </div>
                                        <a href="#">{headerLang('Setting')}</a>
                                    </div>
                                    <div className={styles.storeContainer}>
                                        <div className={styles.checkStyle}>
                                            <IconPaymentMobile />
                                            <p className={styles.storeContent}>{headerLang('PaymentMethods')}</p>
                                        </div>
                                        <a href="#">{headerLang('Setting')}</a>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>

                    <div  className={styles.account}>
                        <p className={styles.experienceTitle}>{headerLang('ExperienceMindHana')}</p>
                        <div onClick={toggleBooking} className={styles.AccountContainer}>
                            <div className={styles.AccountTitle}>
                                <div className={styles.iconTitle}>
                                    <IconAddCalendar />
                                    <p className={styles.titleEx}>{headerLang('MakeAnAppointment')}</p>
                                </div>
                                <button className={`${styles.rotate_button} ${isBooking ? styles.rotate : ''}`} onClick={toggleBooking}><IconRight /></button>
                            </div>
                            {isBooking && (
                                <div className={styles.contenContainer}>
                                    <h1>{headerLang('MakeAnAppointment')}</h1>
                                </div>
                            )}
                        </div>

                        <div onClick={toggleCustomer} className={styles.AccountContainer}>
                            <div className={styles.AccountTitle}>
                                <div className={styles.iconTitle}>
                                    <IconAddCustomer />
                                    <p className={styles.titleEx}>{headerLang('AddCustomers')}</p>
                                </div>
                                <button className={`${styles.rotate_button} ${isCustomer ? styles.rotate : ''}`} onClick={toggleCustomer}><IconRight /></button>
                            </div>
                            {isCustomer && (
                                <div className={styles.contenContainer}>
                                    <h1>{headerLang('AddCustomers')}</h1>
                                </div>
                            )}
                        </div>

                        <div onClick={toggleBookingLink} className={styles.AccountContainer}>
                            <div className={styles.AccountTitle}>
                                <div className={styles.iconTitle}>
                                    <IconSettingSetUp />
                                    <p className={styles.titleEx}>{headerLang('SetUpAppointmentBookingPath')}</p>
                                </div>
                                <button className={`${styles.rotate_button} ${isBookingLink ? styles.rotate : ''}`} onClick={toggleBookingLink}><IconRight /></button>
                            </div>
                            {isBookingLink && (
                                <div className={styles.contenContainer}>
                                    <h1>{headerLang('SetUpAppointmentBookingPath')}</h1>
                                </div>
                            )}
                        </div>

                        <div onClick={toggleHolyday} className={styles.AccountContainer}>
                            <div className={styles.AccountTitle}>
                                <div className={styles.iconTitle}>
                                    <IconHoliday />
                                    <p className={styles.titleEx}>{headerLang('Holidays')}</p>
                                </div>
                                <button className={`${styles.rotate_button} ${isHolyday ? styles.rotate : ''}`} onClick={toggleHolyday}><IconRight /></button>
                            </div>
                            {isHolyday && (
                                <div className={styles.contenContainer}>
                                    <h1>{headerLang('Holidays')}</h1>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </Modal>
        </SetUpStyled>
    );
};

export default ServicesForm;

const SetUpStyled = styled.div`
    .ant-modal {
        .ant-modal-close {
            display: none !important;
        }
    } 
`;