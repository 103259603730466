const IconPickList = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="33"
      height="32"
      viewBox="0 0 33 32"
      fill="none"
    >
      <path
        d="M27.6136 16L16.6136 19L5.61365 16V4.60969C5.61365 3.72069 6.33427 3 7.22327 3H26.004C26.893 3 27.6136 3.72069 27.6136 4.60969V16Z"
        fill="#26A6FE"
      />
      <path
        d="M25.2918 30H7.93559C7.75777 30 7.61365 29.8559 7.61365 29.6781V5.32194C7.61365 5.14413 7.75777 5 7.93559 5H25.2917C25.4695 5 25.6136 5.14413 25.6136 5.32194V29.6781C25.6137 29.8559 25.4695 30 25.2918 30Z"
        fill="#EAF6FF"
      />
      <path
        d="M25.2917 5H24.3259V13.3903C24.3259 14.2793 23.6052 15 22.7162 15L20.4024 16.6822L19.0136 15H7.61365V29.6781C7.61365 29.8559 7.75777 30 7.93559 30H25.2917C25.4695 30 25.6136 29.8559 25.6136 29.6781V5.32194C25.6136 5.14413 25.4695 5 25.2917 5Z"
        fill="#D8ECFE"
      />
      <path
        d="M18.9133 2C18.755 2 18.6166 1.88538 18.5953 1.72844C18.4628 0.752313 17.6261 0 16.6136 0C15.6012 0 14.7644 0.752313 14.6319 1.72844C14.6106 1.88531 14.4723 2 14.314 2H10.9356C10.7578 2 10.6136 2.14412 10.6136 2.32194V6C10.6136 6.53337 11.046 6.96581 11.5795 6.96581H21.6479C22.1813 6.96581 22.6137 6.53344 22.6137 6V2.32194C22.6137 2.14412 22.4696 2 22.2918 2H18.9133Z"
        fill="#B3DAFE"
      />
      <path
        d="M18.6093 9.10238L15.9098 11.8019C15.8415 11.8702 15.7309 11.8702 15.6626 11.8019L14.2511 10.3904C14.1146 10.2539 13.8933 10.2539 13.7569 10.3904L12.716 11.4312C12.5795 11.5677 12.5795 11.789 12.716 11.9254L15.5391 14.7486C15.6756 14.8851 15.8969 14.8851 16.0333 14.7486L20.1445 10.6374C20.281 10.5009 20.281 10.2797 20.1445 10.1433L19.1035 9.10238C18.9671 8.96587 18.7458 8.96587 18.6093 9.10238Z"
        fill="#46CC8D"
      />
      <path
        d="M19.0136 15H30.225C30.3684 15 30.4403 15.1734 30.3388 15.2748L27.6136 18L21.6136 19L15.6136 18L18.3308 15.2829C18.5118 15.1018 18.7575 15 19.0136 15Z"
        fill="#ECA026"
      />
      <path
        d="M12.2136 15H2.77494C2.63156 15 2.55969 15.1734 2.66113 15.2748L5.38631 18L9.61363 19L15.6136 18L12.8965 15.2829C12.7154 15.1018 12.4698 15 12.2136 15Z"
        fill="#FEB237"
      />
      <path
        d="M27.2917 32H15.6136L14.6136 22L15.6136 18H27.6136V31.6781C27.6136 31.8559 27.4695 32 27.2917 32Z"
        fill="#FEB237"
      />
      <path
        d="M5.70829 32H15.6137V18H5.38635V31.6781C5.38635 31.8559 5.53054 32 5.70829 32Z"
        fill="#FFC344"
      />
      <path
        d="M25.4526 29.9999H22.7746C22.6857 29.9999 22.6136 29.9279 22.6136 29.839V28.1609C22.6136 28.0721 22.6857 28 22.7746 28H25.4526C25.5415 28 25.6136 28.0721 25.6136 28.1609V29.839C25.6136 29.9279 25.5415 29.9999 25.4526 29.9999Z"
        fill="#EAF6FF"
      />
      <path
        d="M20.6137 5.48284H12.6137C12.347 5.48284 12.1309 5.26665 12.1309 4.99996C12.1309 4.73328 12.347 4.51709 12.6137 4.51709H20.6137C20.8805 4.51709 21.0966 4.73328 21.0966 4.99996C21.0966 5.26665 20.8804 5.48284 20.6137 5.48284Z"
        fill="#8AC9FE"
      />
      <path
        d="M16.6137 2.48284C16.8804 2.48284 17.0966 2.26665 17.0966 1.99996C17.0966 1.73328 16.8804 1.51709 16.6137 1.51709C16.347 1.51709 16.1309 1.73328 16.1309 1.99996C16.1309 2.26665 16.347 2.48284 16.6137 2.48284Z"
        fill="#8AC9FE"
      />
    </svg>
  );
};

export default IconPickList;
