import { Form, Input, InputNumber, Select, Spin, Switch } from 'antd';
import TextArea from 'antd/es/input/TextArea';
// import SwitchInput from 'components/common/Input/SwitchInput';
import MultipleSelect, { IOptionMulti } from 'components/common/MultipleSelect';
import { debounce, get, set } from 'lodash';
import { useEffect, useMemo, useRef, useState } from 'react';
import voucherSelectors from '../services/selectors';
import { IApiVoucherCreateBodyData } from '../services/types/api';
import styled from 'styled-components';
import vouchersApis from '../services/apis';
import settingSelectors from 'features/settings/services/selectors';
import IconClose from 'assets/svg/IconClose';
import { useTranslation } from 'react-i18next';
import { getSelectedLanguage } from 'utils/unit';
// import NumberValidations from 'utils/validations/number';


export type IVoucherFormValue = {
  voucherCode: string;
  voucherName: string;
  value: number;
  retailPrice: number;
  validityPeriod: string;
  terms: string;
  restrictService: string[];
  enable?: boolean,
  redeemed?: number;
  remaining?: number;
  customerName?: string;
  expiryDate?: string;
  targetQuantity?: number;
  unlimited?: boolean;
  invoice?: any;
  total?: any;
  location?: any;
  status?: any;
};

export type VoucherFormProps = {
  isEdit: boolean;
  viewOnly?: boolean;
  onCancel?: () => void;
  onSubmit?: (payload: IApiVoucherCreateBodyData) => void;
  formData?: IVoucherFormValue;
  errors?: any;
  updateVoucherStatus?: (checked: boolean) => Promise<boolean>;
  loading?: boolean;
};

const VoucherForm = ({ isEdit, errors = {}, viewOnly = false, onCancel = () => undefined, formData, onSubmit = () => undefined, loading }: VoucherFormProps) => {

  const periodStore = voucherSelectors.getPeriodOpts();
  const setting = settingSelectors.getSetting();
  // const [, setIsUnlimited] = useState<boolean>(false);

  const periodOpts = useMemo(() => periodStore.map(o => ({
    value: o.key,
    label: o.value,
  })), [periodStore]);

  const [form] = Form.useForm();
  const [selected, setSelected] = useState<(string | number)[]>([]);
  const [valuePrice, setValuePrice] = useState<number>();
  const servicesStore = voucherSelectors.getServices();
  // const [enableStatus, setEnable] = useState(false);
  const [, setLoadingVoucherCode] = useState(false);
  const params = voucherSelectors.getLstVoucherParams();
  const { t: voucherLang} = useTranslation('vouchers');
  const { t: formLang} = useTranslation('form');

  const generateVoucherCode = debounce(async () => {
    if (isEdit) return;
    setLoadingVoucherCode(true);
    try {
      const res = await vouchersApis.generateVoucherCode();
      const voucher_code = get(res, 'data.data.voucher_code', '');
      if (voucher_code)
        form.setFieldValue('voucherCode', voucher_code);
    } catch (error) { } finally {
      setLoadingVoucherCode(false);
    }
  }, 100);

  useEffect(() => {
    if (isEdit) return;
    generateVoucherCode();
  }, []);

  const services = useMemo(() => {
    const result: IOptionMulti[] = [];
    servicesStore?.filter(name => name.enable_discount === 1 && name.enable_voucher_sale === 1)?.forEach((service) => {
      if (service?.service_variants?.length > 0) {
        service?.service_variants.forEach(serviceVariant => {
          result.push({
            value: service?.id + '-' + serviceVariant.id,
            title: service.name + ' - ' + serviceVariant.name,
            // value: serviceVariant.id,
            // title: serviceVariant.name,
          });
        });
      } else {
        result.push({
          value: service.id + '',
          title: service.name,
        });
      }
    });
    return result;
  }, [servicesStore]);


  // const services = useMemo(() => {
  //   const result: IOptionMulti[] = [];
  //   servicesStore?.filter(name => name.enable_discount === 1 && name.enable_voucher_sale === 1)?.forEach((service) => {
  //     result.push({
  //       value: service.id + '',
  //       title: service.name,
  //     });
  //   });
  //   return result;
  // }, [servicesStore]);

  const allServices = useMemo(() => {
    return services.map(o => o.value);
  }, [servicesStore]);

  useEffect(() => {
    if (isEdit) return;
    if (services.length > 0) {
      setSelected([...allServices]);
    }
  }, [services]);

  useEffect(() => {
    if (!isEdit) {
      const terms_conditions_voucher = setting?.booking?.terms_conditions;
      form.setFieldValue('terms', terms_conditions_voucher);
      return;
    }

    form.setFieldsValue(formData);
    // setEnable(!!formData?.enable);
    setSelected(formData?.restrictService ?? []);
    setValuePrice(formData?.value || undefined);
  }, [formData]);

  useEffect(() => {
    form.setFieldValue('restrictService', selected);
  }, [selected]);

  const getPayload = (values: IVoucherFormValue) => {
    const result: IApiVoucherCreateBodyData = {
      name: values.voucherName,
      retail_price: values.retailPrice,
      validity_period_key: values.validityPeriod,
      value: values.value,
      services: [],
      is_enable: params.view === 'purchased' ? values.enable : undefined,
      status: !!values.enable,
      description: values.terms,
      voucher_code: values.voucherCode ?? '',
      target_quantity: values?.unlimited ? undefined : (values.targetQuantity ?? 1),
      unlimited: true
    };

    if (isEdit) {
      set(result, 'value_key', values.value);
    }

    const restrict_services: { id: number, service_variant_ids: number[] }[] = [];
    values.restrictService.forEach(o => {
      if (!o) return;
      const item = o?.split('-');
      const serviceId = get(item, 0, '');
      const serviceVariant = get(item, 1, '');
      const parent = restrict_services.find(o => o.id?.toString() === serviceId?.toString());
      if (!parent) {
        restrict_services.push({
          id: +serviceId,
          service_variant_ids: serviceVariant ? [+serviceVariant] : [],
        });
      } else {
        if (serviceVariant) parent.service_variant_ids.push(+serviceVariant);
      }
    });
    result.services = restrict_services;

    return result;
  };

  const handleSubmit = (values: IVoucherFormValue) => {
    const payload = getPayload(values);
    if (!payload) return;
    onSubmit(payload);
  };

  // const getValid = async () => {
  //   try {
  //     await form.validateFields();
  //     return true;
  //   } catch (error) {
  //     return false;
  //   }
  // };

  // const _submit: MouseEventHandler<HTMLButtonElement> = (e) => {
  //   e.preventDefault();
  //   const isValid = getValid();
  //   if (!isValid) return;
  //   form.submit();
  // };

  const errorsText = useMemo(() => ({
    voucherCode: get(errors, 'voucher_code', ''),
    voucherName: get(errors, 'name', ''),
    retailPrice: get(errors, 'retail_price', ''),
    validityPeriod: get(errors, 'validity_period_key', ''),
    value: get(errors, 'value', ''),
    restrictService: get(errors, 'services', ''),
    enable: get(errors, 'status', ''),
    terms: get(errors, 'description', ''),
  }), [errors]);

  // const _updateVoucherStatus = async (checked: boolean) => {
  //   if (typeof updateVoucherStatus !== 'function') return;
  //   setEnable(checked);
  //   try {
  //     const accept = await updateVoucherStatus(checked);
  //     if (accept) setEnable(checked);
  //     else {
  //       setEnable(!!formData?.enable);
  //     }
  //   } catch (error) { }
  // };

  const myFocus = useRef<any>();

  useEffect(() => {
    myFocus?.current?.input?.focus();
  }, []);

  const [randomString, setRandomString] = useState('');

  useEffect(() => {
    if (!randomString) {
      const length = 8;
      const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      let result = '';
      for (let i = 0; i < length; i++) {
        const randomIndex = Math.floor(Math.random() * characters.length);
        result += characters.charAt(randomIndex);
      }
      setRandomString(result);
    }

  }, [randomString]);

  return (
    <>
      <Spin spinning={services.length === 0 || !!loading}>
        <Form
          className='modal-form'
          autoComplete='off'
          form={form}
          layout='vertical'
          onFinish={handleSubmit}
          initialValues={{
            enable: formData?.enable ?? true,
            validityPeriod: 'years_3',
            targetQuantity: 1, errorsText
          }}
        >
          <TitleModalVoucherStyled>
            <div className='icon-close' onClick={onCancel}>
              <IconClose />
            </div>
            <h2 className='modal-title'>{!isEdit ? voucherLang('AddNewVoucher') : voucherLang('EditVoucher')}</h2>
            <button className='common-btn' type='submit' >
              {voucherLang('Confirm')}
            </button>
          </TitleModalVoucherStyled>
          <VoucherFormStyled>
            <div className='form-container'>
              <h1>{voucherLang('VoucherInformation')}</h1>
              <div className='form-row'>
                <Form.Item label={voucherLang('VoucherName') + ' (*)'} name='voucherName'
                  rules={[{ required: true, message: voucherLang('PleaseEnterVoucherName') || '' }]}
                  validateStatus={errorsText?.voucherName ? 'error' : undefined}
                  help={errorsText?.voucherName ? errorsText?.voucherName : undefined}
                >
                  <Input ref={myFocus} placeholder={voucherLang('EnterVoucherName') || ''} disabled={viewOnly} />
                </Form.Item>
              </div>
              {/* <div className='form-row'> 
                <Form.Item label={voucherLang('VoucherCode')} name='voucherCode'
                // rules={[{ required: true, message: voucherLang('PleaseEnterVoucherName') }]}
                // validateStatus={errorsText?.voucherName ? 'error' : undefined}
                // help={errorsText?.voucherName ? errorsText?.voucherName : undefined}
                >
                  <Input className='code-voucher' disabled value={randomString} />
                </Form.Item>
              </div> */}
              {!viewOnly &&
                <>
                  <div className='form-row dislay-grid'>
                    <Form.Item label={voucherLang('Value') + ' (*)'} name='value' rules={[
                      {
                        validator(_, valuePrice,) {
                          if (valuePrice === null || valuePrice === undefined) return Promise.reject(new Error(voucherLang('PleaseEnterValue') || ''));
                          if (valuePrice < 1) {
                            return Promise.reject(new Error(voucherLang('ValueMustBeLessThanOrEqualTo1') || ''));
                          }
                          return Promise.resolve();
                        },
                      }]}
                      validateStatus={errorsText?.value ? 'error' : undefined}
                      help={errorsText?.value ? errorsText?.value : undefined}
                    >
                      <InputNumber type='number' disabled={viewOnly} onChange={e => setValuePrice(e as number)} addonAfter={getSelectedLanguage() === 'vi' ? 'đ' : '$'} placeholder={formLang('EnterTheActualValue') || ''} />
                    </Form.Item>
                    <Form.Item label={voucherLang('RetailPrice') + ' (*)'} name='retailPrice' rules={[
                      {
                        validator(_, priceRetail,) {
                          if (priceRetail === null || priceRetail === undefined) return Promise.reject(new Error(voucherLang('PleaseEnterRetailPrice') || ''));
                          if (priceRetail < 1) {
                            return Promise.reject(new Error(voucherLang('RetailPriceMustBeLessThanOrEqualTo1') || ''));
                          }
                          if (priceRetail > (valuePrice ?? 0)) {
                            return Promise.reject(new Error(voucherLang('RetailPriceCannotBeGreaterThanValue') || ''));
                          }
                          return Promise.resolve();
                        },
                      }]}
                      validateStatus={errorsText?.retailPrice ? 'error' : undefined}
                      help={errorsText?.retailPrice ? errorsText?.retailPrice : undefined}
                    >
                      <InputNumber disabled={viewOnly} addonAfter={getSelectedLanguage() === 'vi' ? 'đ' : '$'} type='number' placeholder={formLang('EnterTheSellingPrice') || ''} />
                    </Form.Item>
                  </div>

                  {/* <div className='form-row is-wrap'>
              <Form.Item label='Quantity' name='targetQuantity' rules={[
                NumberValidations.ONLY_NUMBER,
                { required: true },
              ]}
                validateStatus={errorsText?.validityPeriod ? 'error' : undefined}
                help={errorsText?.validityPeriod ? errorsText?.validityPeriod : undefined}
              >
                <InputNumber disabled={isUnlimited} placeholder='Enter voucher quantity' style={{ width: '100%' }} onChange={() => {
                  form.setFieldValue('unlimited', false);
                  setIsUnlimited(false);
                }} />
              </Form.Item>
            </div> */}
                  {/* <div className='form-row is-wrap'>
              <Form.Item name='unlimited' valuePropName='checked'>
                <Checkbox onChange={() => {
                  const IsUnlimited = form.getFieldValue('unlimited');
                  setIsUnlimited(IsUnlimited);
                  if (IsUnlimited) {
                    form.setFieldValue('targetQuantity', 0);
                    form.validateFields(['targetQuantity']);
                  }

                }}>Unlimited </Checkbox>
              </Form.Item>
            </div> */}

                  {/* <div className='form-row'>
                <Form.Item label={'Số lượng (*)'} name='voucherName'
                  rules={[{ required: true, message: voucherLang('PleaseEnterVoucherName') }]}
                  validateStatus={errorsText?.voucherName ? 'error' : undefined}
                  help={errorsText?.voucherName ? errorsText?.voucherName : undefined}
                >
                  <InputNumber addonAfter='Voucher' />
                </Form.Item>
              </div> */}

                  <div className='form-row is-wrap'>
                    <Form.Item label={voucherLang('ValidityPeriod') + ' (*)'} name='validityPeriod' valuePropName='value' rules={[{ required: true }]}
                      validateStatus={errorsText?.validityPeriod ? 'error' : undefined}
                      help={errorsText?.validityPeriod ? errorsText?.validityPeriod : undefined}
                    >
                      <Select disabled={viewOnly} options={periodOpts} />
                    </Form.Item>
                    <p style={{ fontSize: '12px', color: '#595959' }}>Thời hạn sử dụng tính từ ngày bán voucher.</p>
                  </div>
                </>
              }

              {viewOnly && (
                <>
                  <div className='form-row'>
                    <Form.Item label={voucherLang('CustomerName')} name='customerName'>
                      <Input disabled={viewOnly} placeholder={voucherLang('EnterCustomerName') || ''} />
                    </Form.Item>
                  </div>
                  <div className='form-row'>
                    <Form.Item label={voucherLang('RetailPrice')} name='retailPrice'>
                      <InputNumber disabled={viewOnly} addonAfter={getSelectedLanguage() === 'vi' ? 'đ' : '$'} type='number' placeholder={formLang('AmountPlaceholder') || ''} />
                    </Form.Item>
                  </div>
                  <div className='form-row'>
                    <Form.Item label={voucherLang('Redeemed')} name='redeemed'>
                      <InputNumber disabled={viewOnly} addonAfter={getSelectedLanguage() === 'vi' ? 'đ' : '$'} type='number' placeholder={formLang('AmountPlaceholder') || ''} />
                    </Form.Item>
                  </div>
                  <div className='form-row'>
                    <Form.Item label={voucherLang('Remaining')} name='remaining'>
                      <InputNumber disabled={viewOnly} addonAfter={getSelectedLanguage() === 'vi' ? 'đ' : '$'} type='number' placeholder={formLang('AmountPlaceholder') || ''} />
                    </Form.Item>
                  </div>
                  <div className='form-row'>
                    <Form.Item label={voucherLang('ExpiryDate')} name='expiryDate'>
                      <Input disabled={viewOnly} placeholder={voucherLang('EnterVoucherCode') || ''} />
                    </Form.Item>
                  </div>
                </>
              )}

              <div className='form-row'>
                <Form.Item label={voucherLang('ServiceApplied') + ' (*)'} name='restrictService'
                  validateStatus={errorsText?.restrictService ? 'error' : undefined}
                  help={errorsText?.restrictService ? errorsText?.restrictService : undefined}
                  rules={[{ required: true, message: voucherLang('PleaseSelectServiceApplied') || '' }]}
                >

                  <MultipleSelect
                    onChange={setSelected}
                    value={selected}
                    options={services}
                    maxTagPlaceholder={voucherLang('services') || ''}
                    placeholder={voucherLang('SelectServices') || ''}
                    disabled={viewOnly}
                  />

                </Form.Item>
              </div>
              <div className='form-row'>
                <Form.Item label={voucherLang('Terms&Conditions')} name='terms'
                  validateStatus={errorsText?.terms ? 'error' : undefined}
                  help={errorsText?.terms ? errorsText?.terms : undefined}
                >
                  <TextArea
                    placeholder={voucherLang('EnterTerms&ConditionsOfThisVoucher') || ''}
                    rows={4}
                    disabled={viewOnly}
                  />
                </Form.Item>
              </div>
              {/* <p style={{ fontSize: '12px', color: '#595959' }}>Nội dung sẽ được hiển thị qua email/SMS của khách hàng.</p> */}
              <div className='form-row'>
                <div className='form-row switch-check'>
                  <p className='form-label'>{voucherLang('EnableThisVoucher')}</p>
                  <Form.Item noStyle name={'enable'} valuePropName='checked'>
                    <Switch />
                  </Form.Item>
                </div>
                {/* {
                  viewOnly ?
                    <>
                      <Form.Item name={'enable'} className='form-row'>

                        <div className='form-row'>
                          <p className='form-label'>{voucherLang('EnableThisVoucher')|| ''}</p>
                          <Switch
                            checked={enableStatus}
                            onChange={(checked) => _updateVoucherStatus(checked)}
                            checkedChildren=''
                            unCheckedChildren=''
                          />
                        </div>
                      </Form.Item>
                    </>
                    :
                    <SwitchInput
                      label={voucherLang('EnableThisVoucher')}
                      name='enable'
                    />
                } */}
              </div>

              {/* <div className='form-submit-wrapper'>
            <div
              className='common-btn is-white'
              onClick={onCancel}
            >
              {!viewOnly ? voucherLang('Cancel') : voucherLang('Close')}
            </div>
            {!viewOnly && <button className='common-btn' onClick={_submit} >
              {voucherLang('Save')}
            </button>}
          </div> */}
            </div>
          </VoucherFormStyled>
        </Form>
      </Spin>

    </>

  );
};

export default VoucherForm;

const TitleModalVoucherStyled = styled.div`
display: flex;
justify-content: space-between;
align-items: center;
border-bottom: 1px solid #1B9AAA;
position: sticky;
background: white;
z-index: 99999;
top: -50px;
padding-top: 10px;
.icon-close {
  cursor: pointer;
  width: 20%;
}
.modal-title {
  width: 60%;
  margin-bottom: 0px;
  text-align: center;
}
button {
  width: 20%;
  min-width: 0px;
}
`;

const VoucherFormStyled = styled.div`
    display: flex;
    justify-content: center;

.form-container {
  margin-top: 30px;
justify-content: center;
display: grid;
border: 1px solid rgb(195, 194, 224);
border-radius: 10px;
padding: 20px;
width: 780px;  
}

h1 {
  color: var(--Gray-95, #141414);
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  margin-bottom: 24px;
}

.ant-form-item-label > label {
  color: var(--Gray-95, #141414);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
}

.label-container {
  display: grid;
  gap: 24px;
  .title {
    color: var(--Gray-95, #141414);
    font-family: Inter;
    font-size: 18px;
    font-weight: 600;
    line-height: 140%;
  }
  ul {
    display: grid;
    gap: 24px;
    padding-bottom: 24px;
    li {
      display: flex;
      justify-content: space-between;
      align-items: center;
      p {
        color: var(--Gray-95, #141414);
        font-family: Inter;
        font-size: 16px;
        font-weight: 400;
        line-height: 140%;
      }
      .text-note {
        color: var(--Gray-50, #8C8C8C);
        font-variant-numeric: slashed-zero;
        font-family: Inter;
        font-size: 14px;
        font-style: italic;
        font-weight: 400;
        line-height: 140%;
      }
      .text-data {
        color: var(--Gray-95, #141414);
        text-align: right;
        font-family: Inter;
        font-size: 16px;
        font-weight: 600;
        line-height: 140%;
      }
      .text-total {
        color: var(--Blue-Munsell-Main, #1B9AAA);
        text-align: right;
        font-family: Inter;
        font-size: 24px;
        font-weight: 600;
        line-height: 140%;
      }
    }
  }
}

.ant-spin-nested-loading {
  width: 600px;
  border-radius: 12px;
  border: 1px solid var(--Gray-50, #8C8C8C);
  padding: 24px;
}

.dislay-grid {
  @media only screen and (max-width: 767.98px) {
    display: grid;
  }
}

.code-voucher {
  text-decoration: transparent;
}

.form-submit-wrapper {
  bottom: -40px;
}
 .ant-input-disabled {
    background-color:#F0F2F7;
    color: #7980BC;
  }
  .ant-input-number-disabled {
    .ant-input-number-input {
      color: #7980BC;
      background-color:#F0F2F7;
    }
  }
  .ant-input-number-group .ant-input-number-group-addon:first-child {
    background-color:#F0F2F7;
    color: #7980BC;
  }
  .ant-select-disabled {
    &.ant-select {
      .ant-select-selector {
        background-color:#F0F2F7;
        color: #7980BC;
      }
    }
  }
  .ant-select-multiple.ant-select-disabled.ant-select{
    .ant-select-selector {
      background:#F0F2F7;
      background-color:#F0F2F7 !important;
      color: #7980BC !important;
    }
  }
  .ant-select-multiple.ant-select-lg .ant-select-selection-item {
    .ant-select-selection-item-content {
      // color: #7980BC !important;
    }
  }
  .ant-select-single.ant-select-show-arrow .ant-select-selection-item, .ant-picker .ant-picker-input > input {
    color: #7980BC !important;
  }
`;
