import { Col, Form, Row } from 'antd';
import styled from 'styled-components';
import TeamMemberItem from './TeamMemberItem';
import bookingSelectors from 'features/bookings/services/selectors';
import { useMemo } from 'react';
import { ANONYMOUS_NAME } from 'utils/unit';
import { StaffPublic } from 'features/users/services/types/teamMember';
import { useTranslation } from 'react-i18next';


const TeamMemberForm = ({teamMembers} : {teamMembers: StaffPublic[]}) => {

  const teamMemberSelected = bookingSelectors.bookingOnline.getTeamMemberSelected();   
  const {t: bookingLang} = useTranslation('booking');
  const teamMemberList = useMemo(() => {
    return [{
      id: '',
      full_name: bookingLang(ANONYMOUS_NAME === 'Any team member' ? 'AnyTeamMember' : ''),
      isAvailable: true
    } as any].concat(teamMembers);
  }, [teamMembers]);
  

  return (
    <TeamMemberFormStyled>
      <Form.Item name={'teamMember'} rules={[
        {
          validator: () => {
            if (teamMemberSelected.length === 0){
              return Promise.reject(
                'Team member must be require'
              );
            }
            return Promise.resolve();
          },
        },
      ]}>
        <Row gutter={[16, 16]} >
            {teamMemberList.map((o, index) => (
              <Col key={index} span={teamMembers.length > 1 ? 8 : 12}>
                <TeamMemberItem selected={!!teamMemberSelected?.find(selected => selected.id === o.id)} data={o}/>
              </Col>
            ))}
        </Row>
      </Form.Item>
    </TeamMemberFormStyled>
  );
};

export default TeamMemberForm;

const TeamMemberFormStyled = styled.div`
  display: flex;
  
  .ant-row {
    width: 100%; 
  }

  .active {
    border-color: #363565;
    background-color: #e2e5ff;
  }

  .disabled {
    opacity: 0.9;
    pointer-events: none;
  }

`;