import { Dropdown, MenuProps, Modal } from 'antd';
import auth from 'assets/images/auth';
import authActions from 'features/auth/services/actions';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
import styles from '../index.module.scss';
import userActions from 'features/users/services/actions';
import { getAvatar } from '..';
import { useTranslation } from 'react-i18next';

enum ENUM_ACTIONS {
  EDIT_PROFILE = 'edit_profile',
  LOGOUT = 'logout',
}

/**
 * Avatar user
 * @returns 
 */
const AvatarUser = () => {
  const dispatch = useAppDispatch();
  const [openConfirm, setOpenConfirm] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(userActions.getProfileStaffDetail.fetch());
  }, []);

  /**
   * on click sign out
   */
  const onClickSignOut = () => {
    setOpenConfirm(true);
  };

  /**
   * on sign out
   */
  const onSignOut = () => {
    setOpenConfirm(false);
    dispatch(authActions.logout.fetch());
  };
  const { t: headerLang } = useTranslation('header');
  const items: MenuProps['items'] = [
    {
      label: headerLang('EditProfile'),
      key: ENUM_ACTIONS.EDIT_PROFILE,
      icon: <PenIcon />,
      style: { color: '#363565', gap: 8 },
      onClick: () => navigate('/private/edit-profile'),
    },
    {
      label: headerLang('SignOut'),
      key: ENUM_ACTIONS.LOGOUT,
      icon: <LogoutIcon />,
      style: { color: '#E95060', gap: 8 },
      onClick: onClickSignOut,
    },
  ];

  const menuProps: MenuProps = {
    items,
    style: {
      padding: '8px',
      width: '180px',
    },
  };
  return (
    <>
      <div className={styles.header_user}>
        <Dropdown menu={menuProps} trigger={['click']}>
          <img src={getAvatar()} alt='AuthAvatar' />
        </Dropdown>
      </div>
      <ModalSignOut
        visible={openConfirm}
        onClose={() => setOpenConfirm(false)}
        onOk={onSignOut}
      />
    </>
  );
};

export default AvatarUser;
type ModalSignOutProps = {
  visible: boolean;
  onClose: () => void;
  onOk: () => void;
};
const ModalSignOut = ({ visible, onClose, onOk }: ModalSignOutProps) => {
  const { t: headerLang } = useTranslation('header');
  return (
    <>
      <ModalSignOutStyled>
        <Modal
          maskClosable={false}
          open={visible}
          onCancel={onClose}
          forceRender
          width={'400px'}
          onOk={onOk}
          closable={false}
          okText={'Confirm'}
          className='modal-confirm-custom'
          centered
          footer={false}
        >
          <ModalSignOutStyled>
            <div className='ant-modal-top'>
              <img className='image' src={auth.logout_image} />
            </div>
            <p className='title'>{headerLang('SigningOut')}</p>
            <p className='message'>
              {headerLang('AreYouSureYouWantToSignOut?Click')} &quot;<b>{headerLang('Confirm')}</b>&quot;
              {headerLang('ToProceedOr')} &quot;<b>{headerLang('Cancel')}</b>&quot; {headerLang('ToStayLoggedIn')}
            </p>
          </ModalSignOutStyled>
          <div className='form-row form-submit-wrapper'>
            <button className='common-btn is-white' onClick={onClose}>{headerLang('Cancel')}</button>
            <button className='common-btn' onClick={onOk}>{headerLang('Confirm')}</button>
          </div>
        </Modal>
      </ModalSignOutStyled>
    </>
  );
};

const PenIcon = () => {
  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M14.2383 2.42864C14.4572 2.20977 14.717 2.03615 15.003 1.9177C15.2889 1.79925 15.5954 1.73828 15.9049 1.73828C16.2145 1.73828 16.521 1.79925 16.8069 1.9177C17.0929 2.03615 17.3527 2.20977 17.5716 2.42864C17.7905 2.64751 17.9641 2.90734 18.0826 3.19331C18.201 3.47928 18.262 3.78578 18.262 4.0953C18.262 4.40483 18.201 4.71133 18.0826 4.9973C17.9641 5.28326 17.7905 5.5431 17.5716 5.76197L6.32161 17.012L1.73828 18.262L2.98828 13.6786L14.2383 2.42864Z'
        stroke='#363565'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

const LogoutIcon = () => {
  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M7.5 17.5H4.16667C3.72464 17.5 3.30072 17.3244 2.98816 17.0118C2.67559 16.6993 2.5 16.2754 2.5 15.8333V4.16667C2.5 3.72464 2.67559 3.30072 2.98816 2.98816C3.30072 2.67559 3.72464 2.5 4.16667 2.5H7.5'
        stroke='#E95060'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M13.3334 14.1654L17.5 9.9987L13.3334 5.83203'
        stroke='#E95060'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M17.5 10H7.5'
        stroke='#E95060'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

const ModalSignOutStyled = styled.div`
  .ant-modal {
    @media only screen and (max-width: 767.98px) {
      width: 100% !important;
    }
  }
  .image {
    width: 80px;
    height: 80px;
  }
  .title {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 140%;
    text-align: center;
    color: #363565;
    margin-bottom: 12px;
  }
  .message {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    text-align: center;
    color: #363565;
  }
  .ant-modal-top,
  .actions {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
