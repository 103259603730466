import { IconCalendar, IconCustomers, IconHome, IconInvoice } from 'assets/svg';
import { PATH } from 'constants/path.enum';
import styles from 'features/checkout/pages/CheckoutPage/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';


/**
 * Menu page
 * @returns 
 */
const MenuPage = () => {
    const { t: commonLang } = useTranslation('common');

    /**
     * get navbar
     * @returns 
     */
    const getNavbar = () => {
        return [
            {
                text: commonLang('Sidebar.Home'),
                path: '/private/dashboards',
                icon: <IconHome />,
            },
            {
                text: commonLang('Sidebar.Bookings'),
                path: PATH.bookings,
                icon: <IconCalendar />,
            },
            {
                text: commonLang('Sidebar.Customers'),
                path: PATH.customers,
                icon: <IconCustomers />,
            },
            {
                text: commonLang('Sidebar.Invoices'),
                path: PATH.invoices,
                icon: <IconInvoice />,
            }
            // {
            //     text: commonLang('Sidebar.Settings'),
            //     path: PATH.settings,
            //     icon: <IconSettings />,
            // }
        ];
    };

    const activeStyle = {
        color: '#1B9AAA',
        fill: '#1B9AAA'
    };

    return (
        <menu>
            <MenuStyled>
                <div className='menu-conatiner'>
                    <div className='menu-item-conatiner'>
                        {getNavbar().map((nav) => {
                            const { text, path, icon } = nav;
                            return (
                                // eslint-disable-next-line react/jsx-key
                                <NavLink
                                    to={path}
                                    style={({ isActive }) =>
                                        isActive ? activeStyle : undefined
                                    }
                                    className={({ isActive }) =>
                                        isActive ? styles.navbar_link_active : undefined
                                    }
                                >
                                    {icon}
                                    <span>{text}</span>
                                </NavLink>
                            );
                        })}
                    </div>
                </div>
            </MenuStyled>
        </menu>
    );
};

export default MenuPage;

const MenuStyled = styled.div`
background: #FFFFFF;
.menu-conatiner {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 24px 20px 24px 50%;
    margin: 0 auto;
    background: #ffffff;
    margin-bottom: 24px;
    padding: 16px;
    position: fixed;
    z-index: 9999;
    width: 100%;
    bottom: -25px;
    border-radius: 16px 16px 0px 0px;
    background: var(--White, #FFF);
    box-shadow: 0px -4px 16px -1px rgba(0, 0, 0, 0.08);
    .menu-item-conatiner {
        display: flex;
        justify-content: center;
        align-items: start;
        gap: 8px;
        width: 100%;
        a {
            color: var(--Gray-95, #141414);
            text-align: center;
            font-size: 10px;
            font-weight: 400;
            line-height: 140%;
            letter-spacing: 0.2px;
            display: grid;
            justify-content: center;
            align-items: center;
            flex: 1;
            svg {
                width: 100%;
                align-items: center;
                display: flex;
                justify-content: center;
            }
        }
    }
}
`;