import { createAction, createAsyncAction } from 'services/actionConfigs';
import { IApiPromotionListReq } from '../types/api';
import { PREFIX_ACTIONS } from './constants';
import { IPromotionItemResData } from '../types/promotion';
import { IPromotionAutoParam } from './types/promotion';

const init = createAction(PREFIX_ACTIONS + 'init');

const getConfigs = createAsyncAction(PREFIX_ACTIONS + 'getConfigs');

const getListPromotions = createAsyncAction<IApiPromotionListReq>(PREFIX_ACTIONS + 'getListPromotions');

const getDetailPromotion = createAsyncAction<string>(PREFIX_ACTIONS + 'getDetailPromotion');

const setListPromotionParams = createAction<IApiPromotionListReq>(PREFIX_ACTIONS + 'setListPromotionParams');

const deletePromotion = createAction<IPromotionItemResData['code']>(PREFIX_ACTIONS + 'deletePromotion');

const generatePromotionCode = createAsyncAction(PREFIX_ACTIONS + 'generatePromotionCode');

const getPromotionAutoOffer = createAsyncAction<IPromotionAutoParam>(PREFIX_ACTIONS + 'getPromotionAutoOffer');

const promotionActions = {
  deletePromotion,
  setListPromotionParams,
  getListPromotions,
  getConfigs,
  init,
  getDetailPromotion,
  generatePromotionCode,
  getPromotionAutoOffer
};

export default promotionActions;
