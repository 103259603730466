import { ConfigProvider } from 'antd';
import { Outlet } from 'react-router-dom';
import Breadcrumbs from '../../common/Breadcrumbs';
import Header from '../Header';
import Sidebar from '../Sidebar';
import styles from './index.module.scss';
import React, { useEffect, useRef, useState } from 'react';
import ModalConfirm, { ModalConfirmRef } from 'components/common/Modal/ModalConfirm';
import storage from 'utils/sessionStorage';
import { useDispatch } from 'react-redux';
import authActions from 'features/auth/services/actions';
import MenuPage from '../Menu';
import packagePermissionActions from 'features/packagePermission/services/actions';
import FloatButtonPage from '../FloatButton';

/**
 * Base layout
 * @returns 
 */
const BaseLayout = () => {
  const [isShowSidebar, setIsShowSidebar] = useState(false);
  const isConfirmDevice = storage.isConfirmDevice.get();
  const modalConfirmRef = useRef<ModalConfirmRef>(null);
  const dispatch = useDispatch();

  useEffect(() => {
    const permission = storage.permission.get();
    if(!permission) return;
    dispatch(packagePermissionActions.setMerchantPermission(JSON.parse(permission)));
  }, []);

  useEffect(() => {
    if (isConfirmDevice) {
      modalConfirmRef.current?.show({
        title: 'Force Logout',
        msg: 'Sorry, you have been logged out of your account because another device has logged in.',
        submit: async () => {
          dispatch(authActions.logout.fetch());
        },
      });
    }
  }, [isConfirmDevice]);


  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: 'var(--color-primary)',
          fontFamily: '"Inter", sans-serif',
        },
      }}
    >
      <div className='container'>
        <Header />
        <div className={styles.menu_container}>
          <MenuPage />
        </div>
        <main className={styles.private_content}>
          <Sidebar
            setIsShowSidebar={setIsShowSidebar}
            isShowSidebar={isShowSidebar}
          />
          <div className={`private-wrapper ${isShowSidebar && 'is-show-sidebar'}`}>
            <Breadcrumbs />
            <div className={styles.content_inner}>
              <Outlet />
            </div>
            <FloatButtonPage/>
          </div>
        </main>

      </div>
      <ModalConfirm ref={modalConfirmRef} showClose={false} />
    </ConfigProvider>
  );
};

export default BaseLayout;
