export interface IApiPromotionRequest {
    name: string;
    type: string;
    value: string;
    description: string;
    message?: string;
    services: IService[];
    location_ids: number[];
    enable_booking: number;
}

interface IService {
    id: number;
    service_variant_ids: number[];
}

export enum EPromotionType {
    PERCENT = 'percent',
    PRICE = 'price',
}

export interface IApiPromotionParams {
    keyword?: string;
    per_page?: number;
    type?: EPromotionType;
}

export interface IApiPromotionShowCreateRes {
    services: IServiceShowCreate[];
    locarions: ILocationShowCreate[];
}

interface ILocationShowCreate {
    id: number;
    name: string;
    address?: string;
    contact_number: string;
    status: number;
}

interface IServiceShowCreate {
    id: number;
    name: string;
    service_variants: IServiceVariantShowCreate[];
}

interface IServiceVariantShowCreate {
    id: number;
    service_id: number;
    name: string;
    price: number;
    duration: number;
}


export interface IPromotionDetailResData {
    id: number;
    code: string;
    value: string;
    type: string;
    services_restricts: IPromotionServiceItem[];
    locations: any[];
    enable_voucher_sales: boolean;
    promotion_type?: 'retention';
}

export interface IPromotionServiceItem {
    id: number;
    name: string;
    service_variants: { id: number, name: string }[];
}

export interface IPromotionDetailInput extends Omit<IPromotionDetailResData, 'services'> {
    services: string[];
}

export interface IPromotionAutoParam {
    today: number;
    merchant_location_id : string | number;
    customer_code: string;
    total_booking: number, 
    total_voucher: number
}

export interface IPromotionAutoRes {
    id: number;
    name: string;
    code: string;
    value: string;
    type: string;
    value_text: string;
    promotion_type: string;
    start_date: string;
    end_date?: any;
    status: number;
    enable_booking_online: number;
    enable_voucher_sales: number;
    apply_for_walkin: number;
    services_restricts: IPromotionServiceItem[];
    location_restricts: ILocationRestrict[];
}

interface ILocationRestrict {
id: number;
name: string;
status: number;
}
