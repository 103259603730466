import { AxiosResponse } from 'axios';
// import message from 'components/common/Message';
import actions from './actions';
import apis from './apis';
import { call, put, takeLatest } from 'redux-saga/effects';

// import storage from 'utils/sessionStorage';


const getMerchantPermission = function* () {
  try {
    const res: AxiosResponse<{ data: any }> = yield call(apis.getMerchantPermission);
    const data = res?.data?.data;
    if (data) {
      yield put(actions.getMerchantPermission.success(data));
    }

  } catch (error) {
    yield put(actions.getMerchantPermission.fail({}));

   }
  finally {

  }
};


export default function* packagePermissionSaga() {
  yield takeLatest(actions.getMerchantPermission.fetch, getMerchantPermission);
  
} 
