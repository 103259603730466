import { Spin, Table } from 'antd';
import serviceActions from 'features/services/services/actions';
import serviceSelectors from 'features/services/services/selectors';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

const SupplierTable = ({ columns }: any) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const data = serviceSelectors.getPaginateListServices();
    const loading = serviceSelectors.loadingStaffPaginateList();
    const dispatch = useDispatch();
    const dataSource: any = [
        {
            'supplier_name': 'Uway Technology',
            'phone': '0964074541 ',
            'email': 'nhatnguyen.uway@gmail.com',
            'product': 10,
            'update': '30.12.2022 ~ 12:30',
        },
        {
            'supplier_name': 'MindHana',
            'phone': '0964074541 ',
            'email': 'nhatnguyen.uway@gmail.com',
            'product': 12,
            'update': '30.12.2022 ~ 12:30',
        }
    ];
    return (
        <>
            <Spin spinning={loading}>
                <Table
                    className={'custom-table'}
                    dataSource={dataSource}
                    columns={columns}
                    scroll={{
                        x: 900,
                    }}
                    pagination={{
                        defaultCurrent: Number(searchParams.get('page') ?? 1),
                        defaultPageSize: Number(searchParams.get('per_page') ?? 10),
                        total: data?.pagination?.total_record,
                        onChange: (pageChange, sizeChange) => {
                            const params: any = {
                              page: pageChange == 0 ? 1 : pageChange,
                              per_page: sizeChange,
                            };
                            setSearchParams(params);
                
                            dispatch(serviceActions.getListPaginateServices.fetch({
                              page: params.page,
                              per_page: params.per_page,
                            }));
                          },
                        showSizeChanger: false,
                    }}
                />
            </Spin>
        </>
    );
};

export default SupplierTable;