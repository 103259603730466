const IconCalenderMobile = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
    >
      <path
        d="M25.3333 4.33325H6.66667C4.64162 4.33325 3 5.97487 3 7.99992V25.3333C3 27.3583 4.64162 28.9999 6.66667 28.9999H25.3333C27.3584 28.9999 29 27.3583 29 25.3333V7.99992C29 5.97487 27.3584 4.33325 25.3333 4.33325Z"
        fill="#BAD7FF"
      />
      <path
        d="M29 7.99992V11.6666H3V7.99992C3 5.97325 4.64 4.33325 6.66667 4.33325H25.3333C27.36 4.33325 29 5.97325 29 7.99992ZM10.6667 16.3333H8C7.448 16.3333 7 16.7813 7 17.3333C7 17.8853 7.448 18.3333 8 18.3333H10.6667C11.2187 18.3333 11.6667 17.8853 11.6667 17.3333C11.6667 16.7813 11.2187 16.3333 10.6667 16.3333ZM17.3333 16.3333H14.6667C14.1147 16.3333 13.6667 16.7813 13.6667 17.3333C13.6667 17.8853 14.1147 18.3333 14.6667 18.3333H17.3333C17.8853 18.3333 18.3333 17.8853 18.3333 17.3333C18.3333 16.7813 17.8853 16.3333 17.3333 16.3333ZM24 16.3333H21.3333C20.7813 16.3333 20.3333 16.7813 20.3333 17.3333C20.3333 17.8853 20.7813 18.3333 21.3333 18.3333H24C24.552 18.3333 25 17.8853 25 17.3333C25 16.7813 24.552 16.3333 24 16.3333ZM10.6667 21.6666H8C7.448 21.6666 7 22.1146 7 22.6666C7 23.2186 7.448 23.6666 8 23.6666H10.6667C11.2187 23.6666 11.6667 23.2186 11.6667 22.6666C11.6667 22.1146 11.2187 21.6666 10.6667 21.6666ZM17.3333 21.6666H14.6667C14.1147 21.6666 13.6667 22.1146 13.6667 22.6666C13.6667 23.2186 14.1147 23.6666 14.6667 23.6666H17.3333C17.8853 23.6666 18.3333 23.2186 18.3333 22.6666C18.3333 22.1146 17.8853 21.6666 17.3333 21.6666ZM24 21.6666H21.3333C20.7813 21.6666 20.3333 22.1146 20.3333 22.6666C20.3333 23.2186 20.7813 23.6666 21.3333 23.6666H24C24.552 23.6666 25 23.2186 25 22.6666C25 22.1146 24.552 21.6666 24 21.6666Z"
        fill="#549BFF"
      />
      <path
        d="M11.6666 4V6.66667C11.6666 7.21867 11.2186 7.66667 10.6666 7.66667C10.1146 7.66667 9.66663 7.21867 9.66663 6.66667V4C9.66663 3.448 10.1146 3 10.6666 3C11.2186 3 11.6666 3.448 11.6666 4ZM21.3333 3C20.7813 3 20.3333 3.448 20.3333 4V6.66667C20.3333 7.21867 20.7813 7.66667 21.3333 7.66667C21.8853 7.66667 22.3333 7.21867 22.3333 6.66667V4C22.3333 3.448 21.8853 3 21.3333 3Z"
        fill="#BAD7FF"
      />
    </svg>
  );
};

export default IconCalenderMobile;
