/* eslint-disable react/prop-types */
import IconAdvertisingPromo from 'assets/svg/IconAdvertisingPromo';
import IconBirthdayCakePromo from 'assets/svg/IconBirthdayCakePromo';
import IconPromoCode from 'assets/svg/IconPromoCode';
import IconUserPromo from 'assets/svg/IconUserPromo';
import React, { useState } from 'react';
import styled from 'styled-components';

const SelectPromotion = ({ onPromotionSelect }: any) => {

    const dataPagePromotions = [
        {
            id: 1,
            icon: <IconUserPromo />,
            title: 'Tái tương tác',
            content: 'Tặng mã khuyến mãi cho những khách hàng chưa ghé thăm địa điểm của bạn trong khoảng thời gian dài.',
            name: 'retention'
        },
        {
            id: 2,
            icon: <IconAddUser />,
            title: 'Đăng ký lần đầu',
            content: 'Tặng mã khuyến mãi cho những khách hàng đăng ký và truy cập lần đầu tiên trên ứng dụng MiniApp của cửa hàng.',
            name: 'signup'
        },
        {
            id: 3,
            icon: <IconBirthdayCakePromo />,
            title: 'Khuyến mãi sinh nhật',
            content: 'Tặng thưởng cho những khách hàng đã chia sẻ ngày sinh của họ với bạn. Điều này sẽ giúp bạn đưa ra những ưu đãi đặc biệt vào ngày sinh nhật của họ.',
            name: 'birthday'
        },
        {
            id: 4,
            icon: <IconCrown />,
            title: 'Tích điểm đổi quà',
            content: 'Tạo chương trình tích điểm cho mỗi lần khách hàng ghé thăm cửa hàng hoặc chi tiền để đạt được phần thưởng.',
            name: 'vist_point'
        },
        {
            id: 5,
            icon: <IconAdvertisingPromo />,
            title: 'Khuyến mãi tự động',
            content: 'Tạo chương trình khuyến mãi áp dụng tự động trong thời gian nhất định, bạn không cần phải nhập mã code khuyến mãi khi thanh toán.',
            name: 'auto'
        },
        {
            id: 6,
            icon: <IconPromoCode />,
            title: 'Tạo mã khuyến mãi',
            content: 'Tạo chương trình khuyến mãi và gửi tặng đến khách hàng để tăng doanh thu cửa hàng.',
            name: 'code'
        }
    ];

    const [activeId, setActiveId] = useState<string>('');

    const handlePromotionSelect = (name: string) => {
        setActiveId(name);
        onPromotionSelect(name);
    };

    return (
        <SelectPromotionStyled>
            {
                dataPagePromotions.map((o): any => (
                    <>
                        <div key={o.id} className={`select-promotion ${activeId === o.name ? 'active' : ''}`} onClick={() => handlePromotionSelect(o.name)}>
                            {o?.icon}
                            <h3 style={{ whiteSpace: 'nowrap' }}>{o.title}</h3>
                            <p>
                                {o.content}
                            </p>
                        </div>
                    </>
                ))
            }

        </SelectPromotionStyled>
    );
};

export default SelectPromotion;

const SelectPromotionStyled = styled.div`
display: flex;
flex-wrap: wrap;
gap: 24px;
justify-content: space-between;
margin-top: 24px;
.active {
    border: 1px solid var(--Gray-95, #141414) !important;
}
.select-promotion {
    min-width: 325px;
    width: 325px;
    display: grid;
    gap: 16px;
    padding: 16px;
    justify-content: center;
    align-items: center;
    text-align: center;
    justify-items: center;
    border-radius: 16px;
    border: 1px solid var(--Gray-30, #D9D9D9);
    background: var(--White, #FFF);
    cursor: pointer;
    h3 {
        font-family: Inter;
        font-size: 16px;
        font-weight: 600;
        line-height: 140%;
    }
}
`;
export const IconAddUser = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">
            <path d="M24 24C29.5228 24 34 19.5228 34 14C34 8.47715 29.5228 4 24 4C18.4772 4 14 8.47715 14 14C14 19.5228 18.4772 24 24 24Z" stroke="#1B9AAA" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M6.82031 44C6.82031 36.26 14.5203 30 24.0003 30C25.9203 30 27.7803 30.26 29.5203 30.74" stroke="#1B9AAA" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M44 36C44 36.64 43.92 37.26 43.76 37.86C43.58 38.66 43.26 39.44 42.84 40.12C41.46 42.44 38.92 44 36 44C33.94 44 32.08 43.2199 30.68 41.9399C30.08 41.4199 29.56 40.8 29.16 40.12C28.42 38.92 28 37.5 28 36C28 33.84 28.86 31.86 30.26 30.42C31.72 28.92 33.76 28 36 28C38.36 28 40.5 29.02 41.94 30.66C43.22 32.08 44 33.96 44 36Z" stroke="#1B9AAA" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M38.9795 35.959H33.0195" stroke="#1B9AAA" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M36 33.0391V39.019" stroke="#1B9AAA" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    );
};

export const IconCrown = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="49" height="48" viewBox="0 0 49 48" fill="none">
            <path d="M34.0654 37.9607H15.2654C14.4254 37.9607 13.4854 37.3007 13.2054 36.5007L4.92544 13.3407C3.74544 10.0207 5.12544 9.0007 7.96544 11.0407L15.7654 16.6207C17.0654 17.5207 18.5454 17.0607 19.1054 15.6007L22.6254 6.2207C23.7454 3.2207 25.6054 3.2207 26.7254 6.2207L30.2454 15.6007C30.8054 17.0607 32.2854 17.5207 33.5654 16.6207L40.8854 11.4007C44.0054 9.1607 45.5054 10.3007 44.2254 13.9207L36.1454 36.5407C35.8454 37.3007 34.9054 37.9607 34.0654 37.9607Z" stroke="#1B9AAA" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M13.665 44H35.665" stroke="#1B9AAA" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M19.665 28H29.665" stroke="#1B9AAA" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    );
};
