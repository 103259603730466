import { EBookingStatus } from 'constants/index';
import styled from 'styled-components';

type UIBookingItemStyledProps = {
  bookStatus: string;
  isChildMultiple?: boolean;
  height?: number;
};

const UIBookingItemStyled = styled.div`
    &.memberView {
    }
    width: 100%;
    height: 100%;
    background: ${({ bookStatus }: UIBookingItemStyledProps) => bookStatus === EBookingStatus.COMPLETED ? '#F4FDF7' : EBookingStatus.CANCELLED ? '#FAFAFA' : EBookingStatus.DRAFT ? '#FFF8F2' : EBookingStatus.OPEN ? '#F3FAFB' : 'var(--color-white)'};
    border: none;
    outline: none;
    border-radius: 4px;
    position: relative;
    padding: 6px 10px !important;
    border-top: 1px solid var(--color-purple-06);
    text-align: left;
    font-size: 14px;
    opacity: ${({ bookStatus }: UIBookingItemStyledProps) => bookStatus === EBookingStatus.CANCELLED ? '0.5' : '1'};
    .item-container {
      -webkit-user-select: none; /* Safari */
      -ms-user-select: none; /* IE 10 and IE 11 */
      user-select: none; /* Standard syntax */
  
      width: 100%;
      height: 100%;
      opacity: ${({ bookStatus }: UIBookingItemStyledProps) =>
    bookStatus === EBookingStatus.NO_SHOW ? 0.4 : 1};
    }
    &:before {
      content: '';
      display: block;
      width: 0;
      height: 100%;
      border-left: ${({
      bookStatus,
      isChildMultiple,
    }: UIBookingItemStyledProps) =>
    `4px solid ${getColorBookingStatus(bookStatus, isChildMultiple)}`};
      border-right: 4px solid var(--color-white);
      position: absolute;
      left: 0;
      top: 0;
      border-radius: 8px;
    }
    :hover {
      cursor: pointer;
    }
    .dash {
      /* position: absolute; */
      top: 10px;
      right: 10px;
      display: flex;
    }
    .item-inner {
      max-width: 230px;
      overflow: hidden;
      display: block;
      text-align: left;
      width: 100%;
      height: 100%;
      color: var(--color-primary);
      padding: 0;
    }
    .item-title {
      font-weight: 600;
      font-size: 12px;
      overflow: hidden;
      width: 53%;
      text-overflow: ellipsis;
      text-transform: capitalize;
    }
    .time-container {
      display: flex;
      gap: 4px;
      white-space: nowrap;
      justify-content: space-between;
    }
    .item-time {
      font-size: 12px;
      margin-bottom: 6px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .services {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      &.is-expanded {
        .service-item {
          display: flex;
        }
        .expanded-btn {
          margin-top: 0;
          transform: rotate(0);
          padding-right: 20px;
          span {
            display: block;
            margin-right: 8px;
          }
          &:after {
            right: 12px;
          }
        }
      }
    }
    .service-item {
      display: none;
      &:first-child {
        display: flex;
      }
  
      align-items: center;
      .circle {
        border-radius: 100%;
        width: 12px;
        height: 12px;
        margin-right: 6px;
      }
      .name {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 140%;
        display: flex;
        align-items: center;
        color: #363565;
      }
      .service-name-ellipsis {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 200px;
      @media only screen and (max-width: 767.98px) {
        width: 130px;
      }
      @media only screen and (min-width: 768px) and (max-width: 1180.98px) {
        width: 150px;
      }
    }
    }
    .expanded-btn {
      position: relative;
      font-weight: 500;
      font-size: 12px;
      line-height: 140%;
      color: #949bda;
      margin-left: auto;
      margin-top: -22px;
      transform: rotate(180deg);
      min-width: 24px;
      min-height: 24px;
      pointer-events: none;
      span {
        display: none;
        width: 100%;
        height: 100%;
      }
      &:after {
        content: '';
        display: block;
        width: 8px;
        height: 12px;
        transform: translate(50%, -50%) rotate(-90deg);
        background: var(--icon-gt) center/contain no-repeat;
        right: 50%;
        top: 50%;
        position: absolute;
      }
    }
  `;
export default UIBookingItemStyled;

export const PopoverTitleStyled = styled.div`
    display: flex;
    align-items: center;
    padding: 12px;
    /* background-color: var(--color-purple-05); */
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.06), 0px 1px 3px 0px rgba(0, 0, 0, 0.10);
    .icon-close:hover {
      cursor: pointer;
    }
    .group {
      overflow: hidden;
      display: flex;
      align-items: center;
      flex: 1;
    }
    .avatar {
      background: linear-gradient(315deg, #6587ff 0%, #c883ff 100%);
      margin-right: 12px;
      height: 32px;
      width: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .avatar-blank {
      height: 32px;
      width: 32px;
      background: #f3f3f3;
      border-radius: 90px;
      margin-right: 12px;
    }
    .inner {
      width: 85%;
    }
    .name {
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 140%;
      color: var(--color-primary);
      overflow: hidden;
      text-overflow: ellipsis;
      display: flex;
      justify-content: center;
      margin-left: 80px;
    }
    .id {
      font-weight: 400;
      font-size: 12px;
      line-height: 140%;
      color: var(--color-primary);
      .code {
        font-weight: 600;
      }
    }
  `;

export const PopoverContentStyled = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 16px 24px 24px 24px;
    background: var(--color-white);
    
    @media only screen and (min-width: 992px) {
      width: 560px;
    }
    .border-bottom {
      border-bottom: 1px solid #F0F0F0;
      margin: 0 12px;
    }
    table {
      width: 100%;
    }
    .service-item {
      border-radius: 12px;
      border: 1px solid #BFBFBF;
      overflow: hidden;
    }
    .group {
      overflow: hidden;
      display: flex;
      align-items: center;
      flex: 1;
    }
    .avatar {
      background: linear-gradient(315deg, #6587ff 0%, #c883ff 100%);
      margin-right: 12px;
      height: 60px;
      width: 60px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .ant-avatar-string {
      font-size: 42px;
    }
    .name {
      color: var(--Gray-95, #141414);
      font-family: Inter;
      font-size: 18px;
      font-weight: 600;
      line-height: 140%;
    }
    .phone {
      color: var(--Gray-95, #141414);
      font-size: 14px;
      font-weight: 500;
      line-height: 140%;
    }
    .note-container {
      display: flex;
      gap: 12px;
      padding: 8px;
      background: var(--Blue-Crayola-05, #F7F8FF);
      border-radius: 8px;
      margin: 16px 0;
      width: 100%;
      p {
        overflow: hidden;
        color: var(--Gray-95, #141414);
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: 16px;
        font-weight: 400;
        line-height: 140%;
        width: 100%;
        @media only screen and (max-width: 767.98px) {
          width: 270px;
        }
      }
    }
    .avatar-blank {
      height: 32px;
      width: 32px;
      background: #f3f3f3;
      border-radius: 90px;
      margin-right: 12px;
    }
    .box {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 16px;
      gap: ${({ isEmptyService }: { isEmptyService?: boolean }) =>
    isEmptyService ? '0px' : '16px'};
      width: 100%;
      /* border: 1px solid var(--color-purple-05); */
      border-radius: 12px;
      font-weight: 500;
      font-size: 16px;
      color: var(--color-primary);
      .icon {
        padding-bottom: 16px;
        width: 25px;
      }
    }
    .subtext {
      color: #595959;
    }
    .d-flex {
      display: flex;
      align-items: center;
      .icon {
        margin-right: 10px;
      }
    }
    .button {
      width: 100%;
      margin-top: 24px;
      text-align: center;
    }
  
    .divider {
      height: 1px;
      width: 100%;
      background-color: #f0f2f7;
      margin-bottom: 8px;
    }
  
    .total {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-style: normal;
      /* margin-left: 18px; */
      font-weight: 400;
      font-size: 14px;
      line-height: 140%;
      text-transform: uppercase;
      color: #363565;
      .amount {
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 140%;
        color: #1B9AAA;
      }
    }
    input::placeholder {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 140%;
      color: #363565;
    }
    .title-note {
        color: var(--Gray-95, #141414);
        font-feature-settings: 'clig' off, 'liga' off;
        font-size: 16px;
        font-weight: 600;
        line-height: 140%;
      }
    .input-note-disable {
      -webkit-user-select: none; /* Safari */
      -ms-user-select: none; /* IE 10 and IE 11 */
      user-select: none; /* Standard syntax */
      width:100%;
      display: flex;
      flex-direction: row;
      padding: 20px 16px;
      gap: 8px;
      background: #F5F5F5;
      border-radius: 6px;
      text-align:left;
      overflow: hidden;
      .text-note {
        min-height:11px;
      }
      
      .text-note-show {
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  `;

export const ButtonSuccess = styled.button`
    outline: none;
    border: none;
    display: inline-flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 24px;
    background: var(--Blue-Munsell-Main);
    border-radius: 6px;
    font-weight: 600;
    font-size: 16px;
    line-height: 140%;
    text-align: center;
    /* text-transform: capitalize; */
    color: var(--color-white);
  `;

export const TeamMemberItemStyledOld = styled.button`
      width:100%;
      height:100%;
  `;

const getColorBookingStatus = (
  bookStatus: UIBookingItemStyledProps['bookStatus'],
  isChildMultiple?: boolean
) => {
  switch (bookStatus) {
    case EBookingStatus.COMPLETED:
      return '#1BB250';
    case EBookingStatus.OPEN:
      return '#1B9AAA';
    case EBookingStatus.NO_SHOW:
      return '#BFBFBF';
    case EBookingStatus.DRAFT:
      return '#FF6F00';
    case EBookingStatus.CANCELLED:
      return '#BFBFBF';
    default: {
      if (isChildMultiple) return 'var(--color-yellow-02)';
      return 'var(--color-green)';
    }
  }
};

export const IconCheckCircle = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none">
      <path opacity="0.4" d="M10 5C10 7.76142 7.76142 10 5 10C2.23858 10 0 7.76142 0 5C0 2.23858 2.23858 0 5 0C7.76142 0 10 2.23858 10 5Z" fill="white" />
      <path fillRule="evenodd" clipRule="evenodd" d="M7.23023 3.20401C7.39371 3.33116 7.42316 3.56676 7.29601 3.73024L5.29251 6.30617C4.98065 6.70713 4.39405 6.75917 4.01648 6.41936L2.74914 5.27875C2.5952 5.14021 2.58272 4.9031 2.72127 4.74916C2.85982 4.59521 3.09692 4.58273 3.25087 4.72128L4.51821 5.86189C4.57214 5.91043 4.65594 5.903 4.7005 5.84572L6.704 3.26979C6.83115 3.10631 7.06675 3.07686 7.23023 3.20401Z" fill="white" />
    </svg>
  );
};
export const IconLock = () => {
  return (
    <svg
      width='17'
      height='17'
      viewBox='0 0 17 17'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_3280_47226)'>
        <path
          d='M12.9323 8.27539H3.59896C2.86258 8.27539 2.26562 8.87234 2.26562 9.60872V14.2754C2.26562 15.0118 2.86258 15.6087 3.59896 15.6087H12.9323C13.6687 15.6087 14.2656 15.0118 14.2656 14.2754V9.60872C14.2656 8.87234 13.6687 8.27539 12.9323 8.27539Z'
          stroke='#363565'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M4.93213 8.27539V5.60872C4.93213 4.72467 5.28332 3.87682 5.90844 3.2517C6.53356 2.62658 7.38141 2.27539 8.26546 2.27539C9.14952 2.27539 9.99736 2.62658 10.6225 3.2517C11.2476 3.87682 11.5988 4.72467 11.5988 5.60872V8.27539'
          stroke='#363565'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
      <defs>
        <clipPath id='clip0_3280_47226'>
          <rect
            width='16'
            height='16'
            fill='white'
            transform='translate(0.265625 0.942383)'
          />
        </clipPath>
      </defs>
    </svg>
  );
};
