import { useAppDispatch } from 'store/hooks';
import checkoutSelectors from '../services/selectors';
import checkoutActions from '../services/actions';
import { IServiceSelectedItem } from '../services/types/service';
import { EPaymentMethod } from '../services/types/checkout';

export const useSelectedServices = (): [IServiceSelectedItem[], (a: IServiceSelectedItem[]) => void] => {
  const dispatch = useAppDispatch();

  const data = checkoutSelectors.getSelectedServices();

  const setData = (a: IServiceSelectedItem[]) => {
    dispatch(checkoutActions.setServiceSelected(a));
  };
  return [data, setData];
}; 

const useSetDateBooking = (): [string] => {

  const data = checkoutSelectors.getBookingDate();

  return [data];
};

// type Customer = ICustomerItemResData | null;
// export const useSetCustomerBooking = (): [Customer, (a: Customer) => void] => {
//   const dispatch = useAppDispatch();
  

//   const data = checkoutSelectors.getCustomerInfo();

//   const setData = (a: Customer) => {
//     dispatch(checkoutSelectors.);
//   };

//   return [data, setData];
// };

const usePaymentMethod = (): [EPaymentMethod, (e: EPaymentMethod) => void] => {
  const dispatch = useAppDispatch();
  const method = checkoutSelectors.getPaymentMethod();

  const setMethod = (_method: EPaymentMethod) => {
    dispatch(checkoutActions.setPaymentMethod(_method));
  };
  return [method, setMethod];
};

const checkoutHook = {
  useSelectedServices,
  usePaymentMethod,
  useSetDateBooking
};

export default checkoutHook;
