import { Avatar } from 'antd';
import styled from 'styled-components';
import { itemWidth } from '.';


interface IMemberHorizontalPickerItem {
  name: string;
  isSelected?: boolean;
  mr: number;
  onSelect: () => void;
}


/**
 * Render member horizontal picker item (Mobile)
 * @returns 
 */
const MemberHorizontalPickerItem = ({name, isSelected = false, mr, onSelect}: IMemberHorizontalPickerItem) => {

  
  MemberHorizontalPickerItemStyled.defaultProps = {
    theme: {
      itemWidth
    }
  };

  return (
    <MemberHorizontalPickerItemStyled onClick={onSelect} mr={mr} isSelected={isSelected}>
      <Avatar className='avatar'
      >
        <p>{name.charAt(0)}</p>
      </Avatar>
      <p>
        {name}
      </p>
    </MemberHorizontalPickerItemStyled>
  );
};

export default MemberHorizontalPickerItem;
  
const MemberHorizontalPickerItemStyled = styled.div<{ isSelected: boolean, mr: number }>`
  display: flex;
  gap: 4px;
  padding: 8px 10px 8px 10px;
  border-radius: 10px;
  align-items: center;
  cursor: pointer;
  min-width: ${props => `${props.theme.itemWidth}px`};
  max-width: ${props => `${props.theme.itemWidth}px`};
  transform: ${props => `translate(${props.mr}px, 0px) !important`};
  background-color: ${props => props.isSelected ? '#D1EBEE' : '#FFFFFF'};
  transition: all 0.6s;
  margin: 0px !important;
  .avatar {
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(315deg, #6587ff 0%, #c883ff 100%);
    min-width: 24px;
    height: 24px;
    margin: 0px;
    .ant-avatar-string {
      font-weight: 800;
      font-size: 20px;
      display: flex;
      line-height: 1;
      transform: scale(0.516129) translateX(-100%) !important;
    }
  }

  p {
    width: 100px;
    text-overflow: ellipsis;
    font-weight: 600;
  }
`;

