import { Switch, Tag, message } from 'antd';
import settingActions from 'features/settings/services/actions';
import settingApis from 'features/settings/services/apis';
import settingSelectors from 'features/settings/services/selectors';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useSetLoadingPage } from 'services/UI/LoadingPage';
import { formatMoney } from 'utils/unit';

interface AutomaticTopupProps {
  handleDisplaySetupTopUpModal?: () => void
}

const AutomaticTopup = ({handleDisplaySetupTopUpModal}: AutomaticTopupProps) => {
  const { t: topUpLang } = useTranslation('top_up');
  const { t: settingLang } = useTranslation('setting');
  const topupInitData = settingSelectors.getTopUpInitData();
  const setPageLoading = useSetLoadingPage();
  const dispatch = useDispatch();

  const handleEnableAutoTopUp = async (val?: boolean) => {
    setPageLoading(true);
    try {
      const res = await settingApis.putSwitchAutoTopup(val || false);
      if (res?.data?.code === 1000) {
        dispatch(settingActions.updateAutomaticTopUp(val));
        message.success(res?.data?.message || 'Update successful');
      } else {
        message.error(res?.data?.error?.message || 'Update failed');
      }
      
    } catch (error: any) {
      message.error(error?.response?.data?.error?.message);
    } finally {
      setPageLoading(false);
    }

  };

  return (
    <div>
      <div className='message-remaining-wrap'>
        <p className='remaining-title'>{topUpLang('messageRemaining.text')}</p>
        <div className='money-remaining d-flex no-wrap'>
          <p className='number-sms'>{topupInitData?.number_sms}</p>
          <Tag color='#E6F4FF' className='money-remaining-tag'>
            {formatMoney(topupInitData?.current_balance || 0)} {settingLang('remaining')}
          </Tag>
        </div>
        <p className='remaining-desc'>{topUpLang('messageRemaining.desc')}</p>
        <div className='form-row auto-switch-wrap'>
          <p className='form-label'>
            {topUpLang('messageRemaining.automaticTopUpSwitch')}
          </p>
          <div className='auto-switch'>
            <Switch
              checked={topupInitData?.enable_auto_top_up}
              onChange={(val?: boolean) => {
                if (topupInitData?.current_balance === 0 && val){
                  handleDisplaySetupTopUpModal?.();
                }else {
                  handleEnableAutoTopUp(val);
                }
              }}
            />
          </div>
        </div>
      </div>

      {topupInitData?.enable_auto_top_up ? (
        <div className='auto-to-charge'>
          {topupInitData?.auto_top_up?.number_sms} {settingLang('TextsWillBeAddedToYourBalanceAnd')} ${topupInitData?.auto_top_up?.balance_amount} 
          {settingLang('WillBeChargedToYourCardEachTimeYourTextBalanceDropsTo')} ${topupInitData?.auto_top_up?.balance_drop}.
        </div>
      ) : null}
    </div>
  );
};

export default AutomaticTopup;