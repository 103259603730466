import { CalendarViewType } from 'constants/index';
import { ICustomerItemResData } from 'features/customers/services/types/cutomer';
import { createAction, createAsyncAction } from 'services/actionConfigs';
import { IDaysOfWeekItem } from 'utils/moment/getDatesOfWeek';
import { PREFIX_ACTIONS } from './constants';
import { IApiBookingParams, IBookingStatusItemResData, IEmployeeItemResData, IMerchantLocationItemResData } from './types/booking';
import { IBookingOnlineFormInfoValue, IBookingOnlineFormPromotion } from './types/bookingOnline';
import { IBusyEmployeeItem, ILocationDataConfig, IServiceQuickBookingItem } from './types/quickBooking';
import { IServiceItem } from './types/service';
import { StaffPublic } from 'features/users/services/types/teamMember';
import { PopupType } from './types/reducer';
const getBookingFirstInit = createAction(PREFIX_ACTIONS + 'GET_BOOKINGS_FIRST_INIT');

const getBookings = createAsyncAction<IApiBookingParams>(PREFIX_ACTIONS + 'GET_BOOKINGS');

const getLstTableBookings = createAsyncAction<IApiBookingParams>(PREFIX_ACTIONS + 'getLstTableBookings');

const setCalendarBookingParams = createAction<IApiBookingParams>(PREFIX_ACTIONS + 'SET_CALENDAR_BOOKING_PARAMS');

const setListTableBookingParams = createAction<IApiBookingParams>(PREFIX_ACTIONS + 'setListTableBookingParams');

const setCalendarZoomView = createAction<number>(PREFIX_ACTIONS + 'SET_CALENDAR_ZOOM_VIEW');

const setTeamMembersBooking = createAction<IEmployeeItemResData[]>(PREFIX_ACTIONS + 'SET_TEAM_MEMBERS_BOOKING');

const setLstBookingStatus = createAction<IBookingStatusItemResData[]>(PREFIX_ACTIONS + 'SET_LST_BOOKING_STATUS');

const setLstMerchantLocation = createAction<IMerchantLocationItemResData[]>(PREFIX_ACTIONS + 'SET_LST_MERCHANT_LOCATION');

const setCalendarDaysOfWeek = createAction<IDaysOfWeekItem[]>(PREFIX_ACTIONS + 'SET_CALENDAR_DAYS_OF_WEEK');

const setCalendarViewType = createAction<CalendarViewType>(PREFIX_ACTIONS + 'SET_CALENDAR_VIEW_TYPE');

const setBookingLayout = createAction<string>(PREFIX_ACTIONS + 'SET_BOOKING_LAYOUT');

const getBookingOnlineData = createAsyncAction<string>(PREFIX_ACTIONS + 'GET_BOOKING_ONLINE_DATA');

const getOnlineLocations = createAsyncAction<string>(PREFIX_ACTIONS + 'GET_ONLINE_LOCATIONS');

const bookingOnlineServiceSelected = {
  toggle: createAction<IServiceItem>(PREFIX_ACTIONS + 'BOOKING_ONLINE_SERVICE_SELECTED'),
  set: createAction<IServiceItem[]>(PREFIX_ACTIONS + 'BOOKING_ONLINE_SET_SERVICE_SELECTED')
};

const bookingOnlineSetQuantityServiceItem = createAction<{ id: IServiceItem['id'], service_variant_id: IServiceItem['service_variant_id'], value: number }>(PREFIX_ACTIONS + 'BOOKING_ONLINE_SET_QUANTITY_SERVICE_ITEM');

const setBookingOnlineFormValues = createAction<IBookingOnlineFormInfoValue>(PREFIX_ACTIONS + 'SET_BOOKING_ONLINE_FORM_VALUES');

const setBookingOnlineFormValueItem = createAction<{ key: keyof IBookingOnlineFormInfoValue, value: any }>(PREFIX_ACTIONS + 'SET_BOOKING_ONLINE_FORM_VALUE_ITEM');

const setBookingOnlineActiveLocation = createAction<IMerchantLocationItemResData | null>(PREFIX_ACTIONS + 'SET_BOOKING_ONLINE_ACTIVE_LOCATION');

const setCurrentTime = createAction<string>(PREFIX_ACTIONS + 'SET_CURRENT_TIME');

const checkInBooking = createAction<string>(PREFIX_ACTIONS + 'CHECK_IN_BOOKING');

const cancelBooking = createAction<{ id: string, body: { note: string, reason_id: string | number } }>(PREFIX_ACTIONS + 'CANCEL_BOOKING');

const duplicateBooking = createAction<string>(PREFIX_ACTIONS + 'DUPLICATE_BOOKING');

const setBookingNoShow = createAction<string>(PREFIX_ACTIONS + 'SET_BOOKING_NO_SHOW');

const deleteBooking = createAction<string>(PREFIX_ACTIONS + 'DELETE_BOOKING');

const quickBooking = {
  setServiceLst: createAction<IServiceQuickBookingItem[]>(PREFIX_ACTIONS + 'SET_QUICK_BOOKING_SERVICE_LIST'),
  setEditData: createAction<{
    services: IServiceQuickBookingItem[];
    bookingDate: string;
    bookingStatus: string;
    customer: ICustomerItemResData | null;
    merchantLocationId: string | number;
  }>(PREFIX_ACTIONS + 'SET_QUICK_BOOKING_EDIT_DATA'),
  reset: createAction<{
    merchant_location_id: number | string | null,
    bookingTime: number,
  } | undefined>(PREFIX_ACTIONS + 'RESET_QUICK_BOOKING'),
  setBookingDate: createAction<string>(PREFIX_ACTIONS + 'SET_QUICK_BOOKING_DATE'),
  setCustomer: createAction<ICustomerItemResData | null>(PREFIX_ACTIONS + 'SET_QUICK_BOOKING_CUSTOMER'),
  getDataConfigs: createAsyncAction(PREFIX_ACTIONS + 'QUICK_BOOKING_GET_DATA_CONFIG'),
  setBusyEmployees: createAction<IBusyEmployeeItem[]>(PREFIX_ACTIONS + 'QUICK_BOOKING_SET_BUSY_EMPLOYEES'),
  setMerchantLocationId: createAction<ILocationDataConfig>(PREFIX_ACTIONS + 'QUICK_BOOKING_SET_MERCHANT_LOCATION_ID'),
};

const getActivities = createAsyncAction<string>(PREFIX_ACTIONS + 'GET_ACTIVITY');

const setActivePopover = createAction<string | null>(PREFIX_ACTIONS + 'setActivePopover');

const setHasScrolled = createAction<any>(PREFIX_ACTIONS + 'HAS_SCROLLED');

const getOptionFilter = createAsyncAction(PREFIX_ACTIONS + 'GET_OPTION_FILTER');

const setBookingOnlineActivePromotion = createAction<IBookingOnlineFormPromotion>(PREFIX_ACTIONS + 'SET_BOOK_ONLINE_PROMOTION');

const getParamOptionCalendar = createAsyncAction(PREFIX_ACTIONS + 'GET_PARAM_OPTION_FILTER');

const setBookingOnlineTeamMemberSelected = createAction<StaffPublic[]>(PREFIX_ACTIONS + 'setBookingOnlineTeamMemberSelected');

const setCalendarPopupOpenData = createAction<{
  status: boolean;
  id?: string | number;
  type: PopupType;
  col_id?: string | number;
  is_mutiple?: boolean;
}>(PREFIX_ACTIONS + 'setCalendarPopupOpenData');


const bookingActions = {
  setHasScrolled,
  getBookingFirstInit,
  getBookings,
  getLstTableBookings,
  setTeamMembersBooking,
  setLstBookingStatus,
  setLstMerchantLocation,
  setCalendarBookingParams,
  setListTableBookingParams,
  setCalendarZoomView,
  setCalendarDaysOfWeek,
  setCalendarViewType,
  setBookingLayout,
  getBookingOnlineData,
  bookingOnlineSetQuantityServiceItem,
  bookingOnlineServiceSelected,
  setBookingOnlineFormValues,
  setBookingOnlineFormValueItem,
  setBookingOnlineActiveLocation,
  setCurrentTime,
  quickBooking,
  checkInBooking,
  cancelBooking,
  duplicateBooking,
  setBookingNoShow,
  deleteBooking,
  getOnlineLocations,
  setActivePopover,
  getActivities,
  getOptionFilter,
  setBookingOnlineActivePromotion,
  getParamOptionCalendar,

  setBookingOnlineTeamMemberSelected,
  setCalendarPopupOpenData
};

export default bookingActions;
