import React from 'react';
import ActiveHoursElement from '../widgets/ActiveHoursElement';
import { Form } from 'antd';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { FormInstance } from 'antd/lib/form/Form';
interface Props {
    form?: FormInstance
  }

  /**
   * Hours Of Operation
   * @param form 
   * @returns 
   */
const HoursOfOperation: React.FC<Props> = ({ form }: Props) => {
    const { t: dateLang } = useTranslation('date');

    const dayOfWeek = [
        {
            'label': dateLang('SUN'),
            'value': 'sunday',
        },
        {
            'label': dateLang('MON'),
            'value': 'monday',
        },
        {
            'label': dateLang('TUE'),
            'value': 'tuesday',
        },
        {
            'label': dateLang('WED'),
            'value': 'wednesday',
        },
        {
            'label': dateLang('THU'),
            'value': 'thursday',
        },
        {
            'label': dateLang('FRI'),
            'value': 'friday',
        },
        {
            'label': dateLang('SAT'),
            'value': 'saturday'
        },
        
    ];

    return (
        <HoursOfOperationStyled>
            <div className='cards-col'>
                <p className='form-label'>{'Giờ hoạt động'}</p>
                <div className='form-calendar'>
                    <Form.Item name='calenda'>

                        {dayOfWeek.map((item: any, index) => (
                            <ActiveHoursElement form={form} name={item.value} key={index} label={item.label} />
                        ))
                        }
                    </Form.Item>
                </div>
            </div>
        </HoursOfOperationStyled>
    );
};

export default HoursOfOperation;

const HoursOfOperationStyled = styled.div`
.cards-col {
    width: 500px;
    padding: 24px;
    border-radius: 12px;
    border: 1px solid var(--Gray-30, #D9D9D9);
    @media only screen and (max-width: 767.98px) {
        width: 100%;
        padding: 16px 8px;
    }
}
`;