import { Form, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { IFormAddCustomerData } from 'features/customers/widgets/AddNewCustomer/CustomersForm';
import UserFormFirstStep from './FirstStep';
import UserFormSecondStep from './SecondStep';
import { useAppDispatch } from 'store/hooks';
import userActions from 'features/users/services/actions';
import UserFormFouthStep from './FouthStep';

const DEFAULT_STEP = 1;
const validateMessages = {
  required: '${label} is required!',
  types: {
    email: '${label} is not a valid email!',
    number: '${label} is not a valid number!',
  },
  number: {
    range: '${label} must be between ${min} and ${max}',
  },
};

export interface IFormAddStaffData {
  name?: string;
  phone?: string;
  email?: string;
  accessLevel?: any;
  pinNumber?: string;
  title?: string;
  location?: any;
  isReceiveBook?: boolean;
  services: string[],
  workingTime?: any,
  is_manager?: boolean
}

export type IFuncCancel = () => void;
export type IFuncSubmit = (data: IFormAddCustomerData) => Promise<boolean>;

type Props = {
  onCancel?: () => void;
  onSubmit?: IFuncSubmit;
  formCustom?: any;
  errors?: any;
  isEdit?: boolean;
  defaultData?: IFormAddStaffData;
};
const ModalUserForm: React.FC<Props> = ({
  onCancel = () => undefined,
  onSubmit = () => undefined,
  formCustom,
  errors = {},
  isEdit,
  defaultData,
}) => {
  const [step, setStep] = useState(DEFAULT_STEP);
  const [form] = Form.useForm();
  const [selected, setSelected] = useState<string[]>([]);
  const dispatch = useAppDispatch();

  useEffect(() => {
    form.setFieldValue('services', [...selected]);
  }, [selected]);

  useEffect(() => {
    if (defaultData) {
      setSelected(defaultData.services);
      form.setFieldsValue(defaultData);
      dispatch(userActions.setLocationSelectedId(defaultData?.location?.value));
    }
  }, [defaultData]);  

  useEffect(() => {

    if (Object.keys(errors).some((o: string) => ['full_name', 'phone', 'pin_number'].includes(o))) {
      setStep(1);
      const valid = Object.keys(errors).map(o => ({
        name: o,
        errors: [errors[o]]
      }));
      form.setFields(valid);
    }
    
    
  }, [errors]);

  const handleCancel = () => {
    onCancel();
    setStep(DEFAULT_STEP);
    form.resetFields();
  };

  const nextStep = () => {
    setStep((currPage) => currPage + 1);
  };

  const prevStep = () => {
    setStep((currPage) => currPage - 1);
  };

  const _onSubmit = () => {
    (formCustom ?? form).submit();
  };
  
  const [loading, setLoading] = useState(false);
  const handleSubmit = async (values: any) => {
    setLoading(true);
    try {
      const success = await onSubmit(values);
      if (success) {
        (formCustom ?? form).resetFields();
        setSelected([]);
        setStep(1);
      }
    } catch (error) {
      setStep(1);      
    } finally {
      setLoading(false);
    }
  };

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={handleSubmit}
      initialValues={defaultData}
      validateMessages={validateMessages}

    >
      <Spin spinning={loading}>
        <div style={step !== 1 ? { display: 'none' } : undefined}>
          <UserFormFirstStep defaultData={defaultData} isEdit={isEdit} errors={errors} onCancel={handleCancel} onNextStep={nextStep} />
        </div>
        <div style={step !== 2 ? { display: 'none' } : undefined}>
          <UserFormSecondStep 
            onPrevStep={prevStep} 
            onNextStep={nextStep} 
            // onSubmit={_onSubmit} 
            setSelected={setSelected} 
            selected={selected} 
            isManager={defaultData?.is_manager}
          />
        </div>
        <div style={step !== 3 ? { display: 'none' } : undefined}>
          <UserFormFouthStep onPrevStep={prevStep} onSubmit={_onSubmit} isEdit={isEdit}/>
        </div>
      </Spin>
    </Form>
  );
};

export default ModalUserForm;
