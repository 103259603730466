/* eslint-disable @typescript-eslint/no-inferrable-types */
import { HOUR_MINUTE_FORMAT, TIME_START_FORMAT } from 'features/bookings/services/constants';
import moment, { Moment } from 'moment';

/**
 * Handle get single date short format (MMM DD, YYYY)
 * @param _date 
 * @param t 
 * @returns 
 */
const getSingleDateShortFormat = (_date: Moment, t: any|undefined) => {
  const today = moment();
  
    
  if (_date.isSame(today, 'day')) {
    return t('Today');
  }

  if (_date.isSame(today.clone().subtract(1, 'days'), 'day')) {
    return t('Yesterday');
  }

  if (_date.isSame(today.clone().add(1, 'days'), 'day')) {
    return t('Tomorrow');
  }

  const abbreviatedMonth = _date.format('MMM');
  
  const translatedAbbreviatedMonth = t(abbreviatedMonth);
  
  return _date.format('DD') + ' ' + `${translatedAbbreviatedMonth}` + _date.format(', YYYY');
};

/**
 * Handle get single date and time short format
 * @param _date 
 * @param dateLang 
 * @returns 
 */
const getSingleDateAndTimeShortFormat = (_date: Moment, dateLang: any|undefined) => {
  const abbreviatedMonth = _date.format('MMM');
  
  const translatedAbbreviatedMonth = dateLang(abbreviatedMonth);
  return _date.format('DD ') + `${translatedAbbreviatedMonth}` + _date.format(', YYYY') + ' • ' + _date.format(HOUR_MINUTE_FORMAT);
};

/**
 * Handle get single date format
 * @param _date 
 * @param dateLang 
 * @returns 
 */
const getSingleDateFormat = (_date: Moment, dateLang: any) => {

  const abbreviatedMonth = _date.format('MMM');
  
  const translatedAbbreviatedMonth = dateLang(abbreviatedMonth);
  return _date.format('DD ') + `${translatedAbbreviatedMonth}` + _date.format(', YYYY');
};

/**
 * Handle get format short date and time (MMM DD, YYYY • HH:mm)
 * @param val 
 * @param dateLang 
 * @param template 
 * @returns 
 */
export const getFormatShortDateAndTime = (val: string | null | undefined, dateLang: any|undefined, template?: string) => {
  if (!val) return null;
  const booking_time = moment(val, template);
  if (!booking_time.isValid()) return null;
  return `${getSingleDateAndTimeShortFormat(booking_time, dateLang)}`;
};

/**
 * Handle get format singe date (MMM DD, YYYY)
 * @param val 
 * @param dateLang 
 * @param template 
 * @returns 
 */
export const getFormatSingeDate = (val: string | null | undefined, dateLang: any, template?: string) => {
  if (!val) return null;
  const booking_time = moment(val, template);
  if (!booking_time.isValid()) return null;
  return `${getSingleDateFormat(booking_time, dateLang)}`;
};

/**
 * Handle get single date short format customer (DD.MM.YYYY HH:mm:ss)
 * @param _date 
 * @returns 
 */
const getSingleDateShortFormatCustomer = (_date: Moment) => {
  return _date.format(TIME_START_FORMAT);
};

export const getFormatShortDateCustomer = (val: string | null | undefined, template?: string) => {
  if (!val) return null;
  const booking_time = moment(val, template);
  if (!booking_time.isValid()) return null;
  return `${getSingleDateShortFormatCustomer(booking_time)}`;
};

/**
 * format date to ( Today | Tomorrow | Yesterday | dddd ) • DD.MM.YYYY
 * @param val 
 * @param dateLang 
 * @param template 
 * @returns 
 */
export const getFormatShortDate = (val: string | null | undefined, dateLang?:any|undefined, template?: string, ) => {
  if (!val) return null;
  const booking_time = moment(val, template);
  if (!booking_time.isValid()) return null;
  return `${getSingleDateShortFormat(booking_time, dateLang)} • ${booking_time.format(HOUR_MINUTE_FORMAT)}`;
};

export const getFormatShortDateNoTime = (val: string | null | undefined, dateLang?:any|undefined, template?: string, ) => {
  if (!val) return null;
  const booking_time = moment(val, template);
  if (!booking_time.isValid()) return null;
  return `${getSingleDateShortFormat(booking_time, dateLang)}`;
};

/**
 * format date to ( Today | Tomorrow | Yesterday | dddd ) • DD.MM.YYYY
 * @param _date 
 * @param t 
 * @returns 
 */
export const getWorkingDateShortFormat = (_date: Moment,t: any) => {
  // const _date = moment((params?.start_date ?? 0) * 1000);
  const today = moment();
  if (_date.isSame(today, 'day')) {
    return t('Today');
  }

  if (_date.isSame(today.clone().subtract(1, 'days'), 'day')) {
    return t('Yesterday');
  }

  if (_date.isSame(today.clone().add(1, 'days'), 'day')) {
    return t('Tomorrow');
  }

  return _date.format('DD-MM-YYYY');
};

/**
 * Handle get format time
 * @param time 
 * @param t 
 * @returns 
 */
export const getFormatTime = (time: any, t: any) => {
  const match = time?.match(/^(\d+)([a-zA-Z]+)/);
  let valueToShow: string = '0';
  if (match) {
    const value = match[1];
    const unit = match[2];
    const translatedUnit = t(unit) || unit;
    const translatedText = `${value} ${translatedUnit}`;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    valueToShow = translatedText;
  }
  return valueToShow;
};

export default getSingleDateShortFormat;