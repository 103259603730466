import React, { useEffect, useMemo, useRef, useState } from 'react';
import { IPromotionFormValue } from '../types/promotion';
import { Checkbox, ConfigProvider, DatePicker, Form, Input, InputNumber, Radio, Select } from 'antd';
import styled from 'styled-components';
import { disabledDateBefore } from 'utils/unit';
import dayjs, { Dayjs } from 'dayjs';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import 'dayjs/locale/vi';
import viVN from 'antd/lib/locale/vi_VN';
import enEL from 'antd/lib/locale/en_US';
import MultipleSelect, { IOptionMulti } from 'components/common/MultipleSelect';
import promotionSelectors from '../services/selectors';
import { FormInstance } from 'antd/lib/form/Form';
import FormRow from 'components/common/Form/FormRow';
type Props = {
    errorsText?: Record<string, string>;
    isUsed?: boolean;
    formData?: IPromotionFormValue | null;
    form: FormInstance;
    isCategoryPromotion?: string;
};
const GeneralInformation = ({
    errorsText,
    formData,
    isUsed,
    form,
    isCategoryPromotion
}: Props) => {

    const { t: bookingLang } = useTranslation('booking');
    const { t: formLang } = useTranslation('form');
    const { t: promotionLang } = useTranslation('promotions');
    const lang = 'vi';
    dayjs.locale(lang === 'vi' ? 'vi' : 'en');
    const locale = lang === 'vi' ? viVN : enEL;

    const [initValue, setInitValue] = useState<{ percent?: number, amount?: number }>();
    const [price, setPrice] = useState(form.getFieldValue('amountValue'));
    const [percent, setPercent] = useState(form.getFieldValue('percentValue'));
    const [selectedServices, setSelectedServices] = useState<(string | number)[]>(
        []
    );
    const myFocus = useRef<any>();
    useEffect(() => {
        myFocus?.current?.input?.focus();
    }, []);

    useEffect(() => {
        if (!formData?.amountValue && !formData?.percentValue) return;
        // if(!initValue ) {
        setInitValue({
            amount: formData?.amountValue ?? undefined,
            percent: formData?.percentValue ?? undefined
        });
        // }
    }, [formData]);
    const [valueType, setValueType] = useState('percent');
    const [selectedLocations,] = useState<
        (string | number)[]
    >([]);
    const configsData = promotionSelectors.getConfigsData();
    const serviceOpts = useMemo(() => {
        const result: IOptionMulti[] = [];
        configsData?.services
            .filter((o) => {
                return !o?.merchant_location_id?.find(
                    (d) => !!selectedLocations.find((v) => v.toString() === d.toString())
                );
            })
            ?.forEach((service) => {
                if (service?.service_variants?.length > 0) {
                    service?.service_variants.forEach((serviceVariant) => {
                        result.push({
                            value: service?.id + '-' + serviceVariant.id,
                            title: service.name + ' - ' + serviceVariant.name,
                        });
                    });
                } else {
                    result.push({
                        value: service.id + '',
                        title: service.name,
                    });
                }
            });
        return result;
    }, [configsData, selectedLocations]);

    const [randomString, setRandomString] = useState('');

    useEffect(() => {
        const length = 8;
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let result = '';

        for (let i = 0; i < length; i++) {
            const randomIndex = Math.floor(Math.random() * characters.length);
            result += characters.charAt(randomIndex);
        }

        setRandomString(result);
    }, []);

    console.log('isCategoryPromotion', isCategoryPromotion);
    const [isRadio, setIsRadio] = useState<any>(0);
    const handleRadioChange = (e: any) => {
        setIsRadio(e.target.value);
    };

    return (
        <>
            <div className='form-row'>
                <Form.Item
                    label={isCategoryPromotion !== 'code' ? 'Tên hiển thị (*)' : 'Tên (*)'}
                    name='name'
                    validateStatus={errorsText?.name ? 'error' : undefined}
                    help={errorsText?.name ? errorsText?.name : undefined}
                    rules={[{ required: true, message: promotionLang('PleaseEnterPromotionName') || '' }]}
                >
                    <Input
                        ref={myFocus}
                        placeholder={formLang('EnterPromotionNamePlaceholder') || ''}
                    />
                </Form.Item>
            </div>
            {
                isCategoryPromotion === 'code' ?
                    <div className='form-row'>
                        <Form.Item
                            label={'Mã khuyến mãi (*)'}
                            name='code'
                            help='Bạn có thể thay đổi mã khuyến mãi phù hợp với chiến dịch muốn tạo.'
                        >
                            <Input style={{ textDecoration: 'transparent' }} className='code-voucher' disabled defaultValue={randomString} />
                        </Form.Item>
                    </div>
                    : <></>
            }

            {
                isCategoryPromotion !== 'vist_point' ?
                    <PercenMoneytValue>
                        <div className='form-row groupPrice items-end'>
                            {valueType === 'amount' ? <Form.Item label={formLang('Giá trị khuyến mãi (*)')}
                                name='amountValue'
                                rules={[
                                    {
                                        validator(rule, value, callback) {

                                            if (!initValue?.amount) return callback(undefined);

                                            if (isUsed && value < initValue?.amount) {
                                                callback('A value must be greater previous value');
                                            }
                                            else callback(undefined);
                                        },
                                    },
                                ]}>
                                <Input
                                    // addonBefore='đ'
                                    placeholder={promotionLang('Price') || ''}
                                    disabled={isUsed && !initValue?.amount}
                                    onChange={(val: any) => {
                                        setPrice(val);
                                        setPercent(null);
                                        form.setFieldValue('percentValue', null);
                                    }}
                                    min={0}
                                    type='number'
                                />
                            </Form.Item> : <></>}
                            {valueType === 'percent' ? <Form.Item
                                label={formLang('Giá trị khuyến mãi (*)')}
                                name='percentValue'
                                rules={[
                                    {
                                        validator(rule, value, callback) {

                                            if (!initValue?.percent) return callback(undefined);

                                            if (isUsed && value < initValue?.percent) {
                                                callback('A value must be greater previous value');
                                            }
                                            else callback(undefined);
                                        },
                                    },
                                ]}
                            >
                                <Input
                                    placeholder={promotionLang('Percent') || ''}
                                    max={100}
                                    disabled={isUsed && !initValue?.percent}
                                    onChange={(val: any) => {
                                        setPercent(val);
                                        setPrice(null);
                                        form.setFieldValue('amountValue', null);
                                    }}
                                    min={0.01}
                                    type='number'
                                />
                            </Form.Item> : <></>}
                            <CheckAmountStyled>
                                <p className={`${valueType === 'percent' ? 'active-percent' : ''} button-amount`}
                                    style={{ cursor: 'pointer', textDecoration: valueType === 'percent' ? 'underline' : 'none' }}
                                    onClick={() => setValueType('percent')}
                                >
                                    %
                                </p>
                                <p className={`${valueType === 'amount' ? 'active-amount' : ''} button-amount`}
                                    style={{ cursor: 'pointer', textDecoration: valueType === 'amount' ? 'underline' : 'none' }}
                                    onClick={() => setValueType('amount')}
                                >
                                    đ
                                </p>
                            </CheckAmountStyled>
                        </div>
                    </PercenMoneytValue>
                    : <></>
            }


            <Form.Item
                className='messageError'
                name={'priceOrPercent'}
                rules={[
                    {
                        validateTrigger: 'onChange',
                        validator() {
                            const priceValue = price ?? form.getFieldValue('amountValue');
                            const percentValue =
                                percent ?? form.getFieldValue('percentValue');

                            if (
                                (!priceValue && !percentValue) ||
                                (!!priceValue && !!percentValue)
                            ) {
                                return Promise.reject(
                                    new Error(promotionLang('PleaseFillInValuePriceOrPercentField') || '')
                                );
                            } else if (
                                percentValue &&
                                (percentValue < 0.01 || percentValue > 100)
                            ) {
                                return Promise.reject(
                                    new Error(
                                        `${promotionLang('PercentValueNeedToBeGreaterThan001AndLessThan100')}`
                                    )
                                );
                            }
                            return Promise.resolve();
                        },
                    },
                ]}
                validateStatus={errorsText?.value ? 'error' : undefined}
                help={errorsText?.value ? errorsText?.value : undefined}
                dependencies={['amountValue', 'percentValue']}
            />
            {
                isCategoryPromotion === 'retention' ?
                    <div className="form-row">
                        <Form.Item
                            label="Mốc thời gian (*)"
                            name="startTime"
                            help="Mã khuyến mãi sẽ được gửi đến từng khách hàng đủ điều kiện áp dụng.">
                            <Select
                                options={[
                                    {
                                        value: '1_month',
                                        label: 'Khách chưa ghé thăm hơn 1 tháng',
                                    },
                                    {
                                        value: '2_month',
                                        label: 'Khách chưa ghé thăm hơn 2 tháng',
                                    },
                                ]}
                            />
                        </Form.Item>
                    </div>
                    : <></>
            }

            {
                isCategoryPromotion !== 'vist_point' ?
                    <StartEndDateStyles>
                        <div className='form-row'>
                            <ConfigProvider locale={locale}>
                                <Form.Item
                                    label={'Ngày bắt đầu (*)'}
                                    name='startDate'
                                    rules={[
                                        { required: true, message: promotionLang('PleaseSelectStartDate') || '' },
                                        {
                                            validator: (_, value) => {
                                                const currentDate = moment();

                                                if (value && value.isBefore(currentDate, 'day')) {
                                                    return Promise.reject(
                                                        promotionLang('StartDateMustBeGreaterThanOrEqualToTheCurrentDate') || ''
                                                    );
                                                }
                                                return Promise.resolve();
                                            },
                                        },
                                    ]}
                                    help={'Ngày bắt đầu của chương trình này.'}
                                >
                                    <DatePicker
                                        disabledDate={(current: Dayjs) =>
                                            disabledDateBefore(current, dayjs())
                                        }

                                        format={'DD.MM.YYYY'}
                                        picker='date'
                                        placeholder={bookingLang('SelectDate') || ''}
                                        className='promotion-start-date'
                                    />
                                </Form.Item>
                            </ConfigProvider>
                            {
                                isCategoryPromotion === 'signup' || isCategoryPromotion === 'auto' || isCategoryPromotion !== 'code' ?
                                    <ConfigProvider locale={locale}>
                                        <Form.Item
                                            label={
                                                <p>
                                                    {formLang('Ngày kết thúc')}
                                                </p>
                                            }
                                            name='endDate'
                                            rules={[
                                                ({ getFieldValue }) => ({
                                                    validator(_, value) {
                                                        const startDate = getFieldValue('startDate');

                                                        if (value && startDate && value.isBefore(startDate)) {
                                                            return Promise.reject(
                                                                promotionLang('EndDateMustBeGreaterThanOrEqualToTheStartDate')
                                                            );
                                                        }
                                                        return Promise.resolve();
                                                    },
                                                }),
                                            ]}
                                        >
                                            <DatePicker
                                                disabledDate={(current: Dayjs) =>
                                                    disabledDateBefore(current, dayjs())
                                                }
                                                format={'DD.MM.YYYY'}
                                                placeholder={bookingLang('SelectDate') || ''}
                                                picker='date'
                                                className='promotion-end-date'
                                            />
                                        </Form.Item>
                                    </ConfigProvider>
                                    : <></>
                            }
                        </div>
                        <div className='switch-check'>
                            <Form.Item
                                name={'limitToUsePerCustomer'}
                                valuePropName='checked'
                            >
                                <Checkbox />
                            </Form.Item>
                            <div>
                                <p className='form-label'>{formLang('Ngày kết thúc')}</p>
                            </div>
                        </div>
                    </StartEndDateStyles>
                    : <></>
            }


            {
                isCategoryPromotion === 'signup' ?
                    <CheckboxStyled className='form-row switch-check'>
                        <Form.Item
                            name={'limitToUsePerCustomer'}
                            valuePropName='checked'
                        >
                            <Checkbox />
                        </Form.Item>
                        <div>
                            <p className='form-label'>{formLang('Áp dụng với khách vãng lai')}</p>
                            <p>Áp dụng với khách hàng đã từng để lại thông tin tại cửa hàng.</p>
                        </div>
                    </CheckboxStyled>
                    : <></>
            }

            {
                isCategoryPromotion === 'retention' ?
                    <div className='form-row'>
                        <Form.Item
                            label={
                                <p>
                                    Hạn sử dụng (*)
                                </p>
                            }
                            name='services'
                            validateStatus={errorsText?.services ? 'error' : undefined}
                            extra={errorsText?.services ? errorsText?.services : undefined}
                            help={'Hạn sử dụng khuyến mãi sẽ được tính từ ngày khách hàng nhận được thông báo.'}
                        >
                            <MultipleSelect
                                onChange={setSelectedServices}
                                value={selectedServices}
                                options={serviceOpts}
                                maxTagPlaceholder={promotionLang('services') || ''}
                                placeholder={promotionLang('SelectServices') || ''}
                            />
                        </Form.Item>
                    </div>
                    : <></>
            }
            {
                isCategoryPromotion === 'vist_point' ?
                    <>
                        <FormRow label='Cách đạt điểm (*)' name='gender'>
                            <Radio.Group onChange={handleRadioChange} defaultValue={isRadio}>
                                <Radio value={0}>Lượt ghé thăm</Radio>
                                <Radio value={1}>Dựa trên hoá đơn</Radio>
                            </Radio.Group>
                        </FormRow>

                        {
                            isRadio === 0 ?
                                <PointStyled className='form-row'>
                                    <Form.Item
                                        label={'Điểm nhận được (*)'}
                                        name='name'
                                        validateStatus={errorsText?.name ? 'error' : undefined}
                                        help={'Số điểm nhận được dựa trên 1 lần ghé thăm.'}
                                        rules={[{ required: true, message: promotionLang('PleaseEnterPromotionName') || '' }]}
                                    >
                                        <InputNumber
                                            ref={myFocus}
                                            placeholder={formLang('Nhập điểm') || ''}
                                            type='number'
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        label={'Chi tiêu tối thiểu (*)'}
                                        name='name'
                                        validateStatus={errorsText?.name ? 'error' : undefined}
                                        rules={[{ required: true, message: promotionLang('PleaseEnterPromotionName') || '' }]}
                                    >
                                        <InputNumber
                                            ref={myFocus}
                                            placeholder={formLang('Nhập giá trị') || ''}
                                            addonAfter='đ'
                                            type='number'
                                        />
                                    </Form.Item>
                                </PointStyled>
                                :
                                <PointStyled className='form-row'>
                                    <Form.Item
                                        label={'Điểm nhận được (*)'}
                                        name='name'
                                        validateStatus={errorsText?.name ? 'error' : undefined}
                                        help={'VD Khách hàng sẽ nhận được 1 điểm cho mỗi 10.000đ chi tiêu'}
                                        rules={[{ required: true, message: promotionLang('PleaseEnterPromotionName') || '' }]}
                                    >
                                        <InputNumber
                                            ref={myFocus}
                                            placeholder={formLang('Nhập điểm') || ''}
                                            type='number'
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        label={'Dựa trên X tiền (*)'}
                                        name='name'
                                        validateStatus={errorsText?.name ? 'error' : undefined}
                                        rules={[{ required: true, message: promotionLang('PleaseEnterPromotionName') || '' }]}
                                    >
                                        <InputNumber
                                            ref={myFocus}
                                            placeholder={formLang('Nhập giá trị') || ''}
                                            addonAfter='đ'
                                            type='number'
                                        />
                                    </Form.Item>
                                </PointStyled>
                        }
                        <CheckboxStyled className='form-row switch-check'>
                            <Form.Item
                                name={'limitToUsePerCustomer'}
                                valuePropName='checked'
                            >
                                <Checkbox />
                            </Form.Item>
                            <div>
                                <p className='form-label'>{formLang('Không cộng điểm cho đơn hàng chỉ mua Voucher')}</p>
                            </div>
                        </CheckboxStyled>
                    </>
                    : <></>
            }

        </>
    );
};

export default GeneralInformation;

const PointStyled = styled.div`
.ant-input-number {
    width: 100%;
}
`;

const CheckboxStyled = styled.div`
.ant-form-item {
    width: auto;
}
`;

const PercenMoneytValue = styled.div`
.form-row {
  @media only screen and (max-width: 767.98px) {
    display: grid;
  }
}`;

const StartEndDateStyles = styled.div`
.switch-check {
    display: inline-flex;
    align-items: start;
    gap: 4px;
    .form-label {
        margin-top: 5px;
    }
}
.form-row {
  @media only screen and (max-width: 767.98px) {
    display: grid;
  }
}
`;

const CheckAmountStyled = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    border: 1px solid var(--Gray-30, #D9D9D9);
    .button-amount {
        padding: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: var(--Gray-95, #141414);
        font-size: 16px;
        font-weight: 500;
        line-height: 140%;
        height: 64px;
        width: 56px;
    }
    .active-percent {
        background: var(--Blue-Munsell-Main, #1B9AAA);
        color: var(--White, #FFF);
        font-size: 16px;
        font-weight: 500;
        line-height: 140%;
        border-radius: 10px 0px 0 10px;
    }
    .active-amount {
        background: var(--Blue-Munsell-Main, #1B9AAA);
        color: var(--White, #FFF);
        font-size: 16px;
        font-weight: 500;
        line-height: 140%;
        border-radius: 0 10px 10px 0;
    }
`;