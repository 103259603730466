import checkoutActions from 'features/checkout/services/actions';
import checkoutSelectors from 'features/checkout/services/selectors';
import { EPaymentMethod, IMixPaymentItem } from 'features/checkout/services/types/checkout';
import { findIndex } from 'lodash';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { formatMoney } from 'utils/unit';

/**
 * Result amount row
 * @param step 
 * @returns 
 */
const ResultAmountRow = ({ }: { step: number }) => {
  const totalOriginal = checkoutSelectors.getOriginTotalPrice();
  const voucherSelectedValue = checkoutSelectors.getVoucherValue();
  const method = checkoutSelectors.getPaymentMethod();

  const currMixVoucher = checkoutSelectors.getMixPayments().find(o => o.type === EPaymentMethod.VOUCHER);
  const curMixPayments = checkoutSelectors.getMixPayments();
  const dispatch = useDispatch();

  const getTotal = useMemo(() => {
    const voucherValue = voucherSelectedValue;
    const idx = findIndex(curMixPayments, o => o.type === EPaymentMethod.CASH);
    const data: IMixPaymentItem = {
      data: totalOriginal - voucherValue,
      type: EPaymentMethod.CASH,
      value: totalOriginal - voucherValue ?? 0,
    };
    if (idx) {
      const newMixPayments = curMixPayments.map(o => {
        if (o.type === EPaymentMethod.CASH) {
          return ({
            ...o,
            ...data,
          });
        }
        return o;
      });
      dispatch(checkoutActions.setMixPayments(newMixPayments));
    }
    return totalOriginal - voucherValue;
  }, [method, totalOriginal, voucherSelectedValue, currMixVoucher]);

  const { t: bookingLang } = useTranslation('booking');
  return (
    <ResultAmountRowStyled>
      <p className='total'>
        {bookingLang('TOTAL')}<div className='form-note-mobile'>({bookingLang('AllPricesIncludeGST')})</div>
        <span>{formatMoney(getTotal > 0 ? getTotal : 0)}</span>
      </p>
      {/* <div className='form-note'>{bookingLang('AllPricesIncludeGST')}</div> */}
    </ResultAmountRowStyled>
  );
};

export default ResultAmountRow;

const ResultAmountRowStyled = styled.div`
.form-note {
  display: block;
  @media only screen and (max-width: 767.98px) {
    display: none;
  }
}
.form-note-mobile {
  display: none;
  @media only screen and (max-width: 767.98px) {
      display: flex;
      align-items: center;
      font-style: italic;
      font-weight: 500;
      font-size: 14px;
      color: var(--color-purple-06);
      padding: 10px 0;
    }
}
`;
