import { Modal} from 'antd';
import { AxiosResponse } from 'axios';
import message from 'components/common/Message';
import userActions from 'features/users/services/actions';
import userApis from 'features/users/services/apis';
import { get, set } from 'lodash';
import { useState } from 'react';
import { useAppDispatch } from 'store/hooks';
import storage from 'utils/sessionStorage';
import ModalUserForm, { IFormAddStaffData, IFuncSubmit } from '../ModalUserForm';
import { DayLocation, WorkingTime } from 'features/locations/services/types/location';
import { WorkTimeStatus } from '../ModalUserForm/FouthStep';
import bookingActions from 'features/bookings/services/actions';
import bookingSelectors from 'features/bookings/services/selectors';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import userSelectors from 'features/users/services/selectors';

const AddNewUser = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const dispatch = useAppDispatch();
  const [errors, setErrors] = useState({});
  const loadingDataConfig = bookingSelectors.quickBooking.getLoadingDataConfigs();
  const getShowCreateStaffs = userSelectors.getShowCreateStaffs();
  const accessLevelOptions = getShowCreateStaffs?.access_levels;

  const {t: userLang} = useTranslation('user');
  const showModal = () => {
    dispatch(bookingActions.quickBooking.getDataConfigs.fetch());
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setErrors({});
  };

  const getPayload = (data: IFormAddStaffData) => {
    const merchant_id = storage.merchantId.get() ?? '';
    const full_name = data?.name;
    const phone = data?.phone ?? '';
    // const email = data?.email ?? '';
    const pin_number = data?.pinNumber;
    const access_level = accessLevelOptions?.[1]?.value ?? accessLevelOptions?.[0].value;
    const title_name = data?.title ?? '';
    const merchant_location_id = data?.location;
    const is_receive_book = data?.isReceiveBook;
    const password = data?.password;
    const role_id = data?.role;
  
    const weekWorkingTime: any = [];
      Object.values(data.workingTime as WorkingTime).forEach((workTime) => {
        workTime.forEach((element: any) => {
          if (element?.status !== WorkTimeStatus.UNAVAILABLE){
            weekWorkingTime.push(element);
          }       
        });
      });
      
      const weekday_working_times = weekWorkingTime.map((item: DayLocation) => ({
        weekday: item?.weekday,
        time_start: item?.time_start?.format('HH:mm:ss'),
        time_end: item?.time_end?.format('HH:mm:ss'),
      }));
  
    const services: {
      id: number,
      service_variant_ids: number[]
    }[] = [];
  
    data.services?.forEach(ids => {
      const [parentId, subId] = ids.split('-');
      const parent = services.find(o => o.id === +parentId);
      if (parent) {
        const idx = parent.service_variant_ids.indexOf(+subId);
        if (idx === -1) {
          parent.service_variant_ids.push(+subId);
        }
      } else {
        services.push({
          id: +parentId,
          service_variant_ids: [+subId],
        });
      }
    });
  
    const payload: any = {
      merchant_id,
      full_name,
      phone,
      // email,
      access_level,
      title_name,
      merchant_location_id,
      services,
      pin_number,
      is_receive_book,
      weekday_working_times,
      password,
      role_id
    };
    return payload;
  };

  const handleSubmit = async (values: any) => {
    setErrors({});
    const payload = getPayload(values);
    
    try {
      const rest: AxiosResponse<{ message: string }> =
        await userApis.createStaffs(payload);

      if (rest.data) {
        message.success(rest.data.message);
        dispatch(userActions.setParams({
          page: 1,
          per_page: 10,
        }));
        handleCancel();
        return true;
      }
      return false;
    } catch (error: any) {
      const response = get<Record<string, string[]>>(
        error,
        'response.data.errors',
        {}
      );

      const errors = Object.entries(response);
      const errorsObj = {};
      errors.forEach(([key, value]) => {
        set(errorsObj, [key], value?.[0]);
      });
      setErrors(errorsObj);

      errors.map(([, value]) => {
        message.error(get(value, [0], ''));
      });
      throw error;
    }
  };

  return (
    <>
      <button onClick={showModal} className={'common-btn'}>
        {userLang('AddNew')}
      </button>
      <ModalAddNew errors={errors} visible={isModalOpen} handleSubmit={handleSubmit} handleCancel={handleCancel} modalLoading={loadingDataConfig}/>
    </>
  );
};
type ModalProps = {
  errors?: any;
  visible?: boolean;
  handleCancel?: () => void;
  handleSubmit: IFuncSubmit;
  modalLoading?: boolean
};

const ModalAddNew = ({ errors, handleSubmit, handleCancel = () => undefined, visible }: ModalProps) => {

  if (!visible) return null;
  return (
    <ModalStyled
      maskClosable={false}
      open={visible}
      onCancel={handleCancel}
      okButtonProps={{ style: { display: 'none' } }}
      closable={false}
      footer={null}
      width={1200}
      forceRender
      centered
    >
      
      {/* <Spin spinning={modalLoading}> */}
        <ModalUserForm
          defaultData={{
            services: [],
          }}
          errors={errors}
          onCancel={handleCancel}
          onSubmit={handleSubmit}
        />
      {/* </Spin> */}
    </ModalStyled>
  );
};

export default AddNewUser;

const ModalStyled = styled(Modal)`
  display: flex;
  justify-content: center;
  .ant-modal-content {
    position: relative;
  }

  .ant-modal-content {
    padding: 0px;
  }
  .modal-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: sticky;
    border-bottom: 1px solid #1B9AAA;
    background: white;
    padding: 50px 12px 34px;
    z-index: 99999;
    width: 100%;
    height: 54px;
    top: 0px;
    left: 0px;

    .button-close {
      cursor: pointer;
      width: 200px;
    }
  }

  .form-container {
    display: flex;
    justify-content: center;
    margin-bottom: 48px;
    padding: 24px;
  }

  .round {
    border: 1px solid #BFBFBF;
    border-radius: 12px;
    padding: 24px;
    max-width: 500px;
    margin: 24px 24px 0px 0px;

    .title {
      font-weight: 700;
      font-size: 20px;
    }

    .helper {
      color: #595959;
      font-size: 12px;
      font-weight: 400;
    }

    .label {
      margin-top: 24px;
      font-size: 16px;
      font-weight: 500;
    }
  }

  .button {
    display: flex;
    gap: 8px;
  }

`;

