import { get } from 'lodash';
import { RootState } from 'store';
import { useAppSelector } from 'store/hooks';
import uiSelector from 'services/UI/selectors';
import { PATH_LOADING } from './constants';


type MyState = RootState['location'];

const getCurrentState = (state: RootState): MyState => state.location;

const selector = (key: keyof MyState, defaultValue?: any) => useAppSelector(state => get(getCurrentState(state), key, defaultValue));

const getPaginateListCategory = () => selector('listPaginateCategory') as MyState['listPaginateCategory'];

const loadingCategoryPaginateList = () => uiSelector.getLoading(PATH_LOADING.getListPaginateCategory);


const productSelectors = {
  getPaginateListCategory,
  loadingCategoryPaginateList,
};

export default productSelectors;
