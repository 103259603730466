import { Slider } from 'antd';
import bookingActions from 'features/bookings/services/actions';
import apisBooking from 'features/bookings/services/apis';
import bookingSelectors from 'features/bookings/services/selectors';
import React, { memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from 'store/hooks';

/**
 * render zoom calendar
 * @returns 
 */
const ZoomCalendar: React.FC = () => {
  const value = bookingSelectors.getZoomView();
  const dispatch = useAppDispatch();
  const setZoomCalendar = async (val: number) => {
    dispatch(bookingActions.setCalendarZoomView(val));
    await apisBooking.storeParamOptionCalendar({action_behavior: 'calendar_zoom', action_behavior_value: val});
  };
  

  const [valueSlider, setValueSlider] = useState<number>();

  const _1hr = 0;
  const _30mins = 50;
  const _15mins = 100;

  const range_1hr = [_1hr, (_30mins + _1hr) / 2];
  const range_30mins = [(_30mins + _1hr) / 2, (_15mins + _30mins) / 2];
  const range_15mins = [(_15mins + _30mins) / 2, _15mins];

  const checkRangeSlider = (range: number[], value: number) => range[0] <= value && range[1] >= value;
  

  const onSliderChange = async (val: number) => {
    
    switch (true) {
      case checkRangeSlider(range_1hr, val):
        setValueSlider(_1hr);
        return setZoomCalendar(60);

      case checkRangeSlider(range_30mins, val):
        setValueSlider(_30mins);
        return setZoomCalendar(30);

      case checkRangeSlider(range_15mins, val):
        setValueSlider(_15mins);
        return setZoomCalendar(15);
    }


  };
  const { t: bookingLang } = useTranslation('booking');
  return (
    <div>
      <p className='menu-title'>{bookingLang('CalendarZoom')}</p>
      <Slider
        tooltip={{ open: false }}
        defaultValue={
          value === 60 ? 0 : value === 30 ? _30mins : _15mins
        }
        value={valueSlider}
        min={_1hr}
        max={_15mins}
        step={1}  
        onAfterChange={(onSliderChange)}
        onChange={(e) => setValueSlider(e)}
        handleStyle={{
          height: '40px',
          width: '40px', 
          top: '150%',
          marginLeft: '12px'
        }}
      />
      <ul className='slider-control'>
        <li onClick={() => setZoomCalendar(60)}>1 {bookingLang('hr')}</li>
        <li onClick={() => setZoomCalendar(30)}>30 {bookingLang('mins')}</li>
        <li onClick={() => setZoomCalendar(15)}>15 {bookingLang('mins')}</li>
      </ul>
    </div>
  );
};

export default memo(ZoomCalendar);