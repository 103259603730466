import { Column } from '@ant-design/charts';
import { isArray } from 'lodash';
import moment from 'moment';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

/**
 * Chart visit rate
 * @returns 
 */
const ChartVisitRate = () => {

    const {t: dateLang} = useTranslation('date');

    const data = [
        [
            {
                date: '2024-01-03',
                visit: 50,
                type: 'Tháng trước'
            },
            {
                date: '2024-01-03',
                visit: 30,
                type: 'Tháng này'
            }
        ],
        [
            {
                date: '2024-01-04',
                visit: 20,
                type: 'Tháng trước'
            },
            {
                date: '2024-01-04',
                visit: 30,
                type: 'Tháng này'
            }
        ],
        [
            {
                date: '2024-01-05',
                visit: 20,
                type: 'Tháng trước'
            },
            {
                date: '2024-01-05',
                visit: 30,
                type: 'Tháng này'
            }
        ],
        [
            {
                date: '2024-01-06',
                visit: 20,
                type: 'Tháng trước'
            },
            {
                date: '2024-01-06',
                visit: 30,
                type: 'Tháng này'
            }
        ],
        [
            {
                date: '2024-01-07',
                visit: 20,
                type: 'Tháng trước'
            },
            {
                date: '2024-01-07',
                visit: 30,
                type: 'Tháng này'
            }
        ],
        
    ];

    const _data = useMemo(() => {
        if (!data) return [];
        if (!isArray(data)) {
          const result: {
            xField: string;
            type: string;
            value: number;
          }[] = [];
          // @ts-ignore
          Object.entries(saleFigures).forEach(([key, values]: [string, { type: string, total_price: number }[]]) => {
            values?.forEach(o => {
              result.push({
                xField: dateLang(key),
                type: o.type,
                value: o.total_price,
              });
            });
          });
          return result;
        }
        
        const result: {
          xField: string;
          type: string;
          value: number;
        }[] = [];
        data.forEach((arr: { type: string, visit: number, date: string }[]) => {
          arr?.forEach(o => {
            result.push({
              xField: moment(o.date, 'YYYY-MM-DD').format('DD.MM'),
              type: o.type,
              value: Number(o.visit?.toFixed(2)),
            });
          });
        });
    
        return result;
      }, [data]);

    return (
        <>
            <Column
                data={_data}
                xField='xField'
                yField='value'
                seriesField='type'
                isGroup
                legend={false}
                yAxis={{
                    label: {
                        formatter: (text) => `${text}`,
                    },
                    title: {
                        text: 'Lần',
                        style: {
                            fill: 'black',
                            fontSize: 14, 
                            fontWeight: 'bold',
                        },
                    },
                }}
                columnStyle={(datum) => {
                    let radiusValue = [0, 0, 0, 0];
                    if (datum.type === 'Tháng trước') {
                        radiusValue = [20, 20, 0, 0];
                        return { fill: '#FC0', radius: radiusValue };
                    } else if (datum.type === 'Tháng này') {
                        radiusValue = [20, 20, 0, 0];
                        return { fill: '#40E0D0', radius: radiusValue };
                    }
                    return { fill: 'gray', radius: radiusValue };
                }}
            />
        </>
    );
};

export default ChartVisitRate;