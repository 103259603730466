import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import actions from './actions';
import { ISagaFunc } from 'services/actionConfigs';
import { IApiListVoucherParams } from './types/api';
import { AxiosResponse } from 'axios';
import { IOptPeriodItemResData, IVoucherItemResData, IVoucherListResData } from './types/voucher';
import apis from './apis';
import { PATH_LOADING } from './constants';
import { setLoading } from 'services/UI/sagas';
import { getPureParams } from './selectors';
import { IServiceItemResData } from './types/service';
import { get } from 'lodash';
import message from 'components/common/Message';

const setVoucherListParams: ISagaFunc<IApiListVoucherParams> = function* ({ payload }) {
  const _params = yield select(getPureParams);
  const params = {
    ..._params,
    ...payload ?? {},
  };
  if (params.status === undefined || params.status === null) {
    delete params.status;
  }
  if (params.keyword === undefined || params.keyword === null) {
    delete params.keyword;
  }

  yield setLoading(PATH_LOADING.getListVouchers, true);
  try {
    const res: AxiosResponse<IVoucherListResData> = yield call(apis.getListVouchers, params);
    if (res.data.data) {
      yield put(actions.setVoucherListParams.success(res.data));
    } else {
      throw 'fail';
    }
  } catch (error) {
    yield put(actions.setVoucherListParams.fail(error));
  } finally {
    yield setLoading(PATH_LOADING.getListVouchers, false);
  }
};


const getDataConfigs = function* () {
  yield all([
    put(actions.getLstPeriodOpts.fetch()),
    put(actions.getAllServices.fetch()),
  ]);
};
const getLstPeriodOpts = function* () {
  try {
    const res: AxiosResponse<{ data: IOptPeriodItemResData[] }> = yield call(apis.getListOptsPeriod);
    if (res.data.data) {
      yield put(actions.getLstPeriodOpts.success(res.data.data));
    } else {
      throw 'fail';
    }
  } catch (error) {
    yield put(actions.getLstPeriodOpts.fail(error));
  }
};

const getAllServices = function* () {
  try {
    const res: AxiosResponse<{ data: IServiceItemResData[] }> = yield call(apis.getAllService);
    if (res.data.data) {
      yield put(actions.getAllServices.success(res.data.data));
    } else {
      throw 'fail';
    }
  } catch (error) {
    yield put(actions.getAllServices.fail(error));
  }
};

const deleteVoucher: ISagaFunc<string> = function* ({ payload }) {
  const id = payload;
  yield setLoading(PATH_LOADING.voucherActions, true);
  try {
    const res = yield call(apis.deleteVoucher, id);
    const msg = get(res, 'data.message');
    if (msg) {
      message.success(msg);
      yield put(actions.setVoucherListParams.fetch({}));
    } else {
      const msgError = get(res, 'data.error.message');
      if (msgError) message.error(msgError);
      else throw 'fail';
    }
  } catch (error) {
    const errors = Object.entries(get(error, 'response.data.errors', {}));
    if (errors.length > 0) {
      errors.map(([, valueArr]) => {
        message.error(get(valueArr, [0], ''));
      });
      return;
    }

    message.error('An error occurred. Please try again');
  } finally {
    yield setLoading(PATH_LOADING.voucherActions, false);
  }
};

const getVoucherDetail: ISagaFunc<{voucherCode: string, type: string}> = function* ({ payload }) {
  yield setLoading(PATH_LOADING.getVoucherDetail, true);

  try {
    const res: AxiosResponse<{ data: IVoucherItemResData }> = yield call(apis.getSingleVoucher, payload?.voucherCode, payload?.type);
    if (res?.data?.data) {
      yield put(actions.getVoucherDetail.success(res.data.data));
    } else {
      throw 'fail';
    }
  } catch (error) {
    yield put(actions.getVoucherDetail.fail({}));
  } finally {
    yield setLoading(PATH_LOADING.getVoucherDetail, false);
  }
};

export default function* voucherServiceSaga() {
  yield takeLatest(actions.setVoucherListParams.fetch, setVoucherListParams);
  yield takeLatest(actions.getDataConfigs, getDataConfigs);
  yield takeLatest(actions.getAllServices.fetch, getAllServices);
  yield takeLatest(actions.getLstPeriodOpts.fetch, getLstPeriodOpts);
  yield takeLatest(actions.deleteVoucher, deleteVoucher);
  yield takeLatest(actions.getVoucherDetail.fetch, getVoucherDetail);
}
