import bookingSelectors from 'features/bookings/services/selectors';
import moment from 'moment';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import { LabelStyled } from './BasicContent';
import settingSelectors from 'features/settings/services/selectors';
import { useTranslation } from 'react-i18next';
const BookingDetails: React.FC = () => {
  const formValue = bookingSelectors.bookingOnline.getFormValues();
  const bookingTime = bookingSelectors.bookingOnline.getBookingTime();

  const timeStart = useMemo(() => moment(bookingTime?.start), [bookingTime?.start]);
  const timeEnd = useMemo(() => moment(bookingTime?.finish), [bookingTime?.finish]);
  const serviceSelected = bookingSelectors.bookingOnline.getSelectedServices();
  const setting = settingSelectors.getSettingBookingForm();

  const {t: bookingLang} = useTranslation('booking');
  
  const isTimeRoster: boolean = useMemo( () => setting.enable_timeroster_management, [setting.enable_timeroster_management]);

  return (
    <BookingDetailsStyled>
      <LabelStyled>{bookingLang('BookingDetails')}</LabelStyled>
      <table className='booking_details'>
        <tbody>
          <tr>
            <td className='label'>{bookingLang('Date')}</td>
            <td className='value booking_date'>
              <p className='booking_date_value'>{isTimeRoster ? moment(bookingTime?.finish).format('DD.MM.YYYY') : moment(formValue?.bookingDate).format('DD.MM.YYYY')}</p>
            </td>
          </tr>
          <tr>
            <td className='label'>{bookingLang('Time')}</td>
            <td className='value booking_date'>
              <p className='booking_date_value'>
                {!timeStart.isSame(timeEnd) ? <>
                  {timeStart.format('HH:mm')}
                  {serviceSelected.length > 0 ? ` - ${timeEnd.format('HH:mm')}` : ''}
                </> : <>
                  {timeStart.format('HH:mm')}
                  {serviceSelected.length > 0 ? ` - ${timeEnd?.add(10, 'minute').format('HH:mm')}` : ''}                  
                </>}
              </p>
            </td>
          </tr>
        </tbody>
      </table>
    </BookingDetailsStyled>
  );
};

export default BookingDetails;

const BookingDetailsStyled = styled.div`
.booking_details {
  width:100%;
  td {
    font-weight: 400;
    color: var(--color-primary);
    font-size: 16px;
    line-height: 140%;
    padding-bottom: 8px;
    &.label {
      width: 25%;
    }
    &.value {
      width: 70%;
      font-weight: 600;
      p {
        padding-bottom: 8px;
      }
    }
  }
  .last_row {
    td {
      padding-bottom: 0px;
      &.label {
        width: 25%;
      }
      &.value {
        width: 70%;
        font-weight: 600;
        p {
          padding-bottom: 0px;
        }
      }
    }
  }
}
`;