import { useEffect, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useAppDispatch } from 'store/hooks';
import StaffFilter from '../components/StaffFilter';
import StaffTable from '../components/StaffTable';
import actions from '../services/actions';
import settingActions from 'features/settings/services/actions';

const UserPage = () => {
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const fetchDataStatus = useRef<boolean>(false);

  useEffect(() => {
    dispatch(settingActions.getListRole.fetch());
  }, []);

  useEffect(() => {
    if (!fetchDataStatus?.current){
      dispatch(actions.setParams({
        page: Number(searchParams.get('page') ?? 1),
        per_page: Number(searchParams.get('per_page') ?? 10),
        keyword: searchParams.get('keyword') ?? undefined,
      }));
      dispatch(actions.getShowCreateStaffs.fetch());
      fetchDataStatus.current = true;
    }
  }, []);

  return (
    <>
      <StaffFilter />
      <StaffTable />
    </>
  );
};

export default UserPage;
