import React from 'react';
import ListView from './List';
// eslint-disable-next-line @typescript-eslint/ban-types
type ILayoutDetailViewProps = {};
const LayoutDetailView: React.FC<ILayoutDetailViewProps> = () => {
  
  return <ListView />;
};

export default LayoutDetailView;
