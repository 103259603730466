import { VIEW } from 'constants/view.enum';
import { useAppDispatch } from 'store/hooks';
import bookingActions from '../../../services/actions';
import bookingSelectors from '../../../services/selectors';

/**
 * Render select layout
 * @returns 
 */
function SelectLayout() {
  const dispatch = useAppDispatch();
  const currentView = bookingSelectors.getBookingLayout();
  const setCurrentView = (val: string) => {
    dispatch(bookingActions.setBookingLayout(val));
  };
  return (
    <ul className='common-list-views'>
      <li
        onClick={() => setCurrentView(VIEW.GRID)}
        className={`grid-view ${currentView === VIEW.GRID && 'is-active'
          }`}
      ></li>
      {/* <li
        onClick={() => setCurrentView(VIEW.LIST)}
        className={`list-view ${currentView === VIEW.LIST && 'is-active'
          }`}
      ></li> */}
      <li
        onClick={() => setCurrentView(VIEW.CALENDAR)}
        className={`calendar-view ${currentView === VIEW.CALENDAR && 'is-active'
          }`}
      ></li>
    </ul>
  );
}

export default SelectLayout;
