import React from 'react';

const IconUpload = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="48" height="49" viewBox="0 0 48 49" fill="none">
  <path d="M42 30.5V38.5C42 39.5609 41.5786 40.5783 40.8284 41.3284C40.0783 42.0786 39.0609 42.5 38 42.5H10C8.93913 42.5 7.92172 42.0786 7.17157 41.3284C6.42143 40.5783 6 39.5609 6 38.5V30.5" stroke="#141414" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
  <path d="M34 16.5L24 6.5L14 16.5" stroke="#141414" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
  <path d="M24 6.5V30.5" stroke="#141414" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
</svg>
    );
};

export default IconUpload;