import { Form, Input, Modal, Select, TimePicker } from 'antd';
import IconDecrease from 'assets/svg/IconDecrease';
import IconIncrease from 'assets/svg/IconIncrease';
import dayjs from 'utils/dayjs';
// import { getDisabledHours, getDisabledMinutes } from 'features/bookings/hooks/disableTime';
// import getTimeOpening from 'features/bookings/hooks/getTimeOpening';
import bookingActions from 'features/bookings/services/actions';
import { BREAK_TIME_MINUTES } from 'features/bookings/services/constants';
import bookingSelectors from 'features/bookings/services/selectors';
import { IServiceQuickBookingItem } from 'features/bookings/services/types/quickBooking';
import { findIndex, first, get, last, uniqBy } from 'lodash';
import moment from 'moment';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
// import formatNumber from 'utils/formatNumber';
import { ANONYMOUS_NAME, formatMoney, formatTimeMinutes } from 'utils/unit';
import VerifyPin from 'widgets/VerifyPin';

type Props = {
  isOpenModal: boolean;
  handleCancel: any;
  dataService: IServiceQuickBookingItem;
  handleAddServices: any;
  currServices: IServiceQuickBookingItem[];
  isInProgress: boolean;
  disableSelectTeamMember?: boolean;
};

const NewServicesModal = (props: Props) => {

  const {
    isOpenModal,
    handleCancel = () => undefined,
    dataService,
    handleAddServices = () => undefined,
    currServices = [],
    isInProgress = false,
  } = props;
  const dispatch = useAppDispatch();
  const [quantity, setQuantity] = useState<number>(1);
  const [form] = Form.useForm();
  const [formBookingDate, setFormBookingDate] = useState<string>();
  const bookingDate = bookingSelectors.quickBooking.getBookingDate();
  // const workingHour = bookingSelectors.getWorkingHour();

  // const activeLocation = bookingSelectors.quickBooking.getMerchantLocationActive();
  const merchant_location_id = bookingSelectors.quickBooking.getMerchantLocationId();
  const [visibleVerifyPin, setVisibleVerifyPin] = useState(false);
  const actionSubmitRef = useRef<() => void>(() => undefined);
  const { t: bookingLang } = useTranslation('booking');
  const { isEdit, currentService } = useMemo(() => {
    const currentIdx = findIndex(currServices, (o: IServiceQuickBookingItem) => o.service_variant_id === undefined ? o.id === dataService.id : o.id === dataService.id && o.service_variant_id === dataService.service_variant_id);
    return {
      isEdit: currentIdx !== -1,
      isFirstItem: currentIdx === 0,
      currentIdx,
      currentService: get(currServices, [currentIdx]) as (IServiceQuickBookingItem | undefined),
    };
  }, [currServices, dataService]);
  
    

  const memberOpts = useMemo(() => {

    if(!dataService || !merchant_location_id) return [];

    const result = dataService?.merchant_employees?.filter(o => {
      if (o.merchant_location_id?.toString() !== merchant_location_id?.toString()) return false;
      return o.is_receive_book === 1;

    }).map((employe: any) => {
      const { full_name, id } = employe;
      return {
        value: id,
        label: full_name,
      };
    }).concat([{
      label: bookingLang(ANONYMOUS_NAME === 'Any team member' ? 'AnyTeamMember' : ''),
      value: '',
    }]);

    return uniqBy(result, o => o.value);
  }, [dataService, merchant_location_id]);

  const currentTotalService = useMemo(() => {
    if(!dataService) return 0;

    const price = (dataService?.sale_price ?? 0) || (dataService?.regular_price ?? 0) || (dataService?.service_price ?? 0);
    return +(price * quantity).toFixed(2);
  }, [dataService, quantity]);


  const handleDecrease = () => {
    if (quantity === 1) {
      return;
    }
    setQuantity(() => quantity - 1);
  };

  const handleIncrease = () => {
    setQuantity(() => quantity + 1);
  };

  useEffect(() => {
    form.setFieldValue('quantity', quantity);
  }, [quantity]);

  useEffect(() => {
    form.resetFields();
    setQuantity(1);
  }, [dataService]);

  const getTimeStart = () => {
    let timePicker;

    if (!isEdit) {
      if (currServices.length === 0) {
        timePicker = dayjs(bookingDate);
      } else {
        const lastItem = last(currServices);

        const breakTimeMinutes = BREAK_TIME_MINUTES;
        const prevServiceMinutes = (lastItem?.duration_time ?? 0) * (lastItem?.quantity ?? 0);
        const nextTimeStart = moment(lastItem?.time_start).add(breakTimeMinutes + prevServiceMinutes, 'minute');

        timePicker = dayjs(nextTimeStart.format());
      }
    } else {
      timePicker = dayjs(currentService?.time_start);
    }
    return timePicker;
  };

  const getSelectedMember = () => {
    if (!isEdit) {
      return get(first(memberOpts), 'value');
    }
    return dataService?.employee_id || dataService?.assigned_employee?.employee?.id;
  };

  const { t: dateLang } = useTranslation('date');
  
  useEffect(() => {
    form.setFieldsValue({
      duration: formatTimeMinutes(dataService?.duration_time ?? 0, dateLang),
      time_start: getTimeStart(),
      members: getSelectedMember(),
    });
    setQuantity(dataService?.quantity || 1);
  }, [dataService, isOpenModal, currServices]);

  const format = 'HH:mm';

  const handleFinish = (valueForm: any) => {
    // handleCancel();
    const submit = () => {
      handleAddServices(valueForm, dataService);
      if (formBookingDate) {
        dispatch(bookingActions.quickBooking.setBookingDate(formBookingDate));
      }
    };
    if (isInProgress) {
      setVisibleVerifyPin(true);
      actionSubmitRef.current = submit;
      return;
    }
    submit();
  };

  // const { disabledHours, disabledMinutes } = useMemo(() => {
  //   const _date = moment(bookingDate);
  //   const today = moment();
  //   const isNow = _date.isSame(today, 'day');

  //   const timeOpening = getTimeOpening(bookingDate, activeLocation);
  //   const _timeStart = timeOpening?.timeStart.get('hour') || workingHour.open;
  //   const _timeEnd = timeOpening?.timeEnd.get('hour') || workingHour.close;

  //   const disabledHours = () => getDisabledHours(_timeStart, _timeEnd, isNow);
  //   const disabledMinutes = isNow ? getDisabledMinutes : undefined;

  //   return { disabledHours, disabledMinutes };
  // }, [bookingDate, activeLocation]);

  return (
    <>
      <Modal
        maskClosable={false}
        open={isOpenModal}
        onCancel={(e) => handleCancel(e)}
        okButtonProps={{ style: { display: 'none' } }}
        width={574}
        footer={null}
        forceRender
        centered
      >
        <NewServicesModalStyled>
          <h2 className='modal-title'>
            <p>{bookingLang('ServiceDetails')}</p>
          </h2>
          <Form
            data-form-testing='form-booking-online-service-add'
            name='basic'
            form={form}
            className='modal-form'
            autoComplete='off'
            onFinish={handleFinish}
            layout='vertical'
          >
            <div className='form-label'>{dataService?.name}</div>
            {/* <div>{dataService?.description}</div> */}
            <div className='form-row'></div>
            <div className='form-price'>
              <span className='price-value'>

                {formatMoney(currentTotalService)}
              </span>
              <div className='quantity-control'>
                <Form.Item name='quantity' className='hidden'>
                  <Input disabled={true} value={quantity}></Input>
                </Form.Item>
                <span
                  onClick={handleDecrease}
                  className={`control-btn ${quantity === 1 && 'is-disabled'}`}>
                  <IconDecrease />
                </span>
                <span className='quantity-value'>{quantity}</span>
                <span onClick={handleIncrease} className='control-btn'>
                  <IconIncrease />
                </span>
              </div>
            </div>
            <StartEndTimeStyled>
              <div className='form-row'>
                <Form.Item
                  name='time_start'
                  label={bookingLang('StartTime')}
                  hasFeedback
                  rules={[{ required: true, message: bookingLang('DurationIsRequired') || '' }]}
                >
                  <TimePicker
                    format={format}
                    allowClear={false}
                    className='is-time-picker'
                    showNow={false}
                    // disabledHours={disabledHours}
                    // disabledMinutes={disabledMinutes}
                    onSelect={(value) => {
                      form.setFieldValue('time_start', value);
                      setFormBookingDate(value.format());
                    }}
                    // disabled={!(isEdit && !isFirstItem && !isInProgress)}
                    disabled={true}
                    minuteStep={5}
                    dropdownClassName='time-picker-custom'
                  />
                </Form.Item>
                <Form.Item name='duration' label={bookingLang('Duration')}>
                  <Input disabled={true} />
                </Form.Item>
              </div>
            </StartEndTimeStyled>
            <Form.Item
              label={bookingLang('TeamMember')}
              name='members'
              rules={[{ required: false, message: bookingLang('MemberIsRequired') || '' }]}
            >
              <Select placeholder={bookingLang('SelectTeamMemberPlaceholder') || ''} defaultValue={''} options={memberOpts} disabled={props?.disableSelectTeamMember} />
            </Form.Item>
            {props?.disableSelectTeamMember ?
              <div className='disable-teammember-helptext'>
                {bookingLang('RequireChooseTeamMemberHasBeenDisabled')}
              </div> : null
            }

            <div className='form-row form-submit-wrapper'>
              <button className='common-btn is-white' onClick={handleCancel}>
                {bookingLang('Cancel')}
              </button>
              <button className='common-btn' type='submit'>
                {bookingLang('Save')}
              </button>
            </div>
          </Form>
        </NewServicesModalStyled>
      </Modal>
      <VerifyPin visible={visibleVerifyPin} onCancel={() => setVisibleVerifyPin(false)} onSubmit={actionSubmitRef.current} />
    </>
  );
};

export default NewServicesModal;

const NewServicesModalStyled = styled.div`
  @media (max-width: 767.98px) {
    .form-label {
      margin-top: 24px;
    }
  }
  .disable-teammember-helptext {
    margin-top: -20px;
    color: #B8B8B8;
  }
`;

const StartEndTimeStyled = styled.div`
.form-row {
  @media only screen and (max-width: 767.98px) {
    display: grid;
  }
}
`;
