const IconSupport = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M16.6663 7.49935C16.6663 4.27769 13.6816 1.66602 9.99967 1.66602C6.31778 1.66602 3.33301 4.27769 3.33301 7.49935"
        stroke="white"
        strokeWidth="1.5"
      />
      <path
        d="M15.8332 7.49935H14.6665C14.3904 7.49935 14.1665 7.72321 14.1665 7.99935V13.666C14.1665 13.9422 14.3904 14.166 14.6665 14.166H15.8332C17.2139 14.166 18.3332 13.0467 18.3332 11.666V9.99935C18.3332 8.61864 17.2139 7.49935 15.8332 7.49935Z"
        stroke="white"
        strokeWidth="1.5"
      />
      <path
        d="M16.6665 14.166V14.666C16.6665 15.7706 15.7711 16.666 14.6665 16.666H12.4998"
        stroke="white"
        strokeWidth="1.5"
      />
      <path
        d="M9.16667 14.9987H10.8333C11.7538 14.9987 12.5 15.7449 12.5 16.6654C12.5 17.5858 11.7538 18.332 10.8333 18.332H9.16667C8.24619 18.332 7.5 17.5858 7.5 16.6654C7.5 15.7449 8.24619 14.9987 9.16667 14.9987Z"
        stroke="white"
        strokeWidth="1.5"
      />
      <path
        d="M5.33317 7.49935H4.1665C2.78579 7.49935 1.6665 8.61864 1.6665 9.99935V11.666C1.6665 13.0467 2.78579 14.166 4.1665 14.166H5.33317C5.60931 14.166 5.83317 13.9422 5.83317 13.666V7.99935C5.83317 7.72321 5.60931 7.49935 5.33317 7.49935Z"
        stroke="white"
        strokeWidth="1.5"
      />
    </svg>
  );
};

export default IconSupport;
