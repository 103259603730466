import moment, { Moment } from 'moment';
import { IMerchantLocationItemResData } from '../services/types/booking';
import getTimeOpening from './getTimeOpening';

const findNextWorkingDate = (
  date: Moment,
  time: Moment,
  currLocation?: IMerchantLocationItemResData
) => {
  let bookingDate = date;
  let bookingTime = time;

  const listWeekDay = [...Array(7).keys()]?.map((item: number) =>
    moment()
      .startOf('week')
      .add(item + 1, 'days')
      .format('dddd')
      ?.toLowerCase()
  );

  const newOpenningTime = listWeekDay?.map((item) => {
    const findDayOpen = currLocation?.time_opening?.find(
      (it) => it?.weekday === item
    );
    return !findDayOpen
      ? {
          weekday: item,
          time_end: '',
          time_start: '',
          is_close: true,
        }
      : { ...findDayOpen, is_close: false };
  });

  const currentIndex = listWeekDay?.findIndex(
    (item) => item === bookingDate?.format('dddd')?.toLowerCase()
  );
  let nextIndex = -1;

  for (const index of [...Array(7).keys()]) {
    const nxtId = (currentIndex + index + 1) % newOpenningTime?.length;
    if (!newOpenningTime[nxtId]?.is_close) {
      nextIndex = nxtId;
      break;
    }
  }

  while (bookingDate.day() !== nextIndex + 1) {
    bookingDate = bookingDate.add(1, 'days');
  }

  const nextTimeOpening = getTimeOpening(
    bookingDate.format(),
    currLocation
  );
  bookingTime = bookingTime.set({
    hour: nextTimeOpening?.timeStart?.get('hours'),
    minute: nextTimeOpening?.timeStart?.get('minutes'),
    second: nextTimeOpening?.timeStart?.get('seconds'),
  });

  return { bookingDate, bookingTime };
};

const configBookingDateTime = (
  date: Moment,
  time: Moment,
  currLocation?: IMerchantLocationItemResData
) => {
  let bookingDate = date;
  let bookingTime = time;

  if (currLocation?.time_opening?.length) {
    const dateClosed = currLocation?.date_closed?.find(
      (o: any) =>
        bookingDate.format('YY-MM-DD') == o.start_date ||
        moment(bookingDate).isBetween(
          moment(o.start_date, 'YY-MM-DD'),
          moment(o.end_date, 'YY-MM-DD').add(1, 'day')
        )
    ); // get location date close

    const isTimeOpenning = currLocation?.time_opening.some(
      (o) => o?.weekday === bookingDate?.format('dddd')?.toLowerCase()
    ); // check current booking date is openning

    if (dateClosed || !isTimeOpenning) {
      // if booking date is close or not open
      const endDateOff = moment(dateClosed?.end_date, 'YY-MM-DD'); // get last lose date
      currLocation?.date_closed?.forEach(
        (o: any) => {
          if(endDateOff.isBetween(
            moment(o.start_date, 'YY-MM-DD').subtract(1, 'day'),
            moment(o.end_date, 'YY-MM-DD').add(1, 'day')
          ))
          endDateOff.set({
            date: moment(o.end_date, 'YY-MM-DD').add(1, 'day').date(),
            month: moment(o.end_date, 'YY-MM-DD').add(1, 'day').month(),
            year: moment(o.end_date, 'YY-MM-DD').add(1, 'day').year()
          });
        }          
      );
      const nextWorkingDate = findNextWorkingDate(
        dateClosed ? endDateOff.subtract(1, 'day') : bookingDate,
        bookingTime,
        currLocation
      ); // find next working date

      bookingDate = nextWorkingDate?.bookingDate;
      bookingTime = nextWorkingDate?.bookingTime;
    } else {
      // if booking date is not the closing date or a working day
      const timeOpening = getTimeOpening(bookingDate.format(), currLocation); // get time openning of booking date

      const convertBookingTime = moment(
        moment(bookingTime).format('HH:mm:ss'),
        'HH:mm:ss'
      );

      if (
        convertBookingTime.isBefore(
          moment(timeOpening?.timeStart, 'HH:mm:ss')
        ) // if booking date is before start time
      ) {
        bookingTime = bookingTime.set({
          // set booking time to start time
          hour: timeOpening?.timeStart?.get('hours'),   
          minute: timeOpening?.timeStart?.get('minutes'),
          second: timeOpening?.timeStart?.get('seconds'),
        });
      }

      if (
        convertBookingTime.isAfter(moment(timeOpening?.timeEnd, 'HH:mm:ss'))
      ) {
        //if booking date is after close time
        bookingDate = bookingDate.clone().add(1, 'days'); // Increase booking day by 1

        const isTimeOpenning = currLocation?.time_opening.some(
          (o) => o?.weekday === bookingDate?.format('dddd')?.toLowerCase()
        ); // Check if the booking date increased by 1 is the opening day

        const dateClosed = currLocation?.date_closed?.find(
          (o: any) =>
            bookingDate.format('YY-MM-DD') == o.start_date ||
            moment(bookingDate).isBetween(
              moment(o.start_date, 'YY-MM-DD'),
              moment(o.end_date, 'YY-MM-DD').add(1, 'day')
            )
        ); // Check if the booking date increased by 1 is the close day

        if (dateClosed || !isTimeOpenning) {
          const endDateOff = moment(dateClosed?.end_date, 'YY-MM-DD');

          const nextWorkingDate = findNextWorkingDate(
            dateClosed ? endDateOff : bookingDate,
            bookingTime,
            currLocation
          );
          bookingDate = nextWorkingDate?.bookingDate;
          bookingTime = nextWorkingDate?.bookingTime;
        } else {
          const tomorrowTimeOpening = getTimeOpening(
            bookingDate.format(),
            currLocation
          );

          bookingTime = bookingTime.set({
            hour: tomorrowTimeOpening?.timeStart?.get('hours'),
            minute: tomorrowTimeOpening?.timeStart?.get('minutes'),
            second: tomorrowTimeOpening?.timeStart?.get('seconds'),
          });
        }
      }
    }
  }
  return { bookingDate, bookingTime };
};

export default configBookingDateTime;