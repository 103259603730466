import { Form, Input, InputNumber } from 'antd';
import { get } from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { getSelectedLanguage } from 'utils/unit';
import NumberValidations from 'utils/validations/number';
type Props = {
  onCancel: () => void;
  errors: any;
  onSubmit: () => void;
};
const CustomersFormSecondStep: React.FC<Props> = ({
  errors,
  onCancel = () => undefined,
  onSubmit = () => undefined,
}) => {
  const { t: formLang } = useTranslation('form');
  const { t: commonLang } = useTranslation('common');
  const { t: AnalyticLang } = useTranslation('analytic');

  const form = Form.useFormInstance();
  const checkValid = async () => {
    try {
      await form.validateFields([
        'numberVisits',
        'cancellations',
        'noShows',
        'loyaltyPoints',
        'amountSpent',
      ]);
      return true;
    } catch (error) {
      return false;
    }
  };

  const handleSubmit: React.MouseEventHandler<HTMLButtonElement> = async (
    e
  ) => {
    e.preventDefault();
    const isFormValid = await checkValid();
    if (!isFormValid) return;
    onSubmit();
  };

  return (
    <>
      <div className='form-row'>
        <Form.Item
          label={formLang('NumberOfVisitsLabel')}
          name='numberVisits'
          extra={get(errors, 'total_visited', '')}
          rules={[{ required: true }, NumberValidations.ONLY_NUMBER]}
        >
          <Input type='number' defaultValue={0} min={0} placeholder={formLang('EnterNumberPlaceholder') || ''} />
        </Form.Item>
      </div>
      <div className='form-row'>
        <Form.Item
          label={formLang('CancellationsLabel')}
          name='cancellations'
          extra={get(errors, 'total_cancelled', '')}
          rules={[{ required: true }, NumberValidations.ONLY_NUMBER]}
        >
          <Input type='number' defaultValue={0} min={0} placeholder={formLang('EnterNumberPlaceholder') || ''} />
        </Form.Item>
        <Form.Item
          label={formLang('NoShowsLabel')}
          name='noShows'
          extra={get(errors, 'total_no_show', '')}
          rules={[{ required: true }, NumberValidations.ONLY_NUMBER]}
        >
          <Input type='number' defaultValue={0} min={0} placeholder={formLang('EnterNumberPlaceholder') || ''} />
        </Form.Item>
      </div>
      <div className='form-row'>
        <Form.Item
          label={formLang('LoyaltyPointsLabel')}
          name='loyaltyPoints'
          extra={get(errors, 'loyalty_point', '')}
          rules={[{ required: true }, NumberValidations.ONLY_NUMBER]}
        >
          <Input type='number' defaultValue={0} min={0} placeholder={formLang('EnterNumberPlaceholder') || ''} />
        </Form.Item>
      </div>
      <div className='form-row'>
        <Form.Item
          label={AnalyticLang('TotalSales')}
          name='amountSpent'
          extra={get(errors, 'amount_spent', '')}
          rules={[{ required: true }, NumberValidations.ONLY_NUMBER]}
        >
          <InputNumber 
          addonAfter={getSelectedLanguage() === 'vi' ? 'đ' : '$'}
            precision={2}
            type='number'
            // formatter={(value: any) => {
            //   if (value === undefined) {
            //     return '';
            //   }
            //   return parseFloat(value)?.toFixed(2);
            // }}
            placeholder={formLang('AmountPlaceholder') || ''} />
        </Form.Item>
      </div>
      <div className='form-submit-wrapper'>
        <button className='common-btn is-white' onClick={onCancel}>
          {commonLang('Cancel')}
        </button>
        <button className='common-btn' onClick={handleSubmit}>
          {commonLang('Save')}
        </button>
      </div>
    </>
  );
};

export default CustomersFormSecondStep;
