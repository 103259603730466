import { Empty, Spin, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import workingHourActions from 'features/workingHour/services/actions';
import workingHourSelectors from 'features/workingHour/services/selectors';
import { IStaffAnalyticDataItem } from 'features/workingHour/services/types/workingHour';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { getFormatTime } from 'utils/moment/getSingleDateShortFormat';
import { formatMoney } from 'utils/unit';

// eslint-disable-next-line @typescript-eslint/ban-types
type IWorkingHourTableProps = {};
const WorkingHourTable: React.FC<IWorkingHourTableProps> = () => {
  const lstData = workingHourSelectors.getStaffList();
  const loading = workingHourSelectors.getStaffListLoading();
  const teamMembers = workingHourSelectors.getLstTeamMembers();
  const dispatch = useDispatch();
  const { t: workingHourLang } = useTranslation('workingHour');
  const getWorkingHourEmployee = (merchant_employee_id: number) => {
    dispatch(workingHourActions.setParams({
      merchant_employee_id: [merchant_employee_id]
    }));
  };

  const { t: dateLang } = useTranslation('date');
  const columns: ColumnsType<IStaffAnalyticDataItem> = [
    {
      title: workingHourLang('TeamMember'),
      dataIndex: 'full_name',
      key: 'full_name',
    },
    {
      title: workingHourLang('TotalHour'),
      dataIndex: 'total_worked',
      key: 'total_worked',
      render: (text) => {
        return (
          <div>{getFormatTime(text, dateLang)}</div>
        );
      },
    },
    {
      title: workingHourLang('NoOfCustomer'),
      dataIndex: 'no_of_customer',
      key: 'no_of_customer',
    },
    {
      title: workingHourLang('TotalSales'),
      dataIndex: 'total_sales',
      key: 'total_sales',
      render: (value: number) => `${formatMoney(+value ?? 0)}`,
    },
    {
      title: workingHourLang('Actions'),
      render: (text, record) => (
        <ul className={'common-action'}>
          <NavLink to={'/private/working-hour/detail/'} state={{ employee_id: record.id }} onClick={() => getWorkingHourEmployee(record.id)}>
            <IconRight />
          </NavLink>
        </ul>
      ),
    }
  ];

  return (
    <Spin spinning={loading || teamMembers.length === 0}>
      {lstData.length > 0 ? (
        <WorkingHourTableStyled>
          <p className='title'>{workingHourLang('WorkingHour')}</p>
          <Table
            className={'custom-table'}
            scroll={{ x: 900 }}
            columns={columns}
            rowKey={(record) => record?.id}
            dataSource={lstData}
            pagination={false}
          />
          <NavLink to={'/private/working-hour/detail'}>
            <p className='view-detail'>{workingHourLang('ViewDetails')}</p>
          </NavLink>
        </WorkingHourTableStyled>
      ) : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={workingHourLang('NoWorkingHour')} />}
    </Spin>);
};

export default WorkingHourTable;

const WorkingHourTableStyled = styled.div`
  display: flex;
  padding: 24px;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  align-self: stretch;

  border-radius: 6px;
  background: #FFF;
  box-shadow: 0px 4px 24px 0px rgba(225, 225, 239, 0.60);

.title {
  color: #363565;
  /* font-family: 'Inter'; */
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
  text-transform: capitalize;
  align-self: stretch;
}
.ant-table-wrapper {
  width:100%;
}
.view-detail {
  color: var(--primary-blue, #363565);
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
  text-transform: capitalize;
}
`;

const IconRight = () => {
  return (
    <svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.599609" y="0.5" width="30" height="30" rx="6" fill="#F0F2F7" />
      <path d="M13.0996 20.5L18.0996 15.5L13.0996 10.5" stroke="#363565" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>

  );

};

