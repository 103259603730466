import { CalendarViewType } from 'constants/index';
import { ICustomerItemResData } from 'features/customers/services/types/cutomer';
import { IOption, IPagination } from 'models/config';
import { IDaysOfWeekItem } from 'utils/moment/getDatesOfWeek';
import { IActiveMerchantLocation, IApiBookingParams, IBookingItemResData, IBookingStatusItemResData, IEmployeeItemResData, IMerchantLocationItemResData } from './booking';
import { IBookingOnlineFormInfoValue, IBookingOnlineFormPromotion, IServiceCategoryResDataItem, IServiceItemResData } from './bookingOnline';
import { IBookAssignmentDetail, IBookAssignmentsResponse, IBusyEmployeeItem, IServiceQuickBookingItem } from './quickBooking';
import { IServiceItem } from './service';
import { StaffPublic } from 'features/users/services/types/teamMember';

export enum PopupType {
  BOOKING_ITEM = 'BOOKING_ITEM',
  MULTIPLE_ITEM = 'MULTIPLE_ITEM'
}

export type IState = {
  bookings: IStateBookingItem[];
  lstBookingStatus: IStateBookingStatusItem[];
  merchantLocations: IMerchantLocationItemResData[];
  teamMembers: IStateTeamMemberBookingItem[];
  hasScrolled: boolean;
  listTable: {
    params: IApiBookingParams,
    data: IStateBookingItem[];
    pagination: IPagination | null;
  },
  calendarTable: {
    params: IApiBookingParams,
    previousDate: IApiBookingParams['date'],
    zoomView: number;
    zoomViewOptions: IStateZoomViewOpts;
    daysOfWeek: IDaysOfWeekItem[];
    viewType: CalendarViewType;
    activeLocation: IActiveMerchantLocation | null,
    workingHour: IStateWorkingHour;
    pixelPerMinute: number;
  };
  bookingLayout: string;
  bookingOnlinePage: string;
  bookingOnline: {
    services: IServiceItemResData[];
    categories: IServiceCategoryResDataItem[];
    locations: IMerchantLocationItemResData[],
    selectedServices: IServiceItem[];
    formValue: IBookingOnlineFormInfoValue | null;
    promotionActive: IBookingOnlineFormPromotion | null
    teamMemberSelected: StaffPublic[];
    bookingOnlinePage: string;
    bookingTime: {
      start: string,
      finish: string,
    },
    merchant_location_id: IMerchantLocationItemResData['id'] | null,
  },
  quickBooking: {
    selectedServices: IServiceQuickBookingItem[];
    initialServices: IServiceQuickBookingItem[];
    bookingTime: string | null;
    bookingStatus: string;
    customer: ICustomerItemResData | null;
    isEdit: boolean,
    detail: IBookAssignmentDetail | null,
    dataConfigs: IBookAssignmentsResponse['data'] | null,
    busyEmployees: IBusyEmployeeItem[],
    merchant_location_id: number | string | null;
  },
  currentTime: string;
  workingHour: IStateWorkingHour;
  activePopover: string | null;
  listActivity: IActivity[];
  canlendarPopupOpenData: {
    status: boolean,
    id?: string | number,
    type: PopupType,
    col_id?: string | number
    is_mutiple?: boolean
  }
  // optionFilter: OptionFilter | null
};


export type IStateBookingItem = IBookingItemResData;
export type IStateTeamMemberBookingItem = IEmployeeItemResData;
export type IStateBookingStatusItem = IBookingStatusItemResData;
export type IStateZoomViewOpts = IOption<number>[];
export type IStateWorkingHour = {
  open: number,
  close: number,
  openMinute?: number;
  closeMinute?: number;
};

export type IActivity = {
  title: string;
  content: string;
  created_at: string;
  creator: string;
}