import { useTranslation } from 'react-i18next';
import { Link, useSearchParams } from 'react-router-dom';
import InputSearchText from 'components/common/Input/InputSearchDebounce';
import { message } from 'antd';
import styles from './index.module.scss';
import styled from 'styled-components';
import { useRef, useState } from 'react';
import { IServiceReponse } from './BrandPage';
import apisService from 'features/services/services/apis';
import { get } from 'lodash';
import serviceActions from 'features/services/services/actions';
import { useDispatch } from 'react-redux';
import ModalConfirm, {
    ModalConfirmRef,
} from 'components/common/Modal/ModalConfirm';
import SupplierTable from '../Components/SupplierFrom/SupplierTable';
import { IFuncHandleSubmit, IPayloadReturnType } from '../Components/BrandFrom';
import { AxiosResponse } from 'axios';
import { useSetLoadingPage } from 'services/UI/LoadingPage';
import SupplierForm from '../Components/SupplierFrom';

const SupplierPage = () => {
    const { t: productLang } = useTranslation('products');
    const [isEdit, setIsEdit] = useState<boolean>(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [currService, setCurrService] = useState<any>();
    const [serviceId,] = useState<number>();
    const setPageLoading = useSetLoadingPage();
    const dispatch = useDispatch();
    const modalConfirmRef = useRef<ModalConfirmRef>(null);
    const [searchParams] = useSearchParams();
    const onAddItem = () => {
        setIsEdit(false);
        setIsModalOpen(true);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
        setCurrService(null);
    };

    const handleSubmitUpdate = async (payload: IPayloadReturnType) => {
        handleCancel();
        try {
            const rest: AxiosResponse<{ message: string }> =
                await apisService.updateServices(serviceId, payload);
            const msg = get(rest, 'data.message', '');
            if (msg) {
                message.success(msg);
                dispatch(
                    serviceActions.setServiceListParams({
                        page: Number(searchParams.get('page') ?? 1),
                        per_page: Number(searchParams.get('per_page') ?? 10),
                        keyword: searchParams.get('keyword') ?? ''
                    })
                );
                return true;
            } else {
                const msgError = get(rest, 'data.error.message', '');
                if (msgError) message.error(msgError);
                return false;
            }
        } catch (error: any) {
            const response = get<Record<string, string[]>>(
                error,
                'response.data.errors',
                {}
            );
            const errors = Object.values(response);
            errors.map((value) => {
                message.error(get(value, [0], ''));
            });
            return false;
        }
    };

    const handleSubmitStore = async (payload: IPayloadReturnType) => {

        try {
            const rest: AxiosResponse<{ data: { message: string } }> =
                await apisService.storeServices(payload);
            const msg = get(rest, 'data.data.message', '');
            if (msg) {
                message.success(msg);
                dispatch(
                    serviceActions.setServiceListParams({
                        page: 1,
                        per_page: 10,
                    })
                );
                handleCancel();
                return true;
            }
            const msgError = get(rest, 'data.error.message', '');
            if (msgError) message.error(msgError);

            return false;
        } catch (error: any) {
            const response = get<Record<string, string[]>>(
                error,
                'response.data.errors',
                {}
            );

            const errors = Object.values(response);
            errors.map((value) => {
                message.error(get(value, [0], ''));
            });
            return false;
        }
    };

    const handleSubmit: IFuncHandleSubmit = async (payload) => {
        setPageLoading(true);
        try {
            return isEdit
                ? await handleSubmitUpdate(payload)
                : await handleSubmitStore(payload);
        } catch (error) {
        } finally {
            setPageLoading(false);
            dispatch(serviceActions.getShowCreateService.fetch());
        }
    };

    //   const handleModalUpdate = (record: IServiceReponse) => {
    //     // const fieldsValue = {
    //     //   service_name: record?.name,
    //     //   price: record?.sale_price,
    //     //   duration: record?.duration_time,
    //     //   category: record?.category?.id,
    //     //   isEnableDiscounts: record?.enable_discount,
    //     //   isEnableService: record?.enable,
    //     //   isEnableBookingLink: record?.enable_online_booking,
    //     //   isEnableVoucherSales: record?.enable_voucher_sale,
    //     //   serviceDescription: record?.description,
    //     //   availableLocation: record?.merchant_location_id,
    //     //   locationTemp: record?.merchant_location_id,
    //     //   serviceVariants: record?.service_variants,
    //     //   teamMember: record?.merchant_employees?.map((o) => o.id),
    //     // };
    //     // setCurrService(fieldsValue);
    //     setServiceId(record?.id);
    //     setIsEdit(true);
    //     setIsModalOpen(true);
    //   };

    const onClickRemoveItem = (record: IServiceReponse) => {
        modalConfirmRef.current?.show({
            title: productLang('modalConfirm.deleteSupplier.title'),
            msg: productLang('modalConfirm.deleteSupplier.message'),
            submit: async () => {
                try {
                    const res = await apisService.deleteService(record.id);
                    const msg = get(res, 'data.message', '');
                    if (msg) {
                        message.success(msg);
                        dispatch(serviceActions.getListPaginateServices.fetch({}));
                    } else {
                        const msgError = get(res, 'data.error.message');
                        if (msgError) message.error(msgError);
                    }
                } catch (error) {
                    message.error('An error occurred. Please try again');
                }
            },
            icon: 'delete',
        });
    };

    const columns = [
        {
            title: productLang('Supplier'),
            dataIndex: 'supplier_name',
            key: 'supplier_name',
            fixed: 'left',
            render: (text: any) => {
                return (
                    <Link to={'ABC'}>
                        <div className={styles.id_product_table}>
                            {text}
                        </div>
                    </Link>
                );
            },
        },
        {
            title: productLang('Phone'),
            dataIndex: 'phone',
            key: 'phone',
            render: (text: any) => {
                return (
                    <div>
                        <p className={styles.name_product_table}>{text}</p>
                    </div>
                );
            },
        },
        {
            title: productLang('Email'),
            dataIndex: 'email',
            key: 'email',
            render: (text: string) => {
                return (
                    <div>
                        <p>{text}</p>
                    </div>
                );
            },
        },
        {
            title: productLang('Product'),
            dataIndex: 'product',
            key: 'product',
            render: (text: string) => {
                return (
                    <div className={styles.product_brand}>
                        <p>{text}</p>
                    </div>
                );
            },
        },
        {
            title: productLang('Update'),
            dataIndex: 'update',
            key: 'update',
            render: (text: string) => {
                return (
                    <div className={styles.product_depot}>
                        <p>{text}</p>
                    </div>
                );
            },
        },
        {
            title: productLang('Actions'),
            dataIndex: 'actions',
            key: 'actions',
            render: (record: IServiceReponse) => (
                <ul className={'common-action'}>
                    <Link to={'ABC'}>
                        <li
                            className='action-edit'
                        ></li>
                    </Link>

                    <li
                        className='action-remove'
                        onClick={() => onClickRemoveItem(record)}
                    ></li>
                </ul>
            ),
        },
    ];

    return (
        <>
            <ListProductPageStyled>
                <div className={'private-filter'}>
                    <InputSearchText />
                    <div className={'private-filter'}>
                        <button onClick={onAddItem} className={'common-btn'}>
                            {productLang('AddNew')}
                        </button>
                    </div>
                </div>
            </ListProductPageStyled>
            <SupplierTable columns={columns} />
            {isModalOpen && (
                <SupplierForm
                    handleCancel={handleCancel}
                    handleSubmit={handleSubmit}
                    isModalOpen={isModalOpen}
                    currService={currService}
                />
            )}

            <ModalConfirm ref={modalConfirmRef} />
        </>
    );
};

export default SupplierPage;

const ListProductPageStyled = styled.div`
.img_product {
    @media only screen and (max-width: 767.98px) {
        dislay: none
    }
}
`;