import { Tabs } from 'antd';
import ProgramsTab from '../components/ProgramsTab';
import RewardsTab from '../components/RewardsTab';
// import SettingsTab from '../components/SettingsTab';
import { useAppDispatch } from 'store/hooks';
import { useEffect } from 'react';
import loyalActions from '../services/actions';
import { useTranslation } from 'react-i18next';
import packagePermissionSelector from 'features/packagePermission/services/selectors';
import { ModulePermission, ProgramPermission, RewardPermission } from 'features/packagePermission/services/types/permission';
import { useCheckPermission } from 'features/packagePermission/hooks/useCheckPermission';

const LoyaltyProgramPage = () => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(loyalActions.getLoyalConfigs());
  }, []);
  const {t: loyaltyProgramLang} = useTranslation('loyaltyProgram');
  const programsPermission = packagePermissionSelector.getPermissionFeature(ModulePermission.PROGRAMS);
  const rewardPermission = packagePermissionSelector.getPermissionFeature(ModulePermission.REWARDS);

  const isPrograms = useCheckPermission(programsPermission, ProgramPermission.VIEW_PROGRAM);
  const isReward = useCheckPermission(rewardPermission, RewardPermission.VIEW_REWARD);

  const items = [
    {
      key: '1',
      label: loyaltyProgramLang('Programs'),
      children: <ProgramsTab />,
    },
    {
      key: '2',
      label: loyaltyProgramLang('Rewards'),
      children: <RewardsTab />,
    },
    // {
    //   key: '3',
    //   label: 'Settings',
    //   children: <SettingsTab />,
    // },
  ].filter(item => {
    

    if(isPrograms && isReward) return true;

    if(item.key === '1' && isPrograms) return false;

    if(item.key === '2' && isReward) return false;
  });

  return (
    <div>
      <Tabs defaultActiveKey={'1'} items={items} />
    </div>
  );
};

export default LoyaltyProgramPage;
