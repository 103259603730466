import { Empty, Input } from 'antd';
import IconPlusCircle from 'assets/svg/IconPlusCircle';
import ForSalesItem from 'features/checkout/components/VouchersTabs/ForSalesItem';
import { IVoucherDataItem, IVoucherForSalesSelectedItem } from 'features/checkout/services/types/voucher';
import salesActions from 'features/sales/services/actions';
import salesSelectors from 'features/sales/services/selectors';
import AddVoucher from 'features/vouchers/widgets/AddVoucher';
import useSearchDebounce from 'hooks/useSearchDebounce';
import { remove } from 'lodash';
import { useMemo, useRef, useState } from 'react';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
import PopupConfirm, { ModalConfirmRef } from 'components/common/Modal/ModalConfirm';
import ModalVoucherSale from 'components/common/Modal/ModalVoucherSale';
import { useTranslation } from 'react-i18next';


const VouchersTab = () => {
  const vouchers = salesSelectors.getVouchers().filter(o => o.unlimited || (o.remaining_quantity ?? 0) > 0 );
  const selected = salesSelectors.getSelectedVouchers() ?? [];
  const customer = salesSelectors.getCustomer();
  const modalConfirmRef = useRef<ModalConfirmRef>(null);
  const [voucherSelected, setVoucherSelected] = useState<IVoucherDataItem | null>();
  const {t: bookingLang} = useTranslation('booking');
  const dispatch = useAppDispatch();
  const [searchText, setSearchText] = useState('');
  const _vouchers = useMemo(() => {
    const _searchText = searchText.toLocaleLowerCase().trim();
    if (!_searchText) return vouchers;
    return vouchers.filter(o => o.voucher_code.toLocaleLowerCase().includes(_searchText) || o.name.toLocaleLowerCase().includes(searchText));
  }, [searchText, vouchers]);

  const onChooseVoucher = (voucher: IVoucherDataItem) => () => {
    if(!customer) {
      modalConfirmRef.current?.show({
        title: bookingLang('SelectCustomerFirst'),
        msg: bookingLang('SimplyChooseYourCustomerFirsThenEffortlesslyAddTheVoucher'),
        submit: () => undefined
      });
      return;
    }

    const isExist = !!selected.find(s => s.id?.toString() === voucher.id?.toString());
    const newResult = [...selected];

    if (isExist) {
      // remove
      remove(newResult, s => s.id?.toString() === voucher.id?.toString());
      dispatch(salesActions.setSelectedVouchers(newResult));
      return;
    }

    setVoucherSelected(voucher);    
  };

  const handleChooseVoucher = (voucher: IVoucherForSalesSelectedItem) => {
    if(!voucherSelected) return;
    
    const newResult = [ ...selected ];
      // add new
  
    newResult.push({ ...voucher });
    dispatch(salesActions.setSelectedVouchers(newResult));
  };

  const getChecked = (voucher: IVoucherDataItem) => !!selected.find(s => s.id?.toString() === voucher.id?.toString());

  const [text, onDebounce] = useSearchDebounce(async (value: string) => {
    const textSearch = value.toLowerCase();
    setSearchText(textSearch);
  }, vouchers);
  const {t: salesLang} = useTranslation('sales');
  return (
    <>
      <VouchersTabStyled>
        <div className='common-input-search'>
          <Input
            type='text'
            value={text}
            placeholder={bookingLang('SearchVoucherCodeOrVoucherName')||''}
            onChange={onDebounce}
          ></Input>
        </div>
        <AddVoucher className='button-add-wrap' customButton={
          <div className='button-add'><IconPlusCircle /><span>{salesLang('CreateNewVoucher')}</span></div>
        }
          onSuccess={() => dispatch(salesActions.getVouchersList.fetch())}
        />

        <div className='content'>
          {_vouchers?.length === 0 ? <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={salesLang('NoVoucher')} /> :
            _vouchers.map(o => (
              <ForSalesItem
                key={o.id}
                data={o}
                onChoose={onChooseVoucher(o)}
                checked={getChecked(o)}
              />
            ))
          }
        </div>
      </VouchersTabStyled>
      <PopupConfirm showClose={false} ref={modalConfirmRef} />
      {!!voucherSelected && <ModalVoucherSale handleChooseVoucher={handleChooseVoucher} data={voucherSelected} customer={customer} open={!!voucherSelected} onClose={() =>  setVoucherSelected(null)} />}
    </>
  );
};

export default VouchersTab;

const VouchersTabStyled = styled.div`
.common-input-search {
  margin-bottom:16px;
}
  position: relative;
  .button-add {
    display:flex;
    width:100%;
    color: var(--secondary-green, #4A9D77);
    text-align: center;
    font-size: 18px;
    font-weight: 600;
    line-height: 140%;
    justify-content: center;
    height: 40px;
    align-items: center;
    margin-bottom:8px;
    span {
      margin-left:10px;
    }
    &:hover {
      cursor: pointer;
    }
  }
  .content {
    display:flex;
    flex-direction: column;
    max-height: 40vh;
    overflow: scroll;
  }
  color: var(--color-primary);
  margin-bottom: 16px;
  table {
    border-collapse: separate;
    border-spacing: 0 25px;
  }
  .voucher_content {
    padding-left: 8px;
    width: 100%;
    border-spacing: 0;
    .checkbox {
      margin-right: 16px;
    }
    .ant-form-item-row {
      display: block;
    }
    .ant-form-item-control {
      margin-bottom: 0 !important;
    }
    .group_name {
      display: flex;
      align-items: center;
    }
    .voucher_name {
      font-weight: 500;
      font-size: 16px;
      line-height: 140%;
      display: flex;
      align-items: center;
    }
    .voucher_time {
      font-weight: 400;
      font-size: 12px;
      line-height: 140%;
    }
  }

  .voucher_amount {
    display: block;
    padding-right: 8px;
    font-weight: 600;
    font-size: 16px;
    line-height: 140%;
    text-align: right;
    white-space: nowrap;
    span {
      display: block;
      font-weight: 400;
      font-size: 12px;
    }
  }
`;
