import { Modal } from 'antd';
import { useEffect, useState, useTransition } from 'react';
import { IVoucherFormValue, VoucherFormProps } from './VoucherForm';
import getMappingDataForm from './hooks/getMappingDataForm';
import vouchersApis from '../services/apis';
import { get } from 'lodash';
import message from 'components/common/Message';
import { useAppDispatch } from 'store/hooks';
import voucherActions from '../services/actions';
import { useSetLoadingPage } from 'services/UI/LoadingPage';
import voucherSelectors from '../services/selectors';
import { useTranslation } from 'react-i18next';
import DetailVoucher from './DetailVoucher';
import IconClose from 'assets/svg/IconClose';
import styled from 'styled-components';

type EditVoucherProps = {
  open: boolean;
  handleClose: () => void;
};

function ViewInfoVoucher({ open, handleClose }: EditVoucherProps) {
  const dispatch = useAppDispatch();
  const [formData, setFormData] = useState<IVoucherFormValue>();
  const [, startTransition] = useTransition();
  const setPageLoading = useSetLoadingPage();
  const view = voucherSelectors.getLstVoucherParams().view;
  const loading = voucherSelectors.getLoadingVoucherDetail();
  const voucherDetail = voucherSelectors.getVoucherDetail();
  const { t: dateLang } = useTranslation('date');

  useEffect(() => {
    if (voucherDetail) {
      startTransition(() => {
        const _dataMapping = getMappingDataForm(voucherDetail, dateLang, view);
        setFormData(_dataMapping);
      });
    }
  }, [voucherDetail]);


  const updateVoucherStatus = async (checked: boolean) => {
    setPageLoading(true);
    try {
      const res = await vouchersApis.updateStatus(voucherDetail?.voucher_code, checked);
      const msg = get(res, 'data.message');
      if (msg) {
        message.success(msg);
        dispatch(voucherActions.setVoucherListParams.fetch({}));
        return true;
      } else {
        const errorMsg = get(res, 'data.error.message');
        if (errorMsg) {
          message.error(errorMsg);
          return false;
        } else throw 'fail';
      }
    } catch (error: any) {
      const errors = Object.values(get(error, 'response.data.errors', {}));
      if (errors.length > 0) {
        errors.map((valueArr) => {
          const msg = get(valueArr, [0], '');
          message.error(msg);
        });
        return false;
      }
      message.error('An error occurred. Please try again');
      return false;
    } finally {
      setPageLoading(false);
    }
  };

  return (
    <>
      {/* <li onClick={showModal} className='action-info'></li> */}
      {open && <ModalForm formData={formData} updateVoucherStatus={updateVoucherStatus} visible={open} onCancel={handleClose} loading={loading} />}
    </>
  );
}

export default ViewInfoVoucher;

type ModalFormProps = {
  visible?: boolean;
  onCancel?: () => void;
  onsubmit?: VoucherFormProps['onSubmit'],
  formData?: IVoucherFormValue;
  updateVoucherStatus?: VoucherFormProps['updateVoucherStatus']
  loading?: boolean
};
const ModalForm = ({ visible, formData, onCancel = () => undefined, updateVoucherStatus, loading }: ModalFormProps) => {
  if (!visible) return null;
  const { t: voucherLang } = useTranslation('vouchers');
  return (
    <Modal
      open={visible}
      onCancel={onCancel}
      okButtonProps={{ style: { display: 'none' } }}
      width={1200}
      footer={null}
      forceRender
      centered
    >
      <TitleModalVoucherStyled>
        <div className='icon-close' onClick={onCancel}>
          <IconClose />
        </div>
        <h2 className='modal-title'>{voucherLang('VoucherInformation')}</h2>
        <div className='button'>
          <button onClick={onCancel} className='common-btn' >
            Đóng
          </button>
        </div>
      </TitleModalVoucherStyled>
      <DetailVoucher isEdit formData={formData} voucherLang={voucherLang} onCancel={onCancel} viewOnly updateVoucherStatus={updateVoucherStatus} loading={loading} />
    </Modal>
  );
};

const TitleModalVoucherStyled = styled.div`
display: flex;
justify-content: space-between;
align-items: center;
border-bottom: 1px solid #1B9AAA;
position: sticky;
background: white;
z-index: 99999;
top: -50px;
padding-top: 10px;
.icon-close {
  cursor: pointer;
  width: 20%;
}
.modal-title {
  width: 60%;
  margin-bottom: 0px;
  text-align: center;
}
.button {
  width: 20%;
  min-width: 0px;
  
  .common-btn {
    width: 100px;
    background: #F5F5F5;
    border: 1px solid #F5F5F5;
    color: #141414;
  }
}
`;