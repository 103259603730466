import { get } from 'lodash';
import { RootState } from 'store';
import { useAppSelector } from 'store/hooks';
import { IStateTeamMemberItem } from './types/reducer';
import uiSelector from 'services/UI/selectors';
import { PATH_LOADING } from './constants';
import { StaffPublic } from './types/teamMember';


type MyState = RootState['user'];

const getCurrentState = (state: RootState): MyState => state.user;

export const getPureParams = (root: RootState) => {
  const state = getCurrentState(root);
  return state.params ?? {};
};

const selector = (key: keyof MyState, defaultValue?: any) => useAppSelector(state => get(getCurrentState(state), key, defaultValue));

const getTeamMembers = () => selector('teamMembers') as (IStateTeamMemberItem[] | null);


const getStaffPaginateList = () => selector('staffPaginateList') as MyState['staffPaginateList'];

const loadingStaffPaginateList = () => uiSelector.getLoading(PATH_LOADING.getListPaginateStaffs);

const getShowCreateStaffs = () => selector('staffShowCreate') as MyState['staffShowCreate'];

const loadingShowCreateStaffs = () => uiSelector.getLoading(PATH_LOADING.getShowCreateStaffs);

const getStaffService = () => (selector('staffServices') ?? []) as (any[]);

// const getStaffDetail = () => selector('staffDetail') as (any);

const getProfileStaffDetail = () => selector('profileStaffDetail') as MyState['profileStaffDetail'];

const loadingProfileStaffDetail = () => uiSelector.getLoading(PATH_LOADING.getProfileStaffDetail);

const getParams = () => selector('params') as MyState['params'];

const getLocationSelectedId = () => selector('locationSelectedId') as string;

const getStaffPublic = () => selector('staffPublic') as StaffPublic[];

const loadingStaffPublic = () => uiSelector.getLoading(PATH_LOADING.getStaffPublic);

const getLocationDetail = () => selector('locationDetail')  as MyState['locationDetail'];

const loadingLocationDetail = () => uiSelector.getLoading(PATH_LOADING.getLocationDetail);

const userSelectors = {
  getTeamMembers,
  getStaffPaginateList,
  loadingStaffPaginateList,
  getStaffService,
  getProfileStaffDetail,
  getShowCreateStaffs,
  loadingShowCreateStaffs,
  loadingProfileStaffDetail,
  getLocationSelectedId,
  getParams,
  getStaffPublic,
  loadingStaffPublic,
  getLocationDetail,
  loadingLocationDetail
};
export default userSelectors;
