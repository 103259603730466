import { Form, Input, Modal, Select, Spin } from 'antd';
import IconDecrease from 'assets/svg/IconDecrease';
import IconIncrease from 'assets/svg/IconIncrease';
import dayjs from 'utils/dayjs';
// import { getDisabledHours, getDisabledMinutes } from 'features/bookings/hooks/disableTime';
// import getTimeOpening from 'features/bookings/hooks/getTimeOpening';
import { BREAK_TIME_MINUTES, TIME_START_FORMAT } from 'features/bookings/services/constants';
import bookingSelectors from 'features/bookings/services/selectors';
import { IServiceQuickBookingItem } from 'features/bookings/services/types/quickBooking';
import { findIndex, first, get, last, uniqBy } from 'lodash';
import moment, { Moment } from 'moment';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
// import formatNumber from 'utils/formatNumber';
import { ANONYMOUS_NAME, formatMoney, getNextTimeStart } from 'utils/unit';
import VerifyPin from 'widgets/VerifyPin';
import { IServiceItem } from 'features/bookings/services/types/service';
import { useParams } from 'react-router-dom';
import { Dayjs } from 'dayjs';
import { ETimeSchedule, TimingType } from 'features/timeSchedule/types/timeSchedule';
import MessageWarning from 'assets/icons/ico_message_warning.svg';
import timeScheduleActions from 'features/timeSchedule/services/actions';
import { useDispatch } from 'react-redux';
import timeScheduleSelectors from 'features/timeSchedule/services/selectors';
import settingSelectors from 'features/settings/services/selectors';

type Props = {
  isOpenModal: boolean;
  handleCancel: any;
  dataService: IServiceItem | null;
  handleAddServices?: any;
  currServices?: IServiceItem[];
  modalConfirmRef?: any;
  isCheckIn?: boolean
  disableMember?: boolean;
  isNameService?: any;
};

const NewServicesModal = (props: Props) => {
  const {
    isOpenModal,
    isCheckIn,
    handleCancel = () => undefined,
    dataService,
    handleAddServices = () => undefined,
    currServices = [],
    modalConfirmRef,
    disableMember,
    isNameService
  } = props;
  const [quantity, setQuantity] = useState<number>(1);
  const [form] = Form.useForm();
  const formInstance = Form.useFormInstance();

  const dispatch = useDispatch();

  const bookingDate = bookingSelectors.quickBooking.getBookingDate();
  // const workingHour = bookingSelectors.getWorkingHour();


  const activeLocation = bookingSelectors.quickBooking.getMerchantLocationActive();
  const params = useParams();
  const merchant_location_id = bookingSelectors.quickBooking.getMerchantLocationId() ?? params.location_id ?? formInstance.getFieldValue('location');
  const [visibleVerifyPin, setVisibleVerifyPin] = useState(false);
  const actionSubmitRef = useRef<() => void>(() => undefined);
  const [openReSelectBookingTime, setOpenReSelectBookingTime] = useState<boolean>(false);
  const [selectedTeamMember, setSelectedTeamMember] = useState<number>(-1);
  const [currentSelectBookingDate,] = useState<string>(formInstance.getFieldValue('bookingDate'));
  const [currentSelectBookingTime,] = useState<string>(formInstance.getFieldValue('bookingTime'));
  const scheduleOnline = timeScheduleSelectors.getLstTimeScheduleOnline();
  const [_bookingTime,] = useState<Dayjs | null>(null);
  const [disableSubmit, setDisableSubmit] = useState<boolean>();
  const serviceSelected = bookingSelectors.bookingOnline.getSelectedServices();
  const loadingScheduleOnline = timeScheduleSelectors.getLoadingLstTimeScheduleOnline();

  const teamMemberSelected = bookingSelectors.bookingOnline.getTeamMemberSelected();
  const setting = settingSelectors.getSettingBookingForm();


  useEffect(() => {
    if (!_bookingTime) return;

    form.setFields([
      {
        name: 'bookingTime',
        errors: []
      }
    ]);

    formInstance.setFieldValue('bookingTime', dayjs.tz(_bookingTime));
  }, [_bookingTime]);

  // const [timeScheduleMember, setTimeScheduleMember] = useState<IApiTimeScheduleBodyReq>();

  const handleSubmit = (value: any) => {
    handleAddServices(value);
    formInstance.setFieldValue('bookingDate', value.bookingDate ?? formInstance.getFieldValue('bookingDate'));
    formInstance.setFieldValue('bookingTime', value.bookingTime ?? formInstance.getFieldValue('bookingTime'));
    const time = moment(serviceSelected[0]?.time_start);
    const servicesBusy: any[] = [];
    serviceSelected.forEach(o => {

      const duration_time = o.duration ?? 0;
      const quantity = (o.quantity ?? 0);
      const prevServiceMinutes = (duration_time * quantity) + (quantity) * BREAK_TIME_MINUTES;
      time.add(prevServiceMinutes, 'minute');
      checkEmployeeTimeScheduleSingle(time, formInstance.getFieldValue('bookingTime')?.format());

      if (disableSubmit) {
        servicesBusy.push(`Team member of '${o.name}' is not available`);
      }
    });
    
    servicesBusy.length > 0 ?
      modalConfirmRef.current?.show({
        title: 'Employee not available',
        msg: `${servicesBusy[0]}`,
        submit: () => undefined
      }) : undefined;
  };
  const {t: bookingLang} = useTranslation('booking');
  const { isEdit, currentService } = useMemo(() => {
    const currentIdx = findIndex(currServices, (o) => o?.id === dataService?.id);
    return {
      isEdit: currentIdx !== -1,
      isFirstItem: currentIdx === 0,
      currentIdx,
      currentService: get(currServices, [currentIdx]) as (IServiceQuickBookingItem | undefined),
    };
  }, [currServices, dataService]);

  const memberOpts = useMemo(() => {

    if (!setting.enable_timeroster_management || isCheckIn) {
      const result = [{
        value: '',
        label: bookingLang(ANONYMOUS_NAME === 'Any team member' ? 'AnyTeamMember' : '')
      }].concat(dataService?.merchant_employees?.filter(o => {
        if (o.merchant_location_id?.toString() !== merchant_location_id?.toString()) return false;
        return o.is_receive_book === 1;

      }).map((employe: any) => {
        const { full_name, id } = employe;
        return {
          value: id,
          label: full_name,
        };
      }) ?? []);

      return uniqBy(result, o => o.value);
    }

    if (!teamMemberSelected || teamMemberSelected.length === 0) return [];

    const result = [{
      value: '',
      label: bookingLang(ANONYMOUS_NAME === 'Any team member' ? 'AnyTeamMember' : '')
    }].concat(dataService?.merchant_employees?.filter(o => teamMemberSelected.find(selected => selected?.id === o.id))?.filter(o => {
      if (o.merchant_location_id?.toString() !== merchant_location_id?.toString()) return false;
      return o.is_receive_book === 1;

    }).map((employe: any) => {
      const { full_name, id } = employe;
      return {
        value: id,
        label: full_name,
      };
    }) ?? []);

    return uniqBy(result, o => o.value);
  }, [dataService, activeLocation]);

  useEffect(() => {
    if (selectedTeamMember && selectedTeamMember !== -1) {
      dispatch(timeScheduleActions.getListTimeScheduleOnline.fetch({
        start_date: moment(currentSelectBookingDate).subtract(1, 'month').unix(),
        end_date: moment(currentSelectBookingDate).add(1, 'month').unix(),
        merchant_location_id: Number(merchant_location_id ?? 0),
        page: 1,
        merchant_employee_id: [selectedTeamMember],
      }));
    }
  }, [selectedTeamMember, currentSelectBookingDate]);

  // const disableDate = useCallback((d: Dayjs) => {
  //   if (! scheduleOnline?.length) return false;
  //   const isWorkingDay = scheduleOnline?.[0]?.timing.some(o => {
  //     if(o.shift_type !== ETimeSchedule.ON && d.isSame(o.date, 'day')) {
  //       return  true;
  //     }
  //     return false;
  //   });

  //   return isWorkingDay;

  // }, [scheduleOnline]); 

  const onChangeTeamMember = async (value: any) => {
    setSelectedTeamMember(value);
  };

  const checkEmployeeTimeScheduleSingle = (checkTime: Moment, employee_id: number) => {
    const bookingDateData = scheduleOnline?.[0]?.timing?.find(
      (item: TimingType) =>
        moment(item?.date)?.format('YYYY-MM-DD') ===
        moment(form.getFieldValue('bookingDate'))?.format('YYYY-MM-DD')
    );

    if (bookingDateData && scheduleOnline?.[0]?.id === employee_id) {
      if (
        [
          ETimeSchedule.DAY_OFF,
          ETimeSchedule.HOLIDAY_OFF,
          ETimeSchedule.CLOSED_DAY,
        ]?.includes(bookingDateData.shift_type)
      ) {
        setOpenReSelectBookingTime(true);
        return setDisableSubmit(true);


      } else if (bookingDateData?.shift_type === ETimeSchedule.ON) {

        // const bookingTime =  (getNextTimeStart(services, formInstance.getFieldValue('bookingTime')?.format()) as any)?.format('HH:mm');
        const bookingTime = checkTime?.format('HH:mm');
        const findTimeOff = bookingDateData?.timing_schedules?.find(
          (item) => {
            return !item?.is_work &&
              moment(bookingTime, 'HH:mm').isBetween(
                moment(item?.time_start, 'HH:mm'),
                moment(item?.time_end, 'HH:mm')
              );
          }
        );
        if (findTimeOff) {
          setOpenReSelectBookingTime(true);
          return setDisableSubmit(true);
        }

        const findOutTimeWork = bookingDateData?.timing_schedules?.find(
          (item) =>
            item?.is_work &&
            moment(bookingTime, 'HH:mm').isAfter(
              moment(item?.time_start, 'HH:mm')
            ) &&
            moment(bookingTime, 'HH:mm').isBefore(
              moment(item?.time_end, 'HH:mm')
            )
        );
        if (!findOutTimeWork) {
          setOpenReSelectBookingTime(true);
          return setDisableSubmit(true);

        }
      }
      setOpenReSelectBookingTime(false);
      return setDisableSubmit(false);

    }
  };

  const checkEmployeeTimeSchedule = (checkTime: Moment) => {
    const bookingDateData = scheduleOnline?.[0]?.timing?.find(
      (item: TimingType) =>
        moment(item?.date)?.format('YYYY-MM-DD') ===
        moment(currentSelectBookingDate)?.format('YYYY-MM-DD')
    );
    if (bookingDateData && scheduleOnline?.[0]?.id === selectedTeamMember) {
      if (
        [
          ETimeSchedule.DAY_OFF,
          ETimeSchedule.HOLIDAY_OFF,
          ETimeSchedule.CLOSED_DAY,
        ]?.includes(bookingDateData.shift_type)

      ) {
        setOpenReSelectBookingTime(true);
        return setDisableSubmit(true);
      } else if (bookingDateData?.shift_type === ETimeSchedule.ON) {
        // const services = (serviceSelected ?? [])?.map(o => ({
        //   time_start: o?.time_start ?? '', 
        //   duration: o.duration , 
        //   quantity: o.quantity
        // }));

        const bookingTime = checkTime?.format('HH:mm');
        const findTimeOff = bookingDateData?.timing_schedules?.find(
          (item) => {
            return !item?.is_work &&
              moment(bookingTime, 'HH:mm').isBetween(
                moment(item?.time_start, 'HH:mm'),
                moment(item?.time_end, 'HH:mm')
              );
          }
        );
        if (findTimeOff) {
          setOpenReSelectBookingTime(true);
          return setDisableSubmit(true);

        }
      }
      setOpenReSelectBookingTime(false);
      return setDisableSubmit(false);

    }
  };



  useEffect(() => {
    const services = serviceSelected?.map(o => ({
      time_start: o?.time_start ?? '',
      duration: o?.duration,
      quantity: o?.quantity
    }));
    const checkTime = getNextTimeStart(services ?? [], formInstance?.getFieldValue('bookingTime')?.format());
    checkEmployeeTimeSchedule(checkTime);
  }, [scheduleOnline]);


  const handleDecrease = () => {
    if (quantity === 1) {
      return;
    }
    setQuantity(quantity - 1);
  };

  const handleIncrease = () => {
    setQuantity(quantity + 1);
  };

  useEffect(() => {
    form.resetFields();
    setQuantity(1);
    form.setFieldValue('quantity', 1);
  }, [dataService]);

  useEffect(() => {
    form.setFieldValue('quantity', quantity);
  }, [quantity]);

  const getTimeStart = () => {
    let timePicker;
    if (!isEdit) {
      if (currServices.length === 0) {
        timePicker = dayjs(bookingDate);
      } else {
        const lastItem = last(currServices);
        const breakTimeMinutes = BREAK_TIME_MINUTES;
        const prevServiceMinutes = (lastItem?.duration_time ?? 0) * (lastItem?.quantity ?? 0);
        const nextTimeStart = moment(lastItem?.time_start, TIME_START_FORMAT).add(breakTimeMinutes + prevServiceMinutes, 'minute');
        timePicker = dayjs(nextTimeStart?.format());
      }
    } else {
      const curTimeStart = moment(currentService?.time_start, TIME_START_FORMAT);
      timePicker = dayjs(curTimeStart?.format());
    }
    return timePicker;
  };

  useEffect(() => {
    form.setFieldsValue({
      duration: dataService?.duration_time,
      time_start: getTimeStart(),
      // members: getSelectedMember(),
    });
  }, [dataService, isOpenModal, currServices]);

  useEffect(() => {
    if (!isEdit) {
      setSelectedTeamMember(get(first(memberOpts), 'value') as any);
    }
    setSelectedTeamMember(dataService?.employee_id || dataService?.assigned_employee?.employee?.id as any);
  }, [memberOpts]);

  
  return (
    <>

      <Modal
        maskClosable={false}
        open={isOpenModal}
        onCancel={(e) => handleCancel(e)}
        okButtonProps={{ style: { display: 'none' } }}
        width={574}
        footer={null}
        forceRender
        centered
      >
        <Spin spinning={loadingScheduleOnline}>
          <NewServicesModalStyled>
            <h2 className='modal-title'>  
              <p>{bookingLang('ServiceDetails')}</p>
            </h2>
            <Form
              data-form-testing='form-booking-online-service-add'
              name='basic'
              form={form}
              className='modal-form'
              autoComplete='off'
              onFinish={handleSubmit}
              layout='vertical'
              initialValues={{
                bookingDate: dayjs(currentSelectBookingDate),
                bookingTime: dayjs(currentSelectBookingTime, 'HH:mm'),
                members: dataService?.employee_id || undefined,
              }}
            >
              <div className='form-label'>{isNameService.name} {isNameService.name ? ' - ' : ''} {dataService?.name}</div>
              <div>{dataService?.description}</div>
              <div className='form-row'></div>
              <div className='form-price'>
                <span className='price-value'>
                  {formatMoney(
                    (dataService?.sale_price !== 0
                      ? dataService?.sale_price
                      : dataService?.regular_price) ?? 0
                  )}
                </span>
                <div className='quantity-control'>
                  <Form.Item name='quantity' className='hidden'>
                    <Input
                      defaultValue={1}
                      disabled={true}
                      value={quantity}
                    ></Input>
                  </Form.Item>
                  <span
                    onClick={handleDecrease}
                    className={`control-btn ${quantity === 1 && 'is-disabled'}`}
                  >
                    <IconDecrease />
                  </span>
                  <span className='quantity-value'>{quantity}</span>
                  <span onClick={handleIncrease} className='control-btn'>
                    <IconIncrease />
                  </span>
                </div>
              </div>
              <div className={(disableMember && !teamMemberSelected?.length) || teamMemberSelected?.length === 1 ? 'hide-time-member' : ''}>
                <Form.Item
                  label={bookingLang('TeamMember')}
                  name='members'
                  rules={[{ required: false, message: bookingLang('MemberIsRequired')||'' }]}
                >
                  <Select
                    onChange={onChangeTeamMember}
                    placeholder={bookingLang('SelectTeamMemberPlaceholder') || ''}
                    options={memberOpts}
                    value={selectedTeamMember}
                  />
                </Form.Item>
              </div>

              {openReSelectBookingTime ? (
                <>
                  <div className='message-member-wraning'>
                    <div>
                      <img src={MessageWarning} />
                    </div>
                    <div>
                      {bookingLang('TheSelectedMemberIsOnADayOff.PleaseChooseAnotherMemberOrSelectADifferentBookingDayForBetterService')}
                    </div>
                  </div>
                  <div className='form-row'>
                    {/* <Form.Item name={'bookingDate'} label='Booking Date'>
                        <DatePicker disabledDate={disableDate} value={dayjs(currentSelectBookingDate)} onChange={handleChangeBookingDate}/>
                      </Form.Item>
                      <Form.Item name='bookingTime' label='Booking Time'>
                        <TimePickerAutoChange
                          disabledTime={() => ({
                            disabledHours: () => disabledHour(),
                            disabledMinutes: disableMinute
                          })}
                          format={'HH:mm'}
                          allowClear={false}
                          className='is-time-picker'
                          minuteStep={5}
                          showNow={false}
                          // onSelect={setBookingTime}
                          dropdownClassName='time-picker-custom'
                          value={dayjs(currentSelectBookingTime, 'HH:mm')}
                          onChange={handleChangeBookingTime}
                        />
                      </Form.Item> */}
                  </div>
                </>
              ) : <></>}
              <Form.Item name={'time_start'}></Form.Item>
              <Form.Item name={'duration'}></Form.Item>
              <div className='form-row form-submit-wrapper position-unset'>
                <button className='common-btn is-white' onClick={handleCancel}>
                  {bookingLang('Cancel')}
                </button>
                <button disabled={openReSelectBookingTime} className='common-btn' type='submit'>
                  {bookingLang('Save')}
                </button>
              </div>
            </Form>
          </NewServicesModalStyled>
        </Spin>
      </Modal>
      <VerifyPin
        visible={visibleVerifyPin}
        onCancel={() => setVisibleVerifyPin(false)}
        onSubmit={actionSubmitRef.current}
      />
    </>
  );
};

export default NewServicesModal;

const NewServicesModalStyled = styled.div`
  @media (max-width: 767.98px) {
    .form-label {
      margin-top: 24px;
    }
  }
  .message-member-wraning {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-bottom: 24px;
    margin-top: -24px;
    color: #FA8C16;
  }
  .hide-time-member {
    display: none;
  }
`;
