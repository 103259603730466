import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import ListProductTable from '../Components/ProductFrom/ListProductTable';
import InputSearchText from 'components/common/Input/InputSearchDebounce';
import { Select, Tooltip, message } from 'antd';
import img_sample_01 from '../../../assets/images/customers/img_sample_01.jpg';
import styles from './index.module.scss';
import { IconWarning } from 'assets/svg';
import styled from 'styled-components';
import { useMemo, useRef, useState } from 'react';
import { IServiceReponse } from './BrandPage';
import apisService from 'features/services/services/apis';
import { get } from 'lodash';
import serviceActions from 'features/services/services/actions';
import { useDispatch } from 'react-redux';
import ModalConfirm, {
    ModalConfirmRef,
} from 'components/common/Modal/ModalConfirm';
import MoneyValidations from 'utils/validations/money';

const ListProductPage = () => {
    const { t: productLang } = useTranslation('products');
    const dispatch = useDispatch();
    const modalConfirmRef = useRef<ModalConfirmRef>(null);
    const buttonWidth = 70;
    const btnProps = {
        style: {
            width: buttonWidth,
        },
    };
    const [arrow, ] = useState('Show');

    const mergedArrow = useMemo(() => {
        if (arrow === 'Hide') {
            return false;
        }

        if (arrow === 'Show') {
            return true;
        }

        return {
            pointAtCenter: true,
        };
    }, [arrow]);

    const onClickRemoveItem = (record: IServiceReponse) => {
        modalConfirmRef.current?.show({
            title: productLang('modalConfirm.deleteProduct.title'),
            msg: productLang('modalConfirm.deleteProduct.message'),
            submit: async () => {
                try {
                    const res = await apisService.deleteService(record.id);
                    const msg = get(res, 'data.message', '');
                    if (msg) {
                        message.success(msg);
                        dispatch(serviceActions.getListPaginateServices.fetch({}));
                    } else {
                        const msgError = get(res, 'data.error.message');
                        if (msgError) message.error(msgError);
                    }
                } catch (error) {
                    message.error('An error occurred. Please try again');
                }
            },
            icon: 'delete',
        });
    };

    const columns = [
        {
            title: '',
            dataIndex: 'image',
            key: 'image',
            // fixed: 'left',
            className: `${styles.column_header_mobile}`,
            render: () => {
                return (
                    <div className={styles.img_product_table}>
                        <img src={img_sample_01} alt="" />
                    </div>
                );
            },
        },
        {
            title: productLang('IDProduct'),
            dataIndex: 'id_product',
            key: 'id_product',
            fixed: 'left',
            className: `${styles.column_fixed_left} ${styles.column_with}`,
            render: (text: any) => {
                return (
                    <div className={styles.id_product_table}>
                        {text}
                    </div>
                );
            },
        },
        {
            title: productLang('ProductName'),
            dataIndex: 'name',
            key: 'name',
            className: `${styles.column_with}`,
            render: (text: any) => {
                return (
                    <div>
                        <p className={styles.name_product_table}>{text}</p>
                        <p className={styles.classify_product_table}>2 Phân loại</p>
                    </div>
                );
            },
        },
        {
            title: productLang('Category'),
            dataIndex: 'category',
            key: 'category',
            className: `${styles.column_with}`,
            render: (text: string, record: any) => {
                return (
                    <div className={styles.product_category}>
                        <p style={{ color: record.color }}>{text}</p>
                    </div>
                );
            },
        },
        {
            title: productLang('Brand'),
            dataIndex: 'brand',
            key: 'brand',
            className: `${styles.column_with}`,
            render: (text: string) => {
                return (
                    <div className={styles.product_brand}>
                        <p>{text}</p>
                    </div>
                );
            },
        },
        {
            title: productLang('Depot'),
            dataIndex: 'depot',
            key: 'depot',
            className: `${styles.column_with}`,
            render: (text: string) => {
                return (
                    <div className={styles.product_depot}>
                        <p>{text}</p>
                        {parseFloat(text) <= 5 ?
                            <Tooltip color='rgba(0, 0, 0, 0.60)' placement="topLeft" title={'Sản phẩm sắp hết hàng'} arrow={mergedArrow}>
                                <div {...btnProps}><IconWarning /></div>
                            </Tooltip>
                            : ''}
                    </div>
                );
            },
        },
        {
            title: productLang('Expiry'),
            dataIndex: 'expiry',
            key: 'expiry',
            className: `${styles.column_header_mobile} ${styles.column_expiry_table}`,
            render: (text: string) => {
                return (
                    <div className={styles.product_brand}>
                        <p>{text}</p>
                        <div>

                            <Tooltip color='rgba(0, 0, 0, 0.60)' placement="topLeft" title={'Sản phẩm sắp hết hạn sử dụng'} arrow={mergedArrow}>
                                <div {...btnProps}><IconWarning /></div>
                            </Tooltip>
                        </div>
                    </div>
                );
            },
        },
        {
            title: productLang('Price'),
            dataIndex: 'sale_price',
            key: 'sale_price',
            className: `${styles.column_with}`,
            render: (text: string) => `${(MoneyValidations.formatMoney(parseFloat(text) || 0)) ?? 0}đ`,
        },
        {
            title: productLang('Status'),
            dataIndex: 'status',
            key: 'status',
            className: `${styles.column_with}`,
            render: (text: string) => {
                return (
                    <div className={text === 'Đã ẩn' ? styles.product_status_hidden : styles.product_status}>
                        <p>{text}</p>
                    </div>
                );
            },
        },
        {
            title: productLang('Actions'),
            dataIndex: 'actions',
            key: 'actions',
            className: `${styles.column_with}`,
            render: (record: IServiceReponse) => (
                <ul className={'common-action'}>
                    <Link to="add">
                        <li
                            className='action-edit'
                        ></li>
                    </Link>

                    <li
                        className='action-remove'
                        onClick={() => onClickRemoveItem(record)}
                    ></li>
                </ul>
            ),
        },
    ];

    return (
        <>
            <ListProductPageStyled>
                <div className={'private-filter'}>
                    <InputSearchText />
                    <div className='common-select'>
                        <Select
                            defaultValue={1}
                            options={[
                                {
                                    value: 1,
                                    label: productLang('Sold'),
                                },
                                {
                                    value: 2,
                                    label: productLang('NoSold'),
                                },
                            ]}
                        />
                    </div>
                    <button
                        className='common-btn-import'
                    >
                        <span>{productLang('Import')}</span>
                    </button>
                    <button className='common-btn-export'>
                        <span>{productLang('Export')}</span>
                    </button>
                    <div className={'private-filter'}>
                        <Link to="add-new" className={'common-btn'}>
                            {productLang('AddNew')}
                        </Link>
                    </div>
                </div>
            </ListProductPageStyled>
            <ListProductTable columns={columns} />

            <ModalConfirm ref={modalConfirmRef} />
        </>
    );
};

export default ListProductPage;

const ListProductPageStyled = styled.div`
.img_product {
    @media only screen and (max-width: 767.98px) {
        dislay: none
    }
}
`;