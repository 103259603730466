import { Form, FormProps } from 'antd';
import styles from '../../pages/index.module.scss';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';


export interface IFormTabSettingProps {
    form: FormProps['form'];
    handleSubmit?: any;
    children?: any;
    checkValid?: any;
    initialValues?: any;
}

const FormTabSetting = ({
    form,
    handleSubmit,
    children,
    checkValid,
    initialValues
}: IFormTabSettingProps) => {

    const _onSubmit: React.MouseEventHandler<HTMLButtonElement> = async (e) => {
        e.preventDefault();
        const isFormValid = await checkValid();
        if (!isFormValid) return;
        form?.submit();
    };

    const { t: bookingLang } = useTranslation('booking');

    return (
        <Form
            form={form}
            layout='vertical'
            onFinish={handleSubmit}
            initialValues={initialValues}
        >
            {children}
            <SettingStyled>
                <div className={`form-row form-submit-wrapper ${styles.form_footer}`}>
                    {/* <button className='common-btn is-white' type='button'>Cancel</button> */}
                    <button className='common-btn' type='submit' onSubmit={_onSubmit}>{bookingLang('Save')}</button>
                </div>
            </SettingStyled>
            <div className={styles.form_footer_shadow}></div>
        </Form>
    );
};

export default FormTabSetting;

const SettingStyled = styled.div`
.form-submit-wrapper {
    justify-content: flex-start;
    padding: 12px 200px;
    background: transparent;
    @media only screen and (max-width: 767.98px) {
        position: fixed;
        bottom: 68px;
        right: 0;
        left: 0;
        padding: 12px;
        background: #FFFFFF;
        z-index: 9999;
    }
}
`;