import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import uiSelector from '../selectors';
import { useAppDispatch } from 'store/hooks';
import actions from '../actions';
import { useTranslation } from 'react-i18next';
import lottie from 'lottie-web';
import animationData from '../../../../src/assets/json/99274-loading.json';

export const useSetLoadingPage = () => {
  const dispatch = useAppDispatch();
  return (result: boolean) => dispatch(actions.setLoadingPage(result));
};

function LoadingPage() {
  const loading = uiSelector.getLoadingPage();
  const { t: commonLang } = useTranslation('common');
  

  if (!loading) return null;
  
  return (
    <LoadingPageStyled>
      <div className="box">
        <LoadingIcon />
        <p>{commonLang('LoadingText')}</p>
      </div>
    </LoadingPageStyled>
  );
}

export default LoadingPage;

const LoadingPageStyled = styled.div`
  position: fixed;
  inset: 0;
  background: rgba(0, 0, 0, 0.4);
  display: flex;
  align-items: center;
  justify-content:center;
  z-index: 9999;
  .box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 95px 132px;
    gap: 16px;
    background: #FFFFFF;
    border-radius: 6px;
    p {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      color: #363565;
    }
  }
`;

const LoadingIcon = () => {
  const containerRef = useRef(null);

  useEffect(() => {
    if (containerRef.current) {
      const anim = lottie.loadAnimation({
        container: containerRef.current,
        renderer: 'svg',
        loop: true,
        autoplay: true,
        animationData: animationData,
      });

      return () => anim.destroy();
    }
  }, []);

  return <div ref={containerRef}></div>;
  // return (
  //   // <svg width="98" height="89" viewBox="0 0 98 89" fill="none" xmlns="http://www.w3.org/2000/svg">
  //   //   <path d="M9.29795 20.2665C8.66669 21.4076 8.2759 21.9545 7.76064 22.9844C6.27165 25.9738 5.96361 29.0117 6.48537 31.9349C6.85568 34.0209 7.93936 35.6462 9.92442 36.7566C11.3877 37.573 13.5341 37.6017 14.9773 36.743C17.0949 35.4802 17.5775 32.3036 17.5756 30.0309C18.0282 30.7128 18.4158 31.8549 18.7795 32.3765C19.5941 33.545 20.658 34.6976 21.8761 35.4512C25.0689 37.4234 29.5676 36.2847 31.4063 33.4635C32.9537 31.0904 33.0689 26.412 30.5559 24.2917C28.8605 22.8632 25.5648 23.1196 23.4553 23.0864C24.7796 22.8794 27.1348 21.4237 27.7119 20.1819C28.7234 18.0078 26.2528 14.6866 24.4425 13.6661C20.9876 11.7169 16.2708 12.3186 13.0104 14.2686C11.6996 15.0531 10.6393 16.9533 9.94984 18.3764C9.75882 18.7681 9.70739 19.2635 9.50957 19.6187L9.29795 20.2665Z" fill="#FBDC5E" />
  //   //   <path d="M69.9375 42.6294C70.7185 44.0414 71.2021 44.718 71.8396 45.9923C73.682 49.6912 74.0631 53.4501 73.4175 57.067C72.9593 59.6481 71.6185 61.659 69.1623 63.033C67.3517 64.0432 64.696 64.0787 62.9103 63.0162C60.2901 61.4537 59.6929 57.5233 59.6953 54.7112C59.1353 55.5549 58.6558 56.968 58.2057 57.6134C57.1978 59.0592 55.8814 60.4854 54.3742 61.4179C50.4237 63.8581 44.8573 62.4491 42.5822 58.9584C40.6676 56.0221 40.5251 50.2334 43.6345 47.61C45.7323 45.8424 49.8101 46.1596 52.4202 46.1185C50.7817 45.8624 47.8676 44.0613 47.1535 42.5248C45.9019 39.8347 48.9588 35.7253 51.1987 34.4626C55.4736 32.0508 61.3098 32.7954 65.3439 35.208C66.9659 36.1787 68.2778 38.5299 69.1309 40.2908C69.3672 40.7755 69.4308 41.3883 69.6756 41.8279L69.9375 42.6294Z" fill="#FBDC5E" />
  //   //   <path d="M18.8278 23.2992C17.5438 23.5199 16.2199 23.4887 14.9339 23.2688C13.6489 23.0416 12.3964 22.6222 11.2166 22.0618C10.0341 21.4995 8.93242 20.7761 7.91977 19.9487C6.90671 19.1191 5.99527 18.1644 5.21279 17.1172C4.43174 16.0651 3.76354 14.9353 3.20374 13.7547C2.64166 12.5745 2.18527 11.3415 1.87758 10.0714C1.56533 8.80213 1.37716 7.50263 1.36225 6.19675C1.35234 4.8923 1.51299 3.57961 1.92135 2.34332C1.56324 3.59617 1.4295 4.90148 1.47502 6.19457C1.51371 7.48894 1.72071 8.7755 2.05091 10.0273C2.1419 10.338 2.21696 10.6518 2.32162 10.96L2.63203 11.8783L2.99133 12.7804C3.11565 13.0802 3.26147 13.369 3.39448 13.6648C3.95586 14.8287 4.62152 15.9448 5.3969 16.9791C6.92883 19.0607 8.98471 20.7404 11.2951 21.901C12.4538 22.4747 13.6833 22.9102 14.9554 23.1563C16.2245 23.4123 17.5368 23.4694 18.8278 23.2992Z" fill="#488733" />
  //   //   <path d="M58.1647 46.382C59.7535 46.655 61.3916 46.6164 62.9828 46.3443C64.5727 46.0633 66.1225 45.5442 67.5823 44.8508C69.0454 44.1552 70.4086 43.26 71.6615 42.2363C72.915 41.2098 74.0427 40.0285 75.0109 38.7328C75.9773 37.431 76.8041 36.0331 77.4968 34.5723C78.1923 33.112 78.757 31.5864 79.1377 30.0149C79.524 28.4444 79.7569 26.8365 79.7753 25.2207C79.7876 23.6067 79.5888 21.9824 79.0835 20.4527C79.5266 22.0029 79.6921 23.618 79.6358 25.218C79.5879 26.8195 79.3318 28.4114 78.9232 29.9603C78.8106 30.3448 78.7177 30.733 78.5882 31.1144L78.2042 32.2506L77.7596 33.3668C77.6058 33.7378 77.4253 34.0951 77.2608 34.4611C76.5662 35.9012 75.7425 37.2822 74.7831 38.562C72.8876 41.1375 70.3438 43.2158 67.4852 44.6519C66.0515 45.3618 64.5301 45.9007 62.9562 46.2051C61.3858 46.5219 59.7622 46.5925 58.1647 46.382Z" fill="#488733" />
  //   //   <path d="M83.0569 73.2813C85.8238 74.923 90.0028 74.0271 92.6405 72.6569C95.0331 71.4124 96.0911 69.3404 97.5447 67.2221C97.2523 67.52 96.6379 67.4626 96.175 67.3744C94.9417 67.1405 93.9364 66.6881 92.6327 66.7455C91.2671 66.8073 89.3738 67.4163 88.2513 67.9812C89.8831 66.0857 88.9143 62.2375 87.9573 60.2119C87.6263 59.5102 87.5087 59.1461 86.8387 59.4308C85.795 59.8743 84.598 61.3769 83.941 62.2529C82.4727 64.2101 80.9835 67.5862 81.4501 69.945C81.5836 70.6224 81.7954 71.1939 82.0923 71.8096C82.2093 72.0523 82.534 72.8157 82.5122 72.6635C82.5122 72.6635 82.6902 73.0629 83.0569 73.2813Z" fill="#FBDC5E" />
  //   //   <path d="M26.3646 74.5732C27.5143 72.1102 26.3292 68.7354 24.8846 66.6877C23.5729 64.8303 21.6758 64.1647 19.6998 63.1756C19.9863 63.3877 19.9969 63.9032 19.9659 64.2962C19.8843 65.3431 19.5914 66.2233 19.7688 67.2988C19.9562 68.4253 20.6689 69.9334 21.2679 70.8067C19.4673 69.647 16.2315 70.8443 14.572 71.8453C13.9971 72.1916 13.6935 72.3264 14.0056 72.8531C14.4918 73.6734 15.9097 74.5126 16.7324 74.968C18.5704 75.9856 21.6385 76.8755 23.6344 76.2473C24.2076 76.0672 24.6815 75.8331 25.1853 75.5238C25.3839 75.402 26.0129 75.0545 25.8832 75.0882C25.8832 75.0882 26.2115 74.8997 26.3646 74.5732Z" fill="#FBDC5E" />
  //   //   <path d="M85.4084 70.1228C85.0311 70.1307 84.689 70.3574 84.3789 70.6049C84.068 70.8603 83.7823 71.1652 83.5085 71.4831C82.9579 72.1164 82.4612 72.8174 81.9541 73.5158C80.9475 74.8969 80.0705 76.437 79.3922 78.1126C79.0337 78.9361 78.7433 79.8065 78.4693 80.6794C78.3513 81.1251 78.2105 81.5629 78.1137 82.0189L77.955 82.6964L77.8194 83.3818L77.7514 83.7232L77.6972 84.0698L77.5897 84.7656L77.5109 85.4639L77.4717 85.8157L77.4465 86.1675C77.3551 87.1057 77.3631 88.0516 77.3787 89.0002C77.2412 87.1135 77.3344 85.1929 77.6654 83.3349L77.7888 82.6391L77.9437 81.9537C78.0388 81.4925 78.1779 81.0495 78.2945 80.5961C78.5656 79.7101 78.8531 78.8292 79.211 77.9901C79.9119 76.3067 80.7979 74.7458 81.8408 73.3776C82.359 72.7001 82.8731 72.0069 83.4423 71.3867C83.729 71.0818 84.0274 70.7899 84.3506 70.5476C84.671 70.3105 85.0305 70.102 85.4084 70.1228Z" fill="#488733" />
  //   //   <path d="M23.905 72.1475C23.9526 72.5006 24.1587 72.8015 24.3767 73.0705C24.6007 73.3397 24.8585 73.5808 25.1247 73.8097C25.6556 74.2705 26.2307 74.6747 26.805 75.089C27.9412 75.9118 29.181 76.599 30.4993 77.0881C31.1499 77.3521 31.8278 77.5481 32.5059 77.7285C32.8495 77.8 33.19 77.8937 33.539 77.9445L34.0593 78.0339L34.5828 78.101L34.8436 78.1347L35.1067 78.1552L35.6348 78.195L36.1617 78.2076L36.427 78.2136L36.6908 78.2064C37.3969 78.2098 38.0978 78.1193 38.7998 78.0215C37.4154 78.3159 35.9802 78.3969 34.565 78.2494L34.0352 78.1948L33.5095 78.1096C33.1568 78.0609 32.8127 77.9693 32.4634 77.8997C31.776 77.7232 31.0907 77.5308 30.4285 77.2688C29.1019 76.7592 27.8457 76.0653 26.7151 75.2073C26.155 74.7808 25.5837 74.3595 25.0605 73.8803C24.8026 73.6382 24.553 73.384 24.3373 73.1021C24.1259 72.8225 23.9314 72.5037 23.905 72.1475Z" fill="#488733" />
  //   // </svg>
  //   // <p>{JSON.stringify('../../../../src/assets/json/92460-checkmark-animation.json')}</p>
  //   <div ref={containerRef}></div>
  // );
};
