import { Form, FormInstance } from 'antd';
import React from 'react';
import ActiveHoursElement from '../pages/widgets/ActiveHoursElement';
import { useTranslation } from 'react-i18next';

interface Props {
  form?: FormInstance
}

/**
 * Locations form third step
 * @param form 
 * @returns 
 */
const LocationsFormThirdStep: React.FC<Props> = ({ form }: Props) => {
  // const onChange = () => undefined;

  // const range = (start: number, end: number) => {
  //   const result = [];
  //   for (let i = start; i < end; i++) {
  //     result.push(i);
  //   }
  //   return result;
  // };

  // const disabledDateTime = () => ({
  //   disabledHours: () => [...range(0, 6), ...range(18, 24)],
  // });

  const{t: locationLang} = useTranslation('location');
  const{t: dateLang} = useTranslation('date');

  const dayOfWeek = [
    {
      'label': dateLang('SUN'),
      'value': 'sunday',
    },
    {
      'label': dateLang('MON'),
      'value': 'monday',
    },
    {
      'label': dateLang('TUE'),
      'value': 'tuesday',
    },
    {
      'label': dateLang('WED'),
      'value': 'wednesday',
    },
    {
      'label': dateLang('THU'),
      'value': 'thursday',
    },
    {
      'label': dateLang('FRI'),
      'value': 'friday',
    },
    {
      'label': dateLang('SAT'),
      'value': 'saturday'
    }
  ];

  return (
    <>
      <p className='form-label'>{locationLang('ActiveHours')}</p>
      <div className='form-calendar'>
        <Form.Item name='calenda'>

          {dayOfWeek.map((item: any, index) => (
            <ActiveHoursElement form={form} name={item.value} key={index} label={item.label} />
          ))
          }
        </Form.Item>
      </div>
    </>
  );
};

export default LocationsFormThirdStep;
