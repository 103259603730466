/* eslint-disable no-dupe-else-if */
import { Form, Spin, Switch } from 'antd';
import MultipleSelect, { IOptionMulti } from 'components/common/MultipleSelect';
import { get } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
// import PromotionsFormFirstStep from '../components/PromotionsFormFirstStep';
import promotionSelectors from '../services/selectors';
import { IApiPromotionBodyReq } from '../types/api';
import { IPromotionFormValue } from '../types/promotion';
import { PROMOTION_TYPES, RE_ENGAGE_OFFER } from '../services/constants';
import { FormInstance } from 'antd/lib/form/Form';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { useAppDispatch } from 'store/hooks';
import promotionActions from '../services/actions';
// import PromotionsFormSecondStep from '../components/PromotionsFormSecondStep';
import SelectPromotion from './SelectPromotion';
import GeneralInformation from '../components/GeneralInformation';
import SwitchSetting from '../components/SwitchSetting';


export type IPromotionFormProps = {
  onClose?: () => void;
  isEdit?: boolean;
  formData?: IPromotionFormValue | null;
  onSubmit?: (payload: IApiPromotionBodyReq) => void;
  errors?: Record<string, string> | null;
  form: FormInstance;
  submit: any;
  formType?: 'CREATE' | 'UPDATE';
  handleUpdateError?: (data: Record<string, string>) => void;
  isSelectPromotion: any;
  isCategoryPromotion: any;
  handlePromotionSelect: any;
};
const PromotionForm: React.FC<IPromotionFormProps> = ({
  // onClose = () => undefined,
  onSubmit = () => undefined,
  formData = null,
  isEdit = false,
  errors = null,
  form,
  submit,
  formType,
  isSelectPromotion,
  isCategoryPromotion,
  handlePromotionSelect
  // handleUpdateError,
}) => {
  // const [form] = Form.useForm();
  const [selectedLocations, setSelectedLocations] = useState<
    (string | number)[]
  >([]);
  const [selectedServices, setSelectedServices] = useState<(string | number)[]>(
    []
  );
  const { t: formLang } = useTranslation('form');
  const { t: promotionLang } = useTranslation('promotions');
  const dispatch = useAppDispatch();
  const loadingPromotionCode =
    promotionSelectors.getLoadingGeneratePromotionCodeActions();
  const promotionCode = promotionSelectors.generatePromotionCode();

  useEffect(() => {
    setSelectedLocations(formData?.locations?.map((i) => Number(i)) ?? []);
  }, [formData]);

  const configsData = promotionSelectors.getConfigsData();
  const locationOpts = useMemo(
    () =>
      configsData?.locarions
        ?.filter((o) => o.status === 1)
        ?.map((o) => ({ title: o.name, value: o.id } as IOptionMulti)) ?? [],
    [configsData]
  );

  const serviceOpts = useMemo(() => {
    const result: IOptionMulti[] = [];
    configsData?.services
      .filter((o) => {
        return !o?.merchant_location_id?.find(
          (d) => !!selectedLocations.find((v) => v.toString() === d.toString())
        );
      })
      ?.forEach((service) => {
        if (service?.service_variants?.length > 0) {
          service?.service_variants.forEach((serviceVariant) => {
            result.push({
              value: service?.id + '-' + serviceVariant.id,
              title: service.name + ' - ' + serviceVariant.name,
            });
          });
        } else {
          result.push({
            value: service.id + '',
            title: service.name,
          });
        }
      });
    return result;
  }, [configsData, selectedLocations]);

  useEffect(() => {
    form.setFieldValue('services', selectedServices);
  }, [selectedServices]);

  useEffect(() => {
    form.setFieldValue('locations', selectedLocations);
  }, [selectedLocations]);

  useEffect(() => {
    form.setFieldValue('code', promotionCode);
  }, [promotionCode]);

  useEffect(() => {
    if (!isEdit) return;
    form.setFieldsValue(formData);
  }, [formData]);



  const getPayload = (values: IPromotionFormValue) => {
    let value: IApiPromotionBodyReq['value'] = 0;
    let type: IApiPromotionBodyReq['type'] = '';
    if (values.amountValue) {
      type = PROMOTION_TYPES.PRICE;
      value = values.amountValue;
    } else if (values.percentValue) {
      type = PROMOTION_TYPES.PERCENT;
      value = values.percentValue;
    }
    const services: IApiPromotionBodyReq['services'] = [];
    values.services?.forEach((s) => {
      if (!s) return;
      const arr = s.split('-');
      const serviceParentId = get(arr, [0], '')?.toString();
      const serviceExtraId = get(arr, [1], '')?.toString();

      const parent = services.find((o) => o.id?.toString() === serviceParentId);
      if (parent) {
        parent.service_variant_ids.push(+serviceExtraId);
      } else {
        if (serviceExtraId) {
          services.push({
            id: +serviceParentId,
            service_variant_ids: [+serviceExtraId],
          });
        } else {
          services.push({
            id: +serviceParentId,
            service_variant_ids: [],
          });
        }
      }
    });

    const location_ids = values.locations?.map((o) => +o) ?? null;

    const result: IApiPromotionBodyReq = {
      promotion_type: values?.promotionType,
      name: values.name ?? '',
      type,
      value,
      location_ids,
      services,
      usage_limit_per_customer: values?.limitToUsePerCustomerValue,
      usage_limit_per_promotion: values?.limitTotalNumberOfUseValue,
      re_engage_offer: values?.reEngageOfferValue,
      status: values.enablePromotion ? true : false,
      enable_voucher_sales: values.enableVoucherSale ? true : false,
      apply_other_promotion: values?.applyWithOtherPromotion ? true : false,
    };

    if (values?.promotionType === 'auto_offer') {
      result.start_date = values?.startDate
        ? dayjs(values?.startDate).format('YYYY-MM-DD')
        : '';
      result.end_date = values?.endDate
        ? dayjs(values?.endDate).format('YYYY-MM-DD')
        : '';
      result.apply_for_walkin = values?.enableApplyWalkIn ? true : false;
    } else {
      result.code = values?.code || '';
      result.enable_booking_online = values?.enableBookingOnline ? true : false;
    }

    return result;
  };

  const onFinish = (values: IPromotionFormValue) => {
    const payload = getPayload(values);
    if (onSubmit) onSubmit(payload);
  };

  const handleFormChangeValue = (changedValues: any) => {
    if (changedValues?.promotionType === 'code') {
      if (formType === 'CREATE' || (formType === 'UPDATE' && !formData?.code)) {
        dispatch(promotionActions.generatePromotionCode.fetch());
      } else if (formType === 'UPDATE') {
        const currentPromotionCode = form.getFieldValue('code');

        if (currentPromotionCode !== formData?.code) {
          form.setFieldValue('code', formData?.code);
        }
      }
    }
  };

  const errorsText = useMemo(() => {
    return {
      name: get(errors, 'name', ''),
      code: get(errors, 'code', ''),
      value: get(errors, 'value', ''),
      services: get(errors, 'services', ''),
      location_ids: get(errors, 'location_ids', ''),
    };
  }, [errors]);



  const [isTitle, setIsTitle] = useState<string>('');
  const [isContent, setIsContent] = useState<string>('');

  useEffect(() => {
    if (isCategoryPromotion === 'retention') {
      setIsTitle('Tái tương tác');
      setIsContent('Mã khuyến mãi sẽ được tự động gửi cho những khách hàng chưa ghé thăm địa điểm của bạn trong khoảng thời gian dài.');
    } else if (isCategoryPromotion === 'signup') {
      setIsTitle('Đăng ký lần đầu');
      setIsContent('Mã khuyến mãi sẽ được tự động gửi cho những khách hàng đăng ký và truy cập lần đầu tiên trên ứng dụng MiniApp của cửa hàng.');
    } else if (isCategoryPromotion === 'birthday') {
      setIsTitle('Khuyến mãi sinh nhật');
      setIsContent('Mã khuyến mãi sẽ được gửi đến khách hàng và áp dụng trong tháng sinh nhật của họ.');
    } else if (isCategoryPromotion === 'vist_point') {
      setIsTitle('Tích điểm đổi quà');
      setIsContent('Tạo chương trình tích điểm cho mỗi lần khách hàng ghé thăm cửa hàng hoặc chi tiền để đạt được phần thưởng.');
    } else if (isCategoryPromotion === 'auto') {
      setIsTitle('Khuyến mãi tự động');
      setIsContent('Tạo chương trình khuyến mãi áp dụng tự động trong thời gian nhất định, bạn không cần phải nhập mã code khuyến mãi khi thanh toán.');
    } else {
      setIsTitle('Tạo mã khuyến mãi');
      setIsContent('Tạo chương trình khuyến mãi và gửi tặng đến khách hàng để tăng doanh thu cửa hàng.');
    }
  }, [isCategoryPromotion]);

  return (
    <PromotionFormStyled>
      {
        isSelectPromotion ? <SelectPromotion onPromotionSelect={handlePromotionSelect} /> :
          <Spin spinning={loadingPromotionCode}>
            <Form
              className='modal-form'
              autoComplete='off'
              form={form}
              layout='vertical'
              onKeyUp={(e) => {
                if (e.code === 'Enter') {
                  submit();
                }
              }}
              initialValues={{
                promotionType: !isEdit ? 'auto_offer' : formData?.promotionType,
                enableApplyWalkIn: true,
                enablePromotion: true,
                enableVoucherSale: true,
                enableBookingOnline: true,
                reEngageOfferValue: !isEdit || !formData?.reEngageOfferValue ? RE_ENGAGE_OFFER?.[0]?.value : formData?.reEngageOfferValue
              }}
              onFinish={onFinish}
              onValuesChange={handleFormChangeValue}
            >
              <FormContainerStyled>
                <div className='left'>
                  <div className='box-wrap'>
                    <p className='form-step-tile'>{'Thông tin'}</p>
                    <p className='form-step-tile'>{isTitle}
                      <br />
                      <p className='form-step-content'>{isContent}</p>
                    </p>
                    <GeneralInformation
                      formData={formData}
                      isUsed={formData?.isUsed}
                      errorsText={errorsText}
                      form={form}
                      isCategoryPromotion={isCategoryPromotion}
                    />

                  </div>
                  {
                    isCategoryPromotion !== 'vist_point' ?
                      <div className='box-wrap'>
                        <p className='form-step-tile'>Cài đặt áp dụng</p>
                        <div className='form-row'>
                          <Form.Item
                            label={
                              <p>
                                {formLang('Dịch vụ áp dụng (*)')}
                              </p>
                            }
                            name='services'
                            validateStatus={errorsText?.services ? 'error' : undefined}
                            help={'Áp dụng chương trình khi khách hàng sử dụng những dịch vụ được chọn'}
                            extra={errorsText?.services ? errorsText?.services : undefined}
                          >
                            <MultipleSelect
                              onChange={setSelectedServices}
                              value={selectedServices}
                              options={serviceOpts}
                              maxTagPlaceholder={promotionLang('services') || ''}
                              placeholder={promotionLang('SelectServices') || ''}
                            />
                          </Form.Item>
                        </div>
                        <div className='form-row'>
                          <Form.Item
                            label={
                              <p>
                                {formLang('Sản phẩm áp dụng (*)')}
                              </p>
                            }
                            name='locations'
                            validateStatus={errorsText?.location_ids ? 'error' : undefined}
                            help={'Áp dụng chương trình khi khách hàng mua những sản phẩm được chọn'}
                            extra={errorsText?.location_ids
                              ? errorsText?.location_ids
                              : undefined}
                          >
                            <MultipleSelect
                              placeholder={promotionLang('SelectLocations') || ''}
                              options={locationOpts}
                              onChange={setSelectedLocations}
                              value={selectedLocations}
                              maxTagPlaceholder='locations'
                            />
                          </Form.Item>
                        </div>
                        <SwitchStyled className='switch-check'>
                          <Form.Item
                            name={'limitToUsePerCustomer'}
                            valuePropName='checked'
                          >
                            <Switch />
                          </Form.Item>
                          <div>
                            <p className='form-label'>{formLang('Áp dụng khi bán voucher')}</p>
                          </div>
                        </SwitchStyled>
                      </div>
                      : <></>
                  }
                </div>
                <div className='right'>

                  <div className='box-wrap'>
                    <p className='form-step-tile'>{'Cài đặt thêm'}</p>
                    <SwitchSetting isCategoryPromotion={isCategoryPromotion} />
                  </div>
                </div>
              </FormContainerStyled>

            </Form>
          </Spin>
      }
    </PromotionFormStyled>
  );
};

export default PromotionForm;

const SwitchStyled = styled.div`
&.switch-check {
  display: inline-flex;
  gap: 4px;
  .form-label {
    margin-top: 5px;
  }
}
`;

const PromotionFormStyled = styled.div`
.ant-form {
  display: flex;
  align-items: center;
  gap: 24px;
}
@media only screen and (max-width: 767.98px) {
  .ant-modal .ant-modal-close {
    display: none;
  }
}
  .hide {
    display: none;
  }
  .form-row {
    margin-bottom: 0;
    &.switch-check {
      margin-bottom: 15px;
    }
    &.groupPrice {
      margin-bottom: 0;
      display: flex;
      .center-text {
        margin: 0;
        margin-bottom: 18px;
      }
      .ant-col {
        &.ant-form-item-control {
          margin-bottom: 0;
        }
      }
    }
    .promotion-start-date,
    .promotion-end-date,
    .promotion-start-date:hover,
    .promotion-end-date:hover {
      background: #f0f2f6 !important;
    }
  }
  .ant-form-item {
    &.messageError .ant-form-item-control-input {
      display: none;
      min-height: 0 !important;
    }
  }

  .box-wrap {
    margin-top: 24px;
    border-radius: 10px;
    border: 1px solid #c3c2e0;
    padding: 16px;
    width: 700px;

    @media only screen and (max-width: 767.98px) {
      width: auto;
    }

    .form-step-tile {
      color: var(--grayish-navy-main, #363565);
      font-size: 20px;
      font-weight: 600;
      line-height: 140%;
      margin-bottom: 24px;
      .form-step-content {
        color: var(--Gray-60, #595959);
        font-family: Inter;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%;
      }
    }

    .input-number-custom {
      .ant-input-number {
        width: 100%;
        border: none;
      }
    }
  }

  @media (max-width: 1180px) {
    .box-wrap {
      max-width: 648px;
    }
  }

  .form-submit-wrapper {
    bottom: -35px
  }

  @media (min-width: 1181px) {
    .form-submit-wrapper {
      padding-bottom: 36px;
    }
  }

  @media (max-width: 1180px) {
    .form-submit-wrapper {
      padding-bottom: 14px;
    }
  }
`;

const FormContainerStyled = styled.div`
display: flex;
justify-content: center;
gap: 24px;

.left {
  width: 50%;
}

.right {
  width: 50%;
}
@media only screen and (max-width: 767.98px) {
  display: grid;
}
.box-wrap {
  width: 100%;
}
`;


