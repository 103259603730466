import styled from 'styled-components';
import SetupTopUpCard from './SetupTopUpCard';
import TopUpStory from './TopUpHistory';
import { useDispatch } from 'react-redux';
import settingSelectors from 'features/settings/services/selectors';
import { useEffect, useState } from 'react';
import settingActions from 'features/settings/services/actions';
import { Spin } from 'antd';

const AutoMessageTab = () => {
  const [confirmTopUpOpen, setConfirmTopUpOpen] = useState<boolean>(false);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(settingActions.getTopUpInitData.fetch({ page: 1, per_page: 10 }));
  }, []);

  const loadingTopUpHistory = settingSelectors.loadTopupInitData();

  return (
    <AutoMessageTabContainer>
      <Spin spinning={loadingTopUpHistory}>
        <AutoMessageTabStyled>
          <SetupTopUpCard
            confirmTopUpOpen={confirmTopUpOpen}
            handleConfirmTopupClose={() => setConfirmTopUpOpen(false)}
          />
          <TopUpStory handleOpenConfirmTopup={() => setConfirmTopUpOpen(true)} />
        </AutoMessageTabStyled>
      </Spin>
    </AutoMessageTabContainer>

  );
};

export default AutoMessageTab;

const AutoMessageTabContainer = styled.div`
margin-top: 100px;
@media only screen and (max-width: 767.98px) {
  margin-top: 0;
}
`;

const AutoMessageTabStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;

  .cards-wrapper {
    @media (min-width: 768px) and (max-width: 1180px) {
      width: 100%;

      .cards-col {
        max-width: 100%;
        width: 100%;

        .common-card {
          max-width: 100%;
          width: 100%;
        }
      }
    }
  }
`;
