import { ConfigProvider, DatePicker, Space } from 'antd';
import dayjs from 'utils/dayjs';
import moment from 'moment';
import { useState } from 'react';
import styled from 'styled-components';
import { useAppDispatch } from 'store/hooks';
import timeScheduleActions from 'features/timeSchedule/services/actions';
import 'dayjs/locale/vi';
import viVN from 'antd/lib/locale/vi_VN';
import enEL from 'antd/lib/locale/en_US';

/**
 * Time schedule date view
 * @returns 
 */
function TimeScheduleDateView() {
  const data = {
    end_date: '2024-01-03',
    start_date: '2024-01-05'
  };

  const lang = 'vi';
  dayjs.locale(lang === 'vi' ? 'vi' : 'en');
  const locale = lang === 'vi' ? viVN : enEL;

  const dispatch = useAppDispatch();
  const [open, setOpen] = useState(false);
  const [dateState, setDateState] = useState(dayjs(moment().format()));

  /**
   * on press prev
   * @param event 
   */
  const onPressPrev = (event: any) => {
    event.stopPropagation();

    // Calculate the start and end dates for the previous week
    const prevWeek = moment(data.start_date).subtract(1, 'week');

    // Update parameters with the new start and end dates and reset page to 1
    const _params = {
      ...data,
      start_date: prevWeek.startOf('week').add(1, 'd').format('YYYY-MM-DD'),
      end_date: prevWeek.endOf('week').add(1, 'd').format('YYYY-MM-DD'),
      page: 1,
    };

    // Dispatch action to update the time schedule parameters
    dispatch(timeScheduleActions.setListTimeScheduleParams(_params));
  };

  /**
   * On press next
   * @param event 
   */
  const onPressNext = (event: any) => {
    event.stopPropagation();

    // Calculate the start and end dates for the next week
    const nextWeek = moment(data.start_date).add(1, 'week');

    // Update parameters with the new start and end dates and reset page to 1
    const _params = {
      ...data,
      start_date: nextWeek.startOf('week').add(1, 'd').format('YYYY-MM-DD'),
      end_date: nextWeek.endOf('week').add(1, 'd').format('YYYY-MM-DD'),
      page: 1,
    };

    // Dispatch action to update the time schedule parameters
    dispatch(timeScheduleActions.setListTimeScheduleParams(_params));
  };

  /**
   * handle date picker change
   * @param day 
   * @returns 
   */
  const handleDatePickerChange = (day: any) => {
    // Update the state with the selected day
    setDateState(day);

    // Convert the selected day to the desired format
    const dayConvert = day.format('YYYY-MM-DD');

    // Check if the selected day is within the current week range
    if (
      moment(dayConvert).isSameOrAfter(moment(data?.start_date)) &&
      moment(dayConvert).isSameOrBefore(moment(data?.end_date))
    ) {
      // If it is within the current week, return without further action
      return;
    }

    // Calculate the start and end dates of the week containing the selected day
    const startWeek = moment(dayConvert)
      .subtract(1, 'd')
      .startOf('week')
      .add(1, 'd')
      .format('YYYY-MM-DD');
    const endWeek = moment(dayConvert)
      .subtract(1, 'd')
      .endOf('week')
      .add(1, 'd')
      .format('YYYY-MM-DD');

    // Update parameters with the new start and end dates and reset page to 1
    const _params = {
      ...data,
      start_date: startWeek,
      end_date: endWeek,
      page: 1,
    };

    // Dispatch action to update the time schedule parameters
    dispatch(timeScheduleActions.setListTimeScheduleParams(_params));
  };

  return (
    <DateViewPickerStyled>
      <Space direction='horizontal'>
        <ConfigProvider locale={locale}>
          <DatePicker
            className='is-normal'
            allowClear={false}
            open={open}
            onOpenChange={setOpen}
            suffixIcon={false}
            value={dateState}
            inputRender={(props: any) => (
              <InputPickerStyled>
                <button onClick={onPressPrev}>
                  <IconPrev />
                </button>
                <RangePickerStyled {...props}>
                  <p>
                    {moment(data.start_date).format('DD.MM')} -{' '}
                    {moment(data.end_date).format('DD.MM.YYYY')}
                  </p>
                </RangePickerStyled>
                <button onClick={onPressNext}>
                  <IconNext />
                </button>
              </InputPickerStyled>
            )}
            onChange={(_dayjs) => {
              if (!_dayjs) return;
              handleDatePickerChange(_dayjs);
            }}
          />
        </ConfigProvider>
      </Space>
    </DateViewPickerStyled>
  );
}

export default TimeScheduleDateView;

const IconPrev = () => {
  return (
    <svg
      width='25'
      height='24'
      viewBox='0 0 25 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M15.3101 18L9.31006 12L15.3101 6'
        stroke='#363565'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

const IconNext = () => {
  return (
    <svg
      width='25'
      height='24'
      viewBox='0 0 25 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M9.31006 18L15.3101 12L9.31006 6'
        stroke='#363565'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
const InputPickerStyled = styled.div`
  display: flex;
  align-items: center;
  button {
    width: 50px;
  }
`;
const RangePickerStyled = styled.div`
  &:hover {
    cursor: pointer;
  }
`;

const DateViewPickerStyled = styled.div`
  border-radius: 8px;
  border: 1px solid #D9D9D9;
  .ant-input,
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector,
  .ant-input-number .ant-input-number-input,
  .ant-select-single .ant-select-selector,
  .ant-picker {
    padding: 0;
  }

  
`;
