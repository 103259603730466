import { Form } from 'antd';
import ServicesSelection from 'features/users/components/ServicesSelection';
import { useTranslation } from 'react-i18next';

type Props = {
  selected: string[];
  setSelected: (val: string[]) => void;
  onSubmit?: () => void
  isManager?: boolean
};

const UserFormSecondStep = ({ selected, setSelected, isManager }: Props) => {
  const {t: userLang} = useTranslation('user');

  
  return (
    <>
      <div className='round'>
        <div className="form-row">
          <p className="form-label">{userLang('AssignedServices')}</p>
        </div>
        <ServicesSelection selected={selected} setSelected={setSelected} disabledSelect={isManager}/>
        <Form.Item noStyle name={'services'} />
      </div>
    </>
  );
};

export default UserFormSecondStep;
