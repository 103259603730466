import { ConfigProvider, DatePicker, Form, Input } from 'antd';
import moment from 'moment';
import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import dayjs, { Dayjs } from 'dayjs';
import { disabledDateBefore } from 'utils/unit';
import { IPromotionFormValue } from '../types/promotion';
import styled from 'styled-components';
import 'dayjs/locale/vi';
import viVN from 'antd/lib/locale/vi_VN';
import enEL from 'antd/lib/locale/en_US';

type Props = {
  errorsText?: Record<string, string>;
  isUsed?: boolean;
  formData?: IPromotionFormValue | null;
};
const PromotionsFormFirstStep = ({ errorsText, isUsed = false, formData }: Props) => {
  const { t: formLang } = useTranslation('form');
  const { t: promotionLang } = useTranslation('promotions');
  const { t: bookingLang } = useTranslation('booking');

  const form = Form.useFormInstance();

  const myFocus = useRef<any>();

  useEffect(() => {
    form.setFieldsValue({
      startDate: dayjs(),
      ['promotion-start-date']: formData?.startDate
    });
  }, [formData]);

  const lang = 'vi';
  dayjs.locale(lang === 'vi' ? 'vi' : 'en');
  const locale = lang === 'vi' ? viVN : enEL;

  useEffect(() => {
    myFocus?.current?.input?.focus();
  }, []);

  return (
    <ConfigProvider locale={locale}>
      <div className='form-row'>
        <Form.Item
          label={formLang('promotion.promotionName')}
          name='name'
          validateStatus={errorsText?.name ? 'error' : undefined}
          help={errorsText?.name ? errorsText?.name : undefined}
          rules={[{ required: true, message: promotionLang('PleaseEnterPromotionName') || '' }]}
        >
          <Input
            ref={myFocus}
            placeholder={formLang('EnterPromotionNamePlaceholder') || ''}
          />
        </Form.Item>
      </div>

      <Form.Item
        dependencies={['promotionType']} // if using dependencies
        noStyle
      >
        {({ getFieldValue }: any) => {
          // getFieldValue defined here
          return getFieldValue('promotionType') === 'code' ? (
            <div className='form-row'>
              <Form.Item
                label={formLang('promotion.promotionCode')}
                name='code'

                validateStatus={errorsText?.code ? 'error' : undefined}
                help={errorsText?.code ? errorsText?.code : undefined}
                rules={[
                  { required: true, message: promotionLang('PleaseEnterPromotionCode') || '' },
                  {
                    validator: (_, value) => {
                      if (value && value.length !== 8) {
                        return Promise.reject(
                          promotionLang('PleaseEnterAStringWithALengthOf8Characters')
                        );
                      }
                      return Promise.resolve();
                    },
                  },
                ]}
              >
                <Input
                  disabled={isUsed}
                  placeholder={promotionLang('EnterPromotionCodePlaceholder') || ''}
                />
              </Form.Item>
            </div>
          ) : null;
        }}
      </Form.Item>

      <Form.Item
        dependencies={['promotionType']}
        noStyle
      >
        {({ getFieldValue }: any) => {
          // getFieldValue defined here
          return getFieldValue('promotionType') === 'auto_offer' ? (
            <StartEndDateStyles>
              <div className='form-row'>
                <Form.Item
                  label={formLang('promotion.startDate')}
                  name='startDate'
                  rules={[
                    { required: true, message: promotionLang('PleaseSelectStartDate') || '' },
                    {
                      validator: (_, value) => {
                        const currentDate = moment();

                        if (value && value.isBefore(currentDate, 'day')) {
                          return Promise.reject(
                            promotionLang('StartDateMustBeGreaterThanOrEqualToTheCurrentDate') || ''
                          );
                        }
                        return Promise.resolve();
                      },
                    },
                  ]}
                >
                    <DatePicker
                      disabledDate={(current: Dayjs) =>
                        disabledDateBefore(current, dayjs())
                      }
                      format={'DD.MM.YYYY'}
                      picker='date'
                      placeholder={bookingLang('SelectDate') || ''}
                      className='promotion-start-date'
                    />
                </Form.Item>
                <Form.Item
                  label={
                    <p>
                      {formLang('promotion.endDate')}
                      <span>({promotionLang('optional')})</span>
                    </p>
                  }
                  name='endDate'
                  rules={[
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        const startDate = getFieldValue('startDate');

                        if (value && startDate && value.isBefore(startDate)) {
                          return Promise.reject(
                            promotionLang('EndDateMustBeGreaterThanOrEqualToTheStartDate')
                          );
                        }
                        return Promise.resolve();
                      },
                    }),
                  ]}
                >
                    <DatePicker
                      disabledDate={(current: Dayjs) =>
                        disabledDateBefore(current, dayjs())
                      }
                      format={'DD.MM.YYYY'}
                      placeholder={bookingLang('SelectDate') || ''}
                      picker='date'
                      className='promotion-end-date'
                    />
                </Form.Item>
              </div>
            </StartEndDateStyles>
          ) : null;
        }}
      </Form.Item>
    </ConfigProvider>
  );
};

export default PromotionsFormFirstStep;

const StartEndDateStyles = styled.div`
.form-row {
  @media only screen and (max-width: 767.98px) {
    display: grid;
  }
}

`;
