import { translate } from 'utils/moment/getSingleDateFormat';

export const NAME_REDUCER = 'promotion';

export const PREFIX_ACTIONS = 'PROMOTION_FEATURE_';

export const PATH_LOADING = {
    getListPromotions: `loading.${NAME_REDUCER}.getListPromotions`,
    promotionActions: `loading.${NAME_REDUCER}.promotionActions`,
    getDetailPromotion: `loading.${NAME_REDUCER}.getDetailPromotion`,
    generatePromotionCode: `loading.${NAME_REDUCER}.generatePromotionCode`,
};

export const PROMOTION_TYPES = {
    PRICE: 'price',
    PERCENT: 'percent'
};

const getEngageOffer = (opt: '2_month' | '3_month' | '6_month') => {
  return translate('common', `option_promotion.${opt}`);
};

export const RE_ENGAGE_OFFER = [
    {
      value: 2,
      label: getEngageOffer('2_month'),
    },
    {
      value: 3,
      label: getEngageOffer('3_month'),
    },
    {
      value: 6,
      label: getEngageOffer('6_month'),
    },
  ];