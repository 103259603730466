import { Modal } from 'antd';
import { useEffect, useState } from 'react';
import { useAppDispatch } from 'store/hooks';
import BookingFilter from '../components/BookingFilter';
import BookingLayout from '../components/BookingLayout';
import ModalBookingsContent from '../components/ModalBookingsContent';
import bookingActions from '../services/actions';
import bookingSelectors from '../services/selectors';

const BookingsPage = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(bookingActions.getBookingFirstInit());
  }, []);
  
  const statusOptions = bookingSelectors.getLstBookingStatus();
  const employeeOption = bookingSelectors.getTeamMembers();
  const status = bookingSelectors.getCalendarParamValue('book_status');
  const employee_id = bookingSelectors.getCalendarParamValue('employee_id');
  const listBooking = bookingSelectors.getBookings();
  const location_id = bookingSelectors.getCalendarParamValue('merchant_location_id');

  useEffect(() => {
    if( !employee_id || !status || !employeeOption || (listBooking && listBooking?.length > 0) ) return;

    if((employeeOption?.length !== 0 || (employee_id as any[])?.length !== 0) && ( statusOptions?.length > 0 || ( status as any[])?.length !== 0)) {
      
      const employee_filter = (employee_id as (string | number)[]);
      const status_filter = ( status as any[]);
      const status_applied = status_filter?.length > 0 ? status_filter : statusOptions.map(o => o.value);
      // dispatch(bookingActions.getParamOptionCalendar.fetch());
      dispatch(bookingActions.setCalendarBookingParams({
        employee_id: employee_filter?.length > 0 ? employee_filter : employeeOption.filter(o => !!o.is_receive_book && o.merchant_location_id === location_id).map(o => o.id),
        book_status: status_applied
      }));
      dispatch(bookingActions.setListTableBookingParams({
        book_status: status_applied
      }));
    }

  }, [employee_id, status, statusOptions, employeeOption]);

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  
  return (
    <>
      <BookingFilter />
      <BookingLayout />
      <Modal
        maskClosable={false}
        open={isModalOpen}
        onCancel={handleCancel}
        width={'100%'}
        footer={null}
        className='modal-big'
        forceRender
        centered
      >
        <ModalBookingsContent handleCancel={handleCancel} />
      </Modal>
    </>
  );
};

export default BookingsPage;
