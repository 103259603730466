import React, { useState } from 'react';
import styled from 'styled-components';
import LocationPicker from './Location';
import TeamMemberPicker from './TeamMember';
import TypeViewPicker from './TypeView';
import DateRangePicker from './DateRange';
import { useDispatch } from 'react-redux';
import workingHourActions from 'features/workingHour/services/actions';
import { first, last } from 'lodash';
import { RangePickerProps } from 'antd/es/date-picker';
import { EDateOption } from 'features/workingHour/services/types/workingHour';

const WorkingHourDetailFilter = () => {

  const dispatch = useDispatch();
  const [valueTypeView, setValueTypeView] = useState<string>();

  const onChangeDateRange: RangePickerProps['onChange'] = (values) => {
    const startDate = first(values);
    const endDate = last(values);
    dispatch(workingHourActions.setParams({
      start_date: startDate?.unix(),
      end_date: endDate?.unix(),
      date: undefined
    }));
    setValueTypeView(EDateOption.CUSTOM);
  };

  return (
    <WorkingHourFilterStyled>
      <div className='locate-type-view'>
        <LocationPicker />
        <TypeViewPicker value={valueTypeView} />
      </div>
      <div className='team-member-date-range'>
        <TeamMemberPicker />
        <DateRangePicker onChangeDateRange={onChangeDateRange} />
      </div>
    </WorkingHourFilterStyled>
  );
};

export default WorkingHourDetailFilter;

const WorkingHourFilterStyled = styled.div`
  display:flex;
  align-items: center;
  justify-content: flex-end;
  gap:8px;
  margin-bottom: 8px;
  flex-wrap: wrap;
  .locate-type-view {
    display: flex;
    gap: 8px;
  }
  .team-member-date-range {
    display: flex;
    gap: 8px;
    @media only screen and (max-width: 767.98px) {
      display: grid;
      width: 100%;
    }
  }
`;
