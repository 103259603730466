import { Divider } from 'antd';
import { ReactNode } from 'react';
import styled from 'styled-components';


/**
 * Handle setting card
 * @param children, header
 * @returns 
 */
const SettingCard = ({children, header}: {children: ReactNode, header: ReactNode}) => {
  return (
    <SettingCardStyled>
      {header}
      <Divider />
      <div className="card-body">
        {children}
      </div>

    </SettingCardStyled>
  );
};

export default SettingCard;

const SettingCardStyled = styled.div`
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.06), 0px 1px 3px 0px rgba(0, 0, 0, 0.10);
  background-color: #ffffff;
  border-radius: 16px;
  padding: 24px;
  height: 100%;

  .card-body {
    display: flex;
    justify-content: center;
  }
`;