import { Tag } from 'antd';
import topUpSetting from 'assets/images/setting/top_up_setting.png';
import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import TopUpModal from './TopupModal';
import { useTranslation } from 'react-i18next';
import {
  StripeManualReq,
  TopUpStatus,
  TopUpType,
} from 'features/settings/services/types/topUp';
import settingApis from 'features/settings/services/apis';
import { useDispatch } from 'react-redux';
import settingActions from 'features/settings/services/actions';
import settingSelectors from 'features/settings/services/selectors';
import { Stripe, loadStripe } from '@stripe/stripe-js';
import { STRIPE_PUBLIC_KEY } from 'constants/stripe';
import { useSetLoadingPage } from 'services/UI/LoadingPage';

export interface ITopUpChargeForm {
  topUp: number;
  autoChargeWhen?: number;
  notifyYourWhen?: number;
  cardHolderFullName: string;
  cardNumber: number;
  expiryDate: string;
  cvv: number;
  abnNumber: number;
}

export enum CancelType {
  CLOSE = 'CLOSE',
  BACK = 'BACK',
}

interface SetupTopUpCardProps {
  confirmTopUpOpen?: boolean;
  handleConfirmTopupClose?: () => void;
}

const SetupTopUpCard = ({
  confirmTopUpOpen,
  handleConfirmTopupClose,
}: SetupTopUpCardProps) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [progressNumber, setProgressNumber] = useState<number>(0);
  const [topUpType, setTopUpType] = useState<TopUpType | ''>('');
  const [stripe, setStripe] = useState<Stripe | null>(null);
  const dispatch = useDispatch();
  const { t: topUpLang } = useTranslation('top_up');
  const loadingGetMerchantBalance = settingSelectors.loadMerchantBalance();
  const topupInitData = settingSelectors.getTopUpInitData();
  const paymentFormRef = useRef<any>();
  const setPageLoading = useSetLoadingPage();

  useEffect(() => {
    (async () => {
      if (STRIPE_PUBLIC_KEY) {
        const stripeKey = await loadStripe(STRIPE_PUBLIC_KEY);
        setStripe(stripeKey);
      }
    })();
  }, []);

  useEffect(() => {
    if (confirmTopUpOpen && !isModalOpen) {
      setIsModalOpen(true);
      handleConfirmTopupClose?.();
    }
  }, [confirmTopUpOpen]);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancelWithStep = (type: CancelType) => {
    if (type === CancelType.CLOSE) {
      setTopUpType('');
      setProgressNumber(0);
      setIsModalOpen(false);
    }

    if (type === CancelType.BACK) {
      setProgressNumber(progressNumber - 1);
    }
  };

  const handleChangePopUpType = (type: TopUpType) => {
    setTopUpType(type);
  };

  const handlePaymentStep = () => {
    if (topUpType === TopUpType.MANUALLY) {
      dispatch(settingActions.getMerchantStripeBalance.fetch());
    }
    setProgressNumber(1);
  };

  const handleSubmit = async (values: ITopUpChargeForm) => {
    setPageLoading(true);

    try {
      if (!stripe) {
        setProgressNumber(2);
        return TopUpStatus.FAILED;
      }

      const cardNumberElement = paymentFormRef.current.submitStripeAndElement();
      const { token, error } = await stripe.createToken(cardNumberElement, {
        name: values?.cardHolderFullName ?? '',
      });

      if (error || !token) {
        setProgressNumber(2);
        return TopUpStatus.FAILED;
      }

      const postTopUpData: StripeManualReq = {
        payment_method: 'Stripe',
        stripeToken: token?.id,
        amount: values?.topUp,
      };

      if (topUpType === TopUpType.AUTOMATIC) {
        postTopUpData.auto_charge_amount = values?.autoChargeWhen;
        postTopUpData.type = 'auto';
      }

      const response = await settingApis.postTopUpCharge(postTopUpData);

      if (response?.data?.code === 1000) {
        const res = await settingApis.putSwitchAutoTopup(
          topUpType === TopUpType.AUTOMATIC ? true : false
        );

        if (res?.data?.code !== 1000) {
          setProgressNumber(2);
          return TopUpStatus.FAILED;
        }

        setProgressNumber(2);
        return TopUpStatus.SUCCESS;
      }

      setProgressNumber(2);
      return TopUpStatus.FAILED;
    } catch (error: any) {
      setProgressNumber(2);
      return TopUpStatus.FAILED;
    } finally {
      setPageLoading(false);
    }
  };

  const {t: settingLang} = useTranslation('setting');

  return (
    <SetupTopUpCardStyled>
      <div className='cards-wrapper'>
        <div className='cards-col'>
          <div className='common-card'>
            <div className='d-flex no-wrap'>
              <div className='top-up-left'>
                <p className='setup-topup-title'>
                  {topUpLang('topUpCard.title')}
                </p>
                <p className='setup-topup-desc'>
                  {settingLang('UseAutomaticTopUpsToMakeSureYourTextsAlwaysKeepSending')}
                </p>
                <div>
                  <Tag color='green' className='top-up-money'>
                    {topupInitData?.fee_sms}đ {settingLang('PerTextMessage')}
                  </Tag>
                </div>

                <button
                  className='common-btn is-big no-flex'
                  onClick={showModal}
                >
                  {settingLang('TopUp')}
                </button>
              </div>
              <div className='top-up-right'>
                <img src={topUpSetting} alt='topup-setting' />
              </div>
            </div>
          </div>
        </div>
      </div>

      {isModalOpen && (
        <TopUpModal
          isModalOpen={isModalOpen}
          handleCancelWithStep={handleCancelWithStep}
          handleChangePopUpType={handleChangePopUpType}
          topUpType={topUpType}
          progressNumber={progressNumber}
          handleSubmit={handleSubmit}
          handlePaymentStep={handlePaymentStep}
          loading={loadingGetMerchantBalance}
          stripe={stripe}
          ref={paymentFormRef}
        />
      )}
    </SetupTopUpCardStyled>
  );
};

const SetupTopUpCardStyled = styled.div`
  .setup-topup-title {
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%;
  }

  .setup-topup-desc {
    margin-top: 24px;
    margin-bottom: 24px;
  }

  .top-up-money {
    border: none;
    margin-bottom: 24px;
  }

  .d-flex {
    gap: 40px;
    justify-content: flex-start;
    .top-up-left {
      width: 70%;
    }

    .top-up-right {
      width: 30%;
    }
  }
`;

export default SetupTopUpCard;
