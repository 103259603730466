const IconInvoice = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path opacity="0.4" d="M3 6.0127C3 3.80356 4.79086 2.0127 7 2.0127H17C19.2091 2.0127 21 3.80356 21 6.0127V18.0127C21 20.2218 19.2091 22.0127 17 22.0127H7C4.79086 22.0127 3 20.2218 3 18.0127V6.0127Z" fill="#595959"/>
        <path d="M17.75 7.0127C17.75 7.42691 17.4142 7.7627 17 7.7627L7 7.76269C6.58579 7.76269 6.25 7.42691 6.25 7.01269C6.25 6.59848 6.58579 6.26269 7 6.26269L17 6.2627C17.4142 6.2627 17.75 6.59848 17.75 7.0127Z" fill="#595959"/>
        <path d="M17.75 12.0127C17.75 12.4269 17.4142 12.7627 17 12.7627L7 12.7627C6.58579 12.7627 6.25 12.4269 6.25 12.0127C6.25 11.5985 6.58579 11.2627 7 11.2627L17 11.2627C17.4142 11.2627 17.75 11.5985 17.75 12.0127Z" fill="#595959"/>
        <path d="M12.75 17.0127C12.75 17.4269 12.4142 17.7627 12 17.7627L7 17.7627C6.58579 17.7627 6.25 17.4269 6.25 17.0127C6.25 16.5985 6.58579 16.2627 7 16.2627L12 16.2627C12.4142 16.2627 12.75 16.5985 12.75 17.0127Z" fill="#595959"/>
        </svg>
    );
  };
  
  export default IconInvoice;
  