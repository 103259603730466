import { IconRemove } from 'features/checkout/components/FeeButton';
import checkoutActions from 'features/checkout/services/actions';
import checkoutSelectors from 'features/checkout/services/selectors';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from 'store/hooks';
import { formatMoney } from 'utils/unit';

/**
 * Cash payment result row
 * @param viewOnly 
 * @returns 
 */
const CashPaymentResultRow = ({viewOnly}: {viewOnly: boolean}) => {
  const cash = checkoutSelectors.getPurchaseCurrentCash() ?? 0;
  const voucherValue = checkoutSelectors.getVoucherValue();
  const total = checkoutSelectors.getOriginTotalPrice();
  const {t: bookingLang} = useTranslation('booking');

  const amount = total - voucherValue;


    const dispatch = useAppDispatch();
  
  
    /**
     * On remove
     */
  const onRemove = () => {
    dispatch(checkoutActions.setPurchaseCash(0));
  };

  if (!cash) return null;

  return (
    <div>
      {cash <= 0 ? <></> :
        cash && <div className='extra-price-row'>
          <div className='label'>
            {!viewOnly &&
            <button type='button' onClick={onRemove}><IconRemove /></button>}
            {bookingLang('Cash')}
          </div>

          <span> {formatMoney(cash > amount ? amount : cash)}</span>
        </div>
      }
  </div>
  );
};

export default CashPaymentResultRow;