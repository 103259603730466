import { Button, message } from 'antd';
import IconCheckOverview from 'assets/svg/IconCheckOverview';
import IconCopy from 'assets/svg/IconCopy';
import dayjs from 'dayjs';
import checkoutSelectors from 'features/checkout/services/selectors';
import moment from 'moment';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { translate } from 'utils/moment/getSingleDateFormat';
import { getFormatShortDate } from 'utils/moment/getSingleDateShortFormat';
import storage from 'utils/sessionStorage';
import { CUSTOMER_WALKIN_INDEX } from 'utils/unit';

/**
 * Customer info step success
 * @returns 
 */
const CustomerInfoStepSuccess = () => {
  const customerInfo = checkoutSelectors.getCustomerInfo();
  const detail = checkoutSelectors.getBookingDetail();
  const resultTotal = checkoutSelectors.getResultTotal();
  const bookingDate = useMemo(() => moment(detail?.time_start), [detail]);
  const { t: bookingLang } = useTranslation('booking');
  const { t: dateLang } = useTranslation('date');

  /**
   * Copy text to clipboard
   * @param text 
   * @returns 
   */
  const copyTextToClipboard = async (text: string) => {
    if ('clipboard' in navigator) {
      return await navigator.clipboard.writeText(text);
    } else {
      return await document.execCommand('copy', true, text);
    }
  };

  /**
   * Handle copy
   * @param text 
   */
  const handleCopy = async (text: string) => {

    try {

      await copyTextToClipboard(text);
      message.success(translate('common', 'message.Copied'));
    } catch (error) { }
  };

  return (
    <CustomerInfoStepSuccessStyled>
      <div className='complete-order'>
        <div className='complete-icon'>
          <IconCheckOverview />
          <p className='complete-title'>{bookingLang('SaleOverview')}</p>
        </div>
        <div className='complete-content'>
          {/* <p className='complete-title'>{bookingLang('SaleOverview')}</p>
          <p className='complete-text'>
            {bookingLang('PaymentReceivedOn')} <span>{bookingLang('Today')}, {dayjs(new Date()).format('YYYY.MM.DD')}</span> {bookingLang('at')}{' '}
            <span>{storage.merchantName.get()}</span> {bookingLang('with')} <span className='customer-name'> {customerInfo?.name} </span>
            <span></span>.
          </p> */}
          <div className='invoice_id_container'>
            <b className='invoice_id'>Hoá đơn {resultTotal?.invoice_no}</b>
            <Button
              onClick={() => {
                if (resultTotal?.invoice_no) {
                  handleCopy(resultTotal.invoice_no);
                }
              }}
              icon={<IconCopy />}
            />
          </div>
          <div className='detail_invoice_item'>
            <p className='detail_invoice_left'>Ngày thanh toán</p> <p className='detail_invoice_right'>{dayjs(new Date()).format('DD/MM/YYYY')}</p>
          </div>
          <div className='detail_invoice_item'>
            <p className='detail_invoice_left'>Ngày hẹn</p> <p className='detail_invoice_right'>{getFormatShortDate(moment(bookingDate).format(), dateLang)}</p>
          </div>
          <div className='detail_invoice_item'>
            <p className='detail_invoice_left'>Địa điểm</p> <p className='detail_invoice_right'>{storage.merchantName.get()}</p>
          </div>
          <div className='detail_invoice_item'>
            <p className='detail_invoice_left'>Khách hàng</p> <p className='detail_invoice_right'>{Number(customerInfo?.is_walkin_in) !== CUSTOMER_WALKIN_INDEX ? customerInfo?.name : translate('common', 'WalkInCustomer')}</p>
          </div>
        </div>
      </div>
    </CustomerInfoStepSuccessStyled>
  );
};

export default CustomerInfoStepSuccess;

const CustomerInfoStepSuccessStyled = styled.div`
.complete-order {
    display: grid !important;
    .complete-icon {
      display: grid;
      justify-content: center;
      align-items: center;
      justify-items: center;
      gap: 12px;
      .complete-title {
        color: #1BB250;
        text-align: center;
        font-family: Inter;
        font-size: 24px;
        font-weight: 600;
        line-height: 140%;
      }
    }

    .complete-content {
      border-top: 1px solid #F0F0F0;
      padding-top: 24px;
      display: grid;
      align-items: center;
      gap: 24px;
      .invoice_id_container {
        display: flex;
        gap: 8px;
        align-items: center;
        .invoice_id {
          color: #141414;
          font-family: Inter;
          font-size: 18px;
          font-weight: 600;
          line-height: 140%;
        }
        .ant-btn-default {
          border: 1px solid transparent;
          border-radius: 4px;
        }
      }
      .detail_invoice_item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .detail_invoice_left {
          color: #141414;
          font-family: Inter;
          font-size: 16px;
          font-weight: 400;
          line-height: 140%;
        }
        .detail_invoice_right {
          color: #141414;
          text-align: right;
          font-family: Inter;
          font-size: 16px;
          font-weight: 600;
          line-height: 140%;
        }
      }
    }
  }
`;
