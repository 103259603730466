import { Empty, Table } from 'antd';
import analyticSelectors from '../services/selectors';
import { useTranslation } from 'react-i18next';

/**
 * Working table
 * @returns 
 */
const WorkingTable = () => {

  const workingHour = analyticSelectors.getWorkingHours();
  const { t: analyticLang } = useTranslation('analytic');
  const { t: userLang } = useTranslation('user');
  const { t: invoiceLang } = useTranslation('invoice');
  const dataSource = workingHour;

  /**
   * Columns table
   */
  const columns = [
    {
      title: analyticLang('Staff'),
      dataIndex: 'full_name',
      key: 'full_name',
      width: '35%',
    },
    {
      title: analyticLang('Position'),
      dataIndex: 'access_level',
      key: 'access_level',
      width: '35%',
      render: (text: string) => {
        return (
          <p>
            {userLang(text)}
          </p>
        );
      },
    },
    {
      title: (
        <p>
          {analyticLang('TotalHours')} <span className='optional-text'>({analyticLang('hrs')})</span>
        </p>
      ),
      dataIndex: 'total_hours',
      key: 'total_hours',
    },
  ];

  return (
    <>
      <Table
        className={'custom-table'}
        dataSource={dataSource}
        columns={columns}
        // scroll={{
        //   x: 900,
        // }}
        pagination={false}
        locale={{
          emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={invoiceLang('NoData')} />,
        }}
      />
    </>
  );
};

export default WorkingTable;
