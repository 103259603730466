import MainLogo from 'assets/icons/main_logo.svg';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import '../../styles/Auth.scss';
import SignUp from 'features/auth/components/SignUp';

import SignUpSuccess from 'features/auth/components/SignUpSuccess';

/**
 * Sign up page
 * @returns 
 */
const SignUpPage = () => {
    const { t: signInLang } = useTranslation('sign_in');

    const [userName] = useState('Abdullah');
    const [email, setEmail] = useState('');
    const [isShowInputPass, setIsShowInputPass] = useState(false);
    const [isShowForgotPass, setIsShowForgotPass] = useState(false);
    const [isShowBtnBack, setIsShowBtnBack] = useState(false);
    const [, setErrorText] = useState('');
    const [isRegisterSuccess, setIsRegisterSuccess] = useState(false);

    // =============== Change language ====================
    const { t, i18n } = useTranslation();

    /**
     * Change language
     * @param languageValue 
     */
    const changeLanguage = (languageValue: any) => {
        i18n.changeLanguage(languageValue);
        document.title = t('MindHana');
        localStorage.setItem('selectedLanguage', languageValue);
    };

    /**
     * Handle language change
     * @param e 
     */
    const handleLanguageChange = (e: any) => {
        const languageValue = e.target.value;
        changeLanguage(languageValue);
    };

    useEffect(() => {
        const storedLanguage = localStorage.getItem('selectedLanguage');
        const defaultLanguage = 'vi';
        const selectedLanguage = storedLanguage || defaultLanguage;

        if (selectedLanguage !== i18n.language) {
            changeLanguage(selectedLanguage);
        }

        const handleBeforeUnload = () => {
            localStorage.setItem('selectedLanguage', i18n.language);
        };

        const handlePageVisibilityChange = () => {
            if (document.visibilityState === 'hidden') {
                localStorage.setItem('selectedLanguage', i18n.language);
            }
        };

        window.addEventListener('beforeunload', handleBeforeUnload);
        document.addEventListener('visibilitychange', handlePageVisibilityChange);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
            document.removeEventListener('visibilitychange', handlePageVisibilityChange);
        };
    }, [i18n.language]);
    // ========================================

    /**
     * Handle btn back click
     */
    const handleBtnBackClick = () => {
        if (isShowForgotPass) {
            setIsShowForgotPass(false);
        } else {
            setErrorText('');
            setEmail('');
            setIsShowInputPass(false);
            setIsShowBtnBack(false);
        }
    };

    /**
     * Handle btn click resgister
     * @param email 
     */
    const handleBtnClickResgister = (email: string) => {
        setEmail(email);
        setIsRegisterSuccess(true);
    };

    /**
     * Click login
     */
    const clickLogin = () => {
        window.location.href = '/sign-in';
    };


    const [ isStep, setIsStep ] = useState<number>(1);
    return (
        <section className='auth-layout'>
            <div className='layout-right'>
                <div className='auth-content'>
                    <div className='content-inner'>
                        {isShowBtnBack && (
                            <span onClick={handleBtnBackClick} className='btn-back'></span>
                        )}
                        <div className='auth-block'>
                            <>
                                <div className='auth-top'>
                                    <div className='auth-logo'>
                                        <img src={MainLogo} alt='Natural Nails and Beauty'></img>
                                    </div>
                                    <select className='auth_select_language' value={i18n.language} onChange={handleLanguageChange}>
                                        <option value="en">EN</option>
                                        <option value="vi">VN</option>
                                    </select>
                                </div>
                                {!isRegisterSuccess ?
                                    <>
                                        <div>
                                            <h1 className='auth-title'>
                                                {!isShowInputPass
                                                    ? signInLang('firstStep.title')
                                                    : signInLang('secondStep.title') + userName + '!'}
                                            </h1>
                                            <p className='auth-text'>
                                                {!isShowInputPass
                                                    ? signInLang('firstStep.text')
                                                    : signInLang('secondStep.text')}
                                            </p>
                                        </div>
                                        <SignUp
                                            ToRegiterSuccess={handleBtnClickResgister}
                                            ToLogin={clickLogin}
                                            setIsStep={setIsStep}
                                            isStep={isStep} />
                                    </>
                                    :
                                    <SignUpSuccess
                                        ToLogin={clickLogin}
                                        email={email} />
                                }
                            </>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default SignUpPage;
