export const NAME_REDUCER = 'Staff';

export const PREFIX_ACTIONS = 'STAFF_FEATURE_';

export const PATH_LOADING = {
  getListPaginateStaffs: `loading.${NAME_REDUCER}.getListPaginateStaffs`,
  getShowCreateStaffs: `loading.${NAME_REDUCER}.getShowCreateStaffs`,
  getProfileStaffDetail: `loading.${NAME_REDUCER}.getProfileStaffDetail`,
  getStaffPublic: `loading.${NAME_REDUCER}.getStaffPublic`,
  getLocationDetail: `loading.${NAME_REDUCER}.getLocationDetail`
};