import { Checkbox, Modal } from 'antd';
import { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

type IconType = 'delete';

type IConfigs = { title: string, content: string, checkboxContent: string, submit: any, icon?: JSX.Element | null | IconType };
export type ModalConfirmRef = {
  show: (configs: IConfigs) => void
  hide: () => void;
}
type Props = {
  titleClose?: string;
  showClose?: boolean;
  titleConfirm?: string;
};
/* eslint-disable-next-line */
const ModalDeleteDayOff = forwardRef<ModalConfirmRef, Props>(({ titleClose = '', titleConfirm = '', showClose = true }, ref) => {

  const [visible, setVisible] = useState(false);
  const memoRef = useRef<IConfigs>({
    title: '', content: '', submit: () => undefined, checkboxContent: '',
    icon: null,
  });
  const [checkboxDeleteValue, setCheckboxDeleteValue] = useState<boolean>(false);

  const hide: ModalConfirmRef['hide'] = () => {
    setVisible(false);
    setCheckboxDeleteValue(false);
  };

  const show: ModalConfirmRef['show'] = (configs) => {
    memoRef.current = {
      ...configs,
    };
    setVisible(true);
  };

  useImperativeHandle(ref, () => ({
    show,
    hide,
  }));

  const { submit = () => undefined, content, title, icon, checkboxContent } = memoRef.current ?? {};

  const onSubmit = () => {
    submit(checkboxDeleteValue);
    hide();
  };

  const Icon = () => {
    if (typeof icon !== 'string') {
      return (
        icon ? <div className='modal-icon'>{icon}</div> : null
      );
    }
    return <></>;
  };

  const {t: modelLang} = useTranslation('model');

  return (
    <>
      <Modal
        maskClosable={false}
        open={visible}
        onCancel={hide}
        okButtonProps={{ style: { display: 'none' } }}
        width={574}
        footer={null}
        centered
        forceRender
        zIndex={1040}
      >
        <div className='modal-delete-confirm'>
          <Icon />
          <h2 className='modal-title'>{title}</h2>
          <ModalDeleteStyled>
            <div className='body-content'>
              {content}
            </div>
            <div className='form-row delete-checkbox'>
              <Checkbox checked={checkboxDeleteValue} onChange={(val) => setCheckboxDeleteValue(val.target.checked)}/>
              <p className='checkbox-content'>{checkboxContent}</p>
            </div>
          </ModalDeleteStyled>
          <div className='form-submit-wrapper'>
            {showClose && <button
              onClick={hide}
              type='button'
              className='common-btn is-white'
            >
              {titleClose || modelLang('Close')}
            </button>}
            <button
              onClick={onSubmit}
              type='button'
              className='common-btn'
            >
              {titleConfirm || modelLang('Confirm')}
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
});

const ModalDeleteStyled = styled.div`
  .body-content {
    color: #363565
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    line-height: 140%;
    margin-top: 12px;
  }

  .delete-checkbox {
    justify-content: center;
    margin-top: 28px;

    .checkbox-content {
      color: #363565;
      font-size: 16px;
      font-weight: 600;
      line-height: 140%;
      text-transform: capitalize;
    }
  }
`;

export default ModalDeleteDayOff;
