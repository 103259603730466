import { Form, Input } from 'antd';
import MultipleSelect, { IOptionMulti } from 'components/common/MultipleSelect';
import checkoutSelectors from 'features/checkout/services/selectors';
import { IVoucherDataItem } from 'features/checkout/services/types/voucher';
import getMappingDataForm from 'features/vouchers_v2/widgets/hooks/getMappingDataForm';
import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
export type VoucherFormViewProps = {
  data?: IVoucherDataItem | null;
  onCancel?: () => void;
  onSubmit?: (data: IVoucherDataItem | null) => void;
  viewOnly?: boolean;
};

/**
 * Voucher form view
 * @param data, onCancel , onSubmit, viewOnly
 * @returns 
 */
const VoucherFormView = ({ data, onCancel = () => undefined, onSubmit = () => undefined, viewOnly }: VoucherFormViewProps) => {
  const [form] = Form.useForm();
  const [selected, setSelected] = React.useState<(string | number)[]>([]);
  const servicesStore = checkoutSelectors.getServices();
  const {t: bookingLang} = useTranslation('booking');
  const services = useMemo(() => {
    const result: IOptionMulti[] = [];
    servicesStore?.forEach((service) => {
      if (service?.service_variants?.length > 0) {
        service?.service_variants.forEach(serviceVariant => {
          result.push({
            value: service?.id + '-' + serviceVariant.id,
            title: service.name + ' - ' + serviceVariant.name,
          });
        });
      } else {
        result.push({
          value: service.id + '',
          title: service.name,
        });
      }
    });
    return result;
  }, [servicesStore]);

  const allServices = useMemo(() => {
    return services.map(o => o.value);
  }, [servicesStore]);

  const {t: dateLang} = useTranslation('date');

  useEffect(() => {
    if (data) {
      const _data = getMappingDataForm(data, dateLang);
      form.setFieldsValue({
        ..._data,
        customerName: data.customer?.name,
      });
      if (_data?.restrictService?.length == 0) {
        form.setFieldValue('restrictService', allServices);
      } else {
        form.setFieldValue('restrictService', _data?.restrictService ?? []);
      }
    }
    else
      form.setFieldsValue(null);
  }, [data]);

  return (
    <VoucherFormStyled>
      <Form
        data-form-testing='form-checkout-voucher'
        className='modal-form'
        autoComplete='off'
        form={form}
        layout='vertical'
      >
        <div className='form-row'>
          <Form.Item label={bookingLang('VoucherCode')} name='voucherCode'>
            <Input disabled />
          </Form.Item>
        </div>
        <div className='form-row'>
          <Form.Item label={bookingLang('VoucherName')} name='voucherName'>
            <Input disabled />
          </Form.Item>
        </div>
        <div className='form-row'>
          <Form.Item label={bookingLang('CustomerName')} name='customerName'>
            <Input disabled />
          </Form.Item>
        </div>
        <div className='form-row'>
          <Form.Item label={bookingLang('Value')} name='value'>
            <Input disabled />
          </Form.Item>
          <Form.Item label={bookingLang('RetailPrice')} name='retailPrice'>
            <Input disabled />
          </Form.Item>
        </div>
        <div className='form-row'>
          <Form.Item label={bookingLang('Redeemed')} name='redeemed'>
            <Input disabled />
          </Form.Item>
        </div>
        <div className='form-row'>
          <Form.Item label={bookingLang('ExpiryDate')} name='expiryDate'>
            <Input disabled />
          </Form.Item>
        </div>
        <div className='form-row'>
          <Form.Item label={bookingLang('Services')} name='restrictService'
          >
            <MultipleSelect
              onChange={setSelected}
              value={selected}
              options={services}
              disabled
              maxTagPlaceholder={bookingLang('Services')||''}
              placeholder={bookingLang('SelectServices')||''}
            />
          </Form.Item>
        </div>
        <div className='form-row'>
          <Form.Item label={bookingLang('Terms&Conditions')} name='terms'
          >
            <Input.TextArea
              placeholder={bookingLang('EnterTerms&ConditionsOfThisVoucher')||''}
              rows={4}
              disabled
            />
          </Form.Item>
        </div>
        <div className='form-submit-wrapper'>
          <div
            className='common-btn is-white'
            onClick={onCancel}
          >
            {bookingLang('Close')}
          </div>
          {!viewOnly && <div
            className='common-btn'
            onClick={() => onSubmit(data || null)}
          >
            {bookingLang('Apply')}
          </div>}
        </div>
      </Form>
    </VoucherFormStyled >
  );
};

export default VoucherFormView;

const VoucherFormStyled = styled.div`
 .ant-input-disabled {
    background-color:#F0F2F7;
    color: #7980BC;
  }
  .ant-input-number-disabled {
    .ant-input-number-input {
      color: #7980BC;
      background-color:#F0F2F7;
    }
  }
  .ant-input-number-group .ant-input-number-group-addon:first-child {
    background-color:#F0F2F7;
    /* color: #7980BC; */
  }
  .ant-select-disabled {
    &.ant-select {
      .ant-select-selector {
        background-color:#F0F2F7;
        color: #7980BC;
      }
    }
  }
  .ant-select-multiple.ant-select-disabled.ant-select{
    .ant-select-selector {
      background:#F0F2F7;
      background-color:#F0F2F7 !important;
      /* color: #7980BC !important; */
    }
  }
  .ant-select-multiple.ant-select-lg .ant-select-selection-item {
    .ant-select-selection-item-content {
      color: #7980BC !important;
    }
  }
  .ant-select-single.ant-select-show-arrow .ant-select-selection-item, .ant-picker .ant-picker-input > input {
    color: #7980BC !important;
  }
`;
