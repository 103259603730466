/**
 * Handle tag color class
 * @param text 
 * @returns 
 */
export const tagColorClass = (text: string) => {
  // eslint-disable-next-line default-case
  switch (text) {
    case 'general':
      return 'is-green';
    case 'active':
      return 'is-green';
    case 'waxing':
      return 'is-red';
    case 'disable':
      return 'is-disabled';
    case 'hand & feet':
      return 'is-purple';
  }
  return 'is-primary';
};

/**
 * Handle generate random str
 * @param length 
 * @returns 
 */
export const generateRandomStr = (length: number) => {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return result;
};

