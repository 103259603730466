import { Empty, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import ModalConfirm, {
  ModalConfirmRef,
} from 'components/common/Modal/ModalConfirm';
import { useRef } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useAppDispatch } from 'store/hooks';
import promotionActions from '../services/actions';
import promotionSelectors from '../services/selectors';
import { IPromotionItemResData } from '../types/promotion';
import EditPromotion from '../widgets/Edit';
import { getFormatSingeDate } from 'utils/moment/getSingleDateShortFormat';
import { useTranslation } from 'react-i18next';
import { formatMoney } from 'utils/unit';

const PromotionsTable = () => {
  const confirmRef = useRef<ModalConfirmRef>(null);
  const lstData = promotionSelectors.getLstPromotions();
  const pagination = promotionSelectors.getLstPromotionPagination();
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();

  const { t: promotionLang } = useTranslation('promotions');

  const loading = promotionSelectors.getLoadingLstPromotions();
  const loadingActions = promotionSelectors.getLoadingPromotionActions();

  const confirm = (id: IPromotionItemResData['id']) => {
    confirmRef.current?.show({
      title: promotionLang('AreYouSure'),
      icon: 'delete',
      msg: promotionLang('AreYouSureRemoveThisPromotion'),
      submit: async () => {
        dispatch(promotionActions.deletePromotion(id.toString()));
      },
    });
  };
  const { t: dateLang } = useTranslation('date');
  const columns: ColumnsType<IPromotionItemResData> = [
    {
      title: promotionLang('PromotionName'),
      dataIndex: 'name',
      key: 'name',
      fixed: 'left',
    },
    {
      title: promotionLang('Code'),
      dataIndex: 'code',
      key: 'code',
      className: 'nowrap',
    },
    // {
    //   title: 'Used',
    //   dataIndex: 'used',
    //   key: 'used',
    // },
    {
      title: promotionLang('Value'),
      dataIndex: 'value_text',
      key: 'value_text',
      className: 'nowrap',
      render: (value) => {
        const isDollar = value.startsWith('$');
        const isPercentage = value.endsWith('%');

        let displayedValue = value;
        if (isDollar) {
          displayedValue = formatMoney(value.slice(1));
        } else if (isPercentage) {
          displayedValue.value;
        }

        return (
          <div>
            {displayedValue}
          </div>
        );
      },
    },
    {
      title: promotionLang('ActiveDates'),
      dataIndex: 'start_date',
      key: 'start_date',
      render: (value, record) => {
        const startDate = getFormatSingeDate(value, dateLang) ?? '';
        const endDate = getFormatSingeDate(record?.end_date, dateLang)
          ? getFormatSingeDate(record?.end_date, dateLang)
          : getFormatSingeDate(value, dateLang)
            ? 'Ongoing'
            : '';
        return <div>{startDate + ' - ' + endDate}</div>;
      },
    },
    {
      title: promotionLang('Status'),
      dataIndex: 'status',
      key: 'status',
      render: (value, record) => (
        <div
          className={`common-status promotion ${record.status ? 'active' : 'deactive'}`}
        >
          {record.status ? promotionLang('Active') : promotionLang('Deactive')}
        </div>
      ),
    },
    {
      title: promotionLang('Actions'),
      dataIndex: 'actions',
      key: 'actions',
      render: (tex, record) => {
        return (
          <ul className={'common-action'}>
            <EditPromotion record={record} />
            {/* <li className='action-send'></li> */}
            <li
              className='action-remove'
              onClick={() => confirm(record.id)}
            ></li>
          </ul>
        );
      },
    },
  ];

  return (
    <>
      <Table
        className={'custom-table'}
        dataSource={lstData}
        columns={columns}
        loading={loading || loadingActions}
        scroll={{
          x: 900,
        }}
        rowKey={(row) => row.id}
        pagination={{
          total: pagination?.total_record,
          pageSize: pagination?.record_per_page,
          defaultCurrent: Number(searchParams.get('page') ?? 1),
          defaultPageSize: Number(searchParams.get('per_page') ?? 10),
          onChange: (pageChange, sizeChange) => {
            const params: any = {
              page: pageChange == 0 ? 1 : pageChange,
              per_page: sizeChange,
            };
            dispatch(
              promotionActions.setListPromotionParams({
                page: params.page,
                per_page: params.per_page,
              })
            );
          },
          showSizeChanger: false,
          // showTotal(total) {
          //   return `Total ${total} items`;
          // },
        }}
        locale={{
          emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={promotionLang('NoPromotion')} />,
        }}
      />
      <ModalConfirm ref={confirmRef} />
    </>
  );
};

export default PromotionsTable;