import { Modal } from 'antd';
import message from 'components/common/Message';
import CardPayment, { ICardType } from 'features/checkout/components/CardPayment';
import useDoRedeem from 'features/checkout/components/PromotionCode/useDoRedeem';
import checkoutActions from 'features/checkout/services/actions';
import apisCheckout from 'features/checkout/services/apis';
import checkoutSelectors from 'features/checkout/services/selectors';
import { IFuncPromotionAccept } from 'features/checkout/services/types/promotion';
import settingSelectors from 'features/settings/services/selectors';
import useIClientTyro, { EResultTransactionType, ITransactionCompleteCallbackResponse } from 'hooks/useIClientTyro';
import { get } from 'lodash';
import { useState } from 'react';
import { useSetLoadingPage } from 'services/UI/LoadingPage';
import { useAppDispatch } from 'store/hooks';
import useFee from './useFee';
import { getUniqueTransactionId, roundNumber } from 'utils/unit';
import { useCheckoutPayment } from './useSurcharge';
import { useTranslation } from 'react-i18next';

/**
 * Card
 * @param onSuccess, isActive , isActive
 * @returns 
 */
function Card({ onSuccess, isActive }: { onSuccess: () => void, isActive?: boolean }) {
  const dispatch = useAppDispatch();
  const [typeCardModalStatus, setTypeCardModalStatus] = useState<ICardType>(null);
  const iClient = useIClientTyro();
  const id = checkoutSelectors.getIdBookingDetail();
  const setLoadingPage = useSetLoadingPage();
  const setting = settingSelectors.getSetting();


  const amount = checkoutSelectors.getOriginTotalPrice();

  const promotionInfo = checkoutSelectors.getPromotionInfo();
  const softPromotionCode = checkoutSelectors.getSoftPromotionCode();
  const onPromotionAccept: IFuncPromotionAccept = (val) => dispatch(checkoutActions.setPromotionInfo(val));
  const detail = checkoutSelectors.getBookingDetail();
  const selected = checkoutSelectors.getSelectedServices();

  const obFeeValues = useFee();
  const obCheckoutPayment = useCheckoutPayment();

  

  const doSoftRedeem = useDoRedeem(detail?.merchant_location_id || '', {
    servicesSelected: selected,
    setErrorBoundary: (val) => dispatch(checkoutActions.setPromotionErrorBoundary(val)),
  });

  /**
   * Transaction complete callback
   * @param model 
   * @returns 
   */
  const transactionCompleteCallback = (model: 'purchase' | 'refund') => async (response: ITransactionCompleteCallbackResponse) => {
    setTypeCardModalStatus(null);
    switch (response.result) {
      case EResultTransactionType.APPROVED: {
        const transactionId = response.transactionReference ?? getUniqueTransactionId();
        const mid = setting.general.merchant_id;
        const tid = setting.general.teminal_id;
        setLoadingPage(true);
        try {
          const res = await apisCheckout.apiCheckoutByCard(String(id), {
            TID: tid,
            MID: mid,
            transaction_id: transactionId,
            amount_given: amount,
            ...obFeeValues ?? {},
            ...obCheckoutPayment ?? undefined

          });
          const msg = get(res, 'data.data.message', '');
          if (msg) {
            message.success(model === 'purchase' ? 'Purchase' : 'Refund' + ' Successful');
            dispatch(checkoutActions.setLoyaltyPoint(res?.data?.data?.notify_invoice?.loyalty_point));
            dispatch(checkoutActions.setResultTotal(res?.data?.data?.notify_invoice));
            
            onSuccess();
          } else {
            const error = get(res, 'data.error.message', '');
            if (error) {
              message.error(error);
            } else
              throw 'fail';
          }
        } catch (error) {
          message.error('An error occurred. Please try again');
          // if (model === 'purchase') {
          //   iClient.refund(amount, {
          //     transactionCompleteCallback: transactionCompleteCallback('refund'),
          //   });
          // }
        } finally {
          setLoadingPage(false);
        }
        return;
      }
      case EResultTransactionType.CANCELLED:
      case EResultTransactionType.REVERSED:
      case EResultTransactionType.DECLINED: {
        setTypeCardModalStatus('wrongInfo');
        return;
      }
      case EResultTransactionType.SYSTEM_ERROR: {
        setTypeCardModalStatus('systemError');
        return;
      }
      default: {
        setTypeCardModalStatus('notPair');
        return;
      }
    }
  };


  /**
   * On press
   * @returns 
   */
  const onPress = async () => {

    if (!promotionInfo && softPromotionCode) {
      setLoadingPage(true);
      try {
        const softPromotionInfo = await doSoftRedeem(softPromotionCode);
        onPromotionAccept(softPromotionInfo);
      } catch (error) { }
      finally {
        setLoadingPage(false);
      }
      return;
    }

    if (setting.general.merchant_id === '' || setting.general.teminal_id === '') {
      const transactionId = getUniqueTransactionId();
      const mid = undefined;
      const tid = undefined;
      setLoadingPage(true);
      try {
        const res = await apisCheckout.apiCheckoutByCard(String(id), {
          TID: tid,
          MID: mid,
          transaction_id: transactionId,
          amount_given: roundNumber(amount),
          ...obFeeValues ?? {},
          ...obCheckoutPayment ?? undefined

        });
        const msg = get(res, 'data.data.message', '');
        dispatch(checkoutActions.setResultTotal(res?.data?.data?.notify_invoice));

        if (msg) {
          message.success(msg);
          onSuccess();
        } else {
          const error = get(res, 'data.data.error.message', '');
          if (error) {
            message.error(error);
          } else
            throw 'fail';
          }
        } catch (error) {
          message.error('An error occurred. Please try again');
          // if (model === 'purchase') {
          //   iClient.refund(amount, {
          //     transactionCompleteCallback: transactionCompleteCallback('refund'),
          //   });
          // }
        } finally {
          setLoadingPage(false);
        }
      return;
    }
    
    setTypeCardModalStatus('process');
    iClient.purchase(roundNumber(amount), { transactionCompleteCallback: transactionCompleteCallback('purchase') });
  };

  const{t: bookingLang} = useTranslation( 'booking');
  return (
    <>
      <button
        type='button'
        className='form-booking-submit'
        onClick={onPress}
        disabled={isActive}
      >
        {bookingLang('Confirm')}
      </button>
      <CardModal type={typeCardModalStatus} handleCancel={() => setTypeCardModalStatus(null)} />
    </>
  );
}

/**
 * Card modal
 * @param type, handleCancel 
 * @returns 
 */
const CardModal = ({ type, handleCancel }: { type: ICardType, handleCancel: () => void }) => {
  if (!type) return null;
  return (
    <Modal
      maskClosable={false}
      open
      onCancel={handleCancel}
      footer={null}
      centered
      forceRender
    >
      <CardPayment type={type} />
    </Modal>
  );
};



export default Card;
