import styled from 'styled-components';
import { Col, Row } from 'antd';
import { useState } from 'react';
import { take } from 'lodash';
import { useDispatch } from 'react-redux';
import bookingActions from 'features/bookings/services/actions';
import bookingSelectors from 'features/bookings/services/selectors';
import moment from 'moment';
import { HOUR_MINUTE_FORMAT } from 'features/bookings/services/constants';
import { useTranslation } from 'react-i18next';

interface ITimeScheduleItemPickerProps {
  timeSchedule: string[]
  label: string
}

const TimeScheduleItemPicker = ({timeSchedule, label}: ITimeScheduleItemPickerProps) => {

  const dispatch = useDispatch();

  const [isViewMore, setIsViewMore] = useState(true);

  const listTime = isViewMore ? take(timeSchedule, 6) : timeSchedule;
  const timeSelected = bookingSelectors.bookingOnline.getBookingTime();

  const onSelected = (time: string) => {
    dispatch(bookingActions.setBookingOnlineFormValues({
      bookingDate: timeSelected?.start,
      bookingTime: moment(time, HOUR_MINUTE_FORMAT).format()
    }));
  };
  const {t: bookingLang} = useTranslation('booking');
  return listTime.length > 0  ? (
    <TimeScheduleItemPickerStyled>
      <h6 className='label'>{label}</h6>
      <Row gutter={[8, 8]}>
        {listTime.map((time, index) => (
          <Col key={index} span={8} onClick={() => onSelected(time)}>
            <div className={`schedule-item ${moment(timeSelected?.start).format(HOUR_MINUTE_FORMAT) === time ? 'active' : '' }`}>{time}</div>
          </Col>
        ))}
      </Row>
      {timeSchedule.length > 6 ? <div className='collapse' onClick={() => setIsViewMore(!isViewMore)}>
        {isViewMore ? <>
          <p>{bookingLang('ViewMore')}</p>
          <IconDown />
        </> : <>
          <p>{bookingLang('Close')}</p>
          <IconUp />
        </>}
      </div> : <></>}
    </TimeScheduleItemPickerStyled>
  ) : <></>;
};

export default TimeScheduleItemPicker;

const TimeScheduleItemPickerStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 24px;
  .label {
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
  }

  .schedule-item {
    border: 1px solid #B1B8F7;
    border-radius: 4px;
    padding: 15px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    &:hover {
      background-color: #363565;
      border-color: #363565;
      color: #ffffff;
    }
  }

  .active {
    background-color: #363565;
    border-color: #363565;
    color: #ffffff;
  }

  .collapse {
    display: flex;
    gap: 1px;
    color: #60669F;
    align-items: center;
    cursor: pointer;
  }
`;

const IconDown = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path d="M5 7.5L10 12.5L15 7.5" stroke="#60669F" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
};

const IconUp = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path d="M15 12.5L10 7.5L5 12.5" stroke="#60669F" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
};