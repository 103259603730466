import { Checkbox, Form, Input, Radio, Select, Space } from 'antd';
import StaffFormContent, { IStaffFormInput } from 'features/users/components/StaffFormContent';
import userSelectors from 'features/users/services/selectors';
import { get } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
// import StringValidations from 'utils/validations/string';
import { IFormAddStaffData } from '.';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import settingSelectors from 'features/settings/services/selectors';
import { IOption } from 'models/config';
import { translate } from 'utils/moment/getSingleDateFormat';
// import StringValidations from 'utils/validations/string';

type Props = {
  errors: any;

  isEdit?: boolean;
  defaultData?: IFormAddStaffData;
}
const UserFormFirstStep = ({
  errors = {},
  isEdit,
  defaultData
}: Props) => {


  const getShowCreateStaffs = userSelectors.getShowCreateStaffs();
  const locationOptions: IOption[] = getShowCreateStaffs?.merchant_locations;
  const accessLevelOptions = getShowCreateStaffs?.access_levels;
  const listRole = settingSelectors.rolePermission.getListRole();
  const form = Form.useFormInstance();
  const { t: userLang } = useTranslation('user');
  const { t: bookingLang } = useTranslation('booking');


  const [allowPermission, setAllowPermission] = useState<boolean>(false);

  const onChangeAllowPermission = (value: boolean) => {
    if(value) form.setFieldValue('role', listRole?.[0].id);
    else form.setFieldValue('role', undefined);

    setAllowPermission(value);
  };

  useEffect(() => {
    if(defaultData?.role) setAllowPermission(true);
  }, [defaultData?.role]);

  const options = listRole?.filter(role => role.status === 1)?.map((role) => ({
    label: role.name,
    value: role.id
  }));

  // const checkValid = async () => {
  //   try {
  //     await form.validateFields([
  //       'name',
  //       'accessLevel',
  //       'phone',
  //       'pinNumber',
  //       'location',
  //       'password'
  //     ]);

  //     if (isEdit) {
  //       if (defaultData?.pinNumber !== form.getFieldValue('pinNumber'))
  //         await userApis.checkPinNumberExist({
  //           pin_number: form.getFieldValue('pinNumber')
  //         });
  //     } else {
  //       await userApis.checkPinNumberExist({
  //         pin_number: form.getFieldValue('pinNumber')
  //       });
  //     }

  //     return true;
  //   } catch (error: any) {
  //     const response = get<Record<string, string[]>>(
  //       error,
  //       'response.data.errors',
  //       {}
  //     );
  //     const errors = Object.entries(response);
  //     const errorsObj = {};
  //     errors.forEach(([key, value]) => {
  //       set(errorsObj, [key], value?.[0]);
  //     });

  //     errors.map(([, value]) => {
  //       form.setFields([
  //         {
  //           name: 'pinNumber',
  //           errors: [get(value, [0], '')]
  //         }
  //       ]);

  //     });
  //     return false;
  //   }
  // };

  useEffect(() => {
    const locationElement = document.getElementById(`location_${defaultData?.location}`);
    locationElement?.scrollIntoView();
  }, []);

  const getFeedback = (text: string) => {
    if (!text) return {};
    return ({
      validateStatus: text ? 'error' : undefined,
      help: text ? text : undefined,
    });
  };

  // @ts-ignore
  const inputs: IStaffFormInput[] = useMemo(() => [
    {
      label: userLang('Name'),
      name: 'name',
      placeholder: userLang('EnterUserName'),
      type: 'text',
      rules: [
        {
          validator(_, value,) {
            if (!value?.trim()) return Promise.reject(new Error(userLang('TeamMemberNameIsRequired') || ''));
            if (value && value.trim().length > 15) return Promise.reject(new Error(userLang('TeamMemberNameMustBe15CharactersOrFewer') || ''));
            return Promise.resolve();
          },
        },
        { min: 2, message: bookingLang('NameMustBeAtLeast2Characters') || '' },
      ],
      ...getFeedback(get(errors, ['full_name'])),
    },
    {
      label: userLang('PhoneNumber'),
      name: 'phone',
      type: 'phone',
      rules: [{
        validator(_, value,) {
          if (!value?.trim()) return Promise.reject(new Error(userLang('PhoneNumberIsRequired') || ''));
          if (value && !/^0+[0-9]{9}$/.test(value)) return Promise.reject(new Error(userLang('ThePhoneNumberIs04AndHas10Digits') || ''));
          return Promise.resolve();
        },
      }],
      ...getFeedback(get(errors, ['phone'])),
    },
    // {
    //   label: 'Email',
    //   name: 'email',
    //   placeholder: 'Enter user\'s email',
    //   rules: [
    //     { required: true },
    //   ]
    // },
    // {
    //   label: userLang('PinNumber'),
    //   name: 'pinNumber',
    //   placeholder: userLang('EnterPinNumber'),
    //   type: 'textPin',
    //   rules: [{
    //     validator(_, value,) {
    //       if (!value) return Promise.reject(new Error(userLang('PinNumberIsRequired') || ''));
    //       if (value.length < 4) return Promise.reject(new Error(userLang('ThePinNumberMustBe4Digits') || ''));
    //       return Promise.resolve();
    //     },
    //   }],
    //   subTitle: userLang('YouCanUseThisPINNumberToEasilyAccessTheTimeAttendanceSystem'),
    //   ...getFeedback(get(errors, ['pin_number'])),
    // },
    // {
    //   label: userLang('AccessLevel'),
    //   name: 'accessLevel',
    //   placeholder: userLang('SelectAccessLevel'),
    //   options: accessLevelOptions,
    //   type: 'select',
    //   disabled: defaultData?.is_manager,
    //   rules: [{ required: true, message: userLang('AccessLevelRequired') }],
    //   ...getFeedback(get(errors, ['access_level'])),
    // },
    // {
    //   label: userLang('Locations'),
    //   name: 'location',
    //   placeholder: userLang('SelectLocation'),
    //   options: locationOptions,
    //   type: 'select',
    //   disabled: defaultData?.is_manager,
    //   onChange: (value: any) => {
    //     dispatch(userActions.setLocationSelectedId(value));
    //   },
    //   rules: [{ required: true, message: userLang('LocationsRequired') }],
    // },
    {
      label: userLang('Booking'),
      title: userLang('AllowBookingCalendar'),
      name: 'isReceiveBook',
      subTitle: userLang('AllowThisTeamMemberToReceiveBookingNotification'),
      type: 'switch'
    }
  ], [accessLevelOptions, errors]);

  return (
    <UserFormFirstStepStyled>
      <div className='round'>
        <h3 className='title'>{translate('user', 'V2.sectionInfo')}</h3>
        <StaffFormContent inputs={inputs} />
        
      </div>
      <div>
        <div className='round'>
          <h3 className='title'>{translate('user', 'V2.sectionRole')}</h3>
          <p className='helper'>{translate('user', 'V2.sectionRoleDes')}</p>
          <Form.Item name={'allowRole'}>
            <Checkbox checked={allowPermission} onChange={(e) => onChangeAllowPermission(e.target.checked)} className='label'>{translate('user', 'V2.cbAllowRole')}</Checkbox>
            <p style={{ marginLeft: '25px' }} className='helper'>{translate('user', 'V2.cbAllowRoleDes')}</p>
          </Form.Item>
          {allowPermission && 
            <>
              { !(defaultData?.role && isEdit) && <Form.Item rules={[
                {required: true, message: translate('user', 'V2.msgPassword')}
              ]} name={'password'} label={'Mật khẩu (*)'}>
                <Input.Password
                  placeholder={translate('user', 'V2.placeholderPassword')} />
              </Form.Item> }
              <Form.Item rules={[
                {required: true, message: translate('user', 'V2.msgRole')}
              ]} name={'role'} label={translate('user', 'V2.placeholderRole')}>
                <Select placeholder='Chọn quyền' options={options} />
              </Form.Item>
            </>}
        </div>
        <div className='round'>
          <h3 className='title'>{translate('user', 'V2.sectionLocation')}</h3>
          <p className='helper'>{translate('user', 'V2.sectionLocationDes')}</p>
          <div>
            <Form.Item rules={[
              {required: true, message: translate('user', 'V2.msgLocation') }
            ]} name='location'>
              <Radio.Group className='location-options'>
                <Space direction="vertical">
                  {locationOptions.map(location => {

                    return (
                      <div id={`location_${location.value}`} key={location.value}>
                        <Radio className='radio-item' value={location.value}>
                          <LocationIcon/> {location.label}
                        </Radio>
                      </div>
                    );})}
                </Space>
              </Radio.Group>
            </Form.Item>
          </div>

        </div>
      </div>
    </UserFormFirstStepStyled>
  );
};

export default UserFormFirstStep;

const UserFormFirstStepStyled = styled.div`
  display: flex;

  .round {
    border: 1px solid #BFBFBF;
    border-radius: 12px;
    padding: 24px;
    max-width: 432px;
    margin: 24px 24px 0px 0px;
    transition: all 2s;


    .title {
      font-weight: 700;
      font-size: 20px;
    }

    .helper {
      color: #595959;
      font-size: 12px;
      font-weight: 400;
    }

    .label {
      margin-top: 24px;
      font-size: 16px;
      font-weight: 500;
    }
  }
  .location-options {
    margin-top: 24px;
    max-height: 240px;
    overflow-y: scroll;
    width: 100%;
    .ant-radio-wrapper span.ant-radio + * {
      display: flex;
      align-items: center;
      gap: 8px;
    }
  }
`;

const LocationIcon = () => {
  return (
    <svg width="54" height="54" viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="54" height="54" rx="8" fill="#F5F5F5"/>
      <path opacity="0.4" d="M37.6654 35V20.3334H16.332V35C16.332 37.9456 18.7198 40.3334 21.6654 40.3334H32.332C35.2775 40.3334 37.6654 37.9456 37.6654 35Z" fill="#141414"/>
      <path d="M33.3577 13.6667H20.641C18.2179 13.6667 16.0389 15.3264 15.1389 17.8574L14.0127 21.0249C13.7833 21.6702 13.635 22.356 13.7603 23.0294C14.1821 25.297 15.9695 27 18.1105 27C20.5651 27 22.5549 24.7614 22.5549 22C22.5549 24.7614 24.5448 27 26.9993 27C29.4539 27 31.4438 24.7614 31.4438 22C31.4438 24.7614 33.4336 27 35.8882 27C38.0292 27 39.8166 25.297 40.2384 23.0294C40.3637 22.356 40.2154 21.6702 39.986 21.0249L38.8598 17.8574C37.9598 15.3264 35.7808 13.6667 33.3577 13.6667Z" fill="#141414"/>
      <path d="M23 35.3334C23 33.6765 24.3431 32.3334 26 32.3334H28C29.6569 32.3334 31 33.6765 31 35.3334V40.3334H23V35.3334Z" fill="#141414"/>
    </svg>

  );
};