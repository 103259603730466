/* eslint-disable @typescript-eslint/no-unused-vars */
import { Checkbox, Form, Input, InputNumber, Radio, RadioChangeEvent, Select, message } from 'antd';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import apisAuth from '../services/apis';
import authActions from '../services/actions';
import { first, get } from 'lodash';
import { useAppDispatch } from 'store/hooks';
import { useSetLoadingPage } from 'services/UI/LoadingPage';
import { useForm } from 'antd/es/form/Form';
import FormRow from 'components/common/Form/FormRow';
import PhoneNumberInput from 'components/common/PhoneNumberInput';
type Props = {
    ToRegiterSuccess: any;
    ToLogin: () => void;
    isStep: any;
    setIsStep: any;
    setPhone: any;
};

type IAuthRegisterForm = {
    phone: string;
    password: string;
    confirm_password: string;
    jobId: number;
    modelTypeId: number;
    name: string;
    address: string;
}

/**
 * Sign up v2
 * @param ToRegiterSuccess , ToLogin, isStep, setIsStep, setPhone
 * @returns 
 */
const SignUp_v2 = ({
    ToRegiterSuccess = () => undefined,
    ToLogin = () => undefined,
    isStep,
    setIsStep,
    setPhone
}: Props) => {
    const { t: formLang } = useTranslation('form');
    const [form] = useForm<IAuthRegisterForm>();

    const dispatch = useAppDispatch();

    const setIsLoading = useSetLoadingPage();

    const [step1Data, setStep1Data] = useState<IAuthRegisterForm>({
        phone: '',
        password: '',
        confirm_password: '',
        jobId: 0,
        modelTypeId: 0,
        name: '',
        address: '',
    });

    /**
     * Handle btn click email
     * @param values 
     */
    const handleBtnClickEmail = async (values: IAuthRegisterForm) => {
        const { phone, password, confirm_password, jobId, modelTypeId, name, address } = values;
        setPhone(values.phone);

        
        if (isStep === 1) {
            setStep1Data({
                phone,
                password,
                confirm_password,
                jobId,
                modelTypeId,
                name,
                address,
            });
            setIsStep(isStep + 1);
        }
        if (isStep === 2) {
            try {
                const res = await apisAuth.register({
                    phone: step1Data.phone,
                    password: step1Data.password,
                    confirm_password: step1Data.confirm_password,
                    job_id: jobId,
                    model_type_id: modelTypeId,
                    name: name,
                    address: address
                });

                if (!res.data?.data) {
                    throw { response: res };
                }
                dispatch(authActions.register.success(res));
                setIsLoading(true);
                const msg = get(res, 'data.data.message', '');
                message.success(msg);
                setIsStep(isStep + 1);
            } catch (error) {
                const errors = Object.values(get(error, 'response.data.errors', {}));
                const msg = get(first(errors), [0], '');
                if (msg)
                    message.error(msg);
                dispatch(authActions.register.fail({}));
            } finally {
                setIsLoading(false);
            }
        }
        if (isStep === 3) {
            ToRegiterSuccess();
        }
    };

    /**
     * Handle step
     * @param e 
     */
    const handleStep = (e: any) => {
        e.preventDefault();
        setIsStep(isStep - 1);
    };

    //====== Step 3 =====
    const _one = useRef<HTMLInputElement>(null);
    const _two = useRef<HTMLInputElement>(null);
    const _three = useRef<HTMLInputElement>(null);
    const _four = useRef<HTMLInputElement>(null);
    const pinNumbers = [
        {
            name: '_one',
            ref: _one
        },
        {
            name: '_two',
            ref: _two
        },
        {
            name: '_three',
            ref: _three
        },
        {
            name: '_four',
            ref: _four
        },
    ];

    /**
     * Handle key press
     * @param e 
     */
    const handleKeyPress = (e: { charCode: any; preventDefault: () => void; }) => {
        const charCode = e.charCode;

        if (charCode < 48 || charCode > 57) {
            e.preventDefault();
        }
    };

    return (
        <SignUp_v2Styled>
            <Form
                data-form-testing='form-sign-up'
                onFinish={handleBtnClickEmail}
                className='modal-form'
                layout='vertical'
                form={form}
            >
                {
                    isStep === 1 ?
                        <>
                            <Form.Item
                                label={formLang('SignUp.PhoneNumber/Zalo') + '(*)'}
                                name={'phone'}
                                rules={[
                                    {
                                        required: true,
                                        message: formLang('PleaseEnterPhoneNumber/Zalo') || '',
                                    },
                                    {
                                        pattern: /^0\d{9}$/,
                                        message: formLang('SignUp.ErrorPhoneNumber/Zalo') || '',
                                    },
                                ]}
                            >
                                <PhoneNumberInput
                                    autoFocus
                                />
                            </Form.Item>
                            <Form.Item
                                label={formLang('SignUp.PasswordLabel') + '(*)'}
                                name={'password'}
                                rules={[
                                    {
                                        required: true,
                                        message:
                                            formLang('SignUp.ErrorPassword') || '',
                                    },
                                ]}
                            >
                                <Input.Password
                                    type='password'
                                    placeholder={
                                        formLang('SignUp.InputPasswordPlaceholder') || ''
                                    }
                                />
                            </Form.Item>
                            <Form.Item
                                label={formLang('SignUp.EnterThePasswordLabel') + '(*)'}
                                name={'confirm_password'}
                                rules={[
                                    {
                                        required: true,
                                        message:
                                            formLang('PleaseEnterConfirmPassword') || '',
                                    },
                                    ({ getFieldValue }) => ({
                                        validator(_, value) {
                                            if (!value || getFieldValue('password') === value) {
                                                return Promise.resolve();
                                            }
                                            return Promise.reject(new Error(formLang('SignUp.ErrorEnterThePassword') || ''));
                                        },
                                    }),
                                ]}
                            >
                                <Input.Password
                                    type='password'
                                    placeholder={
                                        formLang('SignUp.InputEnterThePasswordPlaceholder') || ''
                                    }
                                />
                            </Form.Item>
                        </>
                        :
                        isStep === 2 ?
                            <>
                                <Form.Item
                                    label={formLang('Career') + '(*)'}
                                    name="job_id"
                                    rules={[
                                        {
                                            required: true,
                                            message: formLang('PleaseSelectaCareer') || '',
                                        },
                                    ]}
                                >
                                    <Select
                                        placeholder={formLang('ChooseACareer') || ''}
                                        options={[
                                            {
                                                value: 1,
                                                label: 'Nail Salon',
                                            },
                                            {
                                                value: 2,
                                                label: 'Hair Salon',
                                            },
                                        ]}
                                    />
                                </Form.Item>
                                <SelectModelStyled>
                                    <FormRow label={formLang('SelectModel') + '(*)'} name='model_type_id'>
                                        <Radio.Group defaultValue={0}>
                                            <Radio value={0}>{formLang('Individual')}</Radio>
                                            <Radio value={1}>{formLang('HouseholdBusiness')}</Radio>
                                            <Radio value={2}>{formLang('Enterprise')}</Radio>
                                        </Radio.Group>
                                    </FormRow>
                                </SelectModelStyled>
                                <Form.Item
                                    label={formLang('CompanyName') + '(*)'}
                                    name={'name'}
                                    rules={[
                                        {
                                            required: true,
                                            message: formLang('PleaseEnterCompanyName') || '',
                                        },
                                    ]}
                                >
                                    <Input
                                        type='text'
                                        placeholder={
                                            formLang('InputCompanyNamePlaceholder') || ''
                                        }
                                        autoFocus
                                    />
                                </Form.Item>
                                <Form.Item
                                    label={formLang('Address')}
                                    name={'Address'}
                                    help={'Vd: 223 Nguyễn Xí, phường 13, quận Bình Thạnh, Hồ Chí Minh, Việt Nam.'}
                                >
                                    <Input
                                        type='text'
                                        placeholder={
                                            formLang('InputAddressPlaceholder') || ''
                                        }
                                        autoFocus
                                    />
                                </Form.Item>
                            </>
                            :
                            <>
                                <div className="PIN_group" >
                                    {pinNumbers.map(({ name, ref }, index, arr) => (
                                        <FormRow key={index} shouldUpdate noStyle name={name}>
                                            <InputNumber
                                                ref={ref}
                                                maxLength={1}
                                                minLength={1}
                                                min={0}
                                                inputMode='numeric'
                                                className='PIN_input'
                                                onFocus={() => {
                                                    // form?.setFieldValue(name, undefined);
                                                }}
                                                onKeyPress={handleKeyPress}
                                                type='number'

                                                onClick={() => {
                                                    const inputOtp = _one.current?.value || _two.current?.value || _three.current?.value || _four.current?.value;
                                                    if (inputOtp) {
                                                        return;
                                                    }
                                                    _one.current?.focus();
                                                }}

                                                onKeyUp={(e: React.KeyboardEvent<HTMLInputElement>) => {
                                                    if (e === null || e === undefined) return;

                                                    const currentValue = (e.target as HTMLInputElement).value;
                                                    form?.setFieldsValue({
                                                        [name]: currentValue,
                                                    });

                                                    const item = arr[index + 1] ? arr[index + 1] : undefined;
                                                    if (item && currentValue.length > 0) {
                                                        setTimeout(() => item.ref?.current?.focus(), 0);
                                                    }
                                                }}
                                                onChange={(e) => {
                                                    if (e !== null && e !== undefined) {
                                                        const currentValue = e.toString();
                                                        form?.setFieldsValue({
                                                            [name]: currentValue,
                                                        });

                                                        const item = arr[index + 0] ? arr[index + 0] : undefined;
                                                        if (item) {
                                                            setTimeout(() => item?.ref?.current?.focus());
                                                        }
                                                    }
                                                }}
                                                onKeyDown={e => {
                                                    if (e.key === 'Backspace' || e.key === 'Delete' || e.keyCode === 46 || e.keyCode === 8) {
                                                        if (!form.getFieldValue(name)) {
                                                            const item = arr[index - 1] ? arr[index - 1] : undefined;
                                                            form?.setFieldValue(item?.name ?? '', undefined);
                                                            setTimeout(() => item?.ref?.current?.focus(), 1);
                                                        } else {
                                                            const item = arr[index + 0] ? arr[index + 0] : undefined;
                                                            form?.setFieldValue(item?.name ?? '', undefined);
                                                            setTimeout(() => item?.ref?.current?.focus(), 0);
                                                        }

                                                    }
                                                }}
                                            />
                                        </FormRow>
                                    ))}
                                </div>
                            </>
                }

                {
                    isStep === 1 ?
                        <>
                            <div className='checkbox-step1'>
                                <Checkbox>{formLang('SignUp.IAgreeWith')} <a href="#">{formLang('SignUp.RegistrationAgreement')}</a> {formLang('SignUp.and')} <a href="#">{formLang('SignUp.PrivacyPolicy')}</a> {formLang('SignUp.ByMindHana')}.</Checkbox>
                            </div>
                            <button
                                type="submit"
                                className='btn-submit'
                            >
                                {formLang('Continue')}
                            </button>
                        </>
                        :
                        isStep === 2 ?
                            <ButonStep2Styled>
                                <button
                                    className='btn-submit btn-back-sign-up'
                                    onClick={handleStep}
                                >
                                    {formLang('Trở về')}
                                </button>
                                <button
                                    type="submit"
                                    className='btn-submit'
                                >
                                    {formLang('SignUp.Register')}
                                </button>
                            </ButonStep2Styled>
                            :
                            <ButonStep3Styled>
                                <div className='btn-container'>
                                    <button
                                        className='btn-submit btn-back-sign-up'
                                        onClick={handleStep}
                                    >
                                        {formLang('SignUp.back')}
                                    </button>
                                    <button
                                        type="submit"
                                        className='btn-submit'
                                    >
                                        {formLang('Confirm')}
                                    </button>
                                </div>
                                <div>
                                    <p className='send-code'>
                                        Gửi lại mã
                                    </p>
                                </div>
                            </ButonStep3Styled>
                }
                <div>
                    <p className='have-account'>Bạn đã có tài khoản? <a href="/sign-in">Đăng nhập</a></p>
                </div>

            </Form>
        </SignUp_v2Styled>
    );
};

export default SignUp_v2;

const SignUp_v2Styled = styled.div`
width: 500px;
@media only screen and (max-width: 767.98px) {
    width: 100%;
}
.checkbox-step1 {
    margin-bottom: 24px;
    span {
        color: var(--Gray-95, #141414);
        font-size: 16px;
        font-weight: 400;
        line-height: 140%;
        text-align: start;
        a {
            color: var(--Blue-Crayola-Main, #5A78FF);
            font-size: 16px;
            font-weight: 600;
            line-height: 140%;
        }
    }
}
.ant-form-item-explain {
    text-align: start;
    margin-top: 8px;
    color: var(--Gray-60, #595959);
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
}
.have-account {
    color: var(--Gray-95, #141414);
    text-align: center;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    margin-top: 48px;
    a {
        color: var(--Blue-Munsell-Main, #1B9AAA);
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 140%;
    }
}
.btn-back-sign-up {
    background-color: transparent;
    color: #1B9AAA;
}
.ant-input-number-input {
    border: 0px;
    height: 48px;
}
.ant-input-number {
    width: 100% !important;
}
 .ant-form-item-explain-error {
    display: flex;
    align-items: start;
  }
  .auth-forgot {
    margin: 16px 0;
  }
  .ant-select-selector {
    text-align: start;
    height: 48px !important;
  }
  .ant-form-item .ant-form-item-label > label {
    color: var(--Gray-95, #141414);
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%;
  }

  .PIN_group {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    width:100%;
    margin-top: 10%;
    margin-bottom: 5%;
  }
  .PIN_input {
    flex:1;
    background: none;
    border: unset;
    border-radius: 0;
    border-bottom: 3px solid #C3C2DE;
    input {
        width: 50px;
        padding: 0;
        font-style: normal;
        font-weight: 600;
        font-size: 40px;
        line-height: 140%;
        text-align: center;
        color: #363565;
        background: none;
    }
    &.ant-input-number {
      width: 40px;
    }
  }
`;

const SelectModelStyled = styled.div`
text-align: start;
.label .labelText {
    color: var(--Gray-95, #141414);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%;
}
.ant-radio-wrapper span.ant-radio + * {
    color: var(--Gray-95, #141414);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
}
`;

const ButonStep2Styled = styled.div`
display: flex;
justify-content: center;
align-items: center;
gap: 12px;
.btn-submit {
    width: 100%;
} 
`;

const ButonStep3Styled = styled.div`
display: grid;
align-items: center;
gap: 8px;
.btn-container {
display: flex;
justify-content: center;
align-items: center;
gap: 12px;
}
.btn-submit {
    width: 100%;
} 
.send-code {
    color: var(--Gray-95, #141414);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    cursor: pointer;
}
`;
