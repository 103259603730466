/* eslint-disable react/jsx-key */
import React, { useState } from 'react';
import styled from 'styled-components';

const SelectColor = () => {
  // const [isOpen, setIsOpen] = useState(false);
  const [selectedColor, setSelectedColor] = useState('');

  const colorOptions = [
    'red',
    'green',
    'blue',
    'yellow',
    'orange',
    'purple',
    'pink',
    'cyan',
    'gray',
  ];

  const handleColorClick = (color: any) => {
    setSelectedColor(color);
    // setIsOpen(false);
  };

  const renderColorOptions = () => {
    return colorOptions.map((color, index) => (
      <div className={`${selectedColor === color ? 'selected' : ''} color-option-container`}>
        <div
          key={index}
          className={'color-option'}
          style={{ backgroundColor: color }}
          onClick={() => handleColorClick(color)}
        ></div>
      </div>
    ));
  };

// const toggleColorPicker = () => {
//   setIsOpen(!isOpen);
// };
return (
  <div>
    <LoadingPageStyled>
      <p className='nameCategory'>Màu danh mục (*)</p>
      {/* <div className="color-picker" onClick={toggleColorPicker}>
        <div className="selected-color" style={{ backgroundColor: selectedColor }}></div>
        <button className={`toggle-icon ${isOpen ? 'rotate' : ''}`} onClick={toggleColorPicker}>
          <IconRight />
        </button>

      </div>
      {isOpen && <div className="color-options">{renderColorOptions()}</div>}

      <hr /> */}
      <div className="color-options-list">{renderColorOptions()}</div>
    </LoadingPageStyled>
  </div>
);
};

export default SelectColor;

const LoadingPageStyled = styled.div`
margin-bottom: 200px;
.nameCategory {
    font-size: 16px;
    font-weight: 600;
    line-height: 22px;
    margin: 24px 0 8px 0;
}
.color-picker {
    display: flex;
    position: relative;
    -webkit-box-pack: justify;
    justify-content: space-between;
    padding: 12px 14px;
    border: 1px solid #D9D9D9;
    border-radius: 10px;
    margin-top: 8px;
  }
  
  .selected-color {
    width: 30px;
    height: 30px;
    border: 1px solid #ccc;
    border-radius: 24px;
  }
  
  .toggle-icon {
    transition: transform 0.3s;
  }
  
  .rotate {
    transform: rotate(90deg);
  }
  
  .color-options {
    display: flex;
    flex-wrap: wrap;
    -webkit-box-pack: start;
    justify-content: flex-start;
    transition: all 0.3s ease 0s;
    width: 250px;
    padding: 18px 10px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 12px 12px -6px, rgba(0, 0, 0, 0.4) 0px 0px 1px;
    border-radius: 10px;
    margin-top: 8px;
  }

  .color-options-list {
    display: flex;
    gap: 12px;
    justify-content: start;
    align-items: center;
  }
  
  .color-option {
    width: 30px;
    height: 30px;
    cursor: pointer;
    border-radius: 24px;
  }
  
  .color-option-container {
    /* width: 40px;
    height: 40px; */
    padding: 2px;
  }

  .selected {
    border: 2px solid #000;
    border-radius: 50%;
  }
`;