import { get } from 'lodash';
import { RootState } from 'store';
import { useAppSelector } from 'store/hooks';
import { IState } from './types/reducer';
import { ModulePermission } from './types/permission';

type MyState = RootState['package'];

const getCurrentState = (state: RootState): MyState => state.package;

const selector = <T = keyof MyState>(key: T, defaultValue?: any) => useAppSelector(state => get(getCurrentState(state), key as any, defaultValue));

const getPermissionFeature = (features: ModulePermission) => selector(`merchant_permission.package_features.${features}`) as string[];
const getPermissionFeatureAll = () => selector('merchant_permission.package_features') as IState['merchant_permission'];
const getPermissionLimit = (type: 'limit_customer' | 'limit_location' | 'limit_staff') => selector(`merchant_permission.package_extras.${type}`) as number;

const packagePermissionSelector = {
  getPermissionFeature,
  getPermissionFeatureAll,
  getPermissionLimit
};

export default packagePermissionSelector;