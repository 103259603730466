/* eslint-disable react/jsx-key */
/* eslint-disable react/no-unknown-property */
import {
  CalendarOutlined,
  ClockCircleOutlined,
  CloseOutlined,
  // UnorderedListOutlined,
} from '@ant-design/icons';
import { Avatar, Popover, Space } from 'antd';
import { EBookingStatus } from 'constants/index';
import bookingActions from 'features/bookings/services/actions';
import moment from 'moment';
import React, { MouseEventHandler, useMemo } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAppDispatch } from 'store/hooks';
import { checkStatusCanEditBooking, formatMoney, formatTimeMinutes, getServicesAndVoucher } from 'utils/unit';
import UIServiceItem from '../UIServiceItem';
import { BookingItem } from '../types';
import { ButtonSuccess, PopoverContentStyled, PopoverTitleStyled } from './styles';
import getSingleDateFormat from 'utils/moment/getSingleDateFormat';
import settingSelectors from 'features/settings/services/selectors';
import { IBookAssignmentServiceResData } from 'features/bookings/services/types/booking';
import { useTranslation } from 'react-i18next';
import IconLocationCardBooking from 'assets/svg/IconLocationCardBooking';
import IconPhoneCardBooking from 'assets/svg/IconPhoneCardBooking';
// import useSendCustomerInvoice from 'hooks/useSendCustomerInvoice';

type Props = {
  open: boolean;
  setOpen: (val: boolean) => void;
  data: BookingItem;
  children?: React.ReactNode;
  isMemberView?: boolean;
  removeWrapStyle?: boolean;
};
/**
 * UI popover
 * @param open, setOpen, data, isMemberView, children, removeWrapStyle 
 * @returns 
 */
const UIPopover: React.FC<Props> = ({ open, setOpen, data, isMemberView, children, removeWrapStyle }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t: bookingLang } = useTranslation('booking');
  const { t: dateLang } = useTranslation('date');

  const {
    customer,
    time_start,
    time_end,
    book_status,
    book_assignment_services = [],
    id,
    noted_client,
    appointment_note,
    parent_time_start,
    book_invoice,
    location
  } = data;

  const bookAssignmentService = getServicesAndVoucher(book_assignment_services ?? [], book_invoice);

  const customerName = book_status === EBookingStatus.DRAFT ? 'Draft booking' : customer?.name ?? '';
  const note = noted_client || appointment_note;
  const setting = settingSelectors.getSetting();

  // const _sendCustomerInvoice = useSendCustomerInvoice();

  // const handleSendInvoice = () => {
  //   const customerCode = data?.customer?.customer_code ?? '';
  //   const email = data?.customer?.email ?? '';
  //   _sendCustomerInvoice({ invoiceId: data?.invoice_code ?? '', email, customerCode });
  // };

  const text = useMemo(
    () => (
      <PopoverTitleStyled>
        <div className='group'>
          {/* {book_status !== EBookingStatus.DRAFT ? (
            <Avatar style={{textTransform: 'uppercase'}} className='avatar' size={'small'}>
              {!!customerName && customerName[0]}
            </Avatar>
          ) : (
            <div className='avatar-blank' />
          )} */}
          {book_status !== EBookingStatus.DRAFT ? (
            <div className='inner'>
              <p className='name' style={{ textTransform: 'capitalize' }}>{bookingLang('CodeBooking')} #{id}</p>
            </div>
          ) : (
            <div className='inner'>
              <p className='name'>{bookingLang('NoCodeBooking')}</p>
            </div>
          )}
        </div>
        <CloseOutlined onClick={() => setOpen(false)} className='icon-close' />
      </PopoverTitleStyled>
    ),
    [data]
  );

  const checkIn = () => {
    if (data?.book_assignment_services?.length === 0 || (data?.book_assignment_services?.find(o => !o.assigned_employee) && setting?.booking.is_team_member_booking)) {
      navigate(`/private/bookings/edit-booking?bookId=${id}&isCheckIn=${true}`);
      return;
    }
    dispatch(bookingActions.checkInBooking(String(id)));
    setOpen(false);
  };

  const content = useMemo(() => {
    const _time_start = isMemberView ? parent_time_start : time_start;
    const _timeStart = moment(_time_start);
    const _timeFinish = moment(time_end);
    const _timeMinute = _timeFinish.diff(_timeStart, 'minutes');
    let total = 0;

    const getButton = () => {
      if (bookAssignmentService.length === 0 && checkStatusCanEditBooking(book_status)) return (
        <Link to={`/private/bookings/edit-booking?bookId=${id}`}>
          <ButtonSuccess type='button' onClick={() => setOpen(false)}>{bookingLang('EditBooking')}</ButtonSuccess>
        </Link>
      );

      // const bookingTime = _timeStart.clone().subtract(1, 'hour');
      // const currentTime = moment();

      switch (book_status) {
        case EBookingStatus.OPEN:
          // if (currentTime > bookingTime) {
          return (
            <ButtonSuccess type='button' onClick={checkIn}>
              {bookingLang('Check-in')}
            </ButtonSuccess>
          );
        // }
        // return null;
        case EBookingStatus.IN_PROGRESS: {
          return (
            <Link to={`/private/bookings/checkout/${id}`}>
              <ButtonSuccess type='button' onClick={() => setOpen(false)}>{bookingLang('Check-out')}</ButtonSuccess>
            </Link>
          );
        }
        case EBookingStatus.COMPLETED:
          return (
            <Space direction='horizontal'>
              {/* <ButtonSuccess type='button'>Refund</ButtonSuccess> */}
              {/* <ButtonSuccess type='button' onClick={handleSendInvoice}>Send Invoice</ButtonSuccess> */}
            </Space>
          );
        case EBookingStatus.CANCELLED:
        case EBookingStatus.NO_SHOW:
          return null;
        case EBookingStatus.DRAFT:
          return (
            <Link to={`/private/bookings/edit-booking?bookId=${id}`}>
              <ButtonSuccess type='button' onClick={() => setOpen(false)}>{bookingLang('EditBooking')}</ButtonSuccess>
            </Link>
          );
        default:
          return null;
      }
    };

    return (
      <PopoverContentStyled isEmptyService={!bookAssignmentService.length}>
        <div className='group'>
          {book_status !== EBookingStatus.DRAFT ? (
            <Avatar style={{ textTransform: 'uppercase' }} className='avatar' size={'small'}>
              {!!customerName && customerName[0]}
            </Avatar>
          ) : (
            <div className='avatar-blank' />
          )}
          {book_status !== EBookingStatus.DRAFT ? (
            <div className='inner'>
              <p className='name' style={{ textTransform: 'capitalize' }}>{customerName}</p>
              <p className='phone' style={{ textTransform: 'capitalize' }}><IconPhoneCardBooking /> {customer?.phone_number}</p>
            </div>
          ) : (
            <div>
              <p className='name'>{bookingLang('NoCustomer')}</p>
            </div>
          )}
        </div>
        <div className='note-container'>
          <IconInfoNote /> <p>Đây là ghi chú quan trọng của khách hàng</p>
        </div>
        <div className='box'>
          <table>
            <tbody>
              <tr>
                <td className='icon'>
                  <CalendarOutlined />
                </td>
                <td className='right-content'>
                  <p>{getSingleDateFormat(_timeStart)}</p>
                </td>
              </tr>
              <tr>
                <td className='icon'>
                  <ClockCircleOutlined />
                </td>
                <td className='right-content'>
                  <p>
                    {_timeStart.format('HH:mm')} - {_timeFinish.format('HH:mm')}{' '}
                    <span className='subtext'>
                      {_timeMinute ? `(${formatTimeMinutes(_timeMinute, dateLang)})` : ''}
                    </span>
                  </p>
                </td>
              </tr>
              <tr>
                <td className='icon'>
                  <IconLocationCardBooking />
                </td>
                <td className='right-content'>
                  <p>
                    {location?.name}
                  </p>
                </td>
              </tr>
              <tr>
                {/* <td className='icon' style={{ verticalAlign: 'top' }}>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      paddingTop: '2px',
                    }}
                  >
                    <UnorderedListOutlined />
                  </div>
                </td> */}
                <td colSpan={2}>
                  <div className='service-item'>
                    {bookAssignmentService.length > 0 ? (
                      bookAssignmentService.map((ser, i) => {
                        total = total + ser.service_price * ser.quantity;
                        return (
                          <div className={i === bookAssignmentService.length - 1 ? 'no-border-bottom' : 'border-bottom'}>
                            <UIServiceItem key={i} data={ser as IBookAssignmentServiceResData} />
                          </div>
                        );
                      })
                    ) : (
                      <div className='service-item'>
                        <span className='name'>{bookingLang('NoServices')}</span>
                      </div>
                    )}
                  </div>
                </td>
              </tr>

              {bookAssignmentService.length > 0 && (
                <>
                  <tr>
                    <td colSpan={2}>
                      <div className='divider'></div>
                    </td>
                  </tr>
                  <tr>
                    {/* <td style={{ opacity: 0 }}>
                      <UnorderedListOutlined />
                    </td> */}
                    <td colSpan={2} className='right-content'>
                      <div className='total'>
                        <p>{bookingLang('TOTAL')}</p>
                        <p className='amount'>
                          <b>{formatMoney(total)}</b>
                        </p>
                      </div>
                    </td>
                  </tr>
                </>
              )}
            </tbody>
          </table>
          {
            note ? <p className='title-note'>{bookingLang('Note')}</p> : <></>
          }
          {note ? <div className='input-note-disable'>

            {note ? <p className='text-note-show'>{note}</p> : <div className='text-note'></div>}
          </div> : <></>}
          {customer?.important_client_info && <>
            <p>{bookingLang('CustomerNote')}:</p>
            <div className='input-note-disable'>
              <p className='text-note-show'>{customer.important_client_info}</p> : <div className='text-note'></div>
            </div>
          </>}
        </div>
        <div className='button'>{getButton()}</div>
      </PopoverContentStyled>
    );
  }, [data, book_status, isMemberView]);

  const onClickButton: MouseEventHandler<HTMLDivElement> = (e) => {
    e.stopPropagation();
    setOpen(true);
  };

  return (
    <Popover
      arrow={false}
      open={open}
      onOpenChange={setOpen}
      overlayInnerStyle={{ padding: 0, }}
      placement='right'
      title={text}
      content={content}
      align={{ offset: [0, 100] }}
      trigger='click'
      overlayClassName='is-detail'
    >
      <div style={removeWrapStyle ? {} : { width: '100%', height: '100%' }} onClick={onClickButton}>{children}</div>
    </Popover>
  );
};

export default UIPopover;


export const IconInfoNote = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="23" height="22" viewBox="0 0 23 22" fill="none">
      <path d="M11.3535 21.1797C16.8764 21.1797 21.3535 16.7025 21.3535 11.1797C21.3535 5.65684 16.8764 1.17969 11.3535 1.17969C5.83067 1.17969 1.35352 5.65684 1.35352 11.1797C1.35352 16.7025 5.83067 21.1797 11.3535 21.1797Z" stroke="#5A78FF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M11.3535 15.1797V11.1797" stroke="#5A78FF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M11.3535 7.17969H11.3635" stroke="#5A78FF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
  );
};
