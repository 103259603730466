import fetch from 'services/request';
import { IApiPromotionBodyReq, IApiPromotionListReq } from '../types/api';
import { IPromotionAutoParam } from './types/promotion';

const BASE_URL = 'api/v1/merchants/:merchant_code/promotions';

const getListPromotion = (params?: IApiPromotionListReq) => {
  const _params = { ...params };

  if (_params['per_page'] === undefined) {
    _params.per_page = 20;
  }
  return fetch({
    method: 'get',
    url: BASE_URL,
    params: _params,
  });
};

const getDetailPromotion = (promotion_id: string) => {
  return fetch({
    method: 'get',
    url: BASE_URL + `/show/${promotion_id}`,
  });
};

const getConfigs = () => {
  return fetch({
    method: 'get',
    url: BASE_URL + '/create',
  });
};

const checkPromotionOfflineValid = (promotion_code: string, location_id: number | string, customer_code: string) => {
  return fetch({
    method: 'get',
    url: `api/v1/merchants/:merchant_code/promotions/check-offline/${promotion_code}/${location_id}`,
    params: {
      customer_code: customer_code
    }
  });
};

const checkPromotionOnlineValid = (promotion_code: string, { location_id, merchant_code, customer_code }: {
  
  location_id: number | string,
  merchant_code: string,
  customer_code: string,
}) => {
  return fetch({
    method: 'get',
    url: `api/v1/merchants/${merchant_code}/promotions/check-online/${promotion_code}/${location_id}`,
    params: {
      customer_code
    }
  });
};

const createPromotion = (body: IApiPromotionBodyReq) => {
  return fetch({
    method: 'post',
    url: `${BASE_URL}/create`,
    body: body as any,
  });
};

const updatePromotion = (promotion_id: number, body: IApiPromotionBodyReq) => {
  return fetch({
    method: 'put',
    url: `${BASE_URL}/${promotion_id}`,
    body: body as any,
  });
};

const deletePromotion = (promotion_id: string) => {
  return fetch({
    method: 'delete',
    url: `${BASE_URL}/delete/${promotion_id}`,
  });
};

const generatePromotionCode = () => {
  return fetch({
    method: 'get',
    url: `${BASE_URL}/generate/promotion-code`,
  });
};

const getPromotionAutoOffer = (params: IPromotionAutoParam) => {
  return fetch({
    method: 'post',
    url: `${BASE_URL}/auto-offer`,
    params: params as any,
    body: {
      total_booking: params.total_booking, 
      total_voucher: params.total_voucher
    }
  });
};

const apisPromotion = {
  getListPromotion,
  getDetailPromotion,
  getConfigs,
  checkPromotionOfflineValid,
  checkPromotionOnlineValid,
  createPromotion,
  updatePromotion,
  deletePromotion,
  generatePromotionCode,
  getPromotionAutoOffer
};

export default apisPromotion;
