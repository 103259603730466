import { Form, Input, InputNumber, Modal } from 'antd';
import { IModalFeeFormPayload, feeType } from 'features/checkout/services/types/fee';
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { getNameInitialValue, getTitleModal, getTitleInput } from './hook';
import salesSelectors from 'features/sales/services/selectors';
import checkoutSelectors from 'features/checkout/services/selectors';
import { RuleObject } from 'antd/es/form';
import { formatMoney, getSelectedLanguage } from 'utils/unit';
import { useTranslation } from 'react-i18next';
type IModalFeeFormValues = {
  name: string,
  amountValue?: number;
  percentValue?: number;
};

export type IModalFeeSubmitFunc = (values: IModalFeeFormPayload) => void;
type IModalFeeProps = {
  visible?: boolean;
  onCancel?: () => void;
  onSubmit?: IModalFeeSubmitFunc;
  type?: feeType;
  formData?: IModalFeeFormValues;
};

/**
 * Modal fee
 * @param visible , onCancel , onSubmit , type , formData
 * @returns 
 */
const ModalFee: React.FC<IModalFeeProps> = ({
  visible,
  onCancel = () => undefined,
  onSubmit = () => undefined,
  type = 'extra',
  formData,
}) => {
  const [form] = Form.useForm();
  const [price, setPrice] = useState<number | null>();
  const [percent, setPercent] = useState<number | null>();

  useEffect(() => {
    form.setFieldsValue(formData);
  }, [formData]);

  const onFinish = (values: IModalFeeFormValues) => {
    const payload: IModalFeeFormPayload = {
      name: values.name,
      type: values.percentValue ? 'percent' : 'price',
      value: values.percentValue || values.amountValue || 0,
    };
    onSubmit(payload);
  };

  /**
   * Check valid
   * @returns 
   */
  const checkValid = async () => {
    try {
      await form.validateFields();
      return true;
    } catch (error) {
      return false;
    }
  };

  /**
   * Submit
   * @returns 
   */
  const submit = async () => {
    if (!await checkValid()) return;
    form.submit();
  };

  const myFocus = useRef<any>();

  useEffect(() => {
    myFocus?.current?.input?.focus();
  }, []);

  const totalSale = salesSelectors.getSubTotal();
  const extraFeeSale = salesSelectors.getExtraFeeValue();
  const totalCheckout = checkoutSelectors.getSubTotal();
  const extraFeeCheckout = checkoutSelectors.getExtraFeeValue();
  const { t: bookingLang } = useTranslation('booking');
  return (
    <Modal
      maskClosable={false}
      open={visible}
      onCancel={onCancel}
      okButtonProps={{ style: { display: 'none' } }}
      width={574}
      footer={null}
      forceRender
      centered
    >
      <ModalFeeStyled>
        <h2 className='modal-title'>{getTitleModal(type, bookingLang)}</h2>
        <Form
          data-form-testing='form-checkout-fee'
          className='modal-form'
          autoComplete='off'
          form={form}
          layout='vertical'
          onFinish={onFinish}
          initialValues={{
            name: getNameInitialValue(type, bookingLang),
          }}
        >
          <div className='form-row'>
            <Form.Item rules={[{ required: true, message: 'Vui lòng nhập' }]} label={getTitleInput(type, bookingLang)} name='name'>
              <Input ref={myFocus} />
            </Form.Item>
          </div>
          <PriceStyled>
            <div className='form-row groupPrice items-end'>
              <Form.Item label={bookingLang('Value')} name='amountValue'
                rules={[
                  {
                    validator(rule: RuleObject, value: any, callback: (error?: string | undefined) => void) {
                      if (!value) {
                        callback(undefined);
                        return;
                      }
                      const valueSplit = value.toString().split('.');
                      const valueMax = type === 'discount' ? (totalCheckout === 0 ? (totalSale + extraFeeSale) : (totalCheckout + extraFeeCheckout)) : Number.MAX_SAFE_INTEGER;
                      if (!(valueSplit.length === 1 || valueSplit.length === 2 && valueSplit[1].length <= 2)) callback('The amount contains a decimal number with no more than 2 digits');
                      else if (value > valueMax) callback(bookingLang('TheValueMustNotExceed') + ' ' + formatMoney(valueMax));
                      else callback(undefined);
                    },
                  },
                ]}>
                <InputNumber
                  addonAfter={getSelectedLanguage() === 'vi' ? 'đ' : '$'}
                  placeholder={bookingLang('Price') || ''}
                  min={0.01}
                  // max={type === 'discount' ? (totalCheckout === 0 ? totalSale : totalCheckout) : Number.MAX_SAFE_INTEGER}
                  onChange={(val: any) => {
                    setPrice(val);
                    setPercent(null);
                    form.setFieldValue('percentValue', null);
                  }}
                  type='number'
                />
              </Form.Item>
              <p className='center-text'>{bookingLang('or')}</p>
              <Form.Item label={bookingLang('Value')} name='percentValue'>
                <InputNumber
                  addonAfter='%'
                  placeholder={bookingLang('Percent') || ''}
                  min={0.01}
                  max={100}
                  onChange={(val: any) => {
                    setPercent(val);
                    setPrice(null);
                    form.setFieldValue('amountValue', null);
                  }}
                  type='number'
                />
              </Form.Item>
            </div>
          </PriceStyled>
          <ErrorPriceStyled>
            <Form.Item className='messageError' name={'priceOrPercent'} rules={[{
              validateTrigger: ['amountValue', 'percentValue'],
              validator() {
                const priceValue = price ?? form.getFieldValue('amountValue');
                const percentValue = percent ?? form.getFieldValue('percentValue');

                if ((!priceValue && !percentValue) || (!!priceValue && !!percentValue)) return Promise.reject(new Error(`${bookingLang('PleaseFillInValuePriceOrPercentAField')}`));
                return Promise.resolve();
              },
            }]}
            />
          </ErrorPriceStyled>
          <div className='form-submit-wrapper'>
            <button type='button' onClick={onCancel} className='common-btn is-white'>
              {bookingLang('Cancel')}
            </button>
            <button type='button' onClick={submit} className='common-btn'>
              {bookingLang('Save')}
            </button>
          </div>
        </Form>
      </ModalFeeStyled>
    </Modal>
  );
};

export default ModalFee;
const ModalFeeStyled = styled.div`
.items-end {
  @media only screen and (max-width: 767.98px) {
    display: grid;
  }
}
.center-text {
  @media only screen and (max-width: 767.98px) {
    display: flex;
    justify-content: center;
    margin: 0 !important;
  }
}
`;

const ErrorPriceStyled = styled.div`
.ant-form-item-control-input {
  display: none;
}
`;

const PriceStyled = styled.div`
.ant-form-item-control {
  margin-bottom: 0px !important;
}
`;
