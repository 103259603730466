import { createAction } from '@reduxjs/toolkit';
import { IApiTimeScheduleListReq } from '../types/api';
import { PREFIX_ACTIONS } from './constants';
import { createAsyncAction } from 'services/actionConfigs';
import { IDaysOfWeekItem } from 'utils/moment/getDatesOfWeek';

const setListTimeScheduleParams = createAction<IApiTimeScheduleListReq>(PREFIX_ACTIONS + 'setListTimeScheduleParams');

const getListTimeSchedules = createAsyncAction<IApiTimeScheduleListReq>(PREFIX_ACTIONS + 'getListTimeSchedules');

const getListTimeScheduleOnline = createAsyncAction<IApiTimeScheduleListReq>(PREFIX_ACTIONS + 'getListTimeScheduleOnline');

const getDetailTimeSchedules = createAsyncAction<string>(PREFIX_ACTIONS + 'getDetailTimeSchedules');

const setTimeScheduleFilterOpts = createAction<any[]>(PREFIX_ACTIONS + 'setTimeScheduleFilterOpts');

const getInit = createAsyncAction(PREFIX_ACTIONS + 'getInit');

const getLocationDetail = createAsyncAction<string | number>(PREFIX_ACTIONS + 'getLocationDetail');

const setCalendarDaysOfWeek = createAction<IDaysOfWeekItem[]>(PREFIX_ACTIONS + 'SET_CALENDAR_DAYS_OF_WEEK');

const timeScheduleActions = {
  setListTimeScheduleParams,
  getListTimeSchedules,
  getInit,
  setTimeScheduleFilterOpts,
  getListTimeScheduleOnline,
  getDetailTimeSchedules,
  getLocationDetail,
  setCalendarDaysOfWeek
};

export default timeScheduleActions;
